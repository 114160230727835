<template>
  <md-card class="block-bg px-0">
    <md-card-content class="custom-tab super-table">
      <md-tabs md-alignment="left" :md-active-tab="'tab-combos'">
        <md-tab id="tab-combos" md-label="Combos" exact>
          <combos-list></combos-list>
        </md-tab>

        <md-tab id="tab-combo-groups" md-label="Combo Groups">
          <combo-groups-list></combo-groups-list>
        </md-tab>
      </md-tabs>
    </md-card-content>
  </md-card>
</template>

<script>
import { Tabs } from '@/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import CombosList from './../Categories/CombosList'
import ComboGroupsList from './../Categories/ComboGroupsList'

export default {
  components: {
    Tabs,
    CombosList,
    ComboGroupsList,
  },
  computed: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  watch: {},
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}

.tab-pane {
  width: 100%;
}
</style>
