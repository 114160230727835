<template>
  <div class="nav-boxs">
    <md-toolbar
      md-elevation="0"
      class="md-transparent"
      :class="{
        'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
      }"
    >
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <bread-crumbs></bread-crumbs>
        </div>
        <div class="md-toolbar-section-end">
          <md-button
            class="md-just-icon md-round md-simple md-toolbar-toggle"
            :class="{ toggled: $sidebar.showSidebar }"
            @click="toggleSidebar"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </md-button>

          <div class="md-collapse">
            <!-- <div class="md-autocomplete">
            <md-autocomplete
              class="search"
              v-model="selectedEmployee"
              :md-options="employees"
              :md-open-on-focus="false"
            >
              <label>Search...</label>
            </md-autocomplete>
          </div> -->
            <md-list>
              <li class="md-list-item d-flex align-items-center">
                <a
                  href="#"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-just-icon md-simple"
                        data-toggle="dropdown"
                      >
                        <md-icon>notifications</md-icon>
                        <span class="notification">{{
                          notificationList.length
                        }}</span>
                        <p class="hidden-lg hidden-md">Notifications</p>
                      </md-button>
                      <ul
                        class="dropdown-menu dropdown-menu-right dropdown-menu-notification"
                      >
                        <li
                          v-for="(item, index) in notificationList"
                          :key="index"
                          @click="navigateRoute(item.link)"
                        >
                          <a :href="item.link" target="_blank">{{
                            item.title
                          }}</a>
                          <p>{{ item.description }}</p>
                          <a :href="item.link" target="_blank">{{
                            item.link
                          }}</a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
              <md-list-item>
                <!-- <router-link :to="{ name: 'Profile' }">
                <div class="photo">
                  <img :src="userIcon" alt="avatar" />
                </div>
                </router-link> -->
                <md-menu md-size="medium" :md-offset-x="127" :md-offset-y="12">
                  <div class="photo" md-menu-trigger>
                    <img :src="userIcon" alt="avatar" />
                  </div>

                  <md-menu-content class="logout-section profile-menu">
                    <md-menu-item @click="routeToProfile()">
                      <div>
                        <i class="material-icons logout-icon">account_circle</i>
                      </div>
                      <div>Profile</div>
                    </md-menu-item>
                    <md-menu-item
                      class="logout-section md-layout"
                      @click="showSwal('warning-message-and-confirmation')"
                    >
                      <div>
                        <i class="material-icons logout-icon"
                          >power_settings_new</i
                        >
                      </div>
                      <div>Logout</div>
                    </md-menu-item>
                  </md-menu-content>
                </md-menu>
              </md-list-item>

              <!-- <md-list-item href="#/user/user-profile">
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item> -->
            </md-list>
          </div>
        </div>
      </div>
    </md-toolbar>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import $ from 'jquery'
import BreadCrumbs from '../../../components/BreadCrumbs'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    BreadCrumbs,
  },

  data() {
    return {
      selectedEmployee: '',
      employees: [
        'Jim Halpert',
        'Dwight Schrute',
        'Michael Scott',
        'Pam Beesly',
        'Angela Martin',
        'Kelly Kapoor',
        'Ryan Howard',
        'Kevin Malone',
      ],
      notificationList: [],
    }
  },

  computed: {
    userIcon() {
      return this.$store.getters.getProfile.logo
    },
  },

  methods: {
    navigateRoute(path) {
      window.open(path, '_blank')
    },
    handleSubscribe() {
      this.$emit('subscribe', true)
    },
    toggleSidebar() {
      console.log("a")
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },

    routeToProfile() {
      this.$router.push({
        name: 'Profile',
      })
    },

    logout() {
      sessionStorage.clear()
    },
    showSwal(type) {
      if (type === 'warning-message-and-confirmation') {
        Swal.fire({
          title: 'Are you sure you want to logout?',
          text: ``,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success',
          cancelButtonClass: 'md-button md-danger',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            // Swal.fire({
            //   title: "Deleted!",
            //   text: "Your file has been deleted.",
            //   type: "success",
            //   confirmButtonClass: "md-button md-success",
            //   buttonsStyling: false
            // });
            sessionStorage.clear()
            this.$router.push({
              name: 'Login',
              // params: { formType: "View Category", category: item }
            })
          }
        })
      }
    },
  },
}
</script>
<style lang="scss">
.nav-boxs {
  // box-shadow: #dedede 0px 4px 8px;
  background: #244f54;
}

.logout-icon {
  font-size: 20px;
}
</style>
<style>
.md-toolbar .md-list-item .md-button.md-just-icon i {
  color: #ffffff !important;
  font-size: 30px !important;
}
.dashboard-bg .dropdown .md-button.md-just-icon i:hover {
  color: #ffffff !important;
}
.md-toolbar .notification {
  font-size: 12px !important;
  font-weight: bold;
}
.dropdown-menu-notification {
  min-width: 600px;
  padding: 15px;
}

.dropdown-menu-notification a {
  white-space: pre-line !important;
  padding: 0 !important;
  font-weight: 600 !important;
}

.dropdown-menu-notification p {
  white-space: pre-line;
  text-transform: none !important;
}
</style>
