<template>
  <div class="tax-summary report-drop">
    <md-card class="report-card block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 report-name">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Tax Summary Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout filter-section block-bg">
          <div class="md-layout search-options">
            <div class="md-layout-item md-size-60 pd-l-0">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout-item md-size-25">
              <div class="md-layout datepickers">
                <div
                  :class="[
                    { 'md-size-50': role() == 'branch_user' },
                    { 'md-size-50': role() != 'branch_user' },
                  ]"
                  class="md-layout-item md-size-100 md-small-size-50 pd-r-10 pd-l-0-i-pad"
                  v-if="role() == 'branch_user'"
                >
                  <md-field>
                    <label>Outlet</label>
                    <md-select
                      v-model="outletId"
                      multiple
                      @md-closed="
                        outletId.length > 0 ? taxSummary() : (outletId = [])
                      "
                    >
                      <md-option
                        v-for="item in outletList"
                        :key="item.id"
                        :value="item.id"
                        class="oulet-select-list-item"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
              </div>
            </div>

            <div class="md-layout-item md-size-15">
              <md-button class="md-teritiary" @click="taxSummary()"
                >Search
              </md-button>
            </div>
          </div>
        </div>
        <div class="loader-container" v-if="loader">
          <clip-loader
            class="md-layout-item md-size-100"
            :loading="loader"
            :color="'#00E0DB'"
          >
          </clip-loader>
        </div>
        <div class="md-layout" v-show="report.length > 1">
          <div class="md-layout-item md-small-size-90 md-size-90">
            <md-card>
              <md-card-content>
                <pie-chart
                  :chart-data="datacollection"
                  :height="90"
                  :options="{
                    legend: {
                      position: 'bottom',
                      labels: {
                        fontColor: '#ffffff',
                      },
                    },
                    plugins: {
                      datalabels: {
                        display: true,
                        font: {
                          weight: 'bold',
                          size: 12,
                          color: 'white',
                        },
                        color: 'white',
                        textStrokeColor: 'black',
                        textStrokeWidth: 3,
                      },
                    },
                  }"
                ></pie-chart>
              </md-card-content>
            </md-card>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item pd-l">
            <md-table class="rep-table table-typ1">
              <md-table-toolbar>
                <div class="md-toolbar-section-start toolbar-monthly">
                  <h1 class="md-title text-white font-weight-bold pad-l">
                    Tax Summary
                  </h1>
                  <div class="xlsx-pdf">
                    <img
                      class="pdf"
                      src="../../../../assets/images/pdf.svg"
                      @click="downloadWithoutCSS()"
                    />
                    <vue-excel-xlsx
                      class="excel-btn2 md-primary"
                      :data="report"
                      :columns="columns"
                      :sdate="sdate"
                      :edate="edate"
                      :filename="'Tax Summary'"
                      :sheetname="'Tax Summary'"
                      :outlet-names="selectedOutletsNames()"
                    ></vue-excel-xlsx>
                  </div>
                </div>
              </md-table-toolbar>

              <md-table-row>
                <md-table-head>No.Of Items</md-table-head>
                <md-table-head>Tax Rate</md-table-head>
                <md-table-head>Gross Sales </md-table-head>
                <md-table-head>Tax On Sales</md-table-head>
                <md-table-head>Net Sales</md-table-head>
              </md-table-row>

              <md-table-row v-for="(item, index) in report" :key="index">
                <md-table-cell>{{ item.no_of_items }}</md-table-cell>
                <md-table-cell>{{ item.tax_rate }}</md-table-cell>
                <md-table-cell>{{ item.gross_sale }}</md-table-cell>
                <md-table-cell>{{ item.tax_on_sale }}</md-table-cell>
                <md-table-cell>{{ item.net_sale }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { accountService } from '../../../../services/account.js'
import { reportService } from '../../../../services/reports.js'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import PieChart from '../../../../components/Graphs/PieChart.vue'
import { DateTimePicker } from '@/components'
import { customizeService } from '../../../../services/customize'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
export default {
  components: {
    PieChart,
    DateTimePicker,
    DatePeriodDropdown,
    ClipLoader,
    VueExcelXlsx,
  },
  data() {
    return {
      search: null,
      data: {
        search_type: 'Daily',
      },
      searched: [],
      report: [],
      outletList: [],
      outletId: [],
      sdate: '',
      edate: '',
      columns: [
        {
          label: 'No.Of Items',
          field: 'no_of_items',
        },
        {
          label: 'Tax Rate',
          field: 'tax_rate',
        },
        {
          label: 'Net Sales',
          field: 'net_sale',
        },
        {
          label: 'Tax On Sales',
          field: 'tax_on_sale',
        },
        {
          label: 'Gross Sales',
          field: 'gross_sale',
        },
      ],
      columnStyles: {
        0: { cellWidth: 53 },
        1: { cellWidth: 53 },
        2: { cellWidth: 53 },
        3: { cellWidth: 53 },
        4: { cellWidth: 53 },
      },
      totals: ['Total Sales', 0, 0, 0, 0, 'Total Sales', 0, 0, 0, 0, 0, 0, 0],
      datacollection: null,
      loader: false,
    }
  },

  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
      console.log('from', fromDateTime)
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
      console.log('to', toDateTime)
    },

    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
        if (!this.loader) {
          this.taxSummary()
        }
      }
    },

    fillData() {
      this.datacollection = {
        labels: [],
        datasets: [
          {
            label: [],
            backgroundColor: [],
            data: [],
          },
        ],
      }
      for (var i = 0; i < this.report.length - 1; i++) {
        this.datacollection.labels.push('Vat @ ' + this.report[i].tax_rate)
        this.datacollection.datasets[0].data.push(this.report[i].gross_sale)
        this.datacollection.datasets[0].backgroundColor.push(
          this.getRandomColor()
        )
      }
      console.log('dataCollection', this.datacollection)
    },
    getRandomColor() {
      var letters = 'BCDEF'.split('')
      var color = '#'
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)]
      }
      return color
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = [
          el.no_of_items,
          el.tax_rate,
          el.net_sale,
          el.tax_on_sale,
          el.gross_sale,
        ]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let selectedOutlets = this.selectedOutletsNames()
      let body = this.arrangeForPdf()
      const doc = new jsPDF({ orientation: 'portarait', format: 'a4' })
      this.data.startDate = this.sdate
      this.data.endDate = this.edate
      let head = [
        ['No.Of Items', 'Tax Rate', 'Net Sales', 'Tax On Sales', 'Gross Sales'],
      ]
      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Tax Summary',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'tax-summary.pdf')
    },
    taxSummary() {
      this.loader = true
      this.data.outletId = this.outletId
      console.log('data', this.data)

      if (this.$route.query.s && this.$route.query.e) {
        this.data.search_type = 'Ad Hoc'
        this.data.startDate = this.$route.query.s
        this.data.endDate = this.$route.query.e
      }
      reportService.getTaxSummary(this.data).then((res) => {
        console.log('Res taxSummary: ', res.data.result)
        this.report = res.data.result
        this.fillData()
        this.loader = false

        this.sdate = res.data.sdate
        this.edate = res.data.edate
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        console.log('outlets', this.outletList)
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.taxSummary()
      })
    },
    role() {
      return accountService.userRole()
    },
  },

  created() {
    this.searched = this.users
    this.loader = true
    this.getOutlets()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}
.main-heading {
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 18px;
}

.toolbar-monthly {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.datepickers {
  padding-left: 12px;
}

table > tbody > tr:last-child {
  font-weight: bold;
}
</style>
