<template>
  <form class="md-layout-item pd-l">
    <md-card class="pd-l">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left ml-auto">
            <router-link :to="{ path: '/Products/Combos' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t(formType) }}</h3>
        </div>
        <div class="md-layout-item md-size-30">
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editModule"
            v-if="formType == 'View Combo'"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader" class="block-bg pad-xy rounded-xl">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('comboName') },
                { 'md-valid': !errors.has('comboName') },
              ]"
            >
              <label>{{ $t('Combo Name') }}</label>
              <md-input
                v-model="combos.name"
                data-vv-name="comboName"
                type="text"
                v-validate="modelValidations.comboName"
                :disabled="formType == 'View Combo'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('comboName')">
                  {{ errors.first('comboName') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('comboName') && touched.comboName"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Price') },
                { 'md-valid': !errors.has('Price') },
              ]"
            >
              <label>{{ $t('Price') }}</label>
              <md-input
                type="text"
                v-model="combos.price"
                data-vv-name="Price"
                v-validate="modelValidations.Price"
                :disabled="formType == 'View Combo'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Price')">
                  {{ errors.first('Price') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Price') && touched.Price"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field
              :class="[
                { 'md-error': errors.has('Price') },
                { 'md-valid': !errors.has('Price')}
              ]"
            >
              <label>{{$t('Price')}}</label>
              <md-input
                type="text"
                v-model="combos.price"
                data-vv-name="Price"
                v-validate="modelValidations.Price"
                :disabled="formType == 'View Combo'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Price')">{{errors.first('Price')}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('Price') && touched.Price">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div
            class="md-layout-item md-size-50"
            :disabled="formType == 'View Combo'"
          >
            <label>{{ $t('From Date') }}</label>
            <!-- <md-datepicker
              class="md-datepicker-custm"
              md-immediately
              v-model="combos.combo_from"
            /> -->
            <datetime
              class="datetime-custm"
              format="YYYY-MM-DD H:i"
              v-model="combos.combo_from"
            ></datetime>
          </div>

          <div
            class="md-layout-item md-size-50"
            :disabled="formType == 'View Combo'"
          >
            <label>{{ $t('To Date') }}</label>
            <!-- <md-datepicker
              class="md-datepicker-custm"
              md-immediately
              v-model="combos.combo_to"
            /> -->
            <datetime
              class="datetime-custm"
              format="YYYY-MM-DD H:i"
              v-model="combos.combo_to"
            ></datetime>
          </div>
        </div>

        <div class="md-layout">
          <div
            class="md-layout-item md-size-50"
            :disabled="formType == 'View Combo'"
          >
            <label>{{ $t('Active Start') }}</label>
            <md-field>
              <!-- <label>{{ $t('00:00 AM') }}</label> -->
              <md-input
                type="time"
                v-model="combos.combo_start_time"
                :disabled="formType == 'View Outlet'"
              ></md-input>
            </md-field>
          </div>

          <div
            class="md-layout-item md-size-50"
            :disabled="formType == 'View Combo'"
          >
            <label>{{ $t('Active Finish') }}</label>
            <md-field>
              <!-- <label>{{ $t('00:00 AM') }}</label> -->
              <md-input
                type="time"
                v-model="combos.combo_end_time"
                :value="combos.combo_end_time"
                :disabled="formType == 'View Outlet'"
              ></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label for="Days">{{ $t('Days') }}</label>
              <md-select
                name="movies"
                id="movies"
                v-model="wk_days"
                :disabled="formType == 'View Combo'"
                multiple
              >
                <md-option class="first-opt" disabled>Days</md-option>
                <md-option value="1">{{ $t('Monday') }}</md-option>
                <md-option value="2">{{ $t('Tuesday') }}</md-option>
                <md-option value="3">{{ $t('Wednesday') }}</md-option>
                <md-option value="4">{{ $t('Thursday') }}</md-option>
                <md-option value="5">{{ $t('Friday') }}</md-option>
                <md-option value="6">{{ $t('Saturday') }}</md-option>
                <md-option value="0">{{ $t('Sunday') }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label for="status">{{ $t('Status') }}</label>
              <md-select
                v-model="combos.status"
                name="status"
                :disabled="formType == 'View Combo'"
              >
                <md-option class="first-opt" disabled>Status</md-option>
                <md-option value="true">{{ $t('Active') }}</md-option>
                <md-option value="false">{{ $t('Inactive') }}</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-checkbox
              v-model="combos.group_on_printer"
              :disabled="formType == 'View Combo'"
              >{{ $t('Group on printer') }}</md-checkbox
            >
          </div>
          <div class="md-layout-item md-size-50">
            <md-checkbox
              v-model="combos.auto_apply"
              :disabled="formType == 'View Combo'"
              >{{ $t('Auto Apply') }}</md-checkbox
            >
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field>
              <label for="status">{{$t('Status')}}</label>
              <md-select v-model="combos.status" name="status" :disabled="formType == 'View Combo'">
                <md-option value="true">{{$t('Active')}}</md-option>
                <md-option value="false">{{$t('Inactive')}}</md-option>
              </md-select>
            </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('comboGp') },
                { 'md-valid': !errors.has('comboGp') },
              ]"
            >
              <label for="comboGroup">{{ $t('Combo Group') }}</label>
              <md-select
                v-model="CombogpId"
                multiple
                v-on:input="findComboGp()"
                :disabled="formType == 'View Combo'"
                data-vv-name="comboGp"
                v-validate="modelValidations.comboGp"
              >
                <md-option class="first-opt" disabled>Combo Group</md-option>
                <md-option
                  :value="item.id"
                  v-for="item in comboGroupList"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('comboGp')">
                  {{ errors.first('comboGp') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('comboGp') && touched.comboGp"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-checkbox
              v-model="combos.hide_from_app"
              :disabled="formType == 'View Combo'"
              >{{ $t('Hide?') }}</md-checkbox
            >
          </div>
          <!-- <div class="md-layout-item md-size-50" v-if="role() == 'branch_user'">
            <md-field>
              <label for="status">{{ $t('Outlets') }}</label>
              <md-select
                v-model="combos.outlets"
                multiple
                :disabled="formType == 'View Combo'"
              >
                <md-option class="first-opt" disabled>Outlets</md-option>
                <md-option
                  :value="item.id"
                  v-for="item in outletList"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div> -->
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label for="comboGroup">{{ $t('Modifier Groups') }}</label>
              <md-select
                v-model="mdGroupIds"
                multiple
                :disabled="formType == 'View Combo'"
              >
                <md-option class="first-opt" disabled
                  >Modifier Groups</md-option
                >
                <md-option
                  :value="item.id"
                  v-for="item in modGroupList"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-checkbox
              v-model="combos.dont_print_single_item"
              :disabled="formType == 'View Combo'"
              >{{ $t('Dont print single item?') }}</md-checkbox
            >
          </div>

          <div class="md-layout-item md-size-50" v-if="combos.auto_apply">
            <md-checkbox
              v-model="combos.prompt_before_apply"
              :disabled="formType == 'View Combo'"
              >{{ $t('Prompt Before Apply?') }}</md-checkbox
            >
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field>
              <label for="status">{{$t('Outlets')}}</label>
              <md-select :disabled="formType == 'View Combo'">
                <md-option :value="item.id" v-for="item in outletList" :key="item.id">{{item.name}}</md-option>
              </md-select>
            </md-field>
          </div>
        </div> -->

        <div class="btn-div" v-if="formType != 'View Combo'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/Products/Combos' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>

      <!--======================================= Edit Combo Form ========================================-->

      <!--================================ View Combo Form ===========================================-->
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
    datetime,
  },
  props: {},
  data() {
    return {
      product1: './img/card-2.jpg',
      equalToSource: '',
      equalToDest: '',
      comboName: '',
      Price: '',
      comboGp: '',
      switch2: null,
      touched: {
        required: false,
        comboName: false,
        comboGp: false,
      },
      modelValidations: {
        comboGp: {
          required: true,
        },

        comboName: {
          required: true,
        },

        Price: {
          required: true,
          regex: /[1-9]*/,
        },

        fromDate: {
          required: true,
          date: true,
        },

        toDate: {
          required: true,
        },
      },

      formType: {
        type: String,
        default: 'AddModules',
      },

      comboId: '',

      combos: {
        status: true,
        combo_groups: {},
      },
      outletList: [],
      comboGroupList: [],
      CombogpId: [],
      mdGroupIds: [],
      modGroupList: [],
      confirmComboGp: [],
      wk_days: [],
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit(' ', this.alert('Add'), isValid)
        }
      })
    },
    role() {
      return accountService.userRole()
    },
    removeSubModule(index) {
      this.subModules.splice(index, 1)
    },

    getMonth(date) {
      let month = date.getMonth() + 1
      return month
    },

    alert(value) {
      var self = this
      this.combos.combo_groups = []
      // let fromDate =
      //   this.combos.combo_from.getFullYear() +
      //   '-' +
      //   this.getMonth(this.combos.combo_from) +
      //   '-' +
      //   this.combos.combo_from.getDate()
      // let toDate =
      //   this.combos.combo_to.getFullYear() +
      //   '-' +
      //   this.getMonth(this.combos.combo_to) +
      //   '-' +
      //   this.combos.combo_to.getDate()

      let fromDate = this.combos.combo_from
      let toDate = this.combos.combo_to
      this.combos.combo_from = fromDate
      this.combos.combo_to = toDate
      this.combos.wk_days = this.wk_days.toString()
      this.combos.combo_groups = this.confirmComboGp
      this.combos.mod_group_ids = this.mdGroupIds
      this.combos.outlet_id = accountService.getOutletId()

      if (this.formType == 'Add Combo') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            customizeService.createCombo(this.combos).then((res) => {
              this.$router.push('/Products/Combos')
            })
          }
        })
      } else if (this.formType == 'Edit Combo') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            let body = {
              combos: this.combos,
            }
            axios
              .put(this.url, body, customizeService.authFun())
              .then((res) => {
                this.$router.push('/Products/Combos')
              })
          }
        })
      }
    },

    editModule() {
      this.formType = 'Edit Combo'
    },

    resetModules() {
      this.combos = {}
    },

    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
      })
    },

    getComboGro() {
      customizeService.getComboGroup().then((res) => {
        this.comboGroupList = res.data.filter((el) => el.status == true)
      })
    },

    findComboGp() {
      let self = this
      this.confirmComboGp = []
      for (var i = 0; i < this.CombogpId.length; i++) {
        this.confirmComboGp.push({ combo_group_id: this.CombogpId[i] })
      }
    },
    getModGroups() {
      customizeService.getModifierGroup().then((res) => {
        this.modGroupList = res.data
      })
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    this.getOutlets()
    this.getComboGro()
    this.getModGroups()

    if (this.formType !== 'Add Combo') {
      this.loader = true
      axios
        .get(
          this.url + '?chain_id=' + accountService.getChainId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.loader = false
          this.combos = res.data
          this.wk_days = this.combos.wk_days.split(',')
          // this.combos.combo_from = new Date(res.data.combo_from)
          // this.combos.combo_to = new Date(res.data.combo_to)
          for (var i = 0; i < res.data.combo_groups.length; i++) {
            this.CombogpId.push(res.data.combo_groups[i].id)
          }
          this.findComboGp()
          this.mdGroupIds = res.data.mod_group_ids
        })
    }
  },
  watch: {
    comboGp() {
      this.touched.required = true
    },
    comboName() {
      this.touched.required = true
    },
    Price() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
// .btn-div {
//   // border-top: 1px solid gray;
// }
</style>
