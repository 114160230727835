<template>
  <div class="user" v-if="userRole != 'admin'">
    <div class="photo">
      <img :src="userImage" alt="avatar" />
    </div>
    <div class="user-info">
      <!-- <a
            data-toggle="collapse"
            :aria-expanded="!isClosed"
            @click.stop="toggleMenu"
            @click.capture="clicked"
          > -->
      <!-- <span v-if="$route.meta.rtlActive">
              {{ rtlTitle }}
              <b class="caret"></b>
            </span> -->
      <router-link :to="{ name: 'Profile' }">
        <span class="user-name">
          {{ $t(userName) }}
        </span>
      </router-link>
      <!-- </a> -->

      <!-- <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <router-link :to="{ name: 'UserProfile'}">
                  <span class="sidebar-mini">MP</span>
                  <span class="sidebar-normal">My Profile</span>
                </router-link>
              </li>

              <li>
                  <router-link :to="{ name: 'Login'}">
                  <span class="sidebar-mini">L</span>
                  <span class="sidebar-normal">Logout</span>
                 </router-link>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition> -->
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions'
import { accountService } from '../../../../services/account.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      default: 'User Name',
    },
    rtlTitle: {
      type: String,
      default: 'تانيا أندرو',
    },
    avatar: {
      type: String,
      // default: "./img/faces/avatar.jpg"
    },
  },
  data() {
    return {
      isClosed: true,
      userRole: accountService.userRole(),
    }
  },
  methods: {
    clicked: function (e) {
      e.preventDefault()
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed
    },
  },

  computed: {
    userName() {
      return (
        this.$store.getters.getProfile.first_name +
        this.$store.getters.getProfile.last_name
      )
    },
    userImage() {
      return this.$store.getters.getProfile.logo
    },
  },
}
</script>
<style>
.collapsed {
  transition: opacity 1s;
}

.photo {
  background-color: white;
}

.user-name {
  color: #60616c;
}
</style>
