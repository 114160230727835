<template>
  <md-card class="ipad-grid">
    <md-card-header
      class="md-card-header-text md-card-header-green md-layout md-gutter"
    >
      <div class="md-layout-item md-size-70 header-content">
        <span class="arrow_left">
          <router-link :to="{ path: '/dashboard' }">
            <img src="../../../assets/images/left.svg" />
          </router-link>
        </span>
        <h3>iPad Grid</h3>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-25 pd-l">
          <div class="search-field">
            <md-field>
              <label>Search</label>
              <md-input
                v-on:keyup="search"
                v-model="searchKey"
                type="text"
              ></md-input>
            </md-field>
          </div>
          <div class="items-list ipdgrd-sidebar">
            <div>Items</div>

            <div class="loader-container" v-if="loader.mainCat">
              <clip-loader
                :loading="loader.mainCat"
                :color="'#00E0DB'"
              ></clip-loader>
            </div>

            <div class="items">
              <slot v-for="cat in mainCat">
                <div
                  class="main-cat"
                  :key="cat.id"
                  @click="cat.checkOpen = !cat.checkOpen"
                  v-bind:class="{ open: cat.checkOpen }"
                >
                  <md-checkbox
                    :model="mainCatId"
                    @change="catCheckbox(cat.id)"
                    :value="cat.id"
                    class="main-catgory-chckbox"
                  ></md-checkbox>
                  <!-- insert here -->
                  <div class="main-cat-name">{{ cat.name }}</div>
                  <div
                    class="item-color cat-color"
                    v-bind:style="{ background: cat.code_color }"
                    @click="currentSwatch(cat, $event, 'main cat')"
                  ></div>
                  <div class="arrow"></div>
                </div>
                <md-list>
                  <div v-show="cat.checkOpen">
                    <md-list-item
                      v-for="(item, index) in searched.filter(
                        (it) =>
                          it.type == 'menu item' &&
                          it.main_category_id == cat.id
                      )"
                      :key="index"
                    >
                      <md-checkbox
                        :model="selectedItems"
                        :value="item.id"
                        class="main-catgory-chckbox"
                      ></md-checkbox>
                      <div class="sub-cat-name">
                        {{ item.name }}
                      </div>
                      <span
                        v-show="conditionFlagGreen(item.id, item.type)"
                        class="dot"
                      ></span>
                      <!-- <div class="form__field">
                                    <div class="form__input">
                                        <swatches v-model="item.color"  @input="itemColorChange(item.id, item.color)" colors="text-advanced" popover-to="right"></swatches>
                                    </div>
                      </div>-->

                      <div
                        class="item-color"
                        v-bind:style="{ background: item.color }"
                        @click="currentSwatch(item, $event, 'items')"
                      ></div>
                    </md-list-item>
                  </div>
                </md-list>
                <!-- </div> -->
                <!-- insert here2 -->
              </slot>
            </div>

            <div
              class="item-header"
              @click="checkOpen.combos = !checkOpen.combos"
              v-bind:class="{ open: checkOpen.combos }"
            >
              <span>Combos</span>
              <div class="arrow"></div>
            </div>
            <div v-show="checkOpen.combos">
              <div class="loader-container" v-if="loader.combos">
                <clip-loader
                  :loading="loader.combos"
                  :color="'#00E0DB'"
                ></clip-loader>
              </div>

              <div class="combos">
                <md-list>
                  <md-list-item
                    v-for="(item, index) in searched.filter(
                      (it) => it.type == 'combo'
                    )"
                    :key="index"
                  >
                    <md-checkbox
                      :model="selectedItems"
                      :value="item.id"
                    ></md-checkbox>
                    {{ item.name }}
                    <span
                      v-show="conditionFlagGreen(item.id, item.type)"
                      class="dot"
                    ></span>
                    <!-- <div class="form__field">
                                    <div class="form__input">
                                        <swatches v-model="item.color" @input="comboColorChange(item.id, item.color)"  colors="text-advanced" popover-to="right"></swatches>
                                    </div>
                    </div>-->

                    <div
                      class="item-color"
                      v-bind:style="{ background: item.color }"
                      @click="currentSwatch(item, $event, 'items')"
                    ></div>
                  </md-list-item>
                </md-list>
              </div>
            </div>

            <div
              class="item-header"
              @click="checkOpen.menuPages = !checkOpen.menuPages"
              v-bind:class="{ open: checkOpen.menuPages }"
            >
              <span>Menu Pages</span>
              <div class="arrow"></div>
            </div>
            <div v-show="checkOpen.menuPages">
              <div class="loader-container" v-if="loader.menuCat">
                <clip-loader
                  :loading="loader.menuCat"
                  :color="'#00E0DB'"
                ></clip-loader>
              </div>
              <div class="combos">
                <md-list>
                  <md-list-item
                    v-for="(item, index) in searched.filter(
                      (it) => it.type == 'tab'
                    )"
                    :key="index"
                  >
                    <md-checkbox
                      :model="selectedItems"
                      :value="item.id"
                    ></md-checkbox>
                    {{ item.name }}
                    <span
                      v-show="conditionFlagGreen(item.id, item.type)"
                      class="dot"
                    ></span>
                    <!-- <div class="form__field">
                                    <div class="form__input">
                                        <swatches v-model="item.color" @input="catColorChange(item.id, item.color)" colors="text-advanced" popover-to="right"></swatches>
                                    </div>
                    </div>-->

                    <div
                      class="item-color"
                      v-bind:style="{ background: item.color }"
                      @click="currentSwatch(item, $event, 'items')"
                    ></div>
                  </md-list-item>
                </md-list>
              </div>
            </div>
          </div>
        </div>
        <div
          class="md-layout-item md-size-5 md-medium-100 move-keys position-relative d-flex justify-content-center align-items-center pd-l"
        >
          <div class="move-item-icon">
            <i class="fas fa-angle-double-right" @click="moveItems"></i>
          </div>
        </div>
        <div class="md-layout-item md-size-70 ipad">
          <div class="ipad-workspace" @click="rightClick = false">
            <div class="sort-section">
              <div class="sort-btn" @click="showSort">Sort</div>
              <div class="sort-order" v-if="activateSort">
                <div
                  class="sort-option"
                  @click="sortOrder(1)"
                  :class="{ 'current-sort': currentSort == 1 }"
                >
                  Ascending
                </div>
                <div
                  class="sort-option"
                  @click="sortOrder(2)"
                  :class="{ 'current-sort': currentSort == 2 }"
                >
                  Descending
                </div>
                <div
                  class="sort-option"
                  @click="sortOrder(3)"
                  :class="{ 'current-sort': currentSort == 3 }"
                >
                  Random
                </div>
              </div>
            </div>
            <div class="tab-section">
              <i class="fas fa-plus-circle" @click="addTab"></i>
              <div class="tabs">
                <div class="add-remove">
                  <!-- <draggable class="list-group" :list="tabs" group="people" :move="enableDrag"> -->
                  <!-- <div class="tab" v-for="(item,index) in tabs" :key="index" @click="activateTab(item.id)"> -->
                  <!-- <div class="tab">bla</div> -->
                  <!-- <span class="close-btn" @click="removeTab(item.name)"><i class="far fa-times-circle"></i></span> -->
                  <!-- </div> -->
                  <!-- </draggable> -->
                </div>
              </div>
            </div>

            <div class="item-section">
              <div class="loader-container" v-if="loader.ipad">
                <clip-loader
                  :loading="loader.ipad"
                  :color="'#00E0DB'"
                ></clip-loader>
              </div>

              <div class="items">
                <draggable
                  class="list-group"
                  :list="itemsInGrid"
                  group="people"
                  :move="enableDrag"
                >
                  <slot v-for="(item, index) in itemsInGrid">
                    <slot v-if="item.type == 'tab'">
                      <div
                        class="item tab-item"
                        v-bind:style="{ 'border-bottom-color': item.color }"
                        v-if="item.ipad_category_id == activeTab"
                        :key="index"
                      >
                        <div class="item-name">
                          <div class="item-name-grid">{{ item.name }}</div>
                          <span class="close-btn" @click="removeItem(item.id)">
                            <i class="far fa-times-circle"></i>
                          </span>
                        </div>
                      </div>
                    </slot>
                    <slot v-else>
                      <div
                        class="item"
                        v-bind:style="{ background: item.color }"
                        v-if="item.ipad_category_id == activeTab"
                        :key="index"
                      >
                        <div class="item-name">
                          <div class="item-name-grid">{{ item.name }}</div>
                          <span class="close-btn" @click="removeItem(item.id)">
                            <i class="far fa-times-circle"></i>
                          </span>
                        </div>
                      </div>
                    </slot>
                  </slot>
                </draggable>
              </div>
            </div>
          </div>

          <div class="md-layout-item md-size-100">
            <div class="md-layout-item submit-btn">
              <md-button
                class="md-primary"
                @click="submitGrid"
                :disabled="btnDisabled"
                >SAVE</md-button
              >
            </div>
          </div>
        </div>
        <div
          class="colors-popup"
          v-bind:style="{ top: swatchPosition }"
          v-if="activateSwatch"
        >
          <div class="pop-cancel" @click="activateSwatch = false">
            <i class="fas fa-window-close"></i>
          </div>
          <div class="form__field">
            <div class="form__input">
              <swatches
                v-model="swatchActive.color"
                @input="swatchColorChange(swatchActive, swatchActive.color)"
                colors="text-advanced"
                inline
              ></swatches>
            </div>
          </div>
        </div>
      </div>
    </md-card-content>

    <div
      class="right-click-menu"
      ref="rightclick"
      :style="{ top: top, left: left }"
      v-on:blur="rightClick = false"
    >
      <ul class="right-click-menu-list" v-if="rightClick">
        <li @click="insertTabInSlick('before')">Insert Before</li>
        <li @click="insertTabInSlick('after')">Insert After</li>
      </ul>
    </div>
  </md-card>
</template>

<script>
import { customizeService } from '../../../services/customize.js'
import VueSlickCarousel from 'vue-slick-carousel'
import draggable from 'vuedraggable'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'jquery-ui-dist/jquery-ui'
import Swatches from 'vue-swatches'
import { Promise, reject } from 'q'
import { accountService } from '../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Vue from 'vue'

// const app = new Vue(App)

// function activate() {
//     console.log("Activare function")
// }

export default {
  components: {
    VueSlickCarousel,
    draggable,
    Swatches,
    ClipLoader,
  },
  data() {
    return {
      self: this,
      items: [],
      combos: [],
      menu_items: [],
      categories: [],
      selectedItems: [],
      tabs: [],
      itemsInGrid: [],
      activeTab: null,
      drag: true,
      btnDisabled: false,
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      searched: [],
      searchKey: null,
      activateSort: false,
      currentSort: 1,
      outlet_id: accountService.getOutletId(),
      chain_id: accountService.getChainId(),
      swatchActive: {},
      activateSwatch: false,
      swatchPosition: '',
      mainCat: [],
      mainCatId: [],
      swatchCat: '',
      selectFlagGreen: [{ id: null, type: '' }],
      loader: {
        menuItems: false,
        mainCat: false,
        combos: false,
        menuCat: false,
        ipad: false,
      },
      checkOpen: { combos: false, menuPages: false },
      rightClick: false,
      top: '0px',
      left: '0px',
      rightClickCatId: '',
    }
  },

  methods: {
    dropDown() {
      // var self = this
      // self.$nextTick(()=> {
      //   self.checkOpen = !self.checkOpen
      // })
      this.checkOpen = !this.checkOpen
    },
    search() {
      this.searched = this.items.filter((el) => {
        return el.name.search(new RegExp(this.searchKey, 'i')) != -1
      })
    },

    showSort() {
      this.activateSort = !this.activateSort
    },

    catCheckbox(catId) {
      if (this.mainCatId.includes(catId)) {
        //checking if the option is checked or unchecked
        this.items.forEach((el) => {
          if (el.item_group == catId) {
            this.selectedItems.push(el.id)
          }
        })
      } else {
        this.items.forEach((el) => {
          //Unchecked
          if (el.item_group == catId) {
            let index = this.selectedItems.findIndex((item) => item == el.id)
            this.selectedItems.splice(index, 1)
          }
        })
      }
    },

    currentSwatch(item, event, swatchCat) {
      this.swatchPosition = event.clientY - 150 + 'px'
      this.activateSwatch = true
      this.swatchActive = item
      this.swatchCat = swatchCat
      if (this.swatchCat == 'main cat') {
        this.swatchActive.color = item.code_color
      }
    },

    swatchColorChange(item, color) {
      if (this.swatchCat == 'items') {
        let matchedIndex = this.items.findIndex((el) => el == item)
        let matchedItem = this.items[matchedIndex]
        matchedItem.color = color
        Vue.set(this.items, matchedIndex, matchedItem) // div elements are not reactive
        if (item.type == 'menu item') {
          this.itemColorChange(item.id, color)
        } else if (item.type == 'combo') {
          this.comboColorChange(item.id, color)
        } else if (item.type == 'tab') {
          this.catColorChange(item.id, color)
        }
      } else if (this.swatchCat == 'main cat') {
        this.mainCatColorChange(item.id, color)
      }

      this.activateSwatch = false
    },

    sortOrder(order) {
      //ascending
      this.currentSort = order
      if (order == 1) {
        this.tabs.sort(function (a, b) {
          if (a.name > b.name) {
            return 1
          } else {
            return -1
          }
        })
        $('.add-remove').attr('class', 'add-remove')
        $('.add-remove').empty()
        this.configureSlick()
        this.updateTabSlick()
      } else if (order == 2) {
        this.tabs.sort(function (a, b) {
          if (a.name > b.name) {
            return -1
          } else {
            return 1
          }
        })
        $('.add-remove').attr('class', 'add-remove')
        $('.add-remove').empty()
        this.configureSlick()
        this.updateTabSlick()
      } else if (order == 3) {
        //random order
        $('.slick-track').sortable('enable')
      }
    },

    sortMe(a, b) {
      return a.className < b.className
    },

    activateTab(id) {
      this.activeTab = id
      $('.tab').removeClass('borderactive')
      $('#' + id).addClass('borderactive')
    },

    itemColorChange(id, color) {
      customizeService
        .change_item_color(color, id)
        .then((res) => {
          this.itemsInGrid.forEach((el, i) => {
            if (el.type == 'menu item' && el.id == id) {
              this.itemsInGrid[i].color = color
            }
          })
        })
        .catch((err) => {
          alert('color change error')
        })
    },

    comboColorChange(id, color) {
      customizeService.change_combo_color(color, id).then((res) => {
        this.itemsInGrid.forEach((el, i) => {
          if (el.type == 'combo' && el.id == id) {
            this.itemsInGrid[i].color = color
          }
        })
      })
    },

    catColorChange(id, color) {
      customizeService.change_category_color(color, id).then((res) => {
        this.itemsInGrid.forEach((el, i) => {
          if (el.type == 'tab' && el.id == id) {
            this.itemsInGrid[i].color = color
          }
        })
      })
    },

    mainCatColorChange(id, color) {
      customizeService.main_category_color(color, id).then((res) => {
        let matchedCatIndex = this.mainCat.findIndex((el) => el.id == id)
        let matchedCatItem = this.mainCat[matchedCatIndex]
        matchedCatItem.code_color = color
        Vue.set(this.mainCat, matchedCatIndex, matchedCatItem)
        this.items.forEach((item, i) => {
          if (item.item_group == id) {
            let matchedItem = this.items[i]
            matchedItem.color = color
            Vue.set(this.items, i, matchedItem)
          }
        })
      })
    },

    updateTabSlick() {
      this.tabs.forEach((el) => {
        let bgColor = el.category_color
        if (bgColor == null) {
          bgColor = '#ffff'
        }
        $('.add-remove').slick(
          'slickAdd',
          '<div class="tab" id="' +
            el.id +
            '" ><div class="tab-close"><i class="far fa-times-circle"></i></div>' +
            el.name +
            '</div>'
        )
      })
      this.activateTab(this.tabs[0].id)
      $('.add-remove .tab:first').addClass('borderactive')
    },

    insertTabInSlick(order) {
      this.rightClick = false
      let matchedIndex
      if (order == 'before') {
        matchedIndex = this.tabs.findIndex(
          (tab) => tab.id == this.rightClickCatId
        )
      } else {
        matchedIndex =
          this.tabs.findIndex((tab) => tab.id == this.rightClickCatId) + 1
      }

      const catPromise = customizeService
        .fetchIpadCategory(this.tabs.length + 1)
        .then((res) => {
          this.tabs.splice(matchedIndex, 0, {
            name: res.data.name,
            id: res.data.id,
          })
          this.items.push({
            id: res.data.id,
            name: res.data.name,
            color: '#ffff',
            type: 'tab',
          })
          this.selectFlagGreen.push({ id: res.data.id, type: 'tab' })
          $('.add-remove').attr('class', 'add-remove')
          $('.add-remove').empty()
          this.configureSlick()
          this.updateTabSlick()
        })
    },

    addTab(tabIndex) {
      const catPromise = new Promise((resolve, reject) => {
        customizeService.fetchIpadCategory(this.tabs.length + 1).then((res) => {
          this.tabs.push({
            name: res.data.name,
            id: res.data.id,
          })

          // display created tabs in left pane
          this.items.push({
            id: res.data.id,
            name: res.data.name,
            color: '#ffff',
            type: 'tab',
          })
          this.selectFlagGreen.push({ id: res.data.id, type: 'tab' })
          resolve()
        })
      })

      Promise.all([catPromise]).then(() => {
        $('.add-remove').slick(
          'slickAdd',
          '<div class="tab" id="' +
            this.tabs[this.tabs.length - 1].id +
            '" ><div class="tab-close"><i class="far fa-times-circle"></i></div>' +
            this.tabs[this.tabs.length - 1].name +
            '</div>'
        )
        this.activateTab(this.tabs[this.tabs.length - 1].id)
      })
    },

    updateTab(id, tabName) {
      let tabIndex = this.tabs.findIndex((el) => el.id == id)
      customizeService.change_category_name(tabName, id).then((res) => {
        this.tabs[tabIndex].name = tabName
      })
    },

    removeTab(id) {
      let index = this.tabs.findIndex((el) => el.id == id)
      this.tabs.splice(index, 1)

      let slickElements = $('.slick-track')
        .sortable('toArray')
        .filter(function (id) {
          return id != ''
        })

      customizeService.delete_category(id).then((res) => {
        let slideIndex = slickElements.findIndex((item) => item == id)
        $('.add-remove').slick('slickRemove', slideIndex)
        if (slideIndex !== 0) {
          slideIndex--
        }

        let tabIndex = this.items.findIndex(
          (el) => el.type == 'tab' && el.id == id
        )
        this.items.splice(tabIndex, 1)
      })
    },

    assignPosition(id, tab) {
      let tabElements = this.itemsInGrid.filter((el) => {
        return el.ipad_category_id == tab
      })
      let position = tabElements.findIndex((el) => el.id == id)
      return position
    },

    moveItems() {
      if (this.activeTab != null) {
        this.selectedItems.forEach((el) => {
          let matchedItem = this.items.find((item) => item.id == el)
          if (matchedItem.color == null) {
            matchedItem.color = '#ffff' // Setting white background for colorless elements
          }
          this.itemsInGrid.push({
            id: matchedItem.id,
            name: matchedItem.name,
            type: matchedItem.type,
            color: matchedItem.color,
            ipad_category_id: this.activeTab,
          })
          this.selectFlagGreen.push({
            id: matchedItem.id,
            type: matchedItem.type,
          })
        })
        this.selectedItems = []
      } else {
        alert('Create tabs')
      }
    },

    configureSlick() {
      let vm = this
      $('.add-remove')
        .slick({
          slidesToShow: 4,
          slidesToScroll: 4,
          swipe: false,
        })
        .disableSelection()

      $('.slick-track').sortable({
        start: function (event, ui) {
          // vm.activateTab(ui.item.attr('id'))
        },
        stop: function (event, ui) {
          if (ui.position.top > 120) {
            vm.addCustomTab(ui.item.attr('id'))
          }
        },
      })
    },

    enableDrag() {
      return this.drag
    },

    conditionFlagGreen(itemId, type) {
      if (this.selectFlagGreen.some((el) => el.id == itemId && el.type == type))
        return true
      else return false
    },

    removeItem(id) {
      let matchedIndex = this.itemsInGrid.findIndex((el) => el.id == id)
      let flagmatchedIndex = this.selectFlagGreen.findIndex(
        (el) =>
          el.id == this.itemsInGrid[matchedIndex].id &&
          el.type == this.itemsInGrid[matchedIndex].type
      )
      this.selectFlagGreen.splice(flagmatchedIndex, 1)
      this.itemsInGrid.splice(matchedIndex, 1)
    },

    addCustomTab(tabId) {
      let item = this.tabs.find((tab) => tab.id == tabId)
      if (item.category_color == null) {
        item.category_color = '#ffff'
      }
      this.itemsInGrid.push({
        id: item.id,
        name: item.name,
        type: 'tab',
        color: item.category_color,
        ipad_category_id: this.activeTab,
      })
    },

    submitGrid() {
      this.btnDisabled = true
      if (this.tabs.length <= 0) {
        this.itemsInGrid = []
      }
      let duplicateGrid = JSON.parse(JSON.stringify(this.itemsInGrid))
      duplicateGrid.forEach((el) => {
        el.ipad_position = this.assignPosition(el.id, el.ipad_category_id)
        if (el.type == 'menu item') {
          el.menu_item_id = el.id
        } else if (el.type == 'combo') {
          el.combo_id = el.id
        } else if (el.type == 'tab') {
          el.ipad_menu_page_id = el.id
        } else if (el.type == 'main category') {
          el.main_category_id = el.id
        }
        el.outlet_id = this.outlet_id
        delete el.id
        delete el.type
        delete el.color
        delete el.name
      })

      let slickElements = $('.slick-track')
        .sortable('toArray')
        .filter(function (id) {
          return id != ''
        })
      let categoryArray = {}

      slickElements.forEach((el, index) => {
        categoryArray[el] = {
          sort_order: index + 1,
        }
      })

      let data = {
        grid: [
          {
            chain_id: this.chain_id,
            outlet_id: this.outlet_id,
            ipad: duplicateGrid,
            category: categoryArray,
          },
        ],
      }
      customizeService.saveGrid(data).then((res) => {
        alert('iPad grid saved')
        this.btnDisabled = false
      })
    },

    assignGrid(grid) {
      let tabArray = []
      let gridArray = []
      grid.forEach((el) => {
        if (!tabArray.includes(el.ipad_category_id)) {
          tabArray.push(el.ipad_category_id) //creating an array with only ipad_category id
        }
      })
      for (let i = 0; i < tabArray.length; i++) {
        let elementsInTab = []
        grid.forEach((el) => {
          if (el.ipad_category_id == tabArray[i]) {
            elementsInTab.push(el)
            elementsInTab.sort((a, b) => {
              return a.ipad_position - b.ipad_position //sorting elements with same ipad_category_id based on their position
            })
          }
        })
        elementsInTab.forEach((el) => gridArray.push(el)) //consolidating individual arrays to grid array
      }

      this.itemsInGrid = gridArray.map((item) => {
        if (item.menu_item_id != undefined) {
          let matchedItem = this.menu_items.find(
            (el) => el.id == item.menu_item_id
          )
          if (matchedItem == undefined) {
          } else {
            let gridItem = {
              id: item.menu_item_id,
              name: matchedItem.name,
              type: 'menu item',
              color: item.colour,
              ipad_category_id: item.ipad_category_id,
            }
            if (gridItem.color == null) {
              gridItem.color = '#ffff'
            }
            return gridItem
          }
        } else if (item.combo_id != undefined) {
          let matchedCombo = this.combos.find((el) => el.id == item.combo_id)
          let gridItem = {
            id: item.combo_id,
            name: matchedCombo.name,
            type: 'combo',
            color: item.colour,
            ipad_category_id: item.ipad_category_id,
          }
          if (gridItem.color == null) {
            gridItem.color = '#ffff'
          }
          return gridItem
        } else if (item.ipad_menu_page_id != undefined) {
          let matchedPage = this.tabs.find(
            (el) => el.id == item.ipad_menu_page_id
          )
          let gridItem = {
            id: item.ipad_menu_page_id,
            name: matchedPage.name,
            type: 'tab',
            color: item.colour,
            ipad_category_id: item.ipad_category_id,
          }
          if (gridItem.color == null) {
            gridItem.color = '#ffff'
          }
          return gridItem
        }
      })
      this.itemsInGrid = this.itemsInGrid.filter(function (item) {
        return item !== undefined
      })
      this.selectFlagGreen = this.itemsInGrid.map((item) => {
        return { id: item.id, type: item.type }
      })
      this.loader.ipad = false
      this.updateTabSlick()
    },
  },

  created() {
    this.searched = this.items
    this.loader.ipad = true

    const p1 = new Promise((resolve, reject) => {
      this.loader.menuItems = true
      customizeService.getIpadMenuItems().then((res) => {
        let result = res.data.filter((item) => item.status == 'active')
        this.menu_items = result
        result.forEach((el) => {
          el.type = 'menu item'
          el.color = el.item_color
        })
        let items = result
        items.forEach((el) => {
          this.items.push(el)
        })
        this.loader.menuItems = false
        resolve()
      })
    })

    const p2 = new Promise((resolve, reject) => {
      this.loader.combos = true
      customizeService.getCombo().then((res) => {
        this.combos = res.data
        res.data.forEach((el) => {
          el.type = 'combo'
          if (el.combo_color != null) {
            el.color = el.combo_color
          } else {
            el.color = '#ffff'
          }
        })
        let combos = res.data
        combos.forEach((el) => {
          this.items.push(el)
        })
        this.loader.combos = false
        resolve()
      })
    })

    const p3 = new Promise((resolve, reject) => {
      this.loader.menuCat = true
      customizeService.get_categories(this.outlet_id).then((result) => {
        this.tabs = result.data
        this.tabs.sort((a, b) => {
          return a.sort_order - b.sort_order
        })
        result.data.forEach((el) => {
          let tabObj = {
            id: el.id,
            name: el.name,
            color: el.category_color,
            type: 'tab',
          }
          this.items.push(tabObj)
        })
        this.loader.menuCat = false
        resolve()
      })
    })

    this.loader.mainCat = true
    customizeService.getMaincat().then((res) => {
      this.loader.mainCat = false
      const result = res.data.api_v1_main_category.filter(
        (el) => el.status == true
      )
      result.forEach((el) => {
        ;(el.type = 'main category'),
          (el.color = el.code_color),
          (el.checkOpen = false)
        this.mainCat.push(el)
      })
    })

    Promise.all([p2, p3, p1]).then(() => {
      customizeService.get_grid(this.outlet_id).then((res) => {
        this.assignGrid(res.data)
      })
    })
  },

  mounted() {
    var vm = this

    this.configureSlick()
    // $('.slick-track').draggable()

    $('.slick-track').on('dragover', '.tab', (el) => {
      // console.log('Dropped element:', el.target)
    })

    $('.add-remove').on('contextmenu', '.tab', (el) => {
      // custom right click
      el.preventDefault()
      vm.rightClickCatId = el.target.id
      let posY = el.pageY - vm.$refs.rightclick.offsetHeight - 80
      let posX = el.pageX - vm.$refs.rightclick.offsetWidth - 80
      vm.top = posY + 'px'
      vm.left = posX + 'px'
      vm.rightClick = true
    })

    $('.add-remove').on('click', '.tab', (el) => {
      // activate clicked tab
      if (el.target.id) {
        $('.tab').removeClass('borderactive')
        $(el.target).addClass('borderactive')
        this.activateTab(el.target.id)
      }
    })

    $('.add-remove').on('click', '.fa-times-circle', (el) => {
      let tabId = $(el.target).parent().parent().attr('id')
      vm.removeTab(tabId)
    })

    $('.add-remove').on('dblclick', '.tab', function () {
      var currentElement = $(this)
      var elementId = $(this).attr('id')
      $(currentElement).html(
        '<input class="tab-input" type="text" value="' +
          currentElement.text() +
          '" />'
      )
      $('.tab-input').focus()

      $('.tab-input').on('focusout', function (event) {
        var inputVal = $('.tab-input').val()
        $('.tab-input').remove()
        $(currentElement).html(inputVal)
        vm.updateTab(elementId, inputVal)
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.md-list-item {
  .md-ripple {
    position: relative;
  }
}
.dot {
  padding-top: 2px;
  /*height: 10px;
  width: 10px;*/
  height: 7px;
  width: 7px;
  top: 47% !important;
  left: -2px !important;
  padding-top: 7px !important;
  border-radius: 50%;
  display: inline-block;
  background-color: #16b402b4;
}
.arrow {
  position: relative;
  height: 15px;
  width: 15px;
  // margin: auto;
  margin-right: 0.25rem;
  margin-top: auto;
  margin-bottom: auto;
}

.arrow::before,
.arrow::after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.5s;
}

.arrow::before {
  left: -5px;
  transform: rotate(45deg);
  background-color: #fff;
}
.arrow::after {
  left: 5px;
  transform: rotate(-45deg);
  background-color: #fff;
}
.open .arrow::before {
  left: -5px;
  transform: rotate(-45deg);
}
.open .arrow::after {
  left: 5px;
  transform: rotate(45deg);
}

.ipad {
  height: 600px;
  // background-image: url('../../../assets/images/ipad-fliped-bt-top.png');
  // background-repeat: no-repeat;
  // background-size: 900px 700px;
  border: 1px;
  border-style: groove;
  padding: 0px !important;
  background: black;
  border-radius: 10px;
}

.ipad-workspace {
  // padding-left: 50px;
  // padding-top: 10%;
  // padding-right: 50px;
  // padding-bottom: 10%;
  height: 100%;
  /* margin-right: 186px; */
  width: 100%;

  .tab-section {
    background-color: #49e0db;
    height: 66px;
    width: 100%;
    display: flex;
    justify-content: center;
    .fa-plus-circle {
      font-size: 35px;
      padding: 13px;
      color: white;
      padding-left: 0px;
    }

    .tabs {
      margin-left: 35px;
      display: flex;
    }
  }

  .item-section {
    height: 62%;
    width: 100%;
    /*overflow-y: scroll;*/
    margin-top: 2px;
    overflow: auto;
  }

  .items {
    display: flex;
    /*margin-left: 150px;*/
    margin-left: 120px;
    justify-content: center;
    // @media (min-width: 1200px) and (max-width: 1400px){
    //     margin-left: 57px;
    // }
    .item {
      margin-right: 2px;
      margin-bottom: 2px;
      /*height: 60px;
      width: 98px;*/
      height: 70px;
      width: 126.8px;
      position: relative;
      @media (min-width: 1200px) and (max-width: 1400px) {
        width: 115.8px;
      }
      .item-name {
        font-size: 12px;
      }

      .item-name-grid {
        position: absolute;
        bottom: 0;
        padding: 5px;
        color: #000;
      }

      .close-btn {
        position: absolute;
        right: 0;
        padding-right: 5px;
      }
    }
  }
}

.list-group {
  display: flex;
  flex-wrap: wrap;
  max-width: 535px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.fa-angle-double-right {
  margin: auto;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  color: #fff;
  font-size: 30px;
  // margin-left: 2%;
}

.fa-angle-double-right:hover {
  color: #49e0db;
}
.fa-times-circle:before {
  color: #ff0000;
}
.add-remove {
  width: 520px;
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 430px;
  }
  .draggable {
    background: white;
  }
}

.form__field {
  position: absolute;
  right: 0px;
  top: 5px;
}

.items-list {
  height: 600px;
  overflow-y: scroll;
}

.sort-section {
  margin-top: 40px;
  color: white;
  background-color: transparent;
  display: flex;
}

.sort-btn {
  background: #49e0db;
  margin: 0 0 5px 0;
  width: 15%;
  text-align: center;
  border-radius: 2px;
  color: #000;
}

.sort-order {
  display: flex;
  padding: 5px;
}

.sort-option {
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  background: #434343;
  margin-left: 5px;
  font-size: 10px;
}

.current-sort {
  background: #237dbc;
}

.header-content {
  display: flex;
}

.borderactive {
  border-bottom-style: solid;
  border-bottom-color: #ff1744;
  border-bottom-width: 6px;
}

.item-color {
  float: right;
  width: 32px;
  margin-left: 5px;
  // margin-right: 16px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
}

.colors-popup {
  position: absolute;
  /*left: 400px;*/
  left: 359px;
  z-index: 999;
  height: 100px;

  .fa-window-close {
    z-index: 999;
    color: #ec3232;
    position: absolute;
    left: 196px;
    font-size: 19px;
  }
}

.tab-item {
  border-bottom-style: solid;
  border-bottom-width: 27px;
  background-color: white;
}

.item-header {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
  cursor: pointer;
  padding: 10px 0 5px;
}

.main-cat {
  display: flex;
  padding-left: 14px;
  background: #49e0db;

  .cat-color {
    margin-left: auto;
    margin-right: 15px;
    margin-top: 5px;
  }
}

.main-cat-name {
  padding: 14px;
  color: #000;
  width: 170px;
}

.submit-btn {
  text-align: center;
  padding-top: 20px;
}

.right-click-menu {
  position: absolute;
  color: #3d4858;
  background: #fff;
  border-radius: 7px;
  border: solid 1px #4e92c3;
}

.right-click-menu-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: 12px;
  li {
    padding: 6px;
    border-bottom: solid 1px #4e92c3;
    cursor: pointer;
  }
}
.ipdgrd-sidebar {
  .main-cat {
    .main-cat-name {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    .md-checkbox {
      &.main-catgory-chckbox {
        margin-top: 5px;
        margin-bottom: 0px;
        .md-checkbox {
          border: 1px solid #ff1744 !important;
          &:before {
            height: 20px;
          }
        }
      }
    }
    .arrow {
      width: 10px;
      height: 10px;
      &::before {
        left: -3px;
        background-color: #000;
      }
      &::after {
        left: 3px;
        background-color: #000;
      }
    }
  }
  .md-list {
    padding: 1px 0;
  }
}
.ipad-workspace {
  padding-left: 25px;
  padding-right: 25px;
}
</style>
<style>
.md-checkbox .md-checkbox-container {
  background: #ffffff;
}
.ipad-workspace .slick-next {
  right: -32px;
}
.sub-cat-name {
  width: 100%;
}
.ipad-grid .vue-swatches__container {
  box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
}
.vue-swatches__wrapper {
  padding: 13px;
}
.vue-swatches__swatch {
  margin: 7px !important;
}
.vue-swatches__swatch:nth-child(4n + 4) {
  margin-right: 0 !important;
}
.vue-swatches__swatch:nth-child(4n + 1) {
  margin-left: 0 !important;
}
.tab-input {
  width: 100%;
}
@media (min-width: 1750px) {
  .ipad-grid .ipad {
    min-width: 50%;
    max-width: 50%;
  }
}
</style>
