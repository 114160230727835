<template>
  <div v-if="open">
    <slot />
  </div>
</template>

<script>
import html2canvas from 'html2canvas'

function copyStyles(sourceDoc, targetDoc) {
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    try {
      // if (styleSheet.hasOwnProperty('cssRules')) {
      if (styleSheet.cssRules) {
        // for <style> elements
        const newStyleEl = sourceDoc.createElement('style')
        newStyleEl.crossorigin = 'anonymous'

        Array.from(styleSheet.cssRules).forEach((cssRule) => {
          // write the text of each rule into the body of the style element
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText))
        })

        targetDoc.head.appendChild(newStyleEl)
      } else if (styleSheet.href) {
        // for <link> elements loading CSS from a URL
        const newLinkEl = sourceDoc.createElement('link')

        newLinkEl.rel = 'stylesheet'
        newLinkEl.href = styleSheet.href
        newLinkEl.crossorigin = 'anonymous'
        targetDoc.head.appendChild(newLinkEl)
      }
    } catch (err) {
      console.log(err)
    }
  })
}

export default {
  name: 'window-portal',
  model: {
    prop: 'open',
    event: 'close',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowRef: null,
      msg: '',
    }
  },
  watch: {
    open(newOpen) {
      if (newOpen) {
        this.openPortal()
      } else {
        this.closePortal()
      }
    },
  },
  methods: {
    openPortal(data) {
      var _self = this
      this.msg = data
      this.windowRef = window.open(
        '',
        '',
        'width=610,height=1000,left=200,top=200'
      )
      let btn = this.windowRef.document.createElement('button')
      btn.innerHTML = 'Download'
      btn.id = 'eod-download'
      btn.onclick = function () {
        html2canvas(_self.windowRef.document.body)
          .then((canvas) => {
            var list = _self.windowRef.document.getElementsByTagName('canvas')
            //you can also do this with classes.

            while (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                list[i].remove()
              }
            }
            _self.windowRef.document.body.appendChild(canvas)
          })
          .then(() => {
            var canvas = _self.windowRef.document.querySelector('canvas')
            canvas.style.display = 'none'
            var image = canvas
              .toDataURL('image/png')
              .replace('image/png', 'image/octet-stream')
            var a = _self.windowRef.document.createElement('a')
            a.setAttribute('download', 'EOD' + '.png')
            a.setAttribute('href', image)
            a.click()
            a.remove()
          })
        return false
      }

      this.windowRef.document.body.appendChild(this.$el)
      console.log(window.document)
      this.windowRef.document.body.prepend(btn)

      copyStyles(window.document, this.windowRef.document)
      this.windowRef.addEventListener('beforeunload', this.closePortal)
    },

    async closePortal() {
      if (this.windowRef) {
        this.windowRef.close()
        this.windowRef = null
        this.$emit('close')
      }
    },
    downloadEod() {
      html2canvas(document.querySelector('#eod-canvas'), {
        useCORS: true,
        allowTaint: true,
        scrollY: -window.scrollY,
      })
        .then((canvas) => {
          document.body.appendChild(canvas)
        })
        .then(() => {
          var canvas = document.querySelector('canvas')
          canvas.style.display = 'none'
          console.log(canvas)
          var image = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
          var a = document.createElement('a')
          a.setAttribute('download', 'eod' + '.png')
          a.setAttribute('href', image)
          a.click()
          a.remove()
        })
    },
  },
  mounted() {
    if (this.open) {
      this.openPortal()
    }
  },
  beforeDestroy() {
    if (this.windowRef) {
      this.closePortal()
    }
  },
}
</script>
