<template>
  <div class="md-layout re-printer-table">
    <div class="md-layout-item">
      <md-card class="pagination-card block-bg pad-l-15px pad-r-15px">
        <md-card-header class="md-card-header-icon md-card-header-green">
          <router-link
            :to="{
              name: 'RepeatPrinterForm',
              params: { formType: 'Add Re-Printer' },
            }"
          >
            <md-icon class="add-icon">add</md-icon>
          </router-link>
          <h3 class="title">{{ $t('Repeat Printers') }}</h3>
        </md-card-header>
        <md-card-content>
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover mt-25"
            @md-selected="viewDetail"
            v-if="!loader"
          >
            <md-table-toolbar class="table-toolbar-align right-0 mt-15">
              <md-field>
                <label>{{ $t('Search') }}</label>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style=""
                  v-model="searchQuery"
                ></md-input>
                <i class="fa fa-search search-align" aria-hidden="true"></i>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell v-bind:md-label="'Printer-1'">
                <div @click="viewDetail(item)">
                  {{ item.printer_1 }}
                </div>
              </md-table-cell>
              <md-table-cell class="center" v-bind:md-label="'Printer-2'">
                <div @click="viewDetail(item)">
                  {{ item.printer_2 }}
                </div>
              </md-table-cell>
              <md-table-cell
                class="center"
                v-bind:md-label="$t('Print only if needed ')"
              >
                <div @click="viewDetail(item)">
                  <i class="fa fa-check check-align" aria-hidden="true"></i>
                </div>
              </md-table-cell>
              <md-table-cell class="center" v-bind:md-label="$t('Actions')">
                <div class="border-edit">
                  <md-menu
                    md-size="medium"
                    :md-offset-x="-227"
                    :md-offset-y="-16"
                  >
                    <i
                      class="material-icons text_align-center menu-icon"
                      md-menu-trigger
                      >more_vert</i
                    >
                    <md-menu-content class="menuitem-dropdown">
                      <md-menu-item class="action-header menu-align">{{
                        $t('Actions')
                      }}</md-menu-item>
                      <router-link
                        :to="{
                          name: 'RepeatPrinterForm',
                          params: {
                            formType: 'Edit Repeat Printer',
                            client: item.url,
                          },
                        }"
                      >
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fas fa-pencil-alt"></i>
                            {{ $t('Edit') }}
                          </span>
                        </md-menu-item>
                      </router-link>
                      <div>
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fa fa-ban" aria-hidden="true"></i>
                            {{ $t('Deactivate') }}
                          </span>
                        </md-menu-item>
                      </div>
                      <div>
                        <md-menu-item>
                          <span
                            class="edit-align"
                            @click="alert('Delete', item.url)"
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            {{ $t('Delete') }}
                          </span>
                        </md-menu-item>
                      </div>
                    </md-menu-content>
                  </md-menu>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- </md-card-actions> -->
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination } from '@/components'
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import axios from 'axios'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    Pagination,
    ClipLoader,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      currentSort: 'name',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      // footerTable: ["Name", "Email", "Age", "Salary", "Actions"],
      searchQuery: '',
      propsToSearch: ['name'],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
    }
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },

    alert(value, url) {
      var self = this
      if (value === 'Delete') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.deleteStation(url)
          }
        })
      }
    },

    deleteStation(url) {
      axios
        .delete(url, customizeService.authFun())
        .then((res) => {
          this.getItems()
        })
        .catch((err) => console.log(err))
    },

    viewDetail(item) {
      this.$router.push({
        name: 'RepeatPrinterForm',
        params: { formType: 'View Repeat Printer', client: item.url },
      })
    },

    getItems() {
      var self = this
      this.loader = true
      customizeService.getReprinter().then((res) => {
        this.loader = false
        self.tableData = res.data.reprinters
      })
    },
  },
  mounted() {},

  created() {
    this.getItems()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['name'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}
</style>
