var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-table"},[_c('md-card',{staticClass:"report-card block-bg"},[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-green md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-70"},[_c('span',{staticClass:"arrow_left"},[_c('router-link',{attrs:{"to":{ path: '/dashboard' }}},[_c('img',{attrs:{"src":require("../../../../assets/images/left.svg")}})])],1),_c('h3',[_vm._v(_vm._s(_vm.$t('Staff Sales Report')))])])]),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item filter-section pd-l-0 block-bg"},[_c('div',{staticClass:"md-layout search-options"},[_c('div',{staticClass:"md-layout-item md-size-60"},[_c('date-period-dropdown',{on:{"interval":_vm.setDateType}})],1),_c('div',{staticClass:"md-layout-item md-size-25 pd-l-0"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-50 pd-l-mbl-o pd-r-10 pd-l0-r15",class:[
                    { 'md-size-50': _vm.role() == 'branch_user' },
                    { 'md-size-50': _vm.role() != 'branch_user' } ]},[_c('md-autocomplete',{staticClass:"employes",attrs:{"md-options":_vm.customers},on:{"md-opened":_vm.getCustomers,"md-changed":_vm.getCustomers,"md-selected":_vm.getSelected},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}},[_c('label',[_vm._v("User")])])],1),(_vm.role() == 'branch_user')?_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-50 pd-l-10 pd-l-7 pd-l-mbl-o"},[_c('md-field',[_c('label',[_vm._v("Outlet")]),_c('md-select',{attrs:{"multiple":""},on:{"md-closed":function($event){_vm.outletId.length > 0
                          ? _vm.allMenuItemsReport()
                          : (_vm.outletId = [])}},model:{value:(_vm.outletId),callback:function ($$v) {_vm.outletId=$$v},expression:"outletId"}},_vm._l((_vm.outletList),function(item){return _c('md-option',{key:item.id,staticClass:"oulet-select-list-item",attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_vm._e()])]),_c('div',{staticClass:"md-layout-item md-size-15 pd-l-10"},[_c('md-button',{staticClass:"md-teritiary",on:{"click":function($event){return _vm.allMenuItemsReport()}}},[_vm._v("Search ")])],1)])]),(_vm.loader)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{staticClass:"md-layout-item md-size-100",attrs:{"loading":_vm.loader,"color":'#00E0DB'}})],1):_vm._e(),(!_vm.loader)?_c('div',{staticClass:"md-layout-item md-size-100 card-inside report-table"},[_c('div',{staticClass:"xlsx-pdf"},[_c('img',{staticClass:"pdf",attrs:{"src":require("../../../../assets/images/pdf.svg")},on:{"click":function($event){return _vm.downloadWithoutCSS()}}}),_c('vue-excel-xlsx',{staticClass:"excel-btn2 md-primary",attrs:{"data":_vm.reportApiRes,"columns":_vm.columns,"filename":'Staff Sales (All Menu Items)',"sheetname":'Staff Sales (All Menu Items)',"outlet-names":_vm.selectedOutletsNames()}})],1),_c('md-table',{staticClass:"rep-table table-typ1"},[_c('md-table-row',[_c('md-table-head',[_vm._v("SKU")]),_c('md-table-head',[_vm._v("Menu Item")]),_c('md-table-head',[_vm._v("Total Quantity ")]),_c('md-table-head',[_vm._v("Gross Sales")]),_c('md-table-head',[_vm._v("Discount")]),_c('md-table-head',[_vm._v("Gross Sales After Discount")]),_c('md-table-head',[_vm._v("Tax")]),_c('md-table-head',[_vm._v("Net Sales")])],1),_vm._l((_vm.reportApiRes),function(item){return _vm._t("default",[_c('md-table-row',{attrs:{"slot":"md-table-row"},slot:"md-table-row"},[_c('md-table-cell',{class:{
                    'text-bold': item.is_bold,
                  }},[_vm._v(_vm._s(item.sku))]),_c('md-table-cell',{class:{
                    'text-bold': item.is_bold,
                  }},[_vm._v(_vm._s(item.menu_item))]),_c('md-table-cell',{class:{
                    'text-bold': item.is_bold,
                  }},[_vm._v(_vm._s(item.total_quantity))]),_c('md-table-cell',{class:{
                    'text-bold': item.is_bold,
                  }},[_vm._v(_vm._s(item.gross_sales))]),_c('md-table-cell',{class:{
                    'text-bold': item.is_bold,
                  }},[_vm._v(_vm._s(item.discount))]),_c('md-table-cell',{class:{
                    'text-bold': item.is_bold,
                  }},[_vm._v(_vm._s(item.gross_sales_after_discount))]),_c('md-table-cell',{class:{
                    'text-bold': item.is_bold,
                  }},[_vm._v(_vm._s(item.tax))]),_c('md-table-cell',{class:{
                    'text-bold': item.is_bold,
                  }},[_vm._v(_vm._s(item.net_sale))])],1)])})],2)],1):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }