import { environment } from './../../config'
import axios from 'axios'
import { accountService } from '../services/account.js'
import store from '../store'
import account from '../store/modules/account'

var baseUrl = `${environment.api.host}`
var DynamicUrl = store.getters.getBaseUrl
let outletId = ''
//var accessToken=this.sessionStorage.getItem('access_token');
const auth = {
  headers: {
    Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
  },
}

export const reportService = {
  getHourlySales,
  getWeeklySales,
  getMonthlySales,
  getMenuItemReport,
  getMainCategoryReport,
  getSuperCategoryReport,
  getSuperCategoryByDate,
  getComboReport,
  getSubCatReport,
  getModifierItemReport,
  getModifierReport,
  getSalesGpReport,
  getItemTransactions,
  terminalTransactions,
  viewItemTransBill,
  getHourlyReport,
  getDailySales,
  getHourlyOrder,
  getYearlySales,
  getHourlySummaryReport,
  getDetailedTaxReport,
  getTaxReportBySalesCategory,
  getAllMenuItems,
  getItemCategories,
  getRevenue,
  getTaxSummary,
  getEndOfDay,
  getVoidReport,
  getVoidReportByStaff,
  getVoidReportAnalysys,
  getDiscountCategoryReport,
  getDiscountCatInvoice,
  getPettyCash,
  wastageSummary,
  wastageDetails,
  labourReportStaff,
  getvoucherAuditReport,
  getVoucherReport,
}

function authFun() {
  const auth = {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
    },
  }
  return auth
}

function getOutletId(data) {
  if (accountService.userRole() == 'branch_user') {
    return data.outletId
  } else {
    return accountService.getOutletId()
  }
}

/* ---- ======================Sales Summary =====================--- */

function getHourlySales(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.hourlysales}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&terminal_id=' +
      queries.terminal_id,
    authFun()
  )
}

function getWeeklySales(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.weeklysales}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=Weekly' +
      '&start_date=' +
      queries.fromDate +
      '&end_date=' +
      queries.endDate,
    authFun()
  )
}

function getMonthlySales(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.monthlysales}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=Monthly' +
      '&month=' +
      queries.month +
      '&year=' +
      queries.year,
    authFun()
  )
}

function getDailySales(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.dailysales}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=Daily' +
      '&start_date=' +
      queries.startDate,
    authFun()
  )
}

function getHourlyOrder(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.hourlyorder}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&terminal_id=' +
      queries.terminal_id +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate,
    authFun()
  )
}

function getYearlySales(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.yearlysales}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=Yearly' +
      '&year=' +
      queries.year,
    authFun()
  )
}

/* Product Report */

function getMenuItemReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.menuitemreport}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&menu_id=' +
      data.menuItemId +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getMainCategoryReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }

  return axios.get(
    baseUrl +
      `${environment.api.services.reports.maincategoryreport}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&grp_id=' +
      data.mainCatId +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getSuperCategoryReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }

  return axios.get(
    baseUrl +
      `${environment.api.services.reports.supercategoryreport}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&super_category_id=' +
      data.superCatId +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getSuperCategoryByDate(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }

  return axios.get(
    baseUrl +
      `${environment.api.services.reports.supercategorybydate}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getComboReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }

  return axios.get(
    baseUrl +
      `${environment.api.services.reports.comboreport}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&combo_id=' +
      data.comboId +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getSubCatReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.subcategoryreport}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&subcat=' +
      data.subCatId +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getModifierItemReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.modifieritemreport}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getModifierReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.modifiersreport}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getSalesGpReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.salesgpreport}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

/*--------==================================Transaction Summary=======================================-------------*/

function getItemTransactions(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.itemisedtransactions}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&terminal_id=' +
      queries.terminalId +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&type=' +
      queries.billType,
    authFun()
  )
}

function viewItemTransBill(billNo, outletId) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.itemisedtransactions}` +
      '?type=print' +
      '&bill_id=' +
      billNo +
      '&outlet_id=' +
      outletId,
    authFun()
  )
}

function getHourlyReport(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.hourlyreport}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate,
    authFun()
  )
}

function getHourlySummaryReport(value, data) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.hourlysummaryreport}` +
      '?outlet_id=' +
      getOutletId(data) +
      '&search_type=' +
      data.search_type +
      '&type=' +
      value +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate,
    authFun()
  )
}

function terminalTransactions(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.terminal_transactions}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate,
    authFun()
  )
}

/*--------==================================Tax Report=======================================-------------*/

function getTaxSummary(data) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.taxsummary}` +
      '?outlet_id=' +
      getOutletId(data) +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate,
    authFun()
  )
}

function getDetailedTaxReport(data) {
  if (data.taxId == 'All') data.taxId = 'undefined'
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.detailedtaxreport}` +
      '?outlet_id=' +
      getOutletId(data) +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&tax_number=' +
      data.taxId +
      '&item_group=' +
      data.superCatId,
    authFun()
  )
}

function getTaxReportBySalesCategory(data) {
  if (data.cat == 'All') data.cat = 'undefined'
  if (data.taxId == 'All') data.taxId = 'undefined'

  return axios.get(
    baseUrl +
      `${environment.api.services.reports.taxreportbysalescategory}` +
      '?outlet_id=' +
      getOutletId(data) +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&tax_number=' +
      data.taxId +
      '&order_type=' +
      data.cat,
    authFun()
  )
}

/*--------================================== Staff Sale =======================================-------------*/

function getAllMenuItems(data) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.allmenuitems}` +
      '?outlet_id=' +
      getOutletId(data) +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&user_id=' +
      data.userId +
      '&chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function getItemCategories(data) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.itemcategories}` +
      '?outlet_id=' +
      getOutletId(data) +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&user_id=' +
      data.userId,
    authFun()
  )
}

function getRevenue(data) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.staffrevenue}` +
      '?outlet_id=' +
      getOutletId(data) +
      '&search_type=Daily' +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&user_id=' +
      data.userId,
    authFun()
  )
}

/*--------======================End Of Day Report=========================-----------------*/

function getEndOfDay(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.endofday}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&terminal_id=' +
      queries.terminalId +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate,
    authFun()
  )
}

/*--------======================Petty Cash=========================-----------------*/

function getPettyCash(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.pettycash}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate,
    authFun()
  )
}

/*--------======================Void=========================-----------------*/

function getVoidReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.voidsreport}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getVoidReportByStaff(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.voidsreportbystaff}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getVoidReportAnalysys(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.voidsreportanalysis}` +
      '?outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

/*--------=======================Discount Category Report=================---------------*/

function getDiscountCategoryReport(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.discountcategoryreport}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&cat_id=' +
      queries.cat_id,
    authFun()
  )
}

function getDiscountCatInvoice(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.discountcategoryinvoice}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&cat_id=' +
      queries.cat_id,
    authFun()
  )
}

function wastageSummary(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.wastagesummary}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate,
    authFun()
  )
}

function wastageDetails(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.wastagedetails}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate,
    authFun()
  )
}

/*--------=======================Labour Report=================---------------*/

function labourReportStaff(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.labourreporttaff}` +
      '?outlet_id=' +
      getOutletId(queries) +
      '&search_type=' +
      queries.search_type +
      '&start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate,
    authFun()
  )
}

/*--------=======================Voucher Report=================---------------*/

function getvoucherAuditReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.voucher_audit_report}` +
      '?id=' +
      data.id +
      '&outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}

function getVoucherReport(data) {
  if (accountService.userRole() == 'branch_user') {
    outletId = data.outletId
  } else {
    outletId = accountService.getOutletId()
  }
  return axios.get(
    baseUrl +
      `${environment.api.services.reports.voucher}/voucher_sales` +
      '?id=' +
      data.id +
      '&outlet_id=' +
      outletId +
      '&search_type=' +
      data.search_type +
      '&start_date=' +
      data.startDate +
      '&end_date=' +
      data.endDate +
      '&terminal_id=' +
      data.terminalId,
    authFun()
  )
}
