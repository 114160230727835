<template>
  <form @submit.prevent class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item">
          <h3 class="pad-l-15px">Receipt Relay</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Shop Id') }}</label>
              <md-input
                type="text"
                v-model.lazy="receiptRelay.shopId"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Endpoint') }}</label>
              <md-input
                type="text"
                v-model.lazy="receiptRelay.endpoint"
              ></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Vendor') }}</label>
              <md-input
                type="text"
                v-model.lazy="receiptRelay.vendor"
              ></md-input>
            </md-field>
          </div>
        </div>
      </md-card-content>
      <div
        style="margin: 20px 0"
        class="btn-div md-layout-item md-size-60 md-alignment-center-right"
      >
        <md-button
          type="button"
          @click="saveData()"
          style="float: right"
          class="md-teritiary"
          >Update</md-button
        >
      </div>
    </md-card>
  </form>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions'
import axios from 'axios'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'

export default {
  components: {
    SlideYDownTransition,
  },
  data() {
    return {
      receiptRelay: {
        shopId: '',
        endpoint: '',
        vendor: '',
        server: '',
      },
    }
  },
  methods: {
    saveData() {
      let closure = {
        outlets: {
          outlet: {
            receipt_relay: this.receiptRelay,
          },
        },
      }
      let outlet_id = accountService.getOutletId()
      customizeService
        .editOutlet(outlet_id, closure)
        .then((res) => console.log('SaveDAta successful!>>>', res))
    },
  },
  created() {
    customizeService.getOutletById(accountService.getOutletId()).then((res) => {
      this.receiptRelay = res.data.outlet.receipt_relay
        ? res.data.outlet.receipt_relay
        : this.receiptRelay
    })
  },
}
</script>

<style scoped>
.error {
  color: red;
}
</style>
