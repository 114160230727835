<template>
  <!-- <div>
    <article
      v-for="zone in grid"
      :key="zone.i"
      class="available_zone"
      :class="{
        'zone-selected':
          $store.state.screen.allSelectedMenus[activeTab] &&
          $store.state.screen.allSelectedMenus[activeTab].includes(zone.uuid),
      }"
      :number="zone.i"
      @dragenter="dragEnter"
      @dragover.prevent="dragOver"
      @drop="dropBox($event, zone)"
      v-bind:style="{
        'box-shadow': `0 0 0px 3px ${
          zone.colour && zone.menu_item_id ? zone.colour : 'transparent'
        }`,
      }"
    >
      <div
        v-if="zone.item_name !== null"
        class="box"
        draggable="true"
        @dragstart="boxEnter"
        @dragend="boxLeave"
        :data-zone="zone.i"
        v-bind:style="{ background: zone.colour }"
        @click="toggleSelection(zone.uuid)"
      >
        {{ zone.item_name }}<br />
        {{ `${currency}${zone.price}` }}
      </div>
      <md-button
        v-if="zone.item_name !== null"
        class="md-round cat-delete"
        id="cat-delete"
        @click="removeFromGrid(zone)"
        ><md-icon>delete_forever</md-icon></md-button
      >
      <md-button
        v-if="zone.item_name !== null"
        class="cat-edit md-round"
        id="cat-edit"
        @click="editMenuItem(zone)"
        ><md-icon>edit</md-icon></md-button
      >
      <md-button
        class="md-just-icon md-round screen-save"
        id="grid-remove"
        v-if="(zone.i + 1) % 64 == 0"
        ><md-icon>delete</md-icon></md-button
      >
    </article>
  </div> -->

  <!-- <div> -->
  <!-- <VueSlickCarousel
    :arrows="true"
    :dots="true"
    :infinite="false"
    :vertical="true"
  >
    <div>1</div>
    <div>2</div>
    <div>3</div>
    <div>4</div>
  </VueSlickCarousel> -->
  <!-- <VueSlickCarousel :settings="slickSettings" > -->

  <div>
    <md-button
      class="md-round cat-delete screen-delete"
      id="cat-delete"
      @click="removeChunk()"
      v-if="chunkedGrid.length > 1"
      ><md-icon>delete_forever</md-icon></md-button
    >

    <VueSlickCarousel
      :arrows="true"
      :dots="true"
      :infinite="false"
      :vertical="true"
      :swipeToSlide="true"
      :adaptiveHeight="true"
      :touchThreshold="chunkedGrid.length"
      :customPaging="customPaging"
      :slidesToScroll="1"
      :verticalSwiping="true"
      @swipe="swipeEvent($event)"
      @afterChange="handleAfterChange"
      ref="carousel"
    >
      <div
        v-for="(chunk, chunkIndex) in chunkedGrid"
        :key="`chunck-${chunkIndex}`"
        class="screen-listing"
        :class="
          menuPageSelected.screen_size == 'grid8by8'
            ? 'grid-eight-columns'
            : 'grid-four-columns'
        "
        ref="carouselSlides"
      >
        <article
          v-for="zone in chunk"
          :key="zone.i"
          class="available_zone"
          :class="{
            'zone-selected':
              $store.state.screen.allSelectedMenus[activeTab] &&
              $store.state.screen.allSelectedMenus[activeTab].includes(
                zone.uuid
              ),
            'special-class': (zone.i + 1) % 64 === 0, // Add special class to every 64th element
          }"
          :number="zone.i"
          @dragenter="dragEnter"
          @dragover.prevent="dragOver"
          @drop="dropBox($event, zone)"
          v-bind:style="{
            'box-shadow': `0 0 0px 3px ${
              zone.colour && zone.menu_item_id ? zone.colour : 'transparent'
            }`,
          }"
        >
          <div
            v-if="zone.item_name !== null"
            class="box"
            draggable="true"
            @dragstart="boxEnter"
            @dragend="boxLeave"
            :data-zone="zone.i"
            v-bind:style="{ background: zone.colour }"
            @click="toggleSelection(zone.uuid)"
          >
            {{ zone.item_name }}<br />
            {{ `${currency}${zone.price}` }}
          </div>

          <md-button
            v-if="zone.item_name !== null"
            class="md-round cat-delete"
            id="cat-delete"
            @click="removeFromGrid(zone)"
            ><md-icon>delete_forever</md-icon></md-button
          >

          <md-button
            v-if="zone.item_name !== null"
            class="cat-edit md-round"
            id="cat-edit"
            @click="editMenuItem(zone)"
            ><md-icon>edit</md-icon></md-button
          >
        </article>
      </div>
      <!-- <template #customPaging="page">
      <div class="custom-dot">
        {{ page + 1 }}
      </div>
    </template> -->
    </VueSlickCarousel>
  </div>
</template>
<script>
import { customizeService } from '../../../services/customize.js'
import VueSlickCarousel from 'vue-slick-carousel'
// import draggable from 'vuedraggable'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'jquery-ui-dist/jquery-ui'
import Swatches from 'vue-swatches'
import { Promise, reject } from 'q'
import { accountService } from '../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Vue from 'vue'
import { screenDesignMixin } from './screenDesignMixin'
import { active } from 'sortablejs'
import store from '@/store'

// const app = new Vue(App)

// function activate() {
//     console.log("Activare function")
// }

export default {
  components: {
    VueSlickCarousel,
    // draggable,
    Swatches,
    ClipLoader,
  },
  props: {
    gridData: {
      type: Array,
      default: () => [],
    },
    menuPageActive: {
      type: Number || String,
      default: null,
    },
    menuPage: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    gridData(newVal, oldVal) {
      this.grid = newVal // Add your custom logic here
    },
    menuPageActive(newVal, oldVal) {
      this.activeTab = newVal // Add your custom logic here
    },
    menuPage(newVal, oldVal) {
      this.menuPageSelected = newVal
    },
  },

  data() {
    return {
      self: this,
      items: [],
      combos: [],
      menu_items: [],
      categories: [],
      tabs: [],
      itemsInGrid: [],
      drag: true,
      btnDisabled: false,
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      searched: [],
      searchKey: null,
      activateSort: false,
      currentSort: 1,
      outlet_id: accountService.getOutletId(),
      chain_id: accountService.getChainId(),
      swatchActive: {},
      activateSwatch: false,
      swatchPosition: '',
      mainCat: [],
      mainCatId: [],
      swatchCat: '',
      selectFlagGreen: [{ id: null, type: '' }],
      loader: {
        menuItems: false,
        mainCat: false,
        combos: false,
        menuCat: false,
        ipad: false,
      },
      checkOpen: { combos: false, menuPages: false },
      rightClick: false,
      top: '0px',
      left: '0px',
      rightClickCatId: '',
      menuPageSelected: JSON.parse(JSON.stringify(this.menuPage)),
      // grid: [
      //   [
      //     { number: 1, box: null },
      //     { number: 2, box: null },
      //     { number: 3, box: null },
      //     { number: 4, box: null },
      //     { number: 5, box: null },
      //     { number: 6, box: null },
      //     { number: 7, box: null },
      //     { number: 8, box: null },
      //   ],
      //   [
      //     { number: 9, box: null },
      //     { number: 10, box: null },
      //     { number: 11, box: null },
      //     { number: 12, box: null },
      //     { number: 13, box: null },
      //     { number: 14, box: null },
      //     { number: 15, box: null },
      //     { number: 16, box: null },
      //   ],
      //   [
      //     { number: 17, box: null },
      //     { number: 18, box: null },
      //     { number: 19, box: null },
      //     { number: 20, box: null },
      //     { number: 21, box: null },
      //     { number: 22, box: null },
      //     { number: 23, box: null },
      //     { number: 24, box: null },
      //   ],
      // ],
      // grid: [
      //   { x: 0, y: 0, w: 2, h: 2, i: 0, box: null },
      //   { x: 4, y: 0, w: 2, h: 5, i: 2, box: null },
      //   { x: 6, y: 0, w: 2, h: 3, i: 3, box: null },
      //   { x: 2, y: 4, w: 2, h: 3, i: 4, box: null },
      //   { x: 2, y: 0, w: 2, h: 4, i: 1, box: null },
      //   { x: 4, y: 5, w: 2, h: 3, i: 5, box: null },
      //   { x: 0, y: 2, w: 2, h: 5, i: 6, box: null },
      //   { x: 2, y: 7, w: 2, h: 5, i: 7, box: null },
      //   { x: 4, y: 8, w: 2, h: 5, i: 8, box: null },
      //   { x: 6, y: 3, w: 2, h: 4, i: 9, box: null },
      //   { x: 0, y: 7, w: 2, h: 4, i: 10, box: null },
      //   { x: 2, y: 19, w: 2, h: 4, i: 11, box: null },
      //   { x: 0, y: 14, w: 2, h: 5, i: 12, box: null },
      //   { x: 2, y: 14, w: 2, h: 5, i: 13, box: null },
      //   { x: 4, y: 13, w: 2, h: 4, i: 14, box: null },
      //   { x: 6, y: 7, w: 2, h: 4, i: 15, box: null },
      //   { x: 0, y: 19, w: 2, h: 5, i: 16, box: null },
      //   { x: 8, y: 0, w: 2, h: 2, i: 17, box: null },
      //   { x: 0, y: 11, w: 2, h: 3, i: 18, box: null },
      //   { x: 2, y: 12, w: 2, h: 2, i: 19, box: null },
      // ],
      // grid: JSON.parse(JSON.stringify(this.data)),
      grid: this.gridData,
      // selectedMenus: [],
      // selectedMenus: {},

      externalBoxes: [13, 14, 15, 16], // External box identifiers
      activeTab: JSON.parse(JSON.stringify(this.menuPageActive)),
      currency: store.state.account.outlet.currency_type || '€',
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      slickSettings: {
        // Your vue-slick-carousel settings
        dots: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
      },
    }
  },
  mixins: [screenDesignMixin],
  methods: {
    // handleTouchStart(e) {
    //   this.touchStartY = e.changedTouches[0].screenY
    // },
    // handleTouchEnd(e) {
    //   this.touchEndY = e.changedTouches[0].screenY
    //   this.handleSwipeGesture()
    // },
    swipeEvent(e) {
      // if (e == 'left') {
      //   this.showNext()
      // } else {
      //   this.showPrevious()
      // }
    },

    handleSwipeGesture() {
      if (this.touchEndY < this.touchStartY) {
        this.showNext()
      } else if (this.touchEndY > this.touchStartY) {
        this.showPrevious()
      }
    },

    customPaging(index) {
      return `<button>${index + 1}</button>`
    },
    getRandom() {
      return Math.floor(Math.random() * 10000)
    },
    conditionFlagGreen(itemId, type) {
      if (this.selectFlagGreen.some((el) => el.id == itemId && el.type == type))
        return true
      else return false
    },
    // boxEnter(event) {
    //   event.target.classList.add('dragstart')
    //   event.dataTransfer.setData('text/plain', event.target.dataset.zone)
    // },
    // boxLeave(event) {
    //   event.target.classList.remove('dragstart', 'hide')
    // },
    // dragEnter(event) {
    //   event.preventDefault()
    // },
    // dragOver(event) {
    //   event.preventDefault()
    // },
    // dropBox(event, zone) {
    //   debugger
    //   // return (event) => {
    //   const boxNumber = event.dataTransfer.getData('text/plain')
    //   const fromZone = this.findZoneByNumber(boxNumber)
    //   const toZone = this.findZoneByNumber(zone.number)

    //   if (fromZone && toZone) {
    //     toZone.box = fromZone.box
    //     fromZone.box = null
    //     const sound = new Audio(
    //       'https://assets.mixkit.co/active_storage/sfx/3005/3005-preview.mp3'
    //     )
    //     sound.play()
    //   }
    //   // }
    // },
    // findZoneByNumber(number) {
    //   for (let row of this.grid) {
    //     for (let zone of row) {
    //       if (zone.number == number) {
    //         return zone
    //       }
    //     }
    //   }
    //   return null
    // },

    // externalBoxEnter(event, box) {
    //   event.dataTransfer.setData('box-number', box.id)
    //   event.dataTransfer.setData('box-name', box.name)
    // },
    // externalBoxLeave(event) {
    //   event.target.classList.remove('dragstart', 'hide')
    // },
    // boxEnter(event) {
    //   debugger
    //   event.target.classList.add('dragstart')
    //   event.dataTransfer.setData('text/plain', event.target.dataset.zone)
    // },
    // boxLeave(event) {
    //   event.target.classList.remove('dragstart', 'hide')
    // },
    // dragEnter(event) {
    //   event.preventDefault()
    // },
    // dragOver(event) {
    //   event.preventDefault()
    // },
    // dropBox(event, zone) {
    //   debugger
    //   //   return (event) => {
    //   const boxNumber = event.dataTransfer.getData('text/plain')
    //   const fromZone = this.findZoneByNumber(boxNumber)
    //   const toZone = this.findZoneByNumber(zone.i)
    //   if (toZone.box !== null) {
    //     return
    //   }
    //   // If the drop is from an external source (boxNumber will be empty)
    //   if (!boxNumber) {
    //     const externalBoxNumber = event.dataTransfer.getData('box-name')
    //     if (toZone) {
    //       toZone.box = externalBoxNumber
    //     }
    //   } else {
    //     if (fromZone && toZone) {
    //       toZone.box = fromZone.box
    //       fromZone.box = null
    //     }
    //   }

    //   const sound = new Audio(
    //     'https://assets.mixkit.co/active_storage/sfx/3005/3005-preview.mp3'
    //   )
    //   sound.play()
    //   //   }
    // },
    // findZoneByNumber(number) {
    //   // for (let row of this.grid) {
    //   //   for (let zone of row) {
    //   //     if (zone.i == number) {
    //   //       return zone
    //   //     }
    //   //   }
    //   // }
    //   // return null
    //   return this.grid.find((zone) => zone.i == number)
    // },

    dropDown() {
      // var self = this
      // self.$nextTick(()=> {
      //   self.checkOpen = !self.checkOpen
      // })
      this.checkOpen = !this.checkOpen
    },
    search() {
      this.searched = this.items.filter((el) => {
        return el.name.search(new RegExp(this.searchKey, 'i')) != -1
      })
    },

    showSort() {
      this.activateSort = !this.activateSort
    },
  },

  created() {
    this.searched = this.items
    this.loader.ipad = true
  },

  mounted() {
    var vm = this
    this.addTouchListeners()
    // $('.slick-track').draggable()
  },
}
</script>

<style lang="scss" scoped>
.md-list-item {
  .md-ripple {
    position: relative;
  }
}
.dot {
  padding-top: 2px;
  /*height: 10px;
      width: 10px;*/
  height: 7px;
  width: 7px;
  top: 47% !important;
  left: -2px !important;
  padding-top: 7px !important;
  border-radius: 50%;
  display: inline-block;
  background-color: #16b402b4;
}
.arrow {
  position: relative;
  height: 15px;
  width: 15px;
  // margin: auto;
  margin-right: 0.25rem;
  margin-top: auto;
  margin-bottom: auto;
}

.arrow::before,
.arrow::after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.5s;
}

.arrow::before {
  left: -5px;
  transform: rotate(45deg);
  background-color: #fff;
}
.arrow::after {
  left: 5px;
  transform: rotate(-45deg);
  background-color: #fff;
}
.open .arrow::before {
  left: -5px;
  transform: rotate(-45deg);
}
.open .arrow::after {
  left: 5px;
  transform: rotate(45deg);
}

.ipad {
  height: 600px;
  // background-image: url('../../../assets/images/ipad-fliped-bt-top.png');
  // background-repeat: no-repeat;
  // background-size: 900px 700px;
  border: 1px;
  border-style: groove;
  padding: 0px !important;
  background: black;
  border-radius: 10px;
}

.ipad-workspace {
  // padding-left: 50px;
  // padding-top: 10%;
  // padding-right: 50px;
  // padding-bottom: 10%;
  height: 100%;
  /* margin-right: 186px; */
  width: 100%;

  .tab-section {
    background-color: #49e0db;
    height: 66px;
    width: 100%;
    display: flex;
    justify-content: center;
    .fa-plus-circle {
      font-size: 35px;
      padding: 13px;
      color: white;
      padding-left: 0px;
    }

    .tabs {
      margin-left: 35px;
      display: flex;
    }
  }

  .item-section {
    height: 62%;
    width: 100%;
    /*overflow-y: scroll;*/
    margin-top: 2px;
    overflow: auto;
  }

  .items {
    display: flex;
    /*margin-left: 150px;*/
    margin-left: 120px;
    justify-content: center;
    // @media (min-width: 1200px) and (max-width: 1400px){
    //     margin-left: 57px;
    // }
    .item {
      margin-right: 2px;
      margin-bottom: 2px;
      /*height: 60px;
          width: 98px;*/
      height: 70px;
      width: 126.8px;
      position: relative;
      @media (min-width: 1200px) and (max-width: 1400px) {
        width: 115.8px;
      }
      .item-name {
        font-size: 12px;
      }

      .item-name-grid {
        position: absolute;
        bottom: 0;
        padding: 5px;
        color: #000;
      }

      .close-btn {
        position: absolute;
        right: 0;
        padding-right: 5px;
      }
    }
  }
}

.list-group {
  display: flex;
  flex-wrap: wrap;
  max-width: 535px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.fa-angle-double-right {
  margin: auto;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  color: #fff;
  font-size: 30px;
  // margin-left: 2%;
}

.fa-angle-double-right:hover {
  color: #49e0db;
}
.fa-times-circle:before {
  color: #ff0000;
}
.add-remove {
  width: 520px;
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 430px;
  }
  .draggable {
    background: white;
  }
}

.form__field {
  position: absolute;
  right: 0px;
  top: 5px;
}

.items-list {
  height: 600px;
  overflow-y: scroll;
}

.sort-section {
  margin-top: 40px;
  color: white;
  background-color: transparent;
  display: flex;
}

.sort-btn {
  background: #49e0db;
  margin: 0 0 5px 0;
  width: 15%;
  text-align: center;
  border-radius: 2px;
  color: #000;
}

.sort-order {
  display: flex;
  padding: 5px;
}

.sort-option {
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  background: #434343;
  margin-left: 5px;
  font-size: 10px;
}

.current-sort {
  background: #237dbc;
}

.header-content {
  display: flex;
}

.borderactive {
  border-bottom-style: solid;
  border-bottom-color: #ff1744;
  border-bottom-width: 6px;
}

.item-color {
  float: right;
  width: 32px;
  margin-left: 5px;
  // margin-right: 16px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
}

.colors-popup {
  position: absolute;
  /*left: 400px;*/
  left: 359px;
  z-index: 999;
  height: 100px;

  .fa-window-close {
    z-index: 999;
    color: #ec3232;
    position: absolute;
    left: 196px;
    font-size: 19px;
  }
}

.tab-item {
  border-bottom-style: solid;
  border-bottom-width: 27px;
  background-color: white;
}

.item-header {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
  cursor: pointer;
  padding: 10px 0 5px;
}

.main-cat {
  display: flex;
  padding-left: 14px;
  background: #49e0db;

  .cat-color {
    margin-left: auto;
    margin-right: 15px;
    margin-top: 5px;
  }
}

.main-cat-name {
  padding: 14px;
  color: #000;
  width: 170px;
}

.submit-btn {
  text-align: center;
  padding-top: 20px;
}

.right-click-menu {
  position: absolute;
  color: #3d4858;
  background: #fff;
  border-radius: 7px;
  border: solid 1px #4e92c3;
}

.right-click-menu-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: 12px;
  li {
    padding: 6px;
    border-bottom: solid 1px #4e92c3;
    cursor: pointer;
  }
}
.ipdgrd-sidebar {
  .main-cat {
    .main-cat-name {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    .md-checkbox {
      &.main-catgory-chckbox {
        margin-top: 5px;
        margin-bottom: 0px;
        .md-checkbox {
          border: 1px solid #ff1744 !important;
          &:before {
            height: 20px;
          }
        }
      }
    }
    .arrow {
      width: 10px;
      height: 10px;
      &::before {
        left: -3px;
        background-color: #000;
      }
      &::after {
        left: 3px;
        background-color: #000;
      }
    }
  }
  .md-list {
    padding: 1px 0;
  }
}
.ipad-workspace {
  padding-left: 25px;
  padding-right: 25px;
}
</style>
<style>
.md-checkbox .md-checkbox-container {
  background: #ffffff;
}
.ipad-workspace .slick-next {
  right: -32px;
}
.sub-cat-name {
  width: 100%;
  cursor: pointer;
}
.ipad-grid .vue-swatches__container {
  box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
}
.vue-swatches__wrapper {
  padding: 13px;
}
.vue-swatches__swatch {
  margin: 7px !important;
}
.vue-swatches__swatch:nth-child(4n + 4) {
  margin-right: 0 !important;
}
.vue-swatches__swatch:nth-child(4n + 1) {
  margin-left: 0 !important;
}
.tab-input {
  width: 100%;
}
/* .screen-listing {
  display: flex !important;
} */
@media (min-width: 1750px) {
  .ipad-grid .ipad {
    min-width: 50%;
    max-width: 50%;
  }
}

/* main {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-family: system-ui;
  }
  
  section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    justify-items: center;
  }
  
  section h1 {
    grid-column: span 4;
    text-align: center;
    font-size: 2em;
    margin: 0;
  }
  
  section article {
    display: contents;
  }
  
  section article .available_zone {
    position: relative;
    border: 0.25rem dotted #dddddd50;
    padding: 0.2rem;
    height: 5rem;
    width: 5rem;
  }
  
  section article .available_zone::before {
    content: attr(number);
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: min-content;
    height: min-content;
    z-index: 1;
    color: #dddddd50;
    font-size: 2em;
  }
  
  section article .available_zone .box {
    position: sticky;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #de7300;
    width: 5rem;
    height: 5rem;
    cursor: pointer;
    border-radius: 0.25rem;
    transition: all 0.35s ease-in-out;
  }
  
  section article .available_zone .box.dragstart {
    border-radius: 5rem;
  }
  
  section article .available_zone .box.hide {
    opacity: 0;
  } */

/* main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: system-ui;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.external-list {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.external-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  background-color: #de7300;
  color: white;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.35s ease-in-out;
}

.external-box.dragstart {
  border-radius: 5rem;
}

.external-box.hide {
  opacity: 0;
}

section h1 {
  text-align: center;
  font-size: 2em;
  margin: 0;
}

section article {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  justify-items: center;
}

section article.available_zone {
  position: relative;
  border: 0.25rem dotted #dddddd50;
  padding: 0.2rem;
  height: 5rem;
  width: 5rem;
}

section article.available_zone::before {
  content: attr(number);
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: min-content;
  height: min-content;
  z-index: 1;
  color: #dddddd50;
  font-size: 2em;
}

section article.available_zone .box {
  position: sticky;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #de7300;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.35s ease-in-out;
}

section article.available_zone .box.dragstart {
  border-radius: 5rem;
}

section article.available_zone .box.hide {
  opacity: 0;
} */
.box {
  position: relative;
}
.cat-delete {
  width: 18px;
  height: 18px;
  min-width: unset !important;
  background-color: transparent !important;
  position: absolute;
  top: -4px;
  left: -4px;
  border-radius: 0px !important;
  z-index: 9;
  box-shadow: none !important;
}
#cat-delete .md-ripple {
  padding: 4px 0px !important;
}
.cat-delete .md-ripple .md-button-content i {
  font-size: 15px !important;
  font-weight: 600;
  color: #fff !important;
  height: 10px !important;
}
.cat-delete:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}
.cat-delete:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.cat-delete:active {
  background-color: transparent !important;
  box-shadow: none !important;
}
.cat-delete:active:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.cat-delete .md-ripple .md-button-content i:hover {
  color: aqua !important;
  scale: 1.3;
}
.cat-edit {
  width: 18px;
  height: 18px;
  min-width: unset !important;
  background-color: transparent !important;
  position: absolute;
  top: -5px;
  right: 2px;
  border-radius: 0px !important;
  z-index: 9;
  box-shadow: none !important;
}
#cat-edit .md-ripple {
  padding: 4px 0px !important;
}
.cat-edit .md-ripple .md-button-content i {
  font-size: 15px !important;
  font-weight: 600;
  color: #fff !important;
  height: 10px !important;
}
.cat-edit:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}
.cat-edit:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.cat-edit:active {
  background-color: transparent !important;
  box-shadow: none !important;
}
.cat-edit:active:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.cat-edit .md-ripple .md-button-content i:hover {
  color: aqua !important;
  scale: 1.3;
}
.fixed-height-carousel {
  height: 600px; /* Set your desired height */
  overflow: hidden;
}
.grid-four-columns {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
.grid-eight-columns {
  display: grid !important;
  grid-template-columns: repeat(8, 1fr); /* 8 equal columns */
  gap: 16px; /* Adjust the gap between columns if needed */
}
</style>
