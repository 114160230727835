<template>
  <md-card class="block-bg">
    <md-card-content class="custom-tab">
      <md-tabs md-alignment="left" :md-active-tab="activeTab" md-sync-route>
        <md-tab
          id="tab-super"
          md-label="Super Category"
          to="/products/categories/super-category"
          exact
        >
          <super-category></super-category>
        </md-tab>

        <md-tab
          id="tab-main"
          md-label="Main Category"
          to="/products/categories/main-category"
        >
          <main-category></main-category>
        </md-tab>

        <md-tab
          id="tab-sub"
          md-label="Sub Category"
          to="/products/categories/sub-category"
        >
          <sub-category></sub-category>
        </md-tab>
        <!-- <md-tab
          id="tab-section"
          md-label="Menu Section"
          to="/products/categories/menu-section"
        >
          <menu-section></menu-section>
        </md-tab> -->
      </md-tabs>
    </md-card-content>
  </md-card>
</template>

<script>
import { Tabs } from '@/components'
import SuperCategory from './SuperCategory'
import MainCategory from './MainCategory'
import SubCategory from './SubCategory'
// import MenuSection from './MenuSection.vue'

export default {
  components: {
    Tabs,
    SuperCategory,
    MainCategory,
    SubCategory,
    // MenuSection,
  },
  data() {
    return {
      activeTab: 'tab-super',
    }
  },

  methods: {
    updateActiveTab() {
      this.activeTab = this.$route.name
    },
  },

  created() {
    if (this.$route.path == '/products/categories') {
      this.$router.push({
        name: 'Super Category',
      })
    }
    this.activeTab = 'tab-super'
  },

  updated() {
    if (this.$route.path == '/products/categories') {
      this.$router.push({
        name: 'Super Category',
      })
      this.activeTab = 'tab-super'
    }
  },
}
</script>

<style>
.tab-router {
  text-align: center;
  padding: 10px 15px;
  border-radius: 3px;
  text-transform: uppercase;
  color: #3c4858;
}

.tab-router-active {
  color: white;
  background: #2b82be;
}

.categories-section {
  margin-left: 18px;
  margin-top: 18px;
}
.material-tab .md-button {
  border-bottom: 2px solid transparent;
  transition: none;
}
.material-tab .md-button.md-active {
  border-bottom: 2px solid #2b82be !important;
}

.material-tab .md-tabs-indicator.md-tabs-indicator-left {
  transition: none;
  display: none;
}
.md-tabs.md-theme-default.md-accent .md-tabs-navigation {
  background: linear-gradient(
    109.46deg,
    rgba(201, 201, 201, 0.8) 1.57%,
    rgba(196, 196, 196, 0.1) 100%
  );
}

.md-content.md-tabs-content.md-theme-default {
  height: auto !important;
}
.super-table .md-table-cell:last-child .md-table-cell-container {
  float: none;
}
.super-table .md-table-head:nth-child(4) .md-table-head-container {
  text-align: center;
}
.super-table .md-table-head:nth-child(4) .md-table-head-label {
  padding: 0 !important;
}
.super-table .md-table-head:nth-child(4) {
  padding-right: 0 !important;
}

.md-tabs-container tbody .md-table-row td .check-align {
  padding-right: 0 !important;
  display: table;
  margin-left: auto;
  margin-right: auto;
}
</style>
