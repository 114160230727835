<template>
  <div>
    <md-card class="itemised-trans block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 report-name">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('CBE PAY Report') }}</h3>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout filter-section block-bg">
          <div class="md-layout search-options">
            <div class="md-layout-item">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div
              class="md-layout-item md-size-30"
              v-if="userRole == 'branch_user'"
            >
              <md-field style="margin-top: 20px">
                <label for="Outlets">Outlets</label>
                <md-select
                  v-model="data.outletId"
                  multiple
                  @md-closed="
                    data.outletId.length > 0 ? getItems() : (data.outletId = [])
                  "
                >
                  <md-option
                    v-for="item in outlets"
                    :key="item.id"
                    :value="item.id"
                    class="oulet-select-list-item"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-size-10">
              <md-button class="md-teritiary" @click="getItems()"
                >Search</md-button
              >
            </div>
          </div>
        </div>

        <div class="md-layout-item md-size-100 card-inside report-table">
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <div
            class="md-layout-item md-size-100 file-icon-holder"
            v-if="!loader"
          >
            <h4 class="center" v-if="!loader">
              CBE PAY Transactions- {{ sdate }} to {{ edate }}
            </h4>
            <div class="action-buttons">
              <div class="pdf-el">
                <div class="download-btn">
                  <img
                    src="../../../../assets/images/pdf.svg"
                    @click="downloadWithoutCSS()"
                  />
                </div>
              </div>

              <div class="excel-el" v-if="userRole=='user'">
                <vue-excel-xlsx
                  class="Excel-btn"
                  :data="report"
                  :columns="columns"
                  :filename="'CBE PAY Transactions'"
                  :sheetname="'CBE PAY Transactions'"
                  :outlet-names="selectedOutletsNames()"
                  :sdate="sdate"
                  :edate="edate"
                  :userrole="userRole"
                >
                </vue-excel-xlsx>
              </div>
              <div class="excel-el" v-if="userRole=='branch_user'">
                <vue-excel-xlsx
                  class="Excel-btn"
                  :data="report"
                  :columns="columnsHo"
                  :filename="'CBE PAY Transactions'"
                  :sheetname="'CBE PAY Transactions'"
                  :outlet-names="selectedOutletsNames()"
                  :sdate="sdate"
                  :edate="edate"
                  :userrole="userRole"
                >
                </vue-excel-xlsx>
              </div>
            </div>
          </div>
          <div class="pad-l-15px pad-r-15px">
            <md-table
              :value="queriedData"
              :md-sort.sync="currentSort"
              :md-sort-order.sync="currentSortOrder"
                class="paginated-table itemised-txn table-typ1"
              v-if="!loader"
            >
              <md-table-toolbar
                class="table-toolbar-align"
                md-card
                md-fixed-header
              >
                <md-field>
                  <label>{{ $t('Search') }}</label>
                  <md-input
                    type="search"
                    class="mb-3"
                    clearablef7
                    style=""
                    v-model="searchQuery"
                  ></md-input>
                  <i class="fa fa-search search-align" aria-hidden="true"></i>
                </md-field>
              </md-table-toolbar>
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell
                  v-bind:md-label="$t('Transaction Date')"
                  md-sort-by="eventDate"
                >
                <div style="text-align: center">
                    {{ moment(item.eventDate).format('DD/MM/YYYY HH:mm') }}
                  </div>
                </md-table-cell>
                <md-table-cell v-bind:md-label="$t('Outlet')" v-if="userRole=='branch_user'" 
                  md-sort-by="outletName" >
                  <div style="text-align: left">
                    {{ item.outletName }}
                  </div>
                </md-table-cell>
                <md-table-cell
                  v-bind:md-label="$t('Terminal Id')"
                  md-sort-by="terminalId"
                >
                  <div style="text-align: left">{{ item.terminalId }}</div>
                </md-table-cell>
                <md-table-cell v-bind:md-label="$t('Txn Id')" md-sort-by="transactionId" >
                  <div style="text-align: left">{{ item.transactionId }}</div>
                </md-table-cell>
                <md-table-cell v-bind:md-label="$t('Merchant Reference')" md-sort-by="merchantRef" >
                  <div style="text-align:left;width:150px;">
                    {{ item.merchantRef }}
                  </div>
                </md-table-cell>
                <md-table-cell v-bind:md-label="$t('PSP Ref')" md-sort-by="originalRef">
                  <div style="text-align: left">{{ item.originalRef }}</div>
                </md-table-cell>
                <md-table-cell
                  v-bind:md-label="$t('Amount')"
                  md-sort-by="amount"
                >
                  <div>
                    {{ (item.amount / 100).toFixed(2) }}
                  </div>
                </md-table-cell>
                <md-table-cell
                  v-bind:md-label="$t('Status')"
                  md-sort-by="status"
                >
                  <div class="center">{{ item.status }}</div>
                </md-table-cell>
                <md-table-cell
                  v-bind:md-label="$t('Seller Split')"
                  md-sort-by="sellerSplit"
                >
                  <div>
                    {{ (item.sellerSplit / 100).toFixed(2) }}
                  </div>
                </md-table-cell>
                <md-table-cell v-bind:md-label="$t('Value Date')" md-sort-by="valueDate">
                  <div class="center">
                    {{item.valueDate? moment(item.valueDate).format('DD/MM/YYYY'): ''}}
                  </div>
                </md-table-cell>
                <md-table-cell
                  v-bind:md-label="$t('Card Type')"
                  md-sort-by="cardType"
                >
                  <div class="center">{{ item.cardType }}</div>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <md-table class="table-typ1">
              <md-table-row >
                <md-table-cell></md-table-cell>
                <md-table-cell></md-table-cell>
                <md-table-cell v-if="userRole=='branch_user'"></md-table-cell>
                <md-table-cell></md-table-cell>
                <md-table-cell></md-table-cell>
                <md-table-cell >Total</md-table-cell>
                <md-table-cell>{{ (totals.amount / 100).toFixed(2) }}</md-table-cell>
                <md-table-cell></md-table-cell>
                <md-table-cell>{{ (totals.sellerSplit / 100).toFixed(2) }}</md-table-cell>
                <md-table-cell> </md-table-cell>
                <md-table-cell> </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </md-card-content>
      <!-- <div class="md-layout">
        <div class="md-layout-item layout-border">
          <div class="pagination-main">
            <p class="mr-2">{{ 'Rows Per page' }}</p>
            <div class="pagenation-number mr-2">
              <md-field>
                <md-select v-model="data.pageSize" name="pages">
                  <md-option
                    v-for="item in perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="pagination-wrap d-flex align-items-center pad-l-15px">
              <p>Page {{ data.pageNumber }} of {{ totalPages }}</p>
              <ul
                data-v-40e0fa12=""
                class="pagination pagination-no-border pagination-success pagination-primary"
              >
                <li class="page-item prev-page">
                  <a
                    aria-label="Previous"
                    class="page-link"
                    @click="previousPage()"
                    :disabled="data.pageNumber === 1"
                  >
                    <i class="fas fa-angle-left"></i
                  ></a>
                </li>
                <li class="page-item page-pre next-page">
                  <a
                    aria-label="Next"
                    class="page-link"
                    @click="nextPage()"
                    :disabled="data.pageNumber >= totalPages"
                    ><i class="fas fa-angle-right"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> -->
    </md-card>
  </div>
</template>
<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import { customizeService } from '../../../../services/customize.js'
import { accountService } from '../../../../services/account.js'
import { reusableService } from '../../../../services/reusable.js'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import { cbePayService } from '../../../../services/cbePay.js'
import moment from 'moment'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import { data } from 'jquery'

export default {
  components: {
    ClipLoader,
    Pagination,
    DatePeriodDropdown,
    VueExcelXlsx,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result
    },
    totalPages() {
      return Math.ceil(this.totalPage / this.data.pageSize)
    },
    totals() {
      let totals = {}
      totals.amount = this.queriedData.reduce((prev, next) => {
        return prev + next.amount
      }, 0)
      totals.sellerSplit = this.queriedData.reduce((prev, next) => {
        return prev + next.sellerSplit
      }, 0)
      return totals
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      data: {
        sdate: '',
        search_type: 'Daily',
        outletId: [],
        chainid: 0,
        pageSize: 25,
        pageNumber: 1,
        totalCount: 0,
      },
      totalCbePAmount :0,
      totalSellerSplit :0,
      perPageOptions: [5, 10, 25, 50],
      rowsPerPage: 10,
      currentSort: 'eventDate',
      currentSortOrder: 'desc',
      searchQuery: '',
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
      activeStatus: { index: '', status: false },
      sdate: '',
      edate: '',
      totalPage: 0,
      outlets: [],
      outletList: [],
      outletId: [],
      report: [],
      reportTotals: [],
      columns: [
        {
          label: 'Txn Date',
          field: 'eventDate',
        },
        {
          label: 'Terminal Id.',
          field: 'terminalId',
        },
        {
          label: 'Tnx Id',
          field: 'transactionId',
        },
        {
          label: 'Merchant Refe.',
          field: 'merchantRef',
        },
        {
          label: 'PSP Ref',
          field: 'pspRef',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Seller Split',
          field: 'sellerSplit',
        },
        {
          label: 'Value Date',
          field: 'valueDate',
        },
        {
          label: 'Card Type',
          field: 'cardType',
        },
      ],
      columnStyles: {
        0: { columnWidth: 30 },
        1: { columnWidth: 30 },
        2: { columnWidth: 25 },
        3: { columnWidth: 35 },
        4: { columnWidth: 35 },
        5: { columnWidth: 20 },
        6: { columnWidth: 17 },
        7: { columnWidth: 35 },
        8: { columnWidth: 30 },
        9: { columnWidth: 30 },
        10: { columnWidth: 23 },
      },
      columnsHo: [
        {
          label: 'Txn Date',
          field: 'eventDate',
        },
        {
          label: 'Outlet',
          field: 'outletName',
        },
        {
          label: 'Terminal Id.',
          field: 'terminalId',
        },
        {
          label: 'Tnx Id',
          field: 'transactionId',
        },
        {
          label: 'Merchant Refe.',
          field: 'merchantRef',
        },
        {
          label: 'PSP Ref',
          field: 'pspRef',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Seller Split',
          field: 'sellerSplit',
        },
        {
          label: 'Value Date',
          field: 'valueDate',
        },
        {
          label: 'Card Type',
          field: 'cardType',
        },
      ],
      columnStylesHo: {
        0: { columnWidth: 30 },
        1: { columnWidth: 30 },
        2: { columnWidth: 30 },
        3: { columnWidth: 25 },
        4: { columnWidth: 30 },
        5: { columnWidth: 30 },
        6: { columnWidth: 20 },
        7: { columnWidth: 25 },
        8: { columnWidth: 17 },
        9: { columnWidth: 30 },
        10: { columnWidth: 20 },
        11: { columnWidth: 24 },
      },
    }
  },

  methods: {
    previousPage() {
      if (this.data.pageNumber > 1) {
        this.data.pageNumber--
        this.getItems()
      }
    },
    nextPage() {
      if (this.data.pageNumber < this.totalPages) {
        this.data.pageNumber++
        this.getItems()
      }
    },
    async setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
        if (!this.loader) {
          await this.getItems()
        }
      }
    },
    toggleRow(index) {
      this.activeStatus.index = index
      this.activeStatus.status = !this.activeStatus.status
    },
    async getItems() {
      this.loader = true
      if (accountService.userRole() == 'branch_user') {
        this.data.chainid = await accountService.getChainId()
        await cbePayService.getPaymentslistHo(this.data).then((res) => {
          this.loader = false
          if (res) {
            this.sdate = res.data.sdate
            this.edate = res.data.edate
            this.totalPage = res.data.totalPage
            this.tableData = res.data.result
            this.totalCbePAmount = res.data.totalCbePAmount
            this.totalSellerSplit = res.data.totalSellerSplit
            this.report = JSON.parse(JSON.stringify(res.data.result))
            if (res.data.result) {
              this.report.forEach((el) => {
                el.eventDate = moment(el.eventDate).format('DD/MM/YYYY HH:mm')
                el.amount = (el.amount / 100).toFixed(2)
                el.sellerSplit = (el.sellerSplit / 100).toFixed(2)
                el.valueDate = el.valueDate
                  ? moment(el.valueDate).format('DD/MM/YYYY')
                  : ''
              })
            }
          }
        })
      } else {
        await cbePayService.getPayments(this.data).then((res) => {
          this.loader = false
          this.sdate = res.data.sdate
          this.edate = res.data.edate
          this.totalPage = res.data.totalPage
          this.tableData = res.data.result
          this.totalCbePAmount = res.data.totalCbePAmount
          this.totalSellerSplit = res.data.totalSellerSplit
          this.report = JSON.parse(JSON.stringify(res.data.result))
          if (res.data.result) {
            this.report.forEach((el) => {
              el.eventDate = moment(el.eventDate).format('DD/MM/YYYY HH:mm')
              el.amount = (el.amount / 100).toFixed(2)
              el.sellerSplit = (el.sellerSplit / 100).toFixed(2)
              el.valueDate = el.valueDate
                ? moment(el.valueDate).format('DD/MM/YYYY')
                : ''
            })
          }
          })
          }
    },

    async pushTotals(){
      this.reportTotals=[]
      let totObj = [
              '',
              '',
              '',
              '',
              'Total',
              (this.totalCbePAmount / 100).toFixed(2),
              '',
              (this.totalSellerSplit / 100).toFixed(2),
              '',
              '',
            ]
           await this.reportTotals.push(totObj);
    },
    viewPromoDetail(item) {
      this.$router.push({
        name: 'AddPromotions',
        query: { formType: 'Edit Promotion', client: item.id },
      })
    },

    alert(value, id) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.deletePromotion(id)
        }
      })
    },

    deletePromotion(id) {
      customizeService
        .deletePromotion(id)
        .then((res) => {
          console.log('response get', res)
          this.getItems()
        })
        .catch((err) => console.log(err))
    },

    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.data.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    arrangeForPdf() {
      var body = []
      if(this.userRole=='user'){
        this.report.forEach((el) => {
          let arrayObj = [
            el.eventDate,
            el.terminalId,
            el.transactionId,
            el.merchantRef,
            el.pspRef,
            el.amount,
            el.status,
            el.sellerSplit,
            el.valueDate,
            el.cardType,
          ]
          body.push(arrayObj)
        })
        let totObj = [
          '',
          '',
          '',
          '',
          'Total',
          (this.totals.amount / 100).toFixed(2),
          '',
          (this.totals.sellerSplit / 100).toFixed(2),
          '',
          '',
        ]
        body.push(totObj)
      }else{
        this.report.forEach((el) => {
            let arrayObj = [
              el.eventDate,
              el.outletName,
              el.terminalId,
              el.transactionId,
              el.merchantRef,
              el.pspRef,
              el.amount,
              el.status,
              el.sellerSplit,
              el.valueDate,
              el.cardType,
            ]
            body.push(arrayObj)
          })
          let totObj = [
            '',
            '',
            '',
            '',
            '',
            'Total',
            (this.totals.amount / 100).toFixed(2),
            '',
            (this.totals.sellerSplit / 100).toFixed(2),
            '',
            '',
          ]
          body.push(totObj)
      }
      
      return body
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let dateText = ''
      let head = []
      if(this.userRole=='user'){
         head = [
          [
            // 'Termi',
            'Txn. Date',
            'Terminal Id.',
            'Txn Id',
            'Merchant Ref.',
            'PSP Ref',
            'Amount',
            'Status',
            'Seller Split',
            'Value Date',
            'Card Type',
          ],
        ]
      }else{
         head = [
          [
            'Txn. Date',
            'Outlet',
            'Terminal Id.',
            'Txn Id',
            'Merchant Ref.',
            'PSP Ref',
            'Amount',
            'Status',
            'Seller Split',
            'Value Date',
            'Card Type',
          ],
        ]
      }
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      let selectedOutlets = this.selectedOutletsNames()
      pdfService.headerFooterConfig(
        this.data,
        doc,
        'CBE PAY Transactions',
        selectedOutlets
      )
      doc.setFontSize(8)
      if(this.userRole=='user'){
        pdfService.autoTableConfig(
          doc,
          head,
          body,
          this.columnStyles,
          'cbepay-transactions.pdf'
        )
      }else{
        pdfService.autoTableConfig(
          doc,
          head,
          body,
          this.columnStylesHo,
          'cbepay-transactions.pdf'
        )
      }
    },
  },

  async created() {
    this.userRole = accountService.userRole();
  },
  async mounted() {
    if (accountService.userRole() == 'branch_user') {
      await customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.data.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
      })
    }
    await this.getItems()
  },
  watch: {
    // 'report'() {
    //   this.reportTotals = this.report
    // },
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['pspRef', 'merchantRef'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}

.bill-tabs {
  display: flex;
}

.bill-tab-item {
  padding: 14px;
  margin: 14px;
  border-radius: 3px;
}

.activeTab {
  background: #2b82be;
  color: white;
}

.table-options {
  display: flex;
}
.itemised-txn tr:last-child td {
  font-weight: normal;
}
.footer-table .md-table-content .md-table-row .md-table-cell {
  width: 150px;
}
.footer-table tr td:nth-child(5) {
  width: 15% !important;
}

.itemised-trans-bill.bill-container .md-dialog-container {
  max-width: 20% !important;
}

.itemised-trans-bill.bill-container {
  height: 80%;
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .footer-table tr td:nth-child(5) {
    width: 30% !important;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1280px) {
  .footer-table tr td:nth-child(5) {
    width: 30% !important;
  }
}
.action-cell {
  height: 100%;
  cursor: auto;
}
#search-mgn {
  margin-right: 100px !important;
}
.action-buttons {
  top: -10px !important;
}
</style>
<style>
.md-table table {
  table-layout: fixed;
}

.md-table-cell {
  word-break: break-all;
}
.md-table .md-table-row td {
  padding-left: 5px !important;
}
.md-table-cell:last-child .md-table-cell-container {
  padding-right: 5px !important;
}
.md-table-head:last-child {
  text-align: center !important;
  padding-right: 5px !important;
}
/* .report-table .md-table-head .md-table-head-container {
} */
@media (max-width: 1440px) {
  .itemised-trans .md-table-head-label {
    white-space: normal;
  }
  .md-table-cell {
    font-size: 12px;
  }
}
.action-cell .md-table-cell-container {
  height: 100% !important;
}
.md-table table {
  height: 100% !important;
}
.action-cell .md-table-cell-container .action-btn {
  height: 100% !important;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  cursor: pointer;
}
</style>
