<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header
          class="md-card-header-text md-card-header-green md-layout md-gutter"
        >
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <router-link :to="{ path: '/business-vertical/all-outlets' }">
                <img src="../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h3>{{ 'Edit Outlet' }}</h3>
          </div>
        </md-card-header>
        <div class="card-content-align">
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Outlet Name') }}</label>
                  <md-input
                    type="text"
                    v-model="outlets.outlet.name"
                  ></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <label>{{ $t('Is Voucher') }}</label>
                <md-checkbox v-model="outlets.login.is_voucher"> </md-checkbox>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <label>{{ $t('Want To Reset Password ?') }}</label>
                <md-checkbox v-model="resetPassword"> </md-checkbox>
              </div>
              <div class="md-layout-item md-size-50">
                <label>{{ $t('Is Music ?') }}</label>
                <md-checkbox v-model="outlets.login.is_music"> </md-checkbox>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Status') }}</label>
                  <md-select v-model="outlets.login.status">
                    <md-option value="active">Active</md-option>
                    <md-option value="inactive">Inactive</md-option>
                    <md-option value="disabled">Disable</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <label>{{ $t('Is Online ?') }}</label>
                <md-checkbox v-model="outlets.outlet.is_online"> </md-checkbox>
              </div>
            </div>

            <div class="md-layout" v-if="resetPassword">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Reset Password') }}</label>
                  <md-input
                    type="text"
                    v-model="outlets.login.password"
                  ></md-input>
                </md-field>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Token') }}*</label>
                  <md-input
                    v-model="outlets.outlet.flipdish_token"
                    data-vv-name="Token"
                    type="text"
                    v-on:blur="updateStores()"
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <label>{{ $t('Subscription ?') }}</label>
                <md-checkbox v-model="outlets.outlet.subscription_enabled">
                </md-checkbox>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Apps') }}</label>
                  <md-select v-model="outlets.outlet.flipdish_app_id">
                    <md-option
                      :value="item.AppId"
                      v-for="item in apps"
                      :key="item.AppId"
                      >{{ item.Name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <label>{{ $t('BOI ?') }}</label>
                <md-checkbox v-model="integrations.boi"> </md-checkbox>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Stripe Price') }}</label>
                  <md-select v-model="outlets.outlet.stripe_price_id">
                    <md-option
                      v-for="item in stripePrices"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.nickname }}
                      {{ item.unit_amount > 0 ? item.unit_amount / 100 : '' }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <label>{{ $t('Clover ?') }}</label>
                <md-checkbox v-model="integrations.clover"> </md-checkbox>
              </div>
            </div>

            <div class="btn-div">
              <md-card-actions class="text-center">
                <md-button
                  native-type="submit"
                  class="md-primary"
                  @click.native.prevent="submitOutlet"
                  >{{ $t('SAVE') }}</md-button
                >
              </md-card-actions>
            </div>
          </md-card-content>
        </div>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Tabs } from '@/components'
import { SlideYDownTransition } from 'vue2-transitions'
import { Collapse } from '@/components'
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
import { Promise } from 'q'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import BusinessHours from 'vue-business-hours'
import { stripeService } from '../../../services/stripe.js'

export default {
  components: {
    Tabs,
    Collapse,
    SlideYDownTransition,
    ClipLoader,
    BusinessHours,
  },

  data() {
    return {
      modelValidations: {},
      stripePrices: [],
      selectedPrice: '',
      outletId: '',
      imgUrl: '',
      token: '',
      outlets: {
        outlet: {},
        login: {
          user_type: 'user',
        },
      },
      // packageId: accountService.userPackage(),
      roles: [],
      priceLevels: [],
      outlet_package: [],
      custom_package: [],
      showSubDialog: false,
      // assignModule: false,
      array: [],
      subarray: [],
      file: '',
      loader: false,
      resetPassword: false,
      apps: [],
      appId: '',
      integrations: { clover: false, boi: false },
    }
  },
  methods: {
    business() {
      // console.log(this.days)
    },
    onFileChanged(event) {
      this.file = event.target.files[0]
      this.imgUrl = URL.createObjectURL(this.file)
    },
    handleSelects(e) {
      this.images = []
      let fileList = Array.prototype.slice.call(e.target.files)
      fileList.forEach((f) => {
        if (!f.type.match('image.*')) {
          return
        }

        let reader = new FileReader()
        let that = this

        reader.onload = function (e) {
          that.images.push(e.target.result)
        }
        reader.readAsDataURL(f)
      })
    },
    stripePriceList() {
      stripeService.priceList().then((res) => {
        this.stripePrices = res.data.data
      })
    },
    addPriceLevel() {
      this.priceLevels.push({
        chain_id: accountService.getChainId(),
        name: '',
        outlet_id: this.outletId,
      })
    },
    submitOutlet() {
      this.outlets.outlet.integrations = this.integrations
      let data = {
        outlets: this.outlets,
      }
      customizeService.editOutlet(this.outletId, data).then((res) => {
        this.$router.push({
          path: '/business-vertical/all-outlets',
        })
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitOutlet()
          }
        })
      }
    },

    resetOutlet() {},

    editOutlet() {
      this.formType = 'Edit Outlet'
    },
    getStores() {
      customizeService.getFlipDishStore(this.appId).then((res) => {
        this.stores = res.data.Data
        // this.storeId = res.data.Data[0].StoreId
      })
    },
    updateStores() {
      let body = {
        flipdish_token: this.outlets.outlet.flipdish_token,
      }
      customizeService.updateFlipDishToken(body, this.outletId).then((res) => {
        // this.appId = res.data[0].AppId
        this.apps = res.data
        this.getFlipdishApps()
      })
    },

    getFlipdishApps() {
      customizeService
        .getFlipdishApps(this.outletId)
        .then((res) => {
          this.apps = res.data.Data
        })
        .catch((err) => console.log(err))
    },
  },

  created() {
    this.outletId = this.$route.query.client

    if (this.formType != 'Add Outlet') {
      customizeService.getOutletById(this.outletId).then((res) => {
        this.outlets.outlet = res.data.outlet
        this.outlets.login = res.data.login
        this.outlets.outlet.subscription_enabled =
          res.data.outlet.subscription_status
        this.updateStores()
        this.outlets.outlet.flipdish_app_id = res.data.outlet.flipdish_app_id
        this.integrations = res.data.outlet.integrations
          ? res.data.outlet.integrations
          : {}
      })
    }
    this.stripePriceList()
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.package-name {
  margin-top: 8%;
}

.text-center {
  justify-content: center !important;
}

.btn-add-module {
  text-align: center;
}

.arrow_left {
  float: left;
  padding-top: 21px;
}

.md-card .md-card-actions {
  border: none;
}

.file_Label {
  margin-bottom: 10px;
}

.edit-img {
  padding: 32px;
  float: right;
}

.card-content-align {
  padding: 0px 18px;
}

.md-card img {
  width: 19px;
}

.user-details {
  padding-bottom: 15px;
}

.upload-field {
  .custom-file-input {
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    padding-left: 60px;
    position: relative;
    height: 30px;
    padding-top: 6px;
    color: #3c4858;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Upload Logo';
    color: black;
    display: inline-block;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
    position: absolute;
    left: 0px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 12px;
    padding: 7px 12px;
    top: -2px;
    color: #3c4858;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
}
.md-card {
  .uploaded-img {
    width: 250px;
  }
}
.add-hours {
  display: none !important;
}
</style>
