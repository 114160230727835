<template>
  <div class="md-layout vouchers-table">
    <div class="md-layout-item pd-l">
      <md-card class="block-bg">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="title text-white d-flex align-items-center">
            <router-link :to="{ name: 'Import Vouchers' }">
              <md-icon class="add-icon">add</md-icon>
            </router-link>
            {{ $t('Voucher List') }}
          </h3>
          <md-card-header
            class="md-card-header-icon md-card-header-green d-flex align-items-center justify-content-between"
          >
            <div class="active-inactive-tab" v-if="!loader">
              <div class="onoffswitch">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox-main"
                  id="myonoffswitch-main"
                  tabindex="0"
                  :value="activetab"
                  @change="updateInput($event)"
                  :checked="activetab ? true : false"
                />
                <label class="onoffswitch-label" for="myonoffswitch-main">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
          </md-card-header>
        </div>
        <md-card-content>
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <div style="clear: both"></div>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover main-category"
            @md-selected="viewClientDetail"
            v-if="!loader"
          >
            <md-table-toolbar class="table-toolbar-align">
              <md-field
                class="outlets-list"
                v-if="$store.state.account.profile.share_voucher"
              >
                <label>Outlet</label>
                <md-select
                  v-model="outletIds"
                  multiple
                  @md-closed="
                    outletIds.length > 0 ? filterVouchers() : (outletIds = [])
                  "
                >
                  <md-option
                    v-for="item in outletList"
                    :key="item.id"
                    :value="item.id"
                    class="oulet-select-list-item"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
              <md-field id="search-mgn">
                <label>Search</label>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style=""
                  v-model="searchQuery"
                ></md-input>
                <i class="fa fa-search search-align" aria-hidden="true"></i>
              </md-field>
              <div class="action-buttons export-btn">
                <div class="pdf-el">
                  <div class="download-btn">
                    <img
                      src="../../../assets/images/pdf.svg"
                      @click="downloadWithoutCSS()"
                    />
                  </div>
                </div>
                <div class="excel-el">
                  <vue-excel-xlsx
                    class="Excel-btn"
                    :data="tableData"
                    :columns="columns"
                    :filename="
                      activetab
                        ? 'Vouchers Report Active'
                        : 'Vouchers Report Redeemed'
                    "
                    :sheetname="
                      activetab
                        ? 'Vouchers Report Active'
                        : 'Vouchers Report Redeemed'
                    "
                    :outlet-names="selectedOutletsNames()"
                  >
                  </vue-excel-xlsx>
                </div>
              </div>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                v-bind:md-label="$t('Voucher No.')"
                md-sort-by="voucher_no"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.voucher_no }}
                </div>
              </md-table-cell>
              <md-table-cell
                v-bind:md-label="$t('Legacy No.')"
                md-sort-by="legacy_no"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.legacy_no }}
                </div>
              </md-table-cell>
              <md-table-cell
                v-bind:md-label="$t('Opening Balance')"
                md-sort-by="voucher_amount"
              >
                <div @click="viewClientDetail(item)">
                  <!-- {{ getOpeningBalance(item.actions) }} -->
                  {{ item.opening_amount }}
                </div>
              </md-table-cell>
              <md-table-cell
                v-bind:md-label="$t('Closing Balance')"
                md-sort-by="voucher_amount"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.voucher_amount }}
                </div>
              </md-table-cell>
              <md-table-cell
                v-bind:md-label="$t('Created Date')"
                md-sort-by="created_at"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.created_at }}
                </div>
              </md-table-cell>
              <md-table-cell
                v-bind:md-label="$t('Expiry Date')"
                md-sort-by="expiry_date"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.expiry_date }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Redeemed')">
                <div @click="viewClientDetail(item)">
                  <i
                    v-bind:class="[
                      'check-align',
                      item.redeemed ? 'fa fa-check ' : 'fa fa-ban',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
              </md-table-cell>
              <md-table-cell
                class="center"
                v-bind:md-label="$t('Redeem Date')"
                md-sort-by="redeem_date"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.redeem_date ? item.redeem_date : '' }}
                </div>
              </md-table-cell>

              <md-table-cell
                class="center"
                v-bind:md-label="$t('Customer Name')"
                md-sort-by="customer_name"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.customer_name }}
                </div>
              </md-table-cell>
              <md-table-cell
                class="center"
                v-bind:md-label="$t('Phone Number')"
                md-sort-by="phone_no"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.phone_no }}
                </div>
              </md-table-cell>
              <md-table-cell
                class="center"
                v-bind:md-label="$t('Email')"
                md-sort-by="customer_email"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.customer_email }}
                </div>
              </md-table-cell>
              <md-table-cell
                class="center"
                v-bind:md-label="$t('Last Outlet')"
                v-if="$store.state.account.profile.share_voucher"
              >
                <div @click="viewClientDetail(item)">
                  {{
                    item.last_outlet
                      ? outletNames[item.last_outlet]
                      : outletNames[item.outlet_id]
                  }}
                </div>
              </md-table-cell>
              <md-table-cell
                class="center"
                v-bind:md-label="$t('Outlet Created')"
                v-if="$store.state.account.profile.share_voucher"
              >
                <div @click="viewClientDetail(item)">
                  {{ outletNames[item.outlet_id] }}
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- </md-card-actions> -->
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import users from '../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import draggable from 'vuedraggable'
import Sortable from 'sortablejs'
import VueExcelXlsx from '../../../components/VueExcelXlsx'
import { pdfService } from '../../../services/jspdf.js'
import jsPDF from 'jspdf'
import { accountService } from '../../../services/account.js'
import moment from 'moment'
import store from '@/store'

export default {
  components: {
    Pagination,
    ClipLoader,
    draggable,
    Sortable,
    VueExcelXlsx,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData

      if (this.searchedData.length > 0) {
        result = this.searchedData.filter(
          (item) => item.redeemed == !this.activetab
        )
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },

  data() {
    return {
      columns: [
        {
          label: 'Voucher No',
          field: 'voucher_no',
        },
        {
          label: 'Legacy No',
          field: 'legacy_no',
        },
        {
          label: 'Opening Balance',
          field: 'opening_amount',
        },
        {
          label: 'Closing Balance',
          field: 'voucher_amount',
        },
        {
          label: 'Created Date',
          field: 'created_at',
        },
        {
          label: 'Expiry Date',
          field: 'expiry_date',
        },
        {
          label: 'Redeem Date',
          field: 'redeem_date',
        },
        {
          label: 'Customer Name',
          field: 'customer_name',
        },
        {
          label: 'Phone Number',
          field: 'phone_no',
        },
        {
          label: 'Email',
          field: 'customer_email',
        },
      ],
      columnStyles: {
        0: { cellWidth: 38 },
        1: { cellWidth: 38 },
        2: { cellWidth: 38 },
        3: { cellWidth: 38 },
        4: { cellWidth: 38 },
        5: { cellWidth: 38 },
        6: { cellWidth: 38 },
        7: { cellWidth: 38 },
      },
      data: {
        search_type: 'Daily',
      },

      reportApiRes: [],
      currentSort: 'code_order',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      // footerTable: ["Name", "Email", "Age", "Salary", "Actions"],
      searchQuery: '',
      propsToSearch: ['name'],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
      tempData: [],
      activetab: true,
      outletIds: [],
      outletList: [],
      outletNames: {},
      activeVoucherList: [],
    }
  },

  methods: {
    customSort(value) {
      return this.tableData.sort((a, b) => {
        const sortBy = this.currentSort
        if (a[sortBy] == undefined || a[sortBy] == null) {
          return 1
        }
        if (b[sortBy] == undefined || [sortBy] == null) {
          return -1
        }
        if (this.currentSortOrder === 'desc') {
          if (sortBy == 'created_at' || sortBy == 'expiry_date') {
            return (
              moment(b[sortBy], 'DD/MM/YYYY HH:mm') -
              moment(a[sortBy], 'DD/MM/YYYY HH:mm')
            )
          } else {
            return b[sortBy].toString().localeCompare(a[sortBy].toString())
          }
        } else {
          if (
            (b[sortBy] && sortBy == 'created_at') ||
            sortBy == 'expiry_date'
          ) {
            return (
              moment(a[sortBy], 'DD/MM/YYYY HH:mm') -
              moment(b[sortBy], 'DD/MM/YYYY HH:mm')
            )
          }
        }
        return a[sortBy].toString().localeCompare(b[sortBy].toString())
      })
    },

    alert(value, id) {
      var self = this
      if (value === 'Delete') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.deleteMainCategory(id)
          }
        })
      } else if (value === 'Edit') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.updateItemStatus(id)
          }
        })
      }
    },

    getOpeningBalance(action) {
      let amount = ''
      if (action) {
        let actioElement = action.find((el) => el.actionType == 'create')
        amount = actioElement.amount.toFixed(2)
      }
      return amount
    },

    viewClientDetail(item) {
      this.$router.push({
        name: 'Vouchers Audit',
        query: { formType: 'View Main Category', client: item.id },
      })
    },

    getItems() {
      var self = this
      this.loader = true
      customizeService.getVouchers().then((res) => {
        // self.tableData = res.data.api_v1_main_category
        res.data.map((el) => {
          el.actions
            ? (el['opening_amount'] = this.getOpeningBalance(el.actions))
            : ''
        })
        this.reportApiRes = res.data

        self.tempData = JSON.parse(JSON.stringify(res.data))
        self.tableData = self.tempData.filter((item) => item.redeemed == false)
        self.tempData.map((el) => {
          el.created_at = moment.utc(el.created_at).format('DD/MM/YYYY H:mm')
          el.expiry_date = moment.utc(el.expiry_date).format('DD/MM/YYYY H:mm')
          el.opening_amount = el.opening_amount
            ? parseFloat(el.opening_amount).toFixed(2)
            : ''
          el.voucher_amount = parseFloat(el.voucher_amount).toFixed(2)
          el.redeem_date = el.redeem_date
            ? moment.utc(el.redeem_date).format('DD/MM/YYYY H:mm')
            : ''
        })

        customizeService.getOutlets().then((res) => {
          res.data.outlets.map((outlet) => {
            this.outletNames[outlet.id] = outlet.name
          })
          // this.outletIds = [accountService.getOutletId()]
          if (store.state.account.profile.share_voucher) {
            this.outletIds = res.data.outlets.map((outlet) => {
              return outlet.id
            })
            this.outletList = res.data.outlets.filter((x) =>
              store.state.account.profile.voucher_outlets.includes(x.id)
            )
          } else {
            this.outletIds = [accountService.getOutletId()]
            this.outletList = res.data.outlets
          }
          this.loader = false
        })
      })
    },

    filterBystatus(status) {
      this.activetab = status
      this.searchedData = []

      this.tableData = this.tempData.filter((item) => item.redeemed == !status)
    },
    updateInput(event) {
      let status = event.target.checked ? true : false
      this.filterBystatus(status)
    },
    filterVouchers() {
      this.tableData = this.tempData.filter(
        (item) =>
          this.outletIds.includes(item.outlet_id) &&
          item.redeemed == !this.activetab
      )
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletIds.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    arrangeForPdf() {
      var body = []
      this.data.startDate = ''
      this.data.endDate = ''
      this.tableData.forEach((el) => {
        let arrayObj = [
          el.voucher_no,
          el.legacy_no,
          el.opening_amount,
          el.voucher_amount,
          el.created_at,
          el.expiry_date,
          el.redeem_date,
          el.customer_name,
          el.phone_no,
          el.customer_email,
        ]
        body.push(arrayObj)
      })
      body.push(['', '', '', '', '', '', '', '', ''])

      return body
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })

      let head = [
        [
          'Voucher #',
          'Legacy #',
          'Opening Bal.',
          'Closing Bal.',
          'Created Date',
          'Expiry Date',
          'Redeem Date',
          'Customer Name',
          'Phone',
          'Email',
        ],
      ]
      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Voucher Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        {},
        'voucher-sales-report.pdf'
      )
    },
  },
  mounted() {},
  //   updated: function () {
  //     let table = document.querySelector('.main-category tbody')
  //     const _self = this
  //     Sortable.create(table, {
  //       onEnd({ newIndex, oldIndex }) {
  //         const rowSelected = _self.tableData.splice(oldIndex, 1)[0] // Get the selected row and remove it
  //         _self.tableData.splice(newIndex, 0, rowSelected) // Move it to the new index
  //         _self.updateItemOrder()
  //       },
  //     })
  //   },

  created() {
    this.getItems()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: [
            'voucher_no',
            'customer_name',
            'legacy_no',
            'phone_no',
            'customer_email',
          ],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.vouchers-table .onoffswitch-inner:after {
  content: 'Redeemed' !important;
}
.vouchers-table .onoffswitch-switch {
  right: 70px;
}
.vouchers-table .onoffswitch-inner:before {
  padding-left: 38px;
}
.vouchers-table .onoffswitch {
  width: 110px !important;
}
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.color-btn {
  height: 15px;
}

.drag-row {
  display: contents !important;
}

.draggable-table .md-table-head {
  padding-left: 15px;
  text-align: left;
}

.vouchers-table .md-table-cell .md-table-cell-container {
  padding-right: 0px !important;
}

.draggable-table .md-table-head:nth-child(3) {
  width: 50px;
}

.draggable .md-table-head-container {
  height: auto;
  padding: 5px 0px;
}

.super-table .md-table-row {
  cursor: move;
}

.active-inactive-btn li {
  list-style: none;
  float: right;
}
.active {
  border-bottom: 2px solid #2b82be !important;
}
.fa-ban {
  color: red;
}

.super-table
  .md-table-head:nth-child(3)
  .md-table-head-container
  .md-table-head-label {
  padding: none;
}
.main-cat-table .md-table-row {
  cursor: move;
}
</style>
<style>
.main-cat-table
  .md-table-head:nth-child(4)
  .md-table-head-container
  .md-table-head-label,
.main-cat-table
  .md-table-head:nth-child(5)
  .md-table-head-container
  .md-table-head-label {
  padding-left: 11px !important;
}
.main-cat-table .md-table-cell:nth-child(4),
.main-cat-table .md-table-cell:nth-child(5) {
  padding-left: 16px !important;
}
.outlets-list {
  margin-left: auto;
}
.md-table-head-label {
  font-size: 13px !important;
}

.paginated-table .md-table-cell-container {
  padding-right: 0px !important;
}
#search-mgn {
  margin-right: 100px !important;
}
</style>
