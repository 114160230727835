<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/outlet/discount-category' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType === 'View Discount Category'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editDiscountCat"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                v-model="discount.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Discount Category'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Type') },
                { 'md-valid': !errors.has('Type') && touched.Type },
              ]"
            >
              <label for="Country">{{ $t('Type') }}</label>
              <md-select
                v-model="discount.disc_type"
                :disabled="formType == 'View Discount Category'"
                data-vv-name="Type"
                v-validate="modelValidations.Type"
              >
                <md-option class="first-opt" disabled>Type</md-option>
                <md-option value="percentage">{{ $t('Percentage') }}</md-option>
                <md-option value="value">{{ $t('Value') }}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Type')">
                  {{ errors.first('Type') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Type') && touched.Type"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('Type') },
                { 'md-valid': !errors.has('Type') && touched.Type }
              ]"
            >
              <label for="Country">{{$t('Type')}}</label>
              <md-select
                v-model="discount.disc_type"
                :disabled="formType == 'View Discount Category'"
                data-vv-name="Type"
                v-validate="modelValidations.Type"
              >
                <md-option value="percentage">{{$t('Percentage')}}</md-option>
                <md-option value="value">{{$t('Value')}}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Type')">{{errors.first("Type")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('Type') && touched.Type">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Discount') },
                { 'md-valid': !errors.has('Discount') && touched.Discount },
              ]"
            >
              <label>{{ $t('Discount') }}</label>
              <md-input
                v-model="discount.discount_percent"
                data-vv-name="Discount"
                type="number"
                min="0"
                v-validate="modelValidations.Discount"
                :disabled="formType == 'View Discount Category'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Discount')">
                  {{ errors.first('Discount') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Discount') && touched.Discount"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-checkbox
                v-model="discount.is_delivery"
                :disabled="formType === 'View Discount Category'"
                >{{ $t('Is Delivery?') }}</md-checkbox
              >
            </div>
          </div>
        </div>
        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-checkbox
              v-model="discount.is_delivery"
              :disabled="formType==='View Discount Category'"
            >{{$t('Is Delivery?')}}</md-checkbox>
          </div>
        </div> -->
        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Discount Category'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/outlet/discount-category' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              class="md-primary"
              native-type="submit"
              @click.native.prevent="validate"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import axios from 'axios'
import { accountService } from '../../../services/account'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    SlideYDownTransition,
    axios,
    ClipLoader,
  },
  props: {},

  data() {
    return {
      Name: '',
      Type: '',
      switch2: null,
      Discount: '',

      touched: {
        Name: false,
        Type: false,
        Discount: false,
      },

      modelValidations: {
        Name: {
          required: true,
        },
        Type: {
          required: true,
        },
        Discount: {
          required: true,
        },
      },

      formType: {
        type: String,
        default: 'Add Sub Category',
      },

      clientDetail: {
        default: '',
      },

      discount: { is_delivery: false },
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createDiscountCat()
          }
        })
      }
    },

    createDiscountCat(url) {
      let self = this
      this.discount.outlet_id = accountService.getOutletId()
      if (this.formType == 'Add Discount Category') {
        customizeService
          .createDiscountCat(this.discount)
          .then((res) => {
            self.$router.push({ name: 'DiscountCategory' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        let body = {
          discount_categories: this.discount,
        }
        axios
          .put(
            this.url + '.json' + '?outlet_id=' + accountService.getOutletId(),
            body,
            customizeService.authFun()
          )
          .then((res) => {
            self.$router.push({ name: 'DiscountCategory' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      }
    },

    reset() {
      this.discount = {}
    },

    editDiscountCat() {
      this.formType = 'Edit Discount Category'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    if (this.formType != 'Add Discount Category') {
      this.loader = true
      axios
        .get(
          this.url + '?outlet_id=' + accountService.getOutletId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.loader = false
          this.discount = res.data.discount_categories
        })
    }
  },

  watch: {
    Name() {
      this.touched.required = true
    },
    Type() {
      this.touched.required = true
    },
    Discount() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
