<template>
  <div>
    <md-card class="end-of-day block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('End of Day') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout md-layout-item filter-section pd-l-0 block-bg">
            <div class="md-layout-item md-size-55">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout-item md-size-45">
              <div class="md-layout search-section">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>Terminals</label>
                    <md-select v-model="queries.terminalId">
                      <md-option
                        v-for="(item, index) in terminals"
                        :key="index"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-size-25"
                  v-if="userRole == 'branch_user'"
                >
                  <md-field>
                    <label>Outlets</label>
                    <md-select
                      v-model="queries.outletId"
                      multiple
                      @md-closed="
                        queries.outletId.length > 0
                          ? endOfDayReport()
                          : (queries.outletId = [])
                      "
                    >
                      <md-option
                        v-for="item in outlets"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-25">
                  <div class="float-right">
                    <md-button class="md-teritiary" @click="endOfDayReport"
                      >SEARCH</md-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div class="md-layout" v-if="!loader">
          <div class="md-layout-item report-table hourly-order">
            <md-table class="table-typ1">
              <md-table-toolbar>
                <div class="action-buttons v-img-btn">
                  <div class="pdf-el">
                    <div class="download-btn">
                      <img
                        src="../../../assets/images/pdf.svg"
                        @click="downloadWithoutCSS()"
                      />
                    </div>
                  </div>

                  <div class="excel-el">
                    <vue-excel-xlsx
                      class="Excel-btn"
                      :data="report"
                      :columns="columns"
                      :filename="'end-of-day'"
                      :sheetname="'End Of Day'"
                      :sdate="sdate"
                      :edate="edate"
                    >
                    </vue-excel-xlsx>
                  </div>
                </div>
              </md-table-toolbar>
              <md-table-row>
                <md-table-head>Terminal</md-table-head>
                <md-table-head
                  v-if="$store.state.account.profile.user_type == 'branch_user'"
                  >Outlet</md-table-head
                >

                <md-table-head>Cashup ID</md-table-head>
                <md-table-head>Date</md-table-head>
                <md-table-head>Cash Sales</md-table-head>
                <md-table-head>Card Sales</md-table-head>
                <md-table-head>Service Charge</md-table-head>
                <md-table-head>Gratuity</md-table-head>
                <md-table-head>Other Fee</md-table-head>
                <md-table-head>Other</md-table-head>
                <md-table-head>Voucher</md-table-head>
                <md-table-head>Total In Drower</md-table-head>
                <md-table-head>Opening Float</md-table-head>
                <md-table-head>Petty Cash</md-table-head>
                <md-table-head>Expected Cash</md-table-head>
                <md-table-head>Counted Cash</md-table-head>
                <md-table-head>Difference Cash</md-table-head>
                <md-table-head>Expected Card</md-table-head>
                <md-table-head>Counted Card</md-table-head>
                <md-table-head>Difference Card</md-table-head>

                <md-table-head>Image</md-table-head>
              </md-table-row>

              <md-table-row v-for="(item, index) in report" :key="index">
                <md-table-cell>{{ item.terminal }}</md-table-cell>
                <md-table-cell
                  v-bind:md-label="$t('Outlet')"
                  md-sort-by="outlet_name"
                  v-if="$store.state.account.profile.user_type == 'branch_user'"
                >
                  <div>
                    {{ item.outlet_name }}
                  </div>
                </md-table-cell>
                <md-table-cell>{{ item.cash_up_id }}</md-table-cell>
                <md-table-cell>{{ item.date }}</md-table-cell>
                <md-table-cell>{{ item.cash_sale }}</md-table-cell>
                <md-table-cell>{{ item.card_sale }}</md-table-cell>
                <md-table-cell>{{ item.service_charge }}</md-table-cell>
                <md-table-cell>{{ item.gratuity }}</md-table-cell>
                <md-table-cell>{{ item.other_fee_total }}</md-table-cell>
                <md-table-cell>{{ item.other }}</md-table-cell>
                <md-table-cell>{{ item.voucher }}</md-table-cell>
                <md-table-cell>{{ item.total_sale }}</md-table-cell>
                <md-table-cell>{{ item.opening_float }}</md-table-cell>
                <md-table-cell>{{ item.petty_cash }}</md-table-cell>
                <md-table-cell>{{ item.expected_cash }}</md-table-cell>
                <md-table-cell>{{ item.counted_cash }}</md-table-cell>
                <md-table-cell>{{ item.difference_cash }}</md-table-cell>
                <md-table-cell>{{ item.expected_card }}</md-table-cell>
                <md-table-cell>{{ item.counted_card }}</md-table-cell>
                <md-table-cell>{{ item.difference_card }}</md-table-cell>

                <md-table-cell>
                  <a
                    class="vw-image"
                    @click="base64ToBuffer(item.image)"
                    v-if="item.image"
                    >View Image</a
                  >

                  <a
                    class="vw-image"
                    @click="openEod(item.eod_report_json)"
                    v-if="!item.image && item.eod_report_json"
                    >View Image</a
                  >
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-dialog
      class="itemised-trans-bill bill-container"
      :md-active.sync="showEod"
    >
      <div class="eod-table">
        <template v-for="(item, index) in activeEod">
          <table :key="index + 'table'">
            <div>{{ item.title }}</div>
            <tr :key="index + 'row'">
              <template v-for="(header, index) in item.header">
                <td :key="index + 'header'">{{ header }}</td>
              </template>
            </tr>
            <template v-for="(row, index) in item.row">
              <tr :key="index">
                <template v-for="(col, index) in row.col">
                  <td :key="index + 'col'">{{ col }}</td>
                </template>
              </tr>
            </template>
            <tr :key="index + 'footer-row'">
              <template v-for="(footer, index) in item.footer">
                <td :key="index + 'footer'">{{ footer }}</td>
              </template>
            </tr>
          </table>
        </template>
      </div>
    </md-dialog>
    <WindowPortal v-model="open" ref="WindowPortal">
      <EOD :msg="msg" />
    </WindowPortal>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { accountService } from '../../../services/account.js'
import { reportService } from '../../../services/reports.js'
import { customizeService } from '../../../services/customize.js'
import { pdfService } from '../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import LineChart from '../../../components/Graphs/LineChart.vue'
import datetime from 'vuejs-datetimepicker'
import pako from 'pako'
import DatePeriodDropdown from '../../../components/DatePeriodDropdown'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../components/VueExcelXlsx'
import EOD from '@/components/EOD'
import WindowPortal from '@/components/WindowPortal'

const employee = {
  name: 'Mayank',
  designation: 'Developer',
  address: {
    street: 'Rohini',
    city: 'Delhi',
  },
}

Object.freeze(employee)

export default {
  components: {
    LineChart,
    ChartCard,
    datetime,
    DatePeriodDropdown,
    ClipLoader,
    VueExcelXlsx,
    EOD,
    WindowPortal,
  },

  data() {
    return {
      search: null,
      searched: [],
      report: [],
      open: false,
      msg: '',
      columns: [
        {
          label: 'Terminal',
          field: 'terminal',
        },
        {
          label: 'Cashup ID',
          field: 'cash_up_id',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Cash Sales',
          field: 'cash_sale',
        },
        {
          label: 'Card Sales',
          field: 'card_sale',
        },
        {
          label: 'Service Charge',
          field: 'service_charge',
        },
        {
          label: 'Gratuity',
          field: 'gratuity',
        },
        {
          label: 'Other Fee',
          field: 'other_fee_total',
        },

        {
          label: 'Other',
          field: 'other',
        },
        {
          label: 'Voucher',
          field: 'voucher',
        },
        {
          label: 'Total Sales',
          field: 'total_sale',
        },
        {
          label: 'Opening Float',
          field: 'opening_float',
        },
        {
          label: 'Petty Cash',
          field: 'petty_cash',
        },
        // {
        //   label: 'Variance',
        //   field: 'variance',
        // },
        {
          label: 'Expected Cash',
          field: 'expected_cash',
        },
        {
          label: 'Counted Cash',
          field: 'counted_cash',
        },
        {
          label: 'Difference Cash',
          field: 'difference_cash',
        },
        {
          label: 'Expected Card',
          field: 'expected_card',
        },
        {
          label: 'Counted Card',
          field: 'counted_card',
        },
        {
          label: 'Difference Card',
          field: 'difference_card',
        },
      ],
      columnStyles: {
        0: { columnWidth: 12 },
        1: { columnWidth: 18 },
        2: { columnWidth: 13 },
        3: { columnWidth: 14 },
        4: { columnWidth: 14 },
        5: { columnWidth: 15 },
        6: { columnWidth: 16 },
        7: { columnWidth: 13 },
        8: { columnWidth: 15 },
        9: { columnWidth: 15 },
        10: { columnWidth: 19 },
        11: { columnWidth: 15 },
        12: { columnWidth: 16 },
        13: { columnWidth: 15 },
        14: { columnWidth: 15 },
        15: { columnWidth: 15 },
        16: { columnWidth: 15 },
        17: { columnWidth: 16 },
        18: { columnWidth: 16 },
      },
      datacollection: null,
      userRole: '',
      terminals: [],
      outlets: [],
      queries: {
        outletId: [],
        search_type: 'Daily',
      },
      loader: false,
      resultImage: null,
      showEod: false,
      activeEod: null,
      sdate: '',
      edate: '',
    }
  },

  methods: {
    setDate(date) {
      this.queries.fromDate = date
    },
    async openEod(eod) {
      this.msg = eod
      await this.$refs.WindowPortal.closePortal()
      this.open = true
    },

    setDateType(interval) {
      this.queries.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.queries.startDate = interval.startDate
        this.queries.endDate = interval.endDate
      } else {
        this.queries.startDate = interval.date
        this.queries.endDate = undefined
        this.endOfDayReport()
      }
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = [
          el.terminal,
          el.cash_up_id,
          el.date,
          el.cash_sale,
          el.card_sale,
          el.service_charge,
          el.gratuity,
          el.other_fee_total,
          el.other,
          el.voucher,
          el.total_sale,
          el.opening_float,
          el.petty_cash,
          // el.variance,
          el.expected_cash,
          el.counted_cash,
          el.difference_cash,
          el.expected_card,
          el.difference_cash,
          el.difference_card,
        ]
        body.push(arrayObj)
      })
      return body
    },
    jsonToHtml() {
      var newWin = window.open(
        '',
        'Title',
        'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=1000,top=0,left=0'
      )

      newWin.document.write('html to write...')
    },

    base64ToBuffer(str) {
      // this.showEod = true
      // this.activeEod = data

      str = window.atob(str) // creates a ASCII string
      var buffer = new ArrayBuffer(str.length),
        view = new Uint8Array(buffer)
      for (var i = 0; i < str.length; i++) {
        view[i] = str.charCodeAt(i)
      }
      let result = pako.inflate(buffer)
      this.resultImage = String.fromCharCode.apply(
        null,
        new Uint16Array(result)
      )
      var win = window.open(
        '',
        'Title',
        'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=1000,top=0,left=0'
      )
      win.document.body.innerHTML = this.resultImage
      if (win.document.body.querySelector('#imageHolder img')) {
        win.document.body.querySelector('#imageHolder img').onerror = null
        win.document.body.querySelector('#imageHolder img').src =
          this.$store.getters.getProfile.logo
      }

      // console.log(win.document.body.querySelector('#imageHolder img'))
      //   "this.style.display = 'none'"
      let btn = document.createElement('button')
      btn.innerHTML = 'Download'
      btn.id = 'eod-download'

      btn.onclick = function () {
        html2canvas(win.document.body)
          .then((canvas) => {
            var list = document.getElementsByTagName('canvas')
            //you can also do this with classes.

            while (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                list[i].remove()
              }
            }
            document.body.appendChild(canvas)
          })
          .then(() => {
            var canvas = document.querySelector('canvas')
            canvas.style.display = 'none'
            var image = canvas
              .toDataURL('image/png')
              .replace('image/png', 'image/octet-stream')
            var a = document.createElement('a')
            a.setAttribute('download', 'EOD' + '.png')
            a.setAttribute('href', image)
            a.click()
            a.remove()
          })
        return false
      }
      win.document.body.prepend(btn)
    },

    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })

      let head = [
        [
          'iPad',
          'Cashup ID',
          'Date',
          'Cash',
          'Card',
          'Serv Chrg',
          'Grtty',
          'Oth Fee',
          'Oth',
          'Vchr',
          'Tot In Drawer',
          'Opng Float',
          'Petty Cash',
          // 'Variance',
          'Exp. Cash',
          'Cntd Cash',
          'Diff. Cash',
          'Exp. Card',
          'Cntd Card',
          'Diff. Card',
        ],
      ]

      pdfService.headerFooterConfig(this.queries, doc, 'End Of Day')
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        this.columnStyles,
        'end-of-day.pdf'
      )
    },

    endOfDayReport() {
      this.loader = true
      reportService.getEndOfDay(this.queries).then((res) => {
        this.loader = false
        this.report = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate
      })
    },
  },

  created() {
    this.userRole = accountService.userRole()
    this.loader = true
    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.endOfDayReport()
      })
    } else {
      if (this.$route.query.s && this.$route.query.e) {
        this.queries.search_type = 'Ad Hoc'
        this.queries.startDate = this.$route.query.s
        this.queries.endDate = this.$route.query.e
      }
      this.endOfDayReport()
    }

    customizeService.getterminals().then((res) => {
      this.terminals = res.data
      this.terminals.unshift({ id: 'undefined', name: 'All' })
    })
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}
.main-heading {
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 18px;
}

.toolbar-monthly {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.arrow_left {
  float: left;
  padding-top: 21px;
}
.eod-table {
  overflow: scroll;
}
.v-img-btn {
  z-index: 0 !important;
}
.action-buttons {
  top: -3px !important;
}
.vw-image {
  cursor: pointer;
  display: block;
  text-align: center;
  word-break: break-word;
}
</style>
<style>
.md-table-head-label {
  font-size: 13px !important;
}
.end-of-day .md-table-head-label {
  font-size: 11px !important;
}
</style>
