export const environment = {
  client: {
    id: process.env.VUE_APP_API_CLIENT_ID,
    secret: process.env.VUE_APP_API_CLIENT_SECRET,
  },
  api: {
    host: process.env.VUE_APP_API_URL,
    cbePayHost: process.env.VUE_APP_CBE_PAY_API_URL,
    services: {
      accounts: {
        oauth: '/oauth/token',
        access_token: '/oauth/token?',
        userdetails: '/api/v1/users',
        userdetailsbyid: '/api/v1/users/get_user',
        updateuserdetails: '/api/v1/users/update_user',
        tenant: '/api/v1/users/set_tenant',
        update_password: '/api/v1/users/reset_password',
        user: '/api/v1/users',
      },
      customize: {
        module: '/api/v1/app_modules.json',
        editmodule: '/api/v1/app_modules/',
        package: '/api/v1/app_packages.json',
        editpackage: '/api/v1/app_packages/',
        distributor: '/api/v1/distributors.json',
        editdistributor: '/api/v1/distributors/',
        businessvertical: '/api/v1/chains.json',
        editbusinessvertical: '/api/v1/chains/',
        outlet: '/api/v1/outlets',
        editoutlet: '/api/v1/outlets/',
        supercategory: '/api/v1/item_groups',
        editsupercategory: '/api/v1/item_groups/',
        maincategory: '/api/v1/main_categories',
        editmaincategory: '/api/v1/main_categories/',
        menusection: '/api/v1/menu_sections',
        modifier: '/api/v1/modifiers.json',
        menu_item_lists: '/api/v1/menu_item_lists',
        editmodifier: '/api/v1/modifiers/',
        modifiergroup: '/api/v1/modifier_groups',
        editmodifiergroup: '/api/v1/modifier_groups/',
        subcategory: '/api/v1/sub_categories',
        course: '/api/v1/courses.json',
        station: '/api/v1/stations.json',
        menuitems: '/api/v1/menu_items',
        flipdish: '/api/v1/menu_items/create_flipdish_item',
        online_menu_items: '/api/v1/menu_items/online_menu_items',
        online_orders: '/api/v1/store/get_orders',
        online_order: '/api/v1/store/get_order',
        order_refund: '/api/v1/store/refund_order',
        outlet_online_settings: '/api/v1/outlets/get_online_settings',
        combos: '/api/v1/combos',
        combogroup: '/api/v1/combo_groups',
        portion: '/api/v1/portions',
        taxes: '/api/v1/taxes',
        reprinter: '/api/v1/reprinters.json',
        otherpaymenttype: '/api/v1/other_payment_types',
        discountcategory: '/api/v1/discount_categories',
        scheduledreport: '/api/v1/scheduled_reports',
        table: '/api/v1/outlet_tables',
        tableareas: '/api/v1/table_areas',
        employees: '/api/v1/employes',
        profile: '/api/v1/terminal_profiles',
        terminals: '/api/v1/terminals',
        staffrole: '/api/v1/staff_roles/',
        update_staffrole: '/api/v1/staff_roles/',
        assignitems: '/api/v1/menu_items/assign_items',
        getassignitems: '/api/v1/menu_items/outlet_menu_items',
        roles: '/api/v1/user_package.json',
        getrole: '/api/v1/user_package/',
        voidreasons: '/api/v1/quick_notes',
        menu_category_edit: '/api/v1/menu_items/menu_edit',
        flipdish_menus: '/api/v1/menu_items/flip_dish_menus',
        flipdish_menu_selections: '/api/v1/outlets/flip_dish_menu_select',
        flipdish_by_id: '/api/v1/outlets/get_flipdish_menu_item',
        update_flipdish_token: '/api/v1/outlets/update_fipdish_token',
        get_flipdish_store: '/api/v1/outlets/get_flipdish_stores_by_app_id',
        get_flipdish_menus: '/api/v1/outlets/flip_dish_menus',
        get_map_items: '/api/v1/menu_items/get_map_items',
        get_flipdish_apps: '/api/v1/outlets/get_flipdish_app',
        integrations: '/api/v1/integrations',
        integrations_by_package:
          '/api/v1/integrations/get_package_integrations',
        menu_item_import: '/api/v1/menu_items/import_from_excel',
        employe_info: '/api/v1/labour',
        promotions: '/api/v1/promotions',
        online_promotions: '/api/v1/online_promotions',
        screen: '/api/v1/screens',
      },
      reports: {
        hourlysales: '/api/v1/sales_summary/get_hourly_sales',
        hourlyorder: '/api/v1/sales_summary/get_hourly_order',
        menuitemreport: '/api/v1/product_report/menu_item_report',
        weeklysales: '/api/v1/sales_summary/get_weekly_sales',
        monthlysales: '/api/v1/sales_summary/get_monthly_sales',
        dailysales: '/api/v1/sales_summary/get_daily_sales',
        yearlysales: '/api/v1/sales_summary/get_yearly_sales',
        maincategoryreport: '/api/v1/product_report/main_category_report',
        supercategoryreport: '/api/v1/product_report/super_category_report',
        supercategorybydate: '/api/v1/product_report/super_by_date_report',
        comboreport: '/api/v1/product_report/combo_report',
        subcategoryreport: '/api/v1/product_report/sub_cat_report',
        modifieritemreport: '/api/v1/product_report/modifier_with_item_report',
        modifiersreport: '/api/v1/product_report/modifiers_report',
        salesgpreport: '/api/v1/product_report/sales_gp_report',
        itemisedtransactions:
          '/api/v1/transaction_summary/itemised_transaction',
        terminal_transactions:
          '/api/v1/transaction_summary/terminal_transactions',
        hourlyreport: '/api/v1/transaction_summary/hourly_report',
        hourlysummaryreport:
          '/api/v1/transaction_summary/hourly_summary_report',
        detailedtaxreport: '/api/v1/tax_report/detailed_tax_report',
        taxreportbysalescategory: '/api/v1/tax_report/tax_by_sales_report',
        allmenuitems: '/api/v1/employes/sales_by_menu_item',
        itemcategories: '/api/v1/employes/sales_by_item_categories',
        staffrevenue: '/api/v1/employes/sales_by_revenue',
        taxsummary: '/api/v1/tax_report/tax_summary_report',
        endofday: '/api/v1/sales_summary/end_of_day_report',
        pettycash: '/api/v1/sales_summary/petty_cash_report',
        voidsreport: '/api/v1/voids_report/voids',
        voidsreportbystaff: '/api/v1/voids_report/voids_by_staff',
        voidsreportanalysis: '/api/v1/voids_report/void_report_analysis',
        discountcategoryreport: '/api/v1/discount_categories/report',
        discountcategoryinvoice: '/api/v1/discount_categories/invoice',
        wastagesummary: '/api/v1/wastage_report/summary',
        wastagedetails: '/api/v1/wastage_report/details',
        labourreporttaff: '/api/v1/labour_report/staff',
        voucher_audit_report: '/api/v1/vouchers/audit_report',
        voucher: '/api/v1/vouchers',
      },
      dashboard: {
        dashboard_sales: '/api/v1/dashboard/dashboard_sales',
        sales_category_mix: '/api/v1/dashboard/sales_category_mix',
        payment_mix: '/api/v1/dashboard/payment_mix',
        menu_category_mix: '/api/v1/dashboard/menu_category_mix',
        hourly_sales: '/api/v1/dashboard/hourly_sales',
        online_sales: '/api/v1/online_sales/online_sales_report',
      },
      ipad: {
        ipad_category: '/api/v1/menu_items/create_ipad_category',
        ipad_grid: '/api/v1/menu_items/save_ipad_grid',
        get_grid: '/api/v1/menu_items/ipad_grid_web',
        get_categories: '/api/v1/menu_items/ipad_categories',
        update_category: '/api/v1/menu_items/update_ipad_category',
        delete_category: '/api/v1/menu_items/ipad_category_delete',
      },
      stripe: {
        create_account: '/api/stripe/create_account',
        create_account_link: '/api/stripe/create_account_link',
        create_checkout_session: '/api/stripe/create_checkout_session',
        price_list: '/api/stripe/price_list',
        get_price: '/api/stripe/get_price',
        invoice: '/api/stripe/invoice_list',
      },
      adyen: {
        end_point: '/api/v1/adyen',
      },
      cbePay: {
        onboarding: '/api/cbepayonboarding',
        legalEntity: '/api/LegalEntities',
        payment: '/api/CBEPayments',
        payout: '/api/CBEPayout',
        payoutreconciliation: '/api/CBEPayout/getReportP_P',
      },
    },
  },
}
