<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/outlet/VoidReasons' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType === 'View VoidReasons'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editDiscountCat"
            >edit</i
          >
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Title') },
                { 'md-valid': !errors.has('Title') && touched.Title },
              ]"
            >
              <label>Title*</label>
              <md-input
                v-model="VoidReasons.title"
                data-vv-name="Title"
                type="text"
                v-validate="modelValidations.Title"
                :disabled="formType == 'View VoidReasons'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Title')">
                  {{ errors.first('Title') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Title') && touched.Title"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Description') },
                {
                  'md-valid': !errors.has('Description') && touched.Description,
                },
              ]"
            >
              <label>Description</label>
              <md-input
                v-model="VoidReasons.description"
                data-vv-name="Description"
                type="text"
                v-validate="modelValidations.Description"
                :disabled="formType == 'View VoidReasons'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Description')">
                  {{ errors.first('Description') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Description') && touched.Description"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View VoidReasons'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/outlet/VoidReasons' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              class="md-primary"
              native-type="submit"
              @click.native.prevent="validate"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import axios from 'axios'
import { accountService } from '../../../services/account'
export default {
  components: {
    SlideYDownTransition,
    axios,
  },
  props: {},

  data() {
    return {
      Title: '',
      Description: '',
      Seating: '',
      Area: '',
      Status: '',
      touched: {
        Description: false,
        Title: false,
        Seating: false,
        Area: false,
        Status: false,
      },

      modelValidations: {
        Description: {
          required: true,
        },
        Title: {
          required: true,
        },
        Seating: {
          required: true,
        },
        Area: {
          required: true,
        },
        Status: {
          required: true,
        },
        Description: {
          required: true,
        },
      },

      formType: {
        type: String,
        default: 'Add Sub Category',
      },

      clientDetail: {
        default: '',
      },

      VoidReasons: { status: true },
      VoidReasonsAreaList: [],
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.CreateVoidReasons()
          }
        })
      }
    },

    CreateVoidReasons() {
      let self = this
      this.VoidReasons.outlet_id = accountService.getOutletId()
      this.VoidReasons.status = true

      if (this.formType == 'Add VoidReasons') {
        customizeService
          .createVoidReasons(this.VoidReasons)
          .then((res) => {
            self.$router.push({ path: 'VoidReasons' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        let body = {
          quick_notes: this.VoidReasons,
        }
        axios
          .put(this.url + '.json', body, customizeService.authFun())
          .then((res) => {
            self.$router.push({ path: 'VoidReasons' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      }
    },

    getVoidReasons() {
      customizeService
        .getVoidReasons()
        .then((res) => {
          this.VoidReasons = res.data
        })
        .catch((err) => console.log(err))
    },

    reset() {
      this.VoidReasons = {}
    },

    editDiscountCat() {
      this.formType = 'Edit VoidReasons'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    // this.getVoidReasons();
    if (this.formType != 'Add VoidReasons') {
      axios
        .get(
          this.url + '.json' + '?outlet_id=' + accountService.getOutletId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.VoidReasons = res.data
        })
    }
  },

  watch: {
    Description() {
      this.touched.required = true
    },
    Seating() {
      this.touched.required = true
    },
    Title() {
      this.touched.required = true
    },
    Area() {
      this.touched.required = true
    },
    Status() {
      this.touched.required = true
    },
    Description() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
