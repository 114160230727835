<template>
  <div class="bread-crumbs">
    <div class="bread-item">
      Home
      <span class="bread-slash" v-if="breadCrumbs.length != 0">/</span>
    </div>
    <div class="bread-item" v-for="(item, index) in breadCrumbs" :key="index">
      {{ item.text }}
      <span class="bread-slash" v-if="index != breadCrumbs.length - 1">/</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bread-crumbs',
  data() {
    return {
      breadCrumbs: [],
    }
  },

  created() {
    if (this.$route.meta.breadcrumbs) {
      this.breadCrumbs = this.$route.meta.breadcrumbs
    }
  },

  watch: {
    $route(to, from) {
      if (this.$route.meta.breadcrumbs != undefined) {
        this.breadCrumbs = this.$route.meta.breadcrumbs
      } else {
        this.breadCrumbs = []
      }
    },
  },
}
</script>

<style>
.bread-crumbs {
  display: flex;
  font-size: 12px;
  font-weight: bold;
}

.bread-slash {
  padding-right: 6px;
  padding-left: 4px;
}
</style>
