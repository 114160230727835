<template>
  <div class="md-layout menu-table">
    <div class="md-layout-item">
      <div class="pagination-card">
        <md-card-header class="md-card-header-icon md-card-header-green">
          <h3 class="title">{{ $t('All Users') }}</h3>
        </md-card-header>
        <md-card-content>
          <md-table
            v-model="tableData"
            md-sort="name"
            md-sort-order="asc"
            class="paginated-table table-striped table-hover"
            md-card
            md-fixed-header
          >
            <md-table-toolbar class="table-toolbar-align">
              <div class="md-toolbar-section-start">
                <h1 class="md-title">Users</h1>
              </div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input
                  placeholder="Search..."
                  v-model="searchQuery"
                  @input="searchOnTable"
                />
              </md-field>
            </md-table-toolbar>

            <md-table-empty-state
              md-label="No users found"
              :md-description="`No user found for this '${searchQuery}' query. Try a different search term or create a new user.`"
            >
              <!-- <md-button class="md-primary md-raised" @click="newUser">Create New User</md-button> -->
            </md-table-empty-state>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name" md-sort-by="name">{{
                item.name
              }}</md-table-cell>
              <md-table-cell md-label="Status" md-sort-by="status">
                <i class="fa fa-check check-align" aria-hidden="true"></i>
              </md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{
                item.email
              }}</md-table-cell>
              <md-table-cell md-label="Outlet" md-sort-by="outlet">{{
                item.outlet
              }}</md-table-cell>
              <md-table-cell md-label="Type" md-sort-by="type">{{
                item.type
              }}</md-table-cell>
              <md-table-cell md-label="Paid User" md-sort-by="paid_user">{{
                item.paid_user
              }}</md-table-cell>
              <md-table-cell md-label="Voucher" md-sort-by="voucher">{{
                item.voucher
              }}</md-table-cell>
              <md-table-cell md-label="Inventory" md-sort-by="inventory">{{
                item.inventory
              }}</md-table-cell>
              <md-table-cell md-label="Music" md-sort-by="music">{{
                item.music
              }}</md-table-cell>
            </md-table-row>
          </md-table>

          <!-- table 2 for reference -->
          <md-table
            v-model="tableData"
            md-sort="name"
            md-sort-order="asc"
            md-card
            md-fixed-header
            class="paginated-table table-striped table-hover md-scrollbar"
          >
            <md-table-toolbar>
              <div class="md-toolbar-section-start">
                <h1 class="md-title">Users</h1>
              </div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Search by name..." />
              </md-field>
            </md-table-toolbar>

            <md-table-empty-state
              md-label="No users found"
              :md-description="`No user found for this '' query. Try a different search term or create a new user.`"
            >
              <md-button class="md-primary md-raised"
                >Create New User</md-button
              >
            </md-table-empty-state>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name" md-sort-by="name">{{
                item.name
              }}</md-table-cell>
              <!-- <md-table-cell md-label="Status" md-sort-by="status">
                <div>
                  <i class="fa fa-check check-align" aria-hidden="true"></i>
                </div>
              </md-table-cell> -->
              <md-table-cell md-label="Email" md-sort-by="email">{{
                item.email
              }}</md-table-cell>
              <md-table-cell md-label="Outlet" md-sort-by="outlet">{{
                item.outlet
              }}</md-table-cell>
              <md-table-cell md-label="Type" md-sort-by="type">{{
                item.type
              }}</md-table-cell>
              <md-table-cell md-label="Paid User" md-sort-by="paid_user">{{
                item.paid_user
              }}</md-table-cell>
              <md-table-cell md-label="Voucher" md-sort-by="voucher">{{
                item.voucher
              }}</md-table-cell>
              <md-table-cell md-label="Inventory" md-sort-by="inventory">{{
                item.inventory
              }}</md-table-cell>
              <md-table-cell md-label="Music" md-sort-by="music">{{
                item.music
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import { customizeService } from '../../../services/customize.js'
import Fuse from 'fuse.js'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      usersList: [],
      searchQuery: '',
      tableData: [],
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    searchOnTable() {
      if (this.searchQuery != '') {
        this.tableData = new Fuse(this.usersList, {
          keys: [this.searchQuery],
          threshold: 0.1,
        }).search(this.usersList)
      } else this.tableData = this.usersList
    },
    getUsers() {
      customizeService
        .getAllUsers()
        .then((res) => {
          this.usersList = res.data.map((item) => {
            return {
              name: `${item.first_name} ${item.last_name}`,
              email: item.email,
              status: item.status,
              outlet: item.display_name,
              type: item.user_type,
              paid_user: '',
              voucher: item.is_voucher,
              inventory: item.is_inventory,
              music: item.is_music,
            }
          })
          this.tableData = this.usersList
        })
        .catch((error) => console.log('response from server error>>', error))
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
  },
}
</script>

<style scoped>
/* .md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
} */
/* .md-table .md-table-content {
  flex: 1;
  overflow-x: auto;
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
} */
.md-table-cell:last-child .md-table-cell-container,
.md-table-head:last-child {
  text-align: center !important;
  display: flex;
  justify-content: center;
}
.paginated-table .md-table-head:last-child {
  text-align: center;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
</style>
