<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="block-bg">
        <md-card-header
          class="md-card-header-text md-card-header-green md-layout md-gutter"
        >
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <router-link :to="{ path: '/business-vertical/Outlet' }">
                <img src="../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h3>{{ $t(formType) }}</h3>
          </div>
          <div
            class="md-layout-item md-size-30"
            v-if="formType == 'View Outlet'"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="editOutlet"
              >edit</i
            >
          </div>
        </md-card-header>
        <div class="card-content-align">
          <md-card-content>
            <div>
              <div class="md-layout">
                <div class="left-padding md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Outlet Name') }}</label>
                    <md-input
                      type="text"
                      v-model="outlets.outlet.name"
                      data-vv-name="OutletName"
                      v-validate="modelValidations.OutletName"
                      :disabled="formType == 'View Outlet'"
                    ></md-input>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('OutletName')">
                        {{ errors.first('OutletName') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>
                <!-- <md-field>
                <label>{{$t('Outlet Location')}}</label>
                <md-input type="text" :disabled = "formType == 'View Outlet'" ></md-input>
                </md-field> -->
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Website') }}</label>
                    <md-input
                      type="text"
                      v-model="outlets.outlet.website"
                      data-vv-name="Website"
                      v-validate="modelValidations.Website"
                      :disabled="formType == 'View Outlet'"
                    ></md-input>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('Website')">
                        {{ errors.first('Website') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div>

              <!-- Address Block  --->
              <div class="md-layout">
                <div class="left-padding md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Address 1') }}</label>
                    <md-input
                      type="text"
                      v-model="outlets.outlet.address1"
                      data-vv-name="OutletName"
                      :disabled="formType == 'View Outlet'"
                    ></md-input>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('OutletName')">
                        {{ errors.first('OutletName') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Address 2') }}</label>
                    <md-input
                      type="text"
                      v-model="outlets.outlet.address2"
                      data-vv-name="OutletName"
                      :disabled="formType == 'View Outlet'"
                    ></md-input>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('OutletName')">
                        {{ errors.first('OutletName') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div>

              <div class="md-layout">
                <div class="left-padding md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Address 3') }}</label>
                    <md-input
                      type="text"
                      v-model="outlets.outlet.address3"
                      data-vv-name="OutletName"
                      :disabled="formType == 'View Outlet'"
                    ></md-input>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('OutletName')">
                        {{ errors.first('OutletName') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div>
              <!-- EOF Address Block  --->

              <md-checkbox
                v-model="outlets.outlet.voucher_enabled"
                :disabled="true"
                >Enable Gift Voucher ?</md-checkbox
              >
              <div v-if="outlets.outlet.voucher_enabled == true">
                <div class="md-layout">
                  <div class="left-padding md-layout-item md-size-50">
                    <md-field>
                      <label>{{ $t('Gift Voucher Header') }}</label>
                      <md-input
                        type="text"
                        v-model="outlets.outlet.voucher_header"
                        :disabled="formType == 'View Outlet'"
                      ></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>{{ $t('Gift Voucher Footer') }}</label>
                      <md-input
                        type="text"
                        v-model="outlets.outlet.voucher_footer"
                        :disabled="formType == 'View Outlet'"
                      ></md-input>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout">
                  <div class="left-padding md-layout-item md-size-50">
                    <md-field>
                      <label>{{ $t('Gift Voucher Max.Amount') }}</label>
                      <md-input
                        type="text"
                        v-model="outlets.outlet.voucher_max_amount"
                        :disabled="formType == 'View Outlet'"
                      ></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </div>

        <!---===================+Business Hours==============---------------------------------->
        <!-- <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <h4>Business Hours</h4>
            <business-hours
              :days="businessDays"
              type="datalist"
            ></business-hours>
          </div>

          <div class="md-layout-item md-size-50">
            <h4>Online Hours</h4>
            <business-hours :days="onlineDays"></business-hours>
          </div>
        </div> -->

        <!---===================+ EOF Business Hours==============---------------------------------->

        <!---===================+USER DETAILS==============---------------------------------->

        <md-card-header>
          <h4 class="title">User Details</h4>
        </md-card-header>

        <div class="md-layout user-details">
          <div
            class="md-layout-item md-size-50"
            v-if="formType == 'Add Outlet'"
          >
            <md-field>
              <label>{{ $t('First Name') }}</label>
              <md-input v-model="outlets.login.first_name"></md-input>
            </md-field>
          </div>

          <div
            class="md-layout-item md-size-50"
            v-if="formType == 'Add Outlet'"
          >
            <md-field>
              <label>{{ $t('Last Name') }}</label>
              <md-input v-model="outlets.login.last_name"></md-input>
            </md-field>
          </div>

          <div
            class="md-layout-item"
            v-bind:class="{
              'md-size-100': formType != 'Add Outlet',
            }"
          >
            <md-field>
              <label>{{ $t('Email') }}</label>
              <md-input
                type="email"
                autocomplete="off"
                v-model="outlets.outlet.email"
                data-vv-name="Email"
                v-validate="modelValidations.Email"
                :disabled="formType != 'Add Outlet'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Email')">
                  {{ errors.first('Email') }}
                </div>
              </slide-y-down-transition>
            </md-field>
          </div>

          <div
            class="md-layout-item md-size-50"
            v-if="formType == 'Add Outlet'"
          >
            <md-field>
              <label>{{ $t('Password') }}</label>
              <md-input
                type="password"
                autocomplete="new-password"
                v-model="outlets.login.password"
                data-vv-name="Password"
                v-validate="modelValidations.Password"
                :disabled="formType != 'Add Outlet'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Password')">
                  {{ errors.first('Password') }}
                </div>
              </slide-y-down-transition>
            </md-field>
          </div>

          <div
            class="md-layout-item md-size-50"
            v-if="formType == 'Add Outlet'"
          >
            <md-field>
              <label>{{ $t('Phone') }}</label>
              <md-input
                type="text"
                v-model="outlets.login.phone"
                data-vv-name="Phone"
                v-validate="modelValidations.Phone"
                :disabled="formType == 'View Outlet'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Phone')">
                  {{ errors.first('Phone') }}
                </div>
              </slide-y-down-transition>
            </md-field>
          </div>

          <div
            class="md-layout-item md-size-50"
            v-if="formType == 'Add Outlet'"
          >
            <md-field>
              <label>{{ $t('Address') }}</label>
              <md-input
                type="text"
                v-model="outlets.login.address"
                :disabled="formType == 'View Distributor'"
              >
              </md-input>
            </md-field>
          </div>

          <div
            class="md-layout-item md-size-50"
            v-if="formType == 'Add Outlet'"
          >
            <md-field>
              <label>{{ $t('Postal Code') }}</label>
              <md-input
                type="text"
                v-model="outlets.login.pin_code"
                :disabled="formType == 'View Distributor'"
              >
              </md-input>
            </md-field>
          </div>
        </div>

        <!-- ==========================Assign Roles===================================================-->

        <div class="package-settings">
          <md-card-header>
            <h4 class="title">{{ $t('Package Settings') }}</h4>
          </md-card-header>
          <div class="md-layout-item">
            <md-field>
              <label>Select Role</label>
              <md-select
                v-model="outlets.login.user_package_role_id"
                :disabled="formType == 'View Outlet'"
              >
                <slot v-for="item in roles">
                  <md-option :key="item.id" :value="item.id">{{
                    item.name
                  }}</md-option>
                </slot>
              </md-select>
            </md-field>
          </div>

          <!--===========================Assign modules=================================================-->
          <!-- 
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-radio v-model="assignModule" :value = false>Assign all modules</md-radio>
                <md-radio v-model="assignModule" :value= true>Custom assign modules</md-radio>
              </div>
            </div>
            <div class="package-table table-striped">
              <md-table>
                <md-table-row>
                  <md-table-head>Main Module</md-table-head>
                  <md-table-head>Sub Module</md-table-head>
                  <md-table-head v-if="assignModule==1"></md-table-head>
                </md-table-row>

                <slot v-if="assignModule==0">
                  <slot v-for="(item) in outlet_package.package_modules">
                    <md-table-row slot="md-table-row">
                      <md-table-cell>{{ item.name }}</md-table-cell>
                      <md-table-cell></md-table-cell>
                    </md-table-row>
                    <slot v-if="item.app_sub_module!==0">
                      <slot v-for="sub in item.app_sub_module">
                        <md-table-row slot="md-table-row">
                          <md-table-cell></md-table-cell>
                          <md-table-cell>{{sub.name}}</md-table-cell>
                        </md-table-row>
                      </slot>
                    </slot>
                  </slot>
                </slot>

                  <slot v-if="assignModule==1">
                    <h4>
                  <img src="../../../assets/images/plus.svg" @click="showSubDialog=true" />
                  {{ $t('Modules')}}
                </h4>
                    <slot v-for="(item) in custom_package">
                    <md-table-row slot="md-table-row">
                      <md-table-cell>{{ item.name }}</md-table-cell>
                      <md-table-cell></md-table-cell>
                      <md-table-cell>
                        <img :class="[{ 'disable-class': formType=='View Package'},{ 'active-disabled':formType!=='View Package' }]"
                          @click="removeModule(item.id)"
                          src="../../../assets/images/delete.svg"
                        />
                      </md-table-cell>
                    </md-table-row>
                    <slot v-if="item.app_sub_module!==0">
                      <slot v-for="sub in item.app_sub_module">
                        <md-table-row slot="md-table-row">
                          <md-table-cell></md-table-cell>
                          <md-table-cell>{{sub.name}}</md-table-cell>
                          <md-table-cell>
                            <img 
                            :class="[{ 'disable-class': formType=='View Package'},{ 'active-disabled':formType!=='View Package' }]"
                              @click="removeSubModule(sub.sub_module_id,item.id)"
                              src="../../../assets/images/delete.svg"
                            />
                          </md-table-cell>
                        </md-table-row>
                      </slot>
                    </slot>
                  </slot>
                  </slot>
              </md-table>
            </div>
          </md-card-content> -->
        </div>

        <md-card-header>
          <h4 class="title">{{ $t('Configuration Settings') }}</h4>
        </md-card-header>
        <div class="card-content-align">
          <md-card-content>
            <div>
              <div class="upload-preview" v-if="imgUrl">
                <div class="file_Label">
                  {{ $t('Outlet Logo') }}
                </div>
                <img class="uploaded-img" :src="imgUrl" />
              </div>

              <md-field class="upload-field">
                <!-- <md-input type="file" ref="file" id="file" v-on:change="handleFileUpload()" :disabled="formType == 'View Outlet'"></md-input> -->
                <input
                  class="custom-file-input"
                  type="file"
                  @change="onFileChanged"
                  :disabled="formType == 'View Outlet'"
                />

                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('OutletLogo')">
                    {{ errors.first('OutletLogo') }}
                  </div>
                </slide-y-down-transition>
              </md-field>

              <div class="md-layout">
                <div class="left-padding md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Outlet Opening Time (24hr)') }}</label>
                    <md-input
                      type="time"
                      v-model="outlets.outlet.wrk_start_time"
                      :disabled="formType == 'View Outlet'"
                    ></md-input>
                  </md-field>
                </div>
                <!-- <md-field>
              <label>{{$t('Limit Discount(%)')}}</label>
              <md-input type="number" v-model="outlets.outlet.disc_percentage" data-vv-name="Number" v-validate="modelValidations.Number"  :disabled = "formType == 'View Outlet'"></md-input>
               <slide-y-down-transition>
                      <div class="error"  v-show="errors.has('Number')">{{errors.first("Number")}}</div>
                    </slide-y-down-transition>
            </md-field> -->

                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Currency Label') }}</label>
                    <md-input
                      type="text"
                      v-model="outlets.outlet.currency_type"
                      :disabled="formType == 'View Outlet'"
                    ></md-input>
                  </md-field>
                </div>
              </div>

              <div class="md-layout">
                <div class="left-padding md-layout-item md-size-40">
                  <md-checkbox
                    v-model="outlets.outlet.disable_kot"
                    :disabled="formType == 'View Outlet'"
                  >
                    {{ $t('Disable KOT ?') }}
                    <br />{{
                      $t(
                        '(If checked then KOT printing screen will not display, directly go to bill printing screen)'
                      )
                    }}
                  </md-checkbox>
                </div>

                <div class="md-layout-item md-size-20">
                  <md-checkbox
                    v-model="outlets.outlet.clock_in_enable"
                    :disabled="true"
                  >
                    {{ $t('Enable Clock In Out ?') }}
                  </md-checkbox>
                </div>
                <div class="md-layout-item md-size-20">
                  <md-checkbox v-model="outlets.outlet.enable_ipad_grid">
                    {{ $t('Enable iPad Grid/Screen Design?') }}
                  </md-checkbox>
                </div>
                <div class="md-layout-item md-size-20">
                  <md-checkbox v-model="outlets.outlet.blind_cashup">
                    {{ $t('Blind Cashup ?') }}
                  </md-checkbox>
                </div>
              </div>
              <div class="md-layout mt-15">
                <div class="md-layout-item md-size-20 left-padding">
                  <md-checkbox v-model="outlets.outlet.void_reason">
                    {{ $t('Void Reason ?') }}
                  </md-checkbox>
                </div>
                <div
                  class="md-layout-item md-size-20 left-padding"
                  v-if="userType == 'branch_user'"
                >
                  <md-checkbox v-model="outlets.outlet.allow_price_change">
                    {{ $t('Allow Price Edit ?') }}
                  </md-checkbox>
                </div>
                <div class="md-layout-item md-size-20 left-padding">
                  <md-checkbox
                    v-model="outlets.outlet.enable_inhouse_voucher_qr_code"
                  >
                    {{ $t('Inhouse Voucher QR Code ?') }}
                  </md-checkbox>
                </div>
              </div>
              <br />
              <md-checkbox
                v-model="outlets.outlet.service_enable"
                :disabled="formType == 'View Outlet'"
                >{{ $t('Service Charge') }}</md-checkbox
              >
              <md-field v-if="outlets.outlet.service_enable">
                <label>{{ $t('Service Charge %') }}</label>
                <md-input
                  type="text"
                  v-model="outlets.outlet.service_chrg"
                  :disabled="formType == 'View Outlet'"
                ></md-input>
              </md-field>
              <md-checkbox
                v-model="outlets.outlet.disable_mobile_number_mask"
                :disabled="formType == 'View Outlet'"
                >{{ $t('Disable Mobile Number Mask') }}</md-checkbox
              >
              <md-checkbox
                v-model="outlets.outlet.special_price"
                :disabled="formType == 'View Outlet'"
                >{{ $t('Special Event?') }}</md-checkbox
              >
              <!-- <md-checkbox
                v-model="outlets.outlet.is_flipdish"
                :disabled="formType == 'View Outlet'"
                >{{ $t('Flip Dish') }}</md-checkbox
              > -->
              <!-- <md-checkbox
                v-model="outlets.outlet.is_market_man"
                :disabled="formType == 'View Outlet'"
                >{{ $t('Market Man') }}</md-checkbox
              > -->
              <!-- <div class="md-layout-item md-size-50">
                Price Level
                <i
                  class="md-icon md-icon-font md-theme-default add-pop"
                  @click="addPriceLevel"
                  :disabled="formType === 'View Outlet'"
                  >add</i
                >
                <div v-for="element in priceLevels" :key="element.id">
                  <md-field>
                    <md-input
                      type="text"
                      v-model="element.name"
                      :disabled="formType === 'View Menu Item'"
                    ></md-input>
                  </md-field>
                </div>
              </div> -->
              <div>
                <!-- <stripe-checkout
                  ref="checkoutRef"
                  mode="subscription"
                  pk="pk_test_51IG5WTG4BLFb3DxkGak6mHXUDrYIZXvNxCOm0s9id4eX3iFrYzam3jnm8o1qMc97kCQ8NQODPlmQ16g5iLzvMIab00K363tNNP"
                  :line-items="lineItems"
                  :success-url="successURL"
                  @loading="(v) => (loading = v)"
                  :client-reference-id="'50'"
                />
                <button @click="submit">Subscribe!</button> -->
              </div>

              <div class="btn-div">
                <md-card-actions
                  class="text-center"
                  v-if="formType != 'View Outlet'"
                >
                  <md-button
                    native-type="submit"
                    :to="{
                      path: '/business-vertical/Outlet',
                    }"
                    >{{ $t('CANCEL') }}</md-button
                  >
                  <md-button
                    native-type="submit"
                    class="md-primary"
                    @click.native.prevent="validate"
                    >{{ $t('SAVE') }}</md-button
                  >
                </md-card-actions>
              </div>
            </div>
          </md-card-content>
        </div>
      </md-card>
    </div>

    <!-- ==================================Dialog Box================================== -->

    <!-- <md-dialog  :md-active.sync="showSubDialog">
       <md-dialog-title class="md-layout">
         <div class="md-layout-item md-size-50">Modules</div>
         <div class="md-layout-item md-size-50 close-icon"> <i class="md-icon md-icon-font md-theme-default" @click="showSubDialog=false">close</i></div>
       </md-dialog-title>
      <div class="list">
        <md-list>
          <slot v-for="(item) in outlet_package.package_modules">
              <md-list-item md-expand  v-if="item.app_sub_module !==null ">
            <div class="module-detail">
              <div class="md-list-item-text module-name">{{item.name}}</div>
              <md-checkbox
                :model="array"
                :value="item.id"
                :true-value="item.id"
                @change="moduleCheckbox(item.id)"
              ></md-checkbox>
            </div>
            <md-list slot="md-expand">
              <md-list-item class="md-inset" v-for="(sub,index) in item.app_sub_module" :key="index">
                <span >{{sub.name}}</span>
                
                <md-checkbox
                  :model="subarray"
                  :value="sub.sub_module_id"
                  :true-value="sub.sub_module_id"
                  @change="subModuleCheckbox(item.id,sub.sub_module_id)"
                ></md-checkbox>
              </md-list-item>
            </md-list>
          </md-list-item>
             <md-list-item v-else>
            <div class="module-detail">
              <div class="md-list-item-text module-name">{{item.name}}</div>
              <md-checkbox
                :model="array"
                :value="item.id"
                :true-value="item.id"
                @change="moduleCheckbox(item.id,item.app_sub_module)"
              ></md-checkbox>
            </div>
             </md-list-item>
          </slot>
        
        </md-list>
      </div>
    </md-dialog> -->
  </div>
</template>

<script>
import { Tabs } from '@/components'
import { SlideYDownTransition } from 'vue2-transitions'
import { Collapse } from '@/components'
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
import { Promise } from 'q'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import BusinessHours from 'vue-business-hours'
import { StripeCheckout } from '@vue-stripe/vue-stripe'

export default {
  components: {
    Tabs,
    Collapse,
    SlideYDownTransition,
    ClipLoader,
    BusinessHours,
    StripeCheckout,
  },

  data() {
    return {
      modelValidations: {
        OutletName: {
          required: true,
        },
        Email: {
          required: true,
          email: true,
        },
        Password: {
          required: true,
        },
        Website: {
          url: true,
        },
        Phone: {
          numeric: true,
          required: true,
        },
        // VoucherHeader: {
        //   required: true,
        // },
        // VoucherFooter: {
        //   required: true,
        // },
        // Amount: {
        //   required: true,
        //   numeric: true,
        // },
        OutletLogo: {
          image: true,
        },
        Number: {
          numeric: true,
        },
        loading: false,
      },

      formType: {
        type: String,
        default: 'Add Outlet',
      },

      outletId: '',
      imgUrl: '',
      outlets: {
        outlet: {},
        login: {
          user_type: 'user',
        },
      },
      // packageId: accountService.userPackage(),
      roles: [],
      priceLevels: [],
      outlet_package: [],
      custom_package: [],
      showSubDialog: false,
      // assignModule: false,
      array: [],
      subarray: [],
      file: '',
      loader: false,
      businessHours: {},
      lineItems: [
        {
          price: 'price_1KdtCVG4BLFb3DxkGPmwvlK7', // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
        // {
        //   price: 'price_1JkTJsG4BLFb3Dxk7u42uXDd', // The id of the recurring price you created in your Stripe dashboard
        //   quantity: 1,
        // },
      ],
      successURL: 'http://localhost:8081/business-vertical/Outlet',
      cancelURL: 'your-cancel-url',
      userType: accountService.userRole(),
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },
    business() {},
    onFileChanged(event) {
      this.file = event.target.files[0]
      this.imgUrl = URL.createObjectURL(this.file)
    },
    handleSelects(e) {
      this.images = []
      let fileList = Array.prototype.slice.call(e.target.files)
      fileList.forEach((f) => {
        if (!f.type.match('image.*')) {
          return
        }

        let reader = new FileReader()
        let that = this

        reader.onload = function (e) {
          that.images.push(e.target.result)
        }
        reader.readAsDataURL(f)
      })
    },
    addPriceLevel() {
      this.priceLevels.push({
        chain_id: accountService.getChainId(),
        name: '',
        outlet_id: this.outletId,
      })
    },
    submitOutlet() {
      this.outlets.login.user_name = this.outlets.outlet.email
      // if(this.assignModule){
      //       this.outlets.app_packages = {
      //       is_package_assigned: this.assignModule,
      //       package_id : accountService.userPackage(),
      //       app_modules: this.custom_package
      //     }
      // }
      // else{
      //   this.outlets.app_packages = {
      //     is_package_assigned: this.assignModule
      //   }
      // }

      /* ---------------------Checking for Manager role and assigning null ------------------------ */
      let checkManager = this.roles.find(
        (el) => el.id == this.outlets.login.user_package_role_id
      )
      if (checkManager && checkManager.is_manager) {
        this.outlets.login.user_package_role_id = null
      }

      let formData = new FormData()
      formData.append('outlets[outlet[name]]', this.outlets.outlet.name)
      formData.append('outlets[outlet[chain_id]]', accountService.getChainId())
      formData.append('outlets[outlet[email]]', this.outlets.outlet.email)

      if (this.outlets.outlet.wrk_start_time !== undefined) {
        formData.append(
          'outlets[outlet[wrk_start_time]]',
          this.outlets.outlet.wrk_start_time
        )
      }

      if (this.outlets.outlet.disc_percentage !== undefined) {
        formData.append(
          'outlets[outlet[disc_percentage]]',
          this.outlets.outlet.disc_percentage
        )
      }

      if (this.outlets.outlet.currency_type !== undefined) {
        formData.append(
          'outlets[outlet[currency_type]]',
          this.outlets.outlet.currency_type
        )
      }

      if (this.outlets.outlet.disable_kot !== undefined) {
        formData.append(
          'outlets[outlet[disable_kot]]',
          this.outlets.outlet.disable_kot
        )
      }

      if (this.outlets.outlet.clock_in_enable !== undefined) {
        formData.append(
          'outlets[outlet[clock_in_enable]]',
          this.outlets.outlet.clock_in_enable
        )
      }
      if (this.outlets.outlet.enable_ipad_grid !== undefined) {
        formData.append(
          'outlets[outlet[enable_ipad_grid]]',
          this.outlets.outlet.enable_ipad_grid
        )
      }
      if (this.outlets.outlet.blind_cashup !== undefined) {
        formData.append(
          'outlets[outlet[blind_cashup]]',
          this.outlets.outlet.blind_cashup
        )
      }
      if (this.outlets.outlet.service_enable != undefined) {
        formData.append(
          'outlets[outlet[service_enable]]',
          this.outlets.outlet.service_enable
        )
      }

      if (this.outlets.outlet.service_chrg != undefined) {
        formData.append(
          'outlets[outlet[service_chrg]]',
          this.outlets.outlet.service_chrg
        )
      }

      if (this.outlets.outlet.disable_mobile_number_mask !== undefined) {
        formData.append(
          'outlets[outlet[disable_mobile_number_mask]]',
          this.outlets.outlet.disable_mobile_number_mask
        )
      }

      if (this.outlets.outlet.special_price !== undefined) {
        formData.append(
          'outlets[outlet[special_price]]',
          this.outlets.outlet.special_price
        )
      }

      if (this.outlets.outlet.website != undefined) {
        formData.append('outlets[outlet[website]]', this.outlets.outlet.website)
      }

      if (this.outlets.outlet.phone != undefined) {
        formData.append('outlets[outlet[phone]]', this.outlets.outlet.phone)
      }

      if (this.outlets.outlet.address1 != undefined) {
        formData.append(
          'outlets[outlet[address1]]',
          this.outlets.outlet.address1
        )
      }

      if (this.outlets.outlet.address2 != undefined) {
        formData.append(
          'outlets[outlet[address2]]',
          this.outlets.outlet.address2
        )
      }

      if (this.outlets.outlet.address3 != undefined) {
        formData.append(
          'outlets[outlet[address3]]',
          this.outlets.outlet.address3
        )
      }

      // check this

      if (this.priceLevels != undefined) {
        formData.append(
          'outlets[outlet[price_levels]]',
          JSON.stringify(this.priceLevels)
        )
      }

      // formData.append(
      //   'outlets[outlet[business_hours]]',
      //   JSON.stringify(this.businessDays)
      // )

      // formData.append(
      //   'outlets[outlet[online_business_hours]]',
      //   JSON.stringify(this.onlineDays)
      // )

      if (this.outlets.outlet.voucher_enabled != undefined) {
        formData.append(
          'outlets[outlet[voucher_enabled]]',
          this.outlets.outlet.voucher_enabled
        )
      }

      if (this.outlets.outlet.voucher_header != undefined) {
        formData.append(
          'outlets[outlet[voucher_header]]',
          this.outlets.outlet.voucher_header
        )
      }

      if (this.outlets.outlet.voucher_footer != undefined) {
        formData.append(
          'outlets[outlet[voucher_footer]]',
          this.outlets.outlet.voucher_footer
        )
      }

      if (this.outlets.outlet.voucher_max_amount != undefined) {
        formData.append(
          'outlets[outlet[voucher_max_amount]]',
          this.outlets.outlet.voucher_max_amount
        )
      }

      if (this.outlets.outlet.flipdish != undefined) {
        formData.append(
          'outlets[outlet[flipdish]]',
          this.outlets.outlet.flipdish
        )
      }

      if (this.outlets.outlet.flipdish != undefined) {
        formData.append(
          'outlets[outlet[is_market_man]]',
          this.outlets.outlet.flipdish
        )
      }
      if (this.outlets.outlet.void_reason != undefined) {
        formData.append(
          'outlets[outlet[void_reason]]',
          this.outlets.outlet.void_reason
        )
      }

      if (this.outlets.outlet.allow_price_change != undefined) {
        formData.append(
          'outlets[outlet[allow_price_change]]',
          this.outlets.outlet.allow_price_change
        )
      }

      if (this.outlets.outlet.enable_inhouse_voucher_qr_code != undefined) {
        formData.append(
          'outlets[outlet[enable_inhouse_voucher_qr_code]]',
          this.outlets.outlet.enable_inhouse_voucher_qr_code
        )
      }

      if (this.outlets.outlet.token !== undefined) {
        formData.append('outlets[outlet[token]]', this.outlets.outlet.token)
      }

      if (this.file != '') {
        formData.append('outlets[outlet[out_image]]', this.file)
      }

      if (this.outlets.login.address !== undefined) {
        formData.append('outlets[login[address]]', this.outlets.login.address)
      }

      if (this.outlets.login.pin_code !== undefined) {
        formData.append('outlets[login[pin_code]]', this.outlets.login.pin_code)
      }

      formData.append(
        'outlets[login[distributor_id]]',
        accountService.getDistributorId()
      )

      if (this.formType == 'Add Outlet') {
        if (this.outlets.login.first_name !== undefined) {
          formData.append(
            'outlets[login[first_name]]',
            this.outlets.login.first_name
          )
        }

        if (this.outlets.login.last_name !== undefined) {
          formData.append(
            'outlets[login[last_name]]',
            this.outlets.login.last_name
          )
        }

        if (this.outlets.login.user_name !== undefined) {
          formData.append(
            'outlets[login[user_name]]',
            this.outlets.login.user_name
          )
        }

        if (this.outlets.login.user_name !== undefined) {
          formData.append('outlets[login[email]]', this.outlets.login.user_name)
        }

        if (this.outlets.outlet.name !== undefined) {
          formData.append(
            'outlets[login[display_name]]',
            this.outlets.outlet.name
          )
        }

        if (this.outlets.login.password !== undefined) {
          formData.append(
            'outlets[login[password]]',
            this.outlets.login.password
          )
        }

        if (this.outlets.login.user_type !== undefined) {
          formData.append(
            'outlets[login[user_type]]',
            this.outlets.login.user_type
          )
        }

        if (this.outlets.outlet.currency_type !== undefined) {
          formData.append('outlets[login[status]]', 'active')
        }

        customizeService.createOutlet(formData).then((res) => {
          this.$router.push({
            name: 'Outlet',
          })
        })
      } else {
        customizeService.editOutlet(this.outletId, formData).then((res) => {
          this.$router.push({
            path: '/business-vertical/Outlet',
          })
        })
      }
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitOutlet()
          }
        })
      }
    },

    resetOutlet() {},

    editOutlet() {
      this.formType = 'Edit Outlet'
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout()
    },

    // moduleCheckbox(id){
    //    let moduleIndex = this.outlet_package.package_modules.findIndex(el => el.id == id)
    //    if(this.array.includes(id)){
    //      if(this.outlet_package.package_modules[moduleIndex].app_sub_module != null){
    //         this.outlet_package.package_modules[moduleIndex].app_sub_module.forEach(el => {
    //         this.subarray.push(el.sub_module_id)
    //       })
    //     }
    //     this.custom_package.push(this.outlet_package.package_modules[moduleIndex])
    //    }
    //    else{
    //      if(this.outlet_package.package_modules[moduleIndex].app_sub_module != null){
    //         this.outlet_package.package_modules[moduleIndex].app_sub_module.forEach(el => {
    //         let subModuleIndex = this.subarray.findIndex(subEl => subEl==el.sub_module_id)
    //         this.subarray.splice(subModuleIndex,1)
    //       })
    //      }

    //      let customIndex = this.custom_package.findIndex(el => el.id == id)
    //      if(customIndex != -1){
    //        this.custom_package.splice(customIndex,1)
    //      }
    //    }

    // },

    // subModuleCheckbox(moduleId, subModuleId){
    //   let moduleIndex = this.outlet_package.package_modules.findIndex(el => el.id == moduleId)
    //   let subModuleIndex = this.outlet_package.package_modules[moduleIndex].app_sub_module.findIndex(el => el.sub_module_id == subModuleId)

    //   let customIndex = this.custom_package.findIndex(el => el.id == moduleId)
    //   let customSubIndex = this.custom_package[customIndex].app_sub_module.findIndex(el => el.sub_module_id==subModuleId)

    //    if(this.subarray.includes(subModuleId) && !this.array.includes(moduleId)){
    //      this.array.push(moduleId)
    //      this.custom_package.push({
    //        name: this.outlet_package.package_modules[moduleIndex].name,
    //        id: this.outlet_package.package_modules[moduleIndex].id,
    //        module_path: this.outlet_package.package_modules[moduleIndex].module_path,
    //        app_sub_module: [this.outlet_package.package_modules[moduleIndex].app_sub_module[subModuleIndex]]
    //        })
    //    }

    //    else if(!this.subarray.includes(subModuleId)){

    //      this.custom_package[customIndex].app_sub_module.splice(customSubIndex,1)
    //    }

    //    else {
    //      this.custom_package[customIndex].app_sub_module.push(this.outlet_package.package_modules[moduleIndex].app_sub_module[subModuleIndex])
    //    }
    // },

    // removeModule(id){
    //   let moduleIndex = this.custom_package.findIndex(el => el.id == id)
    //   if(this.custom_package[moduleIndex].app_sub_module != null){
    //       this.custom_package[moduleIndex].app_sub_module.forEach(el => {
    //       let subArrayIndex = this.subarray.findIndex(arr => arr == el.sub_module_id)
    //       this.subarray.splice(subArrayIndex,1)
    //     })
    //   }
    //   this.custom_package.splice(moduleIndex,1)
    //   let arrayIndex = this.array.findIndex( el => el == id)
    //   this.array.splice(arrayIndex,1)
    // },

    // removeSubModule(subModuleId, moduleId){
    //   let moduleIndex = this.custom_package.findIndex(el => el.id == moduleId)
    //   let subArrayIndex = this.subarray.findIndex(arr => arr == subModuleId )
    //   this.subarray.splice(subArrayIndex,1)
    //   let subModuleIndex = this.custom_package[moduleIndex].app_sub_module.findIndex(el => el.sub_module_id == subModuleId)
    //   this.custom_package[moduleIndex].app_sub_module.splice(subModuleIndex,1)
    // },

    // assignCustomPackage(res){
    //   this.custom_package = JSON.parse(JSON.stringify(res.package_modules))
    //   this.custom_package.forEach(el => {
    //           this.array.push(el.id)
    //           if(el.app_sub_module != null){
    //           el.app_sub_module.forEach(sub => {
    //           this.subarray.push(sub.sub_module_id)
    //             })
    //           }
    //   })
    // }
    getManagerId() {
      let defaultrole = this.roles.find((el) => el.is_manager === true)
      this.outlets.login.user_package_role_id = defaultrole.id
    },
  },

  created() {
    this.outletId = this.$route.query.client
    this.formType = this.$route.query.formType
    // customizeService.getPackagebyId(this.packageId).then(res => {
    //   this.outlet_package = res.data

    if (this.formType != 'Add Outlet') {
      customizeService.getOutletById(this.outletId).then((res) => {
        this.outlets.outlet = res.data.outlet
        this.outlets.login = res.data.login
        this.imgUrl = res.data.outlet.img_url
        this.priceLevels = res.data.outlet.price_levels
        if (res.data.login.user_package_role_id == null) {
          Promise.all([p1]).then((val) => {
            this.getManagerId()
          })
        }
      })
    }

    /*--------------------================+Roles+====================--------------*/
    // let self=this

    const p1 = new Promise((resolve, reject) => {
      customizeService.getRoles().then((res) => {
        this.roles = res.data.result
        if (this.formType == 'Add Outlet') {
          this.getManagerId()
        }
        resolve(res.data)
      })
    })
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.package-name {
  margin-top: 8%;
}

.text-center {
  justify-content: center !important;
}

.btn-add-module {
  text-align: center;
}

.arrow_left {
  float: left;
  padding-top: 21px;
}

.md-card .md-card-actions {
  border: none;
}

.file_Label {
  margin-bottom: 10px;
}

.edit-img {
  padding: 32px;
  float: right;
}

.card-content-align {
  padding: 0px 18px;
}

.md-card img {
  width: 19px;
}

.user-details {
  padding-bottom: 15px;
}

.upload-field {
  .custom-file-input {
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    padding-left: 60px;
    position: relative;
    height: 30px;
    padding-top: 6px;
    color: #3c4858;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Upload Logo';
    color: black;
    display: inline-block;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
    position: absolute;
    left: 0px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 12px;
    padding: 7px 12px;
    top: -2px;
    color: #3c4858;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
}
.md-card {
  .uploaded-img {
    width: 250px;
  }
}
.add-hours {
  display: none !important;
}
.left-padding {
  padding-left: 0px !important;
}
</style>
