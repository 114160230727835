<template>
  <form class="md-layout-item">
    <md-card>
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 formHeader">
          <span class="arrow_left">
            <router-link :to="{ path: '/online-ordering/promotions' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Promotion Code') }}</label>
              <md-input
                type="text"
                v-model="online_promotion.promo_code"
                data-vv-name="promo_code"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Description') }}</label>
              <md-input
                type="text"
                v-model="online_promotion.description"
                data-vv-name="description"
              ></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('User Limit') }}</label>
              <md-input
                type="number"
                v-model="online_promotion.user_limit"
                data-vv-name="user_limit"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Discount') }}</label>
              <md-input
                type="number"
                v-model="online_promotion.discount_percent"
                data-vv-name="discount_percent"
              ></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Status') }}</label>
              <md-select
                type="boolean"
                v-model="online_promotion.status"
                data-vv-name="status"
              >
                <md-option value="true">Active</md-option>
                <md-option value="false">Inactive</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="btn-div">
          <md-card-actions>
            <md-button
              native-type="submit"
              :to="{
                path: '/online-ordering/promotions',
              }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              class="md-primary"
              @click="submit()"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'

export default {
  components: {
    ClipLoader,
  },

  data() {
    return {
      promoID: '',
      loader: false,
      online_promotion: {
        outlet_id: accountService.getOutletId(),
      },
    }
  },
  methods: {
    submit() {
      if (this.formType === 'Add Promotions') {
        let body = { online_promotions: this.online_promotion }
        customizeService.addOnlinePromotions(body).then((res) => {
          this.$router.push({ name: 'AllPromotions' })
        })
      } else {
        let body = { online_promotions: this.online_promotion }
        customizeService
          .updateOnlinePromotion(this.promoID, body)
          .then((res) => {
            this.$router.push({ name: 'AllPromotions' })
          })
      }
    },
    getItem(promoID) {
      if (this.formType !== 'Add Promotions') {
        customizeService.getOnlinePromotionById(promoID).then((res) => {
          this.online_promotion = res.data
        })
      }
    },

    editPromotion() {
      this.formType = 'Edit Promotions'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.promoID = this.$route.query.client
    this.getItem(this.promoID)
  },
}
</script>
<style>
.formHeader {
  display: flex;
}

.md-card-actions.md-alignment-right {
  justify-content: center;
}

.edit-img {
  padding: 32px;
  float: right;
}
</style>
