import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue'
import Dashboard from '@/pages/Dashboard/OnlineOrder/Dashboard.vue'
import MenuItems from '@/pages/Dashboard/OnlineOrder/MenuItem.vue'
import MenuItemForm from '@/pages/Dashboard/OnlineOrder/MenuAvailibilityForm.vue'

import Orders from '@/pages/Dashboard/OnlineOrder/Orders.vue'
import Settings from '@/pages/Dashboard/OnlineOrder/Settings.vue'
import OrderType from '@/pages/Dashboard/OnlineOrder/OrderTypes.vue'

import MenuSection from '@/pages/Dashboard/OnlineOrder/MenuSection.vue'
import MenuSectionForm from '@/pages/Dashboard/OnlineOrder/MenuSectionForm.vue'
import OrderDetails from '@/pages/Dashboard/OnlineOrder/OrderDetails.vue'
import OutletSettings from '@/pages/Dashboard/OnlineOrder/OutletSettings.vue'
import CollectionSettings from '@/pages/Dashboard/OnlineOrder/CollectionSettings.vue'
import Promotions from '@/pages/Dashboard/OnlineOrder/Promotions.vue'
import AddPromotions from '@/pages/Dashboard/OnlineOrder/AddPromotions.vue'
import Theme from '@/pages/Dashboard/OnlineOrder/Theme.vue'

export const onlineOrderingModule = {
  path: '/',
  component: DashboardLayout,
  name: 'Online Ordering',
  children: [
    {
      path: 'online-ordering/dashboard',
      name: 'online-ordering',
      components: { default: Dashboard },
      meta: {
        title: 'Online Ordering',
        breadcrumbs: [
          {
            text: 'Dashboard',
          },
          {
            text: 'Dashboard',
          },
        ],
      },
    },
    {
      path: 'online-ordering/menu-item',
      name: 'menuitem',
      components: { default: MenuItems },
      meta: {
        title: 'Online Ordering',
        breadcrumbs: [
          {
            text: 'Online Ordering',
          },
          {
            text: 'Available Menu Items',
          },
        ],
      },
    },
    {
      path: 'online-ordering/menu-edit',
      name: 'MenuAvailibilityForm',
      components: { default: MenuItemForm },
      meta: {
        title: 'Online Ordering',
        breadcrumbs: [
          {
            text: 'Online Ordering',
          },
          {
            text: 'Edit Menu Item',
          },
        ],
      },
    },
    {
      path: 'online-ordering/orders',
      name: 'orders',
      components: { default: Orders },
      meta: {
        title: 'Online Ordering',
        breadcrumbs: [
          {
            text: 'Online Ordering',
          },
          {
            text: 'orders',
          },
        ],
      },
    },
    {
      path: 'online-ordering/settings',
      name: 'orders',
      components: { default: Settings },
      meta: {
        title: 'Online Ordering',
        breadcrumbs: [
          {
            text: 'Online Ordering',
          },
          {
            text: 'settings',
          },
        ],
      },
    },
    {
      path: 'online-ordering/order-types',
      name: 'orders',
      components: { default: OrderType },
      meta: {
        title: 'Online Ordering',
        breadcrumbs: [
          {
            text: 'Online Ordering',
          },
          {
            text: 'settings',
          },
        ],
      },
    },
    {
      path: 'online-ordering/menu-section-form',
      name: 'Menu Section Form',
      components: { default: MenuSectionForm },
      meta: {
        title: 'Menu Section',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Menu Section',
          },
        ],
      },
    },
    {
      path: 'online-ordering/menu-section',
      name: 'Menu Section',
      components: { default: MenuSection, helper: MenuSection },
      meta: {
        title: 'Menu Section',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Categories',
          },
          {
            text: 'Menu Section',
          },
        ],
      },
    },
    {
      path: 'online-ordering/order-details',
      name: 'Order Details',
      components: { default: OrderDetails },
      meta: {
        title: 'Order Details',
        breadcrumbs: [
          {
            text: 'Order Details',
          },
        ],
      },
    },

    {
      path: 'online-ordering/promotions',
      name: 'AllPromotions',
      components: { default: Promotions },
      meta: {
        title: 'Promotions',
        breadcrumbs: [
          {
            text: 'Promotions',
          },
        ],
      },
    },
    {
      path: 'online-ordering/outlet-settings',
      name: 'Outlet Settings',
      components: { default: OutletSettings },
      meta: {
        title: 'Outlet Settings',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
        ],
      },
    },
    {
      path: 'online-ordering/add_promotions',
      name: 'AddPromotions',
      components: { default: AddPromotions },
      meta: {
        title: 'Add Promotions',
        breadcrumbs: [
          {
            text: 'Add Promotions',
          },
        ],
      },
    },
    {
      path: 'online-ordering/collection-settings',
      name: 'Collection Settings',
      components: { default: CollectionSettings },
      meta: {
        title: 'Collection Settings',
        breadcrumbs: [
          {
            text: 'Collection Settings',
          },
        ],
      },
    },
    {
      path: 'online-ordering/theme',
      name: 'Theme',
      components: { default: Theme },
      meta: {
        title: 'Theme',
        breadcrumbs: [
          {
            text: 'Theme',
          },
        ],
      },
    },
  ],
}
