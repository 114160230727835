<template>
  <div class="md-layout profile-custm">
    <div class="md-layout-item md-medium-size-100">
      <md-card class="block-bg">
        <md-card-header class="md-layout add-profile">
          <div class="md-layout-item md-size-70 back-icon">
            <span class="arrow_left">
              <router-link :to="{ path: '/outlet/profile' }">
                <img src="../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h4 class="title">{{ formType }}</h4>
            <!-- <ul class="active-inactive-btn">
              <li>
                <md-button
                  @click="filterBystatus('Inactive')"
                  v-bind:class="{
                    active: activetab != null && activetab == 'Inactive',
                  }"
                  >Inactive</md-button
                >
              </li>
              <li>
                <md-button
                  @click="filterBystatus('Active')"
                  v-bind:class="{
                    active: activetab != null && activetab == 'Active',
                  }"
                  >Active</md-button
                >
              </li>
            </ul> -->
          </div>
          <div class="md-layout-item md-size-30">
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              v-if="formType == 'View Profile'"
              @click="changeFormType()"
              >edit</i
            >
          </div>
        </md-card-header>

        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <md-card-content class="profile-elements" v-if="!loader">
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
              >
                <label>{{ $t('Name') }}</label>
                <md-input
                  data-vv-name="Name"
                  type="text"
                  v-validate="modelValidations.Name"
                  :disabled="formType == 'View Profile'"
                  v-model="terminal_profiles.name"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Name')">
                    {{ errors.first('Name') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Name') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <md-card-header class="md-layout">
            <div class="md-layout-item md-size-70">
              <!-- <span class="arrow_left">
              <router-link :to="{path:'/outlet/profile'}">
                <md-icon class="material-icons">keyboard_arrow_left</md-icon>
              </router-link>
              </span>-->
              <h4 class="title">{{ $t('General') }}</h4>
            </div>
          </md-card-header>
          <div class="sub-text">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <label>{{ $t('Custom Tender') }}</label>
                <i
                  class="fas fa-plus tender-add"
                  @click="tooltipActive = !tooltipActive"
                ></i>
              </div>
              <div class="md-layout-item md-size-50 tender-options">
                <div class="tender">
                  <div
                    class="tender-elements"
                    v-for="(items, index) in tenderElements"
                    :key="index"
                    @click="removeTender(index)"
                  >
                    {{ items }}
                  </div>
                  <div
                    class="add-tender"
                    v-if="tooltipActive == false && formType != 'View Profile'"
                  ></div>
                </div>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item switch-item md-size-33 md-size-33">
                <label>{{ $t('Auto Logout Transaction Complete') }}</label>
                <md-switch
                  class="switch-style"
                  v-model="terminal_profiles.profile_json.auto_logout_txn"
                  :disabled="formType == 'View Profile'"
                ></md-switch>
              </div>

              <div class="md-layout-item switch-item md-size-33 md-size-33">
                <label>{{ $t('Auto Logout Order Complete') }}</label>
                <md-switch
                  class="switch-style"
                  v-model="terminal_profiles.profile_json.auto_logout_order"
                ></md-switch>
              </div>

              <div class="md-layout-item switch-item md-size-33 md-size-33">
                <label>{{ $t('Show Message After Print Success') }}</label>
                <md-switch
                  class="switch-style"
                  v-model="
                    terminal_profiles.profile_json.message_after_print_success
                  "
                  :disabled="formType == 'View Profile'"
                ></md-switch>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item switch-item md-size-33 md-size-33">
                <label>{{ $t('Show Tables as Initial View') }}</label>
                <md-switch
                  class="switch-style"
                  v-model="
                    terminal_profiles.profile_json.show_tables_as_initial_view
                  "
                  :disabled="formType == 'View Profile'"
                ></md-switch>
              </div>

              <div class="md-layout-item switch-item md-size-33 md-size-33">
                <label>{{ $t('Hide Items with Barcode') }}</label>
                <md-switch
                  class="switch-style"
                  v-model="terminal_profiles.profile_json.hide_items_barcode"
                  :disabled="formType == 'View Profile'"
                ></md-switch>
              </div>

              <div class="md-layout-item switch-item md-size-33 md-size-33">
                <label>{{ $t('Enable Time Out') }}</label>
                <md-switch
                  class="switch-style"
                  v-model="terminal_profiles.profile_json.enable_time_out"
                  :disabled="formType == 'View Profile'"
                ></md-switch>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card class="block-bg">
        <md-card-header class="md-layout">
          <div class="md-layout-item md-size-70">
            <!-- <span class="arrow_left">
              <router-link :to="{path:'/outlet/profile'}">
                <md-icon class="material-icons">keyboard_arrow_left</md-icon>
              </router-link>
            </span>-->
            <h4 class="title">{{ $t('Printing') }}</h4>
          </div>
          <div class="md-layout-item md-size-30">
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              v-if="formType == 'View Profile'"
              @click="changeFormType()"
              >edit</i
            >
          </div>
        </md-card-header>
        <md-card-content class="profile-elements sub-text-container">
          <div class="mng-profile">[Order & Bill]</div>
          <!-- <div class="sub-text"> -->
          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33 md-size-33">
              <label>{{ $t('Print Bill By Default') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.print_bill_by_default"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33 md-size-33">
              <label>{{ $t('Print Order Number in Bill') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.print_order_no"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33 md-size-33">
              <label>{{ $t('Print Slip When Order Deleted') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.print_slip_on_delete"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33 md-size-33">
              <label>{{ $t('Print Order Items By Cource') }}</label>
              <md-switch
                class="switch-style"
                v-model="
                  terminal_profiles.profile_json.print_order_item_by_course
                "
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>
          <!-- </div> -->

          <div class="mng-profile">[End Of Day]</div>
          <!-- <div class="sub-text"> -->
          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33 md-size-33">
              <label>{{ $t('Disable Sales By Category') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.disable_sales_by_cat"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33 md-size-33">
              <label>{{ $t('Disable Sales By Employee') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.disable_sales_by_emp"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Disable Average Spend') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.disable_avg_spend"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33 md-size-33">
              <label>{{ $t('Disable Sales By Sale Type') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.disable_sales_by_type"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33 md-size-33">
              <label>{{ $t('Disable Hourly Sales') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.disable_hourly_sales"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Disable Hourly Order') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.disable_hourly_orders"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>
          <!-- </div> -->
          <div class="mng-profile">[Control]</div>
          <!-- <div class="sub-text"> -->
          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Printer Heartbeat') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.printer_heartbeat"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>
          <!-- </div> -->

          <div class="mng-profile">[Prints]</div>
          <!-- <div class="sub-text"> -->
          <div class="md-layout">
            <div class="md-layout-item md-size-33">
              <md-field>
                <label>{{ $t('Promotion Bill Footer') }}</label>
                <md-textarea
                  v-model="terminal_profiles.profile_json.promo_bill_footer"
                  :disabled="formType == 'View Profile'"
                ></md-textarea>
              </md-field>
            </div>

            <div class="md-layout-item md-size-33">
              <md-field>
                <label>{{ $t('Away Message') }}</label>
                <md-textarea
                  v-model="terminal_profiles.profile_json.away_msg"
                  :disabled="formType == 'View Profile'"
                ></md-textarea>
              </md-field>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Summaries / Bills') }}</label>
              <md-switch
                class="switch-style"
                v-model="
                  terminal_profiles.profile_json.print_bill_customer_detail
                "
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>
          <!-- </div> -->
        </md-card-content>
      </md-card>

      <md-card class="block-bg">
        <md-card-header class="md-layout">
          <div class="md-layout-item md-size-70">
            <!-- <span class="arrow_left">
              <router-link :to="{path:'/outlet/profile'}">
                <md-icon class="material-icons">keyboard_arrow_left</md-icon>
              </router-link>
            </span>-->
            <h4 class="title">{{ $t('Order') }}</h4>
          </div>
          <div class="md-layout-item md-size-30">
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              v-if="formType == 'View Profile'"
              @click="changeFormType()"
              >edit</i
            >
          </div>
        </md-card-header>
        <md-card-content class="profile-elements sub-text-container">
          <div class="mng-profile">[General]</div>

          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33 md-size-33">
              <label>{{ $t('Ask for Number of Guests') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.ask_for_no_of_guest"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Display Order Lead Time') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.display_order_lead_time"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Hide Cource Button') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.hide_cource_button"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Show Option Pop Up on Menu Click') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.show_option_popup"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Allow Adding Custom Course') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.allow_adding_cus_course"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Disable Order Select Popup') }}</label>
              <md-switch
                class="switch-style"
                v-model="
                  terminal_profiles.profile_json.disable_order_select_popup
                "
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-33">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
                :disabled="formType == 'View Profile'"
              >
                <label>{{ $t('Tax No.') }}</label>
                <md-input
                  data-vv-name="TaxNo"
                  type="text"
                  v-validate="modelValidations.TaxNo"
                  :disabled="formType == 'View Profile'"
                  v-model="terminal_profiles.profile_json.tax_no"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('TaxNo')">
                    {{ errors.first('TaxNo') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('TaxNo') && touched.TaxNo"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>

            <div class="md-layout-item md-size-33">
              <md-field :disabled="formType == 'View Profile'">
                <label>{{ $t('Bill Order Prefix') }}</label>
                <md-input
                  type="text"
                  :disabled="formType == 'View Profile'"
                  v-model="terminal_profiles.profile_json.bill_order_prefix"
                ></md-input>
                <!-- <slide-y-down-transition>
                  <div class="error" v-show="errors.has('TaxNo')">{{errors.first("TaxNo")}}</div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('TaxNo') && touched.TaxNo">done</md-icon>
                </slide-y-down-transition>-->
              </md-field>
            </div>
          </div>

          <div class="mng-profile">[Billing]</div>
          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Open Draw on Card Payment') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.open_draw_on_card"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Open Draw On Other Payment') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.open_draw_on_other"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Use Additional Cash Draw') }}</label>
              <md-switch
                class="switch-style"
                v-model="
                  terminal_profiles.profile_json.use_additional_cash_draw
                "
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Disable Gratuituy') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.disable_gratuity"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Print priced modifiers') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.print_priced_modifiers"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('CBE PAY Merchant Copy') }}</label>
              <md-switch
                class="switch-style"
                v-model="
                  terminal_profiles.profile_json.cbe_pay_print_merchant_copy
                "
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="mng-profile">[Order]</div>

          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Prompt Deliver To') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.promt_deliver_to"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Compact Order Print') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.compact_order_print"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Allow Finalized Bill') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.allow_finalized_bill"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Fast Payment') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.bill_from_order_screen"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Print Slip After Order Merge Or Join') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.print_slip_after_merge"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Use Takeout As Default Type') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.takeout_as_default_type"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
              >
                <label>{{ $t('Maximum Number Of Guests') }}</label>
                <md-input
                  data-vv-name="Guests"
                  type="text"
                  v-validate="modelValidations.Guests"
                  :disabled="formType == 'View Profile'"
                  v-model="terminal_profiles.profile_json.max_no_of_guest"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Guests')">
                    {{ errors.first('Guests') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Guests') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>

            <div class="md-layout-item">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
              >
                <label>{{ $t('Reset Order Number After') }}</label>
                <md-input
                  data-vv-name="Name"
                  type="text"
                  v-validate="modelValidations.Name"
                  :disabled="formType == 'View Profile'"
                  v-model="terminal_profiles.profile_json.reset_order_no_after"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Name')">
                    {{ errors.first('Name') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Name') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>

            <div class="md-layout-item">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
              >
                <label>{{ $t('Order Number Reset To') }}</label>
                <md-input
                  data-vv-name="Name"
                  type="text"
                  v-validate="modelValidations.Name"
                  :disabled="formType == 'View Profile'"
                  v-model="terminal_profiles.profile_json.order_no_reset_to"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Name')">
                    {{ errors.first('Name') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Name') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-33">
              <md-field>
                <label for="Super Category">{{ $t('Default Menu') }}</label>
                <md-select
                  v-model="terminal_profiles.profile_json.menu_design_screen_id"
                  :disabled="formType == 'View Profile'"
                >
                  <md-option value=""></md-option>
                  <md-option
                    v-for="(item, index) in screens"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</md-option
                  >
                </md-select>
                <div class="error"></div>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33">
              <md-field>
                <label>{{ $t('Default Order Type') }}</label>
                <md-select
                  v-model="terminal_profiles.profile_json.default_order_type"
                  :disabled="formType == 'View Profile'"
                >
                  <md-option class="first-opt" disabled
                    >Default Order Type</md-option
                  >
                  <md-option
                    v-for="(item, index) in orderType"
                    :key="index"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33">
              <md-field>
                <label>{{ $t('Quick Service') }}</label>
                <md-select
                  v-model="
                    terminal_profiles.profile_json.default_cat_quick_service
                  "
                  :disabled="formType == 'View Profile'"
                >
                  <md-option class="first-opt" disabled
                    >Quick Service</md-option
                  >
                  <md-option
                    v-for="(item, index) in orderType"
                    :key="index"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
                <div class="error"></div>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-33">
              <md-field>
                <label for="Super Category">{{ $t('Take Out') }}</label>
                <md-select
                  v-model="terminal_profiles.profile_json.default_cat_take_out"
                  :disabled="formType == 'View Profile'"
                >
                  <md-option class="first-opt" disabled>Take Out</md-option>

                  <md-option
                    v-for="(item, index) in orderType"
                    :key="index"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
                <div class="error"></div>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33">
              <md-field>
                <label for="Super Category">{{ $t('Dine In') }}</label>
                <md-select
                  v-model="terminal_profiles.profile_json.default_cat_dine_in"
                  :disabled="formType == 'View Profile'"
                >
                  <md-option class="first-opt" disabled>Dine In</md-option>

                  <md-option
                    v-for="(item, index) in orderType"
                    :key="index"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
                <div class="error"></div>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33">
              <md-field>
                <label for="Super Category">{{ $t('Web Order') }}</label>
                <md-select
                  v-model="terminal_profiles.profile_json.default_cat_web_order"
                  :disabled="formType == 'View Profile'"
                >
                  <md-option class="first-opt" disabled>Web Order</md-option>

                  <md-option
                    v-for="(item, index) in orderType"
                    :key="index"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
                <div class="error"></div>
              </md-field>
            </div>
          </div>

          <!-- <div class="md-layout"></div> -->
        </md-card-content>
      </md-card>

      <md-card class="block-bg">
        <md-card-header class="md-layout">
          <div class="md-layout-item md-size-70">
            <!-- <span class="arrow_left">
              <router-link :to="{path:'/outlet/profile'}">
                <md-icon class="material-icons">keyboard_arrow_left</md-icon>
              </router-link>
            </span>-->
            <h4 class="title">{{ $t('Diagnostics') }}</h4>
          </div>
          <div class="md-layout-item md-size-30">
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              v-if="formType == 'View Profile'"
              @click="changeFormType()"
              >edit</i
            >
          </div>
        </md-card-header>
        <md-card-content class="profile-elements sub-text-container">
          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Ask Password To Open Cash Draw') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.pwd_to_open_cash_draw"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Print Sales Report Before EOD') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.sales_rprt_before_eod"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>

            <div class="md-layout-item switch-item md-size-33">
              <label>{{
                $t('Disable Image Capture For Clock In / Out')
              }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.disable_img_cap_clk"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item switch-item md-size-33">
              <label>{{ $t('Disable Opening Float') }}</label>
              <md-switch
                class="switch-style"
                v-model="terminal_profiles.profile_json.disable_ope_flt"
                :disabled="formType == 'View Profile'"
              ></md-switch>
            </div>
          </div>

          <div class="btn-div" v-if="formType !== 'View Profile'">
            <md-card-actions class="text-center">
              <md-button
                native-type="submit"
                :to="{ path: '/outlet/profile' }"
                >{{ $t('CANCEL') }}</md-button
              >
              <md-button
                class="md-primary"
                native-type="submit"
                @click="alertModule('Add')"
                >{{ $t('SAVE') }}</md-button
              >
            </md-card-actions>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <md-dialog class="tender-add-popup" :md-active.sync="tooltipActive">
      <md-dialog-title>Add Custom Tender</md-dialog-title>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-field>
            <label>{{ $t('Tender') }}</label>
            <md-input
              v-model="newTender"
              :disabled="formType == 'View Profile'"
            ></md-input>
          </md-field>
        </div>
      </div>

      <md-dialog-actions>
        <md-button @click="tooltipActive = !tooltipActive">{{
          $t('Cancel')
        }}</md-button>
        <md-button class="md-primary" @click="addTender()">{{
          $t('Save')
        }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { SlideYDownTransition } from 'vue2-transitions'
import axios from 'axios'
import { accountService } from '../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },

  data() {
    return {
      formType: '',
      url: '',
      touched: {
        Name: false,
        TaxNo: false,
        Rate: false,
        Number: false,
        Email: false,
        Password: false,
        Guests: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
        TaxNo: {
          required: true,
          numeric: true,
        },
        Number: {
          required: true,
        },
        Email: {
          required: true,
        },
        Password: {
          required: true,
        },
        Guests: {
          numeric: true,
        },
      },
      orderType: ['Quick Service', 'Take Out', 'Web Order', 'Delivery'],
      employee: {
        employe: {
          name: '',
          employe_type: '',
          rate: '',
          phone: '',
        },
        user: {
          user_name: '',
          password: '',
          user_type: '',
          status: 'active',
        },
      },
      changePassword: 'No',
      tenderElements: [5, 10, 20, 50],
      tooltipActive: false,
      newTender: '',

      terminal_profiles: {
        name: '',
        status: 'active',
        outlet_id: accountService.getOutletId(),
        profile_json: {
          auto_logout_txn: false,
          auto_logout_order: false,
          message_after_print_success: false,
          show_tables_as_initial_view: false,
          hide_items_barcode: false,
          enable_time_out: false,
          time_out: '',
          tax_no: '',
          bill_order_prefix: '',
          ask_for_no_of_guest: false,
          display_order_lead_time: false,
          hide_cource_button: false,
          show_option_popup: false,
          allow_adding_cus_course: false,
          disable_order_select_popup: false,
          open_draw_on_card: false,
          open_draw_on_other: false,
          use_additional_cash_draw: false,
          disable_gratuity: false,
          max_no_of_guest: '',
          reset_order_no_after: '',
          order_no_reset_to: '',
          promt_deliver_to: false,
          compact_order_print: false,
          print_slip_on_delete: false,
          print_slip_after_merge: false,
          takeout_as_default_type: false,
          allow_finalized_bill: false,
          default_order_type: '',
          default_cat_quick_service: '',
          default_cat_take_out: '',
          default_cat_dine_in: '',
          default_cat_web_order: '',
          default_cat_delivery: '',
          custom_tender: '5,10,20,50',
          print_bill_by_default: false,
          print_order_no: false,
          print_slip: false,
          print_order_item_by_course: false,
          disable_sales_by_cat: false,
          disable_sales_by_emp: false,
          disable_avg_spend: false,
          disable_sales_by_type: false,
          disable_hourly_sales: false,
          disable_hourly_orders: false,
          printer_heartbeat: false,
          promo_bill_footer: '',
          away_msg: '',
          print_bill_customer_detail: false,
          pwd_to_open_cash_draw: false,
          sales_rprt_before_eod: false,
          disable_img_cap_clk: false,
          disable_ope_flt: false,
        },
      },
      loader: false,
      activetab: 'Active',
      screens: [],
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit('on-submit', this.alertModule('Add'), isValid)
        }
      })
    },

    alertModule(value) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.submitProfile()
        }
      })
    },

    reset() {},

    changeFormType() {
      this.formType = 'Edit Profile'
    },

    addTender() {
      if (this.formType != 'View Profile') {
        if (this.newTender.length > 0) {
          this.tenderElements.push(this.newTender)
        }
      }
    },

    removeTender(index) {
      if (this.formType != 'View Profile') {
        this.tenderElements.splice(index, 1)
      }
    },
    getScreens() {
      var self = this
      customizeService.getScreenMenus().then((res) => {
        self.screens = res.data.screens
      })
    },

    submitProfile() {
      this.terminal_profiles.profile_json.custom_tender =
        this.tenderElements.toString()
      console.log('Submitted profile', this.terminal_profiles)

      if (this.formType != 'Add Profile') {
        delete this.terminal_profiles.url
        delete this.terminal_profiles.created_at
        delete this.terminal_profiles.updated_at
        let body = {
          terminal_profiles: this.terminal_profiles,
        }
        axios
          .put(this.url, body, customizeService.authFun())
          .then((res) => {
            this.$router.push({ path: '/outlet/profile' })
          })
          .catch((err) => console.log('Profile editing error', err))
      } else {
        customizeService.createProfile(this.terminal_profiles).then((res) => {
          this.$router.push({ path: '/outlet/profile' })
        })
      }
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    if (this.formType != 'Add Profile') {
      this.loader = true
      axios
        .get(
          this.url + '?outlet_id=' + accountService.getOutletId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.loader = false
          this.tenderElements = res.data.profile_json.custom_tender.split(',')
          console.log('tender elements', this.tenderElements)
          this.terminal_profiles = res.data
        })
    }
    this.getScreens()
  },
  watch: {
    Name() {
      this.touched.required = true
    },
    TaxNo() {
      this.touched.required = true
    },
    Rate() {
      this.touched.required = true
    },
    Number() {
      this.touched.required = true
    },
    Email() {
      this.touched.required = true
    },
    Password() {
      this.touched.required = true
    },
    Guests() {},
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.package-name {
  margin-top: 8%;
}

.text-center {
  justify-content: center !important;
}
.btn-add-module {
  text-align: center;
}
.arrow_left {
  float: left;
  padding-top: 0px !important;
}
.md-card .md-card-actions {
  border: none;
}

.add-role-btn {
  text-align: center;
  padding-top: 18px;
}

.roles-list {
  /*your fixed height*/
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.roles-list li {
  list-style: none;
}

.ul-style {
  ul {
    padding: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 480px;
    width: 100%;
  }
  li {
    width: 150px;
    height: 42px;
  }
}

.switch-style {
  position: absolute;
  right: 0px;
  width: 50px;
}

.switch-item {
  position: relative;
  display: flex;
}

.switch-item label {
  padding-top: 14px;
  width: 80%;
}

.profile-elements {
  padding: 15px 20px 45px 15px;
}

h6 {
  padding-left: 15px;
}

.back-icon {
  padding-left: 0px;
}

.tender {
  display: flex;
  position: relative;
}

.tender-elements {
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  width: auto;
  min-width: 32px;
  text-align: center;
  border: 2px solid #2b82be;
}

.add-tender {
  position: absolute;
  text-align: right;
  right: 0%;
  padding-right: 5%;
}

.btn-div {
  padding-top: 48px;
}
.md-card {
  .add-profile {
    padding-left: 7px;
    padding-top: 25px;
  }
}

.md-card {
  .md-card-header {
    h4 {
      font-weight: 300;
      margin-top: 2px;
    }
  }
}

.mng-profile {
  font-size: 14px;
  font-weight: 700;
  color: #5c6572;
  margin-top: 10px;
}
.sub-text {
  padding: 0px 20px;
}
.sub-text-container {
  padding: 0px 30px 30px;
}
.tender-add {
  margin-left: 10px;
}
.tender-add-popup {
  padding: 15px 10px;
  .md-button {
    .md-ripple {
      padding: 8px 0px;
      width: 80px;
    }
  }
}
</style>
