<template>
  <form @submit.prevent class="md-layout-item pd-l">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <h3 class="my-2">Stripe</h3>
        </div>
      </md-card-header>

      <!-- <div
        style="margin: 20px 0"
        class="btn-div md-layout-item md-size-60 md-alignment-center-right"
      >
        <md-button
          type="button"
          @click="createAccount()"
          style="float: right"
          class="md-primary"
          >Create/login Stripe Accocunt

          <md-progress-spinner
            class="md-accent"
            md-mode="indeterminate"
            :md-diameter="30"
            v-if="loading"
          ></md-progress-spinner>
        </md-button>
      </div> -->

      <!-- <div
        style="margin: 20px 0"
        class="btn-div md-layout-item md-size-60 md-alignment-center-right"
        v-if="!loading && stripe_account_no"
      >
        <md-button
          type="button"
          @click="linkAccount()"
          style="float: right"
          class="md-primary"
          >Link Stripe Accocunt</md-button
        >
      </div> -->
    </md-card>
    <div class="md-layout-item pd-l">
      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>
      <ul class="invoice-list" v-if="!loader">
        <li>
          <a
            class="button secondary download text-primary font-weight-bold h5 cursor-pointer"
            :href="stripeCustomer.url"
          >
            <span class="mr-2">Stripe Dashboard</span>
            <img class="" src="../../../assets/images/download.png" />
          </a>
        </li>
      </ul>
    </div>
    <!-- <div v-for="invoice in stripeInvoices" :key="invoice.id"> -->
    <!-- <a class="button secondary download" :href="stripeCustomer.url">
      Download
    </a> -->
    <!-- </div> -->
  </form>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import store from '@/store'
import { accountService } from '../../../services/account.js'
import { stripeService } from '../../../services/stripe.js'

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  data() {
    return {
      email: '',
      stripe_account_no: null,
      loading: false,
      stripeInvoices: [],
      stripeCharges: [],
      stripeCustomer: {},
      loader: false,
    }
  },
  methods: {
    createAccount() {
      this.loading = true
      if (!this.stripe_account_no) {
        customizeService.createStripeAccount(this.email).then((res) => {
          this.stripe_account_no = res.data.id
          this.linkAccount()
        })
      } else {
        this.linkAccount()
      }
    },

    linkAccount() {
      this.loading = true
      customizeService.linkStripeAccount(this.email).then((res) => {
        this.loading = false
        window.open(res.data.url, '_self')
      })
    },
    listInvoices() {
      stripeService.getStripeInvoices().then((res) => {
        this.stripeInvoices = res.data.invoices.data
        this.stripeCharges = res.data.charges.data
        this.stripeCustomer = res.data.customer
        this.loader = false
      })
    },
  },

  created() {
    this.loader = true
    customizeService.getOutletById(accountService.getOutletId()).then((res) => {
      this.stripe_account_no = res.data.outlet.stripe_account_no
      this.loading = false
    })
    this.listInvoices()
  },
}
</script>

<style scoped>
.error {
  color: red;
}
</style>
