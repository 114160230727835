<template>
  <div class="md-layout">
    <div class="md-layout-item pd-l">
      <div>
        <md-card class="pd-l">
          <md-card-header
            class="md-card-header-text md-card-header-green md-layout md-gutter"
          >
            <div class="md-layout-item md-size-70">
              <span class="arrow_left ml-auto">
                <router-link :to="{ path: '/products/MenuItem' }">
                  <img src="../../../assets/images/left.svg" />
                </router-link>
              </span>
              <h3>{{ $t(formType) }}</h3>
            </div>
            <div
              class="md-layout-item md-size-30 pad-xy"
              v-if="formType === 'View Menu Item'"
            >
              <i
                class="md-icon md-icon-font md-theme-default edit-img"
                @click="editMenuItem"
                >edit</i
              >
            </div>
          </md-card-header>

          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <md-card-content class="block-bg pad-xy rounded-xl" v-if="!loader">
            <form data-vv-scope="menu">
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field
                    :class="[
                      { 'md-error': errors.has('menu.Name') },
                      {
                        'md-valid': !errors.has('menu.Name') && touched.Name,
                      },
                    ]"
                  >
                    <label>{{ $t('Name') }}*</label>
                    <md-input
                      type="text"
                      v-model="menuitems.name"
                      data-vv-name="Name"
                      v-validate="modelValidations.Name"
                      :disabled="formType === 'View Menu Item'"
                    ></md-input>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('menu.Name')">
                        {{ errors.first('menu.Name') }}
                      </div>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="!errors.has('menu.Name') && touched.Name"
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-50">
                  <md-field
                    :class="[
                      { 'md-error': errors.has('menu.shortName') },
                      {
                        'md-valid':
                          !errors.has('menu.shorName') && touched.shortName,
                      },
                    ]"
                  >
                    <label>{{ $t('POS Short Name') }}*</label>
                    <md-input
                      type="text"
                      v-model="menuitems.short_name"
                      data-vv-name="shortName"
                      v-validate="modelValidations.shortName"
                      :disabled="formType === 'View Menu Item'"
                    ></md-input>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('menu.shortName')">
                        {{ errors.first('menu.shortName') }}
                      </div>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="
                          !errors.has('menu.shortName') && touched.shortName
                        "
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field
                    :class="[
                      { 'md-error': errors.has('menu.sku') },
                      { 'md-valid': !errors.has('menu.sku') && touched.sku },
                    ]"
                  >
                    <label>{{ $t('SKU') }}*</label>
                    <md-input
                      type="text"
                      v-model="menuitems.item_code"
                      data-vv-name="sku"
                      v-validate="modelValidations.sku"
                      :disabled="true"
                    ></md-input>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('menu.sku')">
                        {{ errors.first('menu.sku') }}
                      </div>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="!errors.has('menu.sku') && touched.sku"
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Barcode') }}</label>
                    <md-input
                      type="text"
                      v-model="menuitems.bar_code"
                      :disabled="formType === 'View Menu Item'"
                    ></md-input>
                  </md-field>
                </div>
              </div>

              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field
                    v-if="portionCheck !== true"
                    :class="[
                      { 'md-error': errors.has('menu.salePrice') },
                      {
                        'md-valid':
                          !errors.has('menu.salePrice') && touched.salePrice,
                      },
                    ]"
                  >
                    <label>{{ $t('Sale Price') }}*</label>
                    <md-input
                      type="text"
                      v-model="menuitems.price"
                      data-vv-name="salePrice"
                      v-validate="modelValidations.salePrice"
                      :disabled="formType === 'View Menu Item'"
                    ></md-input>

                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('menu.salePrice')">
                        {{ errors.first('menu.salePrice') }}
                      </div>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="
                          !errors.has('menu.salePrice') && touched.salePrice
                        "
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                    <i
                      class="md-icon md-icon-font md-theme-default add-pop cursor-pointer"
                      @click="menuPricLevels = true"
                      :disabled="formType === 'View Menu Item'"
                      v-if="priceLevels && priceLevels.length > 0"
                      >add</i
                    >
                  </md-field>
                </div>

                <div class="md-layout-item md-size-50">
                  <md-field
                    :class="[
                      { 'md-error': errors.has('menu.tax') },
                      { 'md-valid': !errors.has('menu.tax') && touched.tax },
                    ]"
                  >
                    <label>
                      {{ $t('Tax') }}(%)
                      <span v-if="portionCheck === true">{{
                        $t('For Din In Portion')
                      }}</span
                      >*
                    </label>
                    <md-select
                      class="select_btn"
                      v-model="menuitems.tax"
                      data-vv-name="tax"
                      v-validate="modelValidations.tax"
                      :disabled="formType === 'View Menu Item'"
                      @click="getTax()"
                    >
                      <md-option
                        :value="item"
                        v-for="item in taxList"
                        :key="item"
                        >{{ item }}</md-option
                      >
                    </md-select>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('menu.tax')">
                        {{ errors.first('menu.tax') }}
                      </div>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="!errors.has('menu.tax') && touched.tax"
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div>

              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field
                    class="select-new"
                    :class="[
                      { 'md-error': errors.has('menu.mainCat') },
                      {
                        'md-valid':
                          !errors.has('menu.mainCat') && touched.mainCat,
                      },
                    ]"
                  >
                    <label>{{ $t('Main Category') }}*</label>
                    <md-select
                      class="select_btn"
                      v-model="menuitems.main_category_id"
                      data-vv-name="mainCat"
                      v-validate="modelValidations.mainCat"
                      :disabled="formType === 'View Menu Item'"
                      @click="getmainCategory()"
                    >
                      <md-option
                        :value="item.id"
                        v-for="item in mainCategory"
                        :key="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('menu.mainCat')">
                        {{ errors.first('menu.mainCat') }}
                      </div>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="!errors.has('menu.mainCat') && touched.mainCat"
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                    <!-- <md-button
                    class="md-button md-primary md-theme-default"
                    @click="addMainCat"
                      >{{$t('New')}}</md-button>-->
                    <i
                      class="md-icon md-icon-font md-theme-default add-pop cursor-pointer"
                      @click="addMainCat"
                      :disabled="formType === 'View Menu Item'"
                      >add</i
                    >
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field class="select-new">
                    <label>{{ $t('Sub Category') }}</label>
                    <md-select
                      class="select_btn"
                      v-model="menuitems.cat_id"
                      :disabled="formType === 'View Menu Item'"
                      @click="getsubCategory()"
                    >
                      <md-option value="">Select</md-option>
                      <md-option
                        :value="item.id"
                        v-for="item in subCategory"
                        :key="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>

                    <i
                      class="md-icon md-icon-font md-theme-default add-pop cursor-pointer"
                      @click="showSubDialog = true"
                      :disabled="formType === 'View Menu Item'"
                      >add</i
                    >
                  </md-field>
                </div>
              </div>

              <!-- Modifier Groups --->
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-checkbox
                    v-model="modifierGroup"
                    :disabled="formType === 'View Menu Item'"
                    >{{ $t('Modifier Group') }}</md-checkbox
                  >
                </div>
                <div class="md-layout-item color-picker-layout md-size-50">
                  <div class="form__field">
                    <div class="form__input">
                      <label class="form__label">{{ $t('Color') }}</label>
                      <swatches
                        v-model="menuitems.item_color"
                        :colors="'text-advanced'"
                        shapes="circles"
                        popover-to="center"
                      ></swatches>

                      <i
                        class="md-icon md-icon-font md-icon-image md-theme-default"
                      >
                        <svg
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z" />
                          <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                      </i>
                      <div class="custm-color-picker">{{ menuCodeShow }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="md-layout modifierGpData block-bg"
                v-if="modifierGroup === true"
              >
                <div class="md-layout-item md-size-100">
                  <div class="modifier-add md-layout">
                    <div
                      class="md-layout-item md-size-50 mod-click"
                      @click="modDialog = true"
                    >
                      <h5 class="text-dark">
                        {{ $t('Modifier Group') }}
                        <i
                          class="md-icon md-icon-font md-theme-default cursor-pointer"
                          :disabled="formType === 'View Menu Item'"
                          >add</i
                        >
                      </h5>
                    </div>
                    <div class="md-layout-item md-size-50">
                      <md-field
                        v-if="
                          confirmModGroups.length > 0 &&
                          formType != 'View Menu Item'
                        "
                      >
                        <label>Sort By:</label>
                        <md-select
                          v-model="menuitems.group_sort_order_type"
                          v-on:input="updateSortOrder()"
                        >
                          <md-option value="0">{{ $t('Priotity') }}</md-option>
                          <md-option value="1">{{ $t('Ascending') }}</md-option>
                          <md-option value="2">{{
                            $t('Descending')
                          }}</md-option>
                          <md-option value="3">{{ $t('Custom') }}</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>

                <div
                  class="md-layout-item md-size-100"
                  v-if="confirmModGroups.length > 0"
                >
                  <draggable
                    class="list-group"
                    :list="confirmModGroups"
                    group="people"
                    :move="enableDrag"
                    :disabled="formType === 'View Menu Item'"
                  >
                    <transition-group>
                      <div
                        class="list-group-item"
                        v-for="(element, index) in confirmModGroups"
                        :key="`key-${index}`"
                      >
                        {{ element.name }}
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
              <!-- Menu Item List --->
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-checkbox
                    v-model="showMenuList"
                    :disabled="formType === 'View Menu Item'"
                    >{{ $t('Menu Item List') }}</md-checkbox
                  >
                </div>
              </div>
              <div class="md-layout menulistbg" v-if="showMenuList">
                <menu-item-list
                  ref="MenuList"
                  :menu-list="menuitems.menu_item_menu_list_links_attributes"
                  @set-drs-price="setDrsPrice"
                />
              </div>

              <!-- EOF Modifier Groups --->
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Printer') }}</label>
                    <md-select
                      v-model="menuitems.printer_ids"
                      multiple
                      :disabled="formType === 'View Menu Item'"
                      @click="printer()"
                    >
                      <md-option
                        :value="item.id"
                        v-for="item in printerList"
                        :key="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Course') }}</label>
                    <md-select
                      v-model="menuitems.sub_cat_id"
                      :disabled="formType === 'View Menu Item'"
                      @click="printerGroup()"
                    >
                      <md-option value="">No Course</md-option>
                      <md-option
                        :value="item.id"
                        v-for="item in printerGroupList"
                        :key="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
              </div>

              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Add To POS Favourites') }} ?</label>
                    <md-select
                      v-model="menuitems.fat_free"
                      :disabled="formType === 'View Menu Item'"
                    >
                      <md-option value>{{ $t('Select') }}</md-option>
                      <md-option value="true">{{ $t('Yes') }}</md-option>
                      <md-option value="false">{{ $t('No') }}</md-option>
                    </md-select>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Status') }}</label>
                    <md-select
                      v-model="menuitems.status"
                      :disabled="formType === 'View Menu Item'"
                    >
                      <md-option value="active"> Active </md-option>
                      <md-option value="not_active"> Inactive </md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>

              <!---   Advanced Menus -->

              <div class="card-expansion">
                <md-card class="advanced">
                  <md-card-expand>
                    <md-card-actions md-alignment="space-between">
                      <md-card-expand-trigger>
                        <md-button class="advanced-btn"
                          >Advanced
                          <div @click="showCard = !showCard">
                            <i
                              v-bind:class="
                                showCard ? 'fa fa-angle-up' : 'fa fa-angle-down'
                              "
                              aria-hidden="true"
                            ></i>
                          </div>
                        </md-button>
                      </md-card-expand-trigger>
                    </md-card-actions>
                    <md-card-expand-content>
                      <md-card-content>
                        <div class="md-layout">
                          <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>{{ $t('Take away Price') }}</label>
                              <md-input
                                type="text"
                                v-model="menuitems.take_out_price"
                                :disabled="formType === 'View Menu Item'"
                              ></md-input>
                              <i
                                class="md-icon md-icon-font md-theme-default add-pop"
                                @click="takeOutPricLevels = true"
                                :disabled="formType === 'View Menu Item'"
                                >add</i
                              >
                            </md-field>
                          </div>

                          <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>{{
                                $t(
                                  'Tax(%) (Select Appropriate Tax Rate For Take Away)'
                                )
                              }}</label>
                              <!-- <md-input
                                  type="text"
                                  v-model="menuitems.take_away_tax"
                                  :disabled="formType==='View Menu Item'"
                                  ></md-input>-->
                              <md-select
                                class="select_btn"
                                v-model="menuitems.take_away_tax"
                                :disabled="formType === 'View Menu Item'"
                                @click="getTax()"
                              >
                                <md-option
                                  :value="item"
                                  v-for="item in taxList"
                                  :key="item"
                                  >{{ item }}</md-option
                                >
                              </md-select>
                            </md-field>
                          </div>
                        </div>
                        <div class="md-layout">
                          <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>{{ $t('Cost Price(Ex Tax)') }}</label>
                              <md-input
                                type="text"
                                v-model="menuitems.cost_price"
                                :disabled="formType === 'View Menu Item'"
                              ></md-input>
                            </md-field>
                          </div>

                          <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>{{ $t('Special Price') }}</label>
                              <md-input
                                type="text"
                                v-model="menuitems.special_price"
                                :disabled="formType === 'View Menu Item'"
                              ></md-input>
                            </md-field>
                          </div>
                        </div>

                        <!-- Single Combo -->

                        <div
                          class="md-layout-item md-size-50"
                          v-if="
                            $store.state.account.profile.user_type == 'user'
                          "
                        >
                          <md-checkbox
                            v-model="isSingleCombo"
                            @click="isSingleCombo = !isSingleCombo"
                            :disabled="formType === 'View Menu Item'"
                            >{{ $t('Single Combo?') }}</md-checkbox
                          >
                          <div class="md-layout-item" v-if="isSingleCombo">
                            <md-field>
                              <label for="Combos">{{ $t('Combos') }}</label>
                              <!-- <md-select
                                  v-model="menuitems.single_combo_id"
                                  @click="getCombos()"
                                >
                                  <md-option
                                    v-for="item in combos"
                                    :key="item.id"
                                    :value="item.id"
                                    >{{ item.name }}</md-option
                                  >
                                </md-select> -->

                              <md-select
                                v-model="menuitems.single_combo_id"
                                :disabled="formType === 'View Menu Item'"
                              >
                                <md-option
                                  :value="item.id"
                                  v-for="item in combos"
                                  :key="item.id"
                                  >{{ item.name }}</md-option
                                >
                              </md-select>
                            </md-field>
                          </div>
                        </div>

                        <div class="md-layout">
                          <div class="md-layout-item md-size-50">
                            <md-checkbox
                              v-model="portionCheck"
                              :disabled="formType === 'View Menu Item'"
                              >Portion</md-checkbox
                            >
                            <i
                              class="md-icon md-icon-font md-theme-default"
                              @click="addPortion"
                              v-if="portionCheck === true"
                              :disabled="formType === 'View Menu Item'"
                              >add</i
                            >
                          </div>
                          <div class="md-layout-item md-size-50">
                            <md-checkbox
                              v-model="menuitems.is_menu_list_optional"
                              :disabled="formType === 'View Menu Item'"
                              >{{ $t('Stay Open Until Close') }}?</md-checkbox
                            >
                          </div>
                        </div>
                        <div
                          class="portionData block-bg"
                          v-show="portionList.length > 0"
                        >
                          <div
                            class="md-layout"
                            v-for="(item, index) in portionList"
                            :key="`key-${index}`"
                          >
                            <div class="md-layout-item md-size-30">
                              <md-field>
                                <label>{{ $t('Select') }}</label>
                                <md-select
                                  class="select_btn"
                                  v-model="item.portion_id"
                                  :disabled="formType === 'View Menu Item'"
                                >
                                  <md-option
                                    :value="item.id"
                                    v-for="item in portion"
                                    :key="item.id"
                                    >{{ item.name }}</md-option
                                  >
                                </md-select>
                              </md-field>
                            </div>
                            <div class="md-layout-item md-size-30">
                              <md-field>
                                <label>{{ $t('Dine In Price') }}</label>
                                <md-input
                                  type="text"
                                  v-model="item.din_in_price"
                                  :disabled="formType === 'View Menu Item'"
                                ></md-input>
                              </md-field>
                            </div>
                            <div class="md-layout-item md-size-30">
                              <md-field>
                                <label>{{ $t('Take away Price') }}</label>
                                <md-input
                                  type="text"
                                  v-model="item.take_away_price"
                                  :disabled="formType === 'View Menu Item'"
                                ></md-input>
                              </md-field>
                            </div>
                            <div class="md-layout-item md-size-10 close-button">
                              <i
                                class="md-icon md-icon-font md-theme-default"
                                @click="removePortion(index)"
                                :disabled="formType === 'View Menu Item'"
                                >close</i
                              >
                            </div>
                          </div>
                        </div>

                        <div class="md-layout">
                          <div class="md-layout-item md-size-50">
                            <md-checkbox
                              v-model="menuitems.is_price_edit"
                              :disabled="formType === 'View Menu Item'"
                              >{{ $t('Allow Price Edit') }}?</md-checkbox
                            >
                          </div>

                          <div class="md-layout-item md-size-50">
                            <md-checkbox
                              v-model="menuitems.is_negative"
                              :disabled="formType === 'View Menu Item'"
                              >{{ $t('Negative Menu Item') }}?</md-checkbox
                            >
                          </div>

                          <div class="md-layout-item md-size-50">
                            <md-checkbox
                              v-model="menuitems.is_mapped"
                              :disabled="formType === 'View Menu Item'"
                              >{{ $t('Mapped Item') }}?</md-checkbox
                            >
                          </div>
                          <div class="md-layout-item md-size-50">
                            <md-checkbox
                              v-model="menuitems.exclude_from_discount"
                              :disabled="formType === 'View Menu Item'"
                              >{{ $t('Discount Excluded') }}?</md-checkbox
                            >
                          </div>
                        </div>
                      </md-card-content>
                    </md-card-expand-content>
                  </md-card-expand>
                </md-card>
              </div>
              <!------------------------------------------  EOF Advanced Menus ---------------------------------------------------->

              <!-- <div class="md-layout-item md-size-50">
                <md-checkbox
                  v-model="menuitems.flipdish_token"
                  :disabled="formType === 'View Menu Item'"
                  >{{ $t('Mapped Item') }}?</md-checkbox
                >
              </div> -->
              <div v-if="$store.state.account.profile.user_type == 'user'">
                <div class="md-layout-item md-size-50">
                  <md-checkbox v-model="flip_dish">{{
                    $t('Flip Dish')
                  }}</md-checkbox>
                </div>
                <flipdishForm
                  ref="flipdishform"
                  :flipdishProp="query_param"
                  :flipTitle="menuitems.name"
                  :flipDescription="menuitems.short_name"
                  :flipPrice="menuitems.price"
                  :flipDrs="depositReturnFee"
                  v-show="flip_dish"
                ></flipdishForm>
              </div>

              <!-- <div class="card-expansion">
                <md-card class="advanced">
                  <md-card-expand>
                    <md-card-actions md-alignment="space-between">
                      <md-card-expand-trigger>
                        <div class="md-layout-item md-size-50">
                          <md-button class="advanced-btn">Advanced </md-button>
                        </div>
                      </md-card-expand-trigger>
                    </md-card-actions>
                    <md-card-expand-content>
                      <md-card-content>
                        hhhh
                      </md-card-content>
                    </md-card-expand-content>
                  </md-card-expand>
                </md-card>
              </div> -->

              <!-- Is Online -->
              <div
                class="md-layout-item md-size-50"
                v-if="
                  $store.state.account.profile.user_type == 'user' &&
                  $store.state.account.isOnline
                "
              >
                <md-checkbox
                  v-model="menuitems.is_online"
                  :disabled="formType === 'View Menu Item'"
                  >{{ $t('Is Online?') }}</md-checkbox
                >
              </div>
              <form data-vv-scope="online">
                <onlineOrder
                  :mod-groups="modGroups"
                  :onlineModGroups="menuitems.online_modifier_groups"
                  :onlineData="menuitems.online_details"
                  :price="menuitems.price"
                  :name="menuitems.name"
                  :menuItem="menuitems"
                  ref="onlineOder"
                  v-if="menuitems.is_online && !loader"
                />
              </form>
              <!-- <img :src="menuitems.img_url" /> -->

              <div class="btn-div" v-if="formType !== 'View Menu Item'">
                <md-card-actions class="text-center">
                  <md-button
                    native-type="submit"
                    :to="{ path: '/products/MenuItem' }"
                    >{{ $t('CANCEL') }}</md-button
                  >
                  <md-button
                    native-type="submit"
                    class="md-primary"
                    @click.native.prevent="validate('menu')"
                    >{{ $t('SAVE') }}</md-button
                  >
                </md-card-actions>
              </div>
            </form>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <!-------------------------------------------------- Dialog Box -------------------------------------------------->

    <md-dialog :md-active.sync="showDialog" class="new-main-popup">
      <md-dialog-title>{{ $t('New Main Category') }}</md-dialog-title>
      <form data-vv-scope="main" class="dialog-top">
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              class="mainCat-field"
              :class="[
                { 'md-error': errors.has('main.mainName') },
                {
                  'md-valid': !errors.has('main.mainName') && touched.required,
                },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                data-vv-name="mainName"
                type="text"
                v-validate="modelValidations.mainName"
                v-model="mainCategoryPop.name"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('main.mainName')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('main.mainName') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('main.mainShort') },
                {
                  'md-valid': !errors.has('main.mainShort') && touched.required,
                },
              ]"
            >
              <label>{{ $t('Short Name') }}</label>
              <md-input
                data-vv-name="mainShort"
                type="text"
                v-validate="modelValidations.mainShort"
                v-model="mainCategoryPop.short_name"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('main.mainShort')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('main.mainShort') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label for="Super Category">{{ $t('Super Category') }}</label>
              <md-select v-model="mainCategoryPop.main_cat_id">
                <md-option
                  v-for="item in superCategoryPop"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <div class="form__label">{{$t('Color')}}</div>
            <swatches v-model="mainCategoryPop.code_color" :colors="colors" inline></swatches>
          </div>
        </div>-->

        <div class="md-layout color-picker-layout">
          <div class="md-layout-item">
            <div class="form__field">
              <div class="form__input">
                <label class="form__label">{{ $t('Color') }}</label>
                <swatches
                  v-model="mainCategoryPop.code_color"
                  :colors="'text-advanced'"
                  shapes="circles"
                  popover-to="center"
                ></swatches>

                <i class="md-icon md-icon-font md-icon-image md-theme-default">
                  <svg
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 10l5 5 5-5z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </i>
                <div class="custm-color-picker">{{ colorCodeShow }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('main.mainDes') },
                { 'md-valid': !errors.has('main.mainDes') && touched.required },
              ]"
            >
              <label>{{ $t('Description') }}</label>
              <md-input
                data-vv-name="mainDes"
                type="text"
                v-validate="modelValidations.mainDes"
                v-model="mainCategoryPop.description"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('main.mainDes')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('main.mainDes') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <md-dialog-actions class="main-actions">
          <md-button native-type="submit" @click="reset">{{
            $t('CANCEL')
          }}</md-button>
          <md-button
            native-type="submit"
            @click.native.prevent="validate('main')"
            class="md-primary"
            >{{ $t('SAVE') }}</md-button
          >
        </md-dialog-actions>
      </form>
    </md-dialog>

    <!-- Sub Category -->

    <md-dialog :md-active.sync="showSubDialog" class="new-sub-category">
      <form data-vv-scope="sub" class="dialog-top-sub">
        <md-dialog-title>{{ $t('New Sub Category') }}</md-dialog-title>
        <div class="md-layout main-lab1">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('sub.subName') },
                { 'md-valid': !errors.has('sub.subName') && touched.subName },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                id="subName"
                v-model="subCategoryPop.name"
                data-vv-name="subName"
                type="text"
                v-validate="modelValidations.subName"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('sub.subName')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('sub.subName') && touched.subName"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('sub.subShort') },
                { 'md-valid': !errors.has('sub.subShort') && touched.required },
              ]"
            >
              <label>{{ $t('Short Name') }}</label>
              <md-input
                v-model="subCategoryPop.short_name"
                data-vv-name="subShort"
                type="text"
                v-validate="modelValidations.subShort"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('sub.subShort')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('sub.subShort') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('sub.subDes') },
                { 'md-valid': !errors.has('sub.subDes') && touched.required },
              ]"
            >
              <label>{{ $t('Description') }}</label>
              <md-input
                v-model="subCategoryPop.description"
                data-vv-name="subDes"
                type="text"
                v-validate="modelValidations.subDes"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('sub.subDes')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('sub.subDes') && touched.Description"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <md-dialog-actions class="main-actions-sub">
          <md-button native-type="submit" @click="resetsub">{{
            $t('CANCEL')
          }}</md-button>
          <md-button
            native-type="submit"
            @click.native.prevent="validate('sub')"
            class="md-primary"
            >{{ $t('SAVE') }}</md-button
          >
        </md-dialog-actions>
      </form>
    </md-dialog>

    <!-- Modifier Groups -->

    <md-dialog :md-active.sync="modDialog" class="modifier-dialog">
      <md-dialog-title class="md-layout">
        <div class="md-layout-item md-size-50 left-padding">
          Modifier Groups
        </div>
        <div class="close-icon">
          <i
            class="md-icon md-icon-font md-theme-default"
            @click="modDialog = false"
            >close</i
          >
        </div>
      </md-dialog-title>
      <div class="modifier-checkbox">
        <md-list>
          <md-list-item
            v-for="(item, index) in modGroups"
            :key="`key-${index}`"
          >
            {{ item.name }}
            <md-checkbox
              :model="ModGroupsIds"
              :value="item.id"
              @change="findModifier()"
            ></md-checkbox>
          </md-list-item>
        </md-list>
      </div>
    </md-dialog>

    <!-- Outlet Menus -->

    <md-dialog
      :md-active.sync="showOutlets"
      class="outlet-menu-prices price-outlets"
    >
      <md-dialog-content>
        <div class="md-layout mb-25">
          <div class="md-layout-item">
            <table class="tables table-bordered">
              <tr>
                <td>
                  <md-checkbox
                    v-model="selAll"
                    @change="setAll($event)"
                    :disabled="formType == 'Edit Menu Item'"
                    :class="{ 'md-checked': formType == 'Edit Menu Item' }"
                  ></md-checkbox
                  >Outlet
                </td>
                <td>Price</td>

                <td>Take Away Price</td>
                <td>
                  Tax (%)
                  <span class="font-11"
                    >(Select Appropriate Tax Rate For Take Away)</span
                  >
                </td>
              </tr>

              <tr v-for="outlet in outletList" :key="outlet.id">
                <!-- <td><input type="checkbox" id="checkbox" />{{ outlet.name }}</td> -->
                <md-checkbox
                  v-model="menuPrices"
                  :value="outlet"
                  :disabled="formType == 'Edit Menu Item'"
                  @change="setPrice(outlet.outlet_id)"
                >
                  {{ outlet.outlet_name }}
                </md-checkbox>
                <td>
                  <md-field>
                    <md-input v-model="outlet.price" type="text"></md-input>
                  </md-field>
                </td>

                <td>
                  <md-field>
                    <md-input
                      v-model="outlet.take_away_price"
                      type="text"
                    ></md-input>
                  </md-field>
                </td>
                <td>
                  <md-field>
                    <md-select
                      class="select_btn"
                      v-model="outlet.take_away_tax"
                      :disabled="formType === 'View Menu Item'"
                      @click="getTax()"
                    >
                      <md-option
                        :value="item"
                        v-for="item in taxList"
                        :key="item"
                        >{{ item }}</md-option
                      >
                    </md-select>
                  </md-field>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="md-layout-item md-small-size-100 toggle-price"
          v-if="formType == 'Edit Menu Item'"
        >
          <md-switch v-model="appyHeadOffice" @change="togglePrice"
            >Apply Head Office Price({{
              parseFloat(menuitems.price).toFixed(2)
            }})</md-switch
          >
        </div>
        <div class="d-flex justify-content-center">
          <md-button
            native-type="submit"
            class="md-primary"
            @click="submitMenuItem()"
            >{{ $t('SAVE') }}</md-button
          >
        </div>
      </md-dialog-content>
    </md-dialog>

    <!-- Price Levels -->
    <md-dialog :md-active.sync="menuPricLevels" class="menu-price-levels">
      <h4>Price Levels</h4>
      <md-dialog-content>
        <div v-for="element in priceLevels" :key="element.id">
          <md-field>
            <label> {{ element.name }}</label>
            <md-input
              type="text"
              v-model="element.s_price"
              :disabled="formType === 'View Menu Item'"
            ></md-input>
          </md-field>
        </div>
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="takeOutPricLevels" class="menu-price-levels">
      <div v-for="element in priceLevels" :key="element.id">
        <md-field>
          {{ element.name }}
          <md-input
            type="text"
            v-model="element.t_price"
            :disabled="formType === 'View Menu Item'"
          ></md-input>
        </md-field>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { Tabs } from '@/components'
import { Collapse } from '@/components'
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import flipdishForm from './Flipdish.vue'
import onlineOrder from './OnlineOrder.vue'
import MenuItemList from './Components/MenuItemList.vue'
import Swal from 'sweetalert2'
import Example from './Example.vue'

import draggable from 'vuedraggable'
import Swatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import { SlideYDownTransition } from 'vue2-transitions'
import { accountService } from '../../../services/account'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import axios from 'axios'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import store from '@/store'
export default {
  name: 'DialogCustom',
  components: {
    Tabs,
    Collapse,
    Swatches,
    draggable,
    SlideYDownTransition,
    flipdishForm,
    Example,
    ClipLoader,
    onlineOrder,
    MenuItemList,
  },
  provide() {
    return {
      $validator: this.$validator,
    }
  },
  data() {
    return {
      Name: '',
      shortName: '',
      sku: '',
      salePrice: '',
      tax: '',
      mainCat: '',
      removeNullProps: '',
      colorCodeShow: '',
      menuCodeShow: '',
      MenuId: '',
      endPoint: '',
      selAll: false,
      touched: {
        Name: false,
        shortName: false,
        sku: false,
        salePrice: false,
        tax: false,
        mainCat: false,
        subName: false,
        subShort: false,
        subDes: false,
        mainName: false,
        mainShort: false,
        mainDes: false,
        MenuId: false,
      },
      modelValidations: {
        mainCat: {
          required: true,
        },
        Name: {
          required: true,
        },
        shortName: {
          required: true,
        },
        sku: {
          required: true,
        },
        salePrice: {
          required: true,
        },
        tax: {
          required: true,
        },
        subName: {
          required: true,
        },
        subShort: {
          required: true,
        },
        subDes: {
          required: true,
        },
        mainName: {
          required: true,
        },
        mainShort: {
          required: true,
        },
        mainDes: {
          required: true,
        },
        MenuId: {
          required: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Outlet',
      },
      outletId: '',
      outlets: {
        outlet: {},
        login: {
          user_type: 'user',
        },
      },
      color: '',
      colors: ['green', 'red', 'blue', 'grey', 'yellow', 'violet'],
      portionCheck: false,
      checkSetMenu: false,
      drag: false,
      modifierGroup: false,
      showMenuList: false,

      showDialog: false,
      showSubDialog: false,
      portionList: [],
      portion: [],
      mainCategory: [],
      subCategory: [],
      flip_dish: false,
      query_param: {
        Title: '',
        flipdish_menu_id: '',
        flipdish_menu_section_id: '',
        flipdish_item_id: '',
      },
      menuitems: {
        modifier_groups: {},
        portions: [],
        is_price_edit: false,
        is_negative: false,
        is_mapped: false,
        status: 'active',
        menu_item_menu_list_links_attributes: [],
      },
      mainCategoryPop: {},
      superCategoryPop: [{}],
      subCategoryPop: {},
      modGroups: [],
      confirmModGroups: [],
      ModGroupsIds: [],
      printerGroupList: [],
      printerList: [],
      modifierGroupList: [],
      prioList: [],
      taxList: [],
      flipdishMenus: [],
      flipdishMenusCalled: false,
      flipdishMenuSection: [],
      flipdishMenuSectionCalled: false,
      getPortionsCalled: false,
      getmainCategoryCalled: false,
      getsubCategoryCalled: false,
      printerGroupCalled: false,
      printerCalled: false,
      getTaxCalled: false,
      modDialog: false,
      loader: false,
      showCard: false,
      showOutlets: false,
      outletList: [],
      menuPrices: [],
      combos: [],
      isSingleCombo: false,
      priceLevels: [],
      menuPricLevels: false,
      takeOutPricLevels: false,
      flipdishMenuModifiers: [],
      flipdishComboId: '',
      itemInfo: {},
      appyHeadOffice: false,
      tempOutletMenus: [],
      depositReturnFee: '',
    }
  },
  methods: {
    validate(scope) {
      this.$validator.validateAll(scope).then((isValid) => {
        if (!this.is_online && isValid) {
          if (
            store.state.account.profile.user_type == 'branch_user' &&
            (this.formType === 'Add Menu Item' ||
              this.formType === 'Edit Menu Item')
          ) {
            this.showOutlets = true
          } else {
            this.$emit('on-submit', this.alert(scope), isValid)
          }
        } else {
          this.$refs.onlineOder.$validator.validateAll('online').then((res) => {
            if (isValid && res) {
              this.$emit('on-submit', this.alert(scope), isValid)
            }
          })
        }
      })
    },
    changeEvent(index, event, type) {
      if (this.priceLevelSales[index] && type == 'sprice') {
        this.priceLevelsData[index] = event
        this.priceLevelsData[index]['s_price'] = parseInt(
          this.priceLevelSales[index]
        )
      } else {
        this.priceLevelsData[index] = event
        this.priceLevelsData[index]['t_price'] = parseInt(
          this.priceLevelTakeOut[index]
        )
      }
    },
    updateName(e) {
      this.name = e.target.value
    },
    setAll(event) {
      this.outletList.forEach((item) => {
        item.price = event ? this.menuitems.price : ''
        item.take_away_price = event ? this.menuitems.take_out_price : ''
        item.take_away_tax = event ? this.menuitems.take_away_tax : ''
        //  this.menuPrices.push(this.outletList)
      })
      if (event) {
        this.menuPrices = this.outletList
      } else {
        this.menuPrices = []
      }
    },
    setPrice(val) {
      let itemIndex = this.menuPrices.findIndex(
        (item) => item.outlet_id === val
      )
      let priceIndex = this.outletList.findIndex(
        (item) => item.outlet_id === val
      )
      if (itemIndex != -1) {
        this.outletList[priceIndex].price = this.menuitems.price
        this.outletList[priceIndex].take_away_price =
          this.menuitems.take_out_price
        this.outletList[priceIndex].take_away_tax = this.menuitems.take_away_tax
      } else {
        this.outletList[priceIndex].price = ''
        this.outletList[priceIndex].take_away_price = ''
        this.outletList[priceIndex].take_away_tax = ''
      }
    },
    getPriceLevels() {
      customizeService
        .getPriceLevels()
        .then((res) => {
          this.priceLevels = res.data ? res.data : []
          this.priceLevels = this.menuitems.price_levels.map((x) =>
            Object.assign(
              x,
              this.priceLevels.find((y) => y.id == x.id)
            )
          )
          this.tempPricelevels = JSON.parse(JSON.stringify(res.data))
        })
        .catch((err) => console.log(err))
    },
    async alert(value) {
      var self = this
      let isAvailable = true
      if (this.menuitems.bar_code && this.menuitems.bar_code != '') {
        await customizeService
          .checkBarcodeAvailability(this.menuitems.bar_code, this.menuitems.id)
          .then((res) => {
            isAvailable = res.data.success
          })
      }

      if (isAvailable) {
        reusableService.showSwal('Edit').then((result) => {
          if (result.value) {
            if (value === 'menu') {
              self.submitMenuItem()
            } else if (value === 'sub') {
              self.createSupercategory()
            } else if (value === 'main') {
              self.createMaincategory()
            }
          }
        })
      } else {
        Swal.fire(
          'The barcode entered is already in use on another menu item. Please remove it from that item before it can be added to this.'
        )
      }
    },

    submitMenuItem() {
      let flag = this.$route.query.flag
      if (flag == 0) {
        this.saveMenuItem()
      } else {
        this.updateOutletMenu()
      }
    },

    updateOutletMenu() {
      let body = {
        menu_items: {
          price: this.menuitems.price,
          outlet_id: this.menuitems.outlet_id,
          single_combo_id: this.isSingleCombo
            ? this.menuitems.single_combo_id
            : '',
          take_away_price: this.menuitems.take_out_price,
          take_away_tax: this.menuitems.take_away_tax,
          // price_levels: this.priceLevels,
        },
      }

      customizeService
        .updateMenuItemPriceById(this.menuitems.id, body)
        .then((result) => {
          this.$router.push('/products/MenuItem')
        })
    },

    togglePrice(event) {
      if (this.appyHeadOffice) {
        this.menuPrices.map((el) => {
          el.price = this.menuitems.price
          el.take_away_price = this.menuitems.take_out_price
          el.take_away_tax = this.menuitems.take_away_tax
        })
      } else {
        let tempMenu = JSON.parse(JSON.stringify(this.tempOutletMenus))
        this.outletList = tempMenu
        this.menuPrices = tempMenu
      }
    },

    saveMenuItem() {
      for (var i = 0; i < this.confirmModGroups.length; i++) {
        this.modifierGroupList.push({
          modifier_group_id: this.confirmModGroups[i].id,
          name: this.confirmModGroups[i].name,
          sort_order: i + 1,
          chain_id: accountService.getChainId(),
        })
      }
      this.menuitems.modifier_groups = this.modifierGroupList
      this.menuitems.item_group = this.menuitems.main_category_id
      this.menuitems.portions = this.portionList
      this.menuitems.chain_id = accountService.getChainId()
      this.menuitems.outlet_id = accountService.getOutletId()
      this.menuitems.outlet_menus = this.menuPrices
      this.menuitems.menu_type = 'normal'
      this.menuitems.single_combo_id = this.isSingleCombo
        ? this.menuitems.single_combo_id
        : ''
      this.menuitems.price_levels = this.priceLevels
      this.menuitems.is_flipdish = this.flip_dish
      if (this.flip_dish) {
        this.menuitems.flipdish_menu_section_id =
          this.$refs.flipdishform.flipdish.query_param.flipdish_menu_section_id
      }
      //Menu Item List
      this.menuitems.menu_item_menu_list_links_attributes = this.$refs.MenuList
        ? this.$refs.MenuList.confirmMenuList
        : []

      //Menu item submit
      let self = this
      if (this.formType === 'Add Menu Item') {
        customizeService.createMenuItems(this.menuitems).then((res) => {
          if (
            self.flip_dish &&
            store.state.account.profile.user_type == 'user'
          ) {
            self.$refs.flipdishform.flipdish.query_param.menu_item_id =
              res.data.menu_items.id
            self.$refs.flipdishform.flipdish.query_param.menu_modifiers =
              // self.flipdishMenuModifiers
              self.$refs.flipdishform.flipdish.query_param.single_combo_id =
                self.flipdishComboId
            self.$refs.flipdishform.createFlipdishMenu(
              accountService.getOutletId()
            )
          }
          if (res.data.menu_items.is_online) {
            this.$refs.onlineOder.isOnline = this.menuitems.is_online
            this.$refs.onlineOder.submit(
              res.data.menu_items.id,
              accountService.getOutletId()
            )
          } else {
            this.$router.push('/products/MenuItem')
          }
        })
      } else {
        delete this.menuitems.online_modifier_groups
        let body = {
          menu_items: this.menuitems,
        }
        axios
          .put(
            this.endPoint +
              '?chain_id=' +
              accountService.getChainId() +
              '&outlet_id=' +
              accountService.getOutletId(),
            body,
            customizeService.authFun()
          )
          .then((res) => {
            if (this.menuitems.is_flipdish) {
              self.$refs.flipdishform.flipdish.query_param.menu_item_id =
                res.data.menu_items.id
              // self.$refs.flipdishform.flipdish.query_param.menu_modifiers =
              //   self.flipdishMenuModifiers

              self.$refs.flipdishform.flipdish.query_param.single_combo_id =
                self.flipdishComboId
              this.$refs.flipdishform.createFlipdishMenu(
                accountService.getOutletId()
              )
            }
            if (this.menuitems.is_online) {
              this.$refs.onlineOder.isOnline = this.menuitems.is_online
              this.$refs.onlineOder.submit(
                this.menuitems.id,
                accountService.getOutletId()
              )
            } else {
              this.$router.push('/products/MenuItem')
            }
          })
      }
    },

    resetOutlet() {},

    addPortion() {
      this.portionList.push({
        portion_id: '',
        din_in_price: '',
        take_away_price: '',
      })
    },

    removePortion(index) {
      this.portionList.splice(index, 1)
    },

    priList() {
      let self = this
      this.confirmModGroups = []
      for (var i = 0; i < this.ModGroupsIds.length; i++) {
        let body = this.modGroups.find(function (el) {
          return el.modifier_group.id == self.ModGroupsIds[i]
        })
        this.confirmModGroups.push(body)
      }
      this.menuitems.group_sort_order_type = 0
    },

    orderList() {
      this.confirmModGroups = this.confirmModGroups.sort((one, two) => {
        if (one.name < two.name) {
          return -1
        }
        if (one.name > two.name) {
          return 1
        }
        return 0
      })
      this.drag = false
      this.menuitems.group_sort_order_type = 1
    },

    descOrderList() {
      this.confirmModGroups = this.confirmModGroups.sort((one, two) => {
        if (one.name > two.name) {
          return -1
        }
        if (one.name < two.name) {
          return 1
        }
        return 0
      })
      this.drag = false
      this.menuitems.group_sort_order_type = 2
    },

    customOrder() {
      this.drag = true
      this.menuitems.group_sort_order_type = 3
    },

    enableDrag() {
      return this.drag
    },

    addMainCat() {
      customizeService
        .getSupercat()
        .then((res) => {
          this.superCategoryPop = res.data
        })
        .catch((err) => {
          console.log('error', err)
        })
      this.showDialog = true
    },

    createMaincategory() {
      let self = this
      customizeService
        .createMaincat(this.mainCategoryPop)
        .then((res) => {
          this.mainCategoryPop = {}
          customizeService
            .getMaincat()
            .then((res) => {
              this.mainCategory = res.data.api_v1_main_category
              this.getmainCategoryCalled = true
            })
            .catch((err) => {
              console.log('Main Category not found')
            })
          this.showDialog = false
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    createSupercategory() {
      let self = this
      customizeService
        .createSubcat(this.subCategoryPop)
        .then((res) => {
          this.subCategoryPop = {}
          customizeService
            .getSubcat()
            .then((res) => {
              this.subCategory = res.data.api_v1_sub_category
              this.getsubCategoryCalled = true
            })
            .catch((err) => {
              console.log('Main Category not found')
            })
          this.showSubDialog = false
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    reset() {
      this.mainCategoryPop = {}
      this.showDialog = false
      this.colorCodeShow = ''
    },

    resetsub() {
      this.subCategoryPop = {}
      this.showSubDialog = false
    },

    getModifierGroups() {
      let self = this

      customizeService.getModifierGroup().then((res) => {
        this.modGroups = res.data.filter((item) => item.status == 'active')
        this.allModGroups = res.data

        if (this.formType !== 'Add Menu Item') {
          this.getTax()
          Promise.all([
            this.getmainCategory(),
            this.getsubCategory(),
            this.printerGroup(),
            this.printer(),
            // this.getTax(),
          ]).then((result) => {
            this.loader = false
            // axios
            //   .get(
            //     this.url + '?chain_id=' + accountService.getChainId(),
            //     customizeService.authFun()
            //   )
            customizeService
              .getMenuItemById(this.url)
              .then((res) => {
                this.menuitems = res.data.menu_items

                this.outletList = res.data.menu_items.outlet_menus || []
                this.menuPrices = this.outletList

                this.tempOutletMenus = JSON.parse(
                  JSON.stringify(this.outletList)
                )

                this.endPoint = res.data.menu_items.url
                this.flipdishMenuModifiers = JSON.stringify(
                  res.data.menu_items.modifier_groups
                )

                this.flipdishComboId = res.data.menu_items.single_combo_id

                this.query_param.menu_item_id = this.menuitems.id
                this.query_param.flipdish_menu_id =
                  this.menuitems.flipdish_menu_id
                this.query_param.flipdish_menu_section_id =
                  this.menuitems.flipdish_menu_section_id
                this.query_param.flipdish_item_id =
                  this.menuitems.flipdish_item_id
                this.query_param.take_out_price = this.menuitems.take_out_price
                this.query_param.is_flipdish = this.menuitems.is_flipdish
                this.query_param.name = this.menuitems.name
                this.query_param.short_name = this.menuitems.short_name
                this.query_param.price = this.menuitems.price

                this.isSingleCombo = this.menuitems.single_combo_id != null
                this.flip_dish = this.menuitems.is_flipdish

                this.portionList = this.menuitems.portions
                if (this.menuitems.group_sort_order_type == '3') {
                  this.customOrder()
                }
                if (this.portionList.length !== 0) {
                  this.portionCheck = true
                }
                if (this.menuitems.modifier_groups.length != 0) {
                  this.modifierGroup = true
                  for (
                    var i = 0;
                    i < this.menuitems.modifier_groups.length;
                    i++
                  ) {
                    this.ModGroupsIds.push(this.menuitems.modifier_groups[i].id)
                  }
                  self.findModifier()
                }
                if (
                  this.menuitems.menu_item_menu_list_links_attributes.length > 0
                ) {
                  this.showMenuList = true
                }

                this.$refs.flipdishform.setFlipdish()
                this.getPriceLevels()
              })
              .catch((err) => {
                console.log(err)
              })
          })
        } else {
          this.getPriceLevels()

          this.$refs.flipdishform.setFlipdish()
        }
      })
    },

    findModifier() {
      let self = this
      this.confirmModGroups = []
      for (var i = 0; i < this.ModGroupsIds.length; i++) {
        let body = this.allModGroups.find(function (el) {
          return el.id == self.ModGroupsIds[i]
        })
        this.confirmModGroups.push(body)
      }
    },

    printerGroup() {
      return new Promise((resolve, reject) => {
        if (this.printerGroupCalled === false) {
          customizeService
            .getCourse()
            .then((res) => {
              this.printerGroupList = res.data
              this.printerGroupCalled = true
              resolve(true)
            })
            .catch((err) => reject(false))
        }
      })
    },

    printer() {
      return new Promise((resolve, reject) => {
        if (this.printerCalled === false) {
          customizeService
            .getStation()
            .then((res) => {
              this.printerList = res.data.api_v1_station
              this.printerCalled = true
              resolve(true)
            })
            .catch((err) => reject(false))
        }
      })
    },
    getmainCategory() {
      return new Promise((resolve, reject) => {
        if (this.getmainCategoryCalled === false) {
          customizeService
            .getMaincat()
            .then((res) => {
              this.mainCategory = res.data.api_v1_main_category.filter(
                (el) => el.status == true
              )
              this.getmainCategoryCalled = true
              resolve(true)
            })
            .catch((err) => {
              reject(false)
            })
        }
      })
    },

    getsubCategory() {
      return new Promise((resolve, reject) => {
        if (this.getsubCategoryCalled === false) {
          customizeService
            .getSubcat()
            .then((res) => {
              this.subCategory = res.data.api_v1_sub_category
              this.getsubCategoryCalled = true
              resolve(true)
            })
            .catch((err) => {
              reject(false)
            })
        }
      })
    },
    getCombos() {
      if (!this.combos.length > 0) {
        customizeService.getCombo().then((res) => {
          this.combos = res.data.filter((item) => item.status == true)
        })
      }
    },

    getportions() {
      customizeService
        .getPortions()
        .then((res) => {
          this.portion = res.data
          this.getPortionsCalled = true
        })
        .catch((err) => {
          console.log('Main Category not found')
        })
    },

    getTax() {
      if (this.getTaxCalled === false) {
        customizeService
          .getTaxes()
          .then((res) => {
            this.taxList = res.data.result[0].tax.taxes
            this.getTaxCalled = true
          })
          .catch((err) => {})
      }
    },

    editMenuItem() {
      this.formType = 'Edit Menu Item'
    },

    updateSortOrder() {
      if (this.menuitems.group_sort_order_type == 0) {
        this.priList()
      } else if (this.menuitems.group_sort_order_type == 1) {
        this.orderList()
      } else if (this.menuitems.group_sort_order_type == 2) {
        this.descOrderList()
      } else {
        this.customOrder()
      }
    },

    getFlipdishMenu() {
      if (this.flipdishMenusCalled === false) {
        customizeService
          .getFlipdishMenus()
          .then((res) => {
            this.flipdishMenus = res.data.Data
            this.flipdishMenusCalled = true
          })
          .catch((err) => {
            console.log('Main Category not found')
          })
      }
    },
    getFlipdishMenuSection() {
      if (this.flipdishMenuSectionCalled === false) {
        customizeService
          .getFlipdishMenuSection()
          .then((res) => {
            this.flipdishMenuSection = res.data.Data
            this.flipdishMenuSectionCalled = true
          })
          .catch((err) => {
            console.log('Main Category not found')
          })
      }
    },
    setDrsPrice(value) {
      this.depositReturnFee = value
      // if (this.$refs.onlineOder) {
      //   console.log('dddddd 111')
      //   this.$refs.onlineOder.DepositReturnFee = value
      // }
    },
  },

  created() {
    var self = this
    this.url = this.$route.query.client
    this.formType = this.$route.query.formType
    this.menuitems.item_code = this.$route.query.sku
      ? this.$route.query.sku + 1
      : 1
    this.menuitems.group_sort_order_type = 0
    if (this.formType !== 'Add Menu Item') {
      this.loader = true
    }
    this.getModifierGroups()
    this.getCombos()

    customizeService.getOutlets().then((res) => {
      const data = res.data.outlets
      for (var i = 0; i < data.length; i++) {
        this.outletList.push({
          outlet_id: data[i].id,
          outlet_name: data[i].name,
          take_away_price: '',
          take_away_tax: '',
          status: 'active',
        })
      }
    })

    // this.getmainCategory();
    // this.getsubCategory();
    // this.printerGroup();
    // this.printer();
  },

  computed: {
    colorCode() {
      return this.mainCategoryPop.code_color
    },
    menuItemColr() {
      return this.menuitems.item_color
    },
  },
  watch: {
    portionCheck: function () {
      if (this.portionCheck == false) {
        this.portionList = []
      }
      if (this.getPortionsCalled === false) {
        this.getportions()
      }
    },
    Name() {
      this.touched.required = true
    },
    shortName() {
      this.touched.required = true
    },
    sku() {
      this.touched.required = true
    },
    salePrice() {
      this.touched.required = true
    },
    tax() {
      this.touched.required = true
    },
    mainCat() {
      this.touched.required = true
    },
    subName() {
      this.touched.required = true
    },
    subShort() {
      this.touched.required = true
    },
    subDes() {
      this.touched.required = true
    },
    mainName() {
      this.touched.required = true
    },
    mainShort() {
      this.touched.required = true
    },
    mainDes() {
      this.touched.required = true
    },
    colorCode() {
      if (this.mainCategoryPop.code_color != null) {
        this.colorCodeShow = this.mainCategoryPop.code_color.replace('#', '')
      }
    },
    menuItemColr() {
      if (this.menuitems.item_color != null) {
        this.menuCodeShow = this.menuitems.item_color.replace('#', '')
      }
    },
    // menuPrices(val) {
    //   console.log(val)
    //   this.menuPrices.find(el=> el)
    //   this.menuPrices.findIndex((el) => el.outlet_id == subId)
    // },
  },

  filters: {
    removeNullProps: function (el) {
      if (el != null && el != '') {
        return el
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}
.md-icon-font {
  color: #000 !important;
}
.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.package-name {
  margin-top: 8%;
}

.text-center {
  justify-content: center !important;
}
.btn-add-module {
  text-align: center;
}
.arrow_left {
  float: left;
  padding-top: 31px;
}
.md-card .md-card-actions {
  border: none;
}
.file_Label {
  top: -0.8rem;
  font-size: 0.6875rem;
}
.set_menu {
  width: 90%;
  margin: auto;
}
.select_btn {
  margin-right: 10px;
}
i.md-icon.md-icon-font.md-theme-default {
  margin-top: -5px;
}
.close-button i.md-icon.md-icon-font.md-theme-default {
  margin-top: 17px;
}

.md-dialog {
  max-width: 768px;
}
.list-group {
  padding: 17px 0px;
  width: 50%;
}

.list-group-item {
  margin: 5px;
  padding: 8px;
  background-color: #2b82be;
  color: white;
  border-radius: 5px;
}
.md-dialog {
  width: 50%;
}
.card-content-align {
  padding: 0px 18px;
}
.dialog-top {
  padding-top: 15px;
  min-height: 490px;
  .main-actions {
    display: flex;
    justify-content: center;
    margin-top: 62px;
  }
  .main-lab1 {
    margin-top: 31px;
  }
}
.dialog-top-sub {
  padding-top: 15px;
  .main-actions-sub {
    display: flex;
    justify-content: center;
    margin-top: 29px;
    margin-bottom: 14px;
  }
}
.pd-top {
  padding-top: 15px;
}
.mainCat-field {
  padding-right: 10px;
}
.form__label {
  float: left;
}
.custm-color-picker {
  position: absolute;
  right: 41px;
  margin-top: 16px;
  top: 12px;
  font-size: 14px;
}
.color-picker-layout {
  margin-bottom: 13px;
  margin-top: 13px;
  .form__field {
    .form__input {
      width: 150px;
      position: relative;
      .vue-swatches {
        width: 85px !important;
        cursor: pointer;
        .vue-swatches__trigger {
          width: 68px !important;
        }
      }
      .md-icon {
        position: absolute;
        right: 9px;
        z-index: -1;
        top: 0px;
      }
    }
  }
}

.mod-click {
  cursor: pointer;
  padding-left: 0px !important;
}

.list-group {
  padding: 17px 10px;
  width: 100%;
}

.list-group-item {
  margin: 5px;
  padding: 8px;
  background-color: #2b82be;
  color: white;
  border-radius: 5px;
}

.plus-icon {
  height: 15px;
  width: 15px;
  margin: 0px 4px 4px 0px;
}

.modifier-add {
  display: flex;
}

.modifier-checkbox {
  padding: 0px;
  .md-list-item-content > .md-checkbox:first-child,
  .md-list-item-content > .md-radio:first-child {
    margin-right: 0px;
  }

  .md-list-item .md-list-item-container .md-ripple {
    display: flex;
  }
  overflow-y: scroll;
  height: 300px;
}

.btn-div {
  text-align: center;
}

.mod-click {
  cursor: pointer;
}

.modifier-dialog {
  padding: 15px;
  width: 40%;
  padding-right: none;
}

.close-icon {
  width: 50%;
  text-align: right;
  i {
    cursor: pointer;
  }
}

.portionData {
  width: 94%;
  display: table;
  margin: 10px auto;
  padding-top: 11px;
  padding-bottom: 23px;
  background-color: #eee;
  border-radius: 9px;
}

.modifierGpData {
  width: 94%;
  display: table;
  margin: 10px auto;
  padding-top: 11px;
  padding-bottom: 23px;
  background-color: #eee;
  border-radius: 9px;
}

.add-pop {
  margin-top: 8px !important;
}
.advanced {
  box-shadow: none;
}
.advanced-btn {
  background-color: white !important;
  color: black !important;
  box-shadow: none;
}
.advanced-btn .fa {
  color: black !important;
}

.md-dialog-container {
  left: unset !important;
}
</style>

<style>
.price-outlets.outlet-menu-prices .md-dialog-container.md-dialog-fullscreen {
  max-width: 100% !important;
}
.price-outlets.outlet-menu-prices
  .md-checkbox.md-checked.md-disabled
  .md-checkbox-container:after {
  border-color: rgba(0, 0, 0, 0.3) !important;
}
.price-outlets.outlet-menu-prices
  .md-checkbox.md-theme-default.md-disabled.md-checked
  .md-checkbox-container {
  border-color: rgba(0, 0, 0, 0.3) !important;
}
.price-outlets.outlet-menu-prices
  .md-dialog-container
  .md-checkbox
  .md-checkbox-label {
  color: #3c4858 !important;
}
.menulistbg {
  width: 100%;
  display: table;
  margin: 10px auto;
  padding-top: 11px;
  padding-bottom: 23px;
  border-radius: 9px;
}
.vue-list-picker .list-picker-title[data-v-27b7fd04] {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif !important;
}
</style>
