<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header
          class="md-card-header-text md-card-header-green md-layout md-gutter"
        >
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <router-link :to="{ path: '/distributors/AddBusinessVertical' }">
                <img src="../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h3>Outlet Options</h3>
          </div>
          <div
            class="md-layout-item md-size-30"
            v-if="formType == 'View Outlet Options'"
          >
            <i class="md-icon md-icon-font md-theme-default edit-img">edit</i>
          </div>
        </md-card-header>

        <!-- <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div> -->

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 outlet-datalist">
              <input list="brow" placeholder="Select Outlet" />
              <datalist id="brow">
                <option v-for="item in outlets" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </datalist>
            </div>

            <div class="md-layout-item md-size-100">
              <md-checkbox>{{ $t('Is Voucher ?') }}</md-checkbox>
            </div>

            <div class="md-layout-item md-size-100">
              <md-checkbox>{{ $t('Is Inventory ?') }}</md-checkbox>
            </div>

            <div class="md-layout-item md-size-100">
              <md-checkbox>{{ $t('Is Music ?') }}</md-checkbox>
            </div>

            <div class="md-layout-item md-size-50">
              <single-date-picker
                @emitDate="setEnableDate"
              ></single-date-picker>
            </div>

            <div class="md-layout-item md-size-50">
              <single-date-picker
                @emitDate="setDisabledDate"
              ></single-date-picker>
            </div>

            <div class="md-layout-item md-size-100">
              <md-checkbox>{{ $t('Is Flipdish ?') }}</md-checkbox>
            </div>

            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Token</label>
                <md-input
                  type="text"
                  v-model="token"
                  data-vv-name="Token"
                  v-validate="modelValidations.Token"
                  v-on:blur="getFlipdishApps()"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Token')">
                    {{ errors.first('Token') }}
                  </div>
                </slide-y-down-transition>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Apps</label>
                <md-select v-model="selectedApp">
                  <md-option
                    v-for="item in apps"
                    :key="item.AppId"
                    :value="item.AppId"
                  >
                    {{ item.Name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="btn-div">
              <md-card-actions class="text-center">
                <md-button
                  native-type="submit"
                  :to="{ path: '/Distributors/BusinessVertical' }"
                  >{{ $t('CANCEL') }}</md-button
                >
                <md-button
                  native-type="submit"
                  @click.native.prevent="validate"
                  class="md-primary"
                  >{{ $t('UPDATE') }}</md-button
                >
              </md-card-actions>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SingleDatePicker from '../../../components/SingleDatePicker.vue'
import { customizeService } from './../../../services/customize.js'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import { SlideYDownTransition } from 'vue2-transitions'
import { accountService } from '../../../services/account'
export default {
  components: {
    SingleDatePicker,
    SlideYDownTransition,
  },
  data() {
    return {
      formType: '',
      outletId: '',
      enabledDate: null,
      disabledDate: null,
      token: '',
      selectedApp: 1,
      apps: [],
      modelValidations: {
        Token: {
          required: true,
        },
      },
      outlets: [],
      selectedOutlet: '',
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            this.submitOutletOptions()
          }
        })
      }
    },

    setEnableDate(date) {
      this.enabledDate = date
    },

    setDisabledDate(date) {
      this.disabledDate = date
    },

    getFlipdishApps() {
      customizeService
        .getFlipdishApps(this.outletId)
        .then((res) => {
          this.apps = res.data.Data
        })
        .catch((err) => console.log(err))
    },

    submitOutletOptions() {},
  },

  created() {
    this.outletId = this.$route.params.id
    this.getFlipdishApps()

    customizeService
      .getOutletByDistributorId(accountService.getDistributorId())
      .then((res) => {
        this.outlets = res.data.outlets
      })
  },
}
</script>

<style lang="scss" scoped>
.arrow_left {
  float: left;
}

.outlet-datalist {
  width: 100%;
  height: 25px;
  border-bottom: solid 1px #eeee !important;
  border: none;
  font-family: Roboto, Noto Sans, -apple-system, BlinkMacSystemFont, sans-serif;
}
</style>
