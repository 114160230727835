<template>
  <div class="sidebar" :data-background-color="'black'">
    <div class="logo">
      <!-- Outlet Logo (Optional) -->
      <!-- <a   
        href="https://www.creative-tim.com"
        class="simple-text logo-mini"
        target="_blank"
      >
        <div class="logo-img">
          <img :src="logo" />
        </div>
      </a>-->

      <div class="maintext">
        <a
          class="simple-text-entity logo-normal font-weight-bold h6"
          target="_blank"
        >
          <span class="fav-image">
            <img
              width="35"
              class="mr-2"
              src="../../assets/images/favicon.png"
            />
          </span>
          <template>{{ $t(entityName) }}</template>
        </a>
      </div>

      <div class="navbar-minimize">
        <md-button
          id="minimizeSidebar"
          class="md-round md-just-icon md-transparent"
          @click="minimizeSidebar"
        >
          <i class="material-icons text_align-center visible-on-sidebar-regular"
            >more_vert</i
          >
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
            >view_list</i
          >
        </md-button>
      </div>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea" id="sidebarwrapper">
      <slot></slot>
      <!-- <div class="sidebar-scroll"> -->
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            ></sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
      <!-- </div> -->
      <!-- <sidebar-footer></sidebar-footer> -->

      <!-- <div @click="contactUs()">Contact Us</div> -->
    </div>
    <!-- <img
      src="../../assets/images/contact.svg"
      @click="contactUs()"
      class="contact-us"
    /> -->
    <div class="logo-img mb-1 mt-10 mx-auto">
      <img src="../../assets/images/cbe_innova_logo.png" />
    </div>
    <span class="font-11 font-weight-bold copy-right-text">
      Copyright © 2022 CBE Innova 2.0.0. <br />All rights Reserved
    </span>
    <md-dialog class="contact-info" :md-active.sync="showContactUs">
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          <md-field>
            <label>{{ $t('Name') }}</label>
            <md-input></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-50">
          <md-field>
            <label>{{ $t('Name') }}</label>
            <md-input></md-input>
          </md-field>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          <md-field>
            <label>{{ $t('Phone') }}</label>
            <md-input></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-50">
          <md-field>
            <label>{{ $t('Email') }}</label>
            <md-input></md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-size-100">
          <md-field>
            <label>{{ $t('Message ') }}</label>
            <md-textarea type="text"></md-textarea>
          </md-field>
        </div>

        <md-button
          native-type="submit"
          class="md-primary"
          @click="sendMessage()"
          >{{ $t('SEND') }}</md-button
        >
      </div>
    </md-dialog>
  </div>
</template>
<script>
//import SideBarFooter from './SideBarFooter'

export default {
  data() {
    return {
      mainTitle: this.$store.getters.getProfile.profile,
      showContactUs: false,
    }
  },
  created() {
    this.minimizeSidebar()
    // this.mainTitle =  this.$store.getters.getProfile.profile
  },
  components: {
    // SideBarFooter
  },
  name: 'sidebar',
  props: {
    activeColor: {
      type: String,
      default: 'green',
      validator: (value) => {
        let acceptedValues = [
          '',
          'purple',
          'azure',
          'green',
          'orange',
          'danger',
          'rose',
        ]
        return acceptedValues.indexOf(value) !== -1
      },
    },
    backgroundImage: {
      type: String,
      default: './img/sidebar-2.jpg',
    },
    backgroundColor: {
      type: String,
      default: 'white',
      validator: (value) => {
        let acceptedValues = ['', 'black', 'white', 'red']
        return acceptedValues.indexOf(value) !== -1
      },
    },
    logo: {
      type: String,
      default: './img/vue-logo.png',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  methods: {
    sendMessage() {},
    contactUs() {
      this.showContactUs = true
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      }
    },

    entityName() {
      return this.$store.getters.getProfile.profile
    },
  },
  beforeDestroy() {
    this.minimizeSidebar()
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
}
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.sidebar-wrapper {
  padding-right: 15px;
}

#sidebarwrapper::-webkit-scrollbar {
  display: none;
}

/* Only Chrome */
#sidebarwrapper {
  scrollbar-width: none;
}

.simple-text-entity {
  text-align: center;
  color: #ffff !important;
  font-size: 13px;
  padding: 8px;
}

.simple-text:hover {
  color: #60616c !important;
}

.maintext {
  text-align: center;
  width: 90%;
  color: #ffff !important;
}
/* @media screen and (max-height: 600px) {

.sidebar .sidebar-wrapper{
  height: 60%;
}

} */

/* .sidebar-scroll{
  overflow: hidden;
} */
</style>
