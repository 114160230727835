<template>
  <div class="md-layout business-hours-table-row" :class="bgColor">
    <!-- Dine In -->
    <div
      is="transition-group"
      name="fade"
      class="adjust-width dinein-adjust-width"
      v-if="storeSettings['dinein'].allow_dinein"
    >
      <div
        v-for="({ open, close }, index) in activeHours['dinein']"
        :key="`dinein${index}`"
      >
        <div class="flex-table row" role="rowgroup">
          <div class="flex-row day" role="cell">
            <div v-if="showDay(index)">{{ localization.days[day] }}</div>
          </div>
          <div class="flex-row toggle" role="cell">
            <ToggleButton
              v-if="showDay(index)"
              @change="
                toggleOpen('dinein')
                resetHours('dinein')
                runValidations('dinein')
              "
              :value="anyOpen('dinein')"
              :sync="true"
              :labels="{
                checked: localization.switchOpen,
                unchecked: localization.switchClosed,
              }"
              :color="color"
              :width="switchWidth"
              :height="25"
              :font-size="12"
              :disabled="!dineinStatus"
            />
          </div>
          <!-- {{ validations[index]['dinein'] }} -->

          <transition name="fade">
            <div
              class="flex-row hours open"
              role="cell"
              v-visible="isOpenToday('dinein')"
            >
              <BusinessHoursSelect
                v-if="type === 'select'"
                :name="name"
                :input-num="inputNum('open', index)"
                :total-inputs="totalInputs('dinein')"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :selected-time="open"
                :localization="localization"
                :hour-format24="hourFormat24"
                deliveryType="dinein"
                @input-change="
                  onChangeEventHandler('open', index, $event, 'dinein')
                "
              ></BusinessHoursSelect>
              <BusinessHoursDatalist
                v-if="type === 'datalist'"
                :name="name"
                :input-num="inputNum('open', index)"
                :total-inputs="totalInputs('dinein')"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :selected-time="open"
                :any-error="anyError(validations[index]['dinein'].open)"
                :localization="localization"
                :hour-format24="hourFormat24"
                deliveryType="dinein"
                @input-change="
                  onChangeEventHandler('open', index, $event, 'dinein')
                "
              ></BusinessHoursDatalist>
            </div>
          </transition>
          <transition name="fade">
            <div
              class="flex-row dash"
              role="cell"
              v-visible="isOpenToday('dinein')"
            >
              -
            </div>
          </transition>
          <transition name="fade">
            <div
              class="flex-row hours close"
              role="cell"
              v-visible="isOpenToday('dinein')"
            >
              <BusinessHoursSelect
                v-if="type === 'select'"
                :name="name"
                :input-num="inputNum('close', index)"
                :total-inputs="totalInputs('dinein')"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :selected-time="close"
                :localization="localization"
                :hour-format24="hourFormat24"
                deliveryType="dinein"
                @input-change="
                  onChangeEventHandler('close', index, $event, 'dinein')
                "
              ></BusinessHoursSelect>

              <BusinessHoursDatalist
                v-if="type === 'datalist'"
                :name="name"
                :input-num="inputNum('close', index)"
                :total-inputs="totalInputs('dinein')"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :any-error="anyError(validations[index]['dinein'].close)"
                :updated-validations="validations[index]['dinein'].close"
                :selected-time="close"
                :hour-format24="hourFormat24"
                :localization="localization"
                deliveryType="dinein"
                @input-change="
                  onChangeEventHandler('close', index, $event, 'dinein')
                "
              ></BusinessHoursDatalist>
            </div>
          </transition>

          <div
            class="flex-row remove"
            role="cell"
            v-visible="isOpenToday('dinein')"
          >
            <button
              type="button"
              class="font-awesome-button"
              v-if="showRemoveButton('dinein')"
              @click="removeRow(index, 'dinein')"
            >
              <FontAwesomeIcon icon="times" class="fa-sm" />
            </button>
          </div>
          <div
            class="flex-row add"
            role="cell"
            v-visible="isOpenToday('dinein')"
          >
            <button
              type="button"
              :style="{ color: color }"
              class="add-hours"
              v-if="showAddButton(index, 'dinein')"
              @click="addRow('dinein')"
            >
              {{ localization.addHours }}
            </button>
          </div>
        </div>
        <ul class="time-errors" v-if="validations[index].anyErrors">
          <li
            v-for="{ whichTime, error } in activeErrors(index)"
            :key="whichTime + '.' + error"
          >
            {{ errorMessage(whichTime, error) }}
          </li>
        </ul>
      </div>
    </div>
    <!-- Dine In END -->

    <!-- Collection -->
    <div
      is="transition-group"
      name="fade"
      class="adjust-width collection-adjust-width"
      v-if="storeSettings['collection'].allow_collection"
    >
      <div
        v-for="({ open, close }, index) in activeHours['collection']"
        :key="`collection${index}`"
      >
        <div class="flex-table row" role="rowgroup">
          <div class="flex-row day" role="cell">
            <div v-if="!storeSettings['dinein'].allow_dinein && showDay(index)">
              {{ localization.days[day] }}
            </div>
          </div>
          <div class="flex-row toggle" role="cell">
            <ToggleButton
              v-if="showDay(index)"
              @change="
                toggleOpen()
                resetHours()
                runValidations()
              "
              :value="anyOpen()"
              :sync="true"
              :labels="{
                checked: localization.switchOpen,
                unchecked: localization.switchClosed,
              }"
              :color="color"
              :width="switchWidth"
              :height="25"
              :font-size="12"
              :disabled="!collectionStatus"
            />
          </div>

          <transition name="fade">
            <div
              class="flex-row hours open"
              role="cell"
              v-visible="isOpenToday()"
            >
              <BusinessHoursSelect
                v-if="type === 'select'"
                :name="name"
                :input-num="inputNum('open', index)"
                :total-inputs="totalInputs()"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :selected-time="open"
                :localization="localization"
                :hour-format24="hourFormat24"
                deliveryType="collection"
                @input-change="onChangeEventHandler('open', index, $event)"
              ></BusinessHoursSelect>
              <BusinessHoursDatalist
                v-if="type === 'datalist'"
                :name="name"
                :input-num="inputNum('open', index)"
                :total-inputs="totalInputs()"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :selected-time="open"
                :any-error="anyError(validations[index]['collection'].open)"
                :localization="localization"
                :hour-format24="hourFormat24"
                deliveryType="collection"
                @input-change="onChangeEventHandler('open', index, $event)"
              ></BusinessHoursDatalist>
            </div>
          </transition>
          <transition name="fade">
            <div class="flex-row dash" role="cell" v-visible="isOpenToday()">
              -
            </div>
          </transition>
          <transition name="fade">
            <div
              class="flex-row hours close"
              role="cell"
              v-visible="isOpenToday()"
            >
              <BusinessHoursSelect
                v-if="type === 'select'"
                :name="name"
                :input-num="inputNum('close', index)"
                :total-inputs="totalInputs()"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :selected-time="close"
                :localization="localization"
                :hour-format24="hourFormat24"
                deliveryType="collection"
                @input-change="onChangeEventHandler('close', index, $event)"
              ></BusinessHoursSelect>

              <BusinessHoursDatalist
                v-if="type === 'datalist'"
                :name="name"
                :input-num="inputNum('close', index)"
                :total-inputs="totalInputs()"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :any-error="anyError(validations[index]['collection'].close)"
                :updated-validations="validations[index]['collection'].close"
                :selected-time="close"
                :hour-format24="hourFormat24"
                :localization="localization"
                deliveryType="collection"
                @input-change="onChangeEventHandler('close', index, $event)"
              ></BusinessHoursDatalist>
            </div>
          </transition>

          <div class="flex-row remove" role="cell" v-visible="isOpenToday()">
            <button
              type="button"
              class="font-awesome-button"
              v-if="showRemoveButton()"
              @click="removeRow(index)"
            >
              <FontAwesomeIcon icon="times" class="fa-sm" />
            </button>
          </div>
          <div class="flex-row add" role="cell" v-visible="isOpenToday()">
            <button
              type="button"
              :style="{ color: color }"
              class="add-hours"
              v-if="showAddButton(index)"
              @click="addRow()"
            >
              {{ localization.addHours }}
            </button>
          </div>
        </div>
        <ul class="time-errors" v-if="validations[index].anyErrors">
          <li
            v-for="{ whichTime, error } in activeErrors(index)"
            :key="whichTime + '.' + error"
          >
            {{ errorMessage(whichTime, error) }}
          </li>
        </ul>
      </div>
    </div>
    <!-- Collection END -->

    <!-- DELIVERY -->
    <div
      is="transition-group"
      name="fade"
      class="adjust-width dinein-adjust-width"
      v-if="
        storeSettings['delivery'] && storeSettings['delivery'].allow_delivery
      "
    >
      <div
        v-for="({ open, close }, index) in activeHours['delivery']"
        :key="`delivery${index}`"
      >
        <div class="flex-table row" role="rowgroup">
          <div class="flex-row day" role="cell">
            <div
              v-if="
                !storeSettings['collection'].allow_collection && showDay(index)
              "
            >
              {{ localization.days[day] }}
            </div>
          </div>
          <div class="flex-row toggle" role="cell">
            <ToggleButton
              v-if="showDay(index)"
              @change="
                toggleOpen('delivery')
                resetHours('delivery')
                runValidations('delivery')
              "
              :value="anyOpen('delivery')"
              :sync="true"
              :labels="{
                checked: localization.switchOpen,
                unchecked: localization.switchClosed,
              }"
              :color="color"
              :width="switchWidth"
              :height="25"
              :font-size="12"
              :disabled="!deliveryStatus"
            />
          </div>
          <!-- {{ validations[index]['dinein'] }} -->

          <transition name="fade">
            <div
              class="flex-row hours open"
              role="cell"
              v-visible="isOpenToday('delivery')"
            >
              <BusinessHoursSelect
                v-if="type === 'select'"
                :name="name"
                :input-num="inputNum('open', index)"
                :total-inputs="totalInputs('delivery')"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :selected-time="open"
                :localization="localization"
                :hour-format24="hourFormat24"
                deliveryType="delivery"
                @input-change="
                  onChangeEventHandler('open', index, $event, 'delivery')
                "
              ></BusinessHoursSelect>
              <BusinessHoursDatalist
                v-if="type === 'datalist'"
                :name="name"
                :input-num="inputNum('open', index)"
                :total-inputs="totalInputs('delivery')"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :selected-time="open"
                :any-error="anyError(validations[index]['delivery'].open)"
                :localization="localization"
                :hour-format24="hourFormat24"
                deliveryType="delivery"
                @input-change="
                  onChangeEventHandler('open', index, $event, 'delivery')
                "
              ></BusinessHoursDatalist>
            </div>
          </transition>
          <transition name="fade">
            <div
              class="flex-row dash"
              role="cell"
              v-visible="isOpenToday('delivery')"
            >
              -
            </div>
          </transition>
          <transition name="fade">
            <div
              class="flex-row hours close"
              role="cell"
              v-visible="isOpenToday('delivery')"
            >
              <BusinessHoursSelect
                v-if="type === 'select'"
                :name="name"
                :input-num="inputNum('close', index)"
                :total-inputs="totalInputs('delivery')"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :selected-time="close"
                :localization="localization"
                :hour-format24="hourFormat24"
                deliveryType="delivery"
                @input-change="
                  onChangeEventHandler('close', index, $event, 'delivery')
                "
              ></BusinessHoursSelect>

              <BusinessHoursDatalist
                v-if="type === 'datalist'"
                :name="name"
                :input-num="inputNum('close', index)"
                :total-inputs="totalInputs('delivery')"
                :day="day"
                :hours="hours"
                :time-increment="timeIncrement"
                :index="index"
                :any-error="anyError(validations[index]['delivery'].close)"
                :updated-validations="validations[index]['delivery'].close"
                :selected-time="close"
                :hour-format24="hourFormat24"
                :localization="localization"
                deliveryType="delivery"
                @input-change="
                  onChangeEventHandler('close', index, $event, 'delivery')
                "
              ></BusinessHoursDatalist>
            </div>
          </transition>

          <div
            class="flex-row remove"
            role="cell"
            v-visible="isOpenToday('delivery')"
          >
            <button
              type="button"
              class="font-awesome-button"
              v-if="showRemoveButton('delivery')"
              @click="removeRow(index, 'delivery')"
            >
              <FontAwesomeIcon icon="times" class="fa-sm" />
            </button>
          </div>
          <div
            class="flex-row add"
            role="cell"
            v-visible="isOpenToday('delivery')"
          >
            <button
              type="button"
              :style="{ color: color }"
              class="add-hours"
              v-if="showAddButton(index, 'delivery')"
              @click="addRow('delivery')"
            >
              {{ localization.addHours }}
            </button>
          </div>
        </div>
        <ul class="time-errors" v-if="validations[index].anyErrors">
          <li
            v-for="{ whichTime, error } in activeErrors(index)"
            :key="whichTime + '.' + error"
          >
            {{ errorMessage(whichTime, error) }}
          </li>
        </ul>
      </div>
    </div>
    <!-- DELIVERY END -->
  </div>
</template>

<script>
import BusinessHoursSelect from './BusinessHoursSelect.vue'
import BusinessHoursDatalist from './BusinessHoursDatalist.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { helperMixin } from '../../mixins/helperMixin'
import { validationMixin } from '../../mixins/validationMixin'
import uniqid from 'uniqid'
export default {
  name: 'BusinessHoursDay',
  components: {
    BusinessHoursSelect,
    BusinessHoursDatalist,
    ToggleButton,
    FontAwesomeIcon,
  },
  mixins: [helperMixin, validationMixin],
  props: {
    day: {
      type: String,
      required: true,
    },
    hours: {
      type: Object,
      required: true,
    },

    deliveryHours: {
      type: Array,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    timeIncrement: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    localization: {
      type: Object,
    },
    switchWidth: {
      type: Number,
    },
    hourFormat24: {
      type: Boolean,
    },
    bgColor: {
      type: String,
    },
    collectionStatus: {
      type: Boolean,
    },
    deliveryStatus: {
      type: Boolean,
    },
    dineinStatus: {
      type: Boolean,
    },
    settings: {
      type: Object,
    },
  },
  computed: {
    // totalInputs: function () {
    //   return this.hours['dinein'].length * 2
    // },
    // isOpenToday: function () {
    //   return this.hours['dinein'][0].isOpen
    // },
    // anyOpen: function () {
    //   return this.hours['dinein'].some((hour) => {
    //     return hour.isOpen === true
    //   })
    // },
  },
  directives: {
    visible: function (el, binding) {
      el.style.visibility = binding.value ? 'visible' : 'hidden'
    },
  },
  watch: {
    hours: {
      deep: true,
      immediate: true,
      handler(val) {
        this.activeHours = val
      },
    },
  },
  data() {
    return {
      activeHours: { collection: [], devlivery: [], dinein: [] },
      storeSettings: this.settings,
    }
  },
  methods: {
    totalInputs: function (deliveryType = 'collection') {
      return this.hours[deliveryType].length * 2
    },
    isOpenToday: function (deliveryType = 'collection') {
      return this.hours[deliveryType][0].isOpen
    },
    anyOpen: function (deliveryType = 'collection') {
      return this.hours[deliveryType].some((hour) => {
        return hour.isOpen === true
      })
    },
    onChangeEventHandler: function (
      whichTime,
      index,
      value,
      deliveryType = 'collection'
    ) {
      value = this.backendInputFormat(value)

      if (value == '24hrs') {
        this.hours[deliveryType].splice(1)
        this.hours[deliveryType][0].open = this.hours[deliveryType][0].close =
          value
        this.runValidations(deliveryType)
        this.updateHours(deliveryType)
        return
      }

      if (
        (this.hours[deliveryType][index].open == '24hrs' ||
          this.hours[deliveryType][index].close == '24hrs') &&
        value == ''
      ) {
        this.hours[deliveryType][index].open = this.hours[deliveryType][
          index
        ].close = value
        this.runValidations(deliveryType)
        this.updateHours(deliveryType)
        return
      }

      if (
        !this.onlyOneRow(this.hours[deliveryType]) &&
        value === '' &&
        ((whichTime === 'open' &&
          this.hours[deliveryType][index].close === '') ||
          (whichTime === 'close' &&
            this.hours[deliveryType][index].open === ''))
      ) {
        this.removeRow(index, deliveryType)
        this.runValidations(deliveryType)
        this.updateHours(deliveryType)
        return
      }

      this.hours[deliveryType][index][whichTime] = value
      this.runValidations(deliveryType)
      this.updateHours(deliveryType)
    },
    inputNum: function (whichTime, index) {
      if (whichTime === 'open') {
        return index * 2 + 1
      } else if (whichTime === 'close') {
        return index * 2 + 2
      }
    },
    toggleOpen: function (deliveryType = 'collection') {
      this.hours[deliveryType][0].isOpen = this.hours[deliveryType][0].isOpen
        ? false
        : true
    },
    resetHours: function (deliveryType = 'collection') {
      for (var i in this.hours[deliveryType]) {
        this.hours[deliveryType][i].isOpen = this.hours[deliveryType][0].isOpen
      }

      this.updateHours(deliveryType)
    },
    addRow: function (deliveryType = 'collection') {
      this.hours[deliveryType].push({
        id: uniqid(),
        open: '',
        close: '',
        isOpen: true,
      })
      this.runValidations(deliveryType)
      this.updateHours(deliveryType)
    },
    removeRow: function (index, deliveryType = 'collection') {
      this.hours[deliveryType].splice(index, 1)
      this.runValidations(deliveryType)
      this.updateHours(deliveryType)
    },
    showDay: function (index) {
      return index > 0 ? false : true
    },
    showRemoveButton: function (deliveryType = 'collection') {
      return this.hours[deliveryType].length > 1 ? true : false
    },
    showAddButton: function (index, deliveryType = 'collection') {
      return this.hours[deliveryType].length === index + 1 &&
        this.hours[deliveryType][index].open !== '' &&
        this.hours[deliveryType][index].close !== '' &&
        this.hours[deliveryType][index].open !== '24hrs' &&
        this.hours[deliveryType][index].close !== '24hrs' &&
        !(
          this.type === 'select' &&
          this.timeIncrement === 15 &&
          this.hours[deliveryType][index].close === '2345'
        ) &&
        !(
          this.type === 'select' &&
          this.timeIncrement === 30 &&
          this.hours[deliveryType][index].close === '2330'
        ) &&
        !(
          this.type === 'select' &&
          this.timeIncrement === 60 &&
          this.hours[deliveryType][index].close === '2300'
        ) &&
        this.hours[deliveryType][index].close !== '2400' &&
        this.validations[index][deliveryType].anyErrors === false
        ? true
        : false
    },
    updateHours: function (deliveryType) {
      const updatedHours = { [this.day]: this.hours[deliveryType] }
      this.$emit('hours-change', updatedHours)
    },
  },
  created() {
    // debugger
  },
}
</script>

<style lang="scss" scoped>
.flex-table {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0.75em 0;
  height: 45px;
  width: 100%;
}

// .flex-row {
//   width: 20%;
// }

.flex-row.add {
  width: 95px;
}

.dinein-adjust-width,
.dinein-adjust-width {
  width: 30%;
}
.collection-adjust-width {
  width: 40%;
}
.flex-row /deep/ input,
.flex-row /deep/ select {
  margin: 1px;
  padding: 3px 5px;
  width: 90px;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
  vertical-align: middle;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
}

.flex-row.day {
  width: 130px;
}

.flex-row.hours {
  width: 90px;
}

.flex-row.toggle {
  width: 90px;
}

.flex-row.dash {
  margin: 0 7px;
  width: 4px;
}

.row-container {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.remove {
  display: flex;
  justify-content: center;
  width: 50px;
}

label.vue-js-switch {
  margin-bottom: 0;
}

button.add-hours,
button.font-awesome-button {
  height: 30px;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

button.add-hours:focus,
button.font-awesome-button:focus {
  outline: none;
}

button.font-awesome-button {
  width: 30px;
  font-size: 24px;
}

button.add-hours {
  font-size: 14px;
  font-weight: bold;
}

.fa-times {
  color: #3d4852;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.time-errors {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #e3342f;
  list-style: none;
}

.time-errors li {
  margin-bottom: 6px;
}

.even-bg {
  background-color: #e4e4e4;
}
.odd-bg {
  background-color: #cacaca;
}
</style>
