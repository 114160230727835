<template>
  <md-card class="block-bg">
    <md-card-header
      class="md-card-header-text md-card-header-green md-layout md-gutter"
    >
      <div class="md-layout-item md-size-70">
        <h3>Accounts</h3>
      </div>
      <div class="md-layout-item md-size-30"></div>
    </md-card-header>
    <div>
      <a class="button secondary download" :href="stripeCustomer.url">
        Customer Portal
      </a>
    </div>
  </md-card>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import store from '@/store'
import { accountService } from '../../../services/account.js'
import { stripeService } from '../../../services/stripe.js'

export default {
  components: {
    SlideYDownTransition,
  },
  data() {
    return {
      email: '',
      stripe_account_no: null,
      loading: false,
      stripeInvoices: [],
      stripeCharges: [],
      stripeCustomer: {},
    }
  },
  methods: {
    listInvoices() {
      stripeService.getStripeInvoices().then((res) => {
        this.stripeInvoices = res.data.invoices.data
        this.stripeCharges = res.data.charges.data
        this.stripeCustomer = res.data.customer
      })
    },
  },

  created() {
    this.listInvoices()
  },
}
</script>

<style scoped>
.error {
  color: red;
}
</style>
