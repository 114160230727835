<template>
  <div class="md-layout-item pd-l">
    <div class="loader-container" v-if="loader">
      <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
    </div>
    <div
      class="new-main-popup ht-100 block-bg pad-xy rounded-xl"
      v-if="!loader"
    >
      <h4 class="text-center mb-0">Assign To {{ this.outlet.name }}</h4>

      <div class="md-layout">
        <div class="md-layout-item md-size-80 mt-2 pt-15" id="modal-picker">
          <vue-list-picker
            :left-items="leftItems"
            :right-items="rightOutletItems"
            :titleRight="titleRight"
            moved-item-location="bottom"
            class="text-dark"
            ref="modalListPicker"
          />
        </div>

        <div class="md-layout-item md-size-20 p-0">
          <div class="mt-15 list-picker-container">
            <div class="p-1 deflt-title">Is Default?</div>
            <div class="supp-price-holder">
              <div
                v-for="item in rightOutletItems"
                :key="item.id"
                class="supp-price list-picker-item"
              >
                <md-field>
                  <md-radio
                    class="s-default"
                    v-model="selectedItemId"
                    type="text"
                    :value="item.key"
                  ></md-radio>
                </md-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <md-button
        :disabled="!$store.state.account.profile.has_access"
        native-type="submit"
        class="md-primary submitMenuList"
        @click="submitScreen()"
        >{{ $t('SAVE') }}</md-button
      >
    </div>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '@/services/customize.js'
import { accountService } from '@/services/account.js'
import { reusableService } from '@/services/reusable.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Sortable from 'sortablejs'
import Swatches from 'vue-swatches'
import Swal from 'sweetalert2'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
    Swatches,
  },
  data() {
    return {
      touched: {},

      modelValidations: {
        required: {
          required: true,
        },

        menuItemListName: {
          required: true,
        },

        Description: {
          required: false,
        },
      },
      menuItemListName: '',
      height: '100px',
      formType: '',
      menuListId: '',
      leftItems: [],
      rightItems: [],
      rightOutletItems: [],
      titleRight: 'ITEMS SELECTED',
      newarray: [],
      sortType: '',
      outlets: [],
      screens: [],
      mainCatList: [],
      mainCatId: '',
      rightItemsKey: [],
      selectedItemId: null,
      screen: {
        screen_outlet_links_attributes: [],
      },
      outlet: {
        screen_outlet_links_attributes: [],
      },
      loader: true,
      showItemDialog: false,
    }
  },

  methods: {
    alert(value) {
      var self = this
      this.$validator.validateAll().then((isValid) => {
        if (value === 'Add' && isValid) {
          reusableService.showSwal(value).then((result) => {
            if (self.rightItems.length <= 0) {
              Swal.fire({
                title: 'Alert!',
                text: `Please add a menu item!`,
                type: 'warning',
                buttonsStyling: false,
                confirmButtonClass: 'md-button md-info',
              })
              return false
            }
            if (result.value) {
              // self.submitMenuList()
              this.showItemDialog = true
            }
          })
        }
      })
    },
    updateSortOrder() {
      if (this.sortType == '1') {
        this.rightItems = this.rightItems.sort((one, two) => {
          if (one.content < two.content) {
            return -1
          }
          if (one.content > two.content) {
            return 1
          }
          return 0
        })
      } else if (this.sortType == '2') {
        this.rightItems = this.rightItems.sort((one, two) => {
          if (one.content > two.content) {
            return -1
          }
          if (one.content < two.content) {
            return 1
          }
          return 0
        })
      } else {
        this.rightItems = this.rightItems.sort((one, two) => {
          if (one.main_category > two.main_category) {
            return -1
          }
          if (one.main_category < two.main_category) {
            return 1
          }
          return 0
        })
      }
    },
    getOutlets() {
      let self = this
      customizeService
        .getOutletByChainId(accountService.getChainId())
        .then((res) => {
          //   self.outlets = res.data.outlets.filter((el) => el.status == 'active')
          self.outlets = res.data.outlets

          this.loadScreenById()

          this.loader = false
        })
    },

    getScreens() {
      let self = this
      customizeService.getScreenMenus().then((res) => {
        self.screens = res.data.screens.filter((el) => el.status == true)
        this.loader = false

        this.loadOutletById()
      })
    },

    getMainCategories() {
      customizeService.getMaincat().then((res) => {
        this.mainCatList = res.data.api_v1_main_category.filter(
          (el) => el.status == true
        )
      })
    },

    loadLeftItems() {
      this.leftItems = []
      this.rightItemsKey = []

      //   let filteredItems = this.outlets.filter(function (el) {
      //     return id == el.item_group
      //   })
      let filteredItems = this.screens
      this.rightOutletItems.forEach((el) => {
        this.rightItemsKey.push(el.key)
      })
      let self = this
      filteredItems.forEach((el) => {
        let body = {}
        body.key = el.id
        body.content = el.name
        if (!this.rightItemsKey.includes(el.id)) {
          self.leftItems.push(body)
        }
      })
    },

    submitScreen() {
      const outId = this.$route.query.screen

      this.outlet.screen_outlet_links_attributes = []

      // this.rightOutletItems.forEach((el, index) => {
      //   this.screen.screen_outlet_links_attributes.push({
      //     outlet_id: el.key,
      //     screen_id: screenId,
      //   })
      // })

      this.rightOutletItems.forEach((el, index) => {
        this.outlet.screen_outlet_links_attributes.push({
          outlet_id: outId,
          screen_id: el.key,
          is_default: el.key == this.selectedItemId,
        })
      })
      const body = {
        outlets: {
          outlet: {
            screen_outlet_links_attributes:
              this.outlet.screen_outlet_links_attributes,
          },
        },
      }

      customizeService.editOutlet(outId, body).then((res) => {
        this.$router.push({
          path: '/business-vertical/Outlet',
        })
      })
    },

    editComboGroup() {
      this.formType = 'Edit Combo Group'
    },
    loadOutletById() {
      this.loader = false
      const outId = this.$route.query.screen

      //   if (this.formType != 'Add Menu List') {
      customizeService.getOutletById(outId).then((res) => {
        this.loader = false
        this.outlet = res.data.outlet
        const defaultItem = this.outlet.screen_outlet_links_attributes.find(
          (el) => el.is_default == true
        )
        this.selectedItemId = defaultItem ? defaultItem.screen_id : null
        this.outlet.screen_outlet_links_attributes.forEach((el) => {
          this.rightOutletItems.push({
            key: el.screen_id,
            content: el.name,
            name: el.name,
          })
        })
        this.loadLeftItems()
      })
    },

    loadScreenById() {
      this.loader = false
      const screenId = this.$route.query.screen

      //   if (this.formType != 'Add Menu List') {
      customizeService.getScreenMenu(screenId).then((res) => {
        this.loader = false
        this.screen = res.data.screens

        this.screen.screen_outlet_links_attributes.forEach((el) => {
          this.rightOutletItems.push({
            key: el.id,
            content: el.name,
            name: el.name,
          })
        })
        this.loadLeftItems()
      })
      //   } else {
      //     this.loader = false
      //   }
    },
  },

  created() {
    // this.getOutlets()
    this.getScreens()

    // this.getMainCategories()
    this.formType = this.$route.query.formType || 'Add Menu List'
    this.menuListId = this.$route.query.client
  },
  updated: function () {},
  watch: {},
}
</script>

<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}

.list-picker {
  padding: 10px 10px;
}
.supp-price .md-field {
  margin: 0px;
  // padding: 10px 10px;
  height: auto;
}
.md-layout-item.md-size-20 {
  padding: 10px 0 10px 15px;
  display: flex;
  flex-direction: column;
}
.p-1 {
  padding: 8px 10px;
}
// .supp-price.list-picker-item {
//   border-bottom: 1px solid #ccc;
// }
.supp-price .md-field {
  padding-bottom: 0;
}
.s-price::after {
  bottom: -2px;
}
.s-price {
  height: 41px;
  padding: 0 10px;
}
.supp-price .md-field::after {
  bottom: 0;
}
.form__label {
  position: absolute;
  left: 20px;
}
#swatches-margin {
  margin-left: 30px !important;
}
.mt10 {
  margin-top: 10px;
}
.color-picker-title {
  color: #0052c0;
  font-size: 1.1em;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  line-height: 25px;
}
.list-picker {
  padding: 0 !important;
}
.list-picker-item {
  border-bottom: 1px solid #ccc;
  height: 41px;
}
/* .swatch-item {
        min-height: 40px;
      } */
.list-picker-container {
  background-color: #f9f9f9;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>
<style>
.swatch-item .vue-swatches__trigger__wrapper .vue-swatches__trigger {
  margin-top: 5px;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50px !important;
}
.swatch-item .vue-swatches__trigger__wrapper .vue-swatches__trigger {
  margin-top: 5px;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50px !important;
}
.swatch-item {
  margin-left: auto !important;
  margin-right: auto !important;
}
.drag-div {
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.ml-auto {
  margin-left: auto;
}
.md-menu-content.md-select-menu {
  z-index: 100000 !important;
}
.submitMenuList {
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 25px;
}
.ht-100 {
  height: 100% !important;
}
.pt-15 {
  padding-top: 15px;
}
#md-field-color label {
  color: #000 !important;
}
#md-field-color .md-menu input {
  -webkit-text-fill-color: #000 !important;
  color: #000 !important;
}
#md-field-color .md-menu svg {
  fill: #000 !important;
}
.supp-price .md-field {
  padding-bottom: 0;
  min-height: 41px;
  border-bottom: 1px solid #ccc;
}
.s-price::after {
  bottom: -2px;
}
.s-price {
  height: 41px;
  padding: 0 10px;
}
.supp-price .md-field::after {
  bottom: 0;
}
.s-default {
  margin: 0 !important;
  padding: 10px 10px;
}
.p-0 {
  padding: 0 !important;
}
.deflt-title {
  min-height: 41px;
}
.supp-price-holder {
  min-height: 452px;
}
</style>
