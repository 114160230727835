<template>
  <div @click="exportExcel">
    <img src="../../src/assets/images/excel.svg" />
    <slot></slot>
  </div>
</template>

<script>
import XLSX from 'xlsx/xlsx'
import store from '../store'

window.$ = window.jQuery = require('jquery')
export default {
  name: 'vue-excel-xlsx',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    filename: {
      type: String,
      default: 'excel',
    },
    sheetname: {
      type: String,
      default: 'SheetName',
    },
    sdate: {
      type: String,
      default: '',
    },
    edate: {
      type: String,
      default: '',
    },
    outletNames: {
      type: String,
      default: '',
    },
    userrole: {
      type: String,
      default: '',
    },
  },
  methods: {
    exportExcel() {
      let vm = this
      let outletName = ''
      if (this.outletNames != '') {
        outletName = this.outletNames
      } else {
        outletName = store.getters.getProfile.profile
      }

      let createXLSLFormatObj = []
      let newXlsHeader = []
      let newXlsFooter = [] // Ensure newXlsFooter is an array

      // Calculate totals and populate data
      let totalAmount = 0
      let totalSellerSplit = 0

      //   let vm = this
      //   if (vm.columns.length === 0) {
      //     console.log('Add columns!')
      //     return
      //   }
      //   if (vm.data.length === 0) {
      //     console.log('Add data!')
      //     return
      //   }
      $.each(vm.columns, function (index, value) {
        if (value.label != undefined) {
          newXlsHeader.push(value.label)
        }
      })

      //   createXLSLFormatObj.push(newXlsHeader)
      $.each(vm.data, function (index, value) {
        let innerRowData = []
        $.each(vm.columns, function (index, val) {
          let cellValue = value[val.field]
          if (val.dataFormat && typeof val.dataFormat === 'function') {
            cellValue = val.dataFormat(cellValue)
          }

          // Convert to number if it's a valid number string
          if (!isNaN(cellValue) && cellValue !== null && cellValue !== '') {
            cellValue = Number(cellValue)
          }

          // Example: Accumulate totals for certain fields
          if (val.field === 'amount') {
            totalAmount += cellValue
          }
          if (val.field === 'sellerSplit') {
            totalSellerSplit += cellValue
          }
          innerRowData.push(cellValue)
        })
        createXLSLFormatObj.push(innerRowData)
      })

      if (this.sheetname === 'CBE PAY Transactions') {
        // Create the footer row
        if (this.userrole === 'user') {
          newXlsFooter = [
            '', // Empty cells for columns that don't require a footer value
            '',
            '',
            '',
            'Total', // Label for the totals column
            totalAmount.toFixed(2), // Total amount
            '',
            totalSellerSplit.toFixed(2), // Total seller split
            '',
            '',
            // Add more cells as needed
          ]
        } else {
          newXlsFooter = [
            '', // Empty cells for columns that don't require a footer value
            '',
            '',
            '',
            '',
            'Total', // Label for the totals column
            totalAmount.toFixed(2), // Total amount
            '',
            totalSellerSplit.toFixed(2), // Total seller split
            '',
            '',
            // Add more cells as needed
          ]
        }

        // Add footer to the data array
        createXLSLFormatObj.push(newXlsFooter)
      }
      if (this.sheetname === 'CBE Payout') {
        // Create the footer row
        if (this.userrole === 'user') {
          newXlsFooter = [
            '', // Empty cells for columns that don't require a footer value
            '',
            'Total', // Label for the totals column
            totalAmount.toFixed(2), // Total amount
            '',
            '',
            '',
            // Add more cells as needed
          ]
        } else {
          newXlsFooter = [
            '', // Empty cells for columns that don't require a footer value
            '',
            '',
            'Total', // Label for the totals column
            totalAmount.toFixed(2), // Total amount
            '',
            '',
            '',
            // Add more cells as needed
          ]
        }
        // Add footer to the data array
        createXLSLFormatObj.push(newXlsFooter)
      }
      //   let filename = vm.filename + '.xlsx'
      //   let ws_name = vm.sheetname
      //   let wb = XLSX.utils.book_new(),
      //     ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj)
      //   ws.A1.c = []
      //   ws.A1.c.push({
      //     a: 'SheetJS',
      //     t: "I'm a little comment, short and stout!",
      //   })
      //   XLSX.utils.book_append_sheet(wb, ws, ws_name)
      //   XLSX.writeFile(wb, filename)

      // Construct the Excel file
      let filename = vm.filename + '.xlsx'
      let title =
        vm.sheetname +
        ' from ' +
        this.sdate +
        ' to ' +
        this.edate +
        ' (' +
        outletName +
        ')'
      let Heading = [[title], newXlsHeader]
      let Data = createXLSLFormatObj

      // Create a new worksheet with the title and header
      let ws = XLSX.utils.aoa_to_sheet(Heading)

      // Add the data to the worksheet starting at row 3
      XLSX.utils.sheet_add_json(ws, Data, {
        skipHeader: true,
        origin: 'A3',
        range: 0,
      })

      // Create a new workbook and append the worksheet
      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')

      // Write the workbook to a file
      XLSX.writeFile(wb, filename)
    },
  },
}
</script>
