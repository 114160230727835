<template>
  <div class="report-table">
    <md-card class="hourly-report block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Hourly Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout filter-section search-section block-bg">
          <date-period-dropdown @interval="setDateType"></date-period-dropdown>
          <div
            class="md-layout-item md-size-25"
            v-if="userRole == 'branch_user'"
          >
            <md-field>
              <label>Outlets</label>
              <md-select
                v-model="queries.outletId"
                multiple
                @md-closed="
                  queries.outletId.length > 0
                    ? hourlyReport()
                    : (queries.outletId = [])
                "
              >
                <md-option
                  v-for="item in outlets"
                  :key="item.id"
                  :value="item.id"
                  class="oulet-select-list-item"
                  >{{ item.name }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-25">
            <md-button class="md-teritiary" @click="hourlyReport"
              >SEARCH</md-button
            >
          </div>

          <!-- <div class="md-layout-item md-small-size-100 md-size-100">
                      <md-card>
                          <md-card-content>
                            <line-chart :chart-data="datacollection"></line-chart>
                          </md-card-content>
                      </md-card>
                    </div> -->
        </div>

        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>
        <h4 class="center">Hourly Report- {{ sdate }} to {{ edate }}</h4>

        <div class="md-layout" v-if="!loader">
          <div class="md-layout-item md-size-100">
            <div class="action-buttons">
              <div class="pdf-el">
                <div class="download-btn">
                  <img
                    src="../../../../assets/images/pdf.svg"
                    @click="downloadWithoutCSS()"
                  />
                </div>
              </div>

              <div class="excel-el">
                <vue-excel-xlsx
                  class="Excel-btn"
                  :data="reports"
                  :columns="columns"
                  :filename="'Hourly Report'"
                  :sheetname="'Hourly Report'"
                  :outlet-names="selectedOutletsNames()"
                >
                </vue-excel-xlsx>
              </div>
            </div>
          </div>

          <div class="md-layout-item report-table">
            <div ref="content" id="mytable">
              <md-table class="table-typ1">
                <slot v-for="items in reports">
                  <md-table-row v-if="items.type == 'Time'">
                    <md-table-head>{{ items.type }}</md-table-head>
                    <md-table-head>{{ items.guest }}</md-table-head>
                    <md-table-head>{{ items.sales_totl }}</md-table-head>
                    <md-table-head>{{ items.txns }}</md-table-head>
                    <md-table-head>{{ items.avg_cus_spend }}</md-table-head>
                  </md-table-row>

                  <md-table-row
                    v-else
                    :class="[
                      {
                        'bold-total':
                          items.type == 'Total' ||
                          items.type == 'Subtotals' ||
                          items.type == 'Report Total',
                      },
                    ]"
                  >
                    <md-table-cell
                      :class="{
                        'order-type-bold':
                          items.type == 'Quick Service' ||
                          items.type == 'Take Out',
                      }"
                      >{{ items.type }}</md-table-cell
                    >
                    <md-table-cell>{{ items.guest }}</md-table-cell>
                    <md-table-cell>{{ items.sales_totl }}</md-table-cell>
                    <md-table-cell>{{ items.txns }}</md-table-cell>
                    <md-table-cell>{{ items.avg_cus_spend }}</md-table-cell>
                  </md-table-row>
                </slot>
              </md-table>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { accountService } from '../../../../services/account.js'
import { customizeService } from '../../../../services/customize.js'
import { reportService } from '../../../../services/reports.js'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import LineChart from '../../../../components/Graphs/LineChart.vue'
import DateTimePicker from '../../../../components/DateTimePicker.vue'
import datetime from 'vuejs-datetimepicker'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'

export default {
  components: {
    LineChart,
    DateTimePicker,
    datetime,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
  },
  data() {
    return {
      datacollection: null,
      reports: [],
      search: null,
      searched: [],
      columns: [
        {
          field: 'type',
        },
        {
          field: 'guest',
        },
        {
          field: 'sales_totl',
        },
        {
          field: 'txns',
        },
        {
          field: 'avg_cus_spend',
        },
      ],
      startDate: null,
      endDate: null,
      queries: {
        outletId: [],
        search_type: 'Daily',
      },
      outlets: [],
      userRole: '',
      loader: false,
      sdate: '',
      edate: '',
    }
  },

  methods: {
    fromDateTime(date) {
      this.queries.startDate = date
    },

    toDateTime(date) {
      this.queries.endDate = date
    },

    fillData() {
      this.datacollection = {
        labels: [
          '1:00',
          '2:00',
          '3:00',
          '4:00',
          '5:00',
          '6:00',
          '7:00',
          '8:00',
          '9:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
          '00:00',
        ],
        datasets: [
          {
            label: 'Total Sales',
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0,
            ],
            fill: 'false',
          },
        ],
      }
    },

    arrangeForPdf() {
      var body = []
      this.reports.forEach((el) => {
        let arrayObj = [
          el.type,
          el.guest,
          el.sales_totl,
          el.txns,
          el.avg_cus_spend,
        ]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.queries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },

    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let dateText = ''
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      let selectedOutlets = this.selectedOutletsNames()
      let head = []
      this.queries.startDate = this.sdate
      this.queries.endDate = this.edate
      pdfService.headerFooterConfig(
        this.queries,
        doc,
        'Hourly Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'hourly-report.pdf')
    },

    setDateType(interval) {
      this.queries.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.queries.startDate = interval.startDate
        this.queries.endDate = interval.endDate
      } else {
        this.queries.startDate = interval.date
        this.queries.endDate = undefined
        this.hourlyReport()
      }
    },

    hourlyReport() {
      reportService.getHourlyReport(this.queries).then((res) => {
        this.loader = false
        this.reports = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate
      })
    },
  },

  created() {
    // this.searched = this.users

    this.loader = true
    this.userRole = accountService.userRole()

    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.hourlyReport()
      })
    } else {
      this.hourlyReport()
    }
  },

  mounted() {
    this.fillData()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.md-table-head-container {
  text-align: left;
}

.bold-total {
  font-weight: bold;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}
</style>
