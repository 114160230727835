<template>
  <div>
    <md-card class="itemised-trans block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Itemised Transactions') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout filter-section block-bg">
          <div class="md-layout search-options">
            <div class="md-layout-item">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>

            <div
              class="md-layout-item md-size-20"
              v-if="userRole == 'branch_user'"
            >
              <md-field>
                <label>Outlets</label>
                <md-select
                  v-model="queries.outletId"
                  multiple
                  @md-closed="
                    queries.outletId.length > 0
                      ? itemisedTransactions(queries.billType)
                      : (queries.outletId = [])
                  "
                >
                  <md-option
                    v-for="item in outlets"
                    :key="item.id"
                    :value="item.id"
                    class="oulet-select-list-item"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-size-10">
              <md-field>
                <label>Terminals</label>
                <md-select v-model="queries.terminalId">
                  <md-option
                    v-for="(item, index) in terminals"
                    :key="index"
                    :value="item.id"
                    class="oulet-select-list-item"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-size-10">
              <md-button
                class="md-teritiary"
                @click="itemisedTransactions(queries.billType)"
                >SEARCH</md-button
              >
            </div>
          </div>
        </div>
        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div class="md-layout" v-if="!loader">
          <div class="md-layout-item report-table">
            <div class="table-options">
              <div class="bill-tabs">
                <div
                  class="bill-tab-item cursor-pointer"
                  @click="itemisedTransactions('Completed Bills')"
                  :class="{ activeTab: queries.billType == 'Completed Bills' }"
                >
                  COMPLETED BILLS
                </div>
                <div
                  class="bill-tab-item cursor-pointer"
                  @click="itemisedTransactions('Void Bills')"
                  :class="{ activeTab: queries.billType == 'Void Bills' }"
                >
                  VOID
                </div>
                <!-- <div
                  class="bill-tab-item cursor-pointer"
                  @click="itemisedTransactions('Split Bills')"
                  :class="{ activeTab: queries.billType == 'Split Bills' }"
                >
                  SPLIT
                </div> -->
              </div>
            </div>

            <div class="pad-l-15px pad-r-15px">
              <md-table
                v-model="reports"
                class="paginated-table itemised-txn table-typ1"
                :md-sort.sync="currentSort"
                :md-sort-order.sync="currentSortOrder"
                :md-sort-fn="customSort"
              >
                <md-table-toolbar>
                  <md-field id="search-mgn">
                    <label>{{ $t('Search') }}</label>
                    <md-input
                      type="search"
                      class="mb-3"
                      clearable
                      style=""
                      v-model="searchQuery"
                    ></md-input>
                    <i class="fa fa-search search-align" aria-hidden="true"></i>
                  </md-field>
                  <div class="action-buttons">
                    <div class="pdf-el">
                      <div class="download-btn">
                        <img
                          src="../../../../assets/images/pdf.svg"
                          @click="downloadWithoutCSS()"
                        />
                      </div>
                    </div>

                    <div class="excel-el">
                      <vue-excel-xlsx
                        class="Excel-btn"
                        :data="reports"
                        :columns="columns"
                        :sdate="sdate"
                        :edate="edate"
                        :filename="'Itemized transaction Report'"
                        :sheetname="'Itemized Transaction Report'"
                        :outlet-names="selectedOutletsNames()"
                      >
                      </vue-excel-xlsx>
                    </div>
                  </div>
                </md-table-toolbar>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell
                    v-bind:md-label="$t('Terminal')"
                    md-sort-by="terminal_name"
                  >
                    <div>
                      {{ item.terminal_name }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Outlet')"
                    md-sort-by="outlet_name"
                    v-if="
                      $store.state.account.profile.user_type == 'branch_user'
                    "
                  >
                    <div>
                      {{ item.outlet_name }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Txn ID')"
                    md-sort-by="outlet_name"
                  >
                    <div>
                      {{ item.id }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Bill No.')"
                    md-sort-by="bill_no"
                  >
                    <div>{{ item.bill_no }}</div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Card Ref')">
                    <div>
                      {{ item.card_ref_id ? item.card_ref_id : '' }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Date')"
                    md-sort-by="bill_time"
                  >
                    <div>
                      {{ item.bill_time }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Type')"
                    md-sort-by="order_type"
                  >
                    <div>
                      {{ item.order_type }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Tables')">
                    <div>
                      <div v-for="(sub, index) in item.tbl" :key="index">
                        {{ sub.name }}
                        <span v-if="index != item.tbl.length - 1">,</span>
                      </div>
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Items')">
                    <div v-for="(sub, index) in item.items" :key="index">
                      {{ sub.item_name }} ({{ sub.quantity }})
                      <span v-if="index != item.items.length - 1">,</span>
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Staff')" md-sort-by="stf">
                    <div>
                      {{ item.stf }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('No. of guest')">
                    <div>
                      {{ item.guest_count }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Cash')"
                    md-sort-by="cash_rcvd"
                  >
                    <div>
                      {{ item.cash_rcvd }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Credit')"
                    md-sort-by="credit_amt"
                  >
                    <div>
                      {{ item.credit_amt }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Other')">
                    <div>
                      {{ item.other_amt }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Voucher')">
                    <div>
                      {{ item.voucher_amt }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Service Charge')">
                    <div>
                      {{ item.service_charge }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Gratuity')">
                    <div>
                      {{ item.service_charge }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Fee')">
                    <div>
                      {{ item.other_fee_total.toFixed(2) }}
                    </div>
                  </md-table-cell>
                  <md-table-cell md-label="Amount">
                    {{ item.bill_total }}</md-table-cell
                  >
                  <md-table-cell class="action-cell" md-label="Action">
                    <div
                      class="action-btn"
                      @click="viewBill(item.id, item.outlet_id)"
                    >
                      <a>View</a>
                    </div>
                  </md-table-cell>
                </md-table-row>
                <!-- <md-table-row>
                  <md-table-head v-if="queries.billType == 'Completed Bills'"
                    >Terminal</md-table-head
                  >
                  <md-table-head>Bill No.</md-table-head>
                  <md-table-head>Date</md-table-head>
                  <md-table-head>Type</md-table-head>
                  <md-table-head>Tables</md-table-head>
                  <md-table-head>Items</md-table-head>
                  <md-table-head>Staff</md-table-head>
                  <md-table-head>No.Of Guest</md-table-head>
                  <md-table-head>Cash</md-table-head>

                  <md-table-head>Credit</md-table-head>
                  <md-table-head>Other</md-table-head>
                  <md-table-head>Voucher</md-table-head>
                  <md-table-head>Amount</md-table-head>
                  <md-table-head v-if="queries.billType != 'Void Bills'"
                    >Action</md-table-head
                  >
                </md-table-row>

                <slot v-for="item in reports">
                  <md-table-row slot="md-table-row">
                    <md-table-cell
                      md-label="Terminal"
                      md-sort-by="terminal_name"
                      >{{ item.terminal_name }}</md-table-cell
                    >
                    <md-table-cell md-label="Bill No.">{{
                      item.bill_no
                    }}</md-table-cell>
                    <md-table-cell md-label="Date">{{
                      item.bill_time
                    }}</md-table-cell>
                    <md-table-cell md-label="Type">{{
                      item.order_type
                    }}</md-table-cell>
                    <md-table-cell md-label="Tables"></md-table-cell>
                    <md-table-cell md-label="Items">
                      <div v-for="(sub, index) in item.items" :key="index">
                        {{ sub.item_name }} ({{ sub.quantity }})
                        <span v-if="index != item.items.length - 1">,</span>
                      </div>
                    </md-table-cell>
                    <md-table-cell md-label="Staff">{{
                      item.stf
                    }}</md-table-cell>
                    <md-table-cell md-label="No. of guest">{{
                      item.guest_count
                    }}</md-table-cell>
                    <md-table-cell md-label="Cash">{{
                      item.cash_rcvd
                    }}</md-table-cell>
                    <md-table-cell md-label="Credit">{{
                      item.credit_amt
                    }}</md-table-cell>
                    <md-table-cell md-label="Other">{{
                      item.other_amt
                    }}</md-table-cell>
                    <md-table-cell md-label="Voucher">{{
                      item.voucher_amt
                    }}</md-table-cell>
                    <md-table-cell md-label="Amount">{{
                      item.bill_total
                    }}</md-table-cell>
                    <md-table-cell md-label="Action"
                      ><a @click="viewBill(item.id)">View</a></md-table-cell
                    >
                  </md-table-row>
                </slot>-->
              </md-table>
              <md-table class="table-typ1">
                <md-table-row>
                  <md-table-cell>Total</md-table-cell>
                  <md-table-cell
                    v-if="
                      $store.state.account.profile.user_type == 'branch_user'
                    "
                  >
                  </md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>

                  <md-table-cell>
                    {{ totals.guest_count.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell
                    >{{ totals.cash_rcvd.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.credit_amt.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.other_amt.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.voucher_amt.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.service_charge.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.tips.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.other_fee_total.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.bill_total.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell></md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <md-dialog
      class="itemised-trans-bill bill-container"
      :md-active.sync="showBill"
    >
      <div id="bill-detail">
        <itemised-bill
          :billDetails="billDetails"
          @downloadBill="downloadBill"
        ></itemised-bill>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { reportService } from './../../../../services/reports'
import { accountService } from '../../../../services/account.js'
import { customizeService } from './../../../../services/customize'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import datetime from 'vuejs-datetimepicker'
import DateTimePicker from '../../../../components/DateTimePicker'
import { Tabs } from '@/components'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
import ItemisedBill from './ItemisedBill'
import Fuse from 'fuse.js'

export default {
  components: {
    datetime,
    Tabs,
    DateTimePicker,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
    ItemisedBill,
  },
  data() {
    return {
      columns: [
        {
          label: 'Terminal',
          field: 'terminal_name',
        },
        {
          label: 'Bill no.',
          field: 'bill_no',
        },
        {
          label: 'Card Ref.',
          field: 'card_ref_id',
        },
        {
          label: 'Date',
          field: 'bill_time',
        },
        {
          label: 'Order Type',
          field: 'order_type',
        },
        {
          label: 'Number of Guests',
          field: 'guest_count',
        },
        {
          label: 'Cash',
          field: 'cash_rcvd',
        },
        {
          label: 'Credit',
          field: 'credit_amt',
        },
        {
          label: 'Other',
          field: 'other_amt',
        },
        {
          label: 'Voucher',
          field: 'voucher_amt',
        },
        {
          label: 'Bill Total',
          field: 'bill_total',
        },
        {
          label: 'Items',
          field: 'itemsString',
        },
        {
          label: 'Tables',
          field: 'table_info',
        },
        {
          label: 'Staff',
          field: 'stf',
        },
      ],
      columnStyles: {
        0: { columnWidth: 16 },
        1: { columnWidth: 16 },
        2: { columnWidth: 17 },
        3: { columnWidth: 16 },
        4: { columnWidth: 20 },
        5: { columnWidth: 20 },
        6: { columnWidth: 17 },
        7: { columnWidth: 18 },
        8: { columnWidth: 18 },
        9: { columnWidth: 18 },
        10: { columnWidth: 18 },
        11: { columnWidth: 14 },
        12: { columnWidth: 16 },
        13: { columnWidth: 10 },
        14: { columnWidth: 12 },
        15: { columnWidth: 17 },
        16: { columnWidth: 20 },
      },
      activeTab: 1,
      queries: {
        outletId: [],
        billType: 'Completed Bills',
        search_type: 'Daily',
      },
      search: '',
      searched: '',
      reports: [],
      terminals: [],
      selectedTerminal: String,
      outlets: [],
      userRole: '',
      loader: false,
      showBill: false,
      billDetails: {
        data: [],
        tax: [],
      },
      searchQuery: '',
      searchedData: '',
      currentSort: 'bill_time',
      currentSortOrder: 'desc',
      sdate: '',
      edate: '',
    }
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

    searchQuery(value) {
      if (value !== '') this.searchInitiate()
      else this.reports = this.searched
    },
  },

  computed: {
    totals() {
      let totals = {}
      totals.guest_count = this.reports.reduce((prev, next) => {
        return prev + next.guest_count
      }, 0)
      totals.cash_rcvd = this.reports.reduce((prev, next) => {
        return prev + next.cash_rcvd
      }, 0)
      totals.credit_amt = this.reports.reduce((prev, next) => {
        return prev + next.credit_amt
      }, 0)
      totals.other_amt = this.reports.reduce((prev, next) => {
        return prev + next.other_amt
      }, 0)
      totals.voucher_amt = this.reports.reduce((prev, next) => {
        return prev + next.voucher_amt
      }, 0)
      totals.service_charge = this.reports.reduce((prev, next) => {
        return prev + next.service_charge
      }, 0)
      totals.other_fee_total = this.reports.reduce((prev, next) => {
        return prev + next.other_fee_total
      }, 0)
      totals.bill_total = this.reports.reduce((prev, next) => {
        return prev + next.bill_total
      }, 0)
      totals.tips = this.reports.reduce((prev, next) => {
        return prev + next.tips
      }, 0)
      return totals
    },
  },

  methods: {
    formatedReceipt(transactionId, receiptCopy) {
      const prefixSpace = ' '

      const pspReference = transactionId.split('.').pop()
      const pspRefStr = pspReference ? `PSPReference:${pspReference}` : ''
      const currentReceiptItems = receiptCopy.split('\n')
      let linesToProcess = []
      const skipLinesStartsWith = [
        'card type',
        'payment method',
        'payment varia',
        'cvm res',
        // 'type',
        'thank you',
      ]
      let authCodeLine = ''
      let result = ''
      let dateTimeLine = ''
      let didFoundTotalRow = false
      let didFoundFirstValidLine = false

      for (const line of currentReceiptItems) {
        const lineTrimmedLowercase = line.trim().toLowerCase()
        if (!didFoundFirstValidLine) {
          if (lineTrimmedLowercase) {
            didFoundFirstValidLine = true
          }
          continue
        }
        let shouldSkip = false
        for (const skipLine of skipLinesStartsWith) {
          if (lineTrimmedLowercase.startsWith(skipLine)) {
            shouldSkip = true
            break
          }
        }
        if (shouldSkip) {
          continue
        }
        if (lineTrimmedLowercase.startsWith('auth.')) {
          authCodeLine = line
          continue
        }
        if (lineTrimmedLowercase.startsWith('date ')) {
          dateTimeLine = line
          continue
        }
        if (lineTrimmedLowercase.startsWith('time ')) {
          const time = lineTrimmedLowercase.split(' ').pop()
          if (time) {
            dateTimeLine = `${dateTimeLine} ${time}`
          }
          continue
        }
        if (didFoundTotalRow && lineTrimmedLowercase && !result) {
          result = line
          continue
        }
        if (lineTrimmedLowercase.startsWith('total ')) {
          didFoundTotalRow = true
        }
        linesToProcess.push(line)
      }

      let newReceipt = []

      for (const line of linesToProcess) {
        const lineTrimmedLowercase = line.trim().toLowerCase()
        if (!lineTrimmedLowercase && newReceipt.length > 0) {
          const lastLine = newReceipt[newReceipt.length - 1]
          if (!lastLine.trim()) {
            continue
          }
        }

        if (lineTrimmedLowercase.startsWith('card ')) {
          newReceipt.push(`${prefixSpace}${dateTimeLine}`)
        }
        if (lineTrimmedLowercase.startsWith('aid ')) {
          newReceipt.push(`${prefixSpace}${authCodeLine}`)
        }
        newReceipt.push(`${prefixSpace}${line}`)
        if (lineTrimmedLowercase.includes('reference')) {
          newReceipt.push(`${prefixSpace}${pspRefStr}`)
        }
      }

      return newReceipt
    },
    getCardRerence(cardType, refId) {
      let cardRefId = ''
      let refernces = []
      let returnData = { refId: '', cardRefsummary: [] }
      let custReceipt = []

      switch (cardType) {
        case 1:
          // code block
          cardRefId = refId.split(',')[1].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'SagePay refId', value: cardRefId })
          custReceipt = ''
          break
        case 2:
          cardRefId = refId.split(',')[2].replace(/[^a-z0-9]/gi, '')
          let authCode = refId.split(',')[3].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'Card Ref', value: cardRefId })
          refernces.push({ title: 'AuthCode', value: authCode })
          custReceipt = ''

          break
        case 3:
          cardRefId = refId.split(',')[1].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'Card Ref', value: cardRefId })
          // code block
          break
        case 4:
          cardRefId = refId.split(',')[2].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'Card Ref', value: cardRefId })
          break
        case 5:
          cardRefId = refId.split(',')[0].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'Card Ref', value: cardRefId })
        case 6:
          cardRefId = refId.split(',')[0].split('.').slice(-1)[0]
          refernces.push({ title: 'Card Ref', value: cardRefId })
          custReceipt = refId.split(',')[5]
          refernces.push({
            title: 'Customer Receipt',
            // value: custReceipt.split('<E¬>'),
            value: this.formatedReceipt(
              cardRefId,
              custReceipt.replace(/<E¬>/g, '\n')
            ),
          })
        case 7:
          let accountId = refId.split(',')[4]
          let correlationId = refId.split(',')[0]
          cardRefId = refId.split(',')[0]
          let userName = refId.split(',')[1]
          let balanceUsed = refId.split(',')[2]
          let currentBalance = refId.split(',')[5]

          refernces.push({ title: 'Account Id', value: accountId })
          refernces.push({ title: 'CorrelationId', value: correlationId })
          refernces.push({ title: 'User Name', value: userName })
          refernces.push({ title: 'Balance Used', value: balanceUsed })
          refernces.push({ title: 'Current Balance', value: currentBalance })
          break
        default:
        // code block
      }
      returnData.refId = cardRefId
      returnData.cardRefsummary = refernces
      return returnData
    },
    customSort(value) {
      return this.searched.sort((a, b) => {
        const sortBy = this.currentSort

        if (this.currentSortOrder === 'desc') {
          return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, { numeric: true })
        }
        return a[sortBy]
          .toString()
          .localeCompare(b[sortBy].toString(), undefined, { numeric: true })
      })
    },
    fromDateTime(date) {
      this.queries.startDate = date
    },

    toDateTime(date) {
      this.queries.endDate = date
    },

    returnItemsAsStrings() {
      let reportString = []
      this.reports.forEach((el) => {
        let itemStringList = ''
        if (el.items) {
          el.items.forEach((item) => {
            itemStringList =
              itemStringList + `${item.item_name}(${item.quantity}),`
          })
        }

        el.itemsString = itemStringList
        // el.card_ref_id = el.card_ref_id
        //   ? el.card_ref_id.split(',')[2].replace(/[^a-z0-9]/gi, '')
        //   : ''
        let ref = el.card_ref_id
          ? this.getCardRerence(el.card_type, el.card_ref_id)
          : {}
        el.card_ref_id = el.card_ref_id ? ref.refId : ''
      })
    },

    arrangeForPdf() {
      var body = []
      this.reports.forEach((el) => {
        let items = []
        let tables = []
        if (el.items != null) {
          el.items.forEach((item) => {
            items.push(item.item_name + '(' + item.quantity + ')')
          })
        }
        if (el.tbl != null) {
          el.tbl.forEach((table) => {
            tables.push(table.name)
          })
        }

        let arrayObj = [
          el.terminal_name,
          el.id,
          el.bill_no,
          el.card_ref_id,
          el.bill_time,
          el.order_type,
          tables,
          el.guest_count,
          el.cash_rcvd,
          el.credit_amt,
          el.other_amt,
          el.voucher_amt,
          el.bill_total,
          el.service_charge,
          el.tips,
          el.other_fee_total,
          items,
          el.stf,
        ]
        body.push(arrayObj)
      })
      let totObj = [
        '',
        '',
        '',
        '',
        '',
        'Total',
        this.totals.guest_count.toFixed(2),
        this.totals.cash_rcvd.toFixed(2),
        this.totals.credit_amt.toFixed(2),
        this.totals.other_amt.toFixed(2),
        this.totals.voucher_amt.toFixed(2),
        this.totals.bill_total.toFixed(2),
        '',
        '',
        '',
      ]

      body.push(totObj)

      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.queries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let dateText = ''
      let head = [
        [
          // 'Termi',
          'iPad',
          'Txn #',
          'Bill #',
          'Car. Ref#',
          'Date',
          'Type',
          'Tables',
          'guests',
          'Cash',
          'Credit',
          'Other',
          'Vchr',
          'Bill Tot',
          'Serv Chrg',
          'Fee',
          'Items',
          'Staff',
        ],
      ]

      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      let selectedOutlets = this.selectedOutletsNames()
      this.queries.startDate = this.sdate
      this.queries.endDate = this.edate
      pdfService.headerFooterConfig(
        this.queries,
        doc,
        'Itemized Transactions',
        selectedOutlets
      )
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        this.columnStyles,
        'Itemized-transactions.pdf'
      )
    },

    searchOnTable() {
      this.searched = this.searchByName(this.reports, this.search)
    },

    searchByName(items, term) {
      if (term) {
        return items.filter((item) =>
          toLower(item.order_type).includes(toLower(term))
        )
      }

      return items
    },

    toLower(text) {
      return text.toString().toLowerCase()
    },

    viewBill(billNo, outId = '') {
      let outletId
      if (this.userRole != 'branch_user') {
        outletId = accountService.getOutletId()
      } else {
        outletId = outId
      }
      reportService.viewItemTransBill(billNo, outletId).then((res) => {
        // this.billDetails = res.data.result[0]
        // console.log('Billdetails:', this.billDetails)
        // this.showBill = true

        let result = res.data.result
        let items = res.data.result.bill.items.filter(
          (item) => item.combo_id === null
        )
        result.bill.items = items
        this.billDetails = result
        let ref = this.billDetails.bill.card_ref_id
          ? this.getCardRerence(
              this.billDetails.bill.card_type,
              this.billDetails.bill.card_ref_id
            )
          : {}

        this.billDetails.bill['card_ref_summary'] = this.billDetails.bill
          .card_ref_id
          ? ref.cardRefsummary
          : []

        this.showBill = true
      })
    },

    downloadBill(billNo) {
      html2canvas(document.querySelector('#bill-detail'), {
        useCORS: true,
        allowTaint: true,
        // scrollY: -window.scrollY,
        // height: document.querySelector('#bill-detail').offsetHeight,
      })
        .then((canvas) => {
          var list = document.getElementsByTagName('canvas')
          //you can also do this with classes.

          while (list && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              list[i].remove()
            }
          }
          document.body.appendChild(canvas)
        })
        .then(() => {
          var canvas = document.querySelector('canvas')
          canvas.style.display = 'none'
          var image = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
          var a = document.createElement('a')
          a.setAttribute('download', billNo + '.png')
          a.setAttribute('href', image)
          a.click()
          a.remove()
        })
    },

    setDateType(interval) {
      this.queries.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.queries.startDate = interval.startDate
        this.queries.endDate = interval.endDate
      } else {
        this.queries.startDate = interval.date
        this.queries.endDate = undefined
        if (!this.loader) {
          this.itemisedTransactions('Completed Bills')
        }
      }
    },

    itemisedTransactions(bill) {
      this.queries.billType = bill
      this.loader = true
      reportService.getItemTransactions(this.queries).then((res) => {
        this.loader = false
        this.reports = res.data.result
        this.searched = this.reports
        this.returnItemsAsStrings()
        this.sdate = res.data.sdate
        this.edate = res.data.edate
        this.reports.forEach((el) => {
          var commaSeparatedString = ''
          if (el.tbl != null) {
            let namesArray = el.tbl.map((item) => item.name)
            commaSeparatedString = namesArray.join(', ')
          }
          el.table_info = commaSeparatedString
        })
      })
    },

    searchInitiate() {
      let result = new Fuse(this.searched, {
        keys: [
          'terminal',
          'bill_no',
          'bill_time',
          'order_type',
          'items.item_name',
          'cash_rcvd',
          'credit_amt',
          'stf',
        ],
        threshold: 0.1,
      }).search(this.searchQuery)
      this.reports = result.map((el) => el.item)
    },
  },

  created() {
    this.loader = true
    customizeService.getterminals().then((res) => {
      this.terminals = res.data
      this.terminals.unshift({ id: 'undefined', name: 'All' })
    })

    this.userRole = accountService.userRole()
    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.itemisedTransactions(this.queries.billType)
      })
    } else {
      if (this.$route.query.s && this.$route.query.e) {
        this.queries.search_type = 'Ad Hoc'
        this.queries.startDate = this.$route.query.s
        this.queries.endDate = this.$route.query.e
      }

      this.itemisedTransactions(this.queries.billType)
    }
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}

.bill-tabs {
  display: flex;
}

.bill-tab-item {
  padding: 14px;
  margin: 14px;
  border-radius: 3px;
}

.activeTab {
  background: #2b82be;
  color: white;
}

.table-options {
  display: flex;
}
.itemised-txn tr:last-child td {
  font-weight: normal;
}
.footer-table .md-table-content .md-table-row .md-table-cell {
  width: 150px;
}
.footer-table tr td:nth-child(5) {
  width: 15% !important;
}

.itemised-trans-bill.bill-container .md-dialog-container {
  max-width: 20% !important;
}

.itemised-trans-bill.bill-container {
  height: 80%;
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .footer-table tr td:nth-child(5) {
    width: 30% !important;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1280px) {
  .footer-table tr td:nth-child(5) {
    width: 30% !important;
  }
}
.action-cell {
  height: 100%;
  cursor: auto;
}
#search-mgn {
  margin-right: 100px !important;
}
.action-buttons {
  top: -10px !important;
}
</style>
<style>
.md-table table {
  table-layout: fixed;
}

.md-table-cell {
  word-break: break-all;
}
.md-table .md-table-row td {
  padding-left: 5px !important;
}
.md-table-cell:last-child .md-table-cell-container {
  padding-right: 5px !important;
}
.md-table-head:last-child {
  text-align: center !important;
  padding-right: 5px !important;
}
/* .report-table .md-table-head .md-table-head-container {
} */
@media (max-width: 1440px) {
  .itemised-trans .md-table-head-label {
    white-space: normal;
  }
  .md-table-cell {
    font-size: 12px;
  }
}
.action-cell .md-table-cell-container {
  height: 100% !important;
}
.md-table table {
  height: 100% !important;
}
.action-cell .md-table-cell-container .action-btn {
  height: 100% !important;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  cursor: pointer;
}
</style>
