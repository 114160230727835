import { environment } from './../../config'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'

var baseUrl = `${environment.api.host}`
var dynamicFlg = false
var currentRequest = {}
// axios interceptors

function authFun() {
  const auth = {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
    },
  }
  return auth
}

axios.interceptors.request.use((request) => {
  if (
    request.url !=
    baseUrl + `${environment.api.services.accounts.oauth}` + '?'
  ) {
    currentRequest = request
    return currentRequest
  }
  return request
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  function (err) {
    if (err) {
      if (err.response.status == 401) {
        // getAccessToken().then((res) => {
        //   console.log('New token', res)
        //   sessionStorage.setItem('access_token', res.data.access_token)
        //   sessionStorage.setItem('refresh_token', res.data.refresh_token)
        //   location.reload()
        // })
      } else {
        throw err
      }
    }
  }
)

//Account Service
export const stripeService = {
  createCheckoutSession,
  priceList,
  getStripeInvoices,
  getPriceDetails,
}
function createCheckoutSession(id, body, type = 'Outlet') {
  return axios.post(
    baseUrl +
      `${environment.api.services.stripe.create_checkout_session}?id=` +
      id +
      '&type=' +
      type,
    body
  )
}
function priceList() {
  return axios.get(baseUrl + `${environment.api.services.stripe.price_list}`)
}
function getStripeInvoices() {
  return axios.get(
    baseUrl + `${environment.api.services.stripe.invoice}`,
    authFun()
  )
}

function getPriceDetails(id, type = 'Outlet') {
  return axios.get(
    baseUrl +
      `${environment.api.services.stripe.get_price}?id=` +
      id +
      '&type=' +
      type
  )
}
