<template>
  <div>
    <md-card class="daily-sales block-bg">
      <md-card-header class="md-card-header-text md-layout md-gutter">
        <div class="md-layout-item md-size-100">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Daily Sales') }}</h3>
        </div>
      </md-card-header>

      <div class="md-layout filter-section block-bg">
        <div class="md-layout search-options">
          <div class="md-layout search-sections">
            <div class="md-layout-item md-size-10">
              <!-- <datetime format="YYYY-MM-DD" width="300px" name='dob' v-model="queries.startDate"></datetime> -->
              <single-date-picker @emitDate="setDate"></single-date-picker>
            </div>

            <div class="md-layout-item">
              <div class="list-interval mt-15">
                <div
                  :class="[
                    { activeDate: item.date == queries.startDate },
                    'interval-item',
                  ]"
                  v-for="(item, index) in prevDays"
                  @click="setDayClick(item)"
                  :key="index"
                >
                  {{ item.display }}
                </div>
              </div>
            </div>
            <div
              class="md-layout-item md-size-25"
              v-if="userRole == 'branch_user'"
            >
              <md-field>
                <label>Outlets</label>
                <md-select
                  v-model="queries.outletId"
                  multiple
                  @md-closed="
                    queries.outletId.length > 0
                      ? dailySales()
                      : (queries.outletId = [])
                  "
                >
                  <md-option
                    v-for="item in outlets"
                    :key="item.id"
                    :value="item.id"
                    class="oulet-select-list-item"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-size-15" md-alignment="right">
              <md-button class="md-teritiary" @click="dailySales()"
                >SEARCH</md-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>
      <h4 class="center">Daily Sales- {{ sdate }} to {{ edate }}</h4>

      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <pie-chart
            :chart-data="datacollection"
            :height="70"
            :options="chartOptions"
          ></pie-chart>
        </div>

        <div class="md-layout-item report-table">
          <md-table class="table-typ1">
            <md-table-toolbar>
              <div class="action-buttons export-btn">
                <div class="pdf-el">
                  <div class="download-btn">
                    <img
                      src="../../../../assets/images/pdf.svg"
                      @click="downloadWithoutCSS()"
                    />
                  </div>
                </div>

                <div class="excel-el">
                  <vue-excel-xlsx
                    class="Excel-btn"
                    :data="report"
                    :columns="columns"
                    :filename="'Daily-sales'"
                    :sheetname="'Daily Sales'"
                    :sdate="sdate"
                    :edate="edate"
                    :outlet-names="selectedOutletsNames()"
                  >
                  </vue-excel-xlsx>
                </div>
              </div>
            </md-table-toolbar>
            <md-table-row>
              <md-table-head>Order Type</md-table-head>
              <md-table-head>Cash</md-table-head>
              <md-table-head>Credit</md-table-head>
              <md-table-head>Other Amount</md-table-head>
              <md-table-head>Voucher</md-table-head>
              <md-table-head>Total Sales</md-table-head>
            </md-table-row>

            <md-table-row v-for="(item, index) in report" :key="index">
              <md-table-cell>{{ item.order_type }}</md-table-cell>
              <md-table-cell>{{ item.cash }}</md-table-cell>
              <md-table-cell>{{ item.credit }}</md-table-cell>
              <md-table-cell>{{ item.other_amt }}</md-table-cell>
              <md-table-cell>{{ item.voucher }}</md-table-cell>
              <md-table-cell>{{ item.total_sale }}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import { accountService } from '../../../../services/account.js'
import { reportService } from '../../../../services/reports.js'
import { customizeService } from '../../../../services/customize.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import PieChart from '../../../../components/Graphs/PieChart.vue'
import datetime from 'vuejs-datetimepicker'
import SingleDatePicker from '../../../../components/SingleDatePicker.vue'
import { pdfService } from '../../../../services/jspdf.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'

export default {
  components: {
    PieChart,
    datetime,
    SingleDatePicker,
    ClipLoader,
    VueExcelXlsx,
  },
  data() {
    return {
      search: null,
      searched: [],
      report: [],
      sdate: '',
      edate: '',
      columns: [
        {
          label: 'Order Type',
          field: 'order_type',
        },
        {
          label: 'Cash',
          field: 'cash',
        },
        {
          label: 'Credit',
          field: 'credit',
        },
        {
          label: 'Other Amount',
          field: 'other_amt',
        },
        {
          label: 'Voucher',
          field: 'voucher',
        },
        {
          label: 'Total Sales',
          field: 'total_sale',
        },
      ],
      datacollection: null,
      totals: ['Total Sales', 0, 0, 0, 0, 'Total Sales', 0, 0, 0, 0, 0, 0, 0],
      queries: {
        outletId: [],
      },
      userRole: '',
      outlets: [],
      loader: false,
      prevDays: [],
      chartOptions: {
        legend: {
          position: 'bottom',
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0
              let dataArr = ctx.chart.data.datasets[0].data
              dataArr.map((data) => {
                sum += parseInt(data)
              })
              let percentage = ((parseInt(value) * 100) / sum).toFixed(2) + '%'

              return percentage
            },
            color: '#fff',
          },
        },
      },
    }
  },

  methods: {
    setDate(date) {
      this.queries.startDate = date
    },

    getPrevDays() {
      let today = new Date()
      let listDate = new Date()
      for (let i = 0; i < 7; i++) {
        let previousDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - i
        )
          .toDateString()
          .split(' ')
          .slice(1)
          .join(' ')
        let displayDate = new Date(
          listDate.getFullYear(),
          listDate.getMonth(),
          listDate.getDate() - i
        )
          .toDateString()
          .split(' ')
          .slice(1)
          .join(' ')
        this.prevDays.push({
          date: previousDate,
          display: displayDate,
        })
      }
      this.queries.startDate = this.prevDays[0].date
    },

    setDayClick(item) {
      this.queries.startDate = item.date
      this.dailySales()
    },

    fillData() {
      this.datacollection = {
        labels: ['Credit', 'Cash'],
        datasets: [
          {
            label: 'Daily Sales',
            backgroundColor: ['#41B883', '#E46651'],
            data: [],
          },
        ],
      }
      this.datacollection.datasets[0].data.push(
        this.report[this.report.length - 1].credit
      )
      this.datacollection.datasets[0].data.push(
        this.report[this.report.length - 1].cash
      )
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = Object.values(el)
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.queries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },

    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })
      let head = [
        [
          'Order Type',
          'Cash',
          'Credit',
          'Other Amount',
          'Voucher',
          'Total Sales',
        ],
      ]
      let dateText = this.queries.startDate
      pdfService.headerFooterConfig(
        this.queries,
        doc,
        'Daily Sales',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'Daily Sales.pdf')
    },

    dailySales() {
      this.loader = true
      reportService.getDailySales(this.queries).then((res) => {
        this.loader = false
        this.report = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate

        this.fillData()
      })
    },
  },

  created() {
    this.searched = this.users
    this.getPrevDays()
    this.loader = true
    this.userRole = accountService.userRole()
    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.dailySales()
      })
    } else {
      this.dailySales()
    }
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}
.main-heading {
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 18px;
}

.toolbar-monthly {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.mt-15 {
  margin-top: 15px;
}
.mt-19 {
  margin-top: 19px;
}
.search-options .datetime-comp {
  margin-top: 0px;
}
</style>
