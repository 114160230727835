<template>
  <div>
    <div class="content" id="powerBIcontainer"></div>
  </div>
</template>

<script>
import * as pbi from 'powerbi-client'
import axios from 'axios'
import { accountService } from '../../../services/account.js'

export default {
  components: {},

  data() {
    return {}
  },

  methods: {
    getAccessCredentials(workspaceID, reportID) {
      // const obj = {
      //   grant_type: 'passsword',
      //   scope: 'https://analysis.windows.net/powerbi/api/.default',
      //   client_id: '012f6e64-4bc3-47c3-ac1f-ea8b50148307',
      //   username: 'anoob@phenomtec.com',
      //   password: 'Islet!123',
      //   client_secret: 'iI48Q~tLGuwMVe8LToXnhX-W-VjJpAd.czt2Qc5s',
      // }
      // const data = Object.keys(obj)
      //   .map((key, index) => `${key}=${encodeURIComponent(obj[key])}`)
      //   .join('&')

      // const data = new URLSearchParams()
      // data.append('grant_type', 'passsword')
      // data.append('scope', 'https://analysis.windows.net/powerbi/api/.default')
      // data.append('client_id', '012f6e64-4bc3-47c3-ac1f-ea8b50148307')
      // data.append('username', 'anoob@phenomtec.com')
      // data.append('password', 'Islet!123')
      // data.append('client_secret', 'iI48Q~tLGuwMVe8LToXnhX-W-VjJpAd.czt2Qc5s')
      // data.append('Access-Control-Allow-Origin', '*')

      // const data =
      //   'grant_type=password&scope=https%3A%2F%2Fanalysis.windows.net%2Fpowerbi%2Fapi%2F.default&client_id=012f6e64-4bc3-47c3-ac1f-ea8b50148307&username=anoob%40phenomtec.com&password=Islet!123&client_secret=iI48Q~tLGuwMVe8LToXnhX-W-VjJpAd.czt2Qc5s'

      const info = {
        data: {
          grant_type: 'password',
          scope: 'https://analysis.windows.net/powerbi/api/.default',
          client_id: '012f6e64-4bc3-47c3-ac1f-ea8b50148307',
          username: 'anoob@phenomtec.com',
          password: 'Islet!123',
          client_secret: 'iI48Q~tLGuwMVe8LToXnhX-W-VjJpAd.czt2Qc5s',
        },
      }
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
        },
        info,
        url: 'https://login.microsoftonline.com/5297ccbe-b22c-4aa0-ba54-a042827f0ffb/oauth2/v2.0/token',
      }
      axios
        .get(`https://192.168.29.198:3000/ws/test`)
        .then((res) => {
          this.powerBI(res.data)
        })
        .catch((err) => {
          this.powerBI({})

          this.$eventBus.$emit('error', err.data.message)
        })
      this.powerBI({})
    },

    powerBI(data) {
      var IamAFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'bills',
          column: 'outlet_id',
        },
        operator: 'In',
        values: [accountService.getOutletId()],
      }
      var permissions = pbi.models.Permissions.All
      var config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: data.token,
        embedUrl: data.embedded_url,
        id: 'a4fb303d-6857-4764-a1a9-748b82ba9d5c',
        permissions: permissions,
        filters: [IamAFilter],
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true,
        },
      }

      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      )
      var reportContainer = document.getElementById('powerBIcontainer')
      var report = powerbi.embed(reportContainer, config)

      var basicFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'chains',
          column: 'Email',
        },
        operator: 'In',
        values: ['test@domain.com'],
      }
      report.getFilters().then(function (allTargetFilters) {
        allTargetFilters.push(basicFilter)

        // Set filters
        // https://microsoft.github.io/PowerBI-JavaScript/interfaces/_src_ifilterable_.ifilterable.html#setfilters
        report.setFilters(allTargetFilters)
      })

      report.off('loaded')
      report.off('rendered')
      report.on('error', function () {
        this.report.off('error')
      })
      report.reload().catch((error) => {
        console.log(error)
      })

      report.on('dataRefreshed', function (event) {
        // Do something here, such as update your UI
      })
    },
  },

  created() {},

  mounted() {
    this.getAccessCredentials(
      'eaf2d71d-704e-4ede-bef7-2f169b4091d0',
      'd5c51eac-c0e4-4f2e-8ed3-27afbeb255b2'
    )
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}
.main-heading {
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 18px;
}

.toolbar-monthly {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.arrow_left {
  float: left;
  padding-top: 21px;
}
.eod-table {
  overflow: scroll;
}
</style>
<style>
.md-table-head-label {
  font-size: 13px !important;
}
.end-of-day .md-table-head-label {
  font-size: 11px !important;
}

#powerBIcontainer {
  height: 100vh;
}
</style>
