import jsPDF from 'jspdf'
import autotable from 'jspdf-autotable'
import store from '../store'
import moment from 'moment'

export const pdfService = {
  headerFooterConfig,
  autoTableConfig,
}

let header
let totalLines = 1
let outletNames = ''
let pageWidth = 0
let maxWidth = 0
let diamension = 0
function headerFooterConfig(queries, doc, reportName, selectedOutlets = '') {
  // console.log('qqqqq', queries)
  let outletName = ''
  if (selectedOutlets != '') {
    outletName = selectedOutlets
  } else {
    outletName = store.getters.getProfile.profile
  }
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  let dateText = ''
  outletNames = reportName + ' (' + outletName + ')'
  pageWidth = doc.internal.pageSize.getWidth() // Get the page width
  maxWidth = pageWidth - 20 // Leaving 10 units margin on each side
  const lines = doc.splitTextToSize(outletNames, maxWidth)
  totalLines = lines
  // Calculate the dimensions of the title text
  const titleDimensions = doc.getTextDimensions(lines)
  diamension = titleDimensions
  header = function (data) {
    var xOffset = doc.internal.pageSize.width / 2

    doc.setFontStyle('Times New Roman')
    doc.setFontSize(14)
    doc.setTextColor(40)
    //doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
    // doc.text(reportName + ' (' + outletName + ')', xOffset, 12, {
    //   align: 'center',
    // })

    const titleHeight = titleDimensions.h // Height of the title text
    // Render the text
    doc.text(lines, pageWidth / 2, 12, {
      align: 'center',
    })
    doc.setFontSize(10)
    if (
      reportName == 'Monthly Sales' ||
      reportName == 'Weekly Sales' ||
      reportName == 'Annual Sales'
    ) {
      if (reportName == 'Monthly Sales' || reportName == 'Annual Sales') {
        dateText =
          queries.month != undefined
            ? months[queries.month - 1] + ' ' + queries.year
            : queries.year.toString()
      } else {
        dateText = queries.startDate + ' to ' + queries.endDate
      }
    } else {
      switch (queries.search_type) {
        case 'Yearly':
          dateText = queries.startDate.toString()
          break
        case 'Monthly':
          const currentYear = moment(queries.startDate, 'DD/MM/YYYY').format(
            'YYYY'
          )
          let selectedYear =
            queries.startDate > parseInt(moment().format('M'))
              ? parseInt(currentYear) - 1
              : currentYear
          dateText =
            moment(queries.startDate, 'DD/MM/YYYY HH:mm').format('MMMM') +
            ' ' +
            selectedYear
          break
        case 'Weekly':
          let endDate = moment(
            moment(queries.startDate, 'DD-MM-YYYY HH:mm').add('days', 7)
          ).format('DD/MM/YYYY HH:mm')

          dateText = queries.startDate + ' to ' + endDate
          break
        default:
          dateText =
            queries.endDate != undefined
              ? queries.startDate + ' to ' + queries.endDate
              : queries.startDate
          break
      }
    }
    doc.setDrawColor(0, 0, 0)
    const dateTextY = diamension.h + 12 // Adding 8 units of padding after the title
    doc.text(dateText, xOffset, dateTextY, {
      align: 'center',
    })
    doc.setLineWidth(0.3)
    if (reportName !== 'Weekly Sales') {
      doc.line(
        5,
        diamension.h + 15,
        doc.internal.pageSize.width - 5,
        diamension.h + 15
      )
    }
    doc.setFontSize(12)
    doc.setTextColor(40)
    if (reportName == 'Monthly Sales') {
      doc.line(5, 36, doc.internal.pageSize.width - 5, 36)
    } else if (reportName == 'End Of Day') {
      doc.line(5, 36, doc.internal.pageSize.width - 5, 36)
    } else if (reportName == 'Terminal Transactions') {
      doc.line(
        5,
        diamension.h + 24,
        doc.internal.pageSize.width - 5,
        diamension.h + 24
      )
    } else {
      doc.line(5, 26, doc.internal.pageSize.width - 5, 26)
    }
    doc.line(
      5,
      doc.internal.pageSize.height - 15,
      doc.internal.pageSize.width - 5,
      doc.internal.pageSize.height - 15
    )
    doc.setFontSize(10)
    doc.text('CBE Innova', 5, doc.internal.pageSize.height - 10)
    doc.setFontSize(9)
    doc.text('ver 2.0.0', 5, doc.internal.pageSize.height - 6)
    doc.text(
      'https://www.cbeinnova.com/',
      doc.internal.pageSize.width / 2 - 22,
      doc.internal.pageSize.height - 10
    )
    doc.text(
      'Page ' + doc.internal.getNumberOfPages(),
      doc.internal.pageSize.width - 13,
      doc.internal.pageSize.height - 10
    )
  }
}

function autoTableConfig(
  doc,
  head,
  body,
  columnStyles,
  filename,
  fontSize = 10
) {
  let heading = []
  let newHead = []
  // let heading = head.map(function (x) {
  //   return x.toString().toUpperCase()
  // })
  for (let i = 0; i < head[0].length; i++) {
    heading.push(head[0][i].toUpperCase())
  }
  newHead.push(heading)

  const headerHeight = diamension.h + 13
  doc.autoTable({
    body: body,
    head: newHead,
    styles: {
      font: 'Times New Roman',
      fontSize: fontSize,
    },
    margin: { top: headerHeight, bottom: 30, left: 5, right: 5 },
    theme: 'grid',
    headStyles: {
      fillColor: ['#FFFFFF'],
      textColor: 50,
      padding: { bottom: 20 },
    },
    columnStyles: columnStyles,
    didDrawPage: header,
    didParseCell: (data) => {
      if (data.cell && data.cell.section == 'body') {
        if (isNumber(data.cell.raw)) {
          data.cell.styles.halign = 'right'
        } else {
          data.cell.styles.halign = 'left'
        }
      } else if (data.cell && data.cell.section == 'head') {
        data.cell.styles.halign = 'center'
      }
    },
    willDrawCell: function (data) {
      var rows = data.table.body

      // loop the outer array
      for (let i = 0; i < body.length; i++) {
        // get the size of the inner array
        var innerArrayLength = body[i].length
        // loop the inner array
        for (let j = 0; j < innerArrayLength; j++) {
          if (body[i][j] === 'Overall Total') {
            if (data.row.index === i) {
              doc.setFontStyle('bold')
              doc.setFontSize('11')
            }
          }
        }
      }

      if (data.row.index === rows.length - 1) {
        doc.setFontStyle('bold')
        doc.setFontSize('11')
      }
    },
  })

  doc.save(filename)
}

function isNumber(str) {
  return /^[0-9.-]+$/.test(str)
}

function getTextHeight(doc, text, fontSize = 12) {
  doc.setFontSize(fontSize) // Set the desired font size
  const dimensions = doc.getTextDimensions(text)
  return dimensions.h // Return the height of the text
}
