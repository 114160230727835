<template>
  <div class="md-layout menu-table">
    <div class="md-layout-item pd-l">
      <md-card class="pagination-card block-bg">
        <md-card-header
          class="md-card-header-icon md-card-header-green"
          v-if="$store.state.account.profile.has_access"
        >
          <router-link
            :to="{
              name: 'MenuItemsForm',
              query: { formType: 'Add Menu Item', sku: maxSku.item_code, flag },
            }"
          >
            <md-icon class="add-icon">add</md-icon>
          </router-link>
          <h3 class="title">{{ $t('Menu Items') }}</h3>
          <h3 class="title" v-if="outletDetails.name">
            ({{ outletDetails.name }})
          </h3>

          <div class="active-inactive-tab" v-if="!loader">
            <div class="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox"
                id="myonoffswitch"
                tabindex="0"
                :value="activetab"
                @change="updateInput($event)"
                :checked="activetab == 'active' ? true : false"
              />
              <label class="onoffswitch-label" for="myonoffswitch">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>

            <!-- <md-button class="edit-items-btn md-primary" @click="importMenuItem"
              >Import</md-button
            > -->
            <md-button
              class="edit-items-btn md-primary"
              @click="viewFieldsPop = true"
              >Add fields</md-button
            >
            <md-button class="edit-items-btn md-primary" @click="editOpen()"
              >Edit Items</md-button
            >
            <!-- <md-checkbox class="edit-items-btn md-primary" v-model="fav"
              >Favourites</md-checkbox
            > -->
          </div>
        </md-card-header>
        <div v-else-if="!loader">
          <md-button
            class="edit-items-btn md-primary"
            @click="viewFieldsPop = true"
            >Add fields</md-button
          >
        </div>
        <!-- <md-table
          :value="queriedData"
          class="paginated-table table-striped table-hover menu-list"
          v-if="!loader"
        >
          <md-table-row>
            <md-table-head>{{ $t('Name') }}</md-table-head>
          </md-table-row>
          <slot v-for="item in queriedData">
            <md-table-row slot="md-table-row">
              <md-table-cell>
                <div @click="viewDetail(item)">{{ item.name }}</div>
              </md-table-cell>
            </md-table-row>
          </slot>
        </md-table> -->
        <md-card-content>
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover menu-list"
            @md-selected="viewDetail"
            v-if="!loader"
          >
            <md-table-toolbar class="">
              <md-field id="filter-toolbar">
                <label for="movie" class="text-white">Filter</label>
                <md-select v-model="filterChange" name="movie" id="movie">
                  <md-option value="">No Filter </md-option>
                  <md-option value="fat_free">Favourites</md-option>
                </md-select>
              </md-field>

              <md-field class="search-toolbar">
                <label>{{ $t('Search') }}</label>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style=""
                  v-model="searchQuery"
                ></md-input>
                <i class="fa fa-search search-align" aria-hidden="true"></i>
              </md-field>
            </md-table-toolbar>
            <md-table-row>
              <md-table-head v-if="filterChange == 'fat_free'">{{
                $t('Fav Seq')
              }}</md-table-head>
              <md-table-head>
                <div @click="sortFn('item_code')" class="text-nowrap">
                  {{ $t('SKU') }}
                  <span>
                    <!-- <i v-bind:class= "['check-align', (headers.item_code.status === true && headers.item_code.sort == 'asc' ||  headers.item_code.hover == true) ? 'fa fa-arrow-up ':( headers.item_code.status === true && headers.item_code.sort == 'desc' | headers.item_code.hover == true) ? 'fa fa fa-arrow-down' : '']" ></i> -->
                    <i
                      v-bind:class="[
                        'check-align',
                        headers.item_code.status &&
                        headers.item_code.sort == 'asc'
                          ? 'fa fa-arrow-up arrow-active'
                          : headers.item_code.status
                          ? 'fa fa-arrow-down arrow-active'
                          : 'fa fa-arrow-up',
                      ]"
                    ></i>
                  </span>
                </div>
              </md-table-head>
              <md-table-head>
                <div @click="sortFn('name')">
                  {{ $t('Name') }}
                  <span>
                    <i
                      v-bind:class="[
                        'check-align',
                        headers.name.status && headers.name.sort == 'asc'
                          ? 'fa fa-arrow-up arrow-active'
                          : headers.name.status
                          ? 'fa fa-arrow-down arrow-active'
                          : 'fa fa-arrow-up',
                      ]"
                    ></i>
                  </span>
                </div>
              </md-table-head>
              <md-table-head>
                <div @click="sortFn('short_name')">
                  {{ $t('POS Short Name') }}
                  <span>
                    <i
                      v-bind:class="[
                        'check-align',
                        headers.short_name.status &&
                        headers.short_name.sort == 'asc'
                          ? 'fa fa-arrow-up arrow-active'
                          : headers.short_name.status
                          ? 'fa fa-arrow-down arrow-active'
                          : 'fa fa-arrow-up',
                      ]"
                    ></i>
                  </span>
                </div>
              </md-table-head>

              <md-table-head width="100">
                <div @click="sortFn('price')">
                  {{ $t('Sales Price') }}
                  <span>
                    <i
                      v-bind:class="[
                        'check-align',
                        headers.price.status && headers.price.sort == 'asc'
                          ? 'fa fa-arrow-up arrow-active'
                          : headers.price.status
                          ? 'fa fa-arrow-down arrow-active'
                          : 'fa fa-arrow-up',
                      ]"
                    ></i>
                  </span>
                </div>
              </md-table-head>

              <md-table-head>
                <div @click="sortFn('tax')">
                  {{ $t('Tax (%)') }}
                  <span>
                    <i
                      v-bind:class="[
                        'check-align',
                        headers.tax.status && headers.tax.sort == 'asc'
                          ? 'fa fa-arrow-up arrow-active'
                          : headers.tax.status
                          ? 'fa fa-arrow-down arrow-active'
                          : 'fa fa-arrow-up',
                      ]"
                    ></i>
                  </span>
                </div>
              </md-table-head>
              <md-table-head v-if="extraFields.take_away_price">{{
                $t('Take Away Price')
              }}</md-table-head>
              <md-table-head v-if="extraFields.take_away_tax">{{
                $t('Take Away Tax')
              }}</md-table-head>
              <md-table-head width="200" class="main-cat-cell">
                <div>
                  <md-field>
                    <label
                      class="d-flex align-items-center cursor-pointer text-nowrap"
                      @click="sortFn('main_category')"
                      >{{ $t('Main Category') }}
                      <div>
                        <span>
                          <i
                            v-bind:class="[
                              'check-align mx-10',
                              headers.main_category.status &&
                              headers.main_category.sort == 'asc'
                                ? 'fa fa-arrow-up arrow-active'
                                : headers.main_category.status
                                ? 'fa fa-arrow-down arrow-active'
                                : 'fa fa-arrow-up',
                            ]"
                          ></i>
                        </span>
                      </div>
                    </label>
                    <md-select
                      v-model="mainCatFilter"
                      v-on:change="onSelect($event)"
                      class="align-items-center"
                    >
                      <md-option
                        v-for="list in mainCatListItems"
                        v-bind:key="list"
                        :value="list"
                        >{{ list }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
              </md-table-head>
              <md-table-head v-if="extraFields.sub_category">{{
                $t('Sub Category')
              }}</md-table-head>
              <md-table-head v-if="extraFields.print_group">{{
                $t('Course')
              }}</md-table-head>
              <md-table-head v-if="extraFields.modifier">{{
                $t('Modifier')
              }}</md-table-head>
              <md-table-head v-if="extraFields.favorites">{{
                $t('Favourites')
              }}</md-table-head>
              <md-table-head v-if="extraFields.printer_assigned">{{
                $t('Printer Assigned')
              }}</md-table-head>
              <md-table-head v-if="extraFields.pos_color">{{
                $t('POS Colour Code')
              }}</md-table-head>
              <!-- <md-table-head
                v-if="extraFields.bar_code"
                @click="sortFn('item_code')"
                >{{ $t('Barcode') }}
              </md-table-head> -->
              <md-table-head v-if="extraFields.bar_code">
                <div @click="sortFn('bar_code')">
                  {{ $t('Barcode') }}
                  <span>
                    <i
                      v-bind:class="[
                        'check-align',
                        headers.bar_code.status &&
                        headers.bar_code.sort == 'asc'
                          ? 'fa fa-arrow-up arrow-active'
                          : headers.bar_code.status
                          ? 'fa fa-arrow-down arrow-active'
                          : 'fa fa-arrow-up',
                      ]"
                    ></i>
                  </span>
                </div>
              </md-table-head>
              <md-table-head>{{ $t('Status') }}</md-table-head>
              <md-table-head class="last-menu-head">{{
                $t('Actions')
              }}</md-table-head>
            </md-table-row>
            <slot v-for="item in queriedData">
              <md-table-row slot="md-table-row" :key="item.id">
                <md-table-cell v-if="filterChange == 'fat_free'">
                  <div @click="viewDetail(item)">
                    {{ item.fav_sort_order }}
                  </div>
                </md-table-cell>
                <md-table-cell>
                  <div @click="viewDetail(item)">{{ item.item_code }}</div>
                </md-table-cell>
                <md-table-cell>
                  <div @click="viewDetail(item)">{{ item.name }}</div>
                </md-table-cell>

                <md-table-cell>
                  <div @click="viewDetail(item)">{{ item.short_name }}</div>
                </md-table-cell>
                <md-table-cell>
                  <div
                    class="sales-price"
                    :id="item.id"
                    @click="
                      ;($store.state.account.outlet.allow_price_change ||
                        userType == 'branch_user') &&
                        editable(item)
                    "
                  >
                    <div v-if="showWordIndex != item.id">
                      {{ item.price ? parseFloat(item.price).toFixed(2) : 0 }}
                    </div>
                    <md-field v-if="showWordIndex === item.id">
                      <md-input
                        type="text"
                        v-model="item.price"
                        v-on:focusout="updatePrice(item)"
                      ></md-input>
                    </md-field>
                  </div>
                </md-table-cell>
                <md-table-cell>
                  <div @click="viewDetail(item)">{{ item.tax }}</div>
                </md-table-cell>
                <md-table-cell v-if="extraFields.take_away_price">
                  <div @click="viewDetail(item)">{{ item.take_out_price }}</div>
                </md-table-cell>
                <md-table-cell v-if="extraFields.take_away_tax">
                  <div @click="viewDetail(item)">{{ item.take_away_tax }}</div>
                </md-table-cell>
                <md-table-cell class="category-column">
                  <div @click="viewDetail(item)">{{ item.main_category }}</div>
                </md-table-cell>
                <md-table-cell v-if="extraFields.sub_category">
                  <div @click="viewDetail(item)">{{ item.subcategory }}</div>
                </md-table-cell>
                <md-table-cell v-if="extraFields.print_group">
                  <div @click="viewDetail(item)">{{ item.print_group }}</div>
                </md-table-cell>
                <md-table-cell v-if="extraFields.modifier">
                  <div @click="viewDetail(item)">
                    <i
                      v-if="item.modifier_groups.length > 0"
                      class="fa fa-check check-align"
                      aria-hidden="true"
                    ></i>
                    <i
                      v-if="item.modifier_groups.length <= 0"
                      class="fa fa-times close-align"
                      aria-hidden="true"
                    ></i>
                  </div>
                </md-table-cell>
                <md-table-cell v-if="extraFields.favorites">
                  <div @click="viewDetail(item)">{{ item.fat_free }}</div>
                </md-table-cell>
                <md-table-cell v-if="extraFields.printer_assigned">
                  <div @click="viewDetail(item)">
                    {{
                      item.printer_ids ? getPrinterNames(item.printer_ids) : ''
                    }}
                  </div>
                </md-table-cell>
                <md-table-cell
                  class="pos-color-column"
                  v-if="extraFields.pos_color"
                >
                  <div @click="viewDetail(item)">
                    <button
                      class="color-btn"
                      :style="{ backgroundColor: item.item_color }"
                    ></button>
                  </div>
                </md-table-cell>
                <md-table-cell v-if="extraFields.bar_code">
                  <div @click="viewDetail(item)">{{ item.bar_code }}</div>
                </md-table-cell>
                <md-table-cell>
                  <div @click="viewDetail(item)">
                    <i
                      v-bind:class="[
                        'check-align',
                        item.status === 'active' ? 'fa fa-check ' : 'fa fa-ban',
                      ]"
                      aria-hidden="true"
                    ></i>
                  </div>
                </md-table-cell>

                <md-table-cell
                  v-if="$store.state.account.profile.has_access && flag == 0"
                >
                  <div class="border-edit">
                    <md-menu
                      md-size="medium"
                      :md-offset-x="-227"
                      :md-offset-y="-16"
                    >
                      <i
                        class="material-icons text_align-center menu-icon"
                        md-menu-trigger
                        >more_vert</i
                      >
                      <md-menu-content class="menuitem-dropdown">
                        <md-menu-item class="action-header menu-align">
                          {{ $t('Actions') }}
                        </md-menu-item>
                        <router-link
                          :to="{
                            name: 'MenuItemsForm',
                            query: {
                              formType: 'Edit Menu Item',
                              client: item.id,
                              flag: flag,
                            },
                          }"
                        >
                          <md-menu-item>
                            <span class="edit-align">
                              <i class="fas fa-pencil-alt"></i>
                              {{ $t('Edit') }}
                            </span>
                          </md-menu-item>
                        </router-link>
                        <div>
                          <md-menu-item>
                            <span
                              class="edit-align"
                              @click="alert('Edit', item.id)"
                            >
                              <i class="fa fa-ban" aria-hidden="true"></i>
                              {{
                                item.status == 'active'
                                  ? $t('Deactivate')
                                  : $t('Activate')
                              }}
                            </span>
                          </md-menu-item>
                        </div>
                        <div>
                          <md-menu-item>
                            <span
                              class="edit-align"
                              @click="alert('Delete', item.url)"
                            >
                              <i class="fa fa-trash" aria-hidden="true"></i>
                              {{ $t('Delete') }}
                            </span>
                          </md-menu-item>
                        </div>
                        <div>
                          <router-link
                            :to="{
                              name: 'Integrations Options',
                              query: {
                                formType: 'Edit Integration',
                                client: item.url,
                              },
                            }"
                          >
                            <md-menu-item>
                              <span class="edit-align">
                                <i
                                  class="fas fa-bookmark"
                                  aria-hidden="true"
                                ></i>
                                {{ $t('Integrations') }}
                              </span>
                            </md-menu-item>
                          </router-link>
                        </div>
                      </md-menu-content>
                    </md-menu>
                  </div>
                </md-table-cell>
                <md-table-cell v-else>
                  <div class="border-edit">
                    <div class="border-edit">
                      <md-menu
                        md-size="medium"
                        :md-offset-x="-227"
                        :md-offset-y="-16"
                      >
                        <i
                          class="material-icons text_align-center menu-icon"
                          md-menu-trigger
                          >more_vert</i
                        >
                        <md-menu-content class="menuitem-dropdown">
                          <md-menu-item class="action-header menu-align">
                            {{ $t('Actions') }}
                          </md-menu-item>
                          <router-link
                            :to="{
                              name: 'OutletMenuItemsForm',
                              query: {
                                formType: 'Edit Menu Item',
                                client: item.id,
                                outlet_id: item.outlet_id,
                              },
                            }"
                          >
                            <md-menu-item>
                              <span class="edit-align">
                                <i class="fas fa-pencil-alt"></i>
                                {{ $t('Edit Item') }}
                              </span>
                            </md-menu-item>
                          </router-link>
                        </md-menu-content>
                      </md-menu>
                    </div>
                  </div>
                </md-table-cell>
              </md-table-row>
            </slot>
          </md-table>
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- </md-card-actions> -->
      </md-card>
    </div>

    <md-dialog :md-active.sync="modDialog" class="edit-dialog">
      <md-dialog-title>
        <div class="edit-items">Edit Items</div>
        <div class="close-icon">
          <i
            class="md-icon md-icon-font md-theme-default"
            @click="modDialog = false"
            >close</i
          >
        </div>
      </md-dialog-title>

      <md-tabs md-alignment="centered">
        <md-tab md-label="Category">
          <div class="md-layout-item text-center">
            <md-checkbox v-model="selAll"></md-checkbox>
            <label>Select All/Deselect Main Category</label>
          </div>
          <div class="modifier-checkbox">
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Select Main Category</label>
                  <md-select v-model="params.cur_item_group" multiple>
                    <md-option
                      v-for="item in mainCategory"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Tax From</label>
                  <md-select v-model="params.tax_from">
                    <md-option
                      v-for="item in taxList"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <label>Tax To</label>
                  <md-select v-model="params.tax_to">
                    <md-option
                      v-for="item in taxList"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Take Away Tax From</label>
                  <md-select v-model="params.take_away_tax_from">
                    <md-option
                      v-for="item in taxList"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <label>Take Away Tax To</label>
                  <md-select v-model="params.take_away_tax_to">
                    <md-option
                      v-for="item in taxList"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Increase By</label>
                  <md-input type="text" v-model="params.increase"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <label>Decrease By</label>
                  <md-input v-model="params.decrease" type="text"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Printer</label>
                  <md-select multiple v-model="params.printer_ids">
                    <md-option
                      v-for="item in printerList"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="printerCatCheck"></md-checkbox>
                <label>Remove Printer</label>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Course</label>
                  <md-select v-model="params.item.sub_cat_id">
                    <md-option
                      v-for="item in printerGroupList"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="printerCatGpCheck"></md-checkbox>
                <label>Remove Course</label>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Tax</label>
                  <md-select v-model="params.item.tax">
                    <md-option
                      v-for="item in taxList"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <!-- <div class="md-layout-item">
            <md-field>
              <label>menuCategory</label>
              <md-select v-model="params.item.item_group" multiple>
                <md-option
                  v-for="item in mainCategory"
                  :key="item.id"
                  :value="item.id"
                >{{ item.name }}</md-option>
              </md-select>
            </md-field>
          </div> -->
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Sub Category</label>
                  <md-select v-model="params.item.cat_id">
                    <md-option
                      v-for="item in subCategory"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <div class="form__field menu-tabl">
                  <div class="form__input top: -200px;-input">
                    <label class="form__label">POS Colour Code</label>
                    <div class="swatches-box">
                      <swatches
                        :colors="'text-advanced'"
                        v-model="params.item.item_color"
                        shapes="circles"
                        popover-to="center"
                      ></swatches>

                      <i
                        class="md-icon md-icon-font md-icon-image md-theme-default"
                      >
                        <svg
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z" />
                          <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                      </i>
                    </div>

                    <!-- <div class="custm-color-picker">{{colorCodeShow}}</div> -->
                  </div>
                </div>
              </div>
            </div>
            <md-dialog-actions class="main-actions">
              <md-button native-type="submit" @click="reset()">
                {{ $t('CANCEL') }}
              </md-button>
              <md-button
                native-type="submit"
                class="md-primary"
                @click="menuCatEdit()"
                >{{ $t('SAVE') }}</md-button
              >
            </md-dialog-actions>
          </div>
        </md-tab>

        <!-- <md-tab md-label="Items">
          <div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Increase By</label>
                  <md-input
                    type="text"
                    v-model="itemPara.item.increase"
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <label>Decrease By</label>
                  <md-input
                    type="text"
                    v-model="itemPara.item.decrease"
                  ></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Printer</label>
                  <md-select multiple v-model="itemPara.printer_ids">
                    <md-option
                      v-for="item in printerList"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="printerCheck"></md-checkbox>
                <label>Remove Printer</label>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Course</label>
                  <md-select v-model="itemPara.item.sub_cat_id">
                    <md-option
                      v-for="item in printerGroupList"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="printerGpCheck"></md-checkbox>
                <label>Remove Course</label>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Tax</label>
                  <md-select v-model="itemPara.item.tax">
                    <md-option
                      v-for="item in taxList"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item">
                <md-field>
                  <label>Select Main Category</label>
                  <md-select v-model="itemPara.item.item_group" multiple>
                    <md-option
                      v-for="item in mainCategory"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item">
                <md-field>
                  <label>Sub Category</label>
                  <md-select v-model="itemPara.item.cat_id">
                    <md-option
                      v-for="item in subCategory"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <div class="form__field menu-tabl">
                  <div class="form__input swatches-input">
                    <label class="form__label">POS Colour Code</label>
                    <div class="swatches-box">
                      <swatches
                        :colors="'text-advanced'"
                        v-model="itemPara.item.item_color"
                        shapes="circles"
                        popover-to="center"
                      ></swatches>

                      <i
                        class="md-icon md-icon-font md-icon-image md-theme-default"
                      >
                        <svg
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z" />
                          <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                      </i>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <md-dialog-actions class="main-actions">
              <md-button native-type="submit" @click="reset()">
                {{ $t('CANCEL') }}
              </md-button>
              <md-button
                native-type="submit"
                class="md-primary"
                @click="menuItEdit()"
                >{{ $t('SAVE') }}</md-button
              >
            </md-dialog-actions>
          </div>
        </md-tab> -->
      </md-tabs>
    </md-dialog>

    <md-dialog :md-active.sync="viewFieldsPop">
      <md-dialog-title>
        <div class="dialog-title">Add fields</div>
      </md-dialog-title>
      <div class="md-layout add-field-pop">
        <md-list>
          <md-list-item>
            <md-checkbox v-model="extraFields.sub_category"
              >Sub Category</md-checkbox
            >
          </md-list-item>
          <md-list-item>
            <md-checkbox v-model="extraFields.print_group"
              >Print Group</md-checkbox
            >
          </md-list-item>
          <md-list-item>
            <md-checkbox v-model="extraFields.modifier">Modifier</md-checkbox>
          </md-list-item>
          <md-list-item>
            <md-checkbox v-model="extraFields.favorites">Favorites</md-checkbox>
          </md-list-item>
          <md-list-item>
            <md-checkbox v-model="extraFields.printer_assigned"
              >Printer Assigned</md-checkbox
            >
          </md-list-item>
          <md-list-item>
            <md-checkbox v-model="extraFields.pos_color"
              >POS Colour Code</md-checkbox
            >
          </md-list-item>

          <md-list-item>
            <md-checkbox v-model="extraFields.bar_code">Barcode</md-checkbox>
          </md-list-item>

          <md-list-item>
            <md-checkbox v-model="extraFields.take_away_price"
              >Take Away Price</md-checkbox
            >
          </md-list-item>
          <md-list-item>
            <md-checkbox v-model="extraFields.take_away_tax"
              >Take Away Tax</md-checkbox
            >
          </md-list-item>
        </md-list>
      </div>
    </md-dialog>

    <!-- Price Levels -->

    <md-dialog :md-active.sync="showPriceLevels">
      <h4>Price Levels</h4>
      <md-dialog-content>
        <div class="md-layout">
          <md-field>
            <label> Price</label>
            <md-input type="text" v-model="activeItem.price"></md-input>
          </md-field>
          <div v-for="element in priceLevels" :key="element.id">
            <md-field>
              <label> {{ element.name }}</label>
              <md-input
                type="text"
                v-model="element.s_price"
                :disabled="formType === 'View Menu Item'"
              ></md-input>
            </md-field>
          </div>
        </div>
      </md-dialog-content>
      <h4>Take Out Price Levels</h4>

      <md-dialog-content>
        <div v-for="element in priceLevels" :key="element.id">
          <md-field>
            <label> {{ element.name }}</label>
            <md-input
              type="text"
              v-model="element.t_price"
              :disabled="formType === 'View Menu Item'"
            ></md-input>
          </md-field>
        </div>
      </md-dialog-content>
      <div>
        <md-button
          native-type="submit"
          class="md-primary"
          @click="updatePrice(activeItem)"
          >{{ $t('SAVE') }}</md-button
        >
      </div>
    </md-dialog>

    <!-- Outlet Menus -->

    <md-dialog :md-active.sync="showOutletPrices" class="outlet-menu-prices">
      <md-dialog-content class="outlet-menu-block outlet-menu-width">
        <div class="md-layout mb-25">
          <div class="md-layout-item d-flex flex-column align-items-center">
            <h5 class="font-weight-bold mt-0 mb-4">{{ itemInfo.name }}</h5>
            <table class="tables table-bordered" width="100%">
              <tr>
                <th style="padding: 0">Outlet</th>
                <th style="padding: 0">Price</th>
              </tr>
              <tr v-for="menu in outletMenus" :key="menu.id">
                <!-- <td><input type="checkbox" id="checkbox" />{{ outlet.name }}</td> -->
                <td>
                  {{ menu.outlet_name }}
                </td>
                <td>
                  <md-field class="mt-0">
                    <md-input v-model="menu.price" type="text"></md-input>
                  </md-field>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="md-layout-item md-small-size-100 toggle-price">
          <md-switch v-model="appyHeadOffice" @change="togglePrice"
            >Apply Head Office Price({{
              parseFloat(itemInfo.price).toFixed(2)
            }})</md-switch
          >
        </div>
        <div class="d-flex justify-content-center">
          <md-button
            native-type="submit"
            @click="showOutletPrices = false"
            class="mr-2"
            >{{ $t('Cancel') }}</md-button
          >
          <md-button
            native-type="submit"
            class="md-primary"
            @click="submitOutletMenuItem()"
            >{{ $t('SAVE') }}</md-button
          >
        </div>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>
<script>
import { Pagination } from '@/components'
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import axios from 'axios'
import Swatches from 'vue-swatches'
import $ from 'jquery'
import 'jquery-ui-dist/jquery-ui'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { Tabs } from '@/components'
import store from '@/store'
import { accountService } from '../../../services/account'
import Sortable from 'sortablejs'

export default {
  components: {
    Pagination,
    Swatches,
    ClipLoader,
    Tabs,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    queriedData() {
      
    if(this.searchQuery != ''){
      this.searchInitiate('name', this.searchQuery)
    }

      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData.filter(
          (item) => item.status == this.activetab
        )
      }

      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      formType: '',
      modDialog: false,
      editItemsClick: true,
      itemClick: false,
      printerCheck: false,
      printerCatCheck: false,
      printerCatGpCheck: false,
      printerGpCheck: false,
      filterMainC: '',
      mainCategory: [],
      printerList: [],
      menuItemList: [],
      taxList: [],
      subCategory: [],
      printerGroupList: [],
      maxSku: 0,
      showWordIndex: null,
      outletDetails: {},
      params: {
        item: {},
      },
      itemPara: {
        item: {},
      },
      currentSort: 'item_code',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      headers: {
        fav_sort_order: { status: true, sort: 'asc' },
        item_code: { status: true, sort: 'asc' },
        name: { status: false, sort: 'asc' },
        short_name: { status: false, sort: 'asc' },
        price: { status: false, sort: 'asc' },
        tax: { status: false, sort: 'asc' },
        main_category: { status: false, sort: 'asc' },
        bar_code: { status: false, sort: 'asc' },
      },
      // footerTable: ["Name", "Email", "Age", "Salary", "Actions"],
      searchQuery: '',
      mainCatFilter: 'All',
      propsToSearch: ['name'],
      tableData: [],
      tempData: [],
      activetab: 'active',
      searchedData: [],
      fuseSearch: null,
      loader: false,
      mainCatListItems: [],
      viewFieldsPop: false,
      extraFields: {
        sub_category: false,
        print_group: false,
        modifier: false,
        favorites: false,
        printer_assigned: false,
        pos_color: false,
        bar_code: false,
        take_away_price: false,
        take_away_tax: false,
      },
      priceLevels: [],
      tempPricelevels: [],
      showPriceLevels: false,
      activeItem: {},
      flag: 0,
      showOutletPrices: false,
      outletMenus: [],
      itemInfo: {},
      appyHeadOffice: false,
      tempOutletMenus: [],
      selAll: false,
      fav: false,
      tableSort: '',
      filterChange: '',
      userType: accountService.userRole(),
    }
  },
  methods: {
    editOpen() {
      this.selAll = false
      this.modDialog = true
      this.params = {
        item: {},
      }
    },
    getPrinterNames(ids) {
      const matchingItems = this.printerList.filter((item) =>
        ids.includes(item.id)
      )
      const names = matchingItems.map((item) => item.name).join(', ')
      return names
    },
    togglePrice(event) {
      if (this.appyHeadOffice) {
        this.outletMenus.map((el) => (el.price = this.itemInfo.price))
      } else {
        this.outletMenus = JSON.parse(JSON.stringify(this.tempOutletMenus))
      }
    },
    mainCatList() {
      // let filtered = this.tableData.map((record) => record.main_category)
      // this.mainCatListItems = filtered.filter((value, index, self) => {
      //   return self.indexOf(value) === index
      // })

      this.mainCatListItems = this.mainCategory
        .filter((el) => el.status == true)
        .map((record) => record.name)

      this.mainCatListItems.unshift('All')
    },
    customSort(value) {
      this.searchedData = this.tableData
      return this.tableData.sort((a, b) => {
        const sortBy = this.currentSort
        if (a[sortBy] == null) {
          return 1
        }
        if (b[sortBy] == null) {
          return -1
        }
        if (this.currentSortOrder === 'desc') {
          return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, { numeric: true })
        }
        return a[sortBy]
          .toString()
          .localeCompare(b[sortBy].toString(), undefined, { numeric: true })
      })
    },
    sortFn(value) {
      let data = this.searchedData
      for (const [key, val] of Object.entries(this.headers)) {
        if (key == value) {
          this.headers[key]['sort'] =
            this.headers[key]['sort'] == 'asc' ? 'desc' : 'asc'
          this.headers[key]['status'] = true
        } else {
          this.headers[key]['status'] = false
        }
      }
      this.currentSortOrder = this.headers[value].sort
      data.sort((a, b) => {
        const sortBy = value

        // Helper function to determine if a value is empty or null
        const isEmptyOrNull = (val) =>
          val === null || val === undefined || val.toString().trim() === ''

        // Convert values to integers if sortBy is 'bar_code', otherwise to strings
        const convertValue = (val) =>
          sortBy === 'bar_code' ? parseInt(val) : val.toString()

        if (isEmptyOrNull(a[sortBy])) {
          return 1
        }
        if (isEmptyOrNull(b[sortBy])) {
          return -1
        }

        const valueA = convertValue(a[sortBy])
        const valueB = convertValue(b[sortBy])

        if (this.currentSortOrder === 'desc') {
          return sortBy === 'bar_code'
            ? valueB - valueA
            : valueB.localeCompare(valueA, undefined, { numeric: true })
        }
        return sortBy === 'bar_code'
          ? valueA - valueB
          : valueA.localeCompare(valueB, undefined, { numeric: true })
      })
    },

    mouseOver(value) {
      if (!this.headers[value].status) {
        this.headers[value].hover = !this.headers[value].hover
      }
    },
    importMenuItem() {
      this.$router.push({
        name: 'MenuItemImport',
      })
    },
    editable(item) {
      this.showWordIndex = item.id

      // if (store.state.account.profile.user_type == 'user') {
      //   this.activeItem = item
      //   if (this.priceLevels.length) {
      //     this.showPriceLevels = true
      //     if (item.price_levels) {
      //       this.priceLevels = item.price_levels.map((x) =>
      //         Object.assign(
      //           x,
      //           this.priceLevels.find((y) => y.id == x.id)
      //         )
      //       )
      //     } else {
      //       this.priceLevels = this.tempPricelevels
      //     }
      //   } else {
      //     this.showWordIndex = item.id
      //   }
      // } else {
      //   this.showWordIndex = item.id
      // }
    },
    updatePrice(item) {
      let outlet_id = ''
      if (this.$route.query.client) {
        outlet_id = this.$route.query.client
      } else {
        outlet_id = accountService.getOutletId()
      }
      let body = {
        menu_items: {
          price: item.price,
          outlet_id: outlet_id,
          chain_id: accountService.getChainId(),
          price_levels: this.priceLevels,
        },
      }

      customizeService.updateMenuItemPriceById(item.id, body).then((result) => {
        this.showWordIndex = null
        if (accountService.userRole() == 'branch_user') {
          this.showOutletPrices = true
          this.appyHeadOffice = false
          customizeService
            .getOutletMenuItemById(item.id, outlet_id)
            .then((resp) => {
              this.itemInfo = item
              this.outletMenus = resp.data
              this.tempOutletMenus = JSON.parse(
                JSON.stringify(this.outletMenus)
              )
            })
        }
      })
    },

    submitOutletMenuItem() {
      customizeService
        .updateOutletMenuItemById(this.outletMenus)
        .then((resp) => {
          this.showOutletPrices = false
          this.itemInfo = {}
          this.outletMenus = []
          this.appyHeadOffice = false
        })
    },

    alert(value, url) {
      var self = this
      if (value === 'Delete') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.deleteMenuItems(url)
          }
        })
      } else if (value === 'Edit') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.updateItemStatus(url)
          }
        })
      }
    },

    deleteMenuItems(url) {
      axios.delete(url, customizeService.authFun()).then((res) => {
        this.getItems()
      })
    },

    viewDetail(item) {
      if (store.state.account.profile.has_access && this.flag == 0) {
        this.$router.push({
          name: 'MenuItemsForm',
          query: {
            formType: 'Edit Menu Item',
            client: item.id,
            flag: this.flag,
          },
        })
      } else {
        this.$router.push({
          name: 'OutletMenuItemsForm',
          query: {
            formType: 'Edit Menu Item',
            client: item.id,
            outlet_id: item.outlet_id,
          },
        })
      }
    },

    getItems() {
      var self = this
      this.loader = true
      ;(this.params = { item: {} }),
        customizeService
          .getMenuItems(
            this.$route.query.formType,
            this.$route.query.client,
            this.$route.params.items
          )
          .then((res) => {
            this.loader = false
            self.tempData = JSON.parse(JSON.stringify(res.data.menu_items))
            const maxPeak = self.tempData
              .filter(
                (element) =>
                  element.name != 'Voucher' && element.menu_type == 'normal'
              )
              .reduce((p, c) => (p.item_code > c.item_code ? p : c))

            this.maxSku = self.tempData
              .filter(
                (element) =>
                  element.name != 'Voucher' && element.menu_type == 'normal'
              )
              .reduce(
                (prev, current) =>
                  prev.item_code > current.item_code ? prev : current,
                0
              )

            self.tableData = self.tempData.filter(
              (item) => item.status == 'active'
            )

            // self.tableData.map

            this.mainCatList()
            this.getPriceLevels()
          })
    },
    getmainCategory() {
      customizeService.getMaincat().then((res) => {
        this.mainCategory = res.data.api_v1_main_category
      })
    },
    printer() {
      customizeService.getStation().then((res) => {
        this.printerList = res.data.api_v1_station
      })
    },
    printerGroup() {
      customizeService.getCourse().then((res) => {
        this.printerGroupList = res.data
      })
    },
    getTax() {
      customizeService.getTaxes().then((res) => {
        this.taxList = res.data.result[0].tax.taxes
      })
    },
    getsubCategory() {
      customizeService.getSubcat().then((res) => {
        this.subCategory = res.data.api_v1_sub_category
      })
    },
    menuCatEdit() {
      let html = "You won't be able to revert this!"
      if (this.params.tax_to != '' && this.params.tax_to != undefined) {
        html += `<br/>Tax from ${this.params.tax_from} to ${this.params.tax_to} `
      }
      if (
        this.params.take_away_tax_to != '' &&
        this.params.take_away_tax_to != undefined
      ) {
        html += `<br/>Take Away Tax from ${this.params.take_away_tax_from} to ${this.params.take_away_tax_to} `
      }
      Swal.fire({
        title: 'Are you sure?',
        html: html,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'md-button md-success btn-fill',
        cancelButtonClass: 'md-button md-danger btn-fill',
        confirmButtonText: 'Yes, Update it!',
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (this.printerCatCheck == true) {
            this.params.item.printer_ids = []
          }
          if (this.printerCatGpCheck == true) {
            this.params.item.sub_cat_id = ''
          }
          let outlet_id = ''

          if (
            accountService.userRole() == 'branch_user' &&
            this.$route.query.client
          ) {
            outlet_id = this.$route.query.client
          } else if (accountService.userRole() == 'user') {
            outlet_id = accountService.getOutletId()
          }
          customizeService
            .updateMenuItems(this.params, outlet_id)
            .then((res) => {
              this.modDialog = false
              this.getItems()
              // window.location.reload()
            })
        }
      })
    },

    menuItEdit() {
      if (this.printerCheck == true) {
        this.params.item.printer_ids = []
      }
      if (this.printerGpCheck == true) {
        this.params.item.sub_cat_id = ''
      }
      let outlet_id = ''
      if (
        accountService.userRole() == 'branch_user' &&
        this.$route.query.client
      ) {
        outlet_id = this.$route.query.client
      } else if (accountService.userRole() == 'user') {
        outlet_id = accountService.getOutletId()
      }
      customizeService.updateMenuItems(this.itemPara, outlet_id).then((res) => {
        this.modDialog = false
        this.getItems()
        // window.location.reload()
      })
    },

    getPriceLevels() {
      customizeService
        .getPriceLevels()
        .then((res) => {
          this.priceLevels = res.data
          this.tempPricelevels = JSON.parse(JSON.stringify(res.data))
        })
        .catch((err) => console.log(err))
    },

    itemPriceUpdate(id, price) {
      let body = {
        menu_items: {
          price: price,
        },
      }
      customizeService.updateMenuItemById(id, body).then((res) => {})
    },

    reset() {
      this.modDialog = false
      this.params = {
        item: {},
      }
      this.itemPara = {
        item: {},
      }
    },
    searchInitiate(stringToCompare, dataToCompare) {
      let result = new Fuse(this.tableData, {
        keys: [stringToCompare],
        threshold: 0.2,
        findAllMatches: true,
      }).search(dataToCompare)
      this.searchedData = result.map((el) => el.item)
    },

    updateItemStatus(id) {
      let item = this.tempData.find((element) => element.id == id)
      let status = item.status == 'active' ? 'not_active' : 'active'
      let body = {
        menu_items: { status: status, outlet_id: item.outlet_id },
      }

      customizeService.updateMenuItemById(id, body).then((result) => {
        item.status = status
        if (this.activetab != null) {
          this.filterBystatus(this.activetab)
        } else {
          this.tableData = this.tempData
        }
      })
    },
    filterBystatus(status) {
      this.activetab = status
      this.searchedData = []
      this.tableData = this.tempData.filter((item) => item.status == status)
    },

    updateInput(event) {
      let status = event.target.checked ? 'active' : 'not_active'
      this.filterBystatus(status)
    },

    updateItemOrder: function () {
      this.currentSort = 'fav_sort_order'
      // this.sortFn('fav_sort_order')
      // get your info then...
      let sort_order_json = { menu_items: {} }
      this.tableData.map(function (item, index) {
        item.fav_sort_order = index + 1
        sort_order_json['menu_items'][item.id] = {
          fav_sort_order: index + 1,
        }
      })
      // this.sortFn('fav_sort_order')
      customizeService.update_categories(sort_order_json, 'menu')
    },

    setFilters() {
      let filters = {
        extraFields: this.extraFields,
        searchQuery: this.searchQuery,
      }
      // Store the combined object in sessionStorage
      sessionStorage.setItem('filters', JSON.stringify(filters))
    },
    getFilters() {
      let filters = JSON.parse(sessionStorage.getItem('filters'))

      if (filters) {
        // If filters exist in sessionStorage, reassign them to your component's data
        this.extraFields = filters.extraFields || {} // Reassign extraFields if they exist
        this.searchQuery = filters.searchQuery || '' // Reassign searchQuery if it exists
      }
    },
  },
  mounted() {
    let vm = this
    $('.paginated-table').on('click', '.sales-price', function () {
      let currentElement = $(this)
      let parentId = currentElement.attr('id')
      $(currentElement).html(
        '<input class="sales-input" type="text" value="' +
          currentElement.text().trim() +
          '" />'
      )
      $('.sales-input').focus()

      $('.sales-input').on('focusout', function (event) {
        var inputVal = $('.sales-input').val()
        $('.sales-input').remove()
        $(currentElement).html(inputVal)
        vm.itemPriceUpdate(parentId, inputVal)
      })
    })
  },

  created() {
    this.getItems()
    this.getmainCategory()
    this.getsubCategory()
    this.printerGroup()
    this.printer()
    this.getTax()
    this.getFilters()

    // If searchQuery is already present after restore, trigger the search manually

    if (this.$route.query.client) {
      customizeService.getOutletById(this.$route.query.client).then((res) => {
        this.outletDetails = res.data.outlet
        this.flag = 1
      })
    }
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

    extraFields: {
      handler(newValue, oldValue) {
        let info = { fields: newValue }
        this.setFilters()
      },
      deep: true, // Enable deep watching for nested properties
    },
    mainCatFilter() {
      if (this.mainCatFilter != 'All')
        this.searchInitiate('main_category', this.mainCatFilter)
      else this.searchedData = this.tableData
    },
    searchQuery(value) {
      if (value !== '') this.searchInitiate('name', this.searchQuery)
      else this.searchedData = this.tableData
      this.setFilters()
    },
    selAll(val) {
      if (val) {
        this.params.cur_item_group = this.mainCategory.map((el) => el.id)
      } else {
        this.params.cur_item_group = []
      }
    },

    filterChange(val) {
      if (val != '') {
        this.searchedData = []
        this.currentSort = 'fav_sort_order'
        let key = ''

        this.tableData = this.tableData.filter(
          (item) => item[val] == true && item.status == this.activetab
        )
        this.customSort('fav_sort_order')

        let table = document.querySelector('.menu-list tbody')
        const _self = this
        this.tableSort = Sortable.create(table, {
          chosenClass: 'chosen',
          onEnd({ newIndex, oldIndex, item }) {
            let newTableIndex = _self.from + newIndex
            let oldTableIndex = _self.from + oldIndex

            const rowSelected = _self.tableData.splice(oldTableIndex - 1, 1)[0] // Get the selected row and remove it
            _self.tableData.splice(newTableIndex - 1, 0, rowSelected) // Move it to the new index
            _self.updateItemOrder()
          },
        })
      } else {
        this.filterBystatus(this.activetab)
        this.tableSort.destroy()
      }
    },
  },
}
</script>
<style>
.main-cat-cell .md-table-head-label {
  width: 100%;
}
.swatches-box .vue-swatches {
  position: unset !important;
}
.md-dialog-container .vue-swatches__container {
  right: 20px !important;
}
.swal2-container {
  z-index: 4 !important;
}
.edit-dialog {
  z-index: 3 !important;
}
.edit-dialog .md-dialog-container {
  overflow-y: auto !important;
  min-height: 500px;
  max-height: 100%;
  width: 600px !important;
}
.md-dialog-overlay {
  z-index: 2 !important;
}
.md-dialog-container .add-field-pop .md-checkbox-label {
  color: #000000 !important;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}
.edit-dialog {
  overflow: auto;
  min-height: 720px;
}
.align {
  position: absolute;
  right: 0;
  top: 0;
}
.md-dialog-container .md-dialog-title {
  position: relative;
  padding-top: 10px;
}
.md-dialog-container .md-dialog-title .close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.md-dialog-container .edit-items {
  text-align: center;
  margin-top: 0;
}
.btnCls {
  text-align: center;
  padding-top: 21px;
}
.switch-btn-cls {
  height: 33px;
  width: 90px;
  margin-right: 3px;
  background-color: #ececec;
  /* -webkit-appearance: none; */
  border: 0;
}
.md-dialog-actions {
  display: flex;
  justify-content: center;
  padding-top: 31px;
}
.md-table-head >>> .md-table-head-label {
  margin-right: 10px;
  /* overflow: visible !important; */
  padding: 25px 0 !important;
}
.edit-items {
  margin-top: 20px;
  margin-right: 20px;
}
.swatches-input .swatches-box .vue-swatches__trigger {
  width: 42px !important;
  height: 30px !important;
}
.swatches-input {
  display: flex;
  padding-top: 15px;
}
.swatches-input label {
  margin-top: 11px;
}
.swatches-box {
  width: 80px;
  margin-left: 20px;
  float: right;
}

.swatches-box .md-icon {
  float: right;
}
.title {
  float: left;
}
.edit-items-btn {
  float: right !important;
  margin-top: 22px;
  margin-right: 20px !important;
  width: 78px;
  height: 24px;
}
.form__input {
  padding-top: 22px;
}
.color-btn {
  height: 22px;
  width: 22px;
  border: solid 1px #eeeeee;
  border-radius: 3px;
}

.active-inactive-btn li {
  list-style: none;
  float: right;
}

.active-inactive-btn li .md-button {
  background-color: white !important;
  color: black !important;
  height: 30px;
  margin-top: 16px;
  margin-right: 5px;
}

.active {
  border-bottom: 2px solid #2b82be !important;
}
.fa-ban {
  color: red;
}
.fa-pencil-alt,
.fa-clone,
.fa-ban,
.fa-trash {
  padding-right: 0;
}

.active-inactive-btn
  .md-button:not(.md-just-icon):not(.md-btn-fab):not(.md-icon-button):not(
    .md-toolbar-toggle
  )
  .md-ripple {
  padding: 0;
}
/* .md-table-head::after{
  font-family: "FontAwesome";
  content: "\f062";
} */

.md-table-head span i {
  opacity: 0;
  display: none;
}
.md-table-head:hover span i {
  opacity: 1;
  /*transform: rotate(180deg);*/
}
/*.md-table-head span .fa-active {
   opacity: 1;
   transform: rotate(180deg);

}*/
.md-table-head span .arrow-active {
  opacity: 1;
  display: inline-block;
}
.menu-table .category-column .md-table-cell-container {
  text-align: center !important;
}

.fa-arrow-up,
.fa-arrow-down {
  color: black;
}
.edit-dialog .md-dialog-container {
  transform: translate(0%, 0%) !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px;
}
.search-toolbar {
  width: 350px !important;
}
#filter-toolbar {
  margin-right: 25px !important;
}

.chosen {
  border: 2px solid !important;
  border-bottom: 2px solid !important;
}
</style>
