var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"md-layout-item"},[_c('md-card',{staticClass:"block-bg"},[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-green md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-70"},[_c('span',{staticClass:"arrow_left"},[_c('router-link',{attrs:{"to":{ path: '/products/screen-design/menu-list' }}},[_c('img',{attrs:{"src":require("../../../assets/images/left.svg")}})])],1),_c('h3',[_vm._v(_vm._s(_vm.formType))])]),(_vm.formType == 'View Station')?_c('div',{staticClass:"md-layout-item md-size-30"},[_c('i',{staticClass:"md-icon md-icon-font md-theme-default edit-img",on:{"click":_vm.editSupercat}},[_vm._v("edit")])]):_vm._e()]),(_vm.loader)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{attrs:{"loading":_vm.loader,"color":'#00E0DB'}})],1):_vm._e(),(!_vm.loader)?_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-50"},[_c('md-field',{class:[
              { 'md-error': _vm.errors.has('Name') },
              { 'md-valid': !_vm.errors.has('Name') && _vm.touched.Name } ]},[_c('label',[_vm._v(_vm._s(_vm.$t('Name')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.Name),expression:"modelValidations.Name"}],attrs:{"data-vv-name":"Name","type":"text","disabled":_vm.formType == 'View Station'},model:{value:(_vm.screen.name),callback:function ($$v) {_vm.$set(_vm.screen, "name", $$v)},expression:"screen.name"}}),_c('slide-y-down-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Name')),expression:"errors.has('Name')"}],staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errors.first('Name'))+" ")])]),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('Name') && _vm.touched.Name),expression:"!errors.has('Name') && touched.Name"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-size-50"},[(
              _vm.userType != 'branch_user' &&
              _vm.$store.state.account.profile.has_access
            )?_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t('Is Default ?')))]),_c('md-checkbox',{model:{value:(_vm.screen.is_default),callback:function ($$v) {_vm.$set(_vm.screen, "is_default", $$v)},expression:"screen.is_default"}})],1):_vm._e()]),_c('div',{staticClass:"md-layout-item md-size-20"},[_c('md-checkbox',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: !_vm.screen.is_till,
              at_least_one_selected: { screen: _vm.screen },
            }),expression:"{\n              required: !screen.is_till,\n              at_least_one_selected: { screen },\n            }"}],attrs:{"disabled":_vm.formType == 'Edit Menu' && !_vm.screen.is_handheld && _vm.screen.is_till,"data-vv-name":"handheld"},model:{value:(_vm.screen.is_handheld),callback:function ($$v) {_vm.$set(_vm.screen, "is_handheld", $$v)},expression:"screen.is_handheld"}}),_c('label',[_vm._v(_vm._s(_vm.$t('Handheld ?')))])],1),_c('div',{staticClass:"md-layout-item md-size-50"},[_c('md-checkbox',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: !_vm.screen.is_handheld,
              at_least_one_selected: { screen: _vm.screen },
            }),expression:"{\n              required: !screen.is_handheld,\n              at_least_one_selected: { screen },\n            }"}],attrs:{"disabled":_vm.formType == 'Edit Menu' && !_vm.screen.is_handheld && _vm.screen.is_till,"data-vv-name":"till"},model:{value:(_vm.screen.is_till),callback:function ($$v) {_vm.$set(_vm.screen, "is_till", $$v)},expression:"screen.is_till"}}),_c('label',[_vm._v(_vm._s(_vm.$t('Till ?')))])],1),(_vm.errors.has('till') || _vm.errors.has('handheld'))?_c('div',{staticClass:"error md-layout-item md-size-100 pb-10"},[_vm._v(" At least one option must be selected. ")]):_vm._e(),(
            _vm.formType == 'Edit Menu' && !_vm.screen.is_handheld && _vm.screen.is_till
          )?_c('div',{staticClass:"alert alert-custom alert-with-icon md-layout-item md-size-50",attrs:{"data-notify":"container"}},[_c('i',{staticClass:"material-icons",attrs:{"data-notify":"icon"}},[_vm._v("add_alert")]),_c('span',{attrs:{"data-notify":"message"}},[_vm._v("The menu page configuration is not compatible with handheld.")])]):_vm._e()]),_c('div',{staticClass:"md-layout"}),_c('div',{staticClass:"md-layout"}),(_vm.screen.name == 'Receipt')?_c('div',{staticClass:"md-layout"}):_vm._e(),(_vm.screen.name == 'Receipt')?_c('div',{staticClass:"md-layout"}):_vm._e(),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-50"},[_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t('Status')))]),_c('md-select',{attrs:{"disabled":_vm.formType == 'View Station'},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('md-option',{staticClass:"first-opt",attrs:{"disabled":""}},[_vm._v("Status")]),_c('md-option',{attrs:{"value":"active"}},[_vm._v(_vm._s(_vm.$t('Active')))]),_c('md-option',{attrs:{"value":"inactive"}},[_vm._v(_vm._s(_vm.$t('Inactive')))])],1)],1)],1)]),(_vm.formType !== 'View Station')?_c('div',{staticClass:"btn-div"},[_c('md-card-actions',{staticClass:"text-center"},[_c('md-button',{attrs:{"native-type":"submit","to":{ path: '/products/screen-design/menu-list' }}},[_vm._v(_vm._s(_vm.$t('CANCEL')))]),_c('md-button',{staticClass:"md-primary",attrs:{"disabled":!_vm.$store.state.account.profile.has_access},on:{"click":_vm.validate}},[_vm._v(_vm._s(_vm.$t('SAVE')))])],1)],1):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }