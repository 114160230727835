<template>
  <div class="business-hours-container">
    <div class="order-type-details">
      <md-checkbox
        v-model="dineinStatus"
        @change="check($event, 'dinein')"
        class="order-type-details-dinein"
        v-if="online_store_settings.store['dinein'].allow_dinein"
        :disabled="true"
        >DINE IN</md-checkbox
      >
      <md-checkbox
        class="order-type-details-collection"
        v-model="collectionStatus"
        @change="check($event, 'collection')"
        :disabled="true"
        v-if="online_store_settings.store['collection'].allow_collection"
        >COLLECTION</md-checkbox
      >
      <md-checkbox
        v-model="deliveryStatus"
        @change="check($event, 'delivery')"
        class="order-type-details-dinein"
        :disabled="true"
        v-if="
          online_store_settings.store['delivery'] &&
          online_store_settings.store['delivery'].allow_delivery
        "
        >DELIVERY</md-checkbox
      >
    </div>

    <business-hours-day
      v-for="(day, index) in days"
      ref="businessHours"
      @hours-change="hoursChange"
      :key="index"
      :day="day"
      :hours="hours[day]"
      :name="name"
      :time-increment="timeIncrement"
      :type="type"
      :color="color"
      :bg-color="oddOrEven(index)"
      :localization="localization"
      :switch-width="switchWidth"
      :hour-format24="hourFormat24"
      :collection-status="collectionStatus"
      :delivery-status="deliveryStatus"
      :dinein-status="dineinStatus"
      :settings="online_store_settings.store"
    ></business-hours-day>
    <!-- <test
      v-for="(day, index) in days"
      :hours="hours[day]"
      :day="day"
      :key="index"
    ></test> -->
    <!-- <div class="md-layout-item md-size-50">
      <md-field>
        <label>{{ $t('Time Interval (In Minutes)') }}</label>
        <md-input
          type="number"
          v-model="online_store_settings.store.time_interval"
        ></md-input>
      </md-field>
    </div> -->

    <!-- <h4 class="d-inline-flex">
      Holiday
      <md-icon class="add-icon mt-0 cursor-pointer" @click.native="addEvent()"
        >add</md-icon
      >
    </h4> -->
    <!-- <div
      class="md-layout-item md-size-75 position-relative pad-20px block-bg mb-25"
      v-for="(holiday, index) in holidayHours"
      :key="'holiday-' + index"
    >
      <md-icon
        class="remove-icon cursor-pointer"
        @click.native="removeEvent(index)"
        >close</md-icon
      > -->

    <!-- commented <holiday
        ref="holiday"
        :localization="localization"
        :hour-format24="hourFormat24"
        class="holiday-box"
      ></holiday> -->
    <!-- <div class="md-layout-item left-padding">
        <md-card class="left-padding">
          <md-field>
            <label>{{ $t('Event Name') }}</label>
            <md-input type="text" v-model="holiday.event"></md-input>
          </md-field>
          <label>{{ $t('Date') }}</label>
          <datetime
            class="datetime-custm"
            format="YYYY-MM-DD"
            v-model="holiday.event_date"
            @change="changEvent()"
          ></datetime>
        </md-card>
      </div>
      <md-checkbox v-model="holiday.dinein">DINE IN</md-checkbox>
      <md-checkbox v-model="holiday.collection">COLLECTION</md-checkbox>
      <md-checkbox v-model="holiday.delivery">DELIVERY</md-checkbox>
      <business-hours-day
        v-if="holiday.event_date"
        ref="holidyBusinessHours"
        @hours-change="hoursChange"
        :key="index"
        :day="holiday.event_date"
        :hours="holiday.day"
        :name="name"
        :time-increment="timeIncrement"
        :type="type"
        :color="color"
        :bg-color="oddOrEven(index)"
        :localization="localization"
        :switch-width="switchWidth"
        :hour-format24="hourFormat24"
        :collection-status="holiday.collection"
        :delivery-status="holiday.delivery"
        :dinein-status="holiday.dinein"
      ></business-hours-day>
    </div> -->

    <div class="btn-div btn-wrapper-holiday text-center">
      <md-button
        class="md-button md-primary md-theme-default"
        @click="SaveChanges"
      >
        SAVE CHANGES
      </md-button>
    </div>
  </div>
</template>

<script>
import BusinessHoursDay from './BusinessHoursDay.vue'
import { customizeService } from '../../services/customize'
import { accountService } from '../../services/account'
import Holiday from '@/components/BusinessHours/Holiday.vue'
import Swal from 'sweetalert2'

import datetime from 'vuejs-datetimepicker'

export default {
  name: 'BusinessHours',
  components: {
    BusinessHoursDay,
    Holiday,
    datetime,
  },
  props: {
    days: {
      type: Array,
      required: true,
    },
    holidayList: {
      type: Array,
      required: true,
    },

    hours: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'businessHours',
    },
    settings: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'datalist',
      validator: function (value) {
        return ['datalist', 'select'].indexOf(value) !== -1
      },
    },
    timeIncrement: {
      type: Number,
      default: 30,
      validator: function (value) {
        return [15, 30, 60].indexOf(value) !== -1
      },
    },
    color: {
      type: String,
      default: '#2779bd',
      validator: function (value) {
        return value.charAt(0) === '#' ? true : false
      },
    },
    localization: {
      type: Object,
      default: () => ({
        switchOpen: 'Open',
        switchClosed: 'Closed',
        placeholderOpens: 'Opens',
        placeholderCloses: 'Closes',
        addHours: 'Add hours',
        open: {
          invalidInput:
            'Please enter an opening time in the 12 hour format (ie. 08:00 AM). You may also enter "24 hours".',
          greaterThanNext:
            'Please enter an opening time that is before the closing time.',
          lessThanPrevious:
            'Please enter an opening time that is after the previous closing time.',
          midnightNotLast:
            "Midnight can only be selected for the day's last closing time.",
        },
        close: {
          invalidInput:
            'Please enter a closing time in the 12 hour format (ie. 05:00 PM). You may also enter "24 hours" or "Midnight".',
          greaterThanNext:
            'Please enter a closing time that is after the opening time.',
          lessThanPrevious:
            'Please enter a closing time that is before the next opening time.',
          midnightNotLast:
            "Midnight can only be selected for the day's last closing time.",
        },
        t24hours: '24 hours',
        midnight: 'Midnight',
        days: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          newYearsEve: 'New Year\'s Eve', // prettier-ignore
          newYearsDay: 'New Year\'s Day', // prettier-ignore
          martinLutherKingJrDay: 'Martin Luther King, Jr. Day',
          presidentsDay: 'Presidents\' Day', // prettier-ignore
          easter: 'Easter',
          memorialDay: 'Memorial Day',
          independenceDay: 'Independence Day',
          fourthOfJuly: '4th of July',
          laborDay: 'Labor Day',
          columbusDay: 'Columbus Day',
          veteransDay: 'Veterans Day',
          thanksgiving: 'Thanksgiving',
          christmasEve: 'Christmas Eve',
          christmas: 'Christmas',
        },
      }),
    },
    switchWidth: {
      type: Number,
      default: 75,
    },
    hourFormat24: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collectionStatus: false,
      deliveryStatus: false,
      dineinStatus: false,
      holidayHours: [],
      holidayDates: [],
      online_store_settings: JSON.parse(JSON.stringify(this.settings)),
    }
  },
  created() {
    this.collectionStatus = this.hours.collectionStatus
    this.deliveryStatus = this.hours.deliveryStatus
    this.dineinStatus = this.hours.dineinStatus
  },
  mounted() {
    if (!this.collectionStatus) {
      this.check(this.collectionStatus, 'collection')
    }
    if (!this.deliveryStatus) {
      this.check(this.deliveryStatus, 'delivery')
    }
    if (!this.dineinStatus) {
      this.check(this.dineinStatus, 'dinein')
    }
  },
  watch: {
    holidayHours: {
      deep: true,
      immediate: true,
      handler(val) {},
    },
  },
  methods: {
    changEvent() {},
    hoursChange: function (val) {
      this.$emit('updated-hours', val)
    },
    check(event, deliveryType) {
      this.$refs.businessHours.forEach((el) => {
        // Do something with `col`
        for (var i in el.hours[deliveryType]) {
          el.hours[deliveryType][i].isOpen = event
          // el.hours[deliveryType][i].open = ''
          // el.hours[deliveryType][i].close = ''
        }
      })
    },
    oddOrEven(number) {
      if (number % 2 == 0) {
        return 'even-bg'
      }
      return 'odd-bg'
    },
    addEvent() {
      this.holidayHours.push({
        event: '',
        event_date: '',
        collection: false,
        delivery: false,
        dinein: false,
        day: {
          collection: [
            {
              open: '',
              close: '',
              id: '1',
              isOpen: false,
            },
          ],
          delivery: [
            {
              open: '',
              close: '',
              id: '2',
              isOpen: false,
            },
          ],
          dinein: [
            {
              open: '',
              close: '',
              id: '3',
              isOpen: false,
            },
          ],
        },
      })
    },
    removeEvent(index) {
      this.holidayHours.splice(index, 1)
    },
    SaveChanges() {
      let outlet_id = accountService.getOutletId()
      this.hours['collectionStatus'] = this.collectionStatus
      this.hours['deliveryStatus'] = this.deliveryStatus
      this.hours['dineinStatus'] = this.dineinStatus

      customizeService
        .editOutlet(outlet_id, {
          outlets: {
            outlet: {
              online_business_hours: this.hours,
              // holiday: this.holidayHours,
              online_store_settings: this.online_store_settings,
            },
          },
        })
        .then((response) => {
          Swal.fire('Successfully Updated ')
        })
        .catch((error) => {
          Swal.fire('Error, try again later')
        })

      // debugger
    },
  },
}
</script>
<style>
.md-switch-label {
  color: #fff !important;
}
</style>

<style scoped>
.business-hours-container {
  display: block;
  width: 100%;
  /* max-width: 630px; */
  font-family: -apple-system, Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #3d4852;
}
.order-type-details {
  display: flex;
}
.order-type-details-collection {
  width: 40%;
}
.order-type-details-dinein,
.order-type-details-dinein {
  width: 30%;
}
.remove-icon {
  position: absolute;
  right: 30px;
  z-index: 9;
}
.d-inline-flex {
  display: inline-flex;
}
</style>
