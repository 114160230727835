var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout station-table"},[_c('div',{staticClass:"md-layout-item"},[_c('md-card',{staticClass:"pagination-card block-bg pad-b-15px pad-l-15px pad-r-15px"},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-green"},[(_vm.$store.state.account.profile.has_access)?_c('router-link',{attrs:{"to":{ name: 'ScreenMenuForm', query: { formType: 'Add Menu' } }}},[_c('md-icon',{staticClass:"add-icon"},[_vm._v("add")])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Menus')))]),(!_vm.loader)?_c('div',{staticClass:"active-inactive-tab"},[_c('div',{staticClass:"onoffswitch"},[_c('input',{staticClass:"onoffswitch-checkbox",attrs:{"type":"checkbox","name":"onoffswitch","id":"myonoffswitch","tabindex":"0"},domProps:{"value":_vm.activetab,"checked":_vm.activetab ? true : false},on:{"change":function($event){return _vm.updateInput($event)}}}),_c('label',{staticClass:"onoffswitch-label",attrs:{"for":"myonoffswitch"}},[_c('span',{staticClass:"onoffswitch-inner"}),_c('span',{staticClass:"onoffswitch-switch"})])])]):_vm._e()])],1),_c('md-card-content',{staticClass:"mt-25"},[(_vm.loader)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{attrs:{"loading":_vm.loader,"color":'#00E0DB'}})],1):_vm._e(),(!_vm.loader)?_c('md-table',{staticClass:"paginated-table table-striped table-hover",attrs:{"value":_vm.queriedData,"md-sort":_vm.currentSort,"md-sort-order":_vm.currentSortOrder,"md-sort-fn":_vm.customSort},on:{"update:mdSort":function($event){_vm.currentSort=$event},"update:md-sort":function($event){_vm.currentSort=$event},"update:mdSortOrder":function($event){_vm.currentSortOrder=$event},"update:md-sort-order":function($event){_vm.currentSortOrder=$event},"md-selected":_vm.viewDetail},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":_vm.$t('Name')}},[_c('div',{on:{"click":function($event){return _vm.viewDetail(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('md-table-cell',{attrs:{"md-label":_vm.$t('Id')}},[_c('div',{on:{"click":function($event){return _vm.viewDetail(item)}}},[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('md-table-cell',{staticClass:"center",attrs:{"md-label":_vm.$t('Status')}},[_c('div',{on:{"click":function($event){return _vm.viewDetail(item)}}},[_c('i',{class:[
                    'check-align',
                    item.status ? 'fa fa-check ' : 'fa fa-ban' ],attrs:{"aria-hidden":"true"}})])]),_c('md-table-cell',{staticClass:"center",attrs:{"md-label":_vm.$t('Actions')}},[_c('div',{staticClass:"border-edit"},[_c('md-menu',{attrs:{"md-size":"medium","md-offset-x":-227,"md-offset-y":-16}},[_c('i',{staticClass:"material-icons text_align-center menu-icon",attrs:{"md-menu-trigger":""}},[_vm._v("more_vert")]),_c('md-menu-content',{staticClass:"menuitem-dropdown"},[_c('md-menu-item',{staticClass:"action-header menu-align"},[_vm._v(_vm._s(_vm.$t('Actions')))]),_c('router-link',{attrs:{"to":{
                        name: 'ScreenMenuForm',
                        query: {
                          formType: 'Edit Menu',
                          client: item.id,
                        },
                      }}},[_c('md-menu-item',[_c('span',{staticClass:"edit-align"},[_c('i',{staticClass:"fas fa-pencil-alt"}),_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")])])],1),_c('div',[_c('md-menu-item',[_c('span',{staticClass:"edit-align",on:{"click":function($event){return _vm.alert('Custom', item.id)}}},[_c('i',{staticClass:"fa fa-ban",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(item.status ? _vm.$t('Deactivate') : _vm.$t('Activate'))+" ")])])],1),_c('router-link',{attrs:{"to":{
                        name: 'screenDesign',
                        query: {
                          screen: item.id,
                        },
                      }}},[_c('md-menu-item',[_c('span',{staticClass:"edit-align"},[_c('i',{staticClass:"fas fa-pencil-alt"}),_vm._v(" "+_vm._s(_vm.$t('Grid'))+" ")])])],1),(_vm.userType == 'branch_user')?_c('router-link',{attrs:{"to":{
                        name: 'assignScreenOutlets',
                        query: {
                          screen: item.id,
                        },
                      }}},[_c('md-menu-item',[_c('span',{staticClass:"edit-align"},[_c('i',{staticClass:"fas fa-pencil-alt"}),_vm._v(" "+_vm._s(_vm.$t('Assign To Outlets'))+" ")])])],1):_vm._e()],1)],1)],1)])],1)}}],null,false,3233711672)},[_c('md-table-toolbar',{staticClass:"table-toolbar-align right-0"},[_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t('Search')))]),_c('md-input',{staticClass:"mb-3",attrs:{"type":"search","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('i',{staticClass:"fa fa-search search-align",attrs:{"aria-hidden":"true"}})],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item layout-border"},[_c('div',{staticClass:"pagination-main"},[_c('div',{staticClass:"rowsperpage"},[_vm._v(_vm._s(_vm.$t('Rows Per page')))]),_c('div',{staticClass:"pagenation-number"},[_c('md-field',[_c('md-select',{attrs:{"name":"pages"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('md-option',{key:item,attrs:{"label":item,"value":item}},[_vm._v(_vm._s(item))])}),1)],1)],1),_c('div',{staticClass:"pagination-warp"},[_c('p',{staticClass:"card-category pagination-text-align"},[_vm._v(" "+_vm._s(_vm.$t('From'))+" "+_vm._s(_vm.from + 1)+" "+_vm._s(_vm.$t('to'))+" "+_vm._s(_vm.to)+" ")]),_c('pagination',{staticClass:"pagination-no-border pagination-success",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }