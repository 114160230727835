<template>
  <div class="md-layout main-cat-table">
    <div class="md-layout-item">
      <md-card class="pagination-card">
        <md-card-header
          class="md-card-header-icon md-card-header-green"
          v-if="$store.state.account.profile.has_access"
        >
          <router-link
            :to="{
              name: 'Main Category Form',
              query: { formType: 'Add Main Category' },
            }"
          >
            <md-icon class="add-icon">add</md-icon>
          </router-link>
          <div class="d-flex justify-content-between">
            <h3 class="title">{{ $t('Main Category') }}</h3>

            <div class="active-inactive-tab" v-if="!loader">
              <div class="onoffswitch">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox-main"
                  id="myonoffswitch-main"
                  tabindex="0"
                  :value="activetab"
                  @change="updateInput($event)"
                  :checked="activetab ? true : false"
                />
                <label class="onoffswitch-label" for="myonoffswitch-main">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover main-category"
            @md-selected="viewClientDetail"
            v-if="!loader"
          >
            <md-table-toolbar class="table-toolbar-align">
              <md-field>
                <label>Search</label>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style=""
                  v-model="searchQuery"
                ></md-input>
                <i class="fa fa-search search-align" aria-hidden="true"></i>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                v-bind:md-label="$t('Seq')"
                md-sort-by="code_order"
              >
                <div @click="viewClientDetail(item)">
                  {{ item.code_order }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('id')">
                <div @click="viewClientDetail(item)">
                  {{ item.id }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Name')" md-sort-by="name">
                <div @click="viewClientDetail(item)">
                  {{ item.name }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Super category')">
                <div @click="viewClientDetail(item)">
                  {{ item.super_categoty }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Description')">
                <div @click="viewClientDetail(item)">
                  {{ item.description }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Color')">
                <div @click="viewClientDetail(item)">
                  <button
                    class="color-btn"
                    :style="{ backgroundColor: item.code_color }"
                  ></button>
                </div>
              </md-table-cell>
              <md-table-cell class="center" v-bind:md-label="$t('Status')">
                <div @click="viewClientDetail(item)">
                  <i
                    v-bind:class="[
                      'check-align',
                      item.status === true ? 'fa fa-check ' : 'fa fa-ban',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
              </md-table-cell>
              <md-table-cell class="center" v-bind:md-label="$t('Actions')">
                <div class="border-edit">
                  <md-menu
                    md-size="medium"
                    :md-offset-x="-227"
                    :md-offset-y="-16"
                  >
                    <i
                      class="material-icons text_align-center menu-icon"
                      md-menu-trigger
                      >more_vert</i
                    >
                    <md-menu-content class="menuitem-dropdown">
                      <md-menu-item class="action-header menu-align"
                        >Actions</md-menu-item
                      >
                      <router-link
                        :to="{
                          name: 'Main Category Form',
                          query: {
                            formType: 'Edit Main Category',
                            client: item.id,
                          },
                        }"
                      >
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fas fa-pencil-alt"></i>
                            {{ $t('Edit') }}
                          </span>
                        </md-menu-item>
                      </router-link>
                      <div>
                        <md-menu-item>
                          <span
                            class="edit-align"
                            @click="alert('Edit', item.id)"
                          >
                            <i class="fa fa-ban" aria-hidden="true"></i>
                            {{
                              item.status == true
                                ? $t('Deactivate')
                                : $t('Activate')
                            }}
                          </span>
                        </md-menu-item>
                      </div>
                      <!-- <div>
                        <md-menu-item>
                          <span
                            class="edit-align"
                            @click="alert('Delete', item.id)"
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            {{ $t('Delete') }}
                          </span>
                        </md-menu-item>
                      </div> -->
                    </md-menu-content>
                  </md-menu>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- </md-card-actions> -->
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import draggable from 'vuedraggable'
import Sortable from 'sortablejs'

export default {
  components: {
    Pagination,
    ClipLoader,
    draggable,
    Sortable,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData.filter(
          (item) => item.status == this.activetab
        )
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },

  data() {
    return {
      currentSort: 'code_order',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      // footerTable: ["Name", "Email", "Age", "Salary", "Actions"],
      searchQuery: '',
      propsToSearch: ['name'],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
      tempData: [],
      activetab: true,
    }
  },

  methods: {
    customSort(value) {
      return this.tableData.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, { numeric: true })
        }
        return a[sortBy]
          .toString()
          .localeCompare(b[sortBy].toString(), undefined, { numeric: true })
      })
    },

    alert(value, id) {
      var self = this
      if (value === 'Delete') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.deleteMainCategory(id)
          }
        })
      } else if (value === 'Edit') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.updateItemStatus(id)
          }
        })
      }
    },

    deleteMainCategory(id) {
      customizeService.deleteMaincat(id).then((res) => {
        this.getItems()
      })
    },

    viewClientDetail(item) {
      this.$router.push({
        name: 'Main Category Form',
        query: { formType: 'Edit Main Category', client: item.id },
      })
    },

    getItems() {
      var self = this
      this.loader = true
      customizeService.getMaincat().then((res) => {
        // self.tableData = res.data.api_v1_main_category
        self.tempData = JSON.parse(
          JSON.stringify(res.data.api_v1_main_category)
        )
        self.tableData = self.tempData.filter((item) => item.status == true)
        this.loader = false
      })
    },
    updateItemOrder: function () {
      // get your info then...
      let sort_order_json = { main_categories: {} }
      this.tableData.map(function (item, index) {
        item.code_order = index + 1
        sort_order_json['main_categories'][item.id] = {
          code_order: index + 1,
        }
      })

      customizeService.update_categories(sort_order_json, 'main')
    },
    updateItemStatus: function (id) {
      let update_json = { main_categories: {} }
      let item = this.tempData.find((element) => element.id == id)
      update_json['main_categories'][id] = {
        status: !item.status,
      }
      customizeService.update_categories(update_json, 'main').then((result) => {
        item.status = !item.status
        if (this.activetab != null) {
          this.filterBystatus(this.activetab)
        } else {
          this.tableData = this.tempData
        }
      })
    },
    filterBystatus(status) {
      this.activetab = status
      this.searchedData = []

      this.tableData = this.tempData.filter((item) => item.status == status)
    },
    updateInput(event) {
      let status = event.target.checked ? true : false
      this.filterBystatus(status)
    },
  },
  mounted() {},
  updated: function () {
    let table = document.querySelector('.main-category tbody')
    const _self = this
    Sortable.create(table, {
      onEnd({ newIndex, oldIndex }) {
        const rowSelected = _self.tableData.splice(oldIndex, 1)[0] // Get the selected row and remove it
        _self.tableData.splice(newIndex, 0, rowSelected) // Move it to the new index
        _self.updateItemOrder()
      },
    })
  },

  created() {
    this.getItems()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['name'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.color-btn {
  height: 15px;
}

.drag-row {
  display: contents !important;
}

.draggable-table .md-table-head {
  padding-left: 15px;
  text-align: left;
}

.draggable-table .md-table-head:nth-child(4) {
  padding-left: 0;
}

.draggable .md-table-head-container {
  height: auto;
  padding: 5px 0px;
}

.super-table .md-table-row {
  cursor: move;
}

.active-inactive-btn li {
  list-style: none;
  float: right;
}
.active {
  border-bottom: 2px solid #2b82be !important;
}
.fa-ban {
  color: red;
}

.super-table
  .md-table-head:nth-child(3)
  .md-table-head-container
  .md-table-head-label {
  padding: none;
}
.main-cat-table .md-table-row {
  cursor: move;
}
</style>
<style>
.main-cat-table
  .md-table-head:nth-child(4)
  .md-table-head-container
  .md-table-head-label,
.main-cat-table
  .md-table-head:nth-child(5)
  .md-table-head-container
  .md-table-head-label {
  padding-left: 11px !important;
}
.main-cat-table .md-table-cell:nth-child(4),
.main-cat-table .md-table-cell:nth-child(5) {
  padding-left: 16px !important;
}
</style>
