<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/online-ordering/menu-section' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType == 'View Menu Section'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editMenuSection"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                v-model="menuSection.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Menu Section'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('ShortName') },
                { 'md-valid': !errors.has('ShortName') && touched.ShortName },
              ]"
            >
              <label>{{ $t('Short Name') }}</label>
              <md-input
                v-model="menuSection.short_name"
                data-vv-name="ShortName"
                type="text"
                v-validate="modelValidations.ShortName"
                :disabled="formType == 'View Menu Section'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('ShortName')">
                  {{ errors.first('ShortName') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('ShortName') && touched.ShortName"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Description') },
                {
                  'md-valid': !errors.has('Description') && touched.Description,
                },
              ]"
            >
              <label>{{ $t('Description') }}</label>
              <md-input
                v-model="menuSection.description"
                data-vv-name="Description"
                type="text"
                v-validate="modelValidations.Description"
                :disabled="formType == 'View Menu Section'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Description')">
                  {{ errors.first('Description') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Description') && touched.Description"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Menu Section'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/products/categories/sub-category' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import axios from 'axios'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      Description: '',
      switch2: null,
      ShortName: '',
      Name: '',
      menuSectionId: '',

      touched: {
        Name: false,
        ShortName: false,
        Description: false,
      },

      modelValidations: {
        Name: {
          required: true,
        },
        ShortName: {
          required: true,
        },
      },

      formType: {
        type: String,
        default: 'Add Menu Section',
      },

      clientDetail: {
        default: '',
      },

      menuSection: {},
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createMenuSection()
          }
        })
      }
    },

    createMenuSection() {
      let self = this
      if (this.formType == 'Add Menu Section') {
        customizeService
          .createMenuSection(this.menuSection)
          .then((res) => {
            self.$router.push({ name: 'Menu Section' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        let body = {
          menu_section: this.menuSection,
        }
        body.menu_section.codable_type = 'menu_section'
        // axios
        //   .put(this.suburl + '.json', body, customizeService.authFun())
        //   .then((res) => {
        //     console.log('Super response', res)
        //     self.$router.push({ name: 'Menu Section' })
        //   })
        //   .catch((err) => {
        //     console.log('error', err)
        //   })
        // }
        customizeService
          .updateMenuSection(this.menuSectionId, body)
          .then((res) => {
            self.$router.push({ name: 'Menu Section' })
          })
      }
    },

    reset() {
      this.menuSection = {}
    },

    editMenuSection() {
      this.formType = 'Edit Menu Section'
    },
  },

  created() {
    this.formType = this.$route.query.formType
    this.menuSectionId = this.$route.query.client
    let self = this
    if (this.formType != 'Add Menu Section') {
      this.loader = true
      customizeService.getMenuSectionById(this.menuSectionId).then((res) => {
        this.loader = false
        this.menuSection = res.data.api_v1_menu_section
      })
    }
  },

  watch: {
    Name() {
      this.touched.required = true
    },
    ShortName() {
      this.touched.required = true
    },
    Description() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
