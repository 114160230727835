<template>
  <div class="holiday-add-wrapper">
    <h4>
      Holiday
      <md-icon class="add-icon" @click.native="addEvent()">add</md-icon>
    </h4>
    <div class="md-layout md-gutter">
      <div
        class="md-layout-item md-size-50"
        v-for="(holiday, index) in holidays"
        :key="index"
      >
        <md-card class="pl-2">
          <md-icon class="remove-icon" @click.native="removeEvent(index)"
            >close</md-icon
          >
          <md-field>
            <label>{{ $t('Event Name') }}</label>
            <md-input type="text" v-model="holiday.event"></md-input>
          </md-field>
          <label>{{ $t('Date') }}</label>
          <datetime
            class="datetime-custm"
            format="YYYY-MM-DD"
            v-model="holiday.event_date"
          ></datetime>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import datetime from 'vuejs-datetimepicker'

export default {
  components: {
    datetime,
  },
  props: {
    holidays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      holidayList: [],
    }
  },
  methods: {
    addEvent() {
      this.holidays.push({ event: '', event_date: '' })
    },
    removeEvent(index) {
      this.holidays.splice(index, 1)
    },
  },
}
</script>
