<template>
  <form class="md-layout">
    <md-card class="md-layout-item block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <h3 class="md-layout-item md-size-90">{{ $t('Voucher Me') }}</h3>
        <div class="md-layout-item md-size-10 text-right">
          <!-- <i class="md-icon md-icon-font md-theme-default edit-img">edit</i> -->
        </div>
      </md-card-header>
      <md-card-content
        v-bind:style="'margin-top:4rem;margin-bottom-2rem;margin-left: 0'"
      >
        <div class="md-layout md-alignment-center-center">
          <!-- <div class="md-layout-item layout-border line"></div> -->
          <!-- <form @submit.prevent="handleSubmit"> -->
          <div class="md-layout-item md-size-50 form-group left-padding">
            <md-field :class="{ 'md-error': errors.has('HostKey') }">
              <label class="title">Key</label>
              <md-input
                class="form-control"
                type="text"
                :class="{ 'is-invalid': submitted && errors.has('HostKey') }"
                id="HostKey"
                v-validate="'required'"
                v-model.lazy="voucherMe.key"
                data-vv-name="HostKey"
                @blur="touched.key = !touched.key"
              ></md-input>

              <slide-y-down-transition>
                <div class="error" v-show="submitted && errors.has('HostKey')">
                  {{ errors.first('HostKey') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="
                    !errors.has('HostKey') &&
                    voucherMe.key != null &&
                    touched.key
                  "
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>

            <div
              class="md-layout-item form-group mt-4"
              v-bind:style="'margin-bottom-2rem;padding-left:0'"
            >
              <md-field>
                <label>{{ $t('Online Payment Type') }}</label>
                <md-select v-model="voucherMe.payTypeID">
                  <md-option
                    :value="item.id"
                    v-for="item in online_payments"
                    :key="item.id"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>

          <!-- </form> -->
        </div>

        <div
          style="margin-top: 20px"
          class="btn-div md-layout-item md-size-60 md-alignment-center-right"
        >
          <md-button
            type="button"
            @click="handleSubmit()"
            style="float: right"
            class="md-primary"
            >Update</md-button
          >
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { Pagination } from '@/components'
import axios from 'axios'
import { SlideYDownTransition } from 'vue2-transitions'
import { required } from 'vuelidate/lib/validators'
import { accountService } from '../../../services/account.js'
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'

export default {
  components: {
    Pagination,
    SlideYDownTransition,
  },
  data() {
    return {
      outlet_id: null,
      outletData: null,
      voucherMe: {
        key: null,
      },
      submitted: false,
      touched: {
        key: false,
      },
      online_payments: [],
    }
  },
  beforeMount() {
    this.loadKeys()
  },
  methods: {
    loadKeys() {
      this.outlet_id = accountService.getOutletId()
      customizeService
        .getOutletById(this.outlet_id)
        .then((res) => {
          if (res.data.outlet.voucher_me) {
            this.voucherMe.key = res.data.outlet.voucher_me.key
            this.voucherMe.payTypeID = res.data.outlet.voucher_me.payTypeID
          }
        })
        .catch((error) => console.log('error>>>', error))
    },

    handleSubmit() {
      this.submitted = true
      // this.$validator.validate().then((valid) => {
      // if (valid) {
      this.saveData()
      // }
      // })
    },
    saveData() {
      let outlet_id = accountService.getOutletId()
      customizeService
        .editOutlet(outlet_id, {
          outlets: {
            outlet: {
              voucher_me: this.voucherMe.key ? this.voucherMe : {},
            },
          },
        })
        .then((resp) => {
          reusableService.showSwal('Update').then((result) => {})
        })
    },
  },
  created() {
    customizeService
      .getOtherPaymentTypes(accountService.getOutletId())
      .then((res) => {
        this.online_payments = res.data.other_payment_types
        this.otherFeeTypes = res.data.other_payment_types.filter(
          (item) => item.status == true && item.is_fee == true
        )
      })
  },
}
</script>

<style lang="css" scoped>
.title-main {
  margin-left: 2rem;
}

.sub-group {
  margin-top: 25px;
}

.md-dense {
  float: right;
  margin-top: 2rem;
}
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.error {
  color: red;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}

.text-right {
  text-align: right;
}
</style>
