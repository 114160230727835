<template>
  <div class="md-layout">
    <div class="datepicker-advanced md-layout-item md-size-30 mt-7">
      <!-- <v-date-picker
        mode="range"
        :value="datePicked"
        v-model="datePicked"
        :reactive="true"
        :show-day-popover="true"
        @drag="select()"
      ></v-date-picker> -->
      <date-picker
        show-week-number
        format="DD/MM/YYYY"
        type="week"
        v-model="value6"
        @input="select"
        :lang="lang"
        ref="datePicker"
      ></date-picker>
    </div>
    <!-- @input="dateInput()" -->

    <div class="md-layout-item md-size-60">
      <div class="list-interval">
        <div
          v-for="(item, index) in prevWeeks"
          :class="[
            {
              activeDate:
                JSON.stringify(item.date) == JSON.stringify(datePicked.start),
            },
            'interval-item',
          ]"
          @click="setIntervalDate(item.date)"
          :key="index"
        >
          {{ item.display }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  components: { DatePicker },

  name: 'ad-datepicker',
  props: {
    rangeSet: {
      type: String,
    },
  },
  data() {
    return {
      datePicked: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 1),
      },
      prevWeeks: [],
      today: new Date(),
      selectedWeek: null,
      value6: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    }
  },

  computed: {
    highlightDate() {
      return this.selectedWeek == this.datePicked.start
    },
  },

  methods: {
    fillWeeks() {
      for (let i = 0; i < 7; i++) {
        let previousDate = new Date(
          this.datePicked.start.getFullYear(),
          this.datePicked.start.getMonth(),
          this.datePicked.start.getDate() - i * 7
        )
        let displayDate = new Date(
          this.datePicked.start.getFullYear(),
          this.datePicked.start.getMonth(),
          this.datePicked.start.getDate() - i * 7
        )
          .toDateString()
          .split(' ')
          .slice(1)
          .join(' ')
        this.prevWeeks.push({
          date: previousDate,
          display: displayDate,
        })
      }
      this.datePicked.start = new Date(this.prevWeeks[0].date)
    },

    dateInput() {
      this.datePicked.start.setDate(
        this.datePicked.start.getDate() -
          ((this.datePicked.start.getDay() + 6) % 7)
      )
    },

    setIntervalDate(intervalDate) {
      this.datePicked.start = new Date(intervalDate)
      let endDate = new Date().setDate(intervalDate.getDate() + 7)
      this.datePicked.end = new Date(endDate)
      this.$emit('startDate', this.datePicked.start)
      this.$emit('endDate', this.datePicked.end)
    },

    select() {
      var day1 = this.value6.getDay()
      const startDate = new Date(this.value6)
      startDate.setDate(startDate.getDate() - day1 + 1)
      const endDate = new Date(startDate.getDate() + 7)
      this.$emit('startDate', startDate)
      this.$emit('endDate', endDate)
      this.$refs.datePicker.text = day1
      this.datePicked.start = startDate

      // const today = new Date()
      // const yesterday = new Date(today)
      // yesterday.setDate(yesterday.getDate() - 1)

      // this.datePicked.end = today
      // this.datePicked.start = yesterday
    },
  },

  watch: {
    // datePicked() {
    //   console.log('date picked', this.datePicked)
    //   this.$emit('startDate', this.datePicked.start.toISOString())
    //   this.datePicked.span = 7
    //   this.$emit('endDate', this.datePicked.end.toISOString())
    // },
  },
  created() {
    let today = new Date()
    let date = new Date()
    let day = date.getDay()
    let prevMonday
    prevMonday = new Date().setDate(date.getDate() - ((date.getDay() + 6) % 7))
    this.datePicked.end = today
    this.datePicked.start = new Date(prevMonday)
    this.fillWeeks()

    //   prevMonday = new Date().setDate(date.getDate() + 5)
    // this.datePicked.end = new Date(prevMonday)
    // this.datePicked.start = today
  },
}
</script>

<style></style>
