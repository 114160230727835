<template>
  <div class="icon-datepicker">
    <md-field>
      <!-- <label>
                <i class="fas fa-calendar-alt"></i>
            </label> -->
      <md-select v-model="dateOption">
        <md-option value="Daily">Today</md-option>
        <md-option value="Yesterday">Yesterday</md-option>
        <md-option value="Weekly">This Week</md-option>
        <md-option value="Last week">Last Week</md-option>
        <md-option value="Monthly">This Month</md-option>
        <md-option value="Ad Hoc">Ad Hoc</md-option>
      </md-select>
    </md-field>
  </div>
</template>

<script>
export default {
  name: 'date-filter-drop',
  data() {
    return {
      dateOption: 'Daily',
    }
  },

  watch: {
    dateOption() {
      this.$emit('searchType', this.dateOption)
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-datepicker {
  max-width: 280px;
  width: 100%;

  label {
    margin-left: 12px;
    i {
      color: #2d76a7;
    }
  }
}
</style>
