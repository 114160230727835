<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/outlet/other-payment-types' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType == 'View Other Payment Type'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editPaymentType"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Other Payment Type'"
                v-model="other_payment_types.name"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Copies') },
                { 'md-valid': !errors.has('Copies') && touched.Copies },
              ]"
            >
              <label>{{ $t('Number of Copies') }}</label>
              <md-input
                :disabled="formType == 'View Other Payment Type'"
                data-vv-name="Copies"
                v-validate="modelValidations.Copies"
                v-model="other_payment_types.no_copie_bill"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Copies')">
                  {{ errors.first('Copies') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Copies') && touched.Copies"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Status') },
                { 'md-valid': !errors.has('Status') && touched.Status },
              ]"
            >
              <label>{{ $t('Status') }}</label>
              <md-select
                :disabled="formType == 'View Station'"
                data-vv-name="Status"
                v-validate="modelValidations.Status"
                v-model="other_payment_types.status"
              >
                <md-option class="first-opt" disabled>Status</md-option>
                <md-option value="true">{{ $t('Active') }}</md-option>
                <md-option value="false">{{ $t('Inactive') }}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Status')">
                  {{ errors.first('Status') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Status') && touched.Status"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-checkbox v-model="other_payment_types.payment_decl">{{
              $t('Payment Declaration')
            }}</md-checkbox>
          </div>
        </div>

        <!-- 
         <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('Copies') },
                { 'md-valid': !errors.has('Copies') && touched.Copies }
              ]"
            >
              <label>{{$t('Number of Copies')}}</label>
              <md-input :disabled="formType == 'View Other Payment Type'" data-vv-name="Copies"
                v-validate="modelValidations.Copies" v-model="other_payment_types.no_copie_bill"></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Copies')">{{errors.first("Copies")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Copies') && touched.Copies"
                >done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <!-- <div class="md-layout">
          <div class="md-layout-item">
               <md-checkbox v-model="other_payment_types.payment_decl">{{$t('Payment Declaration')}}</md-checkbox>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
               <md-checkbox v-model="other_payment_types.open_drawer">{{$t('Open Drawer')}}</md-checkbox>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-checkbox v-model="other_payment_types.allow_change">{{
              $t('Allow Change')
            }}</md-checkbox>
          </div>
          <div class="md-layout-item md-size-50">
            <md-checkbox v-model="other_payment_types.open_drawer">{{
              $t('Open Drawer')
            }}</md-checkbox>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-checkbox v-model="other_payment_types.is_fee">{{
              $t('Is Fee?')
            }}</md-checkbox>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Other Payment Type'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/outlet/other-payment-types' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import axios from 'axios'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      copyCount: '',
      Status: '',
      switch2: null,
      Name: '',
      touched: {
        Name: false,
        Status: false,
        Copies: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
        Status: {
          required: true,
        },
        Copies: {
          required: true,
          numeric: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Client',
      },
      payment: false,
      other_payment_types: {
        name: '',
        allow_change: true,
        no_copie_bill: '',
        payment_decl: false,
        open_drawer: false,
        status: true,
      },
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createPaymentType()
          }
        })
      }
    },

    createPaymentType() {
      if (this.formType == 'Add Other Payment Type') {
        this.other_payment_types.outlet_id = accountService.getOutletId()
        customizeService
          .createOtherPayment(this.other_payment_types)
          .then((res) => {
            this.$router.push({
              name: 'OtherPaymentTypes',
            })
          })
          .catch((err) => console.log('post error', err))
      } else {
        let body = {
          other_payment_types: this.other_payment_types,
        }
        return axios
          .put(this.other_payment_types.url, body, customizeService.authFun())
          .then((res) => {
            this.$router.push({ name: 'OtherPaymentTypes' })
          })
      }
    },

    reset() {
      this.other_payment_types = {}
    },

    editPaymentType() {
      this.formType = 'Edit Other Payment Type'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    if (this.formType != 'Add Other Payment Type') {
      this.loader = true
      axios
        .get(
          this.url + '?outlet_id=' + accountService.getOutletId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.loader = false
          this.other_payment_types = res.data.other_payments
        })
    }
  },
  watch: {
    Name() {
      this.touched.required = true
    },
    copyCount() {
      this.touched.required = true
    },
    Status() {
      this.touched.required = true
    },
    Copies() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
