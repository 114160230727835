var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.chunkedGrid.length > 1)?_c('md-button',{staticClass:"md-round cat-delete screen-delete",attrs:{"id":"cat-delete"},on:{"click":function($event){return _vm.removeChunk()}}},[_c('md-icon',[_vm._v("delete_forever")])],1):_vm._e(),_c('VueSlickCarousel',{ref:"carousel",attrs:{"arrows":true,"dots":true,"infinite":false,"vertical":true,"swipeToSlide":true,"adaptiveHeight":true,"touchThreshold":_vm.chunkedGrid.length,"customPaging":_vm.customPaging,"slidesToScroll":1,"verticalSwiping":true},on:{"swipe":function($event){return _vm.swipeEvent($event)},"afterChange":_vm.handleAfterChange}},_vm._l((_vm.chunkedGrid),function(chunk,chunkIndex){return _c('div',{key:("chunck-" + chunkIndex),ref:"carouselSlides",refInFor:true,staticClass:"screen-listing",class:_vm.menuPageSelected.screen_size == 'grid8by8'
          ? 'grid-eight-columns'
          : 'grid-four-columns'},_vm._l((chunk),function(zone){return _c('article',{key:zone.i,staticClass:"available_zone",class:{
          'zone-selected':
            _vm.$store.state.screen.allSelectedMenus[_vm.activeTab] &&
            _vm.$store.state.screen.allSelectedMenus[_vm.activeTab].includes(
              zone.uuid
            ),
          'special-class': (zone.i + 1) % 64 === 0, // Add special class to every 64th element
        },style:({
          'box-shadow': ("0 0 0px 3px " + (zone.colour && zone.menu_item_id ? zone.colour : 'transparent')),
        }),attrs:{"number":zone.i},on:{"dragenter":_vm.dragEnter,"dragover":function($event){$event.preventDefault();return _vm.dragOver($event)},"drop":function($event){return _vm.dropBox($event, zone)}}},[(zone.item_name !== null)?_c('div',{staticClass:"box",style:({ background: zone.colour }),attrs:{"draggable":"true","data-zone":zone.i},on:{"dragstart":_vm.boxEnter,"dragend":_vm.boxLeave,"click":function($event){return _vm.toggleSelection(zone.uuid)}}},[_vm._v(" "+_vm._s(zone.item_name)),_c('br'),_vm._v(" "+_vm._s(("" + _vm.currency + (zone.price)))+" ")]):_vm._e(),(zone.item_name !== null)?_c('md-button',{staticClass:"md-round cat-delete",attrs:{"id":"cat-delete"},on:{"click":function($event){return _vm.removeFromGrid(zone)}}},[_c('md-icon',[_vm._v("delete_forever")])],1):_vm._e(),(zone.item_name !== null)?_c('md-button',{staticClass:"cat-edit md-round",attrs:{"id":"cat-edit"},on:{"click":function($event){return _vm.editMenuItem(zone)}}},[_c('md-icon',[_vm._v("edit")])],1):_vm._e()],1)}),0)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }