<template>
  <div class="md-layout-item pd-l">
    <div class="loader-container" v-if="loader">
      <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
    </div>

    <div
      class="new-main-popup ht-100 block-bg pad-xy rounded-xl"
      v-if="!loader"
    >
      <h4 class="text-center mb-0">Assign Menu Items</h4>
      <div class="md-layout-item md-size-100 mt-2 pt-15" id="modal-picker">
        <md-field id="md-field-color">
          <label>{{ $t('Main Category') }}</label>
          <md-select
            v-model="mainCatId"
            @md-selected="loadLeftItems(mainCatId)"
          >
            <md-option class="first-opt" disabled>Main Category</md-option>
            <md-option
              :value="item.id"
              v-for="(item, index) in mainCatList"
              :key="index"
              >{{ item.name }}</md-option
            >
          </md-select>
        </md-field>

        <vue-list-picker
          :left-items="leftItems"
          :right-items="rightMenuItems"
          :titleRight="titleRight"
          moved-item-location="bottom"
          class="text-dark"
          ref="modalListPicker"
        />
        <md-button
          :disabled="!$store.state.account.profile.has_access"
          native-type="submit"
          class="md-primary submitMenuList"
          @click="submitMenuList()"
          >{{ $t('SAVE') }}</md-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../../services/customize.js'
import { accountService } from '../../../../services/account.js'
import { reusableService } from '../../../../services/reusable.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Sortable from 'sortablejs'
import Swatches from 'vue-swatches'
import Swal from 'sweetalert2'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
    Swatches,
  },
  data() {
    return {
      touched: {},

      modelValidations: {
        required: {
          required: true,
        },

        menuItemListName: {
          required: true,
        },

        Description: {
          required: false,
        },
      },
      menuItemListName: '',
      height: '100px',
      formType: '',
      menuListId: '',
      leftItems: [],
      rightItems: [],
      rightMenuItems: [],
      titleRight: 'ITEMS SELECTED',
      newarray: [],
      sortType: '',
      menuItems: [],
      mainCatList: [],
      mainCatId: '',
      rightItemsKey: [],
      menu_list: {
        name: '',
        description: '',
        chain_id: accountService.getOutletId(),
        status: 'true',
        menu_item_list_link_items_attributes: [],
        menu_item_menu_list_links_attributes: [],
      },
      loader: true,
      showItemDialog: false,
    }
  },

  methods: {
    alert(value) {
      var self = this
      this.$validator.validateAll().then((isValid) => {
        if (value === 'Add' && isValid) {
          reusableService.showSwal(value).then((result) => {
            if (self.rightItems.length <= 0) {
              Swal.fire({
                title: 'Alert!',
                text: `Please add a menu item!`,
                type: 'warning',
                buttonsStyling: false,
                confirmButtonClass: 'md-button md-info',
              })
              return false
            }
            if (result.value) {
              // self.submitMenuList()
              this.showItemDialog = true
            }
          })
        }
      })
    },
    updateSortOrder() {
      if (this.sortType == '1') {
        this.rightItems = this.rightItems.sort((one, two) => {
          if (one.content < two.content) {
            return -1
          }
          if (one.content > two.content) {
            return 1
          }
          return 0
        })
      } else if (this.sortType == '2') {
        this.rightItems = this.rightItems.sort((one, two) => {
          if (one.content > two.content) {
            return -1
          }
          if (one.content < two.content) {
            return 1
          }
          return 0
        })
      } else {
        this.rightItems = this.rightItems.sort((one, two) => {
          if (one.main_category > two.main_category) {
            return -1
          }
          if (one.main_category < two.main_category) {
            return 1
          }
          return 0
        })
      }
    },
    getMenuItems() {
      let self = this
      customizeService.getMenuItems().then((res) => {
        self.menuItems = res.data.menu_items.filter(
          (el) => el.status == 'active'
        )
        this.loadMenuListById()
      })
    },

    getMainCategories() {
      customizeService.getMaincat().then((res) => {
        this.mainCatList = res.data.api_v1_main_category.filter(
          (el) => el.status == true
        )
      })
    },

    loadLeftItems(id) {
      this.leftItems = []
      this.rightItemsKey = []

      let filteredItems = this.menuItems.filter(function (el) {
        return id == el.item_group
      })
      this.rightItems.forEach((el) => {
        this.rightItemsKey.push(el.key)
      })
      let self = this
      filteredItems.forEach((el) => {
        let body = {}
        body.key = el.id
        body.content = el.name
        body.main_category = el.main_category
        if (!this.rightItemsKey.includes(el.id)) {
          self.leftItems.push(body)
        }
      })
    },

    submitMenuList() {
      this.menu_list.menu_item_menu_list_links_attributes = []

      this.rightMenuItems.forEach((el, index) => {
        this.menu_list.menu_item_menu_list_links_attributes.push({
          menu_item_id: el.key,
          sort_order: index + 1,
        })
      })
      let body = { menu_list: this.menu_list }

      if (this.formType == 'Add Menu List') {
        this.menu_list.chain_id = accountService.getChainId()
        customizeService.createMenuList(body).then((res) => {
          this.$router.push({ path: '/Products/MenuItemLists' })
        })
      } else {
        customizeService.updateMenuList(this.menuListId, body).then((res) => {
          this.$router.push({ path: '/Products/MenuItemLists' })
        })
      }
    },

    editComboGroup() {
      this.formType = 'Edit Combo Group'
    },

    loadMenuListById() {
      this.loader = false
      if (this.formType != 'Add Menu List') {
        customizeService.getMenuItemList(this.menuListId).then((res) => {
          this.loader = false
          this.menu_list = res.data

          this.menu_list.menu_item_lists.forEach((el) => {
            this.rightMenuItems.push({
              key: el.id,
              content: el.name,
              colour: el.colour,
              name: el.name,
              main_category: el.main_category,
            })
          })
        })
      } else {
        this.loader = false
      }
    },
  },

  created() {
    this.getMenuItems()
    this.getMainCategories()
    this.formType = this.$route.query.formType || 'Add Menu List'
    this.menuListId = this.$route.query.client
  },
  updated: function () {
    let table = document.querySelector(
      '#list-drag .list-picker-right .list-picker-panel'
    )
    let modalTable = document.querySelector(
      '#modal-picker .list-picker-right .list-picker-panel'
    )
    const _self = this

    Sortable.create(table, {
      removeCloneOnHide: true, // Remove the clone element when it is not showing, rather than just hiding it
      onEnd({ newIndex, oldIndex, item }) {
        const rowSelected = _self.rightItems.splice(oldIndex, 1)[0] // Get the selected row and remove it
        _self.rightItems.splice(newIndex, 0, rowSelected) // Move it to the new index
        _self.$refs.vueListPicker.stopDrag()

        // _self.updateItemOrder()
        return false
      },
    })
    Sortable.create(modalTable, {
      removeCloneOnHide: true, // Remove the clone element when it is not showing, rather than just hiding it
      onEnd({ newIndex, oldIndex, item }) {
        const rowSelected = _self.rightMenuItems.splice(oldIndex, 1)[0] // Get the selected row and remove it
        _self.rightMenuItems.splice(newIndex, 0, rowSelected) // Move it to the new index
        _self.$refs.modalListPicker.stopDrag()

        // _self.updateItemOrder()
        return false
      },
    })
  },
  watch: {
    rightItems() {
      if (this.rightItems.length > 1) {
        this.menu_list.automatic_ok = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}

.list-picker {
  padding: 10px 10px;
}
.supp-price .md-field {
  margin: 0px;
  // padding: 10px 10px;
  height: auto;
}
.md-layout-item.md-size-20 {
  padding: 10px 0 10px 15px;
  display: flex;
  flex-direction: column;
}
.p-1 {
  padding: 8px 10px;
}
// .supp-price.list-picker-item {
//   border-bottom: 1px solid #ccc;
// }
.supp-price .md-field {
  padding-bottom: 0;
}
.s-price::after {
  bottom: -2px;
}
.s-price {
  height: 41px;
  padding: 0 10px;
}
.supp-price .md-field::after {
  bottom: 0;
}
.form__label {
  position: absolute;
  left: 20px;
}
#swatches-margin {
  margin-left: 30px !important;
}
.mt10 {
  margin-top: 10px;
}
.color-picker-title {
  color: #0052c0;
  font-size: 1.1em;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  line-height: 25px;
}
.list-picker {
  padding: 0 !important;
}
.list-picker-item {
  border-bottom: 1px solid #ccc;
}
/* .swatch-item {
    min-height: 40px;
  } */
.list-picker-container {
  background-color: #f9f9f9;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>
<style>
.swatch-item .vue-swatches__trigger__wrapper .vue-swatches__trigger {
  margin-top: 5px;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50px !important;
}
.swatch-item .vue-swatches__trigger__wrapper .vue-swatches__trigger {
  margin-top: 5px;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50px !important;
}
.swatch-item {
  margin-left: auto !important;
  margin-right: auto !important;
}
.drag-div {
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.ml-auto {
  margin-left: auto;
}
.md-menu-content.md-select-menu {
  z-index: 100000 !important;
}
.submitMenuList {
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 25px;
}
.ht-100 {
  height: 100% !important;
}
.pt-15 {
  padding-top: 15px;
}
#md-field-color label {
  color: #000 !important;
}
#md-field-color .md-menu input {
  -webkit-text-fill-color: #000 !important;
  color: #000 !important;
}
#md-field-color .md-menu svg {
  fill: #000 !important;
}
</style>
