import { render, staticRenderFns } from "./AddTax.vue?vue&type=template&id=dc2544e8&scoped=true&"
import script from "./AddTax.vue?vue&type=script&lang=js&"
export * from "./AddTax.vue?vue&type=script&lang=js&"
import style0 from "./AddTax.vue?vue&type=style&index=0&id=dc2544e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc2544e8",
  null
  
)

export default component.exports