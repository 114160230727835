<template>
  <md-card
    @mouseleave.native="onMouseLeave"
    :data-count="hoverCount"
    class="md-card-chart block-bg"
  >
    <md-card-header
      @mouseenter.native="onMouseOver"
      :data-header-animation="headerAnimation"
      :class="[
        { hovered: imgHovered },
        { hinge: headerDown },
        { fadeInDown: fixedHeader },
        { animated: true },
        { [getClass(backgroundColor)]: true },
        { 'md-card-header-text': HeaderText },
        { 'md-card-header-icon': HeaderIcon },
      ]"
    >
      <!-- <div v-if="chartInsideHeader" :id="chartId" class="ct-chart"></div> -->
      <doughnut-chart
        :chart-data="datacollection"
        :options="options"
        :height="250"
        v-if="chartType == 'doughnut'"
      ></doughnut-chart>
      <horizontal-bar-chart
        :chart-data="datacollection"
        :options="barChartOptions"
        :height="250"
        v-else-if="chartType == 'Horizontal Bar'"
      ></horizontal-bar-chart>
      <line-chart
        :chart-data="datacollection"
        :options="LineChartOptions"
        :height="240"
        v-else-if="chartType == 'line'"
      ></line-chart>

      <vertical-bar-chart
        :chart-data="datacollection"
        :options="verticalChartOptions"
        :height="480"
        v-else-if="chartType == 'bar'"
      ></vertical-bar-chart>
      <slot name="chartInsideHeader"></slot>
    </md-card-header>

    <md-card-content>
      <!-- <div v-if="chartInsideContent" :id="chartId" ref="chartId" class="ct-chart"></div> -->

      <div
        class="md-card-action-buttons text-center hourly-sale-table"
        v-if="headerAnimation === 'true'"
      >
        <!-- <md-button
          class="md-danger md-simple fix-broken-card"
          @click="fixHeader"
          v-if="headerDown"
        >
          <slot name="fixed-button"></slot> Fix Header!
        </md-button> -->
        <slot name="first-button"></slot>
        <!--<slot name="second-button"></slot>
        <slot name="third-button"></slot> -->
      </div>

      <slot name="content"></slot>
    </md-card-content>

    <md-card-actions md-alignment="left" v-if="!noFooter">
      <slot name="footer"></slot>
      <!-- <md-button @click="fixHeader"
          >Load Graph</md-button> -->
      <md-switch
        v-model="headerSwitch"
        class="switch-style chart-button"
      ></md-switch>
    </md-card-actions>
  </md-card>
</template>
<script>
import Chartist from 'chartist'
import DoughnutChart from '../../components/Graphs/DoughnutChart.vue'
import HorizontalBarChart from '../../components/Graphs/HorizontalBarChart.vue'
import LineChart from '../../components/Graphs/LineChart.vue'
import VerticalBarChart from '../../components/Graphs/BarChart.vue'

export default {
  components: {
    DoughnutChart,
    HorizontalBarChart,
    LineChart,
    VerticalBarChart,
  },
  name: 'chart-card',
  props: {
    chartload: Boolean,
    HeaderText: Boolean,
    HeaderIcon: Boolean,
    noFooter: Boolean,
    chartInsideContent: Boolean,
    chartInsideHeader: Boolean,
    chartType: {
      type: String,
      default: 'Line', // Line | Pie | Bar
    },
    headerAnimation: {
      type: String,
      default: 'true',
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
    chartResponsiveOptions: {
      type: Array,
      default: () => {
        return []
      },
    },
    chartAnimation: {
      type: Array,
      default: () => {
        return []
      },
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: [],
          series: [],
        }
      },
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartColors: [
        '#018bae',
        '#eed00d',
        '#f47514',
        '#35b2c1',
        '#d1573f',
        '#56767a',
        '#f5b682',
        '#ddafbf',
        '#cad517',
      ],
      headerSwitch: true,
      hoverCount: 0,
      imgHovered: false,
      fixedHeader: false,
      chartId: 'no-id',
      // chartd: [],
      chartInstance: null,
      loaded: false,
      datacollection: null,
      options: {
        plugins: {
          datalabels: {
            display: false,
          },
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltips: {
          yAlign: 'bottom',
          callbacks: {
            title: function (tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']]
            },
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex]
              var total = dataset.data.reduce(function (
                previousValue,
                currentValue,
                currentIndex,
                array
              ) {
                return previousValue + currentValue
              })
              var currentValue = dataset.data[tooltipItem.index]
              var precentage = Math.floor((currentValue / total) * 100 + 0.5)
              // return precentage + "%" + ' (' + currentValue + ')';
              return [precentage + '%', 'Total: ' + currentValue.toFixed(2)]
            },
          },
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
          },
        },
      },
      verticalChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        layout: {
          padding: {
            left: 10,
            right: 20,
            top: 30,
            bottom: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                fontSize: 14,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 14,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },

      barChartOptions: {
        plugins: {
          datalabels: {
            display: false,
          },
        },
        layout: {
          padding: {
            left: 30,
            right: 50,
            top: 30,
            bottom: 20,
          },
        },

        animation: {
          duration: 0,
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx
            ctx.textAlign = 'center'
            ctx.fillStyle = 'rgba(0, 0, 0, 1)'
            ctx.textBaseline = 'bottom'

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i)
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index]
                if (index == 0) {
                  ctx.fillText(data.toFixed(2), bar._model.x - 25, bar._model.y)
                } else {
                  ctx.fillText(data.toFixed(2), bar._model.x + 25, bar._model.y)
                }
              })
            })
          },
        },

        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },

      LineChartOptions: {
        layout: {
          padding: {
            left: 30,
            right: 40,
            top: 30,
            bottom: 20,
          },
        },

        legend: {
          display: true,
          position: 'bottom',
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              scaleLabel: {
                display: true,
                labelString: 'Hour',
                fontSize: 10,
              },
            },
          ],
          yAxes: [
            {
              gridLines: false,
              scaleLabel: {
                display: true,
                labelString: 'Total(£)',
                fontSize: 10,
              },
              ticks: {
                padding: 20,
              },
            },
          ],
        },
      },
    }
  },
  computed: {
    headerDown() {
      return this.hoverCount > 5
    },
  },
  methods: {
    headerBack: function () {
      this.fixedHeader = false
    },
    fixHeader: function () {
      this.hoverCount = 0
      this.fixedHeader = true

      setTimeout(this.headerBack, 480)
    },
    onMouseOver: function () {
      if (this.headerAnimation === 'true') {
        this.imgHovered = true
        // this.hoverCount++;
      }
    },
    onMouseLeave: function () {
      if (this.headerAnimation === 'true') {
        this.imgHovered = false
      }
    },

    getClass: function (backgroundColor) {
      return 'md-card-header-' + backgroundColor + ''
    },
    /***
     * Initializes the chart by merging the chart options sent via props and the default chart options
     */
    initChart() {
      var chartIdQuery = `#${this.chartId}`
      this.chartInstance = this.$Chartist[this.chartType](
        chartIdQuery,
        this.chartData,
        this.chartOptions
      )

      // const divNode = this.$refs.chartId
      // var chartInstance =new Chartist.Line(divNode, this.chartData, this.chartOptions)
      // this.chartd = this.chartData
    },
    /***
     * Assigns a random id to the chart
     */
    updateChartId() {
      var currentTime = new Date().getTime().toString()
      var randomInt = this.getRandomInt(0, currentTime)
      this.chartId = `div_${randomInt}`
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },

    shuffle(array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    },

    filldata() {
      if (this.chartType == 'bar') {
        this.datacollection = {
          labels: this.chartData.labels,
          datasets: [
            {
              label: 'Net Total',
              fill: false,
              borderColor: '#d4e68e',
              data: this.chartData.series[0],
              backgroundColor: '#00E0DB',
              barThickness: 20,
            },
            {
              label: 'Guests',
              fill: false,
              borderColor: '#edc7aa',
              data: this.chartData.series[1],
              backgroundColor: '#E59866',
              barThickness: 20,
            },
            {
              label: 'Transactions',
              fill: false,
              borderColor: '#c4445c',
              data: this.chartData.series[2],
              backgroundColor: '#85C1E9',
              barThickness: 20,
            },
          ],
        }
      } else {
        this.datacollection = {
          labels: this.chartData.labels,
          datasets: [
            {
              label: 'Sales Category Mix',
              // backgroundColor: ['#8DC059','#F4BA4F','#D94656','#967BE2','#42AFD8','#3FBC9C','#D8599E','#BB714C','#E65844','#00CCFF','#1799CD','#006699','#663399','#922977','#BD225E','#EB1B2E','#FF6633','#FFCC00','#C8DB2A','#99CC33','#00A640','#00884A'],
              backgroundColor: this.shuffle(this.chartColors),
              data: this.chartData.series,
            },
          ],
        }
      }

      // this.datacollection.datasets.data = this.chartData
    },
  },
  mounted() {
    this.updateChartId()
    this.filldata()
  },

  created() {},

  watch: {
    headerSwitch: function (val) {
      if (val) {
        this.fixHeader()
      } else {
        this.hoverCount = 6 //hover is set to 5, 5+1 would return headerDown as false
      }
    },

    // chartload() {
    //   if(this.chartload){
    //       this.$nextTick(this.initChart);
    //   }
    // }
  },
}
</script>

<style lang="scss" scoped>
.md-card-header-white {
  background: transparent;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  z-index: 999;
  margin-top: 0 !important;
}

canvas {
  padding: 20px;
}

.md-card-action-buttons {
  position: absolute;
  z-index: -1;
  top: 0px;
  width: 100%;
  left: 0px;
  text-align: center;
  height: 65%;
  overflow-y: auto;
}

.md-card-chart {
  padding: 0px 0px;
}
.chart-button {
  position: absolute;
  right: 0px;
  margin-right: 0px;
  top: 4px;
}

@media screen and (max-width: 1440px) and (min-width: 100px) {
  .md-card-action-buttons {
    height: 75% !important;
  }
}
</style>
