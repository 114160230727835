<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/settings/repeat-printer' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType === 'View Repeat Printer'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editPrinter"
            >edit</i
          >
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Printer 1') }}</label>
              <md-select
                :disabled="formType === 'View Repeat Printer'"
                v-model="reprinter.printer_1"
              >
                <md-option class="first-opt" disabled>Printer 1</md-option>
                <md-option
                  v-for="item in stations.filter(
                    (it) => it.id != reprinter.printer_2
                  )"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Printer 2') }}</label>
              <md-select
                :disabled="formType === 'View Repeat Printer'"
                v-model="reprinter.printer_2"
              >
                <md-option class="first-opt" disabled>Printer 2</md-option>
                <md-option
                  v-for="item in stations.filter(
                    (it) => item.id != reprinter.printer_1
                  )"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
               <md-field>
                <label>{{$t('Printer 2')}}</label>
                <md-select
                  :disabled="formType==='View Repeat Printer'"
                  v-model="reprinter.printer_2" 
                >
                   <md-option v-for="item in stations" :key="item.id" :value="item.id" v-if="item.id != reprinter.printer_1">{{item.name }}</md-option>
                </md-select>
              </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Printer only if needed?') }}</label>
              <md-select
                v-model="reprinter.print"
                :disabled="formType === 'View Repeat Printer'"
              >
                <md-option class="first-opt" disabled
                  >Printer only if needed?</md-option
                >
                <md-option value="true">{{ $t('Yes') }}</md-option>
                <md-option value="false">{{ $t('No') }}</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Repeat Printer'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/settings/repeat-printer' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ 'SAVE' }}</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import axios from 'axios'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import { accountService } from '../../../services/account'
export default {
  components: {
    SlideYDownTransition,
  },
  props: {},
  data() {
    return {
      touched: {},
      modelValidations: {},
      formType: {
        type: String,
        default: 'Add Repeat Printer',
      },
      clientDetail: {
        default: '',
      },
      stations: [],
      stations2: [],
      printer1: {
        id: '',
        name: '',
      },
      printer2: {
        id: '',
        name: '',
      },
      reprinter: {},
      sample: 'my sample',
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createRePrinter()
          }
        })
      }
    },

    createRePrinter() {
      if (this.formType == 'Add Repeat Printer') {
        customizeService.createReprinter(this.reprinter).then((res) => {
          this.$router.push({ name: 'Repeat Printers' })
        })
      } else {
        let body = {
          reprinter: this.reprinter,
        }
        axios
          .put(this.reprinter.url, customizeService.authFun())
          .then((res) => console.log('Edited successfully', res))
      }
    },

    reset() {
      this.portion = {}
    },

    editPrinter() {
      this.formType = 'Edit Repeat Printer'
    },

    getStations() {
      customizeService.getStation().then((res) => {
        res.data.api_v1_station.forEach((element) => {
          this.stations.push({
            id: element.id,
            name: element.name,
            is_active: element.is_active,
          })
        })
      })
    },

    updateStations() {},
  },
  created() {
    this.formType = this.$route.params.formType
    this.url = this.$route.params.client
    let self = this
    if (this.formType != 'Add Repeat Printer') {
      axios
        .get(
          this.url + '?chain_id=' + accountService.getChainId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.reprinter = res.data
          console.log('reprinter:', this.reprinter.printer_1)
          this.getStations()
        })
    } else {
      this.getStations()
    }
  },
  watch: {
    Name() {
      this.touched.required = true
    },
    Qty() {
      this.touched.required = true
    },
    measurement() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
