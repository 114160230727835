var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subcat-report menu-item-rep"},[_c('md-card',{staticClass:"report-card block-bg"},[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-green md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-70 report-name"},[_c('span',{staticClass:"arrow_left"},[_c('router-link',{attrs:{"to":{ path: '/dashboard' }}},[_c('img',{attrs:{"src":require("../../../../assets/images/left.svg")}})])],1),_c('h3',[_vm._v(_vm._s(_vm.$t('Detailed Tax Report')))])])]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item filter-section block-bg"},[_c('div',{staticClass:"md-layout search-section"},[_c('div',{staticClass:"md-layout-item md-size-55 pd-l-0"},[_c('date-period-dropdown',{on:{"interval":_vm.setDateType}})],1),_c('div',{staticClass:"md-layout-item md-size-35 pd-l-10"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 pd-l-0",class:[
                  { 'md-size-30': _vm.role() == 'branch_user' },
                  { 'md-size-30': _vm.role() != 'branch_user' } ]},[_c('label',[_vm._v("Category")]),_c('md-field',[_c('md-select',{model:{value:(_vm.data.superCatId),callback:function ($$v) {_vm.$set(_vm.data, "superCatId", $$v)},expression:"data.superCatId"}},_vm._l((_vm.superCatList),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 pd-l-10",class:[
                  { 'md-size-25': _vm.role() == 'branch_user' },
                  { 'md-size-25': _vm.role() != 'branch_user' } ]},[_c('label',[_vm._v("Tax Rate (%)")]),_c('md-field',[_c('md-select',{model:{value:(_vm.data.taxId),callback:function ($$v) {_vm.$set(_vm.data, "taxId", $$v)},expression:"data.taxId"}},_vm._l((_vm.taxList),function(item){return _c('md-option',{key:item,attrs:{"value":item}},[_vm._v(_vm._s(item))])}),1)],1)],1),(_vm.role() == 'branch_user')?_c('div',{staticClass:"md-layout-item md-size-45 md-small-size-45 pd-l-10"},[_c('label',[_vm._v("Outlet")]),_c('md-field',[_c('md-select',{attrs:{"multiple":""},on:{"md-closed":function($event){_vm.outletId.length > 0
                        ? _vm.detailedTaxReport()
                        : (_vm.outletId = [])}},model:{value:(_vm.outletId),callback:function ($$v) {_vm.outletId=$$v},expression:"outletId"}},_vm._l((_vm.outletList),function(item){return _c('md-option',{key:item.id,staticClass:"oulet-select-list-item",attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_vm._e()])]),_c('div',{staticClass:"md-layout-item md-size-10"},[_c('div',{staticClass:"float-right"},[_c('md-button',{staticClass:"md-teritiary",on:{"click":function($event){return _vm.detailedTaxReport()}}},[_vm._v("Search ")])],1)])])]),(_vm.loader)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{attrs:{"loading":_vm.loader,"color":'#00E0DB'}})],1):_vm._e(),(!_vm.loader)?_c('div',{staticClass:"md-layout-item md-size-100 card-inside report-table"},[_c('div',{staticClass:"xlsx-pdf"},[_c('img',{staticClass:"pdf",attrs:{"src":require("../../../../assets/images/pdf.svg")},on:{"click":function($event){return _vm.downloadWithoutCSS()}}}),_c('vue-excel-xlsx',{staticClass:"excel-btn2 md-primary",attrs:{"data":_vm.reportApiRes,"columns":_vm.columns,"filename":'Detailed Tax Report',"sheetname":'Detailed Tax Report',"outlet-names":_vm.selectedOutletsNames()}})],1),(!_vm.loader)?_c('md-table',{staticClass:"rep-table table-typ1"},[_c('md-table-row',[_c('md-table-head',[_vm._v("SKU")]),_c('md-table-head',[_vm._v("Order Type")]),_c('md-table-head',[_vm._v("Category")]),_c('md-table-head',[_vm._v("Menu Item Name")]),_c('md-table-head',[_vm._v("Sales Qty")]),_c('md-table-head',[_vm._v("Tax Rate")]),_c('md-table-head',[_vm._v("Sales Ex Tax")]),_c('md-table-head',[_vm._v("Discount")]),_c('md-table-head',[_vm._v("Tax On Sales")]),_c('md-table-head',[_vm._v("Sales Value")])],1),_vm._l((_vm.reportApiRes),function(item){return _vm._t("default",[_c('md-table-row',{attrs:{"slot":"md-table-row"},slot:"md-table-row"},[_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.sku))]),_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.order_type))]),_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.category))]),_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.menu_item_name))]),_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.sales_qty))]),_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.tax_rate))]),_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.sales_ex_tax))]),_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.discount))]),_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.tax_on_sale))]),_c('md-table-cell',{class:{
                  'text-bold': item.is_bold,
                }},[_vm._v(_vm._s(item.sales_value))])],1)])})],2):_vm._e()],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }