import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue'
import ScreenDesign from '@/pages/Dashboard/ScreenDesign/ScreenDesign.vue'
import MenuList from '@/pages/Dashboard/ScreenDesign/ScreenList.vue'
import MenuForm from '@/pages/Dashboard/ScreenDesign/ScreenForm.vue'
import LinkOutlets from '@/pages/Dashboard/ScreenDesign/LinkOutlets.vue'
import ScreenOutletLink from '@/pages/Dashboard/ScreenDesign/ScreenOutletLink.vue'


export const screenDesignModule = {
  children: [
    {
      path: 'screen-design',
      name: 'screenDesign',
      components: { default: ScreenDesign },
    },
    {
      path: 'screen-design/menu-list',
      name: 'ScreenMenus',
      components: { default: MenuList },
    },
    {
      path: 'screen-design/menu-form',
      name: 'ScreenMenuForm',
      components: { default: MenuForm },
    },
    {
      path: 'screen-design/assign-outlets',
      name: 'assignOutlets',
      components: { default: LinkOutlets },
    },
    {
      path: 'screen-design/assign-screen-outlets',
      name: 'assignScreenOutlets',
      components: { default: ScreenOutletLink },
    },
  ],
}
