<template>
  <div>
    <md-card class="hourly-sales report-card block-bg">
      <md-card-header class="md-card-header-text md-layout md-gutter">
        <div class="md-layout-item md-size-100">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Hourly Sales') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout filter-section block-bg">
          <div class="md-layout search-options">
            <div class="md-layout-item md-size-60">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
              <!-- <Date-Time-Picker
              @SendFrom="fromDateTime"
              @SendTo="toDateTime"
            ></Date-Time-Picker> -->
              <!-- <ad-datepicker @startDate="fromDateTime" @endDate="toDateTime"></ad-datepicker> -->
            </div>
            <div
              class="md-layout-item md-size-25"
              v-if="userRole == 'branch_user'"
            >
              <md-field>
                <label>Outlets</label>
                <md-select
                  v-model="reportQueries.outletId"
                  multiple
                  @md-closed="
                    reportQueries.outletId.length > 0
                      ? hourlySales()
                      : (reportQueries.outletId = [])
                  "
                >
                  <md-option
                    v-for="item in outlets"
                    :key="item.id"
                    :value="item.id"
                    class="oulet-select-list-item"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-15" md-alignment="right">
              <md-button class="md-teritiary" @click="hourlySales()"
                >SEARCH</md-button
              >
            </div>
          </div>
        </div>
        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>
        <h4 class="center" v-if="!loader">
          Hourly Sales Summary- {{ sdate }} to {{ edate }}
        </h4>

        <div class="md-layout">
          <div class="chart-container" style="position: relative; width: 50vw">
            <bar-chart
              :chart-data="datacollection"
              :options="chartOptions"
            ></bar-chart>
          </div>
        </div>

        <div class="md-layout" v-if="!loader">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>Terminals</label>
              <md-select
                v-model="reportQueries.terminal_id"
                multiple
                v-on:input="hourlySales()"
              >
                <md-option :value="'undefined'">All</md-option>
                <slot v-for="item in terminals">
                  <md-option :value="item.id">{{ item.name }}</md-option>
                </slot>
              </md-select>
            </md-field>
          </div>
          <!-- <div class="md-layout-item md-size-50"></div> -->
          <div
            class="report-table md-layout-item hourly-sale-table md-size-100"
          >
            <div ref="content" id="mytable">
              <md-table class="table-typ1">
                <md-table-toolbar>
                  <div class="action-buttons export-btn">
                    <div class="pdf-el">
                      <div class="download-btn">
                        <img
                          src="../../../../assets/images/pdf.svg"
                          @click="downloadWithoutCSS()"
                        />
                      </div>
                    </div>

                    <div class="excel-el">
                      <vue-excel-xlsx
                        class="Excel-btn"
                        :data="reports"
                        :columns="columns"
                        :filename="'hourly-sales'"
                        :sheetname="'Hourly Sales'"
                        :sdate="sdate"
                        :edate="edate"
                        :outlet-names="selectedOutletsNames()"
                      >
                      </vue-excel-xlsx>
                    </div>
                  </div>
                </md-table-toolbar>
                <md-table-row>
                  <md-table-head>Hour</md-table-head>
                  <md-table-head>No.of Transactions</md-table-head>
                  <md-table-head>Cash Sale</md-table-head>
                  <md-table-head>Card Sale</md-table-head>
                  <md-table-head>Other Amount</md-table-head>
                  <md-table-head>Voucher</md-table-head>
                  <md-table-head>Avg/Sales</md-table-head>
                  <md-table-head>Total Sales</md-table-head>
                </md-table-row>

                <md-table-row v-for="(item, index) in reports" :key="index">
                  <md-table-cell>{{ item.hour }}</md-table-cell>
                  <md-table-cell>{{ item.no_of_transactions }}</md-table-cell>
                  <md-table-cell>{{ item.cash_sales }}</md-table-cell>
                  <md-table-cell>{{ item.card_sales }}</md-table-cell>
                  <md-table-cell>{{ item.other_amount }}</md-table-cell>
                  <md-table-cell>{{ item.voucher }}</md-table-cell>
                  <md-table-cell>{{ item.avg_sales }}</md-table-cell>
                  <md-table-cell>{{ item.total_sales }}</md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { accountService } from '../../../../services/account.js'
import { reportService } from '../../../../services/reports.js'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import LineChart from '../../../../components/Graphs/LineChart.vue'
import BarChart from '../../../../components/Graphs/BarChart.vue'

import DateTimePicker from '../../../../components/DateTimePicker.vue'
import { customizeService } from '../../../../services/customize.js'
import AdDatepicker from '../../../../components/AdDatepicker.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'

export default {
  components: {
    LineChart,
    BarChart,
    DateTimePicker,
    AdDatepicker,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
  },
  data() {
    return {
      datacollection: null,
      sdate: '',
      edate: '',
      reports: [],
      search: null,
      searched: [],
      reportQueries: {
        outletId: [],
        terminal_id: [],
        search_type: 'Daily',
      },
      columns: [
        {
          label: 'Hour',
          field: 'hour',
        },
        {
          label: 'No.of Transactions',
          field: 'no_of_transactions',
        },
        {
          label: 'Cash Sale',
          field: 'cash_sales',
        },
        {
          label: 'Card Sale',
          field: 'card_sales',
        },
        {
          label: 'Other Amount',
          field: 'other_amount',
        },
        {
          label: 'Voucher',
          field: 'voucher',
        },
        {
          label: 'Average Sales',
          field: 'avg_sales',
        },
        {
          label: 'Total Sales',
          field: 'total_sales',
        },
      ],
      outlets: [],
      userRole: '',
      loader: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },

        layout: {
          padding: {
            left: 10,
            right: 20,
            top: 30,
            bottom: 0,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontSize: 14,
                suggestedMin: 0,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 14,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
      terminals: [],
    }
  },

  methods: {
    fillData() {
      this.datacollection = {
        labels: [
          '1:00',
          '2:00',
          '3:00',
          '4:00',
          '5:00',
          '6:00',
          '7:00',
          '8:00',
          '9:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
          '00:00',
        ],
        datasets: [
          {
            label: 'Total Sales',
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0,
            ],
            fill: 'false',
            borderColor: 'red',
            backgroundColor: '#00E0DB',
          },
        ],
      }

      for (var i = 0; i < this.reports.length; i++) {
        if (this.reports[i].hour != 'Total') {
          let timeIndex = this.datacollection.labels.findIndex(
            (item) => item == this.reports[i].hour
          )
          this.datacollection.datasets[0].data[timeIndex] =
            this.reports[i].total_sales
        }
      }
    },

    arrangeForPdf() {
      var body = []
      this.reports.forEach((el) => {
        let arrayObj = Object.values(el)
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.reportQueries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      this.reportQueries.startDate = this.sdate
      this.reportQueries.endDate = this.edate
      let selectedOutlets = this.selectedOutletsNames()

      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })
      let head = [
        [
          'Hour',
          'No.of Txns',
          'Cash Sale',
          'Card Sale',
          'Other Amt',
          'Voucher',
          'Avg/Sales',
          'Total Sales',
        ],
      ]
      pdfService.headerFooterConfig(
        this.reportQueries,
        doc,
        'Hourly Sales',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'Hourly-sales.pdf')
    },

    fromDateTime(date) {
      this.reportQueries.startDate = date
    },

    toDateTime(date) {
      this.reportQueries.endDate = date
    },

    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminals = res.data
      })
    },

    setDateType(interval) {
      this.reportQueries.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.reportQueries.startDate = interval.startDate
        this.reportQueries.endDate = interval.endDate
      } else {
        this.reportQueries.startDate = interval.date
        this.reportQueries.endDate = undefined
        this.hourlySales()
      }
    },

    hourlySales() {
      this.loader = true
      if (this.reportQueries.terminal_id.includes('undefined')) {
        this.reportQueries.terminal_id = this.terminals.map(({ id }) => id)
      }
      reportService.getHourlySales(this.reportQueries).then((res) => {
        this.loader = false
        this.reports = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate

        this.fillData()
      })
    },
  },

  created() {
    // this.searched = this.users
    this.loader = true
    this.userRole = accountService.userRole()
    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.reportQueries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.hourlySales()
        this.getTerminals()
      })
    } else {
      this.hourlySales()
      this.getTerminals()
    }
  },

  mounted() {
    // this.fillData()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.search-options {
  padding-top: 12px;
  padding-left: 34px;
}
input {
  height: 24px;
}
.md-field {
  margin-bottom: 0px;
}
</style>
