<template>
  <div class="subcat-report">
    <md-card class="report-card block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 report-name">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Voids reason Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout md-layout-item filter-section block-bg">
            <div class="md-layout-item md-size-55">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout-item md-size-45">
              <div class="md-layout search-section">
                <div class="md-layout-item md-size-70 pd-l-0">
                  <div class="md-layout">
                    <div
                      :class="[
                        { 'md-size-50': role() == 'branch_user' },
                        { 'md-size-35': role() != 'branch_user' },
                      ]"
                      class="md-layout-item md-small-size-50 terminal-cls category-select"
                    >
                      <label>Terminals</label>
                      <md-field>
                        <md-select v-model="data.terminalId">
                          <md-option
                            v-for="item in terminalsList"
                            :key="item.id"
                            :value="item.id"
                            >{{ item.name }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div
                      class="md-layout-item md-size-50 md-small-size-50 pd-lf-mbl-o category-select"
                      v-if="role() == 'branch_user'"
                    >
                      <label>Outlet</label>
                      <md-field>
                        <md-select
                          v-model="outletId"
                          multiple
                          @md-closed="
                            outletId.length > 0 ? voidReport() : (outletId = [])
                          "
                        >
                          <md-option
                            v-for="item in outletList"
                            :key="item.id"
                            class="oulet-select-list-item"
                            :value="item.id"
                            >{{ item.name }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-30 mt-13">
                  <div class="float-right">
                    <md-button class="md-teritiary" @click="voidReport()">
                      Search
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 card-inside report-table">
            <div class="loader-container" v-if="loader">
              <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
            </div>

            <md-table v-if="!loader" class="table-typ1">
              <!-- <md-table-toolbar>
              <div class="md-toolbar-section-start"></div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable" />
              </md-field>
            </md-table-toolbar>-->
              <md-table-toolbar>
                <div class="action-buttons export-btn">
                  <div class="pdf-el">
                    <div class="download-btn">
                      <img
                        src="../../../../assets/images/pdf.svg"
                        @click="downloadWithoutCSS()"
                      />
                    </div>
                  </div>

                  <div class="excel-el">
                    <vue-excel-xlsx
                      class="Excel-btn"
                      :data="reportApiRes"
                      :columns="columns"
                      :filename="'Voids Report'"
                      :sheetname="'Voids Report'"
                      :outlet-names="selectedOutletsNames()"
                    >
                    </vue-excel-xlsx>
                  </div>
                </div>
              </md-table-toolbar>
              <md-table-row>
                <md-table-head>Item</md-table-head>
                <slot v-for="item in headers">
                  <md-table-head> {{ item.title }}</md-table-head>
                </slot>
              </md-table-row>

              <slot v-for="value in reportApiRes">
                <md-table-row slot="md-table-row">
                  <md-table-cell>{{ value.item }}</md-table-cell>
                  <slot v-for="header in headers">
                    <md-table-cell>{{
                      value[header.quick_note_id]
                    }}</md-table-cell>
                  </slot>
                </md-table-row>
              </slot>
            </md-table>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { accountService } from '../../../../services/account.js'
import { reportService } from '../../../../services/reports'
import { customizeService } from '../../../../services/customize'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
export default {
  components: {
    ChartCard,
    DatePeriodDropdown,
    ClipLoader,
    VueExcelXlsx,
  },
  data() {
    return {
      columns: [
        {
          label: 'Menu Item	',
          field: 'menu_item',
        },
        {
          label: 'Qty',
          field: 'qty',
        },
        {
          label: 'Value',
          field: 'vale',
        },
        {
          label: '% Total',
          field: 'percentage_total',
        },
        {
          label: 'Notes',
          field: 'itemsString',
        },
      ],

      data: {
        search_type: 'Daily',
      },

      reportApiRes: [],
      dataResult: [],
      headers: [],
      terminalsList: [],
      outletList: [],
      outletId: [],
      loader: false,
    }
  },

  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
    },

    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
      }
      if (!this.loader) {
        this.voidReport()
      }
    },

    arrangeForPdf() {
      var body = []
      this.reportApiRes.forEach((el) => {
        let arrayObj = [
          el.item,
          el.menu_item,
          el.qty,
          el.vale,
          el.percentage_total,
          el.itemsString,
        ]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })
      // this.data.startDate = this.sdate
      // this.data.endDate = this.edate
      let head = [['SKU', 'Menu Item', 'Qty', 'Value', '% Total', 'Notes']]
      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Voids Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'voids-report.pdf')
    },
    voidReport() {
      this.loader = true
      this.data.outletId = this.outletId

      if (this.$route.query.s && this.$route.query.e) {
        this.data.search_type = 'Ad Hoc'
        this.data.startDate = this.$route.query.s
        this.data.endDate = this.$route.query.e
      }
      reportService.getVoidReportAnalysys(this.data).then((res) => {
        this.loader = false

        this.reportApiRes = res.data.result
        this.headers = res.data.headers

        // for (let i = 0; i < this.reportApiRes.length; i++) {
        //   let obj = { name: this.reportApiRes[i].name }
        //   let notes = {}
        //   for (let j = 0; j < this.headers.length; j++) {
        //     // notes[`key${j}`] = this.reportApiRes[i][
        //     //   this.headers[j].quick_note_id
        //     // ]
        //     notes[`key${j}`] = 23
        //   }
        //   let data = { ...obj, ...notes }
        //   this.dataResult.push(data)
        // }
      })
    },
    role() {
      return accountService.userRole()
    },
    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminalsList = res.data
        this.terminalsList.unshift({ id: 'undefined', name: 'All' })
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.voidReport()
      })
    },
  },

  created() {
    this.searched = this.users
    this.loader = true
    this.getTerminals()
    this.getOutlets()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}
.md-table.md-theme-default .md-table-content {
  display: none;
}
.pd-l-0 {
  padding-left: 0px;
}

.md-button-content {
  font-size: 12px;
}
.menu-item-rep .md-ripple {
  padding: 6px 15px;
  height: auto !important;
  border-radius: 0px;
}
.content .menu-item-rep .md-ripple {
  padding: 0px !important;
}
.card-inside {
  .md-button {
    .md-ripple {
      padding: 0px 0px;
    }
  }
  .md-content {
    width: 100%;
  }
}
.rep-table {
  margin-top: 0px;
}
.terminal-cls {
  padding-left: 0px;
  padding-right: 22px;
}
.mt-13 {
  margin-top: 13px;
}
</style>
