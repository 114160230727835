var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout client-table"},[_c('div',{staticClass:"md-layout-item"},[_c('md-card',{staticClass:"pagination-card"},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-green d-flex align-items-center"},[_c('h3',{staticClass:"title mt-0 mb-0"},[_vm._v("Menu Item")])]),_c('md-card-content',[_c('md-table',{staticClass:"paginated-table table-striped table-hover itemized-txn",class:{},attrs:{"value":_vm.queriedData,"md-sort":_vm.currentSort,"md-sort-order":_vm.currentSortOrder,"md-sort-fn":_vm.customSort},on:{"update:mdSort":function($event){_vm.currentSort=$event},"update:md-sort":function($event){_vm.currentSort=$event},"update:mdSortOrder":function($event){_vm.currentSortOrder=$event},"update:md-sort-order":function($event){_vm.currentSortOrder=$event},"md-selected":_vm.viewClientDetail},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Name","md-sort-by":"Name"}},[_c('div',{on:{"click":function($event){return _vm.viewClientDetail(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('md-table-cell',{attrs:{"md-label":"Online Section","md-sort-by":"City"}},[_c('div',{on:{"click":function($event){return _vm.viewClientDetail(item)}}},[_vm._v(" "+_vm._s(item.menu_section_name)+" ")])]),_c('md-table-cell',{attrs:{"md-label":"Type"}},[_c('div',{on:{"click":function($event){return _vm.viewClientDetail(item)}}},[_vm._v(_vm._s(item.age))])]),_c('md-table-cell',{staticClass:"center",attrs:{"md-label":"Status"}},[_c('div',{on:{"click":function($event){return _vm.viewClientDetail(item)}}},[_c('i',{staticClass:"fa fa-check check-align",attrs:{"aria-hidden":"true"}})])]),_c('md-table-cell',{staticClass:"center",attrs:{"md-label":"Actions"}},[_c('div',{staticClass:"border-edit"},[_c('md-menu',{attrs:{"md-size":"medium","md-offset-x":-227,"md-offset-y":-16}},[_c('i',{staticClass:"material-icons text_align-center menu-icon",attrs:{"md-menu-trigger":""}},[_vm._v("more_vert")]),_c('md-menu-content',{staticClass:"menuitem-dropdown"},[_c('md-menu-item',{staticClass:"action-header menu-align"},[_vm._v("Actions")]),_c('div',[_c('md-menu-item',[_c('router-link',{attrs:{"to":{
                            name: 'MenuAvailibilityForm',
                            query: {
                              formType: 'Edit Menu Item',
                              client: item.menu_item_id,
                              outlet_id: item.outlet_id,
                            },
                          }}},[_c('md-menu-item',[_c('span',{staticClass:"edit-align"},[_c('i',{staticClass:"fas fa-pencil-alt"}),_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")])])],1)],1)],1)],1)],1)],1)])],1)}}])},[_c('md-table-toolbar',{staticClass:"table-toolbar-align"},[_c('md-field',[_c('label',[_vm._v("Search")]),_c('md-input',{staticClass:"mb-3",attrs:{"type":"search","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('i',{staticClass:"fa fa-search search-align",attrs:{"aria-hidden":"true"}})],1)],1)],1)],1),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item layout-border"},[_c('div',{staticClass:"pagination-main"},[_c('div',{staticClass:"rowsperpage"},[_vm._v("Rows Per page")]),_c('div',{staticClass:"pagenation-number"},[_c('md-field',[_c('md-select',{attrs:{"name":"pages"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('md-option',{key:item,attrs:{"label":item,"value":item}},[_vm._v(_vm._s(item))])}),1)],1)],1),_c('div',{staticClass:"pagination-warp"},[_c('p',{staticClass:"card-category pagination-text-align"},[_vm._v(" From "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" ")]),_c('pagination',{staticClass:"pagination-no-border pagination-success",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }