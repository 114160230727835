<template>
  <form class="md-layout-item pd-l">
    <md-card class="pd-l">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left ml-auto">
            <router-link :to="{ path: '/products/Portions' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType == 'View Portion'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editPortion"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader" class="block-bg pad-xy rounded-xl">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('seqPos') },
                { 'md-valid': !errors.has('seqPos') && touched.seqPos },
              ]"
            >
              <label>Sequence Position</label>
              <md-input
                v-model="portion.port_order"
                data-vv-name="seqPos"
                type="text"
                v-validate="modelValidations.seqPos"
                :disabled="formType == 'View Portion'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('seqPos')">
                  {{ errors.first('seqPos') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('seqPos') && touched.seqPos"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>Name*</label>
              <md-input
                v-model="portion.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Portion'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name }
              ]"
            >
              <label>Name*</label>
              <md-input
                v-model="portion.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Portion'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">{{errors.first("Name")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('Name') && touched.Name">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Qty') },
                { 'md-valid': !errors.has('Qty') && touched.Qty },
              ]"
            >
              <label>Quantity*</label>
              <md-input
                v-model="portion.quality"
                data-vv-name="Qty"
                type="text"
                v-validate="modelValidations.Qty"
                :disabled="formType == 'View Portion'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Qty')">
                  {{ errors.first('Qty') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Qty') && touched.Qty"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('measurement') },
                {
                  'md-valid': !errors.has('measurement') && touched.measurement,
                },
              ]"
            >
              <label>Measurement*</label>
              <md-select
                v-model="portion.mesharment"
                :disabled="formType == 'View Portion'"
                data-vv-name="measurement"
                v-validate="modelValidations.measurement"
              >
                <md-option class="first-opt" disabled>Measurement</md-option>
                <md-option value="Milli Liter">Milli Liter</md-option>
                <md-option value="Gram">Gram</md-option>
                <md-option value="Kilo Gram">Kilo Gram</md-option>
                <md-option value="Unit">Unit</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('measurement')">
                  {{ errors.first('measurement') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('measurement') && touched.measurement"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('measurement') },
                { 'md-valid': !errors.has('measurement') && touched.measurement }
              ]"
            >
              <label>Measurement*</label>
              <md-select v-model="portion.mesharment" :disabled="formType == 'View Portion'" data-vv-name="measurement"
                v-validate="modelValidations.measurement">
                <md-option value="Milli Liter">Milli Liter</md-option>
                <md-option value="Gram">Gram</md-option>
                <md-option value="Kilo Gram">Kilo Gram</md-option>
                <md-option value="Unit">Unit</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('measurement')">{{errors.first("measurement")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('measurement') && touched.measurement">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Status') },
                { 'md-valid': !errors.has('Status') && touched.Status },
              ]"
            >
              <label>Status</label>
              <md-select
                v-model="portion.status"
                :disabled="formType == 'View Portion'"
                data-vv-name="Status"
                v-validate="modelValidations.Status"
              >
                <md-option class="first-opt" disabled>Status</md-option>
                <md-option value="true">Active</md-option>
                <md-option value="false">Inactive</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Status')">
                  {{ errors.first('Status') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Status') && touched.Status"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Portion'">
          <md-card-actions class="text-center">
            <md-button native-type="submit" :to="{ path: '/products/Portions' }"
              >CANCEL</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >SAVE</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import axios from 'axios'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import { accountService } from '../../../services/account'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      measurement: '',
      Qty: '',
      seqpos: '',
      Status: '',
      switch2: null,
      Name: '',
      touched: {
        Name: false,
        measurement: false,
        Status: false,
        Qty: false,
        seqpos: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
        Qty: {
          required: true,
        },
        measurement: {
          required: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Client',
      },
      clientDetail: {
        default: '',
      },
      portion: {
        status: true,
      },
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createPortion()
          }
        })
      }
    },

    createPortion() {
      let self = this
      this.portion.chain_id = accountService.getChainId()
      if (this.formType == 'Add Portion') {
        customizeService.createPortions(this.portion).then((res) => {
          self.$router.push({ name: 'portions' })
        })
      } else {
        let body = {
          portion: this.portion,
        }
        axios
          .put(this.url + '.json', body, customizeService.authFun())
          .then((res) => {
            self.$router.push({ name: 'portions' })
          })
      }
    },

    reset() {
      this.portion = {}
    },

    editPortion() {
      this.formType = 'Edit Portion'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    if (this.formType != 'Add Portion') {
      this.loader = true
      axios.get(this.url, customizeService.authFun()).then((res) => {
        this.loader = false
        this.portion = res.data
      })
    }
  },
  watch: {
    Name() {
      this.touched.required = true
    },
    Qty() {
      this.touched.required = true
    },
    measurement() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
