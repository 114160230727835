var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-div password-reset"},[_c('div',{staticClass:"md-layout text-center login-card d-flex align-items-center h-90vh"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xlarge-size-40 md-large-size-40"},[_c('form',{staticClass:"md-layout-item"},[_c('md-card',[_c('h4',{staticClass:"card-header"},[_c('a',{attrs:{"href":"#/login"}},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/assets/images/cbe_innova_logo.png")}})])]),_c('p',{staticClass:"h3"},[_vm._v("Welcome To CBEInnova")]),(!_vm.loader)?_c('md-card-content',[_c('div',{staticClass:"md-layout d-flex align-items-center flex-column"},[_c('div',{staticClass:"md-layout-item md-size-75 p-0 position-relative"},[_c('ul',[_c('li',{class:{ is_valid: _vm.contains_eight_characters }},[_vm._v(" 8 Characters ")]),_c('li',{class:{ is_valid: _vm.contains_number }},[_vm._v(" Contains Number ")]),_c('li',{class:{ is_valid: _vm.contains_uppercase }},[_vm._v(" Contains Uppercase ")]),_c('li',{class:{ is_valid: _vm.contains_special_character }},[_vm._v(" Contains Special Character ")])]),_c('div',{staticClass:"checkmark_container",class:{ show_checkmark: _vm.valid_password }},[_c('svg',{attrs:{"width":"50%","height":"50%","viewBox":"0 0 140 100"}},[_c('path',{staticClass:"checkmark",class:{ checked: _vm.valid_password },attrs:{"d":"M10,50 l25,40 l95,-70"}})])])]),_c('div',{staticClass:"md-layout-item md-size-75"},[_c('md-field',{class:[
                    {
                      'md-error':
                        _vm.errors.has('new_password') || !_vm.valid_password,
                    },
                    {
                      'md-valid':
                        !_vm.errors.has('new_password') && _vm.touched.new_password,
                    } ]},[_c('label',[_vm._v(_vm._s(_vm.$t('New Password'))+"*")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.new_password),expression:"modelValidations.new_password"}],attrs:{"data-vv-name":"new_password","type":"password"},model:{value:(_vm.password.new_password),callback:function ($$v) {_vm.$set(_vm.password, "new_password", $$v)},expression:"password.new_password"}}),_c('slide-y-down-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('new_password')),expression:"errors.has('new_password')"}],staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first('new_password'))+" ")])]),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(
                        !_vm.errors.has('new_password') && _vm.touched.new_password
                      ),expression:"\n                        !errors.has('new_password') && touched.new_password\n                      "}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-size-75"},[_c('md-field',{class:[
                    { 'md-error': _vm.errors.has('confirmPassword') },
                    {
                      'md-valid':
                        !_vm.errors.has('confirmPassword') &&
                        _vm.touched.confirmPassword,
                    } ]},[_c('label',[_vm._v(_vm._s(_vm.$t('Confirm Password'))+"*")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.confirmPassword),expression:"modelValidations.confirmPassword"}],attrs:{"data-vv-name":"confirmPassword","type":"password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('slide-y-down-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('confirmPassword')),expression:"errors.has('confirmPassword')"}],staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first('confirmPassword'))+" ")])]),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(
                        !_vm.errors.has('confirmPassword') &&
                        _vm.touched.confirmPassword
                      ),expression:"\n                        !errors.has('confirmPassword') &&\n                        touched.confirmPassword\n                      "}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-size-100"},[(_vm.formError != '')?_c('div',{staticClass:"error-msg text-danger"},[_vm._v(" "+_vm._s(_vm.formError)+" ")]):_vm._e()])]),_c('div',{staticClass:"btn-div"},[_c('md-card-actions',{staticClass:"text-center mt-4"},[_c('md-button',{staticClass:"md-teritiary",attrs:{"native-type":"submit"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.validate($event)}}},[_vm._v(_vm._s(_vm.$t('SAVE')))])],1)],1)]):_vm._e()],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }