<template>
  <div class="subcat-report menu-item-rep">
    <md-card class="report-card block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 report-name">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Terminal Transactions') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout filter-section block-bg">
          <div class="md-layout search-options">
            <div class="md-layout-item">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout-item md-size-25">
              <div v-if="role() == 'branch_user'">
                <md-field>
                  <label>Outlet</label>
                  <md-select
                    v-model="outletId"
                    multiple
                    @md-closed="
                      outletId.length > 0
                        ? terminalTransactions()
                        : (outletId = [])
                    "
                  >
                    <md-option
                      v-for="item in outletList"
                      :key="item.id"
                      :value="item.id"
                      class="oulet-select-list-item"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout-item md-size-15">
              <md-button class="md-teritiary" @click="terminalTransactions()"
                >Search
              </md-button>
            </div>
          </div>
        </div>

        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div
          class="md-layout-item md-size-100 card-inside report-table"
          v-if="!loader"
        >
          <div class="pad-b-15px pad-r-15px xlsx-pdf">
            <img
              class="pdf"
              src="../../../../assets/images/pdf.svg"
              @click="downloadWithoutCSS()"
            />
            <vue-excel-xlsx
              class="excel-btn2 md-primary"
              :data="report"
              :columns="columns"
              :filename="'Terminal Transaction Report'"
              :sheetname="'Terminal Transaction'"
              :sdate="sdate"
              :edate="edate"
              :outlet-names="selectedOutletsNames()"
            ></vue-excel-xlsx>
          </div>

          <md-table class="rep-table table-typ1" v-if="!loader">
            <md-table-row>
              <md-table-head>Terminals</md-table-head>
              <md-table-head
                v-if="$store.state.account.profile.user_type == 'branch_user'"
                >Outlet</md-table-head
              >
              <md-table-head>Total Bills</md-table-head>
              <md-table-head>Total Customers</md-table-head>
              <md-table-head>Cash</md-table-head>
              <md-table-head>Credit</md-table-head>
              <md-table-head>Other</md-table-head>
              <md-table-head>Voucher</md-table-head>
              <md-table-head>Gratuity</md-table-head>
              <md-table-head>Service Charge</md-table-head>
              <md-table-head>Total</md-table-head>
            </md-table-row>
            <slot v-for="item in report">
              <md-table-row
                slot="md-table-row"
                :class="{ 'font-bold': item.is_bold }"
              >
                <md-table-cell>{{ item.terminal }}</md-table-cell>
                <md-table-cell
                  v-if="$store.state.account.profile.user_type == 'branch_user'"
                  >{{ item.outlet_name }}</md-table-cell
                >
                <md-table-cell>{{ item.total_bills }}</md-table-cell>
                <md-table-cell>{{ item.total_customers }}</md-table-cell>
                <md-table-cell>{{ item.cash }}</md-table-cell>
                <md-table-cell>{{ item.credit }}</md-table-cell>
                <md-table-cell>{{ item.other }}</md-table-cell>
                <md-table-cell>{{ item.voucher }}</md-table-cell>
                <md-table-cell>{{ item.tips }}</md-table-cell>
                <md-table-cell>{{ item.service_charge }}</md-table-cell>
                <md-table-cell>{{ item.total }}</md-table-cell>
              </md-table-row>
            </slot>
          </md-table>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { reportService } from './../../../../services/reports'
import { customizeService } from './../../../../services/customize'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import { DateTimePicker } from '@/components'
import { accountService } from '../../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
import store from '@/store'

export default {
  components: {
    ChartCard,
    DateTimePicker,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
  },
  data() {
    return {
      columns: [
        {
          label: 'Terminal',
          field: 'terminal',
        },
        {
          label: 'Total Bills',
          field: 'total_bills',
        },
        {
          label: 'Total Customers',
          field: 'total_customers',
        },
        {
          label: 'Cash',
          field: 'cash',
        },
        {
          label: 'Credit',
          field: 'credit',
        },
        {
          label: 'Other',
          field: 'other',
        },
        {
          label: 'Voucher',
          field: 'voucher',
        },
        {
          label: 'Gratuity',
          field: 'tips',
        },
        {
          label: 'Service charge',
          field: 'service_charge',
        },
        {
          label: 'Total',
          field: 'total',
        },
      ],
      columnStyles: {
        0: { cellWidth: 26 },
        1: { cellWidth: 26 },
        2: { cellWidth: 26 },
        3: { cellWidth: 26 },
      },
      data: {
        search_type: 'Daily',
      },
      report: [],
      taxList: [],
      outletList: [],
      outletId: [],
      loader: false,
      sdate: '',
      edate: '',
    }
  },

  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = [
          el.terminal,
          el.total_bills,
          el.total_customers,
          el.cash,
          el.credit,
          el.other,
          el.voucher,
          el.tips,
          el.service_charge,
          el.total,
        ]
        if (store.state.account.profile.user_type == 'branch_user') {
          arrayObj.splice(1, 0, el.outlet_name) // Add at index 1 (second position)
        }
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })
      let selectedOutlets = this.selectedOutletsNames()
      this.data.startDate = this.sdate
      this.data.endDate = this.edate

      let head = [
        [
          'Terminal',
          'TOTAL BILLS',
          'TOTAL CUST.',
          'Cash',
          'Credit',
          'Other',
          'Voucher',
          'Tips',
          'SERVICE CHARGE',
          'Total',
        ],
      ]

      if (store.state.account.profile.user_type == 'branch_user') {
        head[0].splice(1, 0, 'Outlet') // Add at index 1 (second position)
      }

      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Terminal Transactions',
        selectedOutlets
      )
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        {},
        'terminal-transactions.pdf'
      )
    },
    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
        if (!this.loader) {
          this.terminalTransactions()
        }
      }
    },
    terminalTransactions() {
      this.loader = true
      this.data.outletId = this.outletId
      reportService.terminalTransactions(this.data).then((res) => {
        this.loader = false
        this.report = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.terminalTransactions()
      })
    },
    role() {
      return accountService.userRole()
    },
  },
  mounted() {
    // Conditionally add the new field at the second position
    if (store.state.account.profile.user_type === 'branch_user') {
      this.columns.splice(1, 0, {
        label: 'Outlet',
        field: 'outlet_name', // Replace with the actual field name
      })
    }
  },
  created() {
    this.searched = this.users
    this.loader = true
    if (accountService.userRole() == 'branch_user') {
      this.getOutlets()
    } else {
      this.terminalTransactions()
    }
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}
.md-table.md-theme-default .md-table-content {
  display: none;
}
.pd-l-0 {
  padding-left: 0px;
}

.md-button-content {
  font-size: 12px;
}
.menu-item-rep .md-ripple {
  padding: 6px 15px;
  height: auto !important;
  border-radius: 0px;
}
.content .menu-item-rep .md-ripple {
  padding: 0px !important;
}
.card-inside {
  .md-button {
    .md-ripple {
      padding: 0px 0px;
    }
  }
}
.rep-table {
  margin-top: 0px;
}
.font-bold {
  font-weight: bold;
}
</style>
