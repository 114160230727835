<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-50">
      <md-field
        :class="[
          { 'md-error': errors.has('online.Name') },
          { 'md-valid': !errors.has('online.Name') && touched.Name },
        ]"
      >
        <label>{{ $t('Online Name') }}*</label>
        <md-input
          type="text"
          v-model="onlineDetails.name"
          data-vv-name="Name"
          v-validate="modelValidations.Name"
        ></md-input>
        <slide-y-down-transition>
          <div class="error" v-show="errors.has('online.Name')">
            {{ errors.first('online.Name') }}
          </div>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon
            class="success"
            v-show="!errors.has('online.Name') && touched.Name"
            >done</md-icon
          >
        </slide-y-down-transition>
      </md-field>
    </div>
    <div class="md-layout-item md-size-50">
      <md-field>
        <label>{{ $t('Online Description') }}*</label>
        <md-input type="text" v-model="onlineDetails.description"></md-input>
      </md-field>
    </div>
    <div class="md-layout-item md-size-50">
      <md-field>
        <label>{{ $t('Online Price') }}*</label>
        <md-input type="text" v-model="onlineDetails.price"></md-input>
      </md-field>
    </div>
    <div class="md-layout-item md-size-50">
      <md-field class="select-new">
        <label>{{ $t('Menu Section') }}*</label>
        <md-select
          class="select_btn"
          data-vv-name="mainCat"
          v-model="onlineDetails.menu_section_id"
        >
          <md-option
            :value="item.id"
            v-for="item in menuSections"
            :key="item.id"
            >{{ item.name }}</md-option
          >
        </md-select>
      </md-field>
    </div>

    <div class="md-layout">
      <div class="md-layout-item md-size-50"></div>
      <div class="md-layout-item md-size-100">
        <md-checkbox v-model="onlineModifierGroup">{{
          $t('Modifier Group')
        }}</md-checkbox>
      </div>

      <div
        class="md-layout online-mod-group block-bg"
        v-if="onlineModifierGroup === true"
      >
        <div class="md-layout-item md-size-100">
          <div class="modifier-add md-layout">
            <div
              class="md-layout-item md-size-50 mod-click"
              @click="onlineModDialog = true"
            >
              <h5 class="text-dark">
                {{ $t('Modifier Group') }}
                <i class="md-icon md-icon-font md-theme-default text-dark"
                  >add</i
                >
              </h5>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field v-if="confirmModGroups.length > 0">
                <label>Sort By:</label>
                <md-select v-on:input="updateSortOrder()">
                  <md-option value="0">{{ $t('Priotity') }}</md-option>
                  <md-option value="1">{{ $t('Ascending') }}</md-option>
                  <md-option value="2">{{ $t('Descending') }}</md-option>
                  <md-option value="3">{{ $t('Custom') }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-100">
          <draggable class="list-group" group="people">
            <transition-group>
              <div
                class="online-list-group-item"
                v-for="(element, index) in confirmModGroups"
                :key="`key-${index}`"
              >
                {{ element.name }}
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <md-field>
          <input
            class="custom-file-input"
            type="file"
            @change="onFileChanged"
          />
        </md-field>
      </div>
      <div
        class="md-layout-item md-size-50"
        v-if="menuItemInfo.image_url != null && menuItemInfo.image_url != ''"
      >
        <img class="flip-image" :src="menuItemInfo.image_url" />
        <span class="cursor-pointer" @click="deleteImage()">
          Remove Image
        </span>
      </div>
    </div>

    <md-dialog :md-active.sync="onlineModDialog" class="modifier-dialog">
      <md-dialog-title class="md-layout justify-content-between">
        <div class="md-layout-item md-size-50 left-padding">
          Modifier Groups
        </div>
        <div class="close-icon">
          <i
            class="md-icon md-icon-font md-theme-default cursor-pointer"
            @click="onlineModDialog = false"
            >close</i
          >
        </div>
      </md-dialog-title>
      <div class="modifier-checkbox">
        <md-list>
          <md-list-item
            v-for="(item, index) in modGroups"
            :key="`key-${index}`"
          >
            {{ item.name }}
            <md-checkbox
              :model="ModGroupsIds"
              :value="item.id"
              @change="findModifier()"
            ></md-checkbox>
          </md-list-item>
        </md-list>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import axios from 'axios'
import { customizeService } from './../../../services/customize.js'
import { DayBgRow } from '@fullcalendar/daygrid'
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'

export default {
  components: {
    draggable,
    SlideYDownTransition,
  },
  props: {
    modGroups: {
      type: Array,
    },
    onlineModGroups: {
      type: Array,
    },
    onlineData: {
      type: Object,
    },
    price: {
      type: [Number, String],
    },
    name: {
      type: String,
    },
    menuItem: {
      type: Object,
      default() {
        return {}
      },
      // eslint-disable-next-line vue/require-valid-default-prop
    },
  },

  data() {
    return {
      onlineModifierGroup: false,
      modifierGroupList: [],
      confirmModGroups: [],
      onlineModDialog: false,
      selectedModGroups: [],
      ModGroupsIds: [],
      menuSections: [],
      isOnline: false,
      onlineDetails: {},
      menuItemInfo: {},
      image: '',
      touched: {
        Name: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
        menu_section_id: {
          required: true,
        },
        Title: {
          required: true,
        },
        price: {
          required: true,
        },
      },
    }
  },

  methods: {
    onFileChanged(event) {
      this.image = event.target.files[0]
      this.imgUrl = URL.createObjectURL(this.image)
    },

    getMenuSections() {
      customizeService.getMenuSections().then((res) => {
        this.menuSections = res.data.api_v1_menu_sections.filter(
          (el) => el.status == true
        )
      })
    },
    submit(id, outlet_id, successUrl = '') {
      let formData = new FormData()
      formData.append('menu_items[image]', this.image)

      this.modifierGroupList = []
      for (var i = 0; i < this.confirmModGroups.length; i++) {
        this.modifierGroupList.push({
          modifier_group_id: this.confirmModGroups[i].id,
          name: this.confirmModGroups[i].name,
          sort_order: i + 1,
        })
      }

      formData.append(
        'menu_items[online_modifier_groups]',
        JSON.stringify(this.modifierGroupList)
      )

      formData.append(
        'menu_items[online_details]',
        JSON.stringify(this.onlineDetails)
      )

      formData.append(
        'menu_items[online_menu_section_id]',
        JSON.stringify(this.onlineDetails.menu_section_id)
      )
      formData.append('menu_items[outlet_id]', outlet_id)
      formData.append('menu_items[is_online]', this.isOnline)

      // axios.put(url, formData, customizeService.authFun()).then((res) => {
      //   //formData.append("flipdish[query_param[menu_item_id]]",res.data.menu_items.id);
      //   // customizeService.createFlipDish(formData).then(res =>{console.log(res)})
      //   this.$router.push('/products/MenuItem')
      // })
      customizeService.updateOnlineDeatils(id, formData).then((res) => {
        let url = successUrl != '' ? successUrl : '/products/MenuItem'
        this.$router.push(url)
      })
    },

    deleteImage() {
      customizeService.addOrRemoveImageUrl(this.menuItemInfo.id).then((res) => {
        this.menuItemInfo.image_url = ''

        reusableService.showSwal('Update').then((result) => {})
      })
    },

    findModifier() {
      let self = this
      this.confirmModGroups = []
      for (var i = 0; i < this.ModGroupsIds.length; i++) {
        let body = this.modGroups.find(function (el) {
          return el.id == self.ModGroupsIds[i]
        })
        this.confirmModGroups.push(body)
      }
    },

    updateSortOrder() {
      if (this.menuitems.group_sort_order_type == 0) {
        this.priList()
      } else if (this.menuitems.group_sort_order_type == 1) {
        this.orderList()
      } else if (this.menuitems.group_sort_order_type == 2) {
        this.descOrderList()
      } else {
        this.customOrder()
      }
    },
  },

  created() {
    this.getMenuSections()
    this.onlineDetails = this.onlineData
      ? this.onlineData
      : { price: this.price, name: this.name }
    this.menuItemInfo = this.menuItem.id ? this.menuItem : {}
    //Setting Mod Group

    if (this.onlineModGroups && this.onlineModGroups.length > 0) {
      this.onlineModifierGroup = true

      for (var i = 0; i < this.onlineModGroups.length; i++) {
        this.ModGroupsIds.push(this.onlineModGroups[i].modifier_group_id)
      }
    }

    this.findModifier()
  },

  computed: {},
  watch: {
    Name() {
      this.touched.required = true
    },
    onlineModGroups: function (newVal, oldVal) {
      // watch it
      if (newVal.length > 0) {
        this.onlineModifierGroup = true
      }
      for (var i = 0; i < newVal.length; i++) {
        this.ModGroupsIds.push(newVal[i].modifier_group_id)
      }
      this.findModifier()
    },
    onlineData: function (newVal, oldVal) {
      if (newVal) {
        this.onlineDetails = newVal
      }
    },
    menuItem: function (newVal) {
      if (newVal) {
        this.menuItemInfo = newVal
      }
    },
  },

  filters: {},
}
</script>

<style lang="scss" scoped>
.flip-image {
  width: 120px;
  padding: 5px;
}

.btn-div {
  text-align: cen;
}

.online-mod-group {
  min-width: 94%;
  max-width: 94%;
  display: table;
  margin: 10px auto;
  padding-top: 11px;
  padding-bottom: 23px;
  background-color: #eee;
  border-radius: 9px;
}

.list-group {
  padding: 0px 10px;
  width: 100%;
}

.list-group-item {
  margin: 5px;
  padding: 8px;
  background-color: #2b82be;
  color: white;
  border-radius: 5px;
}
</style>
