<template>
  <div class="report-table">
    <md-card class="block-bg pd-l pad-r-0">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Staff Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item filter-section pd-l-0 block-bg">
            <div class="md-layout search-options">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
              <div class="md-layout-item pd-l-0">
                <div class="md-layout">
                  <div
                    :class="[
                      { 'md-size-25': role() == 'branch_user' },
                      { 'md-size-33': role() != 'branch_user' },
                    ]"
                    class="md-layout-item md-small-size-50 pd-l-mbl-o pd-r-10 pd-l0-r15"
                  ></div>
                  <div
                    class="md-layout-item md-size-25 md-small-size-50 pd-l-0 pd-l-7 pd-l-mbl-o"
                    v-if="role() == 'branch_user'"
                  >
                    <label>Outlet</label>
                    <md-field>
                      <md-select
                        v-model="outletId"
                        multiple
                        @md-closed="
                          outletId.length > 0
                            ? itemCategories()
                            : (outletId = [])
                        "
                      >
                        <md-option
                          v-for="item in outletList"
                          :key="item.id"
                          :value="item.id"
                          >{{ item.name }}</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-10">
                <md-button class="md-primary" @click="itemCategories()"
                  >Search
                </md-button>
              </div>
            </div>
          </div>

          <div class="loader-container" v-if="loader">
            <clip-loader
              class="md-layout-item md-size-100"
              :loading="loader"
              :color="'#00E0DB'"
            ></clip-loader>
          </div>

          <div
            class="md-layout-item md-size-100 card-inside report-table"
            v-if="!loader"
          >
            <div class="xlsx-pdf">
              <img
                class="pdf"
                src="../../../../assets/images/pdf.svg"
                @click="downloadWithoutCSS()"
              />
              <vue-excel-xlsx
                class="excel-btn2 md-primary"
                :data="reportApiRes"
                :columns="columns"
                :filename="'Staff Hours Worked'"
                :sheetname="'Staff Hours Worked'"
              ></vue-excel-xlsx>
            </div>
            <div class="md-layout-item md-size-20">
              <md-button class="md-primary mt-15" @click="openNewModal()"
                >New Staff Info
              </md-button>
            </div>
            <md-table class="rep-table table-typ1">
              <md-table-row>
                <md-table-head>Name</md-table-head>
                <md-table-head>Clocked In Time</md-table-head>
                <md-table-head>Clocked Out Time</md-table-head>
                <md-table-head>Total Time</md-table-head>
                <md-table-head>Rounded</md-table-head>
                <md-table-head>Rate</md-table-head>
                <md-table-head>Pay</md-table-head>
                <md-table-head>Clock In Image</md-table-head>
                <md-table-head>Clock Out Image</md-table-head>
                <md-table-head>Action </md-table-head>
              </md-table-row>
              <slot v-for="item in reportApiRes">
                <md-table-row slot="md-table-row">
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                    >{{ item.name }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                    >{{
                      item.id
                        ? moment(item.clocked_in).format('H:mm A DD MMMM YYYY')
                        : item.clocked_in
                    }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                    >{{
                      item.clocked_out != ''
                        ? moment(item.clocked_out).format('H:mm A DD MMMM YYYY')
                        : item.clocked_out
                    }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                    >{{ item.total_time }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                    >{{ item.rounded }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                    >{{ item.rate }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                    >{{ item.pay }}</md-table-cell
                  >

                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                  >
                    <img :src="item.clock_in_img" />
                  </md-table-cell>
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                  >
                    <img :src="item.clock_out_img" />
                  </md-table-cell>
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                  >
                    <div v-if="item.id" class="d-flex justify-content-center">
                      <!-- <md-button
                        class="md-primary md-round"
                        @click="openModal(item)"
                        >Edit</md-button
                      > -->
                      <div @click="openModal(item)" class="mr-2">
                        <md-icon class="edit">edit</md-icon>
                      </div>
                      <!-- <md-button
                        class="md-danger md-round"
                        @click="alert(item.id)"
                        >Delete</md-button
                      > -->
                      <div @click="alert(item.id)">
                        <md-icon class="delete">delete</md-icon>
                      </div>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </slot>
            </md-table>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-dialog
      :md-active.sync="modDialog"
      class="edit-dialog add-staff edit-staff"
    >
      <md-dialog-title>
        <div class="close-icon">
          <i
            class="md-icon md-icon-font md-theme-default"
            @click="modDialog = false"
            >close</i
          >
        </div>
      </md-dialog-title>
      <md-dialog-content>
        <div class="edit-items text-center font-weight-bold">Edit</div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <label>Clock In: </label>
            <datetime format="YYYY-MM-DD H:i" v-model="clockIn"></datetime>
          </div>
          <div class="md-layout-item md-size-50">
            <label>Clock Out:</label>
            <datetime format="YYYY-MM-DD H:i" v-model="clockOut"></datetime>
          </div>
        </div>
        <div class="md-layout-item md-size-20 mx-auto mt-25">
          <md-button class="md-primary" @click="updateEmployeInfo()"
            >Update
          </md-button>
        </div>
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="modDialogNew" class="new-dialog add-staff">
      <md-dialog-title>
        <div class="edit-items text-center font-weight-bold">NEW</div>

        <div class="md-layout-item">
          <div class="close-icon">
            <i
              class="md-icon md-icon-font md-theme-default"
              @click="modDialogNew = false"
              >close</i
            >
          </div>
          <md-field>
            <label>Staffs</label>
            <md-select v-model="selected">
              <md-option
                v-for="item in empList"
                :key="item.outlet_staff_id"
                :value="item.outlet_staff_id"
                >{{ item.name }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </md-dialog-title>
      <md-dialog-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <label>Clock In: </label>
            <datetime format="YYYY-MM-DD H:i" v-model="clockIn"></datetime>
          </div>
          <div class="md-layout-item md-size-50">
            <label>Clock Out:</label>
            <datetime format="YYYY-MM-DD H:i" v-model="clockOut"></datetime>
          </div>
        </div>
        <div class="md-layout-item md-size-20 mx-auto mt-25">
          <md-button class="md-primary" @click="createEmployeInfo()"
            >Create
          </md-button>
        </div>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { reportService } from './../../../../services/reports'
import { customizeService } from './../../../../services/customize'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import { DateTimePicker } from '@/components'
import { accountService } from '../../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
import { reusableService } from '@/services/reusable.js'
import datetime from 'vuejs-datetimepicker'
import moment from 'moment'

export default {
  components: {
    ChartCard,
    DateTimePicker,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
    datetime,
  },
  data() {
    return {
      columns: [
        {
          label: 'Name	',
          field: 'name',
        },
        {
          label: 'Clocked In Time',
          field: 'clocked_in',
        },
        {
          label: 'Clocked Out Time',
          field: 'clocked_out',
        },
        {
          label: 'Total Time',
          field: 'total_time',
        },
        {
          label: 'Total Time',
          field: 'total_time',
        },
        {
          label: 'Rounded',
          field: 'rounded',
        },
        {
          label: 'Rate',
          field: 'rate',
        },
        {
          label: 'Pay',
          field: 'pay',
        },
      ],
      clockIn: '',
      clockOut: '',
      columnStyles: {
        0: { cellWidth: 38 },
        1: { cellWidth: 38 },
        2: { cellWidth: 38 },
        3: { cellWidth: 38 },
        4: { cellWidth: 38 },
        5: { cellWidth: 38 },
        6: { cellWidth: 38 },
        7: { cellWidth: 38 },
      },
      customers: ['All'],
      customer: 'All',

      data: {
        search_type: 'Daily',
        userId: 'All',
      },
      empList: [],
      reportApiRes: [],
      outletList: [],
      outletId: [],
      loader: true,
      modDialog: false,
      modDialogNew: false,
      activeData: null,
      selected: null,
    }
  },
  watch: {
    customer(val) {
      if (val == '') {
        this.data.userId = 'All'
      }
    },
  },
  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
    },

    arrangeForPdf() {
      var body = []
      this.reportApiRes.forEach((el) => {
        let arrayObj = [
          el.name,
          el.clocked_in != '' && el.id != ''
            ? moment(el.clocked_in).format('H:mm A DD MMMM YYYY')
            : el.clocked_in,
          el.clocked_out != '' && el.id != ''
            ? moment(el.clocked_out).format('H:mm A DD MMMM YYYY')
            : el.clocked_out,
          el.total_time,
          el.rounded,
          el.rate,
          el.pay,
        ]
        body.push(arrayObj)
      })
      return body
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })
      let head = [
        [
          'Name	',
          'Clocked In Time',
          'Clocked Out Time',
          'Total Time',
          'Rounded',
          'Rate',
          'Pay',
        ],
      ]
      pdfService.headerFooterConfig(this.data, doc, 'Staff Hours Worked')
      pdfService.autoTableConfig(doc, head, body, {}, 'Staff Hours Worked.pdf')
    },
    getUsers() {
      customizeService.getEmployees().then((res) => {
        this.empList = res.data.employes.filter((el) => el.status == 'active')
      })
    },

    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
      }
      if (!this.loader) {
        this.itemCategories()
      }
    },

    itemCategories() {
      this.loader = true
      this.data.outletId = this.outletId
      reportService.labourReportStaff(this.data).then((res) => {
        this.reportApiRes = res.data.result
        this.loader = false
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })

        this.itemCategories()
      })
    },
    role() {
      return accountService.userRole()
    },

    getSelected() {
      const found = this.empList.find(
        (element) => element.name == this.customer
      )
      this.data.userId = found.outlet_staff_id
    },

    deleteRecord(item) {
      customizeService.deleteEmployeInfo(item).then((res) => {
        this.itemCategories()
      })
    },

    alert(id) {
      var self = this
      reusableService.showSwal('Delete').then((result) => {
        if (result.value) {
          self.deleteRecord(id)
        }
      })
    },

    openModal(item) {
      this.modDialog = true
      this.activeData = item
      this.clockIn = item.clocked_in
      this.clockOut = item.clocked_out
    },
    openNewModal() {
      this.clockOut = ''
      this.modDialogNew = true
      this.clockIn = ''
    },

    updateEmployeInfo() {
      let data = { login_time: this.clockIn, log_out_time: this.clockOut }
      customizeService
        .updateEmployeInfo(data, this.activeData.id)
        .then((res) => {
          this.modDialog = false
          this.itemCategories()
        })
    },

    createEmployeInfo() {
      let data = {
        login_time: this.clockIn,
        log_out_time: this.clockOut,
        employes_id: this.selected,
        outlet_id: accountService.getOutletId(),
      }
      customizeService.createEmployeInfo(data).then((res) => {
        this.modDialogNew = false
        this.itemCategories()
      })
    },
  },

  created() {
    // this.searched = this.users
    this.getUsers()
    this.getOutlets()
    // this.itemCategories()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}
.md-table.md-theme-default .md-table-content {
  display: none;
}
.pd-l-0 {
  padding-left: 0px;
}

.md-button-content {
  font-size: 12px;
}
.menu-item-rep .md-ripple {
  padding: 6px 15px;
  height: auto !important;
  border-radius: 0px;
}
.content .menu-item-rep .md-ripple {
  padding: 0px !important;
}
.card-inside {
  .md-button {
    .md-ripple {
      padding: 0px 0px;
    }
  }
}
.rep-table {
  margin-top: 0px;
}

.arrow_left {
  float: left;
}
.add-staff {
  z-index: 99998 !important;
}
</style>
