<template>
  <form class="md-layout-item pd-l">
    <md-card class="pd-l">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left ml-auto">
            <router-link :to="{ path: '/products/Modifiers' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType === 'View Modifier'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editModifier"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader" class="block-bg pad-xy rounded-xl">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                v-model="modifier.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Modifier'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Price') },
                { 'md-valid': !errors.has('Price') && touched.Price },
              ]"
            >
              <label>{{ $t('Price') }}</label>
              <md-input
                v-model="modifier.price"
                data-vv-name="Price"
                type="number"
                v-validate="modelValidations.Price"
                :disabled="formType == 'View Modifier'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Price')">
                  {{ errors.first('Price') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Price') && touched.ShortName"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('Price') },
                { 'md-valid': !errors.has('Price') && touched.Price }
              ]"
            >
              <label>{{$t('Price')}}</label>
              <md-input
                v-model="modifier.price"
                data-vv-name="Price"
                type="number"
                v-validate="modelValidations.Price"
                :disabled="formType == 'View Modifier'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Price')">{{errors.first("Price")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('Price') && touched.ShortName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Status') },
                { 'md-valid': !errors.has('Status') && touched.Status },
              ]"
            >
              <label for="Country">{{ $t('Status') }}</label>
              <md-select
                v-model="modifier.status"
                :disabled="formType == 'View Modifier'"
                data-vv-name="Status"
                v-validate="modelValidations.Status"
              >
                <md-option class="first-opt" disabled>Status</md-option>
                <md-option value="active">{{ $t('Active') }}</md-option>
                <md-option value="inactive">{{ $t('Inactive') }}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Status')">
                  {{ errors.first('Status') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Status') && touched.Status"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Modifier'">
          <md-card-actions class="text-center">
            <md-button native-type="submit" @click="reset">{{
              $t('CANCEL')
            }}</md-button>
            <md-button
              class="md-primary"
              native-type="submit"
              @click.native.prevent="validate"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import axios from 'axios'
export default {
  components: {
    SlideYDownTransition,
    axios,
    ClipLoader,
  },
  props: {},

  data() {
    return {
      Name: '',
      switch2: null,
      Status: '',

      touched: {
        Name: false,
        Price: false,
        Status: false,
      },

      modelValidations: {
        Name: {
          required: true,
        },

        Status: {
          required: true,
        },
      },

      formType: {
        type: String,
        default: 'Add Sub Category',
      },

      clientDetail: {
        default: '',
      },

      modifier: {
        status: 'active',
      },
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createModifier()
          }
        })
      }
    },

    createModifier(url) {
      let self = this
      if (this.formType == 'Add Modifier') {
        customizeService
          .createModifier(this.modifier)
          .then((res) => {
            self.$router.push({ name: 'Modifiers' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        let body = {
          modifier: this.modifier,
        }
        axios
          .put(this.url + '.json', body, customizeService.authFun())
          .then((res) => {
            self.$router.push({ name: 'Modifiers' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      }
    },

    reset() {
      this.modifier = {}
      this.$router.push({ name: 'Modifiers' })
    },

    editModifier() {
      this.formType = 'Edit Modifier'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    if (this.formType != 'Add Modifier') {
      this.loader = true
      axios
        .get(
          this.url + '?chain_id=' + accountService.getChainId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.loader = false
          this.modifier = res.data
        })
    }
  },

  watch: {
    Name() {
      this.touched.required = true
    },
    Price() {
      this.touched.required = true
    },
    Status() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
.advanced {
  box-shadow: none;
}
</style>
