<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/Settings/Course' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div class="md-layout-item md-size-30" v-if="formType == 'View Course'">
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editSupercat"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Sposition') },
                { 'md-valid': !errors.has('Sposition') && touched.Sposition },
              ]"
            >
              <label>{{ $t('Sequence Position') }}*</label>
              <md-input
                v-model="course.code_order"
                data-vv-name="Sposition"
                type="text"
                v-validate="modelValidations.Sposition"
                :disabled="formType == 'View Course'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Sposition')">
                  {{ errors.first('Sposition') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Sposition') && touched.Sposition"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}*</label>
              <md-input
                v-model="course.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Course'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Course'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/Settings/Course' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import axios from 'axios'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      Sposition: '',
      switch2: null,
      Name: '',
      touched: {
        Name: false,
        Sposition: false,
      },
      modelValidations: {
        Sposition: {
          required: true,
        },
        Name: {
          required: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Client',
      },
      clientDetail: {
        default: '',
      },
      course: {},
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createCourse()
          }
        })
      }
    },

    createCourse() {
      let self = this
      if (this.formType == 'Add Course') {
        customizeService
          .createCourse(this.course)
          .then((res) => {
            self.$router.push({ name: 'Course' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        let body = {
          courses: this.course,
        }
        body.courses.codable_type = 'subcategory'
        axios
          .put(this.courseUrl + '.json', body, customizeService.authFun())
          .then((res) => {
            self.$router.push({ name: 'Course' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      }
    },

    reset() {
      this.course = {}
    },

    editSupercat() {
      this.formType = 'Edit Course'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.courseUrl = this.$route.query.client
    let self = this
    if (this.formType != 'Add Course') {
      this.loader = true
      axios
        .get(
          this.courseUrl + '?chain_id=' + accountService.getChainId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.loader = false
          this.course = res.data
        })
    }
  },
  watch: {
    Sposition() {
      this.touched.required = true
    },
    Name() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
