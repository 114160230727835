<template>
  <div>
    <md-card class="weekly-sales report-card block-bg">
      <md-card-header class="md-card-header-text md-layout md-gutter">
        <div class="md-layout-item md-size-100 header-items">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Weekly Sales') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout filter-section block-bg">
          <div class="md-layout search-options">
            <div class="md-layout-item weekly-sales-picker">
              <!-- <Date-Time-Picker @SendFrom="fromDateTime" @SendTo="toDateTime"></Date-Time-Picker> -->
              <ad-datepicker
                @startDate="fromDateTime"
                @endDate="toDateTime"
              ></ad-datepicker>
            </div>
            <div
              class="md-layout-item md-size-20"
              v-if="userRole == 'branch_user'"
            >
              <md-field>
                <label>Outlets</label>
                <md-select
                  v-model="queries.outletId"
                  multiple
                  @md-closed="
                    queries.outletId.length > 0
                      ? weeklySales()
                      : (queries.outletId = [])
                  "
                >
                  <md-option
                    v-for="item in outlets"
                    :key="item.id"
                    :value="item.id"
                    class="oulet-select-list-item"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-15" md-alignment="right">
              <md-button class="md-teritiary" @click="weeklySales()"
                >SEARCH</md-button
              >
            </div>
          </div>
        </div>
        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>
        <!-- <h4 class="center">Hourly Order Summary- {{ sdate }} to {{ edate }}</h4> -->

        <div class="md-layout">
          <div class="chart-container" style="position: relative; width: 50vw">
            <bar-chart
              :chart-data="datacollection"
              :options="chartOptions"
            ></bar-chart>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-table class="table-typ1">
              <md-table-toolbar>
                <div class="action-buttons export-btn">
                  <div class="pdf-el">
                    <div class="download-btn">
                      <img
                        src="../../../../assets/images/pdf.svg"
                        @click="downloadWithoutCSS()"
                      />
                    </div>
                  </div>

                  <div class="excel-el">
                    <vue-excel-xlsx
                      class="Excel-btn"
                      :data="report"
                      :columns="columns"
                      :filename="'weekly-sales'"
                      :sheetname="'Weekly Sales'"
                      :sdate="sdate"
                      :edate="edate"
                      :outlet-names="selectedOutletsNames()"
                    >
                    </vue-excel-xlsx>
                  </div>
                </div>
              </md-table-toolbar>
              <slot v-for="items in report">
                <md-table-row v-if="items.order_type == 'Order Type'">
                  <md-table-head>{{ items.order_type }}</md-table-head>
                  <md-table-head>{{ items.date1 }}</md-table-head>
                  <md-table-head>{{ items.date2 }}</md-table-head>
                  <md-table-head>{{ items.date3 }}</md-table-head>
                  <md-table-head>{{ items.date4 }}</md-table-head>
                  <md-table-head>{{ items.date5 }}</md-table-head>
                  <md-table-head>{{ items.date6 }}</md-table-head>
                  <md-table-head>{{ items.date7 }}</md-table-head>
                  <md-table-head>{{ items.total }}</md-table-head>
                </md-table-row>

                <md-table-row
                  v-else
                  :class="[
                    { 'order-type-bold': items.order_type == 'Overall Total' },
                  ]"
                >
                  <md-table-cell>{{ items.order_type }}</md-table-cell>
                  <md-table-cell>{{ items.date1 }}</md-table-cell>
                  <md-table-cell>{{ items.date2 }}</md-table-cell>
                  <md-table-cell>{{ items.date3 }}</md-table-cell>
                  <md-table-cell>{{ items.date4 }}</md-table-cell>
                  <md-table-cell>{{ items.date5 }}</md-table-cell>
                  <md-table-cell>{{ items.date6 }}</md-table-cell>
                  <md-table-cell>{{ items.date7 }}</md-table-cell>
                  <md-table-cell>{{ items.total }}</md-table-cell>
                </md-table-row>
              </slot>
            </md-table>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { accountService } from '../../../../services/account.js'
import { customizeService } from '../../../../services/customize.js'
import { reportService } from '../../../../services/reports.js'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import LineChart from '../../../../components/Graphs/LineChart.vue'
import BarChart from '../../../../components/Graphs/BarChart.vue'

import DateTimePicker from '../../../../components/DateTimePicker'
import AdDatepicker from '../../../../components/AdDatepicker.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'

export default {
  components: {
    LineChart,
    ChartCard,
    DateTimePicker,
    AdDatepicker,
    ClipLoader,
    VueExcelXlsx,
    BarChart,
  },
  data() {
    return {
      colouredLineChart: {
        data: {
          labels: [
            '1:00',
            '2:00',
            '3:00',
            '4:00',
            '5:00',
            '6:00',
            '7:00',
            '8:00',
            '9:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00',
            '00:00',
          ],

          series: [[355, 480, 290, 554, 690, 690, 500, 752, 650, 900]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 10,
          }),
          axisY: {
            showGrid: true,
            offset: 40,
          },
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          showPoint: true,
          height: '300px',
          legend: {
            position: 'bottom',
          },
        },
      },
      sdate: '',
      edate: '',
      search: null,
      searched: [],
      report: [],
      columns: [
        { field: 'order_type' },
        { field: 'date1' },
        { field: 'date2' },
        { field: 'date3' },
        { field: 'date4' },
        { field: 'date5' },
        { field: 'date6' },
        { field: 'date7' },
        { field: 'total' },
      ],
      datacollection: null,
      queries: {
        outletid: [],
      },
      outlets: [],
      userRole: '',
      loader: false,
      chartOptions: {
        legend: {
          position: 'bottom',
        },
        layout: {
          padding: {
            left: 10,
            right: 20,
            top: 30,
            bottom: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontSize: 14,
                suggestedMin: 0,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 14,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    }
  },

  methods: {
    fillData() {
      this.datacollection = {
        labels: [],
        datasets: [
          {
            label: 'Weekly Sales',
            data: [],
            fill: 'false',
            borderColor: 'red',
            backgroundColor: '#00E0DB',
            barThickness: 30,
          },
        ],
      }

      this.report.forEach((el) => {
        if (el.order_type == 'Order Type') {
          this.datacollection.labels.push(el.date1)
          this.datacollection.labels.push(el.date2)
          this.datacollection.labels.push(el.date3)
          this.datacollection.labels.push(el.date4)
          this.datacollection.labels.push(el.date5)
          this.datacollection.labels.push(el.date6)
          this.datacollection.labels.push(el.date7)
        } else if (el.order_type == 'Overall Total') {
          this.datacollection.datasets[0].data.push(el.date1)
          this.datacollection.datasets[0].data.push(el.date2)
          this.datacollection.datasets[0].data.push(el.date3)
          this.datacollection.datasets[0].data.push(el.date4)
          this.datacollection.datasets[0].data.push(el.date5)
          this.datacollection.datasets[0].data.push(el.date6)
          this.datacollection.datasets[0].data.push(el.date7)
        }
      })
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = Object.values(el)
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.queries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      this.queries.startDate = this.sdate
      this.queries.endDate = this.edate
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      let head = [['', '', '', '', '', '', '', '', '']]
      pdfService.headerFooterConfig(
        this.queries,
        doc,
        'Weekly Sales',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'Weekly-Sales.pdf')
    },

    fromDateTime(date) {
      this.queries.fromDate = date
    },

    toDateTime(date) {
      this.queries.endDate = date
      this.weeklySales()
    },

    weeklySales() {
      this.loader = true
      reportService.getWeeklySales(this.queries).then((res) => {
        this.loader = false
        this.report = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate
        this.fillData()
      })
    },
  },

  created() {
    // this.searched = this.users
    this.loader = true

    this.userRole = accountService.userRole()
    if (this.userRole == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.weeklySales()
      })
    } else {
      this.weeklySales()
    }
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}

.header-items {
  display: flex;
}

.weekly-sales-picker {
  margin-top: 8px;
}
</style>
