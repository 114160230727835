<template>
  <div class="md-layout module-table">
    <div class="md-layout-item">
      <md-card class="pagination-card">
        <md-card-header
          class="md-card-header-icon md-card-header-green md-flex"
        >
          <div class="headerDiv">
            <router-link
              :to="{
                name: 'AddPromotions',
                query: { formType: 'Add Promotions' },
              }"
            >
              <md-icon class="add-icon">add</md-icon>
              <!-- <md-icon class="add-icon" v-if="userType != 'user'">add</md-icon> -->
            </router-link>
            <h3 class="head">
              {{ $t('Promotions') }}
            </h3>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <!-- <md-table> -->
          <md-table
            :value="queriedData"
            class="paginated-table table-striped table-hover"
            @md-selected="viewPromoDetail"
            v-if="!loader"
          >
            <md-table-toolbar class="table-toolbar-align">
              <md-field>
                <label>{{ $t('Search') }}</label>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style=""
                  v-model="searchQuery"
                ></md-input>
                <i class="fa fa-search search-align" aria-hidden="true"></i>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell v-bind:md-label="$t('Promotion Code')">
                <div @click="viewPromoDetail(item)">
                  {{ item.promo_code }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Description')">
                <div @click="viewPromoDetail(item)">
                  {{ item.description }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('User Limit')">
                <div @click="viewPromoDetail(item)">
                  {{ item.user_limit }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Discount')">
                <div class="center" @click="viewPromoDetail(item)">
                  {{ item.discount_percent }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Status')">
                <div class="center" @click="viewPromoDetail(item)">
                  {{ item.status }}
                </div>
              </md-table-cell>
              <md-table-cell class="center" v-bind:md-label="$t('Actions')">
                <div class="border-edit">
                  <md-menu
                    md-size="medium"
                    :md-offset-x="-227"
                    :md-offset-y="-16"
                  >
                    <i
                      class="material-icons text_align-center menu-icon"
                      md-menu-trigger
                      >more_vert</i
                    >
                    <md-menu-content class="menuitem-dropdown">
                      <md-menu-item class="action-header menu-align"
                        >{{ $t('Actions') }}
                      </md-menu-item>
                      <router-link
                        :to="{
                          name: 'AddPromotions',
                          query: {
                            formType: 'Edit Promotion',
                            client: item.id,
                          },
                        }"
                      >
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fas fa-pencil-alt"></i>
                            {{ $t('Edit') }}
                          </span>
                        </md-menu-item>
                      </router-link>

                      <md-menu-item>
                        <span
                          class="edit-align"
                          @click="alert('Delete', item.id)"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          {{ $t('Delete') }}
                        </span>
                      </md-menu-item>
                    </md-menu-content>
                  </md-menu>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- </md-card-actions> -->
      </md-card>
    </div>
  </div>
</template>
<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import { reusableService } from '../../../services/reusable.js'

export default {
  components: {
    ClipLoader,
    Pagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData

      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: '',
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
    }
  },

  methods: {
    getItems() {
      customizeService.getOnlinePromotions().then((res) => {
        this.tableData = res.data
      })
    },

    viewPromoDetail(item) {
      this.$router.push({
        name: 'AddPromotions',
        query: { formType: 'Edit Promotion', client: item.id },
      })
    },

    alert(value, id) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.deletePromotion(id)
        }
      })
    },

    deletePromotion(id) {
      customizeService
        .deletePromotion(id)
        .then((res) => {
          console.log('response get', res)
          this.getItems()
        })
        .catch((err) => console.log(err))
    },
  },

  created() {
    this.getItems()
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['promo_code'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>
<style>
.headerDiv {
  display: flex;
}

.add-icon {
  margin-left: 5px !important;
  margin-top: 16px;
  margin-right: 5px;
}

.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center !important;
}

.head {
  margin-top: 16px !important;
}

.paginated-table .md-table-head-label {
  padding-right: 0px !important;
}

.module-table .md-table-head .md-table-head-container {
  text-align: center !important;
}
</style>
