module.exports = country_codes = [
  {
    country: 'India',
    code: 'IN',
  },
  {
    country: 'USA',
    code: 'US',
  },
  {
    country: 'Australia',
    code: 'AU',
  },
  {
    country: 'Russia',
    code: 'RU',
  },
  {
    country: 'Åland Islands',
    code: 'OM',
  },
]
