import { render, staticRenderFns } from "./MainCategory.vue?vue&type=template&id=323baef2&scoped=true&"
import script from "./MainCategory.vue?vue&type=script&lang=js&"
export * from "./MainCategory.vue?vue&type=script&lang=js&"
import style0 from "./MainCategory.vue?vue&type=style&index=0&id=323baef2&prod&lang=css&scoped=true&"
import style1 from "./MainCategory.vue?vue&type=style&index=1&id=323baef2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323baef2",
  null
  
)

export default component.exports