<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/outlet/Table' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div class="md-layout-item md-size-30" v-if="formType === 'View Table'">
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editDiscountCat"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Sequence') },
                { 'md-valid': !errors.has('Sequence') && touched.Sequence },
              ]"
            >
              <label>{{ $t('Sequence Position') }}*</label>
              <md-input
                v-model="table.sequence_no"
                data-vv-name="Sequence"
                type="text"
                v-validate="modelValidations.Sequence"
                :disabled="formType == 'View Table'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Sequence')">
                  {{ errors.first('Sequence') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Sequence') && touched.Sequence"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                v-model="table.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Table'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name }
              ]"
            >
              <label>{{$t('Name')}}</label>
              <md-input
                v-model="table.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Table'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">{{errors.first("Name")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('Name') && touched.Name">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Seating Capacity') }}</label>
              <md-input
                v-model="table.chair_no"
                data-vv-name="Seating"
                type="text"
                :disabled="formType == 'View Table'"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Area') },
                { 'md-valid': !errors.has('Area') && touched.Area },
              ]"
            >
              <label>{{ $t('Floor Area') }}</label>
              <md-select
                v-model="table.outlet_floor_id"
                :disabled="formType == 'View Table'"
                data-vv-name="Status"
                v-validate="modelValidations.Status"
              >
                <md-option class="first-opt" disabled>Floor Area</md-option>
                <md-option
                  :value="item.id"
                  v-for="item in tableAreaList"
                  :key="item.id"
                  >{{ item.floor_name }}</md-option
                >
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Area')">
                  {{ errors.first('Area') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Area') && touched.Area"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('Area') },
                { 'md-valid': !errors.has('Area') && touched.Area }
              ]"
            >
              <label>{{$t('Floor Area')}}</label>
              <md-select
                v-model="table.outlet_floor_id"
                :disabled="formType == 'View Table'"
                data-vv-name="Status"
                v-validate="modelValidations.Status"
              >
                <md-option :value="item.id" v-for="item in tableAreaList" :key="item.id">{{item.floor_name}}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Area')">{{errors.first("Area")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('Area') && touched.Area">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Status') },
                { 'md-valid': !errors.has('Status') && touched.Status },
              ]"
            >
              <label for="Country">{{ $t('Status') }}</label>
              <md-select
                v-model="table.status"
                :disabled="formType == 'View Table'"
                data-vv-name="Status"
                v-validate="modelValidations.Status"
              >
                <md-option class="first-opt" disabled>Status</md-option>
                <md-option value="active">{{ $t('Active') }}</md-option>
                <md-option value="inactive">{{ $t('Inactive') }}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Status')">
                  {{ errors.first('Status') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Status') && touched.Status"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Description') }}</label>
              <md-input
                v-model="table.description"
                data-vv-name="Description"
                type="text"
                :disabled="formType == 'View Table'"
              ></md-input>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('Description') },
                { 'md-valid': !errors.has('Description') && touched.Description }
              ]"
            >
              <label>{{$t('Description')}}</label>
              <md-input
                v-model="table.description"
                data-vv-name="Description"
                type="text"
                v-validate="modelValidations.Description"
                :disabled="formType == 'View Table'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Description')">{{errors.first("Description")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('Description') && touched.Description">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->
        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Table'">
          <md-card-actions class="text-center">
            <md-button native-type="submit" :to="{ path: '/outlet/Table' }">{{
              $t('CANCEL')
            }}</md-button>
            <md-button
              class="md-primary"
              native-type="submit"
              @click.native.prevent="validate"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import axios from 'axios'
import { accountService } from '../../../services/account'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    SlideYDownTransition,
    axios,
    ClipLoader,
  },
  props: {},

  data() {
    return {
      Sequence: '',
      Name: '',
      Seating: '',
      Area: '',
      Status: '',
      Description: '',

      touched: {
        Name: false,
        Sequence: false,
        Area: false,
        Status: false,
      },

      modelValidations: {
        Name: {
          required: true,
        },
        Sequence: {
          required: true,
        },

        Area: {
          required: true,
        },
        Status: {
          required: false,
        },
      },

      formType: {
        type: String,
        default: 'Add Sub Category',
      },

      clientDetail: {
        default: '',
      },

      table: {
        status: 'active',
      },
      tableAreaList: [],
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.CreateTable()
          }
        })
      }
    },

    CreateTable() {
      let self = this
      this.table.outlet_id = accountService.getOutletId()
      if (this.formType == 'Add Table') {
        customizeService
          .createTable(this.table)
          .then((res) => {
            console.log('table response', res)
            self.$router.push({ name: 'Table' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        let body = {
          tables: this.table,
        }
        axios
          .put(this.url + '.json', body, customizeService.authFun())
          .then((res) => {
            console.log('table response', res)
            self.$router.push({ name: 'Table' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      }
    },

    getTableArea() {
      customizeService
        .getTableAreas()
        .then((res) => {
          this.tableAreaList = res.data.table_areas.filter(
            (el) => el.status == 'active'
          )
        })
        .catch((err) => console.log(err))
    },

    reset() {
      this.table = {}
    },

    editDiscountCat() {
      this.formType = 'Edit Table'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    this.getTableArea()
    if (this.formType != 'Add Table') {
      this.loader = true
      axios
        .get(
          this.url + '.json' + '?outlet_id=' + accountService.getOutletId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.loader = false
          this.table = res.data.tables
        })
    }

    this.table.sequence_no = this.$route.query.seq
      ? this.$route.query.seq + 1
      : 1
  },

  watch: {
    Name() {
      this.touched.required = true
    },
    Seating() {
      this.touched.required = true
    },
    Sequence() {
      this.touched.required = true
    },
    Area() {
      this.touched.required = true
    },
    Status() {
      this.touched.required = true
    },
    Description() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
