<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    :class="{ active: isActive }"
    tag="li"
  >
    <!--menu items-->
    <a
      v-if="isMenu"
      href="#"
      class="nav-link sidebar-menu-item"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      @click.prevent="collapseMenu"
    >
      <b class="caret sidebar-caret"></b>
      <div class="sidebar-icon" v-if="link.icon">
        <img :src="'/img/' + link.icon + '.png'" />
      </div>
      <md-icon v-else>{{ link.icon }}</md-icon>
      <p>
        {{ $t(link.name) }}
      </p>
    </a>

    <collapse-transition :class="collapsed ? 'show' : ''">
      <div v-if="$slots.default || this.isMenu" v-show="!collapsed">
        <ul class="nav first-sub-menu">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>

    <slot
      name="title"
      v-if="children.length === 0 && !$slots.default && link.path"
    >
      <component
        :to="link.path"
        @click.native="linkClick"
        :is="elementType(link, false)"
        :class="{ active: isSubActive }"
        class="nav-link"
        :target="link.target"
        :href="link.path"
      >
        <template v-if="addLink">
          <!-- <div > -->
          <span class="sidebar-mini">{{ linkPrefix }}</span>
          <div class="sub-menu-item">
            <div class="active-arrow" v-if="link.path == $route.path">
              <i class="fas fa-arrow-right"></i>
            </div>
            <span class="sidebar-normal"> {{ $t(link.name) }}</span>
          </div>

          <!-- </div> -->
        </template>
        <template v-else>
          <div :class="{ bgm: isMainActive }" class="sidebar-menu-item">
            <div class="sidebar-icon" v-if="link.icon">
              <img :src="'/img/' + link.icon + '.png'" />
            </div>
            <md-icon v-else>{{ link.icon }}</md-icon>
            <p>{{ $t(link.name) }}</p>
          </div>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions'
import { accountService } from './../../services/account'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'sidebar-item',
  components: {
    CollapseTransition,
  },
  props: {
    highlightLink: {
      default: '',
    },
    menu: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: '',
          children: [],
        }
      },
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    }
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
      isMainActive: null,
    }
  },
  computed: {
    baseComponent() {
      return this.isMenu || this.link.isRoute ? 'li' : 'router-link'
    },
    linkPrefix() {
      if (this.link.name) {
        let words = this.link.name.split(' ')
        return words.map((word) => word.substring(0, 1)).join('')
      }
      return false
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true
    },
    isActive() {
      if (this.$route.path == this.highlightLink.link) {
        return true
      } else if (this.$route && this.$route.path) {
        let matchingRoute = this.children.find((c) =>
          this.$route.path.startsWith(c.link.path)
        )

        if (this.$route.path == '/distributors/distributor-form') {
          matchingRoute = this.children.find((c) =>
            this.$route.path.startsWith('/distributors/list-dist')
          )
        }

        if (matchingRoute !== undefined) {
          return true
        }
      }
      return false
    },

    isSubActive() {
      if (this.$route.path == this.highlightLink.link) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    isActive: function () {
      if (this.link.path == this.$route.path) {
        this.isMainActive = true
      } else if (this.link.path != this.$route.path) {
        this.isMainActive = false
      }
    },
    isSubActive: function () {
      if (this.$route.path == this.highlightLink.link) {
        this.link.active = true
      }
    },
  },

  methods: {
    ...mapActions(['checkUrl']),
    checkUser() {
      var token = sessionStorage.getItem('access_token')
      this.checkUrl(token)
    },
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode)
      this.children.splice(index, 0, item)
    },
    removeChild(item) {
      const tabs = this.children
      const index = tabs.indexOf(item)
      tabs.splice(index, 1)
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? 'li' : 'a'
      } else {
        return 'router-link'
      }
    },
    linkAbbreviation(name) {
      const matches = name.match(/\b(\w)/g)
      return matches.join('')
    },
    linkClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false)
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed
    },
    collapseSubMenu(link) {
      link.collapsed = !link.collapsed
    },
  },
  created() {
    if (!accountService.dynamicFlg) {
      this.checkUser()
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this)
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false
    }
  },
  beforeMount() {
    this.$root.$on('make sidebarActive', (res) => {
      // // console.log("res",res)
      // this.link.name  = res.name
      // this.link.path = res.path
      // console.log(this.link)
    })
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.removeLink) {
      this.removeLink(this)
    }
  },
}
</script>

<style lang="scss" scoped>
.sidebar-menu-item {
  cursor: pointer;
  display: flex !important;
}

.sidebar-icon {
  height: 30px;
  width: 30px;
  margin-right: 15px;

  img {
    height: 100%;
    width: 100%;
  }
}

.sidebar-caret {
  left: 0px;
}

.first-sub-menu {
  list-style-type: none;
}

// .sidebar-normal {
// padding-left: 40px;
// }

.sidebar-mini {
  text-align: left !important;
}

.sub-menu-item {
  display: flex;
}

.bgm {
  color: #2b82be !important;
  font-weight: bold;
}

.active-arrow {
  .fa-arrow-right {
    color: #2b82be !important;
    font-size: 12px !important;
    margin-top: 4px;
  }
}

.nav-item {
  .active {
    .sidebar-normal {
      padding-left: 10px;
    }
  }
}

.sidebar-text {
  color: #60616c;
}

.bgm i {
  color: #2b82be !important;
}
</style>
