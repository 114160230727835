<template>
  <router-view></router-view>
</template>

<script>
import { i18n } from './main.js'
export default {}
</script>
<style>
:root {
  --primary-zoom-factor: 1;
}
</style>
