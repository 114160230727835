function decrypt(text, shift) {
  const ALPHABET = [
    ...'abcdefghijklmnopqrstuvwxyz',
    ...'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    ...'0123456789',
    ' ',
    ':',
    ',',
    '{',
    '}',
    '[',
    ']',
    '"',
    '-',
    '@',
    '.',
    '!',
    '_',
  ]
  const decryptedText = text
    .split('')
    .map((char) => {
      const index = ALPHABET.indexOf(char)
      if (index === -1) {
        return char // Non-alphabet characters are returned unchanged
      }
      let newIndex = (index - shift) % ALPHABET.length
      if (newIndex < 0) newIndex += ALPHABET.length
      return ALPHABET[newIndex]
    })
    .join('')

  return decryptedText
}

export { decrypt }
