<template>
  <div class="mx-10">
    <div class="md-layout text-center h-100 plan-list flex-column width-550px">
      <template v-if="currentStep == 1">
        <div class="logo-img-block">
          <img
            class="logo-img-login"
            src="../../../assets/images/cbe_innova_logo.png"
          />
        </div>
        <h1 class="text-white font-weight-bold h2 mb-25 mt-0">Step 1</h1>

        <!-- <h2 class="title text-white">Select a plan that’s right for you</h2> -->
        <h5 class="description text-white">
          Below is your Subscription Details
        </h5>

        <div class="md-layout-item pd-l">
          <pricing-card icon-color="icon-white">
            <h6
              slot="category"
              class="category text-white font-weight-bold mt-4 h4"
            >
              STANDARD
            </h6>
            <!-- <md-icon slot="icon">home</md-icon> -->
            <img slot="icon" src="../../../assets/images/payment.svg" />

            <h3
              slot="title"
              class="title mt-0 position-relative text-primary font-weight-bold"
            >
              <clip-loader
                class="price-loader"
                :color="'#00E0DB'"
                :loading="loader"
              ></clip-loader>
              <span v-if="!loader">{{ currency }} {{ totalAmount }}</span>
            </h3>
            <p slot="description" class="text-white font-weight-semibold">
              This includes use of the CBE Innova application at your point of
              use, secure data storage, access to cloud Back Office with
              multifunctional reporting. We also provide email and phone
              customer support as standard.
            </p>
          </pricing-card>
          <md-field
            :class="[
              { 'md-error': errors.has('termsAndCondition') },
              {
                'md-valid ':
                  !errors.has('termsAndCondition') && touched.termsAndCondition,
              },
            ]"
            class="subscription-plan"
          >
            <md-checkbox
              v-model="termsAndCondition"
              v-validate="modelValidations.termsAndCondition"
              data-vv-scope="terms"
              data-vv-name="termsAndCondition"
              >I agree to the</md-checkbox
            >
            <span class="text-white">
              <a href="/terms_and_condition.pdf" target="_blank"
                >terms and conditions</a
              >
              and SLA of CBE.</span
            >
            <slide-y-down-transition>
              <div class="error" v-show="errors.has('terms.termsAndCondition')">
                {{ errors.first('terms.termsAndCondition') }}
              </div>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon
                class="success"
                v-show="
                  !errors.has('termsAndCondition') && touched.termsAndCondition
                "
                >done</md-icon
              >
            </slide-y-down-transition>
          </md-field>
          <md-field
            :class="[
              { 'md-error': errors.has('cbePaytermsAndCondition') },
              {
                'md-valid ':
                  !errors.has('cbePaytermsAndCondition') &&
                  touched.cbePaytermsAndCondition,
              },
            ]"
            class="subscription-plan"
            v-if="cbePay"
          >
            <md-checkbox
              v-model="cbePaytermsAndCondition"
              v-validate="modelValidations.cbePaytermsAndCondition"
              data-vv-scope="terms"
              data-vv-name="cbePaytermsAndCondition"
              >I agree to the</md-checkbox
            >
            <span class="text-white">
              <a href="/cbepay_terms_and_condition.pdf" target="_blank"
                >terms and conditions</a
              >
              of CBE Pay</span
            >
            <slide-y-down-transition>
              <div
                class="error"
                v-show="errors.has('terms.cbePaytermsAndCondition')"
              >
                CBE PAY terms and conditions is required
              </div>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon
                class="success"
                v-show="
                  !errors.has('cbePaytermsAndCondition') &&
                  touched.cbePaytermsAndCondition
                "
                >done</md-icon
              >
            </slide-y-down-transition>
          </md-field>
        </div>

        <div class="d-flex justify-content-center">
          <button
            type="button"
            class="md-button arrow-btn md-theme-default pad-10px width-110px rounded-pill"
            @click.prevent="goToStep(2)"
          >
            Next
          </button>
        </div>
      </template>
      <template v-if="currentStep == 2">
        <div class="logo-img-block">
          <img
            class="logo-img-login"
            src="../../../assets/images/cbe_innova_logo.png"
          />
        </div>
        <h1 class="text-white font-weight-bold h2 mb-1 mt-0">Step 2</h1>
        <h4 class="text-white font-weight-bold h2 mb-1 mt-0">
          Stripe Billing Details
        </h4>

        <md-card class="block-bg mb-1">
          <md-card-content class="pad-20px">
            <div class="md-layout flex-column">
              <div class="left-padding md-layout-item my-0">
                <md-field>
                  <label class="text-white">{{ $t('Business Name') }}</label>
                  <md-input
                    type="text"
                    v-model="customer.name"
                    data-vv-name="OutletName"
                    v-validate="modelValidations.custmerName"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('OutletName')">
                      {{ errors.first('OutletName') }}
                    </div>
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div class="left-padding md-layout-item my-0">
                <md-field>
                  <label class="text-white">{{ $t('Email') }}</label>
                  <md-input
                    type="text"
                    v-model="customer.email"
                    data-vv-name="OutletName"
                    v-validate="modelValidations.cusomerEmail"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('OutletName')">
                      {{ errors.first('OutletName') }}
                    </div>
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div class="left-padding md-layout-item my-0">
                <md-field>
                  <label class="text-white">{{ $t('Phone') }}</label>
                  <md-input
                    type="text"
                    v-model="customer.phone"
                    data-vv-name="OutletName"
                    v-validate="modelValidations.cusomerPhone"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('OutletName')">
                      {{ errors.first('OutletName') }}
                    </div>
                  </slide-y-down-transition>
                </md-field>
              </div>
              <!-- <div class="left-padding md-layout-item my-0">
                <md-field>
                  <label class="text-white">{{ $t('Tax ID') }}</label>
                  <md-input
                    type="text"
                    v-model="customer.tax"
                    data-vv-name="OutletName"
                  ></md-input>
                </md-field>
              </div>
              <span class="justify-content-start d-flex">
                Please add prefix IE to the tax id. ex: IE1234567AB</span
              > -->
              <div class="address-fields">
                <h4 class="d-flex font-weight-bold mb-1 mt-0">Address</h4>
                <div class="md-layout">
                  <!-- <div class="left-padding md-layout-item my-0 pad-r-15px">
                    <md-field>
                      <label class="text-white">{{ $t('City') }}</label>
                      <md-input
                        type="text"
                        v-model="customer.city"
                        data-vv-name="OutletName"
                        v-validate="modelValidations.cusomerCity"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div> -->
                  <!-- <div class="left-padding md-layout-item my-0">
                    <md-field>
                      <label class="text-white">{{ $t('State') }}</label>
                      <md-input
                        type="text"
                        v-model="customer.state"
                        data-vv-name="OutletName"
                        v-validate="modelValidations.cusomerState"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div> -->
                </div>

                <div class="md-layout">
                  <div class="left-padding md-layout-item my-0 pad-r-15px">
                    <md-field>
                      <label class="text-white">{{
                        $t('Address line1')
                      }}</label>
                      <md-input
                        type="text"
                        v-model="customer.address.line1"
                        data-vv-name="OutletName"
                        v-validate="modelValidations.cusomerLine1"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div>
                  <div class="left-padding md-layout-item my-0">
                    <md-field>
                      <label class="text-white">{{
                        $t('Address line2')
                      }}</label>
                      <md-input
                        type="text"
                        v-model="customer.address.line2"
                        data-vv-name="OutletName"
                        v-validate="modelValidations.cusomerLine2"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout">
                  <div class="left-padding md-layout-item my-0 pad-r-15px">
                    <md-field>
                      <label class="text-white">{{ $t('Post Code') }}</label>
                      <md-input
                        type="text"
                        v-model="customer.address.postal_code"
                        data-vv-name="OutletName"
                        v-validate="modelValidations.zip"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div>
                  <!-- <div class="left-padding md-layout-item my-0">
                    <md-field>
                      <label class="text-white">{{ $t('Country') }}</label>
                      <md-input
                        type="text"
                        v-model="customer.country"
                        data-vv-name="OutletName"
                        v-validate="modelValidations.cusomerCountry"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div> -->
                </div>
              </div>
            </div>
            <md-field
              :class="[
                { 'md-error': errors.has('confirmDetails') },
                {
                  'md-valid ':
                    !errors.has('confirmDetails') && touched.confirmDetails,
                },
              ]"
              class="subscription-plan"
            >
              <md-checkbox
                v-model="confirmDetails"
                v-validate="modelValidations.confirmDetails"
                data-vv-scope="confirm"
                data-vv-name="confirmDetails"
                >I confirm the details above are correct</md-checkbox
              >

              <slide-y-down-transition>
                <div
                  class="error"
                  v-show="errors.has('confirm.confirmDetails')"
                >
                  {{ errors.first('confirm.confirmDetails') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="
                    !errors.has('confirmDetails') && touched.confirmDetails
                  "
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </md-card-content>
        </md-card>
        <!-- <p class="mt-0 small text-white font-weight-bold font-11">
          By clicking "Proceed to Payment" you agree to our Terms & Conditions.
          Our Privacy Policy applies.
        </p> -->

        <div class="d-flex justify-content-between">
          <button
            type="button"
            class="md-button arrow-btn md-theme-default pad-10px width-110px rounded-pill"
            @click.prevent="goToStep(1)"
          >
            Prev
          </button>

          <md-button
            slot="footer"
            class="md-success md-round md-primary"
            @click="choosePlan()"
            :disabled="btnDisabled"
            >Proceed to Payment</md-button
          >
        </div>
      </template>

      <!-- <div
        class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 md-size-25"
      >
        <pricing-card card-class="md-card-plain" icon-color="icon-white">
          <h6 slot="category" class="category">Freelancer</h6>
          <md-icon slot="icon">weekend</md-icon>
          <h3 slot="title" class="title">Free</h3>
          <p slot="description" class="card-description">
            This is good if your company size is between 2 and 10 Persons.
          </p>
          <md-button slot="footer" class="md-white md-round"
            >Choose Plan</md-button
          >
        </pricing-card>
      </div> -->
      <!-- <div
        class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 md-size-25"
      >
        <pricing-card card-class="md-card-plain" icon-color="icon-white">
          <h6 slot="category" class="category">Medium Company</h6>
          <md-icon slot="icon">business</md-icon>
          <h3 slot="title" class="title">69$</h3>
          <p slot="description" class="card-description">
            This is good if your company size is between 11 and 99 Persons.
          </p>
          <md-button slot="footer" class="md-white md-round"
            >Choose Plan</md-button
          >
        </pricing-card>
      </div> -->
      <!-- <div
        class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 md-size-25"
      >
        <pricing-card card-class="md-card-plain" icon-color="icon-white">
          <h6 slot="category" class="category">Extra Pack</h6>
          <md-icon slot="icon">account_balance</md-icon>
          <h3 slot="title" class="title">159$</h3>
          <p slot="description" class="card-description">
            This is good if your company size is between 99+ Persons.
          </p>
          <md-button slot="footer" class="md-white md-round"
            >Choose Plan</md-button
          >
        </pricing-card>
      </div> -->
    </div>
  </div>
</template>
<script>
import { PricingCard } from '@/components'
import { stripeService } from '../../../services/stripe'
import { SlideYDownTransition } from 'vue2-transitions'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize'

export default {
  components: {
    PricingCard,
    SlideYDownTransition,
    ClipLoader,
  },
  data() {
    return {
      totalAmount: '',
      currency: '€',
      loader: true,
      currentStep: 1,
      btnDisabled: false,
      step1: {
        name: '',
        email: '',
      },
      step2: {
        city: '',
        state: '',
      },
      modelValidations: {
        termsAndCondition: {
          required: 'required:true',
        },
        cbePaytermsAndCondition: {
          required: 'required:false',
        },
        confirmDetails: {
          required: 'required:true',
        },
        customerName: {
          required: true,
        },
        cusomerEmail: {
          required: true,
        },
        cusomerPhone: {
          required: true,
        },
        cusomerCity: {
          required: true,
        },
        cusomerCountry: {
          required: true,
        },
        cusomerShipping: {
          required: true,
        },
        cusomerAddress: {
          required: true,
        },
      },
      touched: {
        termsAndCondition: false,
        cbePaytermsAndCondition: false,
        confirmDetails: false,
      },
      customer: { address: {}, tax: '' },
      name: '',
      termsAndCondition: false,
      cbePaytermsAndCondition: false,
      confirmDetails: false,
      cbePay: false,
    }
  },
  methods: {
    choosePlan() {
      // if (this.customer.tax != '') {
      //   this.customer.tax_id_data = [this.customer.tax]
      // }
      this.$validator.validateAll('confirm').then((result) => {
        if (result) {
          let type = this.$route.params.chain ? 'Chain' : 'Outlet'
          this.btnDisabled = true
          let body = { customer: this.customer }
          stripeService
            .createCheckoutSession(this.$route.params.id, body, type)
            .then((res) => {
              window.location.href = res.data.url
            })
            .catch((err) => {
              Swal.fire({
                text: `Please enter valid data to proceed`,
                type: 'warning',
                showCancelButton: false,
                confirmButtonClass: 'md-button md-danger',
                cancelButtonClass: 'md-button md-danger',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                reverseButtons: true,
              })
              this.btnDisabled = false
            })
        }
      })
    },
    getPriceDetails() {
      let type = this.$route.params.chain ? 'Chain' : 'Outlet'
      stripeService.getPriceDetails(this.$route.params.id, type).then((res) => {
        // let amount = 0
        // for (let i = 0; i < res.data.length; i++) {
        //   amount += res.data[i].unit_amount / 100
        // }
        this.totalAmount = res.data.total_amount
        this.customer = res.data.info.customer
        this.currency = res.data.currency
        this.cbePay = res.data.info.cbe_pay || false
        this.loader = false
        // window.location.href = res.data.url
      })
    },
    goToStep(step) {
      // this.currentStep = step
      if (step == 2) {
        this.$validator.validateAll('terms').then((result) => {
          const now = new Date()
          const dateTimeString = now.toLocaleString()
          if (result) {
            let body = {
              outlets: {
                outlet: {
                  accept_cbepay_terms: this.cbePaytermsAndCondition,
                  cbepay_accept_terms_date: dateTimeString,
                  accept_terms: this.confirmDetails,
                  accept_terms_date: dateTimeString,
                  // is_migrated: false,
                },
              },
            }
            this.loader = true
            customizeService
              .editOutlet(this.$route.params.id, body)
              .then((res) => {
                this.currentStep = step
              })
          }
          // alert("Correct them errors!");
        })
      } else {
        this.currentStep = step
      }
    },
  },
  created() {
    this.getPriceDetails()
  },
}
</script>
<style>
.text-center {
  text-align: center;
}
.plan-list .md-layout-item {
  overflow-y: hidden !important;
}
.subscription-plan {
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-plan:after {
  display: none;
}
.subscription-plan .md-checkbox {
  padding-bottom: 22px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
}
.subscription-plan .md-checkbox + span {
  padding-bottom: 22px;
  font-size: 14px;
}
.subscription-plan .md-checkbox-label {
  position: static;
  pointer-events: auto !important;
}
.subscription-plan .error {
  left: 0px;
  bottom: 0;
  color: #f9113e;
  right: 0;
}
.subscription-plan .md-checkbox-container {
  background: #fff !important;
}
</style>
