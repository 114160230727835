<template>
  <div>
    <div class="md-layout-item md-size-100 outlet-logo">
      <!-- <md-card-header></md-card-header> -->
      <img class="outlet-logo-img" :src="userImage" alt="outlet-logo" />
    </div>

    <div class="content-section">
      <div class="md-layout">
        <div class="md-layout-item md-size-55">
          <div class="bill-sub-value text-right">
            Order Type: {{ billDetails.order_type }}
          </div>
          <div class="bill-sub-value text-right">
            {{ formattedDate }}
          </div>
        </div>
      </div>

      <!-- {{ billDetails }} -->
      <div class="md-layout">
        <table class="md-layout-item">
          <tr>
            <th>Items</th>
            <th>Rate</th>
            <th>Qty</th>
            <th>Amount</th>
          </tr>
          <tr v-for="(item, index) in billDetails.cart_items" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.price.toFixed(2) }}</td>
            <td>{{ item.qty }}</td>
            <td>{{ (item.price * item.qty).toFixed(2) }}</td>
          </tr>
        </table>
      </div>
      <div class="md-layout dashed-border">
        <div class="md-layout-item md-size-75 text-right">Total:</div>
        <div class="md-layout-item md-size-25 text-right">
          {{ billDetails.amount.toFixed(2) }}
        </div>
      </div>
    </div>
    <div class="md-layout barcode-section">
      <div class="md-layout-item md-size-100 thanku-hd">
        Thank you for coming !
      </div>
      <!-- <div class="md-layout-item md-size-100">
        <svg id="barcode"></svg>
      </div> -->
      <div class="md-layout-item md-size-100">
        <md-button @click="downloadBill" class="text-white">Download</md-button>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'

export default {
  name: 'order-bill',
  props: ['billDetails'],
  data() {
    return {}
  },
  computed: {
    formattedDate() {
      let date = new Date(this.billDetails.order_date)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      let hours = date.getHours()
      let mins = date.getMinutes()
      return [day, month, year].join('/') + ' ' + [hours, mins].join(':')
    },

    userImage() {
      return this.$store.getters.getProfile.logo
    },
  },

  methods: {
    downloadBill() {
      this.$emit('downloadBill', this.billDetails.id)
    },
  },
}
</script>

<style>
.outlet-logo {
  width: 70%;
  text-align: center;
}

.outlet-logo-img {
  height: 20px !important;
  padding: 3px;
  width: 70%;
  margin-top: 15px;
}

#bill-detail .md-layout-item {
  padding: 10px;
}
.dashed-border {
  border-bottom: 2px dashed;
}
.bill-sub-value {
  font-weight: 600;
  font-size: 15px;
  padding: 5px 0px;
}
</style>
