<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/Dashboard' }">
              <img src="@/assets/images/left.svg" />
            </router-link>
          </span>
          <h3>Debitrack</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>
        <div class="md-layout" v-if="!loader">
          <div class="md-layout-item md-size-25">
            <md-field>
              <label>{{ $t('Username') }}</label>
              <md-input
                class="form-control"
                type="text"
                id="HostKey"
                v-model="integrations.debitrack.username"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-25">
            <md-field>
              <label>{{ $t('Password') }}</label>
              <md-input
                class="form-control"
                type="text"
                id="HostKey"
                v-model="integrations.debitrack.password"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-25">
            <md-field>
              <label>{{ $t('Payment Types') }}</label>
              <md-select v-model="integrations.debitrack.paymentTypeId">
                <md-option
                  :value="item.id"
                  v-for="item in onlinePayments"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout-item" style="margin-top: 17px">
          <md-button
            class="md-mini"
            @click="addTerminal()"
            style="margin: auto"
          >
            Add Terminal<md-icon>add</md-icon>
          </md-button>
        </div>
        <div class="md-layout" style="margin-top: 10px">
          <div
            class="md-layout-item md-size-60"
            v-for="(item, k) in terminalSettings"
            :key="k"
          >
            <div class="md-layout">
              <div class="md-layout-item md-size-25">
                <md-field>
                  <md-select
                    class="select_btn"
                    data-vv-name="mainCat"
                    v-model="item.id"
                  >
                    <md-option
                      :value="item.id"
                      v-for="item in terminalsList"
                      :key="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50 d-flex">
                <md-field>
                  <label>{{ $t('Terminal Id') }}</label>
                  <md-input
                    class="form-control"
                    type="text"
                    id="HostKey"
                    v-model="item.terminalId"
                  ></md-input>
                </md-field>
                <md-icon class="remove-icon" @click.native="removeEvent(k)"
                  >close</md-icon
                >
              </div>
              <!-- <div class="md-layout-item md-size-20" style="margin-top: 12.5px">
                <md-button class="md-mini" @click="handleSubmit(item)">
                  {{
                    item.Status && item.Status == 'Success' ? 'Un Pair' : 'Pair'
                  }}
                </md-button>
              </div> -->
            </div>
          </div>
        </div>
        <div class="btn-div">
          <md-card-actions class="text-center">
            <md-button native-type="submit" :to="{ path: '/Dashboard' }">{{
              $t('CANCEL')
            }}</md-button>
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ $t('UPDATE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '@/services/reusable.js'
import { customizeService } from '@/services/customize.js'
import { accountService } from '@/services/account.js'
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      Token: '',
      touched: {
        Token: false,
      },
      modelValidations: {
        Token: {
          required: true,
        },
      },
      integrations: {
        debitrack: {
          username: '',
          password: '',
        },
      },
      terminalsList: [],
      terminalSettings: [],
      loader: false,
      onlinePayments: [],
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            this.updateOutlet()
          }
        })
      }
    },

    updateMenuId() {
      // let matchedStore = this.stores.find(
      //   (store) => store.StoreId == this.storeId
      // )
      // if (matchedStore) {
      //   this.menuId = matchedStore.MenuId
      // }
      this.getMenus()
    },

    updateOutlet() {
      this.integrations.debitrack.terminalSettings = this.terminalSettings
      let body = {
        outlets: {
          outlet: {
            integrations: this.integrations,
          },
        },
      }
      this.loader = true
      customizeService
        .editOutlet(accountService.getOutletId(), body)
        .then((res) => {
          this.loader = false
        })
    },

    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminalsList = res.data
        // this.storeId = res.data.Data[0].StoreId
      })
    },
    getOtherPaymentTypes() {
      customizeService
        .getOtherPaymentTypes(accountService.getOutletId())
        .then((res) => {
          this.onlinePayments = res.data.other_payment_types
          // this.otherFeeTypes = res.data.other_payment_types.filter(
          //   (item) => item.status == true && item.is_fee == true
          // )
        })
    },
    addTerminal() {
      if (!this.terminalSettings) {
        this.terminalSettings = []
      }
      this.terminalSettings.push({
        id: '',
        terminalId: '',
      })
    },
    removeEvent(index) {
      this.terminalSettings.splice(index, 1)
    },
  },

  created() {
    customizeService.getOutletById(accountService.getOutletId()).then((res) => {
      this.integrations = res.data.outlet.integrations
      this.terminalSettings = this.integrations.debitrack.terminalSettings || []
      if (res.data.outlet.integrations) {
        if (res.data.outlet.integrations.debitrack) {
          this.integrations = res.data.outlet.integrations
        } else {
          this.integrations.debitrack = {
            username: '',
            password: '',
          }
        }
      } else {
        this.integrations = {
          debitrack: {
            username: '',
            password: '',
          },
        }
      }
    })

    // this.updateStores()
    this.getTerminals()
    this.getOtherPaymentTypes()
  },
  watch: {
    Token() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
