<template>
  <div class="distributor-card">
    <div class="md-layout">
      <div
        class="md-layout-item md-size-33 md-medium-size-50 md-xsmall-size-100"
      >
        <pricing-card icon-color="icon-success">
          <div slot="category" class="category d-header add-dist">
            <span class="add-dist-title">Distributor</span>
            <!-- <span class="opt-button"><i class="material-icons ">more_vert</i></span> -->
          </div>
          <i class="fas fa-plus" slot="icon" @click="addDistributor"></i>
          <h3 slot="title" class="title"></h3>

          <div slot="no-of-dist" class="no-of-dist-title">
            {{ distributors.length }} Distributors
          </div>
          <div slot="footer" class="active-since">Inactive 20%</div>
        </pricing-card>
      </div>
      <div
        class="md-layout-item md-size-33 md-medium-size-50 md-xsmall-size-100"
        v-for="dist in distributors"
        v-bind:key="dist.id"
      >
        <pricing-card icon-color="icon-success">
          <div slot="category" class="category d-header">
            <span class="d-flag">FLAG</span>
            <md-menu
              class="no-display"
              md-size="medium"
              :md-offset-x="-227"
              :md-offset-y="-16"
            >
              <span class="opt-button">
                <i class="material-icons" md-menu-trigger>more_vert</i>
                <!-- <md-menu-content>
                            <md-menu-item>
                                <router-link :to="{name:'AddDistributor', params:{formType:'Edit Distributor', client: dist.id}}">
                                    <span class=" edit-align">
                                        <i class="fas fa-pen"></i>
                                        Edit
                                    </span>
                                </router-link>
                            </md-menu-item>
                            <md-menu-item>
                                <div>
                                    <span class="edit-align">
                                    <i class="fa fa-ban" aria-hidden="true"></i>
                                    Deactivate
                                    </span>
                                </div>
                            </md-menu-item>
                            <md-menu-item>
                                <div>
                                    <span class="edit-align">
                                        <i class="fa fa-trash edit-align" aria-hidden="true"></i>
                                        Delete
                                    </span>
                                </div>
                            </md-menu-item>
                </md-menu-content>-->
                <md-menu-content class="menuitem-dropdown">
                  <md-menu-item class="action-header menu-align">{{
                    $t('Actions')
                  }}</md-menu-item>
                  <router-link
                    :to="{
                      name: 'AddDistributor',
                      params: { formType: 'Edit Distributor', client: dist.id },
                    }"
                  >
                    <md-menu-item>
                      <span class="edit-align">
                        <i class="fas fa-pencil-alt"></i>
                        {{ $t('Edit') }}
                      </span>
                    </md-menu-item>
                  </router-link>
                  <div>
                    <md-menu-item>
                      <span class="edit-align">
                        <i class="fa fa-ban" aria-hidden="true"></i>
                        {{ $t('Deactivate') }}
                      </span>
                    </md-menu-item>
                  </div>
                  <div>
                    <md-menu-item>
                      <span
                        class="edit-align"
                        @click="alert('Delete', dist.id)"
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        {{ $t('Delete') }}
                      </span>
                    </md-menu-item>
                  </div>
                </md-menu-content>
              </span>
            </md-menu>
          </div>
          <md-icon slot="icon" class="pointer-link">
            <i @click="viewDistributor(dist)" class="fas fa-user"></i>
          </md-icon>
          <h3
            @click="viewDistributor(dist)"
            slot="title"
            class="title pointer-link"
          >
            {{ dist.name }}
          </h3>
          <!-- <p slot="description" class="card-description">
                    {{dist.d_address}}
          </p>-->
          <div slot="no-of-dist" class="no-of-dist pointer-link">
            {{ dist.no_of_chains }} Business Houses
          </div>
          <div slot="footer" class="active-since">
            <div class="active-square"></div>
            Active since {{ dist.created_at }}
          </div>
        </pricing-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NavTabsCard,
  TimeLine,
  TimeLineItem,
  PricingCard,
  TestimonialCard,
} from '@/components'
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import Swal from 'sweetalert2'
export default {
  name: 'list-dist',
  components: {
    NavTabsCard,
    TimeLine,
    TimeLineItem,
    PricingCard,
    TestimonialCard,
  },
  data() {
    return {
      distributors: [],
    }
  },
  methods: {
    viewDistributor(dist) {
      this.$router.push({
        name: 'AddDistributor',
        params: { formType: 'View Distributor', client: dist.id },
      })
    },
    addDistributor() {
      this.$router.push({
        name: 'AddDistributor',
        params: { formType: 'Add Distributor', viewType: 'grid' },
      })
    },
    getItems() {
      customizeService.getDistributors().then((res) => {
        this.distributors = res.data.distributors
        this.distributors.forEach((el) => {
          let d = new Date(el.created_at)
          el.created_at = d.toDateString()
        })
      })
    },
    alert(value, id) {
      var self = this
      if (value === 'Delete') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.deleteDistributor(id)
          }
        })
      }
    },

    deleteDistributor(id) {
      customizeService
        .deleteDistributor(id)
        .then((res) => {
          console.log('response get', res)
          this.getItems()
        })
        .catch((err) => console.log(err))
    },
  },

  created() {
    this.getItems()
  },
}
</script>

<style scoped>
.active-since {
  text-align: left;
  justify-content: left;
  border-top-style: groove;
  border-top-width: 2px;
  width: 100%;
  padding: 5px 5px;
  display: flex;
}

.md-card-pricing .md-card-actions {
  justify-content: left;
}

.active-square {
  width: 12px;
  height: 12px;
  background-color: #4caf50;
  margin-top: 5px;
  margin-right: 10px;
  bottom: 0px;
}

.card-description {
  margin-top: 0px;
}

.add-dist-desc {
  height: 66px;
}

.d-header {
  padding-left: 15px;
  padding-right: 10px;
}

.opt-button {
  float: right;
}

.d-flag {
  float: left;
}

.no-of-dist,
.no-of-dist-title {
  color: grey;
  float: left;
  text-align: left;
  padding-left: 20px;
  font-size: 18px;
}

.add-dist-title {
  font-size: 20px;
}

.no-display {
  display: unset;
}

.router-link-text {
  font-size: 16px;
  text-transform: none;
}

.pointer-link {
  cursor: pointer;
}

.no-of-dist-title {
  padding-top: 43px;
}
.distributor-card {
  position: relative;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
</style>
