<template>
  <div>
    <md-card class="hourly-order block-bg">
      <md-card-header class="md-card-header-text md-layout md-gutter">
        <div class="md-layout-item md-size-100">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Hourly Order') }}</h3>
        </div>
      </md-card-header>

      <div class="md-layout filter-section block-bg">
        <div class="md-layout search-options">
          <div class="md-layout-item">
            <date-period-dropdown
              @interval="setDateType"
            ></date-period-dropdown>
          </div>

          <div
            class="md-layout-item md-size-25"
            v-if="userRole == 'branch_user'"
          >
            <md-field>
              <label>Outlets</label>
              <md-select
                v-model="queries.outletId"
                multiple
                @md-closed="
                  queries.outletId.length > 0
                    ? hourlyOrder()
                    : (queries.outletId = [])
                "
              >
                <md-option
                  v-for="item in outlets"
                  :key="item.id"
                  :value="item.id"
                  class="oulet-select-list-item"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-15" md-alignment="right">
            <md-button class="md-teritiary" @click="hourlyOrder()"
              >SEARCH</md-button
            >
          </div>
        </div>
      </div>
      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>
      <h4 class="center" v-if="!loader">
        Hourly Order Summary- {{ sdate }} to {{ edate }}
      </h4>

      <div class="chart-container" style="position: relative; width: 50vw">
        <bar-chart
          :chart-data="datacollection"
          :options="chartOptions"
        ></bar-chart>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-50" v-if="!loader">
          <md-field>
            <label class="text-white">Terminals</label>
            <md-select
              v-model="queries.terminal_id"
              multiple
              v-on:input="hourlyOrder()"
            >
              <md-option :value="'undefined'">All</md-option>
              <slot v-for="item in terminals">
                <md-option :value="item.id">{{ item.name }}</md-option>
              </slot>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item report-table md-size-100 hourly-order">
          <md-table class="table-typ1">
            <md-table-toolbar>
              <div class="action-buttons export-btn">
                <div class="pdf-el">
                  <div class="download-btn">
                    <img
                      src="../../../../assets/images/pdf.svg"
                      @click="downloadWithoutCSS()"
                    />
                  </div>
                </div>

                <div class="excel-el">
                  <vue-excel-xlsx
                    class="Excel-btn"
                    :data="report"
                    :columns="columns"
                    :filename="'Hourly Order Report'"
                    :sheetname="'Hourly Order Report'"
                    :sdate="sdate"
                    :edate="edate"
                    :outlet-names="selectedOutletsNames()"
                  >
                  </vue-excel-xlsx>
                </div>
              </div>
            </md-table-toolbar>

            <md-table-row>
              <md-table-head>Hour</md-table-head>
              <md-table-head>No. of Orders</md-table-head>
              <md-table-head>Guests</md-table-head>
              <md-table-head>Voids Qty</md-table-head>
              <md-table-head>Voids Total</md-table-head>
              <md-table-head>Order Total</md-table-head>
            </md-table-row>

            <md-table-row v-for="(item, index) in report" :key="index">
              <md-table-cell>{{ item.hour }}</md-table-cell>
              <md-table-cell>{{ item.no_of_orders }}</md-table-cell>
              <md-table-cell>{{ item.guest }}</md-table-cell>
              <md-table-cell>{{ item.voids_qty }}</md-table-cell>
              <md-table-cell>{{ item.voids_total }}</md-table-cell>
              <md-table-cell>{{ item.order_total.toFixed(2) }}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { accountService } from '../../../../services/account.js'
import { customizeService } from '../../../../services/customize.js'
import { reportService } from '../../../../services/reports.js'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import LineChart from '../../../../components/Graphs/LineChart.vue'
import BarChart from '../../../../components/Graphs/BarChart.vue'

import DateTimePicker from '../../../../components/DateTimePicker.vue'
import datetime from 'vuejs-datetimepicker'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'

export default {
  components: {
    LineChart,
    BarChart,
    ChartCard,
    datetime,
    DateTimePicker,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
  },
  data() {
    return {
      search: null,
      searched: [],
      report: [],
      datacollection: null,
      sdate: '',
      edate: '',
      columns: [
        {
          label: 'Hour',
          field: 'hour',
        },
        {
          label: 'No. Of Orders',
          field: 'no_of_orders',
        },
        {
          label: 'Guest',
          field: 'guest',
        },
        {
          label: 'Voids Qty',
          field: 'voids_qty',
        },
        {
          label: 'Voids Total',
          field: 'voids_total',
        },
        {
          label: 'Order Total',
          field: 'order_total',
        },
      ],
      queries: {
        outletId: [],
        terminal_id: [],
        search_type: 'Daily',
      },
      userRole: '',
      outlets: [],
      loader: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        layout: {
          padding: {
            left: 10,
            right: 20,
            top: 30,
            bottom: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                fontSize: 14,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 14,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
      terminals: [],
    }
  },

  methods: {
    fromDateTime(date) {
      this.queries.startDate = date
    },

    toDateTime(date) {
      this.queries.endDate = date
    },

    fillData() {
      this.datacollection = {
        labels: [
          '1:00',
          '2:00',
          '3:00',
          '4:00',
          '5:00',
          '6:00',
          '7:00',
          '8:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
          '00:00',
        ],
        datasets: [
          {
            label: 'Hourly Order',
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0,
            ],
            fill: 'false',
            backgroundColor: '#00E0DB',
          },
          {
            label: 'Void',
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0,
            ],
            fill: 'false',
            borderColor: 'black',
            backgroundColor: '#E59866',
          },
          {
            label: 'Number of orders',
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0,
            ],
            fill: 'false',
            borderColor: 'blue',
            backgroundColor: '#85C1E9',
          },
        ],
      }
      this.report.forEach((el) => {
        if (el.hour != 'Total') {
          let matchedIndex = this.datacollection.labels.findIndex(
            (time) => time == el.hour
          )
          if (matchedIndex != undefined) {
            this.datacollection.datasets[0].data[matchedIndex] = el.order_total
            this.datacollection.datasets[1].data[matchedIndex] = el.void_total
            this.datacollection.datasets[2].data[matchedIndex] = el.no_of_orders
          } else {
            this.datacollection.datasets[0].data.push(el.order_total)
          }
        }
      })
      console.log('')
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = Object.values(el)
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.queries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },

    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })
      this.queries.startDate = this.sdate
      this.queries.endDate = this.edate
      let dateText = this.queries.startDate + ' to ' + this.queries.endDate
      let head = [
        [
          'Hour',
          'No.of Orders',
          'Guests',
          'Voids Qty',
          'Voids Total',
          'Order Total',
        ],
      ]
      pdfService.headerFooterConfig(
        this.queries,
        doc,
        'Hourly Order',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'Hourly-order.pdf')
    },

    setDateType(interval) {
      this.queries.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.queries.startDate = interval.startDate
        this.queries.endDate = interval.endDate
      } else {
        this.queries.startDate = interval.date
        this.queries.endDate = undefined
        this.hourlyOrder()
      }
    },

    hourlyOrder() {
      this.loader = true
      if (this.queries.terminal_id.includes('undefined')) {
        this.queries.terminal_id = this.terminals.map(({ id }) => id)
      }
      reportService.getHourlyOrder(this.queries).then((res) => {
        this.loader = false
        this.report = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate

        this.fillData()
      })
    },

    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminals = res.data
      })
    },
  },

  created() {
    this.searched = this.users
    this.loader = true
    this.userRole = accountService.userRole()
    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.hourlyOrder()
        this.getTerminals()
      })
    } else {
      this.hourlyOrder()
      this.getTerminals()
    }
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}
.main-heading {
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 18px;
}

.toolbar-monthly {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
</style>
