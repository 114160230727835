<template>
  <div class="md-layout client-table">
    <div class="md-layout-item">
      <md-card class="pagination-card">
        <md-card-header class="md-card-header-icon md-card-header-green">
          <h3 class="title">Orders</h3>
        </md-card-header>
        <md-card-content class="orders-table">
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover itemized-txn"
            @md-selected="viewOrderDetail"
            v-bind:class="{}"
          >
            <md-table-toolbar class="table-toolbar-align">
              <md-field>
                <label>Search</label>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style=""
                  v-model="searchQuery"
                ></md-input>
                <i class="fa fa-search search-align" aria-hidden="true"></i>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Order ID" md-sort-by="id">
                <div @click="viewOrderDetail(item)">
                  {{ item.id }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Status">
                <div @click="viewOrderDetail(item)">
                  {{ item.status }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Requested For">
                <div @click="viewOrderDetail(item)">
                  {{
                    item.pickup_time
                      ? moment.utc(item.pickup_time).format('DD/MM/YYYY H:mm')
                      : ''
                  }}
                </div>
              </md-table-cell>
              <md-table-cell class="center" md-label="Order Type">
                <div @click="viewOrderDetail(item)">
                  <!-- <i class="fa fa-check check-align" aria-hidden="true"></i> -->
                  {{ item.order_type }}
                </div>
              </md-table-cell>
              <md-table-cell class="center" md-label="Order Amount">
                <div @click="viewOrderDetail(item)">
                  {{ item.amount.toFixed(2) }}
                </div>
              </md-table-cell>
              <md-table-cell class="center" md-label="Payment Status">
                <div @click="viewOrderDetail(item)">
                  <!-- <i class="fa fa-check check-align" aria-hidden="true"></i> -->
                  {{ item.payment_status ? 'Success' : 'Failed' }}
                </div>
              </md-table-cell>
              <md-table-cell class="center" md-label="Customer">
                <div @click="viewOrderDetail(item)">
                  {{ getUser(item.user_id) }}
                </div>
              </md-table-cell>
              <md-table-cell class="center" md-label="Transaction">
                <a @click="viewBill(item)">View</a>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <!-- <div class="footer-table md-table">
             <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>-->
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">Rows Per page</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  From {{ from + 1 }} to {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- </md-card-actions> -->
      </md-card>
    </div>
    <md-dialog
      class="itemised-trans-bill bill-container"
      :md-active.sync="showBill"
    >
      <div id="bill-detail">
        <order-bill
          :billDetails="itemDetails"
          @downloadBill="downloadBill"
        ></order-bill>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import users from './users'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import html2canvas from 'html2canvas'
import OrderBill from './OrderBill.vue'

export default {
  components: {
    Pagination,
    OrderBill,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      currentSort: 'id',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ['Name', 'Email', 'Age', 'Salary', 'Actions'],
      searchQuery: '',
      propsToSearch: ['name', 'email', 'age'],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      users: [],
      showBill: false,
      itemDetails: {
        data: [],
      },
    }
  },
  methods: {
    getItems() {
      customizeService.getOnlineOrders().then((res) => {
        this.tableData = res.data.orders
        this.users = res.data.users
      })
    },

    viewBill(item) {
      this.itemDetails = item
      this.showBill = true
    },

    downloadBill(billNo) {
      html2canvas(document.querySelector('#bill-detail'), {
        useCORS: true,
        allowTaint: true,
        scrollY: -window.scrollY,
      })
        .then((canvas) => {
          document.body.appendChild(canvas)
        })
        .then(() => {
          var canvas = document.querySelector('canvas')
          canvas.style.display = 'none'
          console.log(canvas)
          var image = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
          var a = document.createElement('a')
          a.setAttribute('download', billNo + '.png')
          a.setAttribute('href', image)
          a.click()
          a.remove()
        })
    },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].toString().localeCompare(b[sortBy], undefined, {
            numeric: true,
          })
        }
        return b[sortBy]
          .toString()
          .localeCompare(a[sortBy], undefined, { numeric: true })
      })
    },

    getUser(userId) {
      let user = this.users.find((el) => el.id == userId)
      if (user && user.name) {
        return user.name
      }
      return ''
    },

    viewOrderDetail(item) {
      this.$router.push({
        name: 'Order Details',
        query: { order: item.id },
      })
    },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3,
    })
  },
  created() {
    this.getItems()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['status'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
/* .add-icon {
  float: left;
  padding-top: 32px;
} */
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.menu-icon {
  padding-right: 20px;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}
.title {
  padding-left: 15px;
}
.html2canvas-container {
  width: 3000px !important;
  height: 3000px !important;
}
</style>
