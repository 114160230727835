<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-100">
      <md-card class="block-bg">
        <md-card-header class="md-layout add-staff-role">
          <div class="md-layout-item md-size-70">
            <span class="arrow_left" v-if="formPop != 'Add Staff Role'">
              <router-link :to="{ path: '/outlet/staff-roles' }">
                <img src="../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h4 class="title">{{ $t('Staff Role') }}</h4>
          </div>
          <div
            class="md-layout-item md-size-30"
            v-if="formPop != 'Add Staff Role' && formType == 'View Staff Role'"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="changeFormType()"
              >edit</i
            >
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
              >
                <label>{{ $t('Name') }}</label>
                <md-input
                  data-vv-name="Name"
                  type="text"
                  v-validate="modelValidations.Name"
                  :disabled="formType == 'View Staff Role'"
                  v-model="staff_roles.name"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Name')">
                    {{ errors.first('Name') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Name') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <h4 class="title staff-role-name">
            {{ $t('POS Access Restrictions') }}
          </h4>

          <div class="md-layout">
            <div class="md-layout-item ul-style">
              <ul class="roles-list">
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.settings"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Settings') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.set_float"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Set Float') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.error_correct"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Error Correct') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.void_item"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Void Item') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.void_bill"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Void Bill') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.transfer"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Transfer') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.clock_in_out"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Clock In/Out') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.edit_clock_in_out"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Edit Clock In/Out') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.allow_access_all_saves"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Allow Access All Saves') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.price_edit"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Price Edit') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.open_price"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Open Price') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.discount"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Discount') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.finalise_sale"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Finalise Sale') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.sell_voucher"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Sell Voucher') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.open_drawer"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Open Drawer') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.petty_cash"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Petty Cash') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.refund"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Refund') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.wastage"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Wastage') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.sync"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Sync') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.reports"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Reports') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.sales_summary"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Sales Summary') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.end_of_day_x"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('End of Day (X Read)') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.end_of_day_y"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('End of Day (Z Read)') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.reenter_count"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Reenter Count') }}</md-checkbox
                  >
                </li>
                <li>
                  <md-checkbox
                    v-model="staff_roles.role_json.clock_in_out_only"
                    :disabled="formType == 'View Staff Role'"
                    >{{ $t('Clock In / Out Only') }}</md-checkbox
                  >
                </li>
                <li>
                  <div>
                    <md-field>
                      <label>{{ $t('Discount Level') }}</label>
                      <md-input
                        v-model="staff_roles.role_json.discount_level"
                        :disabled="formType == 'View Staff Role'"
                      ></md-input>
                    </md-field>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- ----------------------------------------------------------------------------------- -->
          <div class="btn-div" v-if="formType !== 'View Staff'">
            <md-card-actions class="text-center">
              <md-button native-type="submit" @click="resetStaffRole()">{{
                $t('CANCEL')
              }}</md-button>
              <md-button
                class="md-primary"
                native-type="submit"
                @click.native.prevent="validate"
                >{{ $t('SAVE') }}</md-button
              >
            </md-card-actions>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
import { SlideYDownTransition } from 'vue2-transitions'
import axios from 'axios'
export default {
  name: 'manage-staff-role',
  components: {
    SlideYDownTransition,
  },
  props: {
    formPop: {
      type: String,
    },
  },
  data() {
    return {
      formType: '',
      url: '',
      touched: {
        Name: false,
        Role: false,
        Rate: false,
        Number: false,
        Email: false,
        Password: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
        Role: {
          required: true,
        },
        Rate: {
          required: true,
        },
        Number: {
          required: true,
        },
        Email: {
          required: true,
        },
        Password: {
          required: true,
        },
      },

      roles: [],

      staff_roles: {
        name: '',
        outlet_id: accountService.getOutletId(),
        role_json: {
          allow_access_all_saves: false,
          clock_in_out: false,
          clock_in_out_only: false,
          discount: false,
          discount_level: '',
          edit_clock_in_out: false,
          end_of_day_x: false,
          end_of_day_y: false,
          error_correct: false,
          finalise_sale: false,
          open_drawer: false,
          open_price: false,
          petty_cash: false,
          price_edit: false,
          reenter_count: null,
          refund: false,
          reports: false,
          sales_summary: false,
          sell_voucher: false,
          set_float: false,
          settings: false,
          status: 'active',
          sync: false,
          transfer: false,
          void_bill: false,
          void_item: false,
          wastage: false,
        },
      },
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit('on-submit', this.alertModule('Add'), isValid)
        }
      })
    },

    alertModule(value) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.createStaffRole()
        }
      })
    },

    resetStaffRole() {
      if (this.formPop != 'Add Staff Role') {
        this.$router.push({ path: '/outlet/staff-roles' })
      } else {
        this.$emit('closeModal')
      }
    },

    createStaffRole() {
      // if(this.formType == 'Add Staff Role'){
      if (this.formType == 'Add Staff Role') {
        customizeService
          .createStaffRole(this.staff_roles)
          .then((res) => {
            this.$router.push({ name: 'StaffRoles' })
          })
          .catch((err) => console.log('Staff creation failed'))
      } else {
        let body = {
          staff_roles: this.staff_roles,
        }
        axios
          .put(this.url, body, customizeService.authFun())
          .then((res) => {
            this.$router.push({ name: 'StaffRoles' })
          })
          .catch((err) => console.log('Editing failed'))
      }

      //   }
    },

    changeFormType() {
      this.formType = 'Edit Staff Role'
    },
  },
  created() {
    if (this.formPop == 'Add Staff Role') {
      this.formType = this.formPop
    } else {
      this.formType = this.$route.query.formType
    }
    this.url = this.$route.query.client
    let self = this
    if (this.formType != 'Add Staff Role') {
      axios
        .get(
          this.url + '?outlet_id=' + accountService.getOutletId(),
          customizeService.authFun()
        )
        .then((res) => {
          delete res.data.create_at
          delete res.data.updated_at
          delete res.data.url
          this.staff_roles = res.data
        })
    }
  },
  watch: {
    Name() {
      this.touched.required = true
    },
    Role() {
      this.touched.required = true
    },
    Rate() {
      this.touched.required = true
    },
    Number() {
      this.touched.required = true
    },
    Email() {
      this.touched.required = true
    },
    Password() {
      this.touched.required = true
    },
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.package-name {
  margin-top: 8%;
}

.text-center {
  justify-content: center !important;
}
.btn-add-module {
  text-align: center;
}
.arrow_left {
  float: left;
  padding-top: 0px !important;
  margin-top: 10px;
}
.md-card .md-card-actions {
  border: none;
}

.add-role-btn {
  text-align: center;
  padding-top: 18px;
}

.roles-list {
  /*your fixed height*/
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.roles-list li {
  list-style: none;
}

.ul-style {
  ul {
    padding: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 480px;
    width: 100%;
  }
  li {
    width: 150px;
    height: 42px;
    padding-bottom: 5px;
  }
}
.add-staff-role {
  padding-left: 7px;
  .md-layout-item {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.md-card-header {
  h4 {
    font-weight: 300;
    margin-top: 12px;
  }
}
.staff-role-name {
  margin-top: 20px;
  padding-left: 15px;
}
</style>
