<template>
  <form @submit.prevent class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <h3>Windcave</h3>
        </div>
        <div class="md-layout-item md-size-30"></div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('secret') },
                { 'md-valid': !errors.has('secret') },
              ]"
            >
              <label>{{ $t('Secret') }}*</label>
              <md-input
                type="text"
                v-model.lazy="windcave.secret"
                v-validate="'required'"
                data-vv-name="secret"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('secret')">
                  {{ errors.first('secret') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('secret') && windcave.Secret != ''"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('UserId') },
                { 'md-valid': !errors.has('UserId') },
              ]"
            >
              <label>{{ $t('UserId') }}*</label>
              <md-input
                type="text"
                v-model.lazy="windcave.UserId"
                v-validate="'required'"
                data-vv-name="UserId"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('UserId')">
                  {{ errors.first('UserId') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('UserId') && windcave.UserId != ''"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('StationIds') },
                { 'md-valid': !errors.has('StationIds') },
              ]"
            >
              <label>{{ $t('Station Ids') }}*</label>
              <md-input
                type="text"
                v-model.lazy="windcave.StationIds"
                v-validate="'required'"
                data-vv-name="StationIds"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('StationIds')">
                  {{ errors.first('StationIds') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="
                    !errors.has('StationIds') && windcave.StationIds != ''
                  "
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('SurgeChargePayTypeId') },
                { 'md-valid': !errors.has('SurgeChargePayTypeId') },
              ]"
            >
              <label>{{ $t('Surge Charge Pay Type Id') }}*</label>
              <md-input
                type="text"
                v-model.lazy="windcave.SurgeChargePayTypeId"
                v-validate="'required'"
                data-vv-name="SurgeChargePayTypeId"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('SurgeChargePayTypeId')">
                  {{ errors.first('SurgeChargePayTypeId') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="
                    !errors.has('SurgeChargePayTypeId') &&
                    windcave.windcaveSurgeChargePayTypeId != ''
                  "
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
      </md-card-content>
      <div
        style="margin: 20px 0"
        class="btn-div md-layout-item md-size-60 md-alignment-center-right"
      >
        <md-button
          type="button"
          @click="handleSubmit()"
          style="float: right"
          class="md-primary"
          >Update</md-button
        >
      </div>
    </md-card>
  </form>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions'
import axios from 'axios'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'

export default {
  components: {
    SlideYDownTransition,
  },
  data() {
    return {
      windcave: {
        secret: '',
        userId: '',
        Address: '',
        City: '',
        State: '',
        ZipCode: null,
        Email: '',
        Phone: null,
        ResellerName: '',
        ReferenceID: '',
        TerminalSerialNumber: '',
      },
    }
  },
  methods: {
    handleSubmit(item) {
      this.$validator.validate().then((valid) => {
        this.saveData()
      })
    },

    saveData() {
      let closure = {
        outlets: {
          outlet: {
            windcave_settings: this.windcave,
          },
        },
      }
      let outlet_id = accountService.getOutletId()
      customizeService
        .editOutlet(outlet_id, closure)
        .then((res) => console.log('SaveDAta successful!>>>', res))
    },
  },

  created() {
    let outlet_id = accountService.getOutletId()
    customizeService.getOutletById(outlet_id).then((res) => {
      if (res.data.outlet.receipt_relay) {
        this.windcave = res.data.outlet.receipt_relay
      }
    })
  },
}
</script>

<style scoped>
.error {
  color: red;
}
</style>
