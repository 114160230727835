<template>
  <div class="md-layout custm-row">
    <div class="md-layout-item md-medium-size-100">
      <md-card class="block-bg">
        <md-card-header class="md-layout">
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <router-link :to="{ path: '/Settings/Packages' }">
                <img src="../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h3 class="title">{{ formType }}</h3>
          </div>
          <div
            class="md-layout-item md-size-30"
            v-if="formType == 'View Package'"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="changeFormType()"
              >edit</i
            >
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field
                :class="[
                  { 'md-error': errors.has('packageName') },
                  {
                    'md-valid':
                      !errors.has('packageName') && touched.packageName,
                  },
                ]"
              >
                <label>{{ $t('Package Name') }}</label>
                <md-input
                  type="text"
                  v-model="packageName"
                  data-vv-name="packageName"
                  v-validate="modelValidations.packageName"
                  required
                  :disabled="formType == 'View Package'"
                ></md-input>
                <slide-y-down-transition>
                  <div class="errors" v-show="errors.has('packageName')">
                    {{ errors.first('packageName') }}
                  </div>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <!-- <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Description</label>
                <md-input type="text" v-model="packageDis" :disabled="formType=='View Package'"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field class="input-md">
                <label>Subscription</label>
                <md-select type="text" v-model="Subscription" :disabled="formType=='View Package'">
                  <md-option class="first-opt" disabled>Subscription</md-option>
                  <md-option :value='monthly'>Monthly</md-option>
                  <md-option :value='yearly'>Yearly</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Yearly Subscription</label>
                <md-input type="text" :disabled="formType=='View Package'"></md-input>
              </md-field>
            </div>
          </div> -->
          <div class="md-layout">
            <div class="md-layout-item">
              <label class="status-label">Status</label>
              <md-switch v-model="status" class="status-switch"></md-switch>
            </div>
          </div>
          <!-- </md-card-content>
      </md-card>

      <md-card class="right-whitebox">
          <md-card-content class="right-card">-->
          <div class="full-control">
            <div class="list">
              <md-list>
                <h4 v-if="formType !== 'View Package'">
                  <img
                    src="../../../assets/images/plus.svg"
                    @click="showSubDialog = true"
                  />
                  {{ $t('Modules') }}
                </h4>
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-table class="package-module table-striped">
                      <md-table-row>
                        <md-table-head>Main Module</md-table-head>
                        <md-table-head>Sub Module</md-table-head>
                        <md-table-head></md-table-head>
                      </md-table-row>
                      <slot v-for="item in packageModules">
                        <md-table-row slot="md-table-row">
                          <md-table-cell>{{ item.name }}</md-table-cell>
                          <md-table-cell></md-table-cell>
                          <md-table-cell>
                            <img
                              v-if="item.is_default !== true"
                              :class="[
                                { 'disable-class': formType == 'View Package' },
                                {
                                  'active-disabled':
                                    formType !== 'View Package',
                                },
                              ]"
                              @click="
                                removeModule(item.id, item.app_sub_module)
                              "
                              src="../../../assets/images/delete.svg"
                            />
                          </md-table-cell>
                        </md-table-row>
                        <slot v-if="item.app_sub_module !== 0">
                          <slot v-for="sub in item.app_sub_module">
                            <md-table-row slot="md-table-row">
                              <md-table-cell></md-table-cell>
                              <md-table-cell
                                >{{ sub.name }}
                                <slot v-for="nested in sub.nested_modules">
                                  <div class="nested" :key="nested.path">
                                    {{ nested.name }}
                                  </div>
                                </slot>
                              </md-table-cell>
                              <md-table-cell>
                                <img
                                  v-if="item.is_default !== true"
                                  :class="[
                                    {
                                      'disable-class':
                                        formType == 'View Package',
                                    },
                                    {
                                      'active-disabled':
                                        formType !== 'View Package',
                                    },
                                  ]"
                                  @click="
                                    removeSubModule(
                                      sub.name,
                                      sub.sub_module_id,
                                      item.id
                                    )
                                  "
                                  src="../../../assets/images/delete.svg"
                                />
                              </md-table-cell>
                            </md-table-row>
                          </slot>
                        </slot>
                      </slot>
                    </md-table>
                  </div>
                </div>
              </md-list>
            </div>
          </div>

          <div class="btn-div" v-if="formType !== 'View Package'">
            <md-card-actions class="text-center">
              <md-button
                native-type="submit"
                :to="{ path: '/Settings/Packages' }"
                >{{ $t('CANCEL') }}</md-button
              >
              <md-button
                native-type="submit"
                class="md-primary"
                @click.native.prevent="validate"
                >{{ $t('SAVE') }}</md-button
              >
            </md-card-actions>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <!--======================================================= Dialog Box ================================================ -->

    <md-dialog class="package-custm" :md-active.sync="showSubDialog">
      <md-dialog-title class="md-layout">
        <div class="md-layout-item md-size-50">Modules</div>
        <div class="md-layout-item md-size-50 close-icon">
          <i
            class="md-icon md-icon-font md-theme-default"
            @click="showSubDialog = false"
            >close</i
          >
        </div>
      </md-dialog-title>
      <div class="list">
        <md-list>
          <slot v-for="item in bulkModule">
            <md-list-item md-expand v-if="item.app_sub_module !== null">
              <div
                class="module-detail"
                :class="[
                  {
                    'color-dialog':
                      array.findIndex((el) => el == item.id) !== -1,
                  },
                ]"
              >
                <div class="md-list-item-text module-name">{{ item.name }}</div>
                <md-checkbox
                  :model="array"
                  :value="item.id"
                  :true-value="item.id"
                  @change="
                    moduleCheckbox(item.name, item.id, item.app_sub_module)
                  "
                ></md-checkbox>
              </div>
              <md-list slot="md-expand">
                <md-list-item
                  class="md-inset"
                  v-for="(sub, index) in item.app_sub_module"
                  :key="index"
                >
                  <div
                    class="sub-name-box"
                    :class="[
                      {
                        'color-dialog':
                          subarray.findIndex(
                            (el) => el == sub.sub_module_id
                          ) !== -1,
                      },
                    ]"
                  >
                    {{ sub.name }}
                    <slot v-if="sub.nested_modules != null">
                      <div class="md-layout nested-modules">
                        <div
                          class="nested md-layout-item md-size-100"
                          v-for="nested in sub.nested_modules"
                          :key="nested.path"
                        >
                          {{ nested.name }}
                        </div>
                      </div>
                    </slot>
                  </div>

                  <md-checkbox
                    :model="subarray"
                    :value="sub.sub_module_id"
                    :true-value="sub.sub_module_id"
                    @change="
                      subModuleCheckbox(
                        sub.name,
                        sub.module_path,
                        item.id,
                        sub.sub_module_id
                      )
                    "
                  ></md-checkbox>
                  <br />
                </md-list-item>
              </md-list>
            </md-list-item>
            <md-list-item v-else>
              <div
                class="module-detail"
                :class="[
                  {
                    'color-dialog':
                      array.findIndex((el) => el == item.id) !== -1,
                  },
                ]"
              >
                <div class="md-list-item-text module-name">{{ item.name }}</div>
                <md-checkbox
                  :model="array"
                  :value="item.id"
                  :true-value="item.id"
                  @change="moduleCheckbox(item.name, item.id, item.items)"
                ></md-checkbox>
              </div>
            </md-list-item>
          </slot>
        </md-list>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { Tabs } from '@/components'
import { Collapse } from '@/components'
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { SlideYDownTransition } from 'vue2-transitions'
import { accountService } from '../../../services/account.js'
export default {
  components: {
    Tabs,
    Collapse,
    SlideYDownTransition,
  },

  data() {
    return {
      showSubDialog: false,
      status: true,
      packageName: '',
      packageCost: '',
      package_cost: '',
      bulkModule: [],
      packageModules: [],
      package: {},
      moduleId: [],
      subModuleId: [],
      subModuleList: [],
      array: [],
      subarray: [],
      formType: '',
      Subscription: '',
      packageId: '',
      packageDis: '',
      touched: {
        packageName: false,
        packageCost: false,
      },
      modelValidations: {
        packageName: {
          required: true,
          alpha_spaces: true,
        },
        packageCost: {
          required: true,
          numeric: true,
        },
      },
      default_packages: [
        {
          name: 'Products',
          module_path: '/Products',
          id: 9,
          type: 'default',
          items: [
            {
              name: 'Super Category',
              path: '/products/super-category',
            },
            {
              name: 'Main Category',
              path: '/products/main-category',
            },
            {
              name: 'Sub Category',
              path: '/products/sub-category',
            },
            {
              name: 'Menu Items',
              path: '/products/MenuItem',
              outlet_access: true,
            },
          ],
        },
        {
          name: 'Settings',
          module_path: '/Settings',
          id: 11,
          type: 'default',
          items: [
            {
              name: 'Station',
              path: '/Settings/Station',
            },
            {
              name: 'Course',
              path: '/Settings/Course',
            },
          ],
        },
      ],
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit('on-submit', this.alertModule('Add'), isValid)
        }
      })
    },
    moduleCheckbox(moduleName, id, subModule) {
      let submod = JSON.parse(JSON.stringify(subModule))
      let index = this.array.findIndex((el) => el == id)
      if (this.packageModules == null) {
        this.packageModules = []
      }
      if (index !== -1) {
        this.packageModules.push({
          name: moduleName,
          id: id,
          app_sub_module: submod,
        })
        if (submod !== null) {
          for (var i = 0; i < submod.length; i++) {
            let subindex = this.subarray.findIndex(
              (el) => el == submod[i].sub_module_id
            )
            if (subindex == -1) {
              this.subarray.push(submod[i].sub_module_id)
            }
          }
        }
      } else {
        let index = this.packageModules.findIndex((el) => el.id == id)
        this.packageModules.splice(index, 1)
        if (submod !== null) {
          for (var i = 0; i < submod.length; i++) {
            let subindex = this.subarray.findIndex(
              (el) => el == submod[i].sub_module_id
            )
            this.subarray.splice(subindex, 1)
          }
        }
      }
    },

    alertModule(value) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.createPackage()
        }
      })
    },
    subModuleCheckbox(subModuleName, subModulePath, id, subId) {
      var self = this
      let sub = this.subarray.findIndex((el) => el == subId)
      if (sub !== -1) {
        this.addSubModule(subModuleName, subModulePath, id, subId)
      } else {
        let moduleIndex = this.packageModules.findIndex((el) => el.id == id)
        let subIndex = this.packageModules[
          moduleIndex
        ].app_sub_module.findIndex((el) => el.sub_module_id == subId)
        self.packageModules[moduleIndex].app_sub_module.splice(subIndex, 1)
      }
    },
    addSubModule(subModuleName, subModulePath, id, subId) {
      let index = this.packageModules.findIndex((el) => el.id == id)
      if (index != -1) {
        if (!this.packageModules[index].app_sub_module) {
          this.packageModules[index].app_sub_module = []
        }
        this.packageModules[index].app_sub_module.push({
          name: subModuleName,
          module_path: subModulePath,
          sub_module_id: subId,
        })
      } else {
        let matchedModule = this.bulkModule.find((el) => el.id == id)
        this.packageModules.push({
          name: matchedModule.name,
          id: id,
          app_sub_module: [
            {
              name: subModuleName,
              module_path: subModulePath,
              sub_module_id: subId,
            },
          ],
        })
        this.array.push(id)
      }
    },

    removeModule(id, submod) {
      let index = this.packageModules.findIndex((el) => el.id == id)
      this.packageModules.splice(index, 1)
      let arrayIndex = this.array.findIndex((el) => el == id)
      this.array.splice(arrayIndex, 1)
      for (var i = 0; i < submod.length; i++) {
        let subarrayIndex = this.subarray.findIndex(
          (el) => el == submod[i].sub_module_id
        )
        this.subarray.splice(subarrayIndex, 1)
      }
      // this.moduleId.splice(this.moduleId.findIndex(el => el.module_id == id),1)
    },

    removeSubModule(subModuleName, subModuleId, id) {
      let moduleIndex = this.packageModules.findIndex((el) => el.id == id)
      let subModuleIndex = this.packageModules[
        moduleIndex
      ].app_sub_module.findIndex((el) => el.sub_module_id == subModuleId)
      this.packageModules[moduleIndex].app_sub_module.splice(subModuleIndex, 1)
      // this.subModuleList.splice(this.subModuleList.indexOf(subModulePath), 1);
      let subArrayIndex = this.subarray.findIndex((el) => el == subModuleId)
      this.subarray.splice(subArrayIndex, 1)
    },

    resetPackage() {
      this.packageModules = []
      // this.moduleId=[];
      // this.subModuleList = [];
    },

    createPackage() {
      this.package.name = this.packageName
      this.package.package_cost = this.package_cost

      this.packageModules.forEach((el) => {
        delete el.name
        delete el.module_path
        if (el.app_sub_module !== null) {
          el.app_sub_module.forEach((item) => {
            delete item.name
            delete item.module_path
          })
        }
      })
      this.package.app_modules = this.packageModules

      if (this.formType == 'Edit Package') {
        customizeService
          .updatePackage(this.package, this.packageId)
          .then((res) => {
            this.$router.push({
              name: 'Packages',
            })
          })
          .catch((err) => console.log(err))
      } else {
        // this.package.app_modules = this.package.app_modules.concat(this.default_packages)
        this.package.distributor_id = accountService.getDistributorId()
        customizeService
          .createNewPackage(this.package)
          .then((res) => {
            this.$router.push({
              name: 'Packages',
            })
          })
          .catch((err) => console.log(err))
      }
    },

    changeFormType() {
      this.formType = 'Edit Package'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.packageId = this.$route.query.packageId
    let self = this
    if (this.formType == 'Edit Package' || this.formType == 'View Package') {
      customizeService.getPackagebyId(this.packageId).then((res) => {
        self.packageModules = res.data.package_modules
        console.log('packageModules', self.packageModules)

        self.packageName = res.data.name
        for (var i = 0; i < self.packageModules.length; i++) {
          this.array.push(self.packageModules[i].id)
          if (self.packageModules[i].app_sub_module !== null) {
            for (
              var j = 0;
              j < self.packageModules[i].app_sub_module.length;
              j++
            ) {
              this.subarray.push(
                self.packageModules[i].app_sub_module[j].sub_module_id
              )
            }
          }
        }
        console.log('array', this.array)
        console.log('subarray', this.subarray)
        // self.package_cost = res.data.package_cost;
        // for (var i = 0; i < self.packageModules.length; i++) {
        //   for (var j = 0; j < self.packageModules[i].items.length; j++) {
        //     self.subModuleList.push(self.packageModules[i].items[j].path);
        //   }
        // }
      })
    } else {
      // this.packageModules = this.packageModules.concat(this.default_packages);
      //for (var i = 0; i < self.packageModules.length; i++) {
      //for (var j = 0; j < self.packageModules[i].items.length; j++) {
      //self.subModuleList.push(self.packageModules[i].items[j].path);
      //}
      // if(self.packageModules[i].){
      // }
      // }
    }

    customizeService.getModules().then((res) => {
      console.log('module list', res.data.result)
      if (this.formType == 'Edit Package' || this.formType == 'View Package') {
        //  this.bulkModule = res.data.result;
        for (var i = 0; i < res.data.result.length; i++) {
          if (res.data.result[i].is_default !== true) {
            this.bulkModule.push(res.data.result[i])
          }
        }
      } else {
        for (var i = 0; i < res.data.result.length; i++) {
          if (res.data.result[i].is_default == true) {
            // this.packageModules.push(res.data.result[i]);
            this.packageModules.push({
              name: res.data.result[i].name,
              id: res.data.result[i].id,
              is_default: res.data.result[i].is_default,
              app_sub_module: res.data.result[i].items,
            })
            console.log('pack', this.packageModules)
          } else {
            this.bulkModule.push(res.data.result[i])
          }
        }
      }

      console.log('Bulk Module', this.bulkModule)
    })
  },
  watch: {
    packageName() {
      this.touched.required = true
    },
    packageCost() {
      this.touched.required = true
    },
    array: function () {},
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

// .package-name {
//   margin-top: 8%;
// }

.text-center {
  justify-content: center !important;
}
.btn-add-module {
  text-align: center;
}
.arrow_left {
  float: left;
}
.md-card .md-card-actions {
  border: none;
}

.module-detail {
  display: flex;
  width: 90%;
}

.module-name {
  padding-top: 12px;
}

.close-subcat {
  width: 50%;
  text-align: center;
}

.switch-item {
  width: 20%;
}

.sub-name {
  padding-top: 12px;
  width: 30%;
}

.fa-times {
  color: rgb(221, 115, 115);
  padding-top: 12px;
}

.fa-plus {
  padding-top: 12px;
  color: #6090af;
}
.right-card {
  .md-field {
    margin-left: 15px;
  }
  h6 {
    margin-left: 15px;
  }
}
.right-whitebox {
  .md-card-header {
    padding-left: 7px;
    .md-layout-item {
      padding-left: 0px;
    }
  }
}
.md-card img {
  width: 19px;
}
.md-dialog {
  min-width: 54%;
  overflow: auto;
}

.disable-class {
  cursor: not-allowed !important;
  pointer-events: none;
}
.active-disabled {
  cursor: pointer !important;
  pointer-events: visible;
}
.close-icon {
  text-align: right;
}
.color-dialog {
  color: #2b82be;
}
.input-md {
  position: relative;
}
.md-select-menu {
  position: absolute;
}
.status-label {
  float: left;
}
.status-switch {
  margin: 3px 16px 16px 9px;
  float: left;
}

.nested-modules {
  text-align: left;
}

.nested {
  margin-left: 18px;
  margin-top: 5px;
  font-size: 12px;
}
</style>
<style>
.package-custm {
  z-index: 999999;
}
.package-custm .md-dialog-container {
  left: auto !important;
  top: 13% !important;
}
.md-overlay.md-fixed.md-dialog-overlay {
  z-index: 9999;
}
.package-custm .md-dialog-container {
  max-width: 650px;
}
.package-custm .close-icon .md-icon {
  position: absolute;
  right: 13px;
  top: 15px;
}
.package-custm
  .md-list-item
  .md-list-expand
  .md-list-item-container
  .md-ripple {
  text-align: left !important;
  align-items: start;
  float: none;
  padding-left: 60px;
  width: 100%;
}
.package-custm .md-list-item .md-list-item-container .md-ripple .md-icon-font {
  margin-top: -6px;
}
.package-custm .md-list-item .md-list-expand .sub-name-box {
  width: 50%;
}
.package-custm .md-list-item .md-list-expand .md-checkbox {
  justify-content: right;
  width: 50%;
}
</style>
