<template>
  <div class="modifier-table">
    <md-card class="pagination-card">
      <md-card-header
        class="md-card-header-icon md-card-header-green"
        v-if="$store.state.account.profile.has_access"
      >
        <router-link
          :to="{ name: 'AddModifier', query: { formType: 'Add Modifier' } }"
        >
          <md-icon class="add-icon">add</md-icon>
        </router-link>
        <div class="d-flex justify-content-between">
          <h3 class="title">{{ $t('Modifiers') }}</h3>
          <div class="active-inactive-tab" v-if="!loader">
            <div class="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox"
                id="myonoffswitch"
                tabindex="0"
                :value="activetab"
                @change="updateInput($event)"
                :checked="activetab == 'active' ? true : false"
              />
              <label class="onoffswitch-label" for="myonoffswitch">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <md-table
          :value="queriedData"
          :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder"
          :md-sort-fn="customSort"
          class="paginated-table table-striped table-hover"
          @md-selected="viewModifier"
          v-if="!loader"
        >
          <md-table-toolbar class="table-toolbar-align">
            <md-field>
              <label>{{ $t('Search') }}</label>
              <md-input
                type="search"
                class="mb-3"
                clearable
                style=""
                v-model="searchQuery"
              ></md-input>
              <i class="fa fa-search search-align" aria-hidden="true"></i>
            </md-field>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell v-bind:md-label="$t('Name')" md-sort-by="name">
              <div @click="viewModifier(item)">
                {{ item.name }}
              </div>
            </md-table-cell>
            <md-table-cell
              v-bind:md-label="$t('Modifier Types')"
              md-sort-by="modifier_type"
            >
              <div @click="viewModifier(item)">
                {{ item.modifier_type }}
              </div>
            </md-table-cell>
            <!-- <md-table-cell md-label="Description" md-sort-by="City">
                <div @click="viewModifier(item)">
                  {{
                  item.items.length
                  }}
                </div>
            </md-table-cell>-->
            <md-table-cell class="center" v-bind:md-label="$t('Status')">
              <div @click="viewModifier(item)">
                <i
                  v-bind:class="[
                    'check-align',
                    item.status === 'active' ? 'fa fa-check ' : 'fa fa-ban',
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
            </md-table-cell>
            <md-table-cell
              v-bind:md-label="$t('Price')"
              class="center"
              md-sort-by="price"
            >
              <div @click="viewModifier(item)">
                {{ item.price }}
              </div>
            </md-table-cell>
            <md-table-cell class="center" v-bind:md-label="$t('Actions')">
              <div class="border-edit">
                <md-menu
                  md-size="medium"
                  :md-offset-x="-227"
                  :md-offset-y="-16"
                >
                  <i
                    class="material-icons text_align-center menu-icon"
                    md-menu-trigger
                    >more_vert</i
                  >
                  <md-menu-content class="menuitem-dropdown">
                    <md-menu-item class="action-header menu-align">{{
                      $t('Actions')
                    }}</md-menu-item>
                    <router-link
                      :to="{
                        name: 'AddModifier',
                        query: { formType: 'Edit Modifier', client: item.url },
                      }"
                    >
                      <md-menu-item>
                        <span class="edit-align">
                          <i class="fas fa-pencil-alt"></i>
                          {{ $t('Edit') }}
                        </span>
                      </md-menu-item>
                    </router-link>
                    <div>
                      <md-menu-item>
                        <span
                          class="edit-align"
                          @click="updateItem(item.id, item.url)"
                        >
                          <i class="fa fa-ban" aria-hidden="true"></i>
                          {{
                            item.status == 'active'
                              ? $t('Deactivate')
                              : $t('Activate')
                          }}
                        </span>
                      </md-menu-item>
                    </div>
                    <!-- <div>
                      <md-menu-item>
                        <span
                          class="edit-align"
                          @click="alert('Delete', item.url)"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          {{ $t('Delete') }}
                        </span>
                      </md-menu-item>
                    </div> -->
                  </md-menu-content>
                </md-menu>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <!-- <div class="footer-table md-table">
             <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
        </div>-->
      </md-card-content>
      <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
      <div class="md-layout absolute-layout">
        <div class="md-layout-item layout-border">
          <div class="pagination-main">
            <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

            <div class="pagenation-number">
              <md-field>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="pagination-warp">
              <p class="card-category pagination-text-align">
                {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
              </p>
              <pagination
                class="pagination-no-border pagination-success"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- </md-card-actions> -->
    </md-card>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import axios from 'axios'
import Sortable from 'sortablejs'
export default {
  name: 'modifier-list',
  components: {
    Pagination,
    axios,
    ClipLoader,
    Sortable,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData.filter(
          (item) => item.status == this.activetab
        )
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  updated: function () {
    let table = document.querySelector('tbody')
    const _self = this
    Sortable.create(table, {
      onEnd({ newIndex, oldIndex }) {
        // const rowSelected = _self.desserts.splice(oldIndex, 1)[0]; // Get the selected row and remove it
        // _self.desserts.splice(newIndex, 0, rowSelected); // Move it to the new index
      },
    })
  },
  data() {
    return {
      currentSort: 'name',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ['Name', 'Email', 'Age', 'Salary', 'Actions'],
      searchQuery: '',
      propsToSearch: ['name'],
      tableData: [],
      tempData: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
      activetab: 'active',
    }
  },
  methods: {
    customSort(value) {
      return this.tableData.sort((a, b) => {
        const sortBy = this.currentSort
        let first = a[sortBy] || 0
        let second = b[sortBy] || 0
        if (this.currentSortOrder === 'desc') {
          return second
            .toString()
            .localeCompare(first.toString(), undefined, { numeric: true })
        }
        return first
          .toString()
          .localeCompare(second.toString(), undefined, { numeric: true })
      })
    },
    handleLike(item) {
      Swal.fire({
        title: `You liked ${item.name}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'md-button md-success',
      })
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit ${item.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'md-button md-info',
      })
    },

    viewModifier(item) {
      this.$router.push({
        name: 'AddModifier',
        query: { formType: 'Edit Modifier', client: item.url },
      })
    },
    alert(value, url, id = '') {
      var self = this
      if (value === 'Delete') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.deleteModifier(url)
          }
        })
      }
    },
    deleteModifier(url) {
      axios.delete(url + '.json', customizeService.authFun()).then((res) => {
        this.getItems()
      })
    },

    updateItem(id, url) {
      var self = this
      reusableService.showSwal('Edit').then((result) => {
        self.updateItemStatus(id, url)
      })
    },

    updateItemStatus(id, url) {
      let item = this.tempData.find((element) => element.id == id)
      let status = item.status == 'active' ? 'inactive' : 'active'
      let body = {
        modifier: { status: status },
      }
      axios.put(url + '.json', body, customizeService.authFun()).then((res) => {
        item.status = status
        this.tableData = this.tempData
      })
    },
    filterBystatus(status) {
      this.activetab = status
      this.searchedData = []
      this.tableData = this.tempData.filter((item) => item.status == status)
      this.customSort()
    },
    updateInput(event) {
      let status = event.target.checked ? 'active' : 'inactive'
      this.filterBystatus(status)
    },
    getItems() {
      var self = this
      this.loader = true
      customizeService.getModifier().then((res) => {
        this.loader = false
        // self.tableData = res.data
        self.tempData = JSON.parse(JSON.stringify(res.data))
        self.tableData = self.tempData.filter((item) => item.status == 'active')
      })
    },
  },
  mounted() {
    // Fuse search initialization.
    // this.fuseSearch = new Fuse(this.tableData, {
    //   keys: ["name"],
    //   threshold: 0.6
    // });
    //    let table = document.querySelector("tbody")
    //    console.log(table)
    //    debugger
    //   const _self = this
    //   Sortable.create(table, {
    //   handle: ".handle", // Use handle so user can select text
    //   onEnd({ newIndex, oldIndex }) {
    //     debugger
    //     // const rowSelected = _self.desserts.splice(oldIndex, 1)[0]; // Get the selected row and remove it
    //     // _self.desserts.splice(newIndex, 0, rowSelected); // Move it to the new index
    //   }
    // })
  },

  created() {
    this.getItems()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['name', 'modifier_type'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}
.fa-ban {
  color: red;
}

.active-inactive-btn li {
  list-style: none;
  float: right;
}
.active {
  border-bottom: 2px solid #2b82be !important;
}
</style>
