<template>
  <div class="md-layout">
    <md-button
      class="onboard-btn"
      :class="{ 'md-primary': !onboardingBtnDisabled }"
      @click="generateOnboardingLink()"
      :disabled="onboardingBtnDisabled"
      v-if="!loader"
    >
      Continue onboarding
    </md-button>

    <div class="loader-container" v-if="loader">
      <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
    </div>
    <div
      class="md-layout-item md-size-100 accounts-main gradient-bg"
      v-if="!loader"
    >
      <tabs
        :tab-name="['Legal Entity', 'Business Line', 'Stores']"
        :tab-icon="['policy', 'handshake', 'store']"
        flex-column
        nav-pills-icons
        color-button="success"
        id="tabs-btn"
      >
        <!-- here you can add your content for tab-content -->
        <template slot="tab-pane-1">
          <div class="md-layout legal-entity gradient-bg">
            <div class="md-layout-item mb-15 md-size-100">
              <h5 class="title-tab">Legal Entity</h5>
            </div>
            <div class="md-layout-item mb-15 md-size-30">
              <span>Legal Entity ID</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{ cbePay.legalEntity ? cbePay.legalEntity.id : '' }}</span>
            </div>
            <div class="md-layout-item mb-15 md-size-30">
              <span>Legal Entity type</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{ legalEntityTypes[cbePay.legalEntity.type] }}</span>
            </div>
            <div class="md-layout-item mb-15 md-size-30">
              <span>Name</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{ legalEntityName }}</span>
            </div>
            <template v-if="cbePay.legalEntity.type == 1">
              <div class="md-layout-item mb-15 md-size-30">
                <span>Email</span>
              </div>
              <div class="md-layout-item mb-15 md-size-50">
                <span>{{
                  cbePay.legalEntity[legalEntityTypes[cbePay.legalEntity.type]]
                    .email
                }}</span>
              </div>
              <div class="md-layout-item mb-15 md-size-30">
                <span>Date of birth</span>
              </div>
              <div class="md-layout-item mb-15 md-size-50">
                <!-- <span>{{
                cbePay.legalEntity[legalEntityTypes[cbePay.legalEntity.type]]
                  .birthData.dateOfBirth
              }}</span> -->
                <span>{{
                  cbePay.legalEntity[legalEntityTypes[cbePay.legalEntity.type]]
                    .birthData
                    ? cbePay.legalEntity[
                        legalEntityTypes[cbePay.legalEntity.type]
                      ].birthData.dateOfBirth
                    : ''
                }}</span>
              </div>
            </template>

            <div class="md-layout-item mb-15 md-size-30">
              <span>Residential address</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{ legalEntityAddress }}</span>
            </div>
            <div class="md-layout-item mb-15 md-size-30">
              <span>Country</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{
                cbePay.legalEntity.type == 1
                  ? cbePay.legalEntity[
                      legalEntityTypes[cbePay.legalEntity.type]
                    ].residentialAddress.country
                  : cbePay.legalEntity[
                      legalEntityTypes[cbePay.legalEntity.type]
                    ].registeredAddress.country
              }}</span>
            </div>
            <div
              class="md-layout-item warning-holder md-size-95"
              v-for="(element, index) in verificationErrors"
              :key="`${index}`"
            >
              <span><strong>WARNING - </strong></span>
              <span
                ><strong>{{ element.errorMessage }}</strong>
              </span>
              <span>({{ element.errorCode }})</span>
            </div>
          </div>
        </template>
        <template slot="tab-pane-2">
          <div class="md-layout legal-entity gradient-bg">
            <div class="md-layout-item mb-15 md-size-100">
              <h5 class="title-tab">Business Line</h5>
            </div>
            <div class="md-layout-item mb-15 md-size-30">
              <span>Business line ID</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{ cbePay.businessLine.id }}</span>
            </div>
            <div class="md-layout-item mb-15 md-size-30">
              <span>Sales Channels</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{ cbePay.businessLine.salesChannels.join(', ') }}</span>
            </div>
            <div class="md-layout-item mb-15 md-size-30">
              <span>Service</span>
            </div>

            <div class="md-layout-item mb-15 md-size-30">
              <span>{{
                businessLineServices[cbePay.businessLine.service]
              }}</span>
            </div>
          </div>
        </template>
        <template slot="tab-pane-3">
          <div class="md-layout legal-entity gradient-bg">
            <div class="md-layout-item mb-15 md-size-100">
              <h5 class="title-tab">Stores</h5>
            </div>
            <div class="md-layout-item mb-15 md-size-30">
              <span>Store ID</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{ cbePay.store.id }}</span>
            </div>

            <div class="md-layout-item mb-15 md-size-30">
              <span>Name</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{ cbePay.store.description }}</span>
            </div>

            <div class="md-layout-item mb-15 md-size-30">
              <span>Address</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{
                `${cbePay.store.address.line1},
                ${cbePay.store.address.line2},
                ${cbePay.store.address.line3}`
              }}</span>
            </div>
            <div class="md-layout-item mb-15 md-size-30">
              <span>Phone Number</span>
            </div>
            <div class="md-layout-item mb-15 md-size-50">
              <span>{{ cbePay.store.phoneNumber }}</span>
            </div>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from '@/components'
import { Collapse } from '@/components'
import { cbePayService } from '@/services/cbePay.js'
import moment from 'moment'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import store from '@/store'

export default {
  components: {
    Tabs,
    Collapse,
    ClipLoader,
  },
  data() {
    return {
      loader: true,
      verificationErrors: [],
      legalEntityTypes: { 1: 'individual', 2: 'organization' },
      businessLineServices: { 1: 'Payment processing', 2: '' },
      legalEntityName: '',
      legalEntityAddress: '',
      onboardingBtnDisabled: false,
      cbePay: {
        legalEntity: {},
        onboardingData: {
          cbePay: { legalEntity: { individual: {} } },
        },
      },
    }
  },

  created() {
    cbePayService.getCbePayAccount().then((res) => {
      this.cbePay = res.data
      const completedKyc =
        this.countItemsWithVerificationStatusCompletedAndTotal(
          this.cbePay.accountHolder.capabilities
        )
      if (completedKyc.countVerificationStatusOne == completedKyc.totalCount) {
          this.onboardingBtnDisabled = true
          cbePayService.getAlterationTime().then((res) => {
            if(res.data){
              let alterationTime = this.new_formatDate(res.data)
              let formattedDate = this.new_formatDate(new Date())
              if (alterationTime > formattedDate) {
                this.onboardingBtnDisabled = false
              }else{
                this.onboardingBtnDisabled = true
              }
            }
            
         });
      }
      const legalEntityTypeObj =
        this.cbePay.legalEntity[
          this.legalEntityTypes[this.cbePay.legalEntity.type]
        ]
      if (this.cbePay.legalEntity && this.cbePay.legalEntity.type == 1) {
        const name =
          this.cbePay.legalEntity[
            this.legalEntityTypes[this.cbePay.legalEntity.type]
          ].name
        this.legalEntityName = `${name.firstName} ${name.lastName}`
        this.legalEntityAddress = `${legalEntityTypeObj.residentialAddress.street}, ${legalEntityTypeObj.residentialAddress.street2}, ${legalEntityTypeObj.residentialAddress.postalCode}`
      } else {
        this.legalEntityName = legalEntityTypeObj.legalName

        this.legalEntityAddress = `${
          legalEntityTypeObj.registeredAddress.street || ''
        }, ${legalEntityTypeObj.registeredAddress.street2 || ''}, ${
          legalEntityTypeObj.registeredAddress.postalCode || ''
        }`
      }
      this.verificationErrors = this.extractVerificationErrors(
        this.cbePay.accountHolder
      )
      this.loader = false
    })
  },
  methods: {
    new_formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    countItemsWithVerificationStatusCompletedAndTotal(data) {
      // Define a recursive function to traverse the JSON object
      let countVerificationStatusOne = 0
      let totalCount = 0
      // const countItems = (obj) => {
      for (const key in data) {
        if (typeof data[key] === 'object' && data[key] !== null) {
          totalCount++
          if (
            data[key].hasOwnProperty('verificationStatus') &&
            data[key].verificationStatus == 4
          ) {
            countVerificationStatusOne++
          }
          // countItems(obj[key])
        }
      }
      // }

      // Start counting
      // countItems(data)
      return { countVerificationStatusOne, totalCount }
    },
    generateOnboardingLink() {
      cbePayService.goToOnboard().then((res) => {
        this.loader = false

        //console.log(res)
        // this.stripe_account_no = res.data.id
        window.open(res.data, '_blank')
      })
    },
    extractVerificationErrors(data) {
      const verificationErrors = []
      // Extract verification errors from each capability
      for (const capability in data.capabilities) {
        const problems = data.capabilities[capability].problems

        if (problems && problems.length > 0) {
          problems.forEach((problem) => {
            const entity = problem.entity
            const errors = problem.verificationErrors
            if (errors && errors.length > 0) {
              errors.forEach((error) => {
                const errorMessage = error.message
                const errorCode = error.code
                let verificationError = verificationErrors.find(
                  (el) => el.errorCode == errorCode
                )
                if (!verificationError)
                  verificationErrors.push({ errorMessage, errorCode })
              })
            }
          })
        }
      }
      //console.log('errors', verificationErrors)
      return verificationErrors
    },
  },
}
</script>
<style>
#tabs-btn .active.md-list-item.md-success .md-list-item-button {
  background-color: #2882a2 !important;
  color: #fff !important;
  width: 206px;
}
#tabs-btn .md-list-item-button {
  width: 206px;
  color: #fff !important;
}
.flex-column .tab-content {
  padding: 0;
  padding-left: 50px;
  min-width: unset;
}
.flex-column .md-list {
  min-width: unset;
}
.gradient-bg {
  background: linear-gradient(
    109.46deg,
    hsla(0, 0%, 78.8%, 0.8) 1.57%,
    hsla(0, 0%, 76.9%, 0.1)
  );
  border-radius: 5px;
}
.accounts-main {
  margin-top: 25px;
}
.legal-entity {
  font-size: 16px;
  padding-bottom: 35px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.title-tab {
  color: #000 !important;
  font-weight: 600;
  padding-top: 15px;
  font-size: 22px;
}
.onboard-btn {
  color: #fff;
  padding: 10px;
  font-size: 14px;
  margin-left: auto;
  margin-top: 25px;
  border: none;
  border-radius: 5px;
}
.warning-holder {
  background-color: #fababb;
  padding: 10px;
  margin: auto;
  margin-bottom: 5px !important;
  margin-top: 5px;
}
.warning-holder span {
  color: #000;
}
</style>
