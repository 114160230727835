<template>
  <md-card class="block-bg">
    <!-- <md-card-header>
      <div class="md-layout-item md-size-70">
        <span class="arrow_left">
          <router-link :to="{ path: '/online-ordering/orders' }">
            <img src="../../../assets/images/left.svg" />
          </router-link>
          <h3>Order Details</h3>
        </span>
      </div>
      <div class="md-title"></div>
    </md-card-header> -->

    <md-card-header class="md-card-header">
      <div class="md-layout-item md-size-70 cardHeader">
        <span class="arrow_left">
          <router-link :to="{ path: '/online-ordering/orders' }">
            <img src="../../../assets/images/left.svg" />
          </router-link>
        </span>
        <h3>Order Details</h3>
      </div>
    </md-card-header>
    <!-- <div class="mainCard md-gutter">
      <md-card class="itemsCard">
        <md-card-header>Order Items</md-card-header>
        <md-card-content>
          <div v-for="(item, index) in orderData.cart_items" :key="index">
            <div class="orderItems">
              <div>{{ item.name }}</div>
              <div>{{ item.price }}</div>
            </div>
          </div>
        </md-card-content>
      </md-card>
      <md-card class="userCard">
        <div>{{ userData.first_name }} {{ userData.last_name }}</div>
      </md-card>
    </div> -->
    <div class="loader-container" v-if="loader">
      <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
    </div>
    <md-card class="inner-card" v-if="!loader">
      <div class="d-flex justify-content-between mb-25">
        <div class="dateDiv flex-column">
          <div class="">
            {{ moment(orderData.order_date).format('HH:mm A') }}
            <!-- eslint-disable-next-line vue/no-parsing-error -->
            {{
              moment(orderData.order_date)
                .subtract(0, 'days')
                .calendar()
                .split(' at')[0]
            }}
          </div>
          <div class="dateSecondDiv text-white font-weight-bold">
            {{ moment(orderData.order_date).format('dddd DD MMMM') }}
          </div>
        </div>
        <div class="detailsDiv">
          <div>
            <label>Amount / Payment</label>
          </div>
          <div class="flex">
            <div class="amount text-white font-weight-bold">
              € {{ orderData.amount }}
            </div>
            <!-- <div class="paymentPaid" v-if="(orderData.payment_status === paid)">
              Paid
            </div>
            <div
              class="paymentProcessing"
              v-if="(orderData.payment_status === processing)"
            >
              Processing
            </div>
            <div
              class="paymentFailed"
              v-if="(orderData.payment_status === failed)"
            >
              Failed
            </div> -->
            <div class="paymentProcessing text-white font-11">(Processing)</div>
            <div v-if="cardData !== ''">
              (paid with card number ****{{ cardData }})
            </div>
          </div>
        </div>
        <div class="detailsDiv">
          <div>
            <label>Customer Phone</label>
          </div>
          <div class="phone text-white font-weight-bold">
            {{ userData.phone }}
          </div>
        </div>
      </div>
      <div class="line_segment" />
      <div class="mainCard left-padding">
        <div class="itemsCard">
          <h4 class="font-weight-bold">Order Items</h4>

          <div
            class="pad-b-15px"
            v-for="(item, index) in orderData.cart_items"
            :key="index"
          >
            <div class="orderItems">
              <div class="item-name">
                <div>
                  {{ item.name }}
                </div>
                <div
                  v-for="(item, index) in item.menu_modifiers"
                  :key="index"
                  class="modifiers-div ml-auto"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="item-qty">
                {{ item.qty }}
              </div>
              <div class="item-price">€ {{ item.price }}</div>
            </div>
          </div>
          <div class="line_segment" />
        </div>
        <div class="userCard">
          <h4 class="font-weight-bold">Customer Info</h4>
          <div>{{ userData.first_name }} {{ userData.last_name }}</div>
        </div>
      </div>
    </md-card>
    <div
      class="d-flex align-items-center pad-l-15px"
      v-if="orderData.payment_type == 'card'"
    >
      <md-button
        class="md-dense md-primary refund-button"
        @click="orderRefund(orderId)"
        >Refund</md-button
      >
    </div>
  </md-card>
</template>
<script>
import { customizeService } from '../../../services/customize.js'
import moment from 'moment'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    ClipLoader,
  },
  props: {},
  data() {
    return {
      orderData: {},
      userData: {},
      cardData: {},
      loader: false,
    }
  },
  methods: {
    getItems(orderId) {
      this.loader = true
      customizeService.getOnlineOrder(orderId).then((res) => {
        this.loader = false
        this.orderData = res.data.order
        this.userData = res.data.user
        this.cardData = res.data.card
      })
    },

    orderRefund(orderId) {
      customizeService.refundOrder(orderId).then((res) => {
        this.refundData = res.data
        console.log(this.refundData)
      })
    },
  },

  created() {
    this.orderId = this.$route.query.order
    this.getItems(this.orderId)
    // debugger
    // let self = this

    // this.loader = true
    // customizeService.getMenuSectionById(this.orderId).then((res) => {
    //   this.loader = false
    //   this.menuSection = res.data.api_v1_menu_section
    // })
  },
}
</script>
<style>
.md-card-header {
  padding-left: 0 !important;
}

.cardHeader {
  display: flex;
  padding-left: 0;
}

.mainCard {
  padding-left: 15px;
  padding-bottom: 25px;
}

.orderItems {
  display: flex;
  justify-content: space-between;
}

.itemsCard {
  max-width: 750px;
  width: 100%;
}

.userCard {
  max-width: 650px;
  width: 100%;
}

.topDiv {
  display: flex;
  padding-left: 15px;
  align-items: center;
  margin-top: 15px;
}

.line_segment {
  height: 1px;
  border: 0;
  background-color: #bdc3c7;
  margin-bottom: 16px;
  margin-top: 20px;
  width: 100%;
}

.header5 {
  margin-bottom: 5px !important;
}

.detailsDiv {
  width: 500px;
}

.dateDiv {
  display: flex;
  width: 500px;
}

.dateDivFirst {
  color: black;
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}

.dateSecondDiv {
  color: gray;
  font-size: 14px;
}

.amount {
  color: black;
  font-size: 16px;
}

.flex {
  display: flex;
}

.paymentPaid {
  font-size: 15px;
  margin-left: 10px;
  color: #46c90e;
  font-weight: 700;
  margin-right: 8px;
}

.paymentProcessing {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 700;
  margin-right: 8px;
}

.paymentFailed {
  font-size: 15px;
  margin-left: 10px;
  color: #ab0909;
  font-weight: 700;
  margin-right: 8px;
}

.phone {
  font-size: 15px;
}

.refund-button {
  width: 150px;
  margin-bottom: 20px;
}

.modifiers-div {
  margin-left: 10px;
  font-size: 12px;
}

.item-name {
  width: 500px;
}

.item-qty {
  width: 100px;
}

.item-price {
  width: 100px;
  text-align: right;
}

.bottom-div {
  display: flex;
  justify-content: flex-end;
}
.mb-25 {
  margin-bottom: 25px;
}
@media only screen and (min-width: 1199px) {
  .mainCard {
    display: flex;
    justify-content: space-between;
  }
  .userCard {
    padding-left: 25px;
  }
}
</style>
