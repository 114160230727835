<template>
  <div>
    <div class="md-layout itemized-outlet-info">
      <div class="md-layout-item md-size-100 hd-typ1">{{ userName }}</div>
      <div class="md-layout-item md-size-100 outlet-logo">
        <img class="outlet-logo-img" :src="userImage" alt="outlet-logo" />
      </div>
    </div>
    <div class="flipdish-order-info text-center" v-if="billDetails.flipdish">
      <p>#{{ billDetails.flipdish.OrderId }}<br /><span>Paid</span></p>
      <p>Customer:{{ billDetails.flipdish.Customer.Name }}</p>
      <p>
        Pick Up:
        {{ moment(billDetails.flipdish.RequestedForTime).format('HH:mm') }}
      </p>
      <p>
        Date :
        {{ moment(billDetails.flipdish.PlacedTime).format('DD/MM/YYYY') }}
      </p>
      <p>Phone Number:{{ billDetails.flipdish.Customer.PhoneNumber }}</p>
    </div>
    <div class="content-section">
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          <div class="bill-sub-value">Bill: {{ billDetails.bill.bill_no }}</div>
          <div class="bill-sub-value">
            Tables: {{ billDetails.bill.table_name }}
          </div>
          <div class="bill-sub-value">
            Guests: {{ billDetails.bill.guest_count }}
          </div>
        </div>
        <div class="md-layout-item md-size-50">
          <div class="bill-sub-value text-right">
            Order Type: {{ billDetails.bill.order_type }}
          </div>
          <div class="bill-sub-value text-right">
            Staff: {{ billDetails.bill.name }}
          </div>
          <div class="bill-sub-value text-right">{{ formattedDate }}</div>
        </div>
      </div>
      <div class="md-layout">
        <table class="md-layout-item">
          <tr>
            <th>Items</th>
            <th>Rate</th>
            <th>Qty</th>
            <th>Amount</th>
          </tr>
          <tr v-for="(item, index) in billDetails.bill.combo_info" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.price.toFixed(2) }}</td>
            <td>1</td>
            <td>{{ item.price.toFixed(2) }}</td>
          </tr>
          <tr v-for="(item, index) in billDetails.bill.items" :key="index">
            <td>
              {{ item.item_name }}
              <div v-if="item.item_name.toLowerCase() == 'voucher'">
                {{ item.course_name }}
              </div>
            </td>
            <td>{{ item.rate }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.amount }}</td>
          </tr>
        </table>
      </div>
      <div class="md-layout dashed-border">
        <div class="md-layout-item md-size-75 text-right">Sub Total:</div>
        <div class="md-layout-item md-size-25 text-right">
          {{
            (billDetails.bill.bill_total + billDetails.bill.discount).toFixed(2)
          }}
        </div>

        <slot
          v-if="
            billDetails.bill.discount > 0 || billDetails.bill.item_discount > 0
          "
        >
          <div class="md-layout-item md-size-75 text-right">Less Discount:</div>
          <div class="md-layout-item md-size-25 text-right">
            {{ billDetails.bill.discount.toFixed(2) }}
          </div>
        </slot>

        <slot v-if="billDetails.bill.service_charge > 0.009">
          <div class="md-layout-item md-size-75 text-right">
            Service Charge:
          </div>
          <div class="md-layout-item md-size-25 text-right">
            {{ billDetails.bill.service_charge.toFixed(2) }}
          </div>
        </slot>
        <slot v-if="Math.abs(billDetails.bill.tips) > 0">
          <div class="md-layout-item md-size-75 text-right">Gratuity:</div>
          <div class="md-layout-item md-size-25 text-right">
            {{ billDetails.bill.tips }}
          </div>
        </slot>
        <div class="md-layout-item md-size-75 text-right total-hd">Total:</div>
        <div class="md-layout-item md-size-25 text-right total-amount">
          {{
            (
              billDetails.bill.bill_total +
              otherTotal +
              billDetails.bill.tips +
              billDetails.bill.service_charge
            ).toFixed(2)
          }}
        </div>
        <slot v-if="Math.abs(billDetails.bill.cash_rcvd) > 0.009">
          <div class="md-layout-item md-size-75 text-right">Cash:</div>
          <div class="md-layout-item md-size-25 text-right">
            {{ getBillCashWithServiceCharge() }}
          </div>
        </slot>
        <slot v-if="Math.abs(billDetails.bill.credit_amt) > 0.009">
          <div class="md-layout-item md-size-75 text-right">Card:</div>
          <div class="md-layout-item md-size-25 text-right">
            {{ getBillCardWithServiceCharge() }}
          </div>
        </slot>
        <slot v-for="(item, index) in billDetails.other">
          <div class="md-layout-item md-size-75 text-right" :key="index">
            {{ item.name }}:
          </div>
          <div class="md-layout-item md-size-25 text-right" :key="item.id">
            {{ item.price.toFixed(2) }}
          </div>
        </slot>
        <slot v-if="Math.abs(billDetails.bill.voucher_amt) > 0.009">
          <div class="md-layout-item md-size-75 text-right">Voucher:</div>
          <div class="md-layout-item md-size-25 text-right">
            {{ billDetails.bill.voucher_amt.toFixed(2) }}
          </div>
        </slot>

        <slot v-if="billDetails.bill.additional_amt > 0.009">
          <div class="md-layout-item md-size-75 text-right">CHANGE:</div>
          <div class="md-layout-item md-size-25 text-right">
            {{ billDetails.bill.additional_amt.toFixed(2) }}
          </div>
        </slot>

        <slot v-if="billDetails.bill.discount_category_name">
          <div class="md-layout-item md-size-75 text-right">
            {{ billDetails.bill.discount_category_name }}:
          </div>
          <div class="md-layout-item md-size-25 text-right">
            {{ billDetails.bill.other_amt.toFixed(2) }}
          </div>
        </slot>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">VAT Summary</div>
        <slot v-for="(tax, index) in billDetails.tax">
          <div class="md-layout-item md-size-75 text-right" :key="index">
            Vat at {{ tax.tax }} %
          </div>
          <div class="md-layout-item md-size-25 text-right" :key="tax.tax">
            {{ tax.sales_price.toFixed(2) }}
          </div>
        </slot>
      </div>
      <div v-if="billDetails.bill_vouchers.length">
        <div class="md-layout voucher-summary-title">Voucher Summary</div>
        <div class="md-layout summary-title">
          <div class="md-layout-item md-size-45">Voucher</div>
          <div class="md-layout-item md-size-20">Prev Bal</div>
          <div class="md-layout-item md-size-20">Charged</div>
          <div class="md-layout-item md-size-15">Current</div>
        </div>
        <div
          class="md-layout summary-info"
          v-for="data in billDetails.bill_vouchers"
          :key="data.voucher_no"
        >
          <div class="md-layout-item md-size-45">{{ data.voucher_no }}</div>
          <div class="md-layout-item md-size-20 text-center">
            {{ data.prev_amount.toFixed(2) }}
          </div>
          <div class="md-layout-item md-size-20 text-center">
            {{ data.amount.toFixed(2) }}
          </div>
          <div class="md-layout-item md-size-15 text-center">
            {{ (data.prev_amount - data.amount).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>

    <div class="md-layout barcode-section text-left mx-10">
      <span v-if="billDetails.bill.card_ref_id">
        <div v-if="billDetails.bill.card_type == 6">
          <span class="pr-10">CARDHOLDER COPY</span><br />
          <span class="pr-10 approved-text">APPROVED</span>
        </div>
        <template v-for="item in billDetails.bill.card_ref_summary">
          <div
            v-if="
              item.title != 'Customer Receipt' &&
              billDetails.bill.card_type != 6
            "
            :key="item.title"
            class="text-center"
            :class="[
              billDetails.bill.card_type == 7 ? 'text-left' : 'text-center',
            ]"
          >
            {{ item.title }}: {{ item.value }}
            <br />
          </div>

          <div
            v-if="item.title == 'Customer Receipt'"
            :key="item.title"
            class="text-left mx-10"
          >
            <div v-for="(item, index) in item.value" :key="index">
              <pre>{{ item != '' ? item : ' ' }}</pre>
            </div>
          </div>
          <!-- PSp Referenace: {{ billDetails.bill.card_ref_id }} -->
        </template>
        <!-- <div v-if="billDetails.bill.card_type == 6">
          <span class="pr-10">PSPReferenace:</span><br />
          <span class="pr-10">{{
            billDetails.bill.card_ref_id.split(',')[0].split('.').slice(-1)[0]
          }}</span>
        </div> -->
        <!-- Transaction No:
        {{
          billDetails.bill.card_ref_id.split(',')[2].replace(/[^a-z0-9]/gi, '')
        }}
        <br />AuthCode:
        {{
          billDetails.bill.card_ref_id.split(',')[3].replace(/[^a-z0-9]/gi, '')
        }} -->
      </span>

      <div class="md-layout-item md-size-100 thanku-hd">
        Thank you for coming !
      </div>
      <div class="md-layout-item md-size-100 text-center">
        <svg id="barcode"></svg>
      </div>
      <div class="md-layout-item md-size-100 download-btn text-center">
        <md-button
          class="text-white"
          data-html2canvas-ignore
          @click="downloadBill"
          >Download</md-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import moment from 'moment'

import $ from 'jquery'
export default {
  name: 'itemized-bill',
  props: ['billDetails'],
  data() {
    return {
      otherTotal: 0,
    }
  },
  computed: {
    formattedDate() {
      // let date = new Date(this.billDetails.bill.bill_date)
      // let day = date.getDate()
      // let month = date.getMonth() + 1
      // let year = date.getFullYear()
      // let hours = date.getHours()
      // let mins = date.getMinutes()
      return moment
        .utc(this.billDetails.bill.bill_date)
        .format('DD/MM/YYYY H:mm')
    },
    userName() {
      return this.$store.getters.getProfile.display_name
    },
    userImage() {
      return this.$store.getters.getProfile.logo
    },
  },

  methods: {
    getCardRerence(cardType, refId) {
      let refernces = []
      switch (cardType) {
        case 1:
          // code block
          cardRefId = refId.split(',')[1].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'SagePay refId', value: cardRefId })
          break
        case 2:
          cardRefId = refId.split(',')[2].replace(/[^a-z0-9]/gi, '')
          authCode = refId.split(',')[3].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'transactionNumber', value: cardRefId })
          refernces.push({ title: 'AuthCode', value: authCode })
          break
        case 3:
          cardRefId = refId.split(',')[1].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'transactionNumber', value: cardRefId })
          // code block
          break
        case 4:
          cardRefId = refId.split(',')[2].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'Clover paymentId', value: cardRefId })

          break
        case 5:
          cardRefId = refId.split(',')[0].replace(/[^a-z0-9]/gi, '')
          refernces.push({ title: 'TransactionId', value: cardRefId })
          break
        default:
        // code block
      }
      return refernces
    },
    downloadBill() {
      this.$emit('downloadBill', this.billDetails.bill.bill_no)
    },
    getBillCashWithServiceCharge() {
      let bill = this.billDetails.bill
      let serviceCharge = bill.serviceCharge
      if (Math.abs(bill.cash_rcvd) > 0) {
        serviceCharge = Math.abs(bill.cash_rcvd) > 0 ? bill.service_charge : 0
        return (bill.cash_rcvd + serviceCharge).toFixed(2)
      } else {
        return 0
      }
    },

    getBillCardWithServiceCharge() {
      let bill = this.billDetails.bill
      let serviceCharge = bill.serviceCharge
      if (Math.abs(bill.credit_amt) > 0) {
        serviceCharge = Math.abs(bill.cash_rcvd) > 0 ? 0 : bill.service_charge
        return (bill.credit_amt + serviceCharge).toFixed(2)
      } else {
        return 0
      }
    },
  },

  mounted() {
    JsBarcode('#barcode', this.billDetails.bill.bill_no, {
      height: 40,
      fontSize: 12,
    })
  },
  created() {
    this.otherTotal = this.billDetails.other
      .filter((el) => el.is_fee == true)
      .map((item) => item.price)
      .reduce((prev, curr) => prev + curr, 0)
  },
}
</script>

<style>
.barcode-section {
  text-align: center;
  border-top: solid 2px;
}

.itemized-outlet-info {
  text-align: center;
}

.outlet-logo {
  width: 70%;
}

.outlet-logo-img {
  height: 20px !important;
  padding: 3px;
  width: 70%;
}
.itemised-trans-bill.bill-container .md-dialog-container {
  width: 350px !important;
  font-weight: 600 !important;
  font-family: Roboto, Noto Sans, -apple-system, BlinkMacSystemFont, sans-serif !important;
  font-size: 15px;
  line-height: 22px;
}
#bill-detail .md-layout-item {
  padding-left: 0;
}

.dashed-border {
  border-bottom: 2px dashed;
}
.itemised-trans-bill.bill-container .hd-typ1 {
  color: #272727;
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 15px;
}
.bill-sub-value {
  font-weight: 600;
  font-size: 15px;
  padding: 5px 0px;
}
.itemised-trans-bill .md-dialog-container table tr td {
  font-weight: 600;
  font-size: 15px;
  padding: 5px 0px;
}
.itemised-trans-bill .text-right {
  font-weight: 600;
  font-size: 15px;
  padding: 5px 0px;
}
.itemised-trans-bill .md-ripple {
  line-height: 17px;
  font-size: 11px;
  padding: 4px 8px !important;
  background-color: #353333;
}
.itemised-trans-bill .thanku-hd {
  color: #272727;
  text-align: center;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.itemised-trans-bill .outlet-logo {
  margin-bottom: 10px;
}
.itemised-trans-bill .outlet-logo .outlet-logo-img {
  width: auto;
  height: auto !important;
}
.itemised-trans-bill .text-right.total-amount,
.itemised-trans-bill .text-right.total-hd {
  font-size: 20px;
}
.itemised-trans-bill .md-dialog-container table tr th {
  padding: 6px 0;
}
.bill-container .md-dialog-container.md-theme-default {
  background-color: #fff !important;
  overflow: auto;
}
.download-btn .md-button-content {
  color: #fff !important;
  font-weight: 700;
}
.margin-auto {
  margin-left: auto;
  margin-right: auto;
}
pre {
  margin: 0;
  font-family: inherit !important;
}
.pr-10 {
  padding-left: 10px;
}
.summary-title {
  border-bottom: 3px solid #000;
}
.summary-title .md-layout-item {
  padding: 0 !important;
}
.voucher-summary-title {
  border-top: 2px dashed #000;
  text-decoration: underline;
  padding-top: 5px;
  padding-bottom: 10px;
}
.content-section .summary-info:last-child {
  border-bottom: 2px dashed #000;
}
.approved-text {
  display: block;
  text-align: center;
  width: 100%;
}
.flipdish-order-info p {
  margin: 5px 0px;
}
</style>
