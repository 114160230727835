var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tax-summary report-drop"},[_c('md-card',{staticClass:"report-card block-bg"},[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-green md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-70 report-name"},[_c('span',{staticClass:"arrow_left"},[_c('router-link',{attrs:{"to":{ path: '/dashboard' }}},[_c('img',{attrs:{"src":require("../../../../assets/images/left.svg")}})])],1),_c('h3',[_vm._v(_vm._s(_vm.$t('Tax Summary Report')))])])]),_c('md-card-content',[_c('div',{staticClass:"md-layout filter-section block-bg"},[_c('div',{staticClass:"md-layout search-options"},[_c('div',{staticClass:"md-layout-item md-size-60 pd-l-0"},[_c('date-period-dropdown',{on:{"interval":_vm.setDateType}})],1),_c('div',{staticClass:"md-layout-item md-size-25"},[_c('div',{staticClass:"md-layout datepickers"},[(_vm.role() == 'branch_user')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-50 pd-r-10 pd-l-0-i-pad",class:[
                  { 'md-size-50': _vm.role() == 'branch_user' },
                  { 'md-size-50': _vm.role() != 'branch_user' } ]},[_c('md-field',[_c('label',[_vm._v("Outlet")]),_c('md-select',{attrs:{"multiple":""},on:{"md-closed":function($event){_vm.outletId.length > 0 ? _vm.taxSummary() : (_vm.outletId = [])}},model:{value:(_vm.outletId),callback:function ($$v) {_vm.outletId=$$v},expression:"outletId"}},_vm._l((_vm.outletList),function(item){return _c('md-option',{key:item.id,staticClass:"oulet-select-list-item",attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_vm._e()])]),_c('div',{staticClass:"md-layout-item md-size-15"},[_c('md-button',{staticClass:"md-teritiary",on:{"click":function($event){return _vm.taxSummary()}}},[_vm._v("Search ")])],1)])]),(_vm.loader)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{staticClass:"md-layout-item md-size-100",attrs:{"loading":_vm.loader,"color":'#00E0DB'}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.report.length > 1),expression:"report.length > 1"}],staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-90 md-size-90"},[_c('md-card',[_c('md-card-content',[_c('pie-chart',{attrs:{"chart-data":_vm.datacollection,"height":90,"options":{
                  legend: {
                    position: 'bottom',
                    labels: {
                      fontColor: '#ffffff',
                    },
                  },
                  plugins: {
                    datalabels: {
                      display: true,
                      font: {
                        weight: 'bold',
                        size: 12,
                        color: 'white',
                      },
                      color: 'white',
                      textStrokeColor: 'black',
                      textStrokeWidth: 3,
                    },
                  },
                }}})],1)],1)],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item pd-l"},[_c('md-table',{staticClass:"rep-table table-typ1"},[_c('md-table-toolbar',[_c('div',{staticClass:"md-toolbar-section-start toolbar-monthly"},[_c('h1',{staticClass:"md-title text-white font-weight-bold pad-l"},[_vm._v(" Tax Summary ")]),_c('div',{staticClass:"xlsx-pdf"},[_c('img',{staticClass:"pdf",attrs:{"src":require("../../../../assets/images/pdf.svg")},on:{"click":function($event){return _vm.downloadWithoutCSS()}}}),_c('vue-excel-xlsx',{staticClass:"excel-btn2 md-primary",attrs:{"data":_vm.report,"columns":_vm.columns,"sdate":_vm.sdate,"edate":_vm.edate,"filename":'Tax Summary',"sheetname":'Tax Summary',"outlet-names":_vm.selectedOutletsNames()}})],1)])]),_c('md-table-row',[_c('md-table-head',[_vm._v("No.Of Items")]),_c('md-table-head',[_vm._v("Tax Rate")]),_c('md-table-head',[_vm._v("Gross Sales ")]),_c('md-table-head',[_vm._v("Tax On Sales")]),_c('md-table-head',[_vm._v("Net Sales")])],1),_vm._l((_vm.report),function(item,index){return _c('md-table-row',{key:index},[_c('md-table-cell',[_vm._v(_vm._s(item.no_of_items))]),_c('md-table-cell',[_vm._v(_vm._s(item.tax_rate))]),_c('md-table-cell',[_vm._v(_vm._s(item.gross_sale))]),_c('md-table-cell',[_vm._v(_vm._s(item.tax_on_sale))]),_c('md-table-cell',[_vm._v(_vm._s(item.net_sale))])],1)})],2)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }