<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/Dashboard' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>Azpiral</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div v-if="!loader" class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Shop Id') }}</label>
              <md-input type="text" v-model.lazy="azpiral.shop_id"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-25">
            <md-field>
              <label>{{ $t('Processing Fee') }}</label>
              <md-select v-model="azpiral.menu_id">
                <md-option
                  :value="item.id"
                  v-for="item in mapped_items"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-25">
            <md-field>
              <label>{{ $t('Discount Category') }}</label>
              <md-select v-model="azpiral.discount_id">
                <md-option
                  :value="item.id"
                  v-for="item in vouchers"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-25">
            <md-field>
              <label>{{ $t('Payment Types') }}</label>
              <md-select v-model="azpiral.payment_type_id">
                <md-option
                  :value="item.id"
                  v-for="item in online_payments"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="btn-div">
          <md-card-actions class="text-center">
            <md-button native-type="submit" :to="{ path: '/Dashboard' }">{{
              $t('CANCEL')
            }}</md-button>
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ $t('UPDATE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      Token: '',
      touched: {
        Token: false,
      },
      modelValidations: {
        Token: {
          required: true,
        },
      },
      azpiral: {
        shop_id: '',
        menu_id: '',
        discount_id: '',
        payment_type_id: '',
      },
      token: '',
      appId: '',
      storeId: '',
      menuId: '',
      stores: [],
      menus: [],
      terminals: [],
      mapped_items: [],
      otherFeeTypes: [],
      vouchers: [],
      online_payments: [],
      selectedStoreIds: [],
      selectedTerminalIds: [],

      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            this.updateOutletFlipDish()
          }
        })
      }
    },

    updateMenuId() {
      // let matchedStore = this.stores.find(
      //   (store) => store.StoreId == this.storeId
      // )
      // if (matchedStore) {
      //   this.menuId = matchedStore.MenuId
      // }
      this.getMenus()
    },

    updateStores() {
      let body = {
        flipdish_token: this.token,
      }
      customizeService.updateFlipDishToken(body).then((res) => {
        this.appId = res.data[0].AppId
        this.getStores()
      })
    },

    updateOutletFlipDish() {
      let body = {
        outlets: {
          outlet: {
            azpiral_settings: this.flipDish,
          },
        },
      }
      this.loader = true
      customizeService
        .editOutlet(accountService.getOutletId(), body)
        .then((res) => {
          this.loader = false
        })
    },

    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminals = res.data
        // this.storeId = res.data.Data[0].StoreId
      })
    },
  },
  created() {
    customizeService.getDiscountCat().then((res) => {
      this.vouchers = res.data.discount_categories
    })
    customizeService
      .getOtherPaymentTypes(accountService.getOutletId())
      .then((res) => {
        this.online_payments = res.data.other_payment_types
        this.otherFeeTypes = res.data.other_payment_types.filter(
          (item) => item.status == true && item.is_fee == true
        )
      })

    customizeService.getMapItems().then((res) => {
      this.mapped_items = res.data
    })

    customizeService.getOutletById(accountService.getOutletId()).then((res) => {
      this.azpiral = res.data.outlet.azpiral_settings
        ? res.data.outlet.azpiral_settings
        : this.azpiral_settings

      if (res.data.outlet.flipdish_settings.flipDishStores) {
        this.selectedStoreIds =
          res.data.outlet.flipdish_settings.flipDishStores.map((el) => el.id)
      }

      this.appId = res.data.outlet.flipdish_app_id
      this.getTerminals()
      this.storeId = res.data.outlet.store_id
      this.menuId = res.data.outlet.flipdish_menu_id
      this.selectedTerminalIds =
        res.data.outlet.flipdish_settings.allowed_terminal_ids
    })

    // this.updateStores()
  },
  watch: {
    Token() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
