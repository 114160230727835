<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/outlet/scheduled-report' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType === 'View Scheduled Report'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editDiscountCat"
            >edit</i
          >
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                v-model="scheduledReport.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Scheduled Report'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Frequency') },
                { 'md-valid': !errors.has('Frequency') && touched.Frequency },
              ]"
            >
              <label for="Country">{{ $t('Frequency') }}</label>
              <md-select
                v-model="scheduledReport.reports.frequency"
                :disabled="formType == 'View Scheduled Report'"
                data-vv-name="Frequency"
                v-validate="modelValidations.Frequency"
                v-on:input="setDays()"
              >
                <md-option class="first-opt" disabled>Frequency</md-option>
                <md-option :value="'Daily'">{{ $t('Daily') }}</md-option>
                <md-option :value="'Weekly'">{{ $t('Weekly') }}</md-option>
                <md-option :value="'Monthly'">{{ $t('Monthly') }}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Frequency')">
                  {{ errors.first('Frequency') }}
                </div>
              </slide-y-down-transition>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Attachment') },
                { 'md-valid': !errors.has('Attachment') && touched.Attachment },
              ]"
            >
              <label for="Country">{{ $t('Attachment Type') }}</label>
              <md-select
                v-model="scheduledReport.reports.type"
                :disabled="formType == 'View Scheduled Report'"
                data-vv-name="Attachment"
                v-validate="modelValidations.Attachment"
              >
                <md-option class="first-opt" disabled
                  >Attachment Type</md-option
                >
                <md-option value="csv">{{ $t('CSV') }}</md-option>
                <md-option value="pdf">{{ $t('PDF') }}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Attachment')">
                  {{ errors.first('Attachment') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Attachment') && touched.Attachment"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <label>{{ $t('Time') }}</label>
            <!-- <md-input
                v-model="scheduledReport.reports.time"
                type="number"
                :disabled="formType == 'View Scheduled Report'"
              ></md-input> -->
            <md-field
              :class="[
                { 'md-error': errors.has('time') },
                { 'md-valid': !errors.has('time') && touched.time },
              ]"
            >
              <datetime
                class="scheduled-time"
                format="H:i"
                width="100%"
                v-model="scheduledReport.reports.time"
                v-validate="modelValidations.Time"
                data-vv-name="time"
              ></datetime>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('time')">
                  {{ errors.first('time') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('time') && touched.time"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>

          <div
            class="md-layout-item md-size-50"
            v-if="scheduledReport.reports.frequency == 'Weekly'"
          >
            <md-field>
              <label for="Country">{{ $t('Day') }}</label>
              <md-select
                v-model="scheduledReport.reports.day"
                :disabled="formType == 'View Scheduled Report'"
              >
                <md-option class="first-opt" disabled>Day</md-option>
                <md-option value="1">{{ $t('Monday') }}</md-option>
                <md-option value="2">{{ $t('Tuesday') }}</md-option>
                <md-option value="3">{{ $t('Wednesday') }}</md-option>
                <md-option value="4">{{ $t('Thursday') }}</md-option>
                <md-option value="5">{{ $t('Friday') }}</md-option>
                <md-option value="6">{{ $t('Saturday') }}</md-option>
                <md-option value="7">{{ $t('Sunday') }}</md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Email') },
                { 'md-valid': !errors.has('Email') && touched.Email },
              ]"
            >
              <label>{{ $t('Email') }}</label>
              <md-input
                v-model="scheduledReport.reports.email"
                data-vv-name="Email"
                type="email"
                v-validate="modelValidations.Email"
                :disabled="formType == 'View Scheduled Report'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Email')">
                  {{ errors.first('Email') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Email') && touched.Email"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Status') }}</label>
              <md-select
                v-model="scheduledReport.status"
                :disabled="formType == 'View Scheduled Report'"
              >
                <!-- <md-option class="first-opt" disabled>Status</md-option> -->
                <md-option value="true">{{ $t('Active') }}</md-option>
                <md-option value="false">{{ $t('Inactive') }}</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <md-card-header>Reports</md-card-header>

        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-checkbox
              v-model="scheduledReport.reports.selected"
              :disabled="formType === 'View Scheduled Report'"
              value="weekly_report"
              >{{ $t('Weekly Sales') }}</md-checkbox
            >
          </div>
          <div class="md-layout-item md-size-100">
            <md-checkbox
              v-model="scheduledReport.reports.selected"
              :disabled="formType === 'View Scheduled Report'"
              value="labour"
              >{{ $t('Labor') }}</md-checkbox
            >
          </div>
          <div class="md-layout-item md-size-100">
            <md-checkbox
              v-model="scheduledReport.reports.selected"
              :disabled="formType === 'View Scheduled Report'"
              value="tax_report"
              >{{ $t('Tax Report') }}</md-checkbox
            >
          </div>
          <div class="md-layout-item md-size-100">
            <md-checkbox
              v-model="scheduledReport.reports.selected"
              :disabled="formType === 'View Scheduled Report'"
              value="menu_item_report"
              >{{ $t('Menu Item Report') }}</md-checkbox
            >
          </div>
          <div class="md-layout-item md-size-100">
            <md-checkbox
              v-model="scheduledReport.reports.selected"
              :disabled="formType === 'View Scheduled Report'"
              value="main_category"
              >{{ $t('Main Category') }}</md-checkbox
            >
          </div>
          <div class="md-layout-item md-size-100">
            <md-checkbox
              v-model="scheduledReport.reports.selected"
              :disabled="formType === 'View Scheduled Report'"
              value="hourly_sales"
              >{{ $t('Hourly Sales') }}</md-checkbox
            >
          </div>
          <div class="md-layout-item md-size-100">
            <md-checkbox
              v-model="scheduledReport.reports.selected"
              :disabled="formType === 'View Scheduled Report'"
              value="petty_cash"
              >{{ $t('Petty Cash') }}</md-checkbox
            >
          </div>

          <!-- <div class="md-layout-item md-size-100">
            <md-checkbox
              v-model="scheduledReport.reports.selected"
              :disabled="formType === 'View Scheduled Report'"
              value="void_report"
              >{{ $t('Void') }}</md-checkbox
            >
          </div> -->

          <div class="md-layout-item md-size-100">
            <md-checkbox
              v-model="scheduledReport.reports.selected"
              :disabled="formType === 'View Scheduled Report'"
              value="discount_category"
              >{{ $t('Discount Category Report') }}</md-checkbox
            >
          </div>

          <div
            class="md-layout-item md-size-50 pad-l-40px"
            v-if="
              scheduledReport.reports.selected.includes('discount_category')
            "
          >
            <md-checkbox
              v-for="dis in discountCategories"
              v-model="scheduledReport.reports.selected_discount_catefories"
              :disabled="formType === 'View Scheduled Report'"
              :value="dis.id"
              :key="dis.id"
              >{{ $t(dis.name) }}</md-checkbox
            >
          </div>
        </div>
        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Scheduled Report'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/outlet/scheduled-report' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              class="md-primary"
              native-type="submit"
              @click.native.prevent="validate"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import axios from 'axios'
import { accountService } from '../../../services/account'
import datetime from 'vuejs-datetimepicker'
export default {
  components: {
    SlideYDownTransition,
    datetime,
    axios,
  },
  props: {},

  data() {
    return {
      Name: '',
      Frequency: '',
      switch2: null,
      Discount: '',
      discountCategories: [],

      touched: {
        Name: false,
        Frequency: false,
        Attachment: false,
        Email: false,
        time: false,
      },

      modelValidations: {
        Name: {
          required: true,
        },
        Frequency: {
          required: true,
        },
        Attachment: {
          required: true,
        },
        Email: {
          required: true,
        },
        Time: {
          required: true,
        },
      },

      formType: 'Add Scheduled Report',

      scheduledReport: {
        status: true,
        reports: {
          selected: [],
          selected_discount_catefories: [],
        },
      },
      discount: {},
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitScheduledReport()
          }
        })
      }
    },

    setDays() {
      console.log('Inside set days')
      if (this.scheduledReport.reports.frequency == 'Daily') {
        delete this.scheduledReport.reports.day
      }
    },

    submitScheduledReport(url) {
      this.scheduledReport.outlet_id = accountService.getOutletId()
      if (this.formType == 'Add Scheduled Report') {
        customizeService
          .createScheduledReport(this.scheduledReport)
          .then((res) => {
            this.$router.push({ path: '/outlet/scheduled-report' })
          })
          .catch((err) => alert('Report creation error:', err))
      } else {
        axios
          .put(this.url, this.scheduledReport, customizeService.authFun())
          .then((res) => {
            this.$router.push({
              path: '/outlet/scheduled-report',
            })
          })
          .catch((err) => alert('Report Edit Error:', err))
      }
    },

    reset() {
      this.discount = {}
    },

    editDiscountCat() {
      this.formType = 'Edit Scheduled Report'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    let self = this
    if (this.formType != 'Add Scheduled Report') {
      this.url = this.$route.query.client
      axios
        .get(
          this.url + '?outlet_id=' + accountService.getOutletId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.scheduledReport = res.data
          if (!this.scheduledReport.reports.selected_discount_catefories) {
            this.scheduledReport.reports.selected_discount_catefories = []
          }
        })
    }

    customizeService
      .getDiscountCat(accountService.getOutletId())
      .then((res) => {
        this.discountCategories = res.data.discount_categories
      })
  },

  watch: {
    Name() {
      this.touched.required = true
    },
    Frequency() {
      this.touched.required = true
    },
    Attachment() {
      this.touched.required = true
    },
    Email() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
