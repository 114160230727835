<template>
  <form class="md-layout-item pd-l">
    <md-card class="pd-l">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left ml-auto">
            <router-link :to="{ path: '/products/Modifiers' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType === 'View Modifier Group'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editModifierGp"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader" class="block-bg pad-xy rounded-xl">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('required') },
                { 'md-valid': !errors.has('required') && touched.required },
              ]"
            >
              <label>{{ $t('Sort Order') }}</label>
              <md-input
                data-vv-name="sortOrder"
                type="number"
                v-model="modifierGroupDetails.modifier_group.sort_order"
                v-validate="modelValidations.required"
                :disabled="formType == 'View Modifier Group'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('sortOrder')">
                  {{ errors.first('sortOrder') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('sortOrder') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('ShortName') },
                { 'md-valid': !errors.has('ShortName') && touched.ShortName },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                data-vv-name="ShortName"
                type="text"
                v-model="modifierGroupDetails.modifier_group.name"
                v-validate="modelValidations.ShortName"
                :disabled="formType == 'View Modifier Group'"
                required
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('ShortName')">
                  {{ errors.first('ShortName') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('ShortName') && touched.ShortName"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('ShortName') },
                { 'md-valid': !errors.has('ShortName') && touched.ShortName }
              ]"
            >
              <label>{{$t('Name')}}</label>
              <md-input
                data-vv-name="ShortName"
                type="text"
                v-model="modifierGroupDetails.name"
                v-validate="modelValidations.ShortName"
                :disabled="formType == 'View Modifier Group'"
                required
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('ShortName')">{{errors.first('ShortName')}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('ShortName') && touched.ShortName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <!-- <div v-if="formType != 'View Modifier Group'">{{$t('Sort By')}}:</div> -->
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <div class="modifier-add md-layout">
              <div
                class="md-layout-item md-size-50 mod-click"
                @click="modDialog = true"
              >
                <h5>
                  <img
                    class="plus-icon"
                    src="../../../assets/images/plus.svg"
                  />
                  {{ $t('Modifier') }}
                </h5>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field
                  v-if="
                    sampleModifiers.length > 0 &&
                    formType != 'View Modifier Group'
                  "
                >
                  <label>Sort By:</label>
                  <md-select
                    v-model="modifier_sort_type"
                    v-on:input="updateSortOrder()"
                  >
                    <md-option value="0">{{ $t('Ascending') }}</md-option>
                    <md-option value="1">{{ $t('Descending') }}</md-option>
                    <md-option value="2">{{ $t('Custom') }}</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100">
            <draggable
              class="list-group"
              :list="sampleModifiers"
              :move="enableDrag"
            >
              <transition-group>
                <div
                  class="list-group-item row"
                  v-for="(element, index) in sampleModifiers"
                  :key="`key-${index}`"
                >
                  {{ element.name }}
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Minimum') },
                { 'md-valid': !errors.has('Minimum') && touched.Minimum },
              ]"
            >
              <label>{{ $t('Minimum') }}</label>
              <md-input
                data-vv-name="Minimum"
                type="number"
                v-model="modifierGroupDetails.modifier_group.min"
                v-validate="modelValidations.ShortName"
                :disabled="formType == 'View Modifier Group'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Minimum')">
                  {{ errors.first('Minimum') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Minimum') && touched.Minimum"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('ShortName') },
                { 'md-valid': !errors.has('ShortName') && touched.ShortName },
              ]"
            >
              <label>{{ $t('Maximum') }}</label>
              <md-input
                data-vv-name="Maximum"
                type="number"
                v-model="modifierGroupDetails.modifier_group.max"
                v-validate="modelValidations.ShortName"
                :disabled="formType == 'View Modifier Group'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Maximum')">
                  {{ errors.first('Maximum') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Maximum') && touched.Maximum"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('ShortName') },
                { 'md-valid': !errors.has('ShortName') && touched.ShortName }
              ]"
            >
              <label>{{$t('Maximum')}}</label>
              <md-input
                data-vv-name="Maximum"
                type="number"
                v-model="modifierGroupDetails.max"
                v-validate="modelValidations.ShortName"
                :disabled="formType == 'View Modifier Group'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Maximum')">{{errors.first('Maximum')}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('Maximum') && touched.Maximum">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('No. Priced Mods Inc') }}</label>
              <md-input
                data-vv-name="NoMods"
                type="number"
                v-model="modifierGroupDetails.modifier_group.no_priced_mods"
                v-validate="modelValidations.ShortName"
                :disabled="formType == 'View Modifier Group'"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label for="Country">{{ $t('Status') }}</label>
              <md-select
                v-model="modifierGroupDetails.modifier_group.status"
                :disabled="formType == 'View Modifier Group'"
              >
                <md-option class="first-opt" disabled>Status</md-option>
                <md-option value="inactive">{{ $t('Inactive') }}</md-option>
                <md-option value="active">{{ $t('Active') }}</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-checkbox v-model="modifierGroupDetails.modifier_group.forced">{{
              $t(' Forced?')
            }}</md-checkbox>
          </div>
        </div>

        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label for="Country">{{$t('Status')}}</label>
              <md-select
                v-model="modifierGroupDetails.status"
                :disabled="formType == 'View Modifier Group'"
              >
                <md-option value="Inactive">{{$t('Inactive')}}</md-option>
                <md-option value="Active">{{$t('Active')}}</md-option>
              </md-select>
            </md-field>
          </div>
        </div> -->

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType != 'View Modifier Group'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              @click="reset()"
              :to="{ path: '/products/Modifiers' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              @click="alert('Add')"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>
    </md-card>

    <md-dialog :md-active.sync="modDialog" class="modifier-dialog">
      <md-dialog-title class="md-layout">
        <div class="md-layout-item md-size-50">Modifiers</div>
        <div class="close-icon">
          <i
            class="md-icon md-icon-font md-theme-default"
            @click="modDialog = false"
            >close</i
          >
        </div>
      </md-dialog-title>

      <md-field class="md-search">
        <label>Search</label>
        <md-input
          type="search"
          class="mb-3"
          clearable
          style=""
          v-model="searchQuery"
        ></md-input>
      </md-field>

      <div class="modifier-checkbox">
        <md-list>
          <md-list-item
            v-for="(item, index) in modifiersListTemp"
            :key="`key-${index}`"
          >
            {{ item.name }}
            <md-checkbox
              :model="modifierIdArr"
              :value="item.id"
              @change="updateSampleModifier"
            ></md-checkbox>
          </md-list-item>
        </md-list>
      </div>
    </md-dialog>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import CustomizeService, {
  customizeService,
} from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
import Fuse from 'fuse.js'

export default {
  components: {
    SlideYDownTransition,
    draggable,
    Multiselect,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      required: '',
      Description: '',
      switch2: null,
      modifierGroupId: '',
      ShortName: '',
      searchQuery: '',
      touched: {
        required: false,
        ShortName: false,
        Description: false,
      },
      modelValidations: {
        required: {
          required: true,
        },
        ShortName: {
          required: true,
        },
        Description: {
          required: true,
        },
        sortOrder: {
          required: true,
          numeric: true,
        },

        Minimum: {
          numeric: true,
        },

        Maximum: {
          numeric: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Modifier Group',
      },
      clientDetail: {
        default: '',
      },

      drag: true,

      modifiersList: [],
      modifiersListTemp: [],

      modifierGroupDetails: {
        modifier_group: {
          sort_order: '',
          min: '',
          max: '',
          no_priced_mods: '',
          chain_id: '',
          status: 'active',
          modifiers: [],
        },
      },
      modifierIdArr: [],
      sampleModifiers: [],
      modifier_sort_type: '2',
      modDialog: false,
      sortString: null,
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit('on-submit', this.registerForm, isValid)
      })
    },

    orderList() {
      this.sampleModifiers = this.sampleModifiers.sort((one, two) => {
        if (one.name < two.name) {
          return -1
        }
        if (one.name > two.name) {
          return 1
        }
        return 0
      })
      this.drag = false
      this.modifier_sort_type = 0
    },

    descOrderList() {
      this.sampleModifiers = this.sampleModifiers.sort((one, two) => {
        if (one.name > two.name) {
          return -1
        }
        if (one.name < two.name) {
          return 1
        }
        return 0
      })
      this.drag = false
      this.modifier_sort_type = 1
    },

    customOrder() {
      this.drag = true
      this.modifier_sort_type = 2
    },

    enableDrag() {
      return this.drag
    },

    addTag(newTag) {
      const tag = {
        name: newTag,
        id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      }
      this.modifiersList.push(tag)
      this.modifierGroupDetails.modifier_group.modifiers.push(tag)
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitModifierGroup()
          }
        })
      }
    },

    editModifierGp() {
      this.formType = 'Edit Modifier Group'
    },

    submitModifierGroup() {
      var self = this
      this.modifierGroupDetails.modifier_group.modifiers = this.sampleModifiers
      this.modifierGroupDetails.modifier_group.modifier_sort_type =
        this.modifier_sort_type

      this.modifierGroupDetails.modifier_group.modifiers.forEach((el) => {
        el.modifier_id = el.id
        delete el.id
      })

      for (
        var i = 0;
        i < this.modifierGroupDetails.modifier_group.modifiers.length;
        i++
      ) {
        this.modifierGroupDetails.modifier_group.modifiers[i].sort_order = i + 1
      }
      if (this.formType == 'Add Modifier Group') {
        this.modifierGroupDetails.modifier_group.chain_id =
          accountService.getChainId()
        customizeService
          .createModifierGroup(this.modifierGroupDetails)
          .then((res) => {
            self.$router.push({ name: 'Modifiers' })
          })
      } else {
        customizeService
          .editModifierGroup(this.modifierGroupId, this.modifierGroupDetails)
          .then((res) => {
            self.$router.push({ name: 'Modifiers' })
          })
          .catch((err) => console.log(err))
      }
    },
    reset() {
      this.$router.push({ name: 'Modifiers' })
    },
    getModifierGroupDetails() {
      let self = this
      this.loader = true
      customizeService
        .getModifier()
        .then((res) => {
          this.loader = false
          self.modifiersList = res.data.filter((el) => el.status == 'active')
          self.modifiersList.forEach((el) => {
            delete el.price
            delete el.created_at
            delete el.status
            delete el.updated_at
            delete el.url
          })
          self.modifiersListTemp = JSON.parse(
            JSON.stringify(self.modifiersList)
          )
        })
        .catch((err) => console.log('error'))

      if (this.formType != 'Add Modifier Group') {
        customizeService.getMgById(this.modifierGroupId).then((res) => {
          this.modifierGroupDetails.modifier_group = res.data.modifier_group
          this.sampleModifiers =
            this.modifierGroupDetails.modifier_group.modifiers
          this.modifier_sort_type = this.modifierGroupDetails.modifier_sort_type
          res.data.modifier_group.modifiers.forEach((el) => {
            this.modifierIdArr.push(el.id)
          })
        })
      }
    },

    updateSampleModifier() {
      let self = this
      this.sampleModifiers = []
      for (var i = 0; i < this.modifierIdArr.length; i++) {
        let body = this.modifiersList.find(function (el) {
          return el.id == self.modifierIdArr[i]
        })
        this.sampleModifiers.push(body)
      }
    },

    updateSortOrder() {
      if (this.modifier_sort_type == 0) {
        this.orderList()
      } else if (this.modifier_sort_type == 1) this.descOrderList()
      else {
        this.customOrder()
      }
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.modifierGroupId = this.$route.query.client
    this.modifierGroupDetails.modifier_group.sort_order = this.$route.query.seq
      ? this.$route.query.seq + 1
      : 1
    this.getModifierGroupDetails()
  },
  watch: {
    required() {
      this.touched.required = true
    },
    ShortName() {
      this.touched.required = true
    },
    Description() {
      this.touched.required = true
    },
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.modifiersList
      if (value !== '') {
        result = new Fuse(this.modifiersList, {
          keys: ['name'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.modifiersListTemp = result.map((el) => el.item)
      } else {
        this.modifiersListTemp = JSON.parse(JSON.stringify(this.modifiersList))
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
.multiclass {
  width: 100%;
}
.multiclass .multiselect--active {
  height: 200px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.multiclass .multiselect--active input {
  height: 40px;
}
.multiselect.multy1 input {
  height: 40px;
}

.list-group {
  padding: 10px 10px;
  width: 100%;
}

.list-group-item {
  margin: 5px;
  padding: 8px;
  background-color: #2b82be;
  color: white;
  border-radius: 5px;
}

.plus-icon {
  height: 15px;
  width: 15px;
  margin: 0px 4px 4px 0px;
}

.modifier-add {
  display: flex;
}

.modifier-checkbox {
  padding: 0px;
  .md-list-item-content > .md-checkbox:first-child,
  .md-list-item-content > .md-radio:first-child {
    margin-right: 0px;
  }

  .md-list-item .md-list-item-container .md-ripple {
    display: flex;
  }
  overflow-y: scroll;
  height: 300px;
}

.btn-div {
  text-align: center;
}

.mod-click {
  cursor: pointer;
}

.modifier-dialog {
  padding: 15px;
  width: 40%;
  padding-right: none;
}

.close-icon {
  width: 50%;
  text-align: right;
  i {
    cursor: pointer;
  }
}
.md-search {
  margin-left: 10px;
}
</style>
