<template>
  <form class="md-layout-item pd-l">
    <md-card class="pd-l">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left ml-auto">
            <router-link :to="{ path: '/products/categories/super-category' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType === 'View Category'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editSupercat"
            >edit</i
          >
        </div>
      </md-card-header>
      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader" class="block-bg pad-xy rounded-xl">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                v-model="superCategory.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Category'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('ShortName') },
                { 'md-valid': !errors.has('ShortName') && touched.ShortName },
              ]"
            >
              <label>{{ $t('Short Name') }}</label>
              <md-input
                v-model="superCategory.short_name"
                data-vv-name="ShortName"
                type="text"
                v-validate="modelValidations.ShortName"
                :disabled="formType == 'View Category'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('ShortName')">
                  {{ errors.first('ShortName') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('ShortName') && touched.ShortName"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <!-- <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('ShortName') },
                { 'md-valid': !errors.has('ShortName') && touched.ShortName }
              ]"
            >
              <label>{{$t('Short Name')}}</label>
              <md-input
                v-model="superCategory.short_name"
                data-vv-name="ShortName"
                type="text"
                v-validate="modelValidations.ShortName"
                :disabled="formType == 'View Category'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('ShortName')">{{errors.first("ShortName")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('ShortName') && touched.ShortName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Description') },
                {
                  'md-valid': !errors.has('Description') && touched.Description,
                },
              ]"
            >
              <label>{{ $t('Description') }}</label>
              <md-input
                v-model="superCategory.description"
                data-vv-name="Description"
                type="text"
                v-validate="modelValidations.Description"
                :disabled="formType == 'View Category'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Description')">
                  {{ errors.first('Description') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Description') && touched.Description"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>

          <!-- <Date-Time-Picker @SendDateTime="ReciveDateTime"></Date-Time-Picker> -->
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>
        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Category'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/products/categories/super-category' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { DateTimePicker } from '@/components'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    SlideYDownTransition,
    DateTimePicker,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      Name: '',
      Description: '',
      switch2: null,
      ShortName: '',
      touched: {
        Name: false,
        ShortName: false,
        Description: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
        ShortName: {
          required: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Client',
      },
      clientDetail: {
        default: '',
      },
      superCategory: {},
      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createSupercategory()
          }
        })
      }
    },

    createSupercategory() {
      let self = this
      if (this.formType == 'Add Super Category') {
        customizeService.createSupercat(this.superCategory).then((res) => {
          self.$router.push({ name: 'Super Category' })
        })
      } else {
        customizeService
          .editSupercat(this.superId, this.superCategory)
          .then((res) => {
            self.$router.push({ name: 'Super Category' })
          })
      }
    },

    reset() {
      this.superCategory = {}
    },

    editSupercat() {
      this.formType = 'Edit Super Category'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.superId = this.$route.query.client
    let self = this
    if (this.formType != 'Add Super Category') {
      this.loader = true
      customizeService.getSupercatById(this.superId).then((res) => {
        this.loader = false
        this.superCategory = res.data
      })
    }
  },
  watch: {
    Name() {
      this.touched.required = true
    },
    ShortName() {
      this.touched.required = true
    },
    Description() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
