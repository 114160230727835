var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"supercat-rep menu-item-rep"},[_c('md-card',{staticClass:"report-card block-bg"},[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-green md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-70 report-name"},[_c('span',{staticClass:"arrow_left"},[_c('router-link',{attrs:{"to":{ path: '/dashboard' }}},[_c('img',{attrs:{"src":require("../../../../assets/images/left.svg")}})])],1),_c('h3',[_vm._v(_vm._s(_vm.$t('Super Category Sales Report')))])])]),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout md-layout-item filter-section block-bg"},[_c('div',{staticClass:"md-layout-item md-size-55"},[_c('date-period-dropdown',{on:{"interval":_vm.setDateType}})],1),_c('div',{staticClass:"md-layout-item md-size-45 pd-l-0"},[_c('div',{staticClass:"md-layout search-section"},[_c('div',{staticClass:"md-layout-item md-size-70 pd-l-0"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-50 pd-l-mbl-o pd-l0-r15 category-select",class:[
                      { 'md-size-35': _vm.role() == 'branch_user' },
                      { 'md-size-50': _vm.role() != 'branch_user' } ]},[_c('label',[_vm._v("Super Category")]),_c('md-field',[_c('md-select',{model:{value:(_vm.data.superCatId),callback:function ($$v) {_vm.$set(_vm.data, "superCatId", $$v)},expression:"data.superCatId"}},_vm._l((_vm.superCatList),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-50 pd-l-mbl-o category-select",class:[
                      { 'md-size-35': _vm.role() == 'branch_user' },
                      { 'md-size-50': _vm.role() != 'branch_user' } ]},[_c('label',[_vm._v("Terminals")]),_c('md-field',[_c('md-select',{model:{value:(_vm.data.terminalId),callback:function ($$v) {_vm.$set(_vm.data, "terminalId", $$v)},expression:"data.terminalId"}},_vm._l((_vm.terminalsList),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),(_vm.role() == 'branch_user')?_c('div',{staticClass:"md-layout-item md-size-30 md-small-size-50 pd-l-mbl-o pd-l0-r15 category-select"},[_c('label',[_vm._v("Outlet")]),_c('md-field',[_c('md-select',{attrs:{"multiple":""},on:{"md-closed":function($event){_vm.outletId.length > 0
                            ? _vm.superCatReport()
                            : (_vm.outletId = [])}},model:{value:(_vm.outletId),callback:function ($$v) {_vm.outletId=$$v},expression:"outletId"}},_vm._l((_vm.outletList),function(item){return _c('md-option',{key:item.id,staticClass:"oulet-select-list-item",attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_vm._e()])]),_c('div',{staticClass:"md-layout-item md-size-30"},[_c('div',{staticClass:"float-right"},[_c('md-button',{staticClass:"md-teritiary",on:{"click":function($event){return _vm.superCatReport()}}},[_vm._v("Search ")])],1)])])])]),_c('div',{staticClass:"md-layout-item md-size-100 card-inside report-table"},[_c('div',{staticClass:"md-layout-item md-small-size-90 md-size-90"},[(!_vm.loader)?_c('h4',{staticClass:"center"},[_vm._v(" Super Category Report- "+_vm._s(_vm.sdate)+" to "+_vm._s(_vm.edate)+" ")]):_vm._e(),_c('pie-chart',{directives:[{name:"show",rawName:"v-show",value:(_vm.reportApiRes.length > 1),expression:"reportApiRes.length > 1"}],attrs:{"chart-data":_vm.datacollection,"height":90,"options":{
                legend: {
                  position: 'bottom',
                },
                plugins: {
                  datalabels: {
                    display: true,
                    font: {
                      weight: 'bold',
                      size: 12,
                    },
                    color: 'white',
                    textStrokeColor: 'black',
                    textStrokeWidth: 3,
                  },
                },
              }}})],1),(_vm.loader)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{attrs:{"loading":_vm.loader,"color":'#00E0DB'}})],1):_vm._e(),(!_vm.loader)?_c('md-table',{staticClass:"rep-table table-typ1"},[_c('md-table-toolbar',[_c('div',{staticClass:"action-buttons export-btn"},[_c('div',{staticClass:"pdf-el"},[_c('div',{staticClass:"download-btn"},[_c('img',{attrs:{"src":require("../../../../assets/images/pdf.svg")},on:{"click":function($event){return _vm.downloadWithoutCSS()}}})])]),_c('div',{staticClass:"excel-el"},[_c('vue-excel-xlsx',{staticClass:"Excel-btn",attrs:{"data":_vm.reportApiRes,"columns":_vm.columns,"filename":'Super Category Sales Report',"sheetname":'Super Category Sales Report',"outlet-names":_vm.selectedOutletsNames(),"sdate":_vm.sdate,"edate":_vm.edate}})],1)])]),_c('md-table-row',[_c('md-table-head',[_vm._v("Super Category")]),_c('md-table-head',[_vm._v("Total Quantity")]),_c('md-table-head',[_vm._v("Gross Sales")]),_c('md-table-head',[_vm._v("Discount")]),_c('md-table-head',[_vm._v("Gross Sales After Discount")]),_c('md-table-head',[_vm._v("Tax")]),_c('md-table-head',[_vm._v("Net Sales")])],1),_vm._l((_vm.reportApiRes),function(item){return _vm._t("default",[_c('md-table-row',{attrs:{"slot":"md-table-row"},slot:"md-table-row"},[_c('md-table-cell',[_vm._v(_vm._s(item.super_category))]),_c('md-table-cell',[_vm._v(_vm._s(item.total_quantity))]),_c('md-table-cell',[_vm._v(_vm._s(item.gross_sales))]),_c('md-table-cell',[_vm._v(_vm._s(item.discount))]),_c('md-table-cell',[_vm._v(_vm._s(item.gross_sales_after_discount))]),_c('md-table-cell',[_vm._v(_vm._s(item.tax))]),_c('md-table-cell',[_vm._v(_vm._s(item.net_sales))])],1)])})],2):_vm._e()],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }