<template>
  <md-card>
    <div class="header">
      <div>
        <h4>Order Settings</h4>
      </div>
      <div class="edit">
        <i class="md-icon md-icon-font md-theme-default" @click="editOutlet"
          >edit</i
        >
      </div>
    </div>
    <div class="content">
      <div class="tips">
        <div>Accept tips online</div>
        <div>
          <md-switch v-model="boolean"></md-switch>
        </div>
      </div>
      <div class="line_segment" />
      <div class="tips">
        <div>Require orders to be accepted</div>
        <div>
          <md-switch v-model="boolean"></md-switch>
        </div>
      </div>
      <div class="line_segment" />
      <div class="customer-messages">
        <div>Allow customer order kitchen messages</div>
        <div>
          <md-switch v-model="boolean"></md-switch>
        </div>
      </div>
    </div>

    <div class="bottom-div">
      <md-button class="md-dense md-primary refund-button" @click="updateOutlet"
        >Update</md-button
      >
    </div>
  </md-card>
</template>
<script>
export default {
  name: 'RegularSwitch',
  data: () => ({
    boolean: false,
  }),

  methods: {
    editOutlet() {
      console.log('W')
    },

    updateOutlet() {
      console.log('w')
    },
  },
}
</script>
<style>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  margin: 0;
}
.tips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}
.line_segment {
  height: 1px;
  border: 0;
  background-color: #bdc3c7;
  margin-bottom: 5px;
  margin-top: 5px;
}
.bottom-div {
  display: flex;
  justify-content: center;
  margin-top: 125px;
}

.customer-messages {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 600;
}

.content {
  padding: 0 40px 0 40px;
}
</style>
