<template>
  <div class="md-layout md-size-100 tabs-holder">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <tabs
        :tab-name="['Dine In', 'Collection', 'Geneal Settings']"
        :tab-icon="['dashboard', 'schedule', 'settings']"
        flex-column
        nav-pills-icons
        color-button="success"
        class="settings-btn"
      >
        <!-- <h4 class="title" slot="header-title">
          Navigation Pills Icons -
          <small class="description">Vertical Tabs</small>
        </h4> -->

        <!-- here you can add your content for tab-content -->

        <!------------------------------------------------- Dine In -------------------------------------->
        <template slot="tab-pane-1">
          <div class="md-layout pb-15">
            <div class="md-layout-item">Enable Dine In</div>
            <div class="md-layout-item">
              <md-switch v-model="dineInSettings.allow_dinein"></md-switch>
            </div>
          </div>
          <div class="md-layout pb-15">
            <div class="md-layout-item">Require Customer Name</div>
            <div class="md-layout-item">
              <md-switch
                v-model="dineInSettings.require_customer_name"
              ></md-switch>
            </div>
          </div>
          <div class="md-layout pb-15">
            <div class="md-layout-item">Require Guest Count</div>
            <div class="md-layout-item">
              <md-switch
                v-model="dineInSettings.require_guest_count"
              ></md-switch>
            </div>
          </div>
          <div class="md-layout pb-15">
            <div class="md-layout-item">Minumum Order Value</div>
            <div class="md-layout-item">
              <md-field>
                <label>{{ $t('Min Order Value') }}</label>
                <md-input
                  type="text"
                  v-model="dineInSettings.min_order_value"
                ></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout pb-15">
            <div class="md-layout-item">Enable No Payment</div>
            <div class="md-layout-item">
              <md-switch v-model="dineInSettings.no_payment"></md-switch>
            </div>
          </div>
          <div class="md-layout pb-15">
            <div class="md-layout-item">Send OTP</div>
            <div class="md-layout-item">
              <md-switch v-model="dineInSettings.send_otp"></md-switch>
            </div>
          </div>

          <div class="md-layout pb-15">
            <div class="md-layout-item">Auto Accept</div>
            <div class="md-layout-item">
              <md-switch v-model="dineInSettings.auto_accept"></md-switch>
            </div>
          </div>

          <div class="md-layout pb-15">
            <div class="md-layout-item">Order Instructions</div>
            <div class="md-layout-item">
              <md-switch
                v-model="dineInSettings.require_order_instructions"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout pb-15">
            <div class="md-layout-item">Selected Terminals</div>
            <div class="md-layout-item">
              <md-field>
                <md-select v-model="dineInSelectedTerminalIds" multiple>
                  <md-option
                    :value="item.id"
                    v-for="item in terminals"
                    :key="item.id"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="btn-div btn-wrapper-holiday text-center">
            <md-button
              class="md-button md-primary md-theme-default"
              @click="saveChanges"
            >
              SAVE CHANGES
            </md-button>
          </div>
        </template>
        <!------------------------------------------------->
        <!------------------------------------------------- Collection -------------------------------------->
        <template slot="tab-pane-2">
          <div class="md-layout pb-15">
            <div class="md-layout-item">Enable Collection</div>
            <div class="md-layout-item">
              <md-switch
                v-model="collectionSettings.allow_collection"
              ></md-switch>
            </div>
          </div>
          <div class="md-layout pb-15">
            <div class="md-layout-item">Require Customer Name</div>
            <div class="md-layout-item">
              <md-switch
                v-model="collectionSettings.require_customer_name"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout pb-15">
            <div class="md-layout-item">Minumum Order Value</div>
            <div class="md-layout-item">
              <md-field>
                <label>{{ $t('Min Order Value') }}</label>
                <md-input
                  type="text"
                  v-model="collectionSettings.min_order_value"
                ></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout pb-15">
            <div class="md-layout-item">Enable No Payment</div>
            <div class="md-layout-item">
              <md-switch v-model="collectionSettings.no_payment"></md-switch>
            </div>
          </div>
          <div class="md-layout pb-15">
            <div class="md-layout-item">Send OTP</div>
            <div class="md-layout-item">
              <md-switch v-model="collectionSettings.send_otp"></md-switch>
            </div>
          </div>

          <div class="md-layout pb-15">
            <div class="md-layout-item">Inteval Time(In Minutes)</div>

            <div class="md-layout-item">
              <md-field>
                <md-input
                  type="text"
                  v-model="collectionSettings.time_interval"
                ></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout pb-15">
            <div class="md-layout-item">Preparation Time(In Minutes)</div>
            <div class="md-layout-item">
              <md-field>
                <md-input
                  type="text"
                  v-model="collectionSettings.preparation_time"
                ></md-input>
              </md-field>
            </div>
          </div>
          <!-- <div class="md-layout pb-15">
            <div class="md-layout-item">Cut Of Time(In Minutes)</div>
            <div class="md-layout-item">
              <md-field>
                <md-input
                  type="text"
                  v-model="collectionSettings.cut_of_time"
                ></md-input>
              </md-field>
            </div>
          </div> -->
          <div class="md-layout pb-15">
            <div class="md-layout-item">Auto Accept</div>
            <div class="md-layout-item">
              <md-switch v-model="collectionSettings.auto_accept"></md-switch>
            </div>
          </div>

          <div class="md-layout pb-15">
            <div class="md-layout-item">Accept Orders Up to Closing</div>
            <div class="md-layout-item">
              <md-switch
                v-model="collectionSettings.accept_orders_upto_closing"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout pb-15">
            <div class="md-layout-item">Order Instructions</div>
            <div class="md-layout-item">
              <md-switch
                v-model="collectionSettings.require_order_instructions"
              ></md-switch>
            </div>
          </div>

          <div class="md-layout pb-15">
            <div class="md-layout-item">Selected Terminals</div>
            <div class="md-layout-item">
              <md-field>
                <md-select v-model="collectionSelectedTerminalIds" multiple>
                  <md-option
                    :value="item.id"
                    v-for="item in terminals"
                    :key="item.id"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="btn-div btn-wrapper-holiday text-center">
            <md-button
              class="md-button md-primary md-theme-default"
              @click="saveChanges"
            >
              SAVE CHANGES
            </md-button>
          </div>
        </template>
        <!------------------------------------------------->

        <!------------------------------------------------- Genereal -------------------------------------->
        <template slot="tab-pane-3">
          <div class="md-layout pb-15">
            <div class="md-layout-item md-size-30">Online Payment Type</div>
            <div class="md-layout-item md-size-50">
              <md-field id="input-width">
                <md-select v-model="genralSettings.online_payment_type">
                  <md-option
                    :value="item.id"
                    v-for="item in online_payments"
                    :key="item.id"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout pb-15">
            <div class="md-layout-item md-size-30">SMS SENDER NAME</div>
            <div class="md-layout-item md-size-50">
              <md-field
                id="input-width"
                :class="[
                  { 'md-error': errors.has('smsSenderName') },
                  {
                    'md-valid':
                      !errors.has('smsSenderName') && touched.smsSenderName,
                  },
                ]"
              >
                <md-input
                  type="text"
                  v-model="genralSettings.sms_sender_name"
                  v-validate="modelValidations.smsSenderName"
                  data-vv-name="smsSenderName"
                  data-vv-scope="smsSenderName"
                  maxlength="11"
                ></md-input>
                <slide-y-down-transition>
                  <div
                    class="error"
                    v-show="errors.has('smsSenderName.smsSenderName')"
                  >
                    {{ errors.first('smsSenderName.smsSenderName') }}
                  </div>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="btn-div btn-wrapper-holiday text-center">
            <md-button
              class="md-button md-primary md-theme-default"
              @click="validate"
            >
              SAVE CHANGES
            </md-button>
          </div>
        </template>
        <!------------------------------------------------->
      </tabs>
    </div>
  </div>
</template>

<script>
import { StatsCard } from '@/components'
import DateFilterDrop from '../../../components/DateFilterDrop.vue'
import datetime from 'vuejs-datetimepicker'
import { Tabs } from '@/components'
import { Collapse } from '@/components'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import { SlideYDownTransition } from 'vue2-transitions'
import Swal from 'sweetalert2'

export default {
  components: {
    StatsCard,
    DateFilterDrop,
    datetime,
    Tabs,
    Collapse,
    SlideYDownTransition,
  },
  data() {
    return {
      adHocDialog: false,
      saleData: {},
      queries: {
        outletId: [],
        search_type: 'Daily',
      },
      storeStatus: ['Open', 'Close', 'invisible'],
      selectedStoreStatus: 'Open',
      online_store_settings: {
        store: { dinein: {}, collection: {} },
        general: { online_payment_type: '' },
      },
      dineInSettings: {},
      collectionSettings: {},
      deliverySettings: {},
      genralSettings: {},
      terminals: [],
      collectionSelectedTerminalIds: [],
      dineInSelectedTerminalIds: [],
      online_payments: [],
      modelValidations: {
        smsSenderName: {
          required: true,
          max: 11,
        },
      },
      touched: {
        smsSenderName: false,
      },
    }
  },
  created() {
    this.getOnlineSettings()
    customizeService
      .getOtherPaymentTypes(accountService.getOutletId())
      .then((res) => {
        this.online_payments = res.data.other_payment_types
        this.otherFeeTypes = res.data.other_payment_types.filter(
          (item) => item.status == true && item.is_fee == true
        )
      })
  },
  methods: {
    searchInterval(data) {
      if (data != 'Ad Hoc') {
        this.queries.search_type = data
        this.queries.startDate = undefined
        this.queries.endDate = undefined
        this.configOnlineDashboard()
      } else {
        this.queries.search_type = 'Ad Hoc'
        this.adHocDialog = true
      }
    },
    formatPrice(value) {
      if (value >= 0) {
        return value.toFixed(2)
      }
    },
    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminals = res.data
        // this.storeId = res.data.Data[0].StoreId
      })
    },
    getOnlineSettings() {
      let outlet_id = accountService.getOutletId()

      customizeService.getOutletOnlineSettings(outlet_id).then((res) => {
        if (res.data.holidays) {
          this.holidayList = res.data.holidays
        }
        this.online_store_settings = res.data.online_store_settings || {
          theme: {},
          general: {},
          store: { dinein: {}, collection: {}, delivery: {} },
        }
        if (this.online_store_settings) {
          this.dineInSettings = this.online_store_settings.store.dinein
          this.collectionSettings = this.online_store_settings.store.collection
          this.genralSettings = this.online_store_settings.general || {}
          this.dineInSelectedTerminalIds =
            this.dineInSettings.allowed_terminal_ids || []
          this.collectionSelectedTerminalIds =
            this.collectionSettings.allowed_terminal_ids || []
        }

        this.getTerminals()

        this.loading = false
      })
    },
    validate() {
      this.$validator.validateAll('smsSenderName').then((isValid) => {
        if (isValid) {
          this.saveChanges()
        }
      })
    },

    saveChanges() {
      let outlet_id = accountService.getOutletId()
      this.dineInSettings.allowed_terminal_ids = this.dineInSelectedTerminalIds
      this.collectionSettings.allowed_terminal_ids =
        this.collectionSelectedTerminalIds

      if (this.online_store_settings.store) {
        this.online_store_settings.store.dinein = this.dineInSettings
        this.online_store_settings.store.collection = this.collectionSettings
        this.online_store_settings.general = this.genralSettings
      } else {
        this.online_store_settings.store = {
          dinein: {},
          collection: {},
          delivery: {},
        }
        this.online_store_settings.store.dinein = this.dineInSettings
        this.online_store_settings.store.collection = this.collectionSettings
        this.online_store_settings.general = this.genralSettings
      }

      customizeService
        .editOutlet(outlet_id, {
          outlets: {
            outlet: {
              online_store_settings: this.online_store_settings,
            },
          },
        })
        .then((response) => {
          Swal.fire('Successfully Updated ')
        })
        .catch((error) => {
          Swal.fire('Error, try again later')
        })

      // debugger
    },

    configOnlineDashboard() {
      this.getOnlineSettings()
    },
  },
}
</script>

<style scoped>
.md-switch {
  width: auto;
  margin: 0;
  display: inline-flex;
  position: relative;
}
.pb-15 {
  padding-bottom: 15px;
}

.tabs-holder {
  background: linear-gradient(
    109.46deg,
    rgba(201, 201, 201, 0.8) 1.57%,
    rgba(196, 196, 196, 0.1) 100%
  );

  margin-top: 25px;
  border-radius: 10px;
}
.md-list-item-button {
  font-weight: 600 !important;
}
#input-width {
  min-width: 500px;
  margin: 0 !important;
}
</style>
