import { Validator } from 'vee-validate'
console.log('Custom validation file loaded')

Validator.extend('at_least_one_selected', {
  getMessage: () => 'At least one option must be selected.',
  validate: (value, { screen }) => {
    return !!(screen.is_handheld || screen.is_till) // Check if at least one is true
  },
})
console.log('Registered rules:', Validator.rules)
