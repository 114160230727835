<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="md-layout block-bg">
        <md-card-header
          class="md-layout-item md-card-header-icon md-card-header-green"
        >
          <router-link :to="{ name: 'Dashboard' }"> </router-link>
          <h3 class="title">{{ $t('Menu Item Import') }}</h3>
        </md-card-header>
        <md-card-content class="md-layout-item">
          <a href="../../assets/files/Menu Item Import Temaplate.xlsx" download
            >Download Template
          </a>

          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <div class="md-layout">
            <div class="md-layout-item">
              <input type="file" @change="onFileChanged" />
            </div>
          </div>

          <div class="btn-div">
            <md-card-actions class="text-center">
              <md-button native-type="submit" :to="{ path: '/Dashboard' }">{{
                $t('CANCEL')
              }}</md-button>
              <md-button
                native-type="submit"
                @click.native.prevent="validate"
                class="md-primary"
                >{{ $t('UPDATE') }}</md-button
              >
            </md-card-actions>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import axios from 'axios'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    ClipLoader,
  },

  data() {
    return {
      loader: false,
      file: null,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit('on-submit', this.alertModule('Add'), isValid)
        }
      })
    },

    alertModule(value) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.uploadFile()
        }
      })
    },

    onFileChanged(event) {
      this.file = event.target.files[0]
    },

    uploadFile() {
      let formData = new FormData()
      formData.append('file', this.file)
      customizeService
        .menuItemImport(formData)
        .then((res) => {
          this.$router.push({
            name: 'MenuItem',
          })
        })
        .catch((err) => alert('Upload error'))
    },
  },

  created() {},
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}
</style>
