<template>
  <div>
    <md-card class="petty-cash block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Petty Cash Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout filter-section block-bg">
            <div class="md-layout search-options">
              <div class="md-layout-item">
                <date-period-dropdown
                  @interval="setDateType"
                ></date-period-dropdown>
              </div>
              <div class="md-layout-item md-size-30">
                <md-field v-if="userRole == 'branch_user'">
                  <label>Outlets</label>
                  <md-select
                    v-model="queries.outletId"
                    multiple
                    @md-closed="
                      queries.outletId.length > 0
                        ? pettyCash()
                        : (queries.outletId = [])
                    "
                  >
                    <md-option
                      v-for="item in outlets"
                      class="oulet-select-list-item"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-size-10">
                <div class="float-right">
                  <md-button class="md-teritiary" @click="pettyCash"
                    >SEARCH</md-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div>
          <div class="report-table" v-if="!loader">
            <md-table class="table-typ1">
              <md-table-toolbar>
                <div class="action-buttons export-btn">
                  <div class="pdf-el">
                    <div class="download-btn">
                      <img
                        src="../../../assets/images/pdf.svg"
                        @click="downloadWithoutCSS()"
                      />
                    </div>
                  </div>

                  <div class="excel-el">
                    <vue-excel-xlsx
                      class="Excel-btn"
                      :data="report"
                      :columns="columns"
                      :filename="'petty-cash'"
                      :sheetname="'Petty Cash'"
                    >
                    </vue-excel-xlsx>
                  </div>
                </div>
              </md-table-toolbar>
              <md-table-row>
                <md-table-head>Staff</md-table-head>
                <md-table-head>Date</md-table-head>
                <md-table-head>Note</md-table-head>
                <md-table-head>Amount</md-table-head>
              </md-table-row>

              <md-table-row v-for="(item, index) in report" :key="index">
                <md-table-cell>{{ item.staff }}</md-table-cell>
                <md-table-cell>{{ item.date }}</md-table-cell>
                <md-table-cell>{{ item.note }}</md-table-cell>
                <md-table-cell>{{ item.amount }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { accountService } from '../../../services/account.js'
import { customizeService } from '../../../services/customize.js'
import { reportService } from '../../../services/reports.js'
import { pdfService } from '../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import datetime from 'vuejs-datetimepicker'
import DatePeriodDropdown from '../../../components/DatePeriodDropdown'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../components/VueExcelXlsx'

export default {
  components: {
    datetime,
    DatePeriodDropdown,
    ClipLoader,
    VueExcelXlsx,
  },
  data() {
    return {
      search: null,
      searched: [],
      report: [],
      columns: [
        {
          label: 'Staff',
          field: 'staff',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Note',
          field: 'note',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
      ],
      outlets: [],
      userRole: '',
      queries: {
        outletId: [],
        search_type: 'Daily',
      },
      loader: false,
    }
  },

  methods: {
    setDate(date) {
      this.queries.startDate = date
    },

    setDateType(interval) {
      this.queries.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.queries.startDate = interval.startDate
        this.queries.endDate = interval.endDate
      } else {
        this.queries.startDate = interval.date
        this.queries.endDate = undefined
        if (!this.loader) {
          this.pettyCash()
        }
      }
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = [el.staff, el.date, el.note, el.amount]
        body.push(arrayObj)
      })
      return body
    },

    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })
      let head = [['Staff', 'Date', 'Note', 'Amount']]
      pdfService.headerFooterConfig(this.queries, doc, 'Petty Cash')
      pdfService.autoTableConfig(doc, head, body, {}, 'petty-cash.pdf')
    },

    pettyCash() {
      this.loader = true
      reportService.getPettyCash(this.queries).then((res) => {
        this.loader = false
        this.report = res.data.result
      })
    },
  },

  created() {
    this.userRole = accountService.userRole()
    this.loader = true
    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.pettyCash()
      })
    } else {
      this.pettyCash()
    }
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}
.main-heading {
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 18px;
}

.toolbar-monthly {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.arrow_left {
  float: left;
  padding-top: 21px;
}

.datetime-picker {
  // float: right;
  width: 300px;
}

#datetime-input {
  height: 50px;
}

.date-layout {
  padding-top: 6px;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  width: 350px;
}
input {
  height: 41px;
}
</style>
