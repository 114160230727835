<template>
  <div class="md-layout module-table">
    <div class="md-layout-item">
      <md-card class="pagination-card block-bg pad-l-15px pad-r-15px">
        <md-card-header
          class="md-card-header-icon md-card-header-green d-flex justify-content-between mt-15"
        >
          <h3 class="title">
            <router-link
              :to="{
                name: 'AddVoidReasons',
                query: { formType: 'Add VoidReasons' },
              }"
            >
              <md-icon class="add-icon">add</md-icon>
            </router-link>
            Void Reasons
          </h3>
          <div class="active-inactive-tab" v-if="!loader">
            <div class="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox"
                id="myonoffswitch"
                tabindex="0"
                :value="activetab"
                @change="updateInput($event)"
                :checked="activetab ? true : false"
              />
              <label class="onoffswitch-label" for="myonoffswitch">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover quick-notes mt-25"
            @md-selected="viewDetails"
            v-if="!loader"
          >
            <md-table-toolbar class="table-toolbar-align">
              <md-field>
                <label>{{ $t('Search') }}</label>
                <md-input
                  type="search"
                  class=""
                  clearable
                  style=""
                  v-model="searchQuery"
                ></md-input>
                <i class="fa fa-search search-align" aria-hidden="true"></i>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                v-bind:md-label="$t('Sort order')"
                md-sort-by="sort_order"
              >
                <div @click="viewDetails(item)">
                  {{ item.sort_order }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Name')" md-sort-by="title">
                <div @click="viewDetails(item)">
                  {{ item.title }}
                </div>
              </md-table-cell>
              <!-- <md-table-cell md-label="Description" md-sort-by="City">
                <div @click="viewDetails(item)">
                  {{
                  item.items.length
                  }}
                </div>
            </md-table-cell>-->
              <md-table-cell class="center" v-bind:md-label="$t('Status')">
                <div @click="viewDetails(item)">
                  <i
                    v-bind:class="[
                      'check-align',
                      item.status === true ? 'fa fa-check ' : 'fa fa-ban',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
              </md-table-cell>
              <md-table-cell class="center" v-bind:md-label="$t('Actions')">
                <div class="border-edit">
                  <md-menu
                    md-size="medium"
                    :md-offset-x="-227"
                    :md-offset-y="-16"
                  >
                    <i
                      class="material-icons text_align-center menu-icon"
                      md-menu-trigger
                      >more_vert</i
                    >
                    <md-menu-content class="menuitem-dropdown">
                      <md-menu-item class="action-header menu-align">{{
                        $t('Actions')
                      }}</md-menu-item>
                      <router-link
                        :to="{
                          name: 'AddVoidReasons',
                          query: {
                            formType: 'Edit VoidReasons',
                            client: item.url,
                          },
                        }"
                      >
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fas fa-pencil-alt"></i>
                            {{ $t('Edit') }}
                          </span>
                        </md-menu-item>
                      </router-link>
                      <div>
                        <md-menu-item>
                          <span class="edit-align" @click="updateItem(item.id)">
                            <i class="fa fa-ban" aria-hidden="true"></i>
                            {{
                              item.status ? $t('Deactivate') : $t('Activate')
                            }}
                          </span>
                        </md-menu-item>
                      </div>
                      <div>
                        <md-menu-item>
                          <span
                            class="edit-align"
                            @click="alert('Delete', item.url)"
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            {{ $t('Delete') }}
                          </span>
                        </md-menu-item>
                      </div>
                    </md-menu-content>
                  </md-menu>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <!-- <div class="footer-table md-table">
             <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
        </div>-->
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- </md-card-actions> -->
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import axios from 'axios'
import Sortable from 'sortablejs'

export default {
  name: 'modifier-list',
  components: {
    Pagination,
    axios,
    ClipLoader,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData.filter(
          (item) => item.status == this.activetab
        )
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      currentSort: 'sort_order',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ['Name', 'Email', 'Age', 'Salary', 'Actions'],
      searchQuery: '',
      propsToSearch: ['name'],
      tableData: [],
      tempdata: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
      activetab: true,
    }
  },
  methods: {
    customSort(value) {
      return this.tableData.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, { numeric: true })
        }
        return a[sortBy]
          .toString()
          .localeCompare(b[sortBy].toString(), undefined, { numeric: true })
      })
    },
    handleLike(item) {
      Swal.fire({
        title: `You liked ${item.name}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'md-button md-success',
      })
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit ${item.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'md-button md-info',
      })
    },

    viewDetails(item) {
      this.$router.push({
        name: 'AddVoidReasons',
        query: { formType: 'View VoidReasons', client: item.url },
      })
    },
    alert(value, url) {
      var self = this
      if (value === 'Delete') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.delete(url)
          }
        })
      }
    },
    delete(url) {
      axios.delete(url + '.json', customizeService.authFun()).then((res) => {
        this.getItems()
      })
    },

    getItems() {
      var self = this
      this.loader = true
      customizeService.getVoidReasons().then((res) => {
        this.loader = false
        // self.tableData = res.data.quick_notes
        self.tempData = JSON.parse(JSON.stringify(res.data.quick_notes))
        self.tableData = self.tempData.filter((item) => item.status == true)
      })
    },

    updateItem(id) {
      var self = this
      reusableService.showSwal('Edit').then((result) => {
        self.updateItemStatus(id)
      })
    },

    updateItemStatus(id) {
      let item = this.tableData.find((element) => element.id == id)
      let status = !item.status
      let body = {
        status: status,
      }

      customizeService.updateVoidReasons(id, body).then((result) => {
        item.status = status
      })
    },
    filterBystatus(status) {
      this.activetab = status
      this.searchedData = []
      this.tableData = this.tempData.filter((item) => item.status == status)
      this.customSort(this.tableData)
    },
    updateInput(event) {
      let status = event.target.checked ? true : false
      this.filterBystatus(status)
    },
    updateItemOrder: function () {
      // get your info then...
      let sort_order_json = { quick_notes: {} }
      this.tableData.map(function (item, index) {
        item.sort_order = index + 1
        sort_order_json['quick_notes'][item.id] = {
          sort_order: index + 1,
        }
      })

      customizeService.update_categories(sort_order_json, 'quick_note')
    },
  },
  mounted() {
    // Fuse search initialization.
    // this.fuseSearch = new Fuse(this.tableData, {
    //   keys: ["name"],
    //   threshold: 0.6
    // });
  },
  updated: function () {
    let table = document.querySelector('.quick-notes tbody')
    const _self = this
    Sortable.create(table, {
      onEnd({ newIndex, oldIndex }) {
        const rowSelected = _self.tableData.splice(oldIndex, 1)[0] // Get the selected row and remove it
        _self.tableData.splice(newIndex, 0, rowSelected) // Move it to the new index
        _self.updateItemOrder()
      },
    })
  },

  created() {
    this.getItems()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['title'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}
.quick-notes .md-table-row {
  cursor: move;
}
</style>
