<template>
  <div class="datetime-comp">
    <div class="md-layout pd-l-0">
      <div class="md-layout-item md-size-50 pd-r-0 pd-l-0 pd-r-10">
        <label>From Date</label>
        <datetime
          class="datetime-custm"
          format="YYYY-MM-DD H:i"
          v-model="fromDateTime"
        ></datetime>
        <i
          class="md-icon md-icon-font md-icon-image md-theme-default custom-icon"
        >
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 10l5 5 5-5z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </i>
      </div>
      <div class="md-layout-item md-size-50 pd-l-7 pd-r-0 pd-l-mbl-o">
        <label>To Date</label>
        <datetime format="YYYY-MM-DD H:i" v-model="toDateTime"></datetime>
        <i
          class="md-icon md-icon-font md-icon-image md-theme-default custom-icon"
        >
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 10l5 5 5-5z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import datetime from 'vuejs-datetimepicker'

export default {
  name: 'Date-Time-Picker',
  components: { datetime },
  data() {
    return {
      fromDateTime: '',
      toDateTime: '',
      initialDate: 0,
    }
  },
  methods: {
    formatDateTime() {
      let today = new Date()
      let year = today.getFullYear()
      let month = today.getMonth() + 1
      let day = today.getDate()
      let joinedDate = [year, month, day].join('-')
      if (this.$store.getters.getProfile.outlet_start_time) {
        this.fromDateTime = [
          joinedDate,
          this.$store.getters.getProfile.outlet_start_time,
        ].join(' ')
        this.toDateTime = [
          joinedDate,
          this.$store.getters.getProfile.outlet_start_time,
        ].join(' ')
      }
      this.initialDate++
    },
  },
  watch: {
    fromDateTime() {
      this.$emit('SendFrom', this.fromDateTime)
    },
    toDateTime() {
      this.$emit('SendTo', this.toDateTime)
    },
  },

  created() {
    this.formatDateTime()
  },
}
</script>
<style scoped>
.md-layout-row {
  width: 100%;
  display: flex;
}
.md-size-row {
  width: 50%;
  margin-right: 15px;
}
.pd-l-0 {
  padding-left: 0px;
  position: relative;
}
.custom-icon {
  position: absolute;
  top: 26px;
  right: 15px;
}
.datetime-picker input {
  min-width: 100% !important;
}
</style>
