<template>
  <div class="md-layout md-layout-item search-header">
    <div class="md-layout-item md-size-20 interval-types pd-l">
      <md-field class="d-flex flex-column mt-0">
        <label class="text-nowrap position-static">Choose interval</label>
        <md-select v-model="intervalType">
          <md-option value="Daily">Daily</md-option>
          <md-option value="Weekly">Weekly</md-option>
          <md-option value="Monthly">Monthly</md-option>
          <md-option value="Yearly">Yearly</md-option>
          <md-option value="Ad Hoc">Ad Hoc</md-option>
        </md-select>
      </md-field>
    </div>
    <div
      class="md-layout-item md-size-20"
      v-if="intervalType == 'Daily' || intervalType == 'Weekly'"
    >
      <single-date-picker @emitDate="setDate"></single-date-picker>
    </div>
    <div class="md-layout-item md-size-20" v-if="intervalType == 'Monthly'">
      <md-field>
        <label>Enter Month</label>
        <md-select v-model="selectedDate">
          <md-option
            v-for="(month, index) in months"
            :key="index"
            :value="index + 1"
            >{{ month }}</md-option
          >
        </md-select>
      </md-field>
    </div>
    <div class="md-layout-item md-size-80" v-if="intervalType == 'Ad Hoc'">
      <Date-Time-Picker
        @SendFrom="setAdHocStart"
        @SendTo="setAdHocEnd"
      ></Date-Time-Picker>
    </div>

    <div class="md-layout-item md-size-60" v-if="intervalType != 'Ad Hoc'">
      <div class="list-interval">
        <div
          :class="[{ activeDate: item.date == selectedDate }, 'interval-item']"
          v-for="(item, index) in intervalItems"
          @click="setDate(item.date)"
          :key="index"
        >
          {{ item.display }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleDatePicker from './SingleDatePicker'
import DateTimePicker from './DateTimePicker'
export default {
  components: {
    SingleDatePicker,
    DateTimePicker,
  },
  name: 'date-period-dropdown',
  data() {
    return {
      today: new Date(),
      intervalItems: [],
      intervalType: 'Daily',
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      selectedDate: null,
      adHocStart: null,
      adHocEnd: null,
    }
  },

  methods: {
    updateIntervals(type) {
      this.intervalItems = []
      this.adHocStart = null
      this.adHocEnd = null
      this.$emit('dateType', this.intervalType)
      if (type == 'Daily') {
        for (let i = 0; i < 7; i++) {
          let previousDate = new Date(
            this.today.getFullYear(),
            this.today.getMonth(),
            this.today.getDate() - i
          )
          let displayDate = new Date(
            this.today.getFullYear(),
            this.today.getMonth(),
            this.today.getDate() - i
          )
            .toDateString()
            .split(' ')
            .slice(1)
            .join(' ')
          this.intervalItems.push({
            date: previousDate,
            display: displayDate,
          })
        }
      } else if (type == 'Weekly') {
        var day1 = this.today.getDay()
        const yesterday = new Date(this.today)
        yesterday.setDate(yesterday.getDate() - (day1 - 1))

        for (let i = 0; i < 7; i++) {
          let previousDate = new Date(
            yesterday.getFullYear(),
            yesterday.getMonth(),
            yesterday.getDate() - i * 7
          )
          let displayDate = new Date(
            yesterday.getFullYear(),
            yesterday.getMonth(),
            yesterday.getDate() - i * 7
          )
            .toDateString()
            .split(' ')
            .slice(1)
            .join(' ')
          this.intervalItems.push({
            date: previousDate,
            display: displayDate,
          })
        }
      } else if (type == 'Monthly') {
        var today = new Date()

        for (let i = 0; i < 7; i++) {
          let previousMonthDate = new Date(
            today.getFullYear(),
            today.getMonth() - i,
            1
          )
          this.intervalItems.push({
            date: previousMonthDate.getMonth() + 1,
            display:
              this.months[previousMonthDate.getMonth()] +
              ' ' +
              previousMonthDate.getFullYear(),
          })
        }
      } else if (type == 'Yearly') {
        for (let i = 0; i < 7; i++) {
          let previousYearDate = new Date(
            this.today.getFullYear() - i,
            this.today.getMonth(),
            this.today.getDate()
          )
          this.intervalItems.push({
            date: previousYearDate.getFullYear(),
            display: previousYearDate.getFullYear(),
          })
        }
      }
      if (this.intervalItems && this.intervalItems[0]) {
        this.selectedDate = this.intervalItems[0].date
      }
    },

    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    },

    setDate(date) {
      this.selectedDate = date
    },

    setAdHocStart(date) {
      this.adHocStart = date
    },

    setAdHocEnd(date) {
      this.adHocEnd = date
    },
  },

  created() {
    this.updateIntervals(this.intervalType)
  },

  watch: {
    intervalType: function (interval) {
      this.updateIntervals(interval)
    },

    selectedDate() {
      if (this.intervalType == 'Daily' || this.intervalType == 'Weekly') {
        this.$emit('interval', {
          type: this.intervalType,
          date: this.formatDate(this.selectedDate),
        })
      } else {
        this.$emit('interval', {
          type: this.intervalType,
          date: this.selectedDate,
        })
      }
    },
    adHocStart() {
      if (this.adHocStart != null && this.adHocEnd != null) {
        this.$emit('interval', {
          type: this.intervalType,
          startDate: this.adHocStart,
          endDate: this.adHocEnd,
        })
      }
    },

    adHocEnd() {
      if (this.adHocStart != null && this.adHocEnd != null) {
        this.$emit('interval', {
          type: this.intervalType,
          startDate: this.adHocStart,
          endDate: this.adHocEnd,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.md-menu-content-container .md-autocomplete-items .md-ripple {
  text-align: left !important;
  border: none !important;
}
.md-menu-content-container .md-autocomplete-items .md-list-item {
  border: none !important;
}
</style>
