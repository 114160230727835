<template>
  <div class="full-div" tabindex="0" @keypress.enter="checkUser()">
    <div class="d-flex flex-column align-items-center login-block-width">
      <div class="logo-img-block">
        <img
          class="logo-img-login"
          src="../../../assets/images/cbe_innova_logo.png"
        />
      </div>
      <div class="md-layout d-flex align-items-center justify-content-center">
        <div class="md-layout-item md-size-50 pd-l">
          <img class="img-login-bg" src="../../../assets/images/login-bg.svg" />
        </div>

        <div class="md-layout-item">
          <div class="login-block">
            <div class="md-layout text-center login-card">
              <div
                class="md-layout-item md-medium-size-100 md-xlarge-size-100 md-large-size-100"
              ></div>
              <div
                class="md-layout-item md-medium-size-100 md-xlarge-size-100 md-large-size-100"
              >
                <login-card class="login-card-css">
                  <div slot="description" class="des-size">
                    Enter username and password
                  </div>
                  <div slot="inputs">
                    <md-field class="md-form-group">
                      <label class="text-label text-white">Username</label>
                      <md-input
                        v-model="userLogin.email"
                        type="email"
                        class="input-class form-control"
                        autocomplete="off"
                        name="email"
                        ref="inputEmail"
                      ></md-input>
                      <div
                        v-if="submitted && $v.userLogin.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.userLogin.email.required"
                          >Email is required</span
                        >
                        <span v-else-if="!$v.userLogin.email.email"
                          >Email is invalid</span
                        >
                      </div>
                    </md-field>
                    <md-field class="md-form-group" :md-toggle-password="true">
                      <label class="text-label text-white">Password</label>
                      <md-input
                        v-model="userLogin.password"
                        type="password"
                        class="input-class form-control"
                        autocomplete="new-password"
                      ></md-input>
                      <div
                        v-if="submitted && $v.userLogin.password.$error"
                        class="invalid-feedback invalid-password"
                      >
                        <span v-if="!$v.userLogin.password.required"
                          >Password is required</span
                        >
                      </div>
                    </md-field>
                    <md-checkbox v-model="boolean1" class="remember-me"
                      >Remember Me</md-checkbox
                    >
                    <router-link :to="{ path: '/ForgetPassword' }">
                      <a class="forget-pass">Forgot Password</a>
                    </router-link>
                  </div>
                  <div slot="footer" class="footer-cls">
                    <div>
                      <p v-if="loginStatus.length > 1">
                        {{ loginStatus }}
                      </p>

                      <div class="loader-container" v-if="loader">
                        <clip-loader
                          :loading="loader"
                          :color="'#00E0DB'"
                        ></clip-loader>
                      </div>
                    </div>
                    <!-- <router-link :to="{ path:'/dashboard' }"> -->
                    <md-button class="md-primary btn-login" @click="checkUser"
                      >LOGIN NOW</md-button
                    >
                    <!-- </router-link> -->
                    <div class="signup-agree">
                      By signing up you agree to CBE Innova
                      <a class="a-color"> Terms of Service </a>
                    </div>
                    <!-- <div class="version">
                apetite.com Ver 2.0.1 - {{ $store.getters.appBuildNumber }}
              </div> -->
                  </div>
                </login-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-appetite">
      <div class="contact-box" :class="toggleShowHide ? 'animate-box' : ''">
        <form>
          <md-card>
            <md-card-header>
              <div class="md-title">Contact Us</div>
              <div @click="toggleShowHide = false">
                <md-icon class="contact-close text-white">close</md-icon>
              </div>
            </md-card-header>
            <md-card-content>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                  <md-field>
                    <label for="first-name" class="text-white">Name</label>
                    <md-input
                      name="name"
                      id="first-name"
                      autocomplete="given-name"
                      v-model="message.name"
                      data-vv-name="name"
                      v-validate="modelValidations.name"
                    />
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('name')">
                        {{ errors.first('name') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100">
                  <md-field>
                    <label for="last-name" class="text-white">Email</label>
                    <md-input
                      name="last-name"
                      id="last-name"
                      autocomplete="family-name"
                      v-model="message.email"
                      data-vv-name="email"
                      v-validate="modelValidations.email"
                    />
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('email')">
                        {{ errors.first('email') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                  <md-field>
                    <label for="last-name">Outlet Name</label>
                    <md-input
                      name="last-name"
                      id="last-name"
                      autocomplete="family-name"
                      v-model="message.outlet"
                      data-vv-name="email"
                      v-validate="modelValidations.outlet"
                    />
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('email')">
                        {{ errors.first('email') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                  <md-field>
                    <label for="last-name" class="text-white">Mobile</label>
                    <md-input
                      name="last-name"
                      id="last-name"
                      autocomplete="family-name"
                      v-model="message.mobile"
                      data-vv-name="mobile"
                      v-validate="modelValidations.mobile"
                    />
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('mobile')">
                        {{ errors.first('mobile') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100">
                  <md-field>
                    <label for="email" class="text-white">Message</label>
                    <md-textarea
                      type="text"
                      v-model="message.message"
                      data-vv-name="message"
                      v-validate="modelValidations.message"
                    ></md-textarea>
                    <slide-y-down-transition>
                      <div class="error" v-show="errors.has('message')">
                        {{ errors.first('message') }}
                      </div>
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div>
            </md-card-content>

            <md-button
              type="submit"
              class="md-primary"
              @click.native.prevent="sendMessage()"
              >Send Message</md-button
            >
          </md-card>
        </form>
      </div>
      <!-- <md-button class="md-primary btn-contact">CONTACT US</md-button> -->
      <img
        src="../../../assets/images/contact.svg"
        class="contact-us btn-contact"
        @click="toggleShowHide = !toggleShowHide"
      />
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components'
import { mapGetters, mapActions } from 'vuex'
import Vuelidate from 'vuelidate'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { accountService } from '../../../services/account'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { customizeService } from '../../../services/customize'
import { SlideYDownTransition } from 'vue2-transitions'
import { decrypt } from '@/services/cipher'

export default {
  components: {
    LoginCard,
    ClipLoader,
    SlideYDownTransition,
  },
  data() {
    return {
      message: {},
      firstname: null,
      boolean1: false,
      userLogin: {
        email: null,
        password: null,
      },
      submitted: false,
      loginStatus: '',
      loader: false,
      toggleShowHide: false,

      modelValidations: {
        name: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        mobile: {
          required: true,
        },
        message: {
          required: true,
        },
      },
    }
  },
  validations: {
    userLogin: {
      email: { required },
      password: { required },
    },
  },
  methods: {
    ...mapActions(['checkUserRole']),
    checkUser() {
      this.submitted = true
      this.loader = true
      // stop here if form is invalid
      // this.userLogin.email = document.querySelectorAll(
      //   'input[type=email]'
      // )[0].value

      // this.userLogin.password = document.querySelectorAll(
      //   'input[type=password]'
      // )[0].value

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.loader = false
        return
      }
      this.checkUserRole(this.userLogin)
    },

    checkKeyPress() {
      this.toggleShowHide = false
    },

    sendMessage() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          let data = { message: this.message }
          this.toggleShowHide = false
          customizeService.sendMessage(data).then((res) => {
            this.message = {}
            this.$validator.reset()
          })
        }
      })
    },

    detectAutofill(element) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            window
              .getComputedStyle(element, null)
              .getPropertyValue('appearance') === 'menulist-button'
          )
        }, 600)
      })
    },
  },

  computed: {
    logIn() {
      return this.$store.getters.getLoggedIn
    },
    validationStatus() {
      return this.$store.getters.getValidationStatus
    },
  },

  created() {
    // this.toggleShowHide = true
    // setTimeout(() => {
    //   console.log('Delayed for 1 second.')
    //   this.toggleShowHide = true
    // }, 500)
    if (this.$route.query.flag) {
      sessionStorage.setItem('flag', this.$route.query.flag)
    }
    if (this.$route.query.u && this.$route.query.p) {
      this.userLogin.email = this.$route.query.u
      this.userLogin.password = this.$route.query.p
      this.checkUser()
    } else if (this.$route.query.u) {
      const random = Math.floor(Math.random() * (25 - 1 + 1)) + 1
      const userType = this.$route.query.type || 'outlet'
      accountService
        .getdefaultUser(this.$route.query.u, random, userType)
        .then((res) => {
          const decrypted = JSON.parse(decrypt(res.data, random))
          this.userLogin.email = decrypted.user_name
          this.userLogin.password = decrypted.password
          this.checkUser()
        })
    }
  },

  beforeDestroy() {},

  watch: {
    logIn() {
      this.loader = false
      console.log(this.valEmailStatus)
      if (!this.validationStatus) {
        const token = accountService.getPasswordResetToken()
        window.open(window.location.origin + '/activate/' + token, '_self')
      } else if (this.logIn.loggedIn == true) {
        this.$router.push('/dashboard')
      } else {
        this.loginStatus = this.logIn.status
      }
    },
  },
}
</script>

<style scoped>
.text-white {
  color: #fff !important;
}
.side-div {
  background-color: gray;
}
.full-div {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  background-color: #eee;
  max-width: 540px;
  width: 100%;
}
.card-header {
  padding-bottom: 0px;
  height: 128px;
  margin-bottom: 0px;
}
.login-card-css {
  max-width: 100%;
  padding-right: 28px;
  padding-bottom: 34px;
  padding-left: 28px;
}
.remember-me {
  float: left;
  padding-left: 0px;
}
.forget-pass {
  float: right;
  padding-top: 13px;
  color: #2b82be;
}

.signup-agree {
  line-height: 15px;
  padding-bottom: 5px;
  color: #fff;
  position: relative;
  margin-top: 15px;
  margin-left: 0px;
  border-bottom: 0.1px;
  border-bottom-style: solid;
  font-size: 11px !important;
  border-bottom-color: #b2b6c1;
  width: 100%;
}
.footer-cls {
  width: 100%;
  padding: 0px;
  text-align: left;
}
.btn-login {
  min-width: 35% !important;
}
.a-color {
  color: #2b82be;
}
.text-label {
  margin-left: 0px;
}

.input-class {
  margin-left: 0px;
}
.input-class.md-focused {
  color: #2b82be !important;
}
.logo-img {
  width: 60% !important;
  margin-top: 37px;
}
.des-size {
  font-size: 17px;
  color: #fff;
  padding-bottom: 12px;
}

.invalid-password {
  margin-right: 50px;
}

.version {
  font-size: 11px;
  margin-left: 8px;
  color: #b2b6c1;
}
/* @media only screen and (min-width: 1025px) and (max-width: 1280px)  {
  .md-layout-item{
    min-width: 50%!important;
    max-width: 50%!important;
  }

} */
.contact-appetite .btn-contact {
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
}
.contact-appetite .contact-box {
  background: #17494d;
  opacity: 0.9;
  position: fixed;
  bottom: 90px;
  right: 30px;
  /* transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -webkit-transition: all 0.9 ease; */
  max-height: 0px;
  display: block !important;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
  overflow: hidden;
  border-radius: 24px;
  max-width: 250px;
  width: 100%;
}
.contact-appetite .contact-box.animate-box {
  max-height: 100%;
}
.error {
  color: rgb(255, 0, 0);
}
.contact-close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.contact-close:hover {
  opacity: 0.5;
}
.login-card {
  background: linear-gradient(
    109.46deg,
    rgba(201, 201, 201, 0.8) 1.57%,
    rgba(196, 196, 196, 0.1) 100%
  );
  opacity: 0.9;
  backdrop-filter: blur(53.3714px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 24.0171px;
}

/* #Animation */

@keyframes tawkMaxOpen {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}

@-moz-keyframes tawkMaxOpen {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}

@-webkit-keyframes tawkMaxOpen {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}

.contact-appetite .contact-box.open {
  animation: tawkMaxOpen 0.25s ease !important;
}

@keyframes tawkMaxClose {
  from {
    opacity: 1;
    transform: translate(0, 0px);
  }

  to {
    opacity: 0;
    transform: translate(0, 30px);
  }
}

@-moz-keyframes tawkMaxClose {
  from {
    opacity: 1;
    transform: translate(0, 0px);
  }

  to {
    opacity: 0;
    transform: translate(0, 30px);
  }
}

@-webkit-keyframes tawkMaxClose {
  from {
    opacity: 1;
    transform: translate(0, 0px);
  }

  to {
    opacity: 0;
    transform: translate(0, 30px);
  }
}

.contact-appetite .contact-box.closed {
  animation: tawkMaxClose 0.25s ease !important;
}
</style>
