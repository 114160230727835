import { environment } from './../../config'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'

var baseUrl = `${environment.api.host}`
var dynamicFlg = false
var currentRequest = {}
// axios interceptors

axios.interceptors.request.use((request) => {
  if (
    request.url !=
    baseUrl + `${environment.api.services.accounts.oauth}` + '?'
  ) {
    currentRequest = request
    return currentRequest
  }
  return request
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  function (err) {
    if (err) {
      if (err.response.status == 401) {
        // getAccessToken().then((res) => {
        //   console.log('New token', res)
        //   sessionStorage.setItem('access_token', res.data.access_token)
        //   sessionStorage.setItem('refresh_token', res.data.refresh_token)
        //   location.reload()
        // })
      } else {
        throw err
      }
    }
  }
)

//Account Service
export const accountService = {
  login,
  getAccessToken,
  menuItems,
  userPackage,
  userRole,
  getChainId,
  getOutletId,
  getDistributorId,
  getLanguage,
  dynamicFlg,
  getUserId,
  getRoleId,
  getUserDetails,
  updateUserDetails,
  changePassword,
  createCheckoutSession,
  generatePasswordResetToken,
  newPassword,
  resetPasswordExpiry,
  getPasswordResetToken,
  generateToken,
  getdefaultUser,
}

function authFun() {
  const auth = {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
    },
  }
  return auth
}

function login(user) {
  const headers = {
    'Content-Type': 'application/json',
  }
  let body = {
    grant_type: 'password',
    username: user.email,
    password: user.password,
    client_id: `${environment.client.id}`,
    client_secret: `${environment.client.secret}`,
  }
  return axios.post(
    baseUrl + `${environment.api.services.accounts.oauth}`,
    body,
    headers
  )
}

function getAccessToken() {
  const headers = {
    'Content-Type': 'application/json',
  }
  let body = {
    refresh_token: sessionStorage.getItem('refresh_token'),
    grant_type: 'refresh_token',
    client_id: `${environment.client.id}`,
    client_secret: `${environment.client.secret}`,
  }
  return axios.post(
    baseUrl + `${environment.api.services.accounts.access_token}`,
    body,
    headers
  )
}

function menuItems() {
  let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
  return decoded.menus.items
}

function userPackage() {
  let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
  return decoded.user.package_id
}

function userRole() {
  let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
  return decoded.user.user_type
}

function getChainId() {
  let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
  return decoded.user.chain_id
}

function getOutletId() {
  let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
  return decoded.user.outlet_id
}

function getDistributorId() {
  let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
  return decoded.user.distributor_id
}

function getLanguage() {
  if (sessionStorage.getItem('access_token') != null) {
    let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
    return decoded.user.language
  } else {
    return 'en'
  }
}

function getUserId() {
  let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
  return decoded.user.id
}

function getRoleId() {
  let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
  return decoded.user.role_id
}
function getPasswordResetToken() {
  let decoded = VueJwtDecode.decode(sessionStorage.getItem('access_token'))
  return decoded.user.password_reet_token
}

// User Profile Details

function getUserDetails() {
  return axios.get(
    baseUrl +
      `${environment.api.services.accounts.userdetails}` +
      '/' +
      accountService.getUserId(),
    authFun()
  )
}

function updateUserDetails(userDetails) {
  return axios.put(
    baseUrl +
      `${environment.api.services.accounts.userdetails}` +
      '/' +
      userDetails.id,
    userDetails,
    authFun()
  )
}

function changePassword(body) {
  return axios.post(
    baseUrl +
      `${environment.api.services.accounts.update_password}?id=` +
      getUserId(),
    body,
    authFun()
  )
}
function newPassword(body, token) {
  return axios.post(
    baseUrl +
      `${environment.api.services.accounts.user}/welcome_password_reset?token=${token}`,
    body,
    authFun()
  )
}

function createCheckoutSession() {
  return axios.get(
    baseUrl + `${environment.api.services.accounts.create_checkout_session}`
  )
}

function generatePasswordResetToken(userName) {
  let body = {
    user_name: userName,
  }
  return axios.post(
    baseUrl +
      `${environment.api.services.accounts.userdetails}/geneare_password_token`,
    body
  )
}

function resetPasswordExpiry(token) {
  let body = {
    token: token,
  }
  return axios.post(
    baseUrl +
      `${environment.api.services.accounts.userdetails}/set_pssword_expiry`,
    body,
    authFun()
  )
}
function generateToken() {
  return axios.post(
    baseUrl + `${environment.api.services.accounts.userdetails}/generate_token`,
    {},
    authFun()
  )
}

function getdefaultUser(id, rndm, userType) {
  return axios.post(
    baseUrl +
      `${environment.api.services.accounts.user}/get_default_user?id=${id}&shift=${rndm}&user_type=${userType}`,
    {},
    authFun()
  )
}
