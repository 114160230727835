<template>
  <div class="md-layout-item pd-l">
    <md-card class="pd-l">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left ml-auto">
            <router-link :to="{ path: '/Products/Combos' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t(formType) }}</h3>
        </div>
        <div class="md-layout-item md-size-30">
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editComboGroup"
            v-if="formType == 'View Combo Group'"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader" class="block-bg pad-xy rounded-xl">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('comboGroupName') },
                { 'md-valid': !errors.has('comboGroupName') },
              ]"
            >
              <label>{{ $t('Combo Group Name') }}</label>
              <md-input
                v-model="combos_groups.name"
                data-vv-name="comboGroupName"
                type="text"
                v-validate="modelValidations.comboGroupName"
                :disabled="formType == 'View Combo Group'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('comboGroupName')">
                  {{ errors.first('comboGroupName') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="
                    !errors.has('comboGroupName') && touched.comboGroupName
                  "
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Qty') },
                { 'md-valid': !errors.has('Qty') },
              ]"
            >
              <label>{{ $t('Quantity') }}</label>
              <md-input
                v-model="combos_groups.quantity"
                data-vv-name="Qty"
                type="text"
                v-validate="modelValidations.Qty"
                :disabled="formType == 'View Combo'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Qty')">
                  {{ errors.first('Qty') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Qty') && touched.Qty"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50"
            v-if="formType != 'View Combo Group'"
          >
            <md-field>
              <label>{{ $t('Main Category') }}</label>
              <md-select
                v-model="mainCatId"
                @md-selected="loadLeftItems(mainCatId)"
              >
                <md-option class="first-opt" disabled>Main Category</md-option>
                <md-option
                  :value="item.id"
                  v-for="(item, index) in mainCatList"
                  :key="index"
                  >{{ item.name }}</md-option
                >
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Qty')">
                  {{ errors.first('Qty') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Qty') && touched.Qty"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Status') }}</label>
              <md-select v-model="combos_groups.status">
                <md-option class="first-opt" disabled>Status</md-option>
                <md-option value="true">{{ $t('Active') }}</md-option>
                <md-option value="false">{{ $t('Inactive') }}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Qty')">
                  {{ errors.first('Qty') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Qty') && touched.Qty"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item list-picker md-size-80">
            <vue-list-picker
              :left-items="leftItems"
              :right-items="rightItems"
              :titleRight="titleRight"
              moved-item-location="bottom"
              class="text-dark"
            />
          </div>
          <div class="md-layout-item md-size-20 list-picker-container">
            <div class="p-1">Supplement Price</div>
            <div
              v-for="item in rightItems"
              :key="item.id"
              class="supp-price list-picker-item"
            >
              <md-field>
                <md-input
                  class="s-price"
                  v-model="item.supplement_price"
                  type="text"
                ></md-input>
              </md-field>
            </div>
          </div>
        </div>

        <div class="btn-div" v-if="formType != 'View Combo Groups'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/Products/Combos' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              class="md-primary"
              @click="submitComboGroup()"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import { reusableService } from '../../../services/reusable.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  data() {
    return {
      touched: {},

      modelValidations: {
        required: {
          required: true,
        },

        comboGroupName: {
          required: true,
        },

        Qty: {
          required: true,
          numeric: true,
        },
      },
      comboGroupName: '',
      height: '100px',
      formType: '',
      comboGrpId: '',
      leftItems: [],
      rightItems: [],
      titleRight: 'ITEMS SELECTED',
      newarray: [],

      menuItems: [],
      mainCatList: [],
      mainCatId: '',
      rightItemsKey: [],
      combos_groups: {
        name: '',
        quantity: '',
        outlet_id: accountService.getOutletId(),
        status: 'true',
        combo_menus: [],
      },
      loader: true,
    }
  },

  methods: {
    getMenuItems() {
      let self = this
      customizeService.getMenuItems().then((res) => {
        self.menuItems = res.data.menu_items.filter(
          (el) => el.status == 'active'
        )
        this.loadComboGrpById()
      })
    },

    getMainCategories() {
      customizeService.getMaincat().then((res) => {
        this.mainCatList = res.data.api_v1_main_category.filter(
          (el) => el.status == true
        )
      })
    },

    loadLeftItems(id) {
      this.leftItems = []
      this.rightItemsKey = []

      let filteredItems = this.menuItems.filter(function (el) {
        return id == el.item_group
      })
      this.rightItems.forEach((el) => {
        this.rightItemsKey.push(el.key)
      })
      let self = this
      filteredItems.forEach((el) => {
        let body = {}
        body.key = el.id
        body.content = el.name
        body.price = el.price
        if (!this.rightItemsKey.includes(el.id)) {
          self.leftItems.push(body)
        }
      })
    },

    submitComboGroup() {
      this.combos_groups.combo_menus = []
      this.rightItems.forEach((el) => {
        this.combos_groups.combo_menus.push({
          price: el.supplement_price,
          menu_item_id: el.key,
        })
      })
      if (this.formType == 'Add Combo Group') {
        this.combos_groups.chain_id = accountService.getChainId()
        customizeService.createComboGroup(this.combos_groups).then((res) => {
          this.$router.push({ path: '/Products/Combos' })
        })
      } else {
        customizeService
          .editComboGroup(this.comboGrpId, this.combos_groups)
          .then((res) => {
            this.$router.push({ path: '/Products/Combos' })
          })
      }
    },

    editComboGroup() {
      this.formType = 'Edit Combo Group'
    },

    loadComboGrpById() {
      if (this.formType != 'Add Combo Group') {
        customizeService.getComboGroupById(this.comboGrpId).then((res) => {
          this.loader = false
          this.combos_groups = res.data.combo_groups
          this.combos_groups.menu_items.forEach((el) => {
            let menuItemName = this.menuItems.find(function (menuitem) {
              return menuitem.id == el.id
            })
            this.rightItems.push({
              key: el.id,
              content: el.name,
              supplement_price: el.supplement_price,
            })
          })
        })
      } else {
        this.loader = false
      }
    },
  },

  created() {
    this.getMenuItems()
    this.getMainCategories()
    this.formType = this.$route.query.formType
    this.comboGrpId = this.$route.query.client
  },
}
</script>

<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}

.list-picker {
  padding: 10px 10px;
}
.supp-price .md-field {
  margin: 0px;
  // padding: 10px 10px;
  height: auto;
}
.md-layout-item.md-size-20 {
  padding: 10px 0 10px 15px;
  display: flex;
  flex-direction: column;
}
.p-1 {
  padding: 8px 10px;
}
// .supp-price.list-picker-item {
//   border-bottom: 1px solid #ccc;
// }
.supp-price .md-field {
  padding-bottom: 0;
}
.s-price::after {
  bottom: -2px;
}
.s-price {
  height: 41px;
  padding: 0 10px;
}
.supp-price .md-field::after {
  bottom: 0;
}
</style>
