import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue'
import AuthLayout from '@/pages/Dashboard/Pages/AuthLayout.vue'

// Dashboard pages
import Dashboard from '@/pages/Dashboard/Dashboard.vue'

// Pages
import User from '@/pages/Dashboard/Pages/UserProfile.vue'
import Pricing from '@/pages/Dashboard/Pages/Pricing.vue'
import PricingDemo from '@/pages/Dashboard/Pages/PricingDemo.vue'
import Welcome from '../pages/Dashboard/Settings/Welcome.vue'

import SubscriptionSuccess from '@/pages/Dashboard/Pages/SubscriptionSuccess.vue'

import TimeLine from '@/pages/Dashboard/Pages/TimeLinePage.vue'
import RtlSupport from '@/pages/Dashboard/Pages/RtlSupport.vue'
import Login from '@/pages/Dashboard/Pages/Login.vue'
import ForgetPassword from '@/pages/Dashboard/Pages/ForgetPassword.vue'
import Register from '@/pages/Dashboard/Pages/Register.vue'
import SignUp from '@/pages/Dashboard/Pages/UserProfile/NewUser.vue'
import Lock from '@/pages/Dashboard/Pages/Lock.vue'

// Components pages
import Buttons from '@/pages/Dashboard/Components/Buttons.vue'
import GridSystem from '@/pages/Dashboard/Components/GridSystem.vue'
import Panels from '@/pages/Dashboard/Components/Panels.vue'
import SweetAlert from '@/pages/Dashboard/Components/SweetAlert.vue'
import Notifications from '@/pages/Dashboard/Components/Notifications.vue'
import Icons from '@/pages/Dashboard/Components/Icons.vue'
import Typography from '@/pages/Dashboard/Components/Typography.vue'

// Forms pages
import RegularForms from '@/pages/Dashboard/Forms/RegularForms.vue'
import ExtendedForms from '@/pages/Dashboard/Forms/ExtendedForms.vue'
import ValidationForms from '@/pages/Dashboard/Forms/ValidationForms.vue'
import EditForm from '@/pages/Dashboard/Forms/EditForm.vue'
import Wizard from '@/pages/Dashboard/Forms/Wizard.vue'

// TableList pages
import RegularTables from '@/pages/Dashboard/Tables/RegularTables.vue'
import ExtendedTables from '@/pages/Dashboard/Tables/ExtendedTables.vue'
import PaginatedTables from '@/pages/Dashboard/Tables/PaginatedTables.vue'

// Maps pages
import GoogleMaps from '@/pages/Dashboard/Maps/GoogleMaps.vue'
import FullScreenMap from '@/pages/Dashboard/Maps/FullScreenMap.vue'
import VectorMaps from '@/pages/Dashboard/Maps/VectorMaps.vue'

// Calendar
import Calendar from '@/pages/Dashboard/Calendar.vue'
// Charts
import Charts from '@/pages/Dashboard/Charts.vue'
import Widgets from '@/pages/Dashboard/Widgets.vue'

//Distributors
import Distributors from '@/pages/Dashboard/Distributors/list-distributors.vue'
import DistributorsTable from '@/pages/Dashboard/Distributors/DistributorsTable.vue'
import DistributorForm from '@/pages/Dashboard/Distributors/DistributorForm.vue'
import AddDistributors from '@/pages/Dashboard/Distributors/AddDistributors.vue'
import AddBusinessVertical from '@/pages/Dashboard/Distributors/AddBusinessVertical.vue'
import BusinessVertical from '@/pages/Dashboard/Distributors/BusinessVertical.vue'

//Business Vertical
import AddOutlet from '@/pages/Dashboard/BusinessVertical/AddOutlet.vue'
import EditOutlet from '@/pages/Dashboard/BusinessVertical/EditOutlet.vue'

import Outlet from '@/pages/Dashboard/BusinessVertical/Outlet.vue'
import AllOutlets from '@/pages/Dashboard/BusinessVertical/AllOutlets.vue'

import AssignItems from '@/pages/Dashboard/BusinessVertical/AssignItems.vue'
import OutletOptions from '@/pages/Dashboard/BusinessVertical/OutletOptions.vue'

//Category
import Categories from '@/pages/Dashboard/Categories/Categories.vue'
import SuperCategory from '@/pages/Dashboard/Categories/SuperCategory.vue'
import MainCategory from '@/pages/Dashboard/Categories/MainCategory.vue'
import SubCategory from '@/pages/Dashboard/Categories/SubCategory.vue'
import CategoryForm from '@/pages/Dashboard/Categories/CategoryForm.vue'
import MainCatForm from '@/pages/Dashboard/Categories/MainCatForm.vue'
import SubCatForm from '@/pages/Dashboard/Categories/SubCatForm.vue'
import MenuSectionForm from '@/pages/Dashboard/OnlineOrder/MenuSectionForm.vue'
import Vouchers from '@/pages/Dashboard/Promotions/Vouchers.vue'
import VoucherAudit from '@/pages/Dashboard/Promotions/VoucherAudit.vue'
import VoucherSales from '@/pages/Dashboard/Promotions/VoucherSales.vue'

import ImportVouchers from '@/pages/Dashboard/Promotions/ImportVouchers.vue'

import Modifiers from '@/pages/Dashboard/Categories/Modifiers.vue'
import ModifierCreateForm from '@/pages/Dashboard/Categories/ModifierCreateForm.vue'
import ModifierEditForm from '@/pages/Dashboard/Categories/ModifierEditForm.vue'
import MenuItemsForm from '@/pages/Dashboard/Categories/MenuItemsForm.vue'
import MenuItemListForm from '@/pages/Dashboard/Categories/MenuItemListForm.vue'
import MenuItemLists from '@/pages/Dashboard/Categories/MenuItemLists.vue'
import MenuItemListLink from '@/pages/Dashboard/Categories/MenuListLink.vue'

import OutletMenuItemsForm from '@/pages/Dashboard/Categories/OutletMenuItemsForm.vue'

import MenuItem from '@/pages/Dashboard/Categories/MenuItem.vue'
import Combos from '@/pages/Dashboard/Categories/Combos.vue'
import ComboForm from '@/pages/Dashboard/Categories/ComboForm.vue'
import CombosGroupForm from '@/pages/Dashboard/Categories/CombosGroupForm.vue'
import Portions from '@/pages/Dashboard/Categories/Portions.vue'
import AddPortions from '@/pages/Dashboard/Categories/AddPortions.vue'
import iPadGrid from '@/pages/Dashboard/Categories/iPadGrid.vue'
import ScreenDesign from '@/pages/Dashboard/ScreenDesign/ScreenDesign.vue'

import Integrations from '@/pages/Dashboard/Categories/Integrations.vue'
import MenuSection from '@/pages/Dashboard/OnlineOrder/MenuSection.vue'

//Outlet
import DiscountCategory from '@/pages/Dashboard/Outlet/DiscountCategory.vue'
import DiscountCategoryForm from '@/pages/Dashboard/Outlet/DiscountCategoryForm.vue'
import OtherPaymenTypes from '@/pages/Dashboard/Outlet/OtherPaymentTypes.vue'
import OtherPaymentForm from '@/pages/Dashboard/Outlet/OtherPaymentForm.vue'
import Table from '@/pages/Dashboard/Outlet/Table.vue'
import VoidReasons from '@/pages/Dashboard/Outlet/VoidReasons.vue'
import AddVoidReasons from '@/pages/Dashboard/Outlet/AddVoidReasons.vue'
import AddTable from '@/pages/Dashboard/Outlet/AddTable.vue'
import TablePosition from '@/pages/Dashboard/Outlet/TablePosition.vue'
import TableAreas from '@/pages/Dashboard/Outlet/TableAreas.vue'
import TableAreaForm from '@/pages/Dashboard/Outlet/TableAreaForm.vue'
import ManageStaffForm from '@/pages/Dashboard/Outlet/ManageStaffForm.vue'
import Staffs from '@/pages/Dashboard/Outlet/Staffs.vue'
import Profile from '@/pages/Dashboard/Outlet/Profile.vue'
import ManageProfile from '@/pages/Dashboard/Outlet/ManageProfile.vue'
import Terminals from '@/pages/Dashboard/Outlet/Terminals.vue'
import TerminalsForm from '@/pages/Dashboard/Outlet/TerminalsForm.vue'
import StaffRoles from '@/pages/Dashboard/Outlet/StaffRoles.vue'
import ManageStaffRole from '@/pages/Dashboard/Outlet/ManageStaffRole.vue'
import ScheduledReport from '@/pages/Dashboard/Outlet/ScheduledReport.vue'
import ScheduledReportForm from '@/pages/Dashboard/Outlet/ScheduledReportForm.vue'
import OrderTypes from '@/pages/Dashboard/Outlet/OrderTypes.vue'

//Settings
import Modules from '@/pages/Dashboard/Settings/Modules.vue'
import AddModules from '@/pages/Dashboard/Settings/AddModules.vue'
import PackageForm from '@/pages/Dashboard/Settings/PackageForm.vue'
import Packages from '@/pages/Dashboard/Settings/Packages.vue'
import Station from '@/pages/Dashboard/Settings/Station.vue'
import AddStation from '@/pages/Dashboard/Settings/AddStation.vue'
import Course from '@/pages/Dashboard/Settings/Course.vue'
import AddCourse from '@/pages/Dashboard/Settings/AddCourse.vue'
import RepeatPrinter from '@/pages/Dashboard/Settings/RepeatPrinter.vue'
import RepeatPrinterForm from '@/pages/Dashboard/Settings/RepeatPrinterForm.vue'
import Roles from '@/pages/Dashboard/Settings/Roles.vue'
import AddRoles from '@/pages/Dashboard/Settings/AddRoles.vue'
import AddTax from '@/pages/Dashboard/Settings/AddTax.vue'
import PaymentSense from '@/pages/Dashboard/Settings/PaymentSense.vue'
import BankOfIreland from '@/pages/Dashboard/Settings/BankOfIreland'
import ReceiptRelay from '@/pages/Dashboard/Settings/ReceiptRelay'
import Windcave from '@/pages/Dashboard/Settings/Windcave'
import Stripe from '@/pages/Dashboard/Settings/Stripe'
import VoucherMe from '@/pages/Dashboard/Settings/VoucherMe'
import Azpiral from '@/pages/Dashboard/Settings/Azpiral.vue'
import Debitrack from '@/pages/Dashboard/Settings/Integrations/Debitrack.vue'

import FlipDish from '@/pages/Dashboard/Settings/FlipDish.vue'
import AllUsers from '@/pages/Dashboard/Settings/AllUsers'
import AddIntegrations from '@/pages/Dashboard/Settings/AddIntegrations.vue'
import IntegrationsList from '@/pages/Dashboard/Settings/IntegrationsList.vue'
import MenuItemImport from '@/pages/Dashboard/Settings/MenuItemImport.vue'
import ChangePassword from '@/pages/Dashboard/Settings/ChangePassword.vue'

//Reports
import HourlySales from '@/pages/Dashboard/Reports/Sales-Summary/HourlySales.vue'
import HourlyOrder from '@/pages/Dashboard/Reports/Sales-Summary/HourlyOrder.vue'
import WeeklySales from '@/pages/Dashboard/Reports/Sales-Summary/WeeklySales.vue'
import MonthlySales from '@/pages/Dashboard/Reports/Sales-Summary/MonthlySales.vue'
import DailySales from '@/pages/Dashboard/Reports/Sales-Summary/DailySales.vue'
import AnnualSales from '@/pages/Dashboard/Reports/Sales-Summary/AnnualSales.vue'

import EndOfDay from '@/pages/Dashboard/Reports/EndOfDay.vue'
import PettyCash from '@/pages/Dashboard/Reports/PettyCash.vue'
import PowerBi from '@/pages/Dashboard/Reports/PowerBi.vue'

import DiscountCategoryReport from '@/pages/Dashboard/Reports/DiscountCategoryReport.vue'

import MenuItemReport from '@/pages/Dashboard/Reports/Products/MenuItemReport.vue'
import MainCategoryReport from '@/pages/Dashboard/Reports/Products/MainCategoryReport.vue'
import SuperCategoryReport from '@/pages/Dashboard/Reports/Products/SuperCategoryReport.vue'
import SuperCategoryByDate from '@/pages/Dashboard/Reports/Products/SuperCategoryByDate.vue'
import ComboReport from '@/pages/Dashboard/Reports/Products/ComboReport.vue'
import SubCategoryReport from '@/pages/Dashboard/Reports/Products/SubCategoryReport.vue'
import ModifiersItemReport from '@/pages/Dashboard/Reports/Products/ModifiersItemReport.vue'
import ModifiersReport from '@/pages/Dashboard/Reports/Products/ModifiersReport.vue'
import SalesGpReport from '@/pages/Dashboard/Reports/Products/SalesGpReport.vue'
import ItemisedTransactions from '@/pages/Dashboard/Reports/TransactionSummary/ItemisedTransactions.vue'
import OrderReport from '@/pages/Dashboard/Reports/TransactionSummary/OrderReport.vue'

import HourlyReport from '@/pages/Dashboard/Reports/TransactionSummary/HourlyReport.vue'
import HourlySummaryReport from '@/pages/Dashboard/Reports/TransactionSummary/HourlySummaryReport.vue'
import TerminalTransactions from '@/pages/Dashboard/Reports/TransactionSummary/TerminalTransactions.vue'

import TaxSummary from '@/pages/Dashboard/Reports/Taxes/TaxSummary.vue'
import DetailedTaxReport from '@/pages/Dashboard/Reports/Taxes/DetailedTaxReport.vue'
import TaxReportBySalesCategory from '@/pages/Dashboard/Reports/Taxes/TaxReportBySalesCategory.vue'

import AllMenuItems from '@/pages/Dashboard/Reports/Sales/AllMenuItems.vue'
import ItemCategories from '@/pages/Dashboard/Reports/Sales/ItemCategories.vue'
import Revenue from '@/pages/Dashboard/Reports/Sales/Revenue.vue'

import WastageSummary from '@/pages/Dashboard/Reports/Wastage/WastageSummary.vue'
import WastageDetails from '@/pages/Dashboard/Reports/Wastage/WastageDetails.vue'

import VoidsReport from '@/pages/Dashboard/Reports/Void/VoidsReport.vue'
import VoidsReportByStaff from '@/pages/Dashboard/Reports/Void/VoidsReportByStaff.vue'
import VoidsReason from '@/pages/Dashboard/Reports/Void/VoidrsReasons.vue'

import StaffReport from '@/pages/Dashboard/Reports/Labour/staff.vue'
import Users from '@/pages/Users.vue'
import UserDetailEdit from '@/pages/UserEdit.vue'
import Accounts from '@/pages/Dashboard/Settings/Accounts.vue'
import ResetPassword from '@/pages/Dashboard/Settings/ResetPassword.vue'

import { onlineOrderingModule } from './onlineOrdering'

// Integrations

import GuestDiary from '@/pages/Dashboard/Settings/Pms/GuestDiary.vue'
import { cbePayModule } from './cbePay'
import { screenDesignModule } from './screenDesign'

// function loadView(view, folder) {
//   return () => import(`@/pages/Dashboard/${folder}/${view}.vue`)
// }

let UserMenu = {
  path: '/user',
  component: DashboardLayout,
  name: 'UserProfile',
  redirect: '/user/user-profile',
  children: [
    {
      path: 'user-profile',
      name: 'Profile',
      components: { default: User },
    },
  ],
}

// let UserList = {
//   path: '/users',
//   name: 'users',
//   components: { default: Users },
//   meta: {
//     title: 'users',
//     breadcrumbs: [
//       {
//         text: 'Users',
//       },
//     ],
//   },
//   children: [
//     {
//       path: 'user-edit',
//       name: 'UserDetailEdit',
//       components: { default: UserDetailEdit },
//       meta: {
//         breadcrumbs: [
//           {
//             text: 'UserDetailEdit',
//           },
//         ],
//       },
//     },
//   ],
// }

let UserList = {
  path: '/users',
  component: DashboardLayout,
  name: 'users',
  children: [
    {
      path: '/users',
      name: 'users',
      components: { default: Users },
    },
    {
      path: '/useredit',
      name: 'UserDetailEdit',
      components: { default: UserDetailEdit },
    },
  ],
}

let Dist = {
  path: '/distributors',
  component: DashboardLayout,
  name: 'Distributors',
  redirect: '/distributors/list-dist',
  children: [
    {
      path: 'list-dist',
      name: 'Distributors List',
      components: { default: Distributors },
      meta: {
        breadcrumbs: [
          {
            text: 'Distributors',
          },
        ],
      },
    },
    {
      path: 'Distributors-table',
      name: 'Distributors Table',
      components: { default: DistributorsTable },
      meta: {
        title: 'Distributors',
        breadcrumbs: [
          {
            text: 'Distributors',
          },
        ],
      },
    },
    {
      path: 'distributor-form',
      name: 'Distributor Form',
      components: { default: DistributorForm },
      meta: {
        title: 'Distributors',
        breadcrumbs: [
          {
            text: 'Distributors',
          },
        ],
      },
    },
    {
      path: 'AddDistributor',
      name: 'AddDistributor',
      components: { default: AddDistributors },
      meta: {
        title: 'Distributors',
        breadcrumbs: [
          {
            text: 'Distributors',
          },
        ],
      },
    },
    {
      path: 'AddBusinessVertical',
      name: 'AddBusinessVertical',
      components: { default: AddBusinessVertical },
      meta: {
        title: 'Add Business Vertical',
        breadcrumbs: [
          {
            text: 'Business Verticals',
          },
        ],
      },
    },
    {
      path: 'BusinessVertical',
      name: 'BusinessVertical',
      components: { default: BusinessVertical },
      meta: {
        title: 'Business Vertical',
        breadcrumbs: [
          {
            text: 'Business Verticals',
          },
        ],
      },
    },
  ],
}

let BusVert = {
  path: '/business-vertical',
  name: 'BusinessVert',
  component: DashboardLayout,
  redirect: '/business-vertical/add-outlet',
  children: [
    {
      path: 'add-outlet',
      name: 'AddOutlet',
      components: { default: AddOutlet },
      meta: {
        title: 'Add Outlet',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Outlets',
          },
        ],
      },
    },

    {
      path: 'edit-outlet',
      name: 'EditOutlet',
      components: { default: EditOutlet },
      meta: {
        title: 'Edit Outlet',
      },
    },
    {
      path: 'Outlet',
      name: 'Outlet',
      components: { default: Outlet },
      meta: {
        title: 'Outlet',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Outlets',
          },
        ],
      },
    },
    {
      path: 'all-outlets',
      name: 'All Outlet',
      components: { default: AllOutlets },
    },
    {
      path: 'assign-items',
      name: 'AssignItems',
      components: { default: AssignItems },
      meta: {
        title: 'Outlet',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Outlets',
          },
          {
            text: 'Assign Items',
          },
        ],
      },
    },
    {
      path: 'outlet-options/:id',
      name: 'OutletOptions',
      components: { default: OutletOptions },
      meta: {
        title: 'Outlet',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Outlets',
          },
          {
            text: 'Outlet Options',
          },
        ],
      },
    },
  ],
}

let Settings = {
  path: '/Settings',
  component: DashboardLayout,
  name: 'Settings',
  children: [
    {
      path: 'Modules',
      name: 'Modules',
      components: { default: Modules },
      meta: {
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Modules',
          },
        ],
      },
    },
    {
      path: 'AddModules',
      name: 'AddModules',
      components: { default: AddModules },
      meta: {
        title: 'Modules',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Modules',
          },
        ],
      },
    },

    {
      path: 'PackageForm',
      name: 'PackageForm',
      components: { default: PackageForm },
      meta: {
        title: 'Package',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Packages',
          },
        ],
      },
    },
    {
      path: 'Packages',
      name: 'Packages',
      components: { default: Packages },
      meta: {
        title: 'Packages',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Packages',
          },
        ],
      },
    },
    {
      path: 'Station',
      name: 'Station',
      components: { default: Station },
      meta: {
        title: 'Station',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Station',
          },
        ],
      },
    },
    {
      path: 'AddStation',
      name: 'AddStation',
      components: { default: AddStation },
      meta: {
        title: 'Station',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Stations',
          },
        ],
      },
    },
    {
      path: 'Course',
      name: 'Course',
      components: { default: Course },
      meta: {
        title: 'Course',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Courses',
          },
        ],
      },
    },
    {
      path: 'AddCourse',
      name: 'AddCourse',
      components: { default: AddCourse },
      meta: {
        title: 'Course',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Courses',
          },
        ],
      },
    },
    {
      path: 'repeat-printer',
      name: 'Repeat Printers',
      components: { default: RepeatPrinter },
      meta: {
        title: 'Re-Printer',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Repeat Printer',
          },
        ],
      },
    },
    {
      path: 'edit-repeat-printer',
      name: 'RepeatPrinterForm',
      components: { default: RepeatPrinterForm },
      meta: {
        title: 'Repeat Printer',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Repeat Printer',
          },
        ],
      },
    },
    {
      path: 'menu-item-import',
      name: 'MenuItemImport',
      components: { default: MenuItemImport },
      meta: {
        title: 'Menu Item Import',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Menu Item Import',
          },
        ],
      },
    },
    {
      path: 'change-password',
      name: 'ChangePassword',
      components: { default: ChangePassword },
      meta: {
        title: 'Change Password',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Change Password',
          },
        ],
      },
    },
    {
      path: 'roles',
      name: 'Roles',
      components: { default: Roles },
      meta: {
        title: 'Roles',
        breadcrumbs: [
          {
            text: 'Roles',
          },
        ],
      },
    },
    {
      path: 'add-integrations',
      name: 'AddIntegrations',
      components: { default: AddIntegrations },
      meta: {
        title: 'Integration',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Integrations',
          },
        ],
      },
    },

    {
      path: 'integrations',
      name: 'IntegrationsList',
      components: { default: IntegrationsList },
      meta: {
        title: 'Integration',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Integrations',
          },
        ],
      },
    },

    {
      path: 'edit-role',
      name: 'AddRoles',
      components: { default: AddRoles },
      meta: {
        title: 'Roles',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Roles',
          },
        ],
      },
    },
    {
      path: 'add-tax',
      name: 'AddTax',
      components: { default: AddTax },
      meta: {
        title: 'Tax',
        breadcrumbs: [
          {
            text: 'Settings',
          },
          {
            text: 'Tax',
          },
        ],
      },
    },

    {
      path: 'all-users',
      name: 'AllUsers',
      components: {
        default: AllUsers,
      },
      meta: {
        title: 'All Users',
      },
    },
  ],
}

let IntegrationsModule = {
  path: '/Integrations',
  component: DashboardLayout,
  name: 'Integrations',
  children: [
    {
      path: 'flipdish',
      name: 'FlipDish',
      components: { default: FlipDish },
      meta: {
        title: 'FlipDish',
        breadcrumbs: [
          {
            text: 'Integrations',
          },
          {
            text: 'FlipDish',
          },
        ],
      },
    },
    {
      path: 'payment-sense',
      name: 'PaymentSense',
      components: { default: PaymentSense },
      meta: {
        title: 'Payment Sense',
        breadcrumbs: [
          {
            text: 'Integrations',
          },
          {
            text: 'Payment Sense',
          },
        ],
      },
    },
    {
      path: 'bank-of-ireland',
      name: 'BankOfIreland',
      components: {
        default: BankOfIreland,
      },
      meta: {
        title: 'Bank Of Ireland',
        breadcrumbs: [
          {
            text: 'Integrations',
          },
          {
            text: 'Bank Of Ireland',
          },
        ],
      },
    },
    {
      path: 'windcave',
      name: 'Windcave',
      components: {
        default: Windcave,
      },
      meta: {
        title: 'Windcave',
        breadcrumbs: [
          {
            text: 'Integrations',
          },
          {
            text: 'Windcave',
          },
        ],
      },
    },
    {
      path: 'stripe',
      name: 'stripe',
      components: {
        default: Stripe,
      },
      meta: {
        title: 'Stripe',
        breadcrumbs: [
          {
            text: 'Integrations',
          },
          {
            text: 'Stripe',
          },
        ],
      },
    },
    {
      path: 'voucher-me',
      name: 'voucher-me',
      components: {
        default: VoucherMe,
      },
      meta: {
        title: 'Stripe',
        breadcrumbs: [
          {
            text: 'Integrations',
          },
          {
            text: 'Voucher Me',
          },
        ],
      },
    },
    {
      path: 'receipt-relay',
      name: 'ReceiptRelay',
      components: {
        default: ReceiptRelay,
      },
      meta: {
        title: 'Receipt Relay',
        breadcrumbs: [
          {
            text: 'Receipt Relay',
          },
          {
            text: 'Receipt Relay',
          },
        ],
      },
    },
    {
      path: 'azpiral',
      name: 'Azpiral',
      components: {
        default: Azpiral,
      },
      meta: {
        title: 'Azpiral',
        breadcrumbs: [
          {
            text: 'Azpiral',
          },
          {
            text: 'Azpiral',
          },
        ],
      },
    },
    {
      path: 'debitrack',
      name: 'Debitrack',
      components: {
        default: Debitrack,
      },
      meta: {
        title: 'Debitrack',
        breadcrumbs: [
          {
            text: 'Debitrack',
          },
          {
            text: 'Debitrack',
          },
        ],
      },
    },
    {
      path: 'pms',
      name: 'PMS',
      components: {
        default: GuestDiary,
      },

      children: [
        {
          path: 'guest-diary',
          name: 'GuestDiary',
          components: { default: GuestDiary },
          meta: {
            title: 'GuestDiary',
            breadcrumbs: [
              {
                text: 'Outlet Settings',
              },
              {
                text: 'Outlets',
              },
            ],
          },
        },
      ],
    },
  ],
}

let componentsMenu = {
  path: '/outlets',
  component: DashboardLayout,
  redirect: '/outlets/buttons',
  name: 'Outlets',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      components: { default: Buttons },
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      components: { default: GridSystem },
    },
    {
      path: 'panels',
      name: 'Panels',
      components: { default: Panels },
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      components: { default: SweetAlert },
    },
    {
      path: 'notifications',
      name: 'Notifications',
      components: { default: Notifications },
    },
    {
      path: 'icons',
      name: 'Icons',
      components: { default: Icons },
    },
    {
      path: 'typography',
      name: 'Typography',
      components: { default: Typography },
    },
  ],
}

let OutletsMenu = {
  path: '/outlet',
  component: DashboardLayout,
  name: 'Outlet Settings',
  children: [
    {
      path: 'discount-category',
      name: 'DiscountCategory',
      components: { default: DiscountCategory },
      meta: {
        title: 'Discount Category',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Discount Category',
          },
        ],
      },
    },
    {
      path: 'edit-discount-category',
      name: 'DiscountCategoryForm',
      components: { default: DiscountCategoryForm },
      meta: {
        title: 'Discount Category',
        meta: {
          title: 'Discount Category',
          breadcrumbs: [
            {
              text: 'Outlet Settings',
            },
            {
              text: 'Discount Category',
            },
          ],
        },
      },
    },
    {
      path: 'other-payment-types',
      name: 'OtherPaymentTypes',
      components: { default: OtherPaymenTypes },
      meta: {
        title: 'Other Payment Types',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Other Payment Types',
          },
        ],
      },
    },
    {
      path: 'edit-other-payment',
      name: 'OtherPaymentForm',
      components: { default: OtherPaymentForm },
      meta: {
        title: 'Other Payment Types',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Other Payment Types',
          },
        ],
      },
    },
    {
      path: 'Table',
      name: 'Table',
      components: { default: Table },
      meta: {
        title: 'Table',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Tables',
          },
        ],
      },
    },
    {
      path: 'VoidReasons',
      name: 'VoidReasons',
      components: { default: VoidReasons },
      meta: {
        title: 'VoidReasons',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Void Reasons',
          },
        ],
      },
    },
    {
      path: 'AddVoidReasons',
      name: 'AddVoidReasons',
      components: { default: AddVoidReasons },
      meta: {
        title: 'VoidReasons',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Void Reasons',
          },
        ],
      },
    },
    {
      path: 'AddTable',
      name: 'AddTable',
      components: { default: AddTable },
      meta: {
        title: 'Table',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Tables',
          },
          {
            text: 'Table',
          },
        ],
      },
    },
    {
      path: 'table-area',
      name: 'TableAreas',
      components: { default: TableAreas },
      meta: {
        title: 'Table Area',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Tables',
          },
          {
            text: 'Table Area',
          },
        ],
      },
    },
    {
      path: 'TablePosition',
      name: 'TablePosition',
      components: { default: TablePosition },
      meta: {
        title: 'Table Position',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Tables',
          },
          {
            text: 'Table Position',
          },
        ],
      },
    },
    {
      path: 'table-area-form',
      name: 'TableAreaForm',
      components: { default: TableAreaForm },
      meta: {
        title: 'Table Area',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Tables',
          },
          {
            text: 'Table Area',
          },
        ],
      },
    },
    {
      path: 'ManageStaffForm',
      name: 'ManageStaffForm',
      components: { default: ManageStaffForm },
      meta: {
        title: 'Manage Staff',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Staff',
          },
          {
            text: 'Manage Staffs',
          },
        ],
      },
    },
    {
      path: 'staffs',
      name: 'Staffs',
      components: { default: Staffs },
      meta: {
        title: 'Staffs',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Staff',
          },
          {
            text: 'Manage Staffs',
          },
        ],
      },
    },

    {
      path: 'staff-roles',
      name: 'StaffRoles',
      components: { default: StaffRoles },
      meta: {
        title: 'Staff Roles',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Staff',
          },
          {
            text: 'Manage Staff Roles',
          },
        ],
      },
    },

    {
      path: 'manage-staff-role',
      name: 'ManageStaffRole',
      components: { default: ManageStaffRole },
      meta: {
        title: 'Staff Role',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Staff',
          },
          {
            text: 'Manage Staff Roles',
          },
        ],
      },
    },

    {
      path: 'profile',
      name: 'Staff Profile',
      components: { default: Profile },
      meta: {
        title: 'Profiles',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Terminals',
          },
          {
            text: 'Manage Profiles',
          },
        ],
      },
    },

    {
      path: 'manage-profile',
      name: 'ManageProfile',
      components: { default: ManageProfile },
      meta: {
        title: 'Profiles',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Terminals',
          },
          {
            text: 'Manage Profiles',
          },
        ],
      },
    },
    {
      path: 'Terminals',
      name: 'Terminals',
      components: { default: Terminals },
      meta: {
        title: 'Terminals',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Terminals',
          },
        ],
      },
    },
    {
      path: 'TerminalsForm',
      name: 'TerminalsForm',
      components: { default: TerminalsForm },
      meta: {
        title: 'Terminals',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Terminals',
          },
        ],
      },
    },
    {
      path: 'scheduled-report',
      name: 'ScheduledReport',
      component: ScheduledReport,
      meta: {
        title: 'Scheduled Report',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Scheduled Report',
          },
        ],
      },
    },
    {
      path: 'scheduled-report-form',
      name: 'ScheduledReportForm',
      component: ScheduledReportForm,
      meta: {
        title: 'Scheduled Report',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Scheduled Report',
          },
        ],
      },
    },
    {
      path: 'order-types',
      name: 'OrderTypes',
      component: OrderTypes,
      meta: {
        title: 'Order Types',
        breadcrumbs: [
          {
            text: 'Outlet Settings',
          },
          {
            text: 'Order Types',
          },
        ],
      },
    },
  ],
}

let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  name: 'Forms',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      components: { default: RegularForms },
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      components: { default: ExtendedForms },
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      components: { default: ValidationForms },
    },
    {
      path: 'edit',
      name: 'Edit Forms',
      components: { default: EditForm },
      meta: {
        title: 'Clients',
      },
    },
    {
      path: 'category-form',
      name: 'Category Form',
      components: { default: CategoryForm },
      meta: {
        title: 'Super Category',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Super Category',
          },
        ],
      },
    },
    {
      path: 'main-category-form',
      name: 'Main Category Form',
      components: { default: MainCatForm },
      meta: {
        title: 'Main Category',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Main Category',
          },
        ],
      },
    },
    {
      path: 'sub-category-form',
      name: 'Sub Category Form',
      components: { default: SubCatForm },
      meta: {
        title: 'Sub Category',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Sub Category',
          },
        ],
      },
    },
    // {
    //   path: 'menu-section-form',
    //   name: 'Menu Section Form',
    //   components: { default: MenuSectionForm },
    //   meta: {
    //     title: 'Menu Section',
    //     breadcrumbs: [
    //       {
    //         text: 'Products',
    //       },
    //       {
    //         text: 'Menu Section',
    //       },
    //     ],
    //   },
    // },
    {
      path: 'wizard',
      name: 'Wizard',
      components: { default: Wizard },
    },
  ],
}

let tablesMenu = {
  path: '/reports',
  component: DashboardLayout,
  redirect: '/reports/regular',
  name: 'Reports',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      components: { default: RegularTables },
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      components: { default: ExtendedTables },
    },
    {
      path: 'clients',
      name: 'Clients',
      components: { default: PaginatedTables },
      meta: {
        title: 'Clients',
      },
    },
    {
      path: 'sales-summary/hourly-sales',
      name: 'HourlySales',
      components: { default: HourlySales },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Sales Summary',
          },
          {
            text: 'Hourly Sales',
          },
        ],
      },
    },

    {
      path: 'sales-summary/hourly-order',
      name: 'HourlyOrder',
      components: { default: HourlyOrder },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Sales Summary',
          },
          {
            text: 'Hourly Order',
          },
        ],
      },
    },

    {
      path: 'product-report/menuitem-report',
      name: 'MenuItemReport',
      components: { default: MenuItemReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Product Report',
          },
          {
            text: 'Menu Item Report',
          },
        ],
      },
    },
    {
      path: 'product-report/main-category-report',
      name: 'MainCategoryReport',
      components: { default: MainCategoryReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Product Report',
          },
          {
            text: 'Main Category Report',
          },
        ],
      },
    },
    {
      path: 'product-report/super-category-report',
      name: 'SuperCategoryReport',
      components: { default: SuperCategoryReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Product Report',
          },
          {
            text: 'Super Category Report',
          },
        ],
      },
    },
    {
      path: 'product-report/super-category-by-date',
      name: 'SuperCategoryByDate',
      components: { default: SuperCategoryByDate },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Product Report',
          },
          {
            text: 'Super Category By Date',
          },
        ],
      },
    },
    {
      path: 'product-report/combos-report',
      name: 'ComboReport',
      components: { default: ComboReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Product Report',
          },
          {
            text: 'Combo Report',
          },
        ],
      },
    },
    {
      path: 'product-report/sub-category-report',
      name: 'SubCategoryReport',
      components: { default: SubCategoryReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Product Report',
          },
          {
            text: 'Sub Category Report',
          },
        ],
      },
    },
    {
      path: 'product-report/modifier-item-report',
      name: 'ModifiersItemReport',
      components: { default: ModifiersItemReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Product Report',
          },
          {
            text: 'Modifier Item Report',
          },
        ],
      },
    },
    {
      path: 'product-report/modifiers-report',
      name: 'ModifiersReport',
      components: { default: ModifiersReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Product Report',
          },
          {
            text: 'Modifiers Report',
          },
        ],
      },
    },
    {
      path: 'product-report/Sales-gp-report',
      name: 'SalesGpReport',
      components: { default: SalesGpReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Product Report',
          },
          {
            text: 'Sales GP Report',
          },
        ],
      },
    },
    {
      path: 'sales-summary/weekly-sales',
      name: 'WeeklySales',
      components: { default: WeeklySales },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Sales Summary',
          },
          {
            text: 'Weekly Sales',
          },
        ],
      },
    },

    {
      path: 'sales-summary/monthly-sales',
      name: 'MonthlySales',
      components: { default: MonthlySales },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Sales Summary',
          },
          {
            text: 'Monthly Sales',
          },
        ],
      },
    },

    {
      path: 'sales-summary/daily-sales',
      name: 'DailySales',
      components: { default: DailySales },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Sales Summary',
          },
          {
            text: 'Daily Sales',
          },
        ],
      },
    },

    {
      path: 'sales-summary/annual-sales',
      name: 'AnnualSales',
      components: { default: AnnualSales },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Sales Summary',
          },
          {
            text: 'Annual Sales',
          },
        ],
      },
    },

    {
      path: 'transaction-summary/itemised-transactions',
      name: 'ItemisedTransactions',
      components: { default: ItemisedTransactions },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Transaction Summary',
          },
          {
            text: 'Itemised Transaction',
          },
        ],
      },
    },
    {
      path: 'transaction-summary/order-report',
      name: 'OrderReport',
      components: { default: OrderReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Transaction Summary',
          },
          {
            text: 'Order Report',
          },
        ],
      },
    },
    {
      path: 'transaction-summary/hourly-report',
      name: 'HourlyReport',
      components: { default: HourlyReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Transaction Summary',
          },
          {
            text: 'Hourly Report',
          },
        ],
      },
    },
    {
      path: 'transaction-summary/terminal-transactions',
      name: 'TerminalTransactions',
      components: { default: TerminalTransactions },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Transaction Summary',
          },
          {
            text: 'Terminal Transactions',
          },
        ],
      },
    },

    {
      path: 'end-of-day',
      name: 'EndOfDay',
      components: { default: EndOfDay },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'End Of Day',
          },
        ],
      },
    },

    {
      path: 'petty-cash',
      name: 'PettyCash',
      components: { default: PettyCash },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Petty Cash',
          },
        ],
      },
    },

    {
      path: 'power-bi',
      name: 'PowwrBi',
      components: { default: PowerBi },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Petty Cash',
          },
        ],
      },
    },

    {
      path: 'transaction-summary/hourly-summary-report',
      name: 'HourlySummaryReport',
      components: { default: HourlySummaryReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Transaction Summary',
          },
          {
            text: 'Hourly Summary Report',
          },
        ],
      },
    },
    {
      path: 'tax/tax-summary',
      name: 'TaxSummary',
      components: { default: TaxSummary },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Tax',
          },
          {
            text: 'Tax Summary',
          },
        ],
      },
    },
    {
      path: 'tax/detailed-tax-report',
      name: 'DetailedTaxReport',
      components: { default: DetailedTaxReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Tax',
          },
          {
            text: 'Detailed Tax Summary',
          },
        ],
      },
    },
    {
      path: 'tax/tax-report-by-sales-category',
      name: 'TaxReportBySalesCategory',
      components: { default: TaxReportBySalesCategory },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Tax',
          },
          {
            text: 'Tax Report By Sales Category',
          },
        ],
      },
    },
    {
      path: 'staff-sales/all-menu-items',
      name: 'AllMenuItems',
      components: { default: AllMenuItems },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Staff Sales',
          },
          {
            text: 'All Menu Items',
          },
        ],
      },
    },
    {
      path: 'staff-sales/item-categories',
      name: 'ItemCategories',
      components: { default: ItemCategories },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Staff Sales',
          },
          {
            text: 'Item Categories',
          },
        ],
      },
    },
    {
      path: 'staff-sales/revenue',
      name: 'Revenue',
      components: { default: Revenue },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Staff Sales',
          },
          {
            text: 'Revenue',
          },
        ],
      },
    },
    {
      path: 'void/voids-report',
      name: 'VoidsReport',
      components: { default: VoidsReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Void',
          },
          {
            text: 'Voids Report',
          },
        ],
      },
    },
    {
      path: 'void/voids-report-by-staff',
      name: 'VoidsReportByStaff',
      components: { default: VoidsReportByStaff },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Void',
          },
          {
            text: 'Voids Report By Staff',
          },
        ],
      },
    },
    {
      path: 'void/voidreason',
      name: 'VoidsReportByReason',
      components: { default: VoidsReason },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Void',
          },
          {
            text: 'Voids Report By Reason',
          },
        ],
      },
    },
    {
      path: 'wastage/wastage-summary',
      name: 'WastageSummary',
      components: { default: WastageSummary },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Wastage',
          },
          {
            text: 'Wastage Summary',
          },
        ],
      },
    },
    {
      path: 'wastage/wastage-detailed',
      name: 'WastageDetails',
      components: { default: WastageDetails },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Wastage',
          },
          {
            text: 'Wastage Detailed',
          },
        ],
      },
    },

    {
      path: 'discount-category-report',
      name: 'DiscountCategoryReport',
      components: { default: DiscountCategoryReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Discount Category Report',
          },
        ],
      },
    },

    {
      path: 'labour/staff',
      name: 'StaffHoursWorked',
      components: { default: StaffReport },
      meta: {
        title: 'Reports',
        breadcrumbs: [
          {
            text: 'Reports',
          },
          {
            text: 'Labour',
          },
          {
            text: 'Staff Report',
          },
        ],
      },
    },
  ],
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      components: { default: GoogleMaps },
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      components: { default: VectorMaps },
    },
  ],
}

let pagesMenu = {
  path: '/products',
  component: DashboardLayout,
  name: 'Products',
  redirect: '/products/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      components: { default: User },
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      components: { default: TimeLine },
    },
    {
      path: 'categories',
      name: 'Categories',
      component: Categories,
      redirect: '/products/categories/super-category',
      children: [
        {
          path: 'super-category',
          name: 'Super Category',
          components: { default: SuperCategory, helper: SuperCategory }, //helper is the router view name in categories component
          meta: {
            breadcrumbs: [
              {
                text: 'Products',
              },
              {
                text: 'Categories',
              },
              {
                text: 'Super Categories',
              },
            ],
          },
        },
        {
          path: 'main-category',
          name: 'Main Category',
          components: { default: MainCategory, helper: MainCategory },
          meta: {
            title: 'Main Category',
            breadcrumbs: [
              {
                text: 'Products',
              },
              {
                text: 'Categories',
              },
              {
                text: 'Main Categories',
              },
            ],
          },
        },
        {
          path: 'sub-category',
          name: 'Sub Category',
          components: { default: SubCategory, helper: SubCategory },
          meta: {
            title: 'Sub Category',
            breadcrumbs: [
              {
                text: 'Products',
              },
              {
                text: 'Categories',
              },
              {
                text: 'Sub Categories',
              },
            ],
          },
        },

        // {
        //   path: 'menu-section',
        //   name: 'Menu Section',
        //   components: { default: MenuSection, helper: MenuSection },
        //   meta: {
        //     title: 'Menu Section',
        //     breadcrumbs: [
        //       {
        //         text: 'Products',
        //       },
        //       {
        //         text: 'Categories',
        //       },
        //       {
        //         text: 'Menu Section',
        //       },
        //     ],
        //   },
        // },
      ],
    },
    {
      path: 'Modifiers',
      name: 'Modifiers',
      components: { default: Modifiers },
      meta: {
        title: 'Modifiers',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Modifiers',
          },
        ],
      },
    },

    {
      path: 'add-modifier',
      name: 'AddModifier',
      components: { default: ModifierCreateForm },
      meta: {
        title: 'Modifiers',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Modifiers',
          },
        ],
      },
    },

    {
      path: 'add-modifier-group',
      name: 'AddModifierGroup',
      components: { default: ModifierEditForm },
      meta: {
        title: 'Modifiers',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Modifier Groups',
          },
        ],
      },
    },

    {
      path: 'MenuItemsForm',
      name: 'MenuItemsForm',
      components: { default: MenuItemsForm },
      meta: {
        title: 'Menu Items',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Menu Items',
          },
        ],
      },
    },
    {
      path: 'MenuListForm',
      name: 'MenuListForm',
      components: { default: MenuItemListForm },
      meta: {
        title: 'Menu List',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Menu Items',
          },
        ],
      },
    },
    {
      path: 'MenuItemLists',
      name: 'MenuItemLists',
      components: { default: MenuItemLists },
      meta: {
        title: 'Menu List',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Menu Items',
          },
        ],
      },
    },
    {
      path: 'MenuListLinkItem',
      name: 'MenuListLinkItem',
      components: { default: MenuItemListLink },
      meta: {
        title: 'Menu List',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Menu Items',
          },
        ],
      },
    },
    {
      path: 'OutletMenuItemsForm',
      name: 'OutletMenuItemsForm',
      components: { default: OutletMenuItemsForm },
      meta: {
        title: 'Menu Items',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Menu Items',
          },
        ],
      },
    },

    {
      path: 'MenuItem',
      name: 'MenuItem',
      components: { default: MenuItem },
      meta: {
        title: 'Menu Items',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Menu Items',
          },
        ],
      },
    },

    {
      path: 'Combos',
      name: 'Combos',
      components: { default: Combos },
      meta: {
        title: 'Combos',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Combos',
          },
        ],
      },
    },

    {
      path: 'edit-combo-group',
      name: 'CombosGroupForm',
      components: { default: CombosGroupForm },
      meta: {
        title: 'Combo Groups',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Combo Groups',
          },
        ],
      },
    },

    {
      path: 'combo-form',
      name: 'ComboForm',
      components: { default: ComboForm },
      meta: {
        title: 'Combos',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Combos',
          },
        ],
      },
    },
    {
      path: 'portions',
      name: 'portions',
      components: { default: Portions },
      meta: {
        title: 'Portions',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Portions',
          },
        ],
      },
    },
    {
      path: 'AddPortions',
      name: 'AddPortions',
      components: { default: AddPortions },
      meta: {
        title: 'Portions',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Portions',
          },
        ],
      },
    },

    {
      path: 'ipad-grid',
      name: 'iPadGrid',
      components: { default: iPadGrid },
      meta: {
        title: 'iPad',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'iPad Grid',
          },
        ],
      },
    },
    {
      path: 'screen-designer',
      name: 'screenDesign',
      components: { default: ScreenDesign },
      meta: {
        title: 'iPad',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'iPad Grid',
          },
        ],
      },
    },

    {
      path: 'integrations',
      name: 'Integrations Options',
      components: { default: Integrations },
      meta: {
        title: 'Integrations',
        breadcrumbs: [
          {
            text: 'Products',
          },
          {
            text: 'Menu Items',
          },
          {
            text: 'Integrations',
          },
        ],
      },
    },
    ...screenDesignModule.children,
  ],
}

let promotions = {
  path: '/promotions',
  component: DashboardLayout,
  name: 'Promotions',
  children: [
    {
      path: 'vouchers',
      name: 'List Vouchers',
      component: Vouchers,
    },
    {
      path: 'voucher-report',
      name: 'Voucher Report',
      component: VoucherSales,
    },
    {
      path: 'vouchers-audit',
      name: 'Vouchers Audit',
      component: VoucherAudit,
    },
    {
      path: 'import-vouchers',
      name: 'Import Vouchers',
      component: ImportVouchers,
    },
  ],
}

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',

  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/ForgetPassword',
      name: 'ForgetPassword',
      component: ForgetPassword,
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
    },
    {
      path: '/signup',
      name: 'Signup',
      component: SignUp,
    },
    {
      path: '/pricing/:id/:chain?',
      name: 'Pricing',
      component: Pricing,
      meta: {
        requiredAuth: false,
      },
    },
    {
      path: '/pricing-demo/:id',
      name: 'Pricing',
      component: PricingDemo,
      meta: {
        requiredAuth: false,
      },
    },
    {
      path: '/subscription-success',
      name: 'Subscription Success',
      component: SubscriptionSuccess,
      meta: {
        requiredAuth: false,
      },
    },
    {
      path: '/reset_password/:token',
      name: 'Reset Password',
      component: ResetPassword,
    },
    {
      path: '/activate/:token',
      name: 'Welcome',
      component: Welcome,
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock,
    },
  ],
}

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: (to, from, next) => {
      if (sessionStorage.getItem('access_token') == null) {
        next({ path: '/login' })
      } else {
        next({ path: '/dashboard' })
      }
    },
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  UserMenu,
  UserList,
  Dist,
  Settings,
  BusVert,
  OutletsMenu,
  IntegrationsModule,
  promotions,
  onlineOrderingModule,
  cbePayModule,
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
      },
      {
        path: 'calendar',
        name: 'Calendar',
        components: { default: Calendar },
      },
      {
        path: 'charts',
        name: 'Charts',
        components: { default: Charts },
      },
      {
        path: 'widgets',
        name: 'Widgets',
        components: { default: Widgets },
      },

      {
        path: 'accounts',
        name: 'Accounts',
        components: { default: Accounts },
      },
    ],
  },
]

export default routes
