<template>
  <form @submit.prevent class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <h3>Bank of Ireland</h3>
        </div>
        <div class="md-layout-item md-size-30"></div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('BName') },
                { 'md-valid': !errors.has('BName') },
              ]"
            >
              <label>{{ $t('Business Name') }}*</label>
              <md-input
                type="text"
                v-model.lazy="data.BusinessName"
                v-validate="'required'"
                data-vv-name="BName"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('BName')">
                  {{ errors.first('BName') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('BName') && data.BusinessName != ''"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('CName') },
                { 'md-valid': !errors.has('CName') },
              ]"
            >
              <label>{{ $t('Contact Name') }}*</label>
              <md-input
                type="text"
                v-model.lazy="data.ContactName"
                v-validate="'required'"
                data-vv-name="CName"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('CName')">
                  {{ errors.first('CName') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('CName') && data.ContactName != ''"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Address') },
                { 'md-valid': !errors.has('Address') },
              ]"
            >
              <label>{{ $t('Address') }}*</label>
              <md-input
                type="text"
                v-model.lazy="data.Address"
                v-validate="'required'"
                data-vv-name="Address"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Address')">
                  {{ errors.first('Address') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Address') && data.Address != ''"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('City') },
                { 'md-valid': !errors.has('City') },
              ]"
            >
              <label>{{ $t('City') }}*</label>
              <md-input
                type="text"
                v-model.lazy="data.City"
                v-validate="'required'"
                data-vv-name="City"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('City')">
                  {{ errors.first('City') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('City') && data.City != ''"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('State') },
                { 'md-valid': !errors.has('State') },
              ]"
            >
              <label>{{ $t('State') }}*</label>
              <md-input
                type="text"
                v-model.lazy="data.State"
                v-validate="'required'"
                data-vv-name="State"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('State')">
                  {{ errors.first('State') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('State') && data.State != ''"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('ZipCode') }}*</label>
              <md-input
                type="text"
                data-vv-name="ZipCode"
                v-model.lazy="data.ZipCode"
              ></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Email') },
                { 'md-valid': !errors.has('Email') },
              ]"
            >
              <label>{{ $t('Email') }}*</label>
              <md-input
                type="text"
                v-model.lazy="data.Email"
                v-validate="'required|email'"
                data-vv-name="Email"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Email')">
                  {{ errors.first('Email') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Email') && data.Email != ''"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Phone') },
                { 'md-valid': !errors.has('Phone') },
              ]"
            >
              <label>{{ $t('Phone') }}*</label>
              <md-input
                type="text"
                v-model.lazy="data.Phone"
                v-validate="{
                  required: true,
                  regex: '^[0-9\-\+]+$',
                  min: 9,
                  max: 15,
                }"
                data-vv-name="Phone"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Phone')">
                  {{ errors.first('Phone') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Phone') && data.Phone != null"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('ResellerName') },
                { 'md-valid': !errors.has('ResellerName') },
              ]"
            >
              <label>{{ $t('Reseller Name') }}*</label>
              <md-input
                type="text"
                v-model.lazy="data.ResellerName"
                v-validate="'required'"
                data-vv-name="ResellerName"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('ResellerName')">
                  {{ errors.first('ResellerName') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="
                    !errors.has('ResellerName') && data.ResellerName != ''
                  "
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('ReferenceID') },
                { 'md-valid': !errors.has('ReferenceID') },
              ]"
            >
              <label>{{ $t('Reference ID') }}*</label>
              <md-input
                type="text"
                v-model.lazy="data.ReferenceID"
                v-validate="'required'"
                data-vv-name="ReferenceID"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('ReferenceID')">
                  {{ errors.first('ReferenceID') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('ReferenceID') && data.ReferenceID != ''"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout-item" style="margin-top: 17px">
          <md-button
            class="md-mini"
            @click="addTerminal()"
            style="margin: auto"
          >
            <md-icon>add</md-icon>
          </md-button>
        </div>
        <div class="md-layout" style="margin-top: 10px">
          <div
            class="md-layout-item md-size-60"
            v-for="(item, k) in terminalSerialNumber"
            :key="k"
          >
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field
                  :class="[
                    {
                      'md-error':
                        errors.has('TerminalSerial') &&
                        item.TerminalSerialNumber == '',
                    },
                    {
                      'md-valid':
                        !errors.has('TerminalSerial') &&
                        !item.TerminalSerialNumber == '',
                    },
                  ]"
                >
                  <label>{{ $t('Terminal Serial Number') }}*</label>
                  <md-input
                    type="text"
                    v-model.lazy="item.TerminalSerialNumber"
                    v-validate="'required'"
                    data-vv-name="TerminalSerial"
                  ></md-input>
                  <slide-y-down-transition>
                    <div
                      class="error"
                      v-show="
                        errors.has('TerminalSerial') &&
                        item.TerminalSerialNumber == ''
                      "
                    >
                      {{ errors.first('TerminalSerial') }}
                    </div>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('TerminalSerial') &&
                        item.TerminalSerialNumber != ''
                      "
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20" style="margin-top: 12.5px">
                <md-button class="md-mini" @click="handleSubmit(item)">
                  {{
                    item.Status && item.Status == 'Success' ? 'Un Pair' : 'Pair'
                  }}
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
      <!-- <div
        style="margin: 20px 0;"
        class="btn-div md-layout-item md-size-60 md-alignment-center-right"
      >
        <md-button
          type="button"
          @click="handleSubmit()"
          style="float: right;"
          class="md-primary"
          >Update</md-button
        >
      </div> -->
    </md-card>
  </form>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions'
import axios from 'axios'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'

export default {
  components: {
    SlideYDownTransition,
  },
  data() {
    return {
      data: {
        BusinessName: '',
        ContactName: '',
        Address: '',
        City: '',
        State: '',
        ZipCode: null,
        Email: '',
        Phone: null,
        ResellerName: '',
        ReferenceID: '',
        TerminalSerialNumber: '',
      },
      terminalSerialNumber: [{ TerminalSerialNumber: '' }],
      params: '',
    }
  },
  methods: {
    addTerminal() {
      this.terminalSerialNumber.push({ TerminalSerialNumber: '' })
    },
    removeTerminal(index) {
      this.terminalSerialNumber.splice(index, 1)
      this.saveData(this.data)
    },
    handleSubmit(item) {
      this.$validator.validate().then((valid) => {
        if (valid && item.Status && item.Status === 'Success') {
          this.unpairTerminal(item)
        } else {
          this.pairTerminal(item)
        }
      })
    },
    pairTerminal(item) {
      this.data.TerminalSerialNumber = item.TerminalSerialNumber
      // this.data.TerminalSerialNumber = this.terminalSerialNumber
      //   .map((item) => {
      //     return item.TerminalSerialNumber
      //   })
      //   .toString()
      // console.log('request params>>>', this.data.TerminalSerialNumber)

      axios
        .get('https://econduitapp.com/services/api.asmx/pairTerminal', {
          params: this.data,
        })
        .then((res) => {
          let result = res.data
          result['TerminalSerialNumber'] = item.TerminalSerialNumber
          let terminalIndex = this.terminalSerialNumber.findIndex(
            (element) =>
              element.TerminalSerialNumber == item.TerminalSerialNumber
          )
          if (terminalIndex != -1) {
            this.terminalSerialNumber[terminalIndex] = result
          } else {
            this.terminalSerialNumber.push(result)
          }
          if (result.Status == 'Success') {
            this.saveData(this.data)
          }
        })
        .catch((error) => console.log(error))
    },

    unpairTerminal(item) {
      let url = `https://econduitapp.com/services/api.asmx/unPairTerminal?key=${item.ApiKey}&password=${item.ApiPassword}&terminalId=${item.TerminalID}`
      axios
        .get(url)
        .then((res) => {
          let terminalIndex = this.terminalSerialNumber.findIndex(
            (element) =>
              element.TerminalSerialNumber == item.TerminalSerialNumber
          )
          this.removeTerminal(terminalIndex)
        })
        .catch((error) => console.log(error))
    },
    saveData(params) {
      let closure = {
        outlets: {
          outlet: {
            boi_json: {
              ...params,
              ...{ terminals: this.terminalSerialNumber },
            },
          },
        },
      }
      let outlet_id = accountService.getOutletId()
      customizeService
        .editOutlet(outlet_id, closure)
        .then((res) => console.log('SaveDAta successful!>>>', res))
    },
  },
  created() {
    let outlet_id = accountService.getOutletId()
    customizeService.getOutletById(outlet_id).then((res) => {
      if (res.data.outlet.boi_json) {
        this.data = res.data.outlet.boi_json
        this.terminalSerialNumber = res.data.outlet.boi_json.terminals
      }
    })
  },
}
</script>

<style scoped>
.error {
  color: red;
}
</style>
