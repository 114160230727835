import Swal from 'sweetalert2'
import $ from 'jquery'
const messages = {
  '': '',
  screenMesages:
    'This is a default menu on an assigned outlet. This menu will be removed from the outlet. Please select another default',
}

export const reusableService = {
  showSwal,
}

function showSwal(type, key = '') {
  if (type === 'Add' || type === 'Edit') {
    return Swal.fire({
      text: `Are you sure you want to save changes`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'md-button md-success',
      cancelButtonClass: 'md-button md-danger',
      confirmButtonText: 'Save',
      buttonsStyling: false,
      reverseButtons: true,
    })
  } else if (type === 'Delete') {
    return Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure? You won't be able to revert this!`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'md-button md-success btn-fill',
      cancelButtonClass: 'md-button md-danger btn-fill',
      confirmButtonText: 'Yes, delete it!',
      buttonsStyling: false,
      reverseButtons: true,
    })
  } else if (type == 'Update') {
    return Swal.fire({
      text: 'Saved successfully',
      type: 'success',
    })
  } else if (type === 'Custom') {
    return Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to save changes? ${messages[key]}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'md-button md-success btn-fill',
      cancelButtonClass: 'md-button md-danger btn-fill',
      confirmButtonText: 'Yes, Save it!',
      buttonsStyling: false,
      reverseButtons: true,
    })
  }
}
