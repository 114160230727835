<template>
  <div class="subcat-report">
    <md-card class="report-card">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 report-name">
          <span class="arrow_left">
            <router-link :to="{ path: '/promotions/voucher-report' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Voucher Audit') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div
            class="md-layout-item md-size-100 card-inside report-table voucher-report-table"
          >
            <div class="loader-container" v-if="loader">
              <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
            </div>

            <md-table v-if="!loader" class="table-typ1">
              <!-- <md-table-toolbar>
              <div class="md-toolbar-section-start"></div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable" />
              </md-field>
            </md-table-toolbar>-->
              <md-table-toolbar>
                <div class="action-buttons export-btn">
                  <div class="pdf-el">
                    <div class="download-btn">
                      <img
                        src="../../../assets/images/pdf.svg"
                        @click="downloadWithoutCSS()"
                      />
                    </div>
                  </div>

                  <div class="excel-el">
                    <vue-excel-xlsx
                      class="Excel-btn"
                      :data="reportApiRes"
                      :columns="columns"
                      :filename="'Voids Report'"
                      :sheetname="'Voids Report'"
                      :outlet-names="selectedOutletsNames()"
                    >
                    </vue-excel-xlsx>
                  </div>
                </div>
              </md-table-toolbar>
              <md-table-row>
                <md-table-head>
                  <div class="date-text">Date</div>
                  <div class="voucher-text">Voucher</div></md-table-head
                >
                <md-table-head>+</md-table-head>
                <md-table-head>-</md-table-head>
                <md-table-head>Current Balance</md-table-head>
                <md-table-head>Bill No.</md-table-head>
                <md-table-head>Outlet</md-table-head>
              </md-table-row>
              <slot v-for="item in reportApiRes">
                <md-table-row slot="md-table-row" v-if="!item.bill_no">
                  <md-table-cell class="text-left-align"
                    ><b>{{ item.voucher_no }}</b></md-table-cell
                  >
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                </md-table-row>
                <md-table-row slot="md-table-row" v-if="item.bill_no">
                  <md-table-cell>{{ item.voucher_date }}</md-table-cell>
                  <md-table-cell>{{
                    !item.is_redeem ? item.amount : ''
                  }}</md-table-cell>
                  <md-table-cell>{{
                    item.is_redeem ? item.amount : ''
                  }}</md-table-cell>
                  <md-table-cell>{{
                    item.is_redeem
                      ? (item.prev_amount - item.amount).toFixed(2)
                      : (item.prev_amount + item.amount).toFixed(2)
                  }}</md-table-cell>
                  <md-table-cell>
                    <a
                      @click="viewBill(item.id, item.outlet_id)"
                      v-if="item.bill_no != '-1'"
                      >{{ item.bill_no }}</a
                    >
                  </md-table-cell>

                  <md-table-cell>{{ item.outlet_name }}</md-table-cell>
                </md-table-row>
              </slot>
            </md-table>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-dialog
      class="itemised-trans-bill bill-container"
      :md-active.sync="showBill"
    >
      <div id="bill-detail">
        <itemised-bill
          :billDetails="billDetails"
          @downloadBill="downloadBill"
        ></itemised-bill>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { accountService } from '../../../services/account.js'
import { reportService } from './../../../services/reports'
import { customizeService } from './../../../services/customize'
import { pdfService } from '../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import DatePeriodDropdown from '../../../components/DatePeriodDropdown'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../components/VueExcelXlsx'
import ItemisedBill from '../Reports/TransactionSummary/ItemisedBill.vue'

export default {
  components: {
    ChartCard,
    DatePeriodDropdown,
    ClipLoader,
    VueExcelXlsx,
    ItemisedBill,
  },
  data() {
    return {
      showBill: false,
      billDetails: {
        data: [],
        tax: [],
      },
      columns: [
        {
          label: 'Voucher Number',
          field: 'voucher_no',
        },
        {
          label: 'Voucher Date',
          field: 'voucher_date',
        },
        {
          label: 'Qty',
          field: 'prev_amount',
        },
        {
          label: 'Bill No',
          field: 'bill_no',
        },
      ],

      data: {
        search_type: 'Daily',
      },

      reportApiRes: [],
      terminalsList: [],
      outletList: [],
      outletId: [],
      loader: false,
    }
  },

  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
    },
    viewBill(billNo, outId = '') {
      let outletId
      if (this.userRole != 'branch_user') {
        outletId = accountService.getOutletId()
      } else {
        outletId = outId
      }
      reportService.viewItemTransBill(billNo, outletId).then((res) => {
        // this.billDetails = res.data.result[0]
        // console.log('Billdetails:', this.billDetails)
        // this.showBill = true

        let result = res.data.result
        let items = res.data.result.bill.items.filter(
          (item) => item.combo_id === null
        )
        result.bill.items = items
        this.billDetails = result
        this.showBill = true
      })
    },
    downloadBill(billNo) {
      html2canvas(document.querySelector('#bill-detail'))
        .then((canvas) => {
          document.body.appendChild(canvas)
        })
        .then(() => {
          var canvas = document.querySelector('canvas')
          canvas.style.display = 'none'
          var image = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
          var a = document.createElement('a')
          a.setAttribute('download', billNo + '.png')
          a.setAttribute('href', image)
          a.click()
          a.remove()
        })
    },
    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
      }
      if (!this.loader) {
        this.voucherAuditReport()
      }
    },

    arrangeForPdf() {
      var body = []
      this.reportApiRes.forEach((el) => {
        let arrayObj = [
          el.voucher_no,
          el.voucher_date,
          el.prev_amount,
          el.bill_no,
        ]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })

      let head = [
        ['Voucher Number', 'Voucher Date', 'Previous Amount', 'Bill Number'],
      ]
      this.data.startDate = ''
      this.data.endDate = ''
      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Voucher Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'voucher-report.pdf')
    },
    voucherAuditReport() {
      this.loader = true
      this.data.id = this.$route.query.client
      this.data.outletId = this.outletId
      reportService.getvoucherAuditReport(this.data).then((res) => {
        this.loader = false
        this.reportApiRes = res.data.result
      })
    },
    role() {
      return accountService.userRole()
    },
    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminalsList = res.data
        this.terminalsList.unshift({ id: 'undefined', name: 'All' })
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.voucherAuditReport()
      })
    },
  },

  created() {
    this.searched = this.users
    this.loader = true
    // this.getTerminals()
    this.getOutlets()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}
.md-table.md-theme-default .md-table-content {
  display: none;
}
.pd-l-0 {
  padding-left: 0px;
}

.md-button-content {
  font-size: 12px;
}
.menu-item-rep .md-ripple {
  padding: 6px 15px;
  height: auto !important;
  border-radius: 0px;
}
.content .menu-item-rep .md-ripple {
  padding: 0px !important;
}
.card-inside {
  .md-button {
    .md-ripple {
      padding: 0px 0px;
    }
  }
  .md-content {
    width: 100%;
  }
}
.rep-table {
  margin-top: 0px;
}
.terminal-cls {
  padding-left: 0px;
  padding-right: 22px;
}
.mt-13 {
  margin-top: 13px;
}

.report-table tr:last-child td {
  font-weight: normal;
}
.date-text {
  text-align: right;
}
.voucher-text {
  text-align: left !important;
  display: flex;
}
.voucher-report-table .md-table-head-label {
  font-size: 16px !important;
}
</style>
<style>
.voucher-report-table .md-table-head-label {
  font-size: 16px !important;
  height: auto;
  display: block;
  padding-left: 10px;
}
.text-left-align {
  text-align: left !important;
  display: flex;
}
</style>
