<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <!-- <router-link :to="{path:'/Settings/Modules'}">
              <img src="../../../assets/images/left.svg" />
            </router-link> -->
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div class="md-layout-item md-size-30">
          <!-- <i
            class="md-icon md-icon-font md-theme-default edit-img"
           
          >add</i> -->
          <!-- <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editModule"
            v-else-if="formType=='View Module'"
          >edit</i> -->
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field
              :class="[
                { 'md-error': errors.has('TaxScheme') },
                { 'md-valid': !errors.has('TaxScheme') && touched.TaxScheme },
              ]"
            >
              <label>Tax Scheme</label>
              <md-input
                v-model="scheme"
                data-vv-name="TaxScheme"
                type="text"
                v-validate="modelValidations.TaxScheme"
              ></md-input>
              <!-- :disabled="formType == 'View Module'" -->
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('TaxScheme')">
                  {{ errors.first('TaxScheme') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('TaxScheme') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <h4>
              <img src="../../../assets/images/plus.svg" @click="addTaxRate" />
              Tax Rate
            </h4>
          </div>
        </div>
        <div class="md-layout" v-for="(item, index) in tax" :key="index">
          <div class="md-layout-item md-size-90">
            <md-field>
              <label>Tax Rate {{ index + 1 }} (%)</label>
              <md-input
                type="text"
                v-model="tax[index]"
                :disabled="formType == 'View Module'"
                data-vv-name="TaxScheme"
              ></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-10"
            v-if="formType != 'View Module'"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="removeSubModule(index, item.sub_module_id)"
              >close</i
            >
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType != 'View Module'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/Settings/Modules' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              @click="validate"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>

      <!--======================================= Edit Module Form ========================================-->

      <!--================================ View Module Form ===========================================-->
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
export default {
  components: {
    SlideYDownTransition,
  },
  props: {},
  data() {
    return {
      scheme: '',
      taxRate: [],
      touched: {
        TaxScheme: false,
      },
      modelValidations: {
        TaxScheme: {
          required: true,
        },
      },

      formType: {
        type: String,
        default: 'AddModules',
      },

      tax: [],
      taxDetails: {},
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit('on-submit', this.alertModule('Add'), isValid)
        }
      })
    },

    addTaxRate() {
      this.tax.push('')
      // this.modelValidations.push({"teamId":{"status":"pending"}})
    },

    removeSubModule(index, id) {
      this.tax.splice(index, 1)
    },

    alertModule(value) {
      var self = this
      // if (this.formType == "Add Module") {
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.createTax()
        }
      })
      // } else if (this.formType == "Edit Module") {
      //   reusableService.showSwal(value).then(result => {
      //     if (result.value) {
      //       self.submitEditModule();
      //     }
      //   });
      // }
    },
    createTax() {
      this.taxDetails.scheme = this.scheme
      this.taxDetails.taxes = this.tax
      if (accountService.userRole() == 'branch_user') {
        this.taxDetails.type = 'Business Owner'
      } else {
        this.taxDetails.type = 'Distributor'
      }

      customizeService
        .taxes(this.taxDetails)
        .then((res) => {})
        .catch((err) => {
          console.log('error', err)
        })
    },

    editModule() {
      this.formType = 'Edit Module'
    },

    submitEditModule() {
      // customizeService.editmodule(this.bulkModule, this.moduleId).then(res => {
      //   console.log("Put response", res);
      //   this.$router.push({ name: "Modules" });
      // });
    },

    resetModules() {
      this.tax = []
    },
  },
  created() {
    this.formType = this.$route.params.formType
    let self = this
    if (this.tax.length === 0) {
      this.tax.push('')
    }
    customizeService
      .getTaxes()
      .then((res) => {
        console.log('tax response', res)
        this.tax = res.data.result[0].tax.taxes
        this.scheme = res.data.result[0].tax.scheme
      })
      .catch((err) => {
        console.log('error', err)
      })
  },
  watch: {
    TaxScheme() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
// .btn-div {
//   // border-top: 1px solid gray;
// }

.md-list {
  .md-list-item {
    // &:first-child {
    //   .md-list-item-button {
    //   }
    // }
    .md-list-item-text {
      padding-left: 30px;
    }
  }
}
.md-card img {
  width: 16px;
  height: auto;
}
</style>
