<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="role-card block-bg">
        <!-- <md-card-header>
           <span class="arrow_left">
            <router-link :to="{path:'/business-vertical/Outlet'}">
              <md-icon class="material-icons">keyboard_arrow_left</md-icon>
            </router-link>
          </span>
          <h4 class="title">Business Details</h4>
        </md-card-header> -->

        <md-card-header
          class="md-card-header-text md-card-header-green md-layout md-gutter"
        >
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <router-link :to="{ path: '/Settings/roles' }">
                <img src="../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h3>{{ $t('Role') }}</h3>
          </div>
          <div
            class="md-layout-item md-size-30"
            v-if="formType == 'View Role' && !is_manager"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="editRole"
              >edit</i
            >
          </div>
        </md-card-header>
        <div class="card-content-align">
          <md-card-content>
            <div>
              <md-field>
                <label>{{ $t('Role Name') }}</label>
                <md-input
                  type="text"
                  v-model="user_package.user_roles.name"
                  data-vv-name="RoleName"
                  v-validate="modelValidations.RoleName"
                  :disabled="formType == 'View Role'"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('RoleName')">
                    {{ errors.first('RoleName') }}
                  </div>
                </slide-y-down-transition>
              </md-field>
              <!-- <md-field>
              <label>{{$t('Role Access')}}</label>
              <md-select>
                <md-option>Read</md-option>
                <md-option>Write</md-option>
              </md-select>
            </md-field> -->
            </div>
          </md-card-content>
        </div>

        <div class="package-settings pad-l-15px pad-r-15px">
          <md-card-header>
            <h4 class="title">{{ $t('Package Settings') }}</h4>
          </md-card-header>
          <md-card-content>
            <!-- <div class="md-layout">
              <div class="md-layout-item">
                <md-radio v-model="assignModule" :value = false>Assign all modules</md-radio>
                <md-radio v-model="assignModule" :value= true>Custom assign modules</md-radio>
              </div>
            </div> -->
            <div class="roles-table table-striped">
              <md-table>
                <md-table-row>
                  <md-table-head>Main Module</md-table-head>
                  <md-table-head>Sub Module</md-table-head>
                  <md-table-head v-if="!is_manager">Access</md-table-head>
                  <md-table-head v-if="!is_manager"></md-table-head>
                </md-table-row>

                <!-- <slot>
                  <slot v-for="(item) in outlet_package.package_modules">
                    <md-table-row slot="md-table-row">
                      <md-table-cell>{{ item.name }}</md-table-cell>
                      <md-table-cell></md-table-cell>
                    </md-table-row>
                    <slot v-if="item.app_sub_module!==0">
                      <slot v-for="sub in item.app_sub_module">
                        <md-table-row slot="md-table-row">
                          <md-table-cell></md-table-cell>
                          <md-table-cell>{{sub.name}}</md-table-cell>
                        </md-table-row>
                      </slot>
                    </slot>
                  </slot>
                </slot> -->

                <slot>
                  <h4 class="add-module" v-if="!is_manager">
                    <img
                      src="../../../assets/images/plus.svg"
                      @click="showSubDialog = true"
                      class="cursor-pointer"
                    />
                    <span class="text-dark pad-l-15px">{{
                      $t('Modules')
                    }}</span>
                  </h4>
                  <slot v-for="item in custom_package">
                    <md-table-row slot="md-table-row">
                      <md-table-cell>{{ item.name }}</md-table-cell>
                      <md-table-cell></md-table-cell>
                      <md-table-cell v-if="!is_manager">
                        <slot
                          v-if="
                            item.app_sub_module == null ||
                            item.app_sub_module.length == 0
                          "
                        >
                          <md-radio v-model="item.read_write" value="0"
                            >Read</md-radio
                          ><br />
                          <md-radio v-model="item.read_write" value="1"
                            >Write</md-radio
                          >
                        </slot>
                      </md-table-cell>
                      <md-table-cell v-if="!is_manager">
                        <img
                          :class="[
                            { 'disable-class': formType == 'View Package' },
                            { 'active-disabled': formType !== 'View Package' },
                          ]"
                          @click="removeModule(item.id)"
                          src="../../../assets/images/delete.svg"
                        />
                      </md-table-cell>
                    </md-table-row>
                    <slot v-if="item.app_sub_module !== 0">
                      <slot v-for="sub in item.app_sub_module">
                        <md-table-row slot="md-table-row">
                          <md-table-cell></md-table-cell>
                          <md-table-cell
                            >{{ sub.name }}
                            <slot v-for="nested in sub.nested_modules">
                              <div class="nested" :key="nested.path">
                                {{ nested.name }}
                              </div>
                            </slot>
                          </md-table-cell>
                          <md-table-cell v-if="!is_manager">
                            <md-radio v-model="sub.read_write" value="0"
                              >Read</md-radio
                            ><br />
                            <md-radio v-model="sub.read_write" value="1"
                              >Write</md-radio
                            >
                          </md-table-cell>
                          <md-table-cell v-if="!is_manager">
                            <img
                              :class="[
                                { 'disable-class': formType == 'View Package' },
                                {
                                  'active-disabled':
                                    formType !== 'View Package',
                                },
                              ]"
                              @click="
                                removeSubModule(sub.sub_module_id, item.id)
                              "
                              src="../../../assets/images/delete.svg"
                            />
                          </md-table-cell>
                        </md-table-row>
                      </slot>
                    </slot>
                  </slot>
                </slot>
              </md-table>
            </div>

            <div class="btn-div" v-if="!is_manager">
              <md-card-actions
                class="text-center"
                v-if="formType != 'View Outlet'"
              >
                <md-button
                  native-type="submit"
                  :to="{ path: '/Settings/roles' }"
                  >{{ $t('CANCEL') }}</md-button
                >
                <md-button
                  native-type="submit"
                  class="md-primary"
                  @click.native.prevent="validate"
                  >{{ $t('SAVE') }}</md-button
                >
              </md-card-actions>
            </div>
          </md-card-content>
        </div>
      </md-card>
    </div>

    <!-- ==================================Dialog Box================================== -->

    <md-dialog :md-active.sync="showSubDialog" class="custom-role-package">
      <md-dialog-title class="md-layout">
        <div class="md-layout-item">Modules</div>
        <div class="close-icon">
          <i
            class="md-icon md-icon-font md-theme-default cursor-pointer"
            @click="showSubDialog = false"
            >close</i
          >
        </div>
      </md-dialog-title>
      <div class="list">
        <md-list>
          <slot v-for="item in outlet_package.package_modules">
            <md-list-item md-expand v-if="item.app_sub_module !== null">
              <div class="module-detail">
                <div class="md-list-item-text module-name">{{ item.name }}</div>
                <md-checkbox
                  :model="array"
                  :value="item.id"
                  :true-value="item.id"
                  @change="moduleCheckbox(item.id)"
                ></md-checkbox>
              </div>

              <md-list slot="md-expand">
                <md-list-item
                  class="md-inset"
                  v-for="(sub, index) in item.app_sub_module"
                  :key="index"
                >
                  <span>{{ sub.name }}</span>

                  <md-checkbox
                    :model="subarray"
                    :value="sub.sub_module_id"
                    :true-value="sub.sub_module_id"
                    @change="subModuleCheckbox(item.id, sub.sub_module_id)"
                  ></md-checkbox>

                  <slot v-if="sub.nested_modules != null">
                    <div class="md-layout nested-modules">
                      <div
                        class="nested md-layout-item md-size-100"
                        v-for="nested in sub.nested_modules"
                        :key="nested.path"
                      >
                        {{ nested.name }}
                      </div>
                    </div>
                  </slot>
                </md-list-item>
              </md-list>
            </md-list-item>
            <md-list-item v-else>
              <div class="module-detail">
                <div class="md-list-item-text module-name">{{ item.name }}</div>
                <md-checkbox
                  :model="array"
                  :value="item.id"
                  :true-value="item.id"
                  @change="moduleCheckbox(item.id, item.app_sub_module)"
                ></md-checkbox>
              </div>
            </md-list-item>
          </slot>
        </md-list>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { Tabs } from '@/components'
import { SlideYDownTransition } from 'vue2-transitions'
import { Collapse } from '@/components'
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
export default {
  components: {
    Tabs,
    Collapse,
    SlideYDownTransition,
  },

  data() {
    return {
      modelValidations: {
        RoleName: {
          required: true,
        },
      },

      formType: {
        type: String,
        default: 'Add Role',
      },

      roleId: '',
      roleName: '',
      packageId: accountService.userPackage(),
      outlet_package: [],
      custom_package: [],
      showSubDialog: false,
      // assignModule: false,
      array: [],
      subarray: [],
      user_package: {
        user_roles: {},
        app_packages: {},
      },
      is_manager: '',
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    submitOutlet() {
      this.user_package.user_roles.user_id = accountService.getUserId()
      this.user_package.app_packages = {
        package_id: accountService.userPackage(),
        app_modules: this.custom_package,
      }

      if (this.formType == 'Add Role') {
        customizeService.createRole(this.user_package).then((res) => {
          this.$router.push({ name: 'Roles' })
        })
      } else {
        customizeService
          .editRole(this.roleId, this.user_package)
          .then((res) => {
            this.$router.push({ name: 'Roles' })
          })
      }
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitOutlet()
          }
        })
      }
    },

    resetOutlet() {},

    editRole() {
      this.formType = 'Edit Role'
    },

    moduleCheckbox(id) {
      let moduleIndex = this.outlet_package.package_modules.findIndex(
        (el) => el.id == id
      )
      if (this.array.includes(id)) {
        if (
          this.outlet_package.package_modules[moduleIndex].app_sub_module !=
          null
        ) {
          this.outlet_package.package_modules[
            moduleIndex
          ].app_sub_module.forEach((el) => {
            this.subarray.push(el.sub_module_id)
          })
        }
        this.custom_package.push(
          this.outlet_package.package_modules[moduleIndex]
        )
      } else {
        if (
          this.outlet_package.package_modules[moduleIndex].app_sub_module !=
          null
        ) {
          this.outlet_package.package_modules[
            moduleIndex
          ].app_sub_module.forEach((el) => {
            let subModuleIndex = this.subarray.findIndex(
              (subEl) => subEl == el.sub_module_id
            )
            this.subarray.splice(subModuleIndex, 1)
          })
        }

        let customIndex = this.custom_package.findIndex((el) => el.id == id)
        if (customIndex != -1) {
          this.custom_package.splice(customIndex, 1)
        }
      }
    },

    subModuleCheckbox(moduleId, subModuleId) {
      let moduleIndex = this.outlet_package.package_modules.findIndex(
        (el) => el.id == moduleId
      )
      let subModuleIndex = this.outlet_package.package_modules[
        moduleIndex
      ].app_sub_module.findIndex((el) => el.sub_module_id == subModuleId)

      let customIndex = this.custom_package.findIndex((el) => el.id == moduleId)
      let customSubIndex = this.custom_package[
        customIndex
      ].app_sub_module.findIndex((el) => el.sub_module_id == subModuleId)

      if (
        this.subarray.includes(subModuleId) &&
        !this.array.includes(moduleId)
      ) {
        this.array.push(moduleId)
        this.custom_package.push({
          name: this.outlet_package.package_modules[moduleIndex].name,
          id: this.outlet_package.package_modules[moduleIndex].id,
          module_path:
            this.outlet_package.package_modules[moduleIndex].module_path,
          app_sub_module: [
            this.outlet_package.package_modules[moduleIndex].app_sub_module[
              subModuleIndex
            ],
          ],
        })
      } else if (!this.subarray.includes(subModuleId)) {
        this.custom_package[customIndex].app_sub_module.splice(
          customSubIndex,
          1
        )
      } else {
        this.custom_package[customIndex].app_sub_module.push(
          this.outlet_package.package_modules[moduleIndex].app_sub_module[
            subModuleIndex
          ]
        )
      }
    },

    removeModule(id) {
      let moduleIndex = this.custom_package.findIndex((el) => el.id == id)
      if (this.custom_package[moduleIndex].app_sub_module != null) {
        this.custom_package[moduleIndex].app_sub_module.forEach((el) => {
          let subArrayIndex = this.subarray.findIndex(
            (arr) => arr == el.sub_module_id
          )
          this.subarray.splice(subArrayIndex, 1)
        })
      }
      this.custom_package.splice(moduleIndex, 1)
      let arrayIndex = this.array.findIndex((el) => el == id)
      this.array.splice(arrayIndex, 1)
    },

    removeSubModule(subModuleId, moduleId) {
      let moduleIndex = this.custom_package.findIndex((el) => el.id == moduleId)
      let subArrayIndex = this.subarray.findIndex((arr) => arr == subModuleId)
      this.subarray.splice(subArrayIndex, 1)
      let subModuleIndex = this.custom_package[
        moduleIndex
      ].app_sub_module.findIndex((el) => el.sub_module_id == subModuleId)
      this.custom_package[moduleIndex].app_sub_module.splice(subModuleIndex, 1)
    },

    assignCustomPackage(res) {
      this.custom_package = JSON.parse(JSON.stringify(res.package_modules))
      this.custom_package.forEach((el) => {
        this.array.push(el.id)
        if (el.app_sub_module != null) {
          el.app_sub_module.forEach((sub) => {
            this.subarray.push(sub.sub_module_id)
            if (this.formType == 'Add Role') {
              sub.read_write = 1
            }
          })
        } else {
          if (this.formType == 'Add Role') {
            el.read_write = 1
          }
        }
      })
    },
  },
  created() {
    this.formType = this.$route.params.formType

    customizeService.getPackagebyId(this.packageId).then((res) => {
      this.outlet_package = res.data

      if (this.formType == 'Add Role') {
        this.assignCustomPackage(res.data)
      } else {
        this.roleId = this.$route.params.client.id
        this.roleName = this.$route.params.client.name
        this.is_manager = this.$route.params.client.is_manager

        this.user_package.user_roles.name = this.roleName
        if (!this.is_manager) {
          customizeService.getPackageUsingRole(this.roleId).then((res) => {
            this.assignCustomPackage(res.data)
          })
        } else {
          customizeService
            .getPackagebyId(accountService.userPackage())
            .then((res) => {
              this.assignCustomPackage(res.data)
            })
        }
      }
    })
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.package-name {
  margin-top: 8%;
}

.text-center {
  justify-content: center !important;
}
.btn-add-module {
  text-align: center;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.md-card .md-card-actions {
  border: none;
}
.file_Label {
  top: -0.8rem;
  font-size: 0.6875rem;
}
.edit-img {
  padding: 32px;
  float: right;
}
.card-content-align {
  padding: 0px 18px;
}

.md-card img {
  width: 19px;
}

.module-detail {
  width: 80%;
  float: right;
  display: flex;
}

.md-inset {
  .md-checkbox {
    float: right;
  }

  .md-list-item-content {
    text-align: left;
  }
}

.custom-role-package {
  width: 450px;
  height: 450px;
}

.list {
  height: 100%;
  overflow-y: scroll;
}

.role-card {
  padding: 0;
}

.add-module {
  padding-left: 17px;
}

.nested-modules {
  text-align: right;
}

.nested {
  margin-left: 18px;
  margin-top: 5px;
  font-size: 12px;
}
</style>
