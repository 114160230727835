var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout custm-row"},[_c('div',{staticClass:"md-layout-item md-medium-size-100"},[_c('md-card',{staticClass:"block-bg"},[_c('md-card-header',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-70"},[_c('span',{staticClass:"arrow_left"},[_c('router-link',{attrs:{"to":{ path: '/Settings/Packages' }}},[_c('img',{attrs:{"src":require("../../../assets/images/left.svg")}})])],1),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.formType))])]),(_vm.formType == 'View Package')?_c('div',{staticClass:"md-layout-item md-size-30"},[_c('i',{staticClass:"md-icon md-icon-font md-theme-default edit-img",on:{"click":function($event){return _vm.changeFormType()}}},[_vm._v("edit")])]):_vm._e()]),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
                { 'md-error': _vm.errors.has('packageName') },
                {
                  'md-valid':
                    !_vm.errors.has('packageName') && _vm.touched.packageName,
                } ]},[_c('label',[_vm._v(_vm._s(_vm.$t('Package Name')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.packageName),expression:"modelValidations.packageName"}],attrs:{"type":"text","data-vv-name":"packageName","required":"","disabled":_vm.formType == 'View Package'},model:{value:(_vm.packageName),callback:function ($$v) {_vm.packageName=$$v},expression:"packageName"}}),_c('slide-y-down-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('packageName')),expression:"errors.has('packageName')"}],staticClass:"errors"},[_vm._v(" "+_vm._s(_vm.errors.first('packageName'))+" ")])])],1)],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('label',{staticClass:"status-label"},[_vm._v("Status")]),_c('md-switch',{staticClass:"status-switch",model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)]),_c('div',{staticClass:"full-control"},[_c('div',{staticClass:"list"},[_c('md-list',[(_vm.formType !== 'View Package')?_c('h4',[_c('img',{attrs:{"src":require("../../../assets/images/plus.svg")},on:{"click":function($event){_vm.showSubDialog = true}}}),_vm._v(" "+_vm._s(_vm.$t('Modules'))+" ")]):_vm._e(),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('md-table',{staticClass:"package-module table-striped"},[_c('md-table-row',[_c('md-table-head',[_vm._v("Main Module")]),_c('md-table-head',[_vm._v("Sub Module")]),_c('md-table-head')],1),_vm._l((_vm.packageModules),function(item){return _vm._t("default",[_c('md-table-row',{attrs:{"slot":"md-table-row"},slot:"md-table-row"},[_c('md-table-cell',[_vm._v(_vm._s(item.name))]),_c('md-table-cell'),_c('md-table-cell',[(item.is_default !== true)?_c('img',{class:[
                              { 'disable-class': _vm.formType == 'View Package' },
                              {
                                'active-disabled':
                                  _vm.formType !== 'View Package',
                              } ],attrs:{"src":require("../../../assets/images/delete.svg")},on:{"click":function($event){return _vm.removeModule(item.id, item.app_sub_module)}}}):_vm._e()])],1),(item.app_sub_module !== 0)?_vm._t("default",[_vm._l((item.app_sub_module),function(sub){return _vm._t("default",[_c('md-table-row',{attrs:{"slot":"md-table-row"},slot:"md-table-row"},[_c('md-table-cell'),_c('md-table-cell',[_vm._v(_vm._s(sub.name)+" "),_vm._l((sub.nested_modules),function(nested){return _vm._t("default",[_c('div',{key:nested.path,staticClass:"nested"},[_vm._v(" "+_vm._s(nested.name)+" ")])])})],2),_c('md-table-cell',[(item.is_default !== true)?_c('img',{class:[
                                  {
                                    'disable-class':
                                      _vm.formType == 'View Package',
                                  },
                                  {
                                    'active-disabled':
                                      _vm.formType !== 'View Package',
                                  } ],attrs:{"src":require("../../../assets/images/delete.svg")},on:{"click":function($event){return _vm.removeSubModule(
                                    sub.name,
                                    sub.sub_module_id,
                                    item.id
                                  )}}}):_vm._e()])],1)])})]):_vm._e()])})],2)],1)])])],1)]),(_vm.formType !== 'View Package')?_c('div',{staticClass:"btn-div"},[_c('md-card-actions',{staticClass:"text-center"},[_c('md-button',{attrs:{"native-type":"submit","to":{ path: '/Settings/Packages' }}},[_vm._v(_vm._s(_vm.$t('CANCEL')))]),_c('md-button',{staticClass:"md-primary",attrs:{"native-type":"submit"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.validate($event)}}},[_vm._v(_vm._s(_vm.$t('SAVE')))])],1)],1):_vm._e()])],1)],1),_c('md-dialog',{staticClass:"package-custm",attrs:{"md-active":_vm.showSubDialog},on:{"update:mdActive":function($event){_vm.showSubDialog=$event},"update:md-active":function($event){_vm.showSubDialog=$event}}},[_c('md-dialog-title',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-50"},[_vm._v("Modules")]),_c('div',{staticClass:"md-layout-item md-size-50 close-icon"},[_c('i',{staticClass:"md-icon md-icon-font md-theme-default",on:{"click":function($event){_vm.showSubDialog = false}}},[_vm._v("close")])])]),_c('div',{staticClass:"list"},[_c('md-list',[_vm._l((_vm.bulkModule),function(item){return _vm._t("default",[(item.app_sub_module !== null)?_c('md-list-item',{attrs:{"md-expand":""}},[_c('div',{staticClass:"module-detail",class:[
                {
                  'color-dialog':
                    _vm.array.findIndex(function (el) { return el == item.id; }) !== -1,
                } ]},[_c('div',{staticClass:"md-list-item-text module-name"},[_vm._v(_vm._s(item.name))]),_c('md-checkbox',{attrs:{"model":_vm.array,"value":item.id,"true-value":item.id},on:{"change":function($event){return _vm.moduleCheckbox(item.name, item.id, item.app_sub_module)}}})],1),_c('md-list',{attrs:{"slot":"md-expand"},slot:"md-expand"},_vm._l((item.app_sub_module),function(sub,index){return _c('md-list-item',{key:index,staticClass:"md-inset"},[_c('div',{staticClass:"sub-name-box",class:[
                    {
                      'color-dialog':
                        _vm.subarray.findIndex(
                          function (el) { return el == sub.sub_module_id; }
                        ) !== -1,
                    } ]},[_vm._v(" "+_vm._s(sub.name)+" "),(sub.nested_modules != null)?_vm._t("default",[_c('div',{staticClass:"md-layout nested-modules"},_vm._l((sub.nested_modules),function(nested){return _c('div',{key:nested.path,staticClass:"nested md-layout-item md-size-100"},[_vm._v(" "+_vm._s(nested.name)+" ")])}),0)]):_vm._e()],2),_c('md-checkbox',{attrs:{"model":_vm.subarray,"value":sub.sub_module_id,"true-value":sub.sub_module_id},on:{"change":function($event){return _vm.subModuleCheckbox(
                      sub.name,
                      sub.module_path,
                      item.id,
                      sub.sub_module_id
                    )}}}),_c('br')],1)}),1)],1):_c('md-list-item',[_c('div',{staticClass:"module-detail",class:[
                {
                  'color-dialog':
                    _vm.array.findIndex(function (el) { return el == item.id; }) !== -1,
                } ]},[_c('div',{staticClass:"md-list-item-text module-name"},[_vm._v(_vm._s(item.name))]),_c('md-checkbox',{attrs:{"model":_vm.array,"value":item.id,"true-value":item.id},on:{"change":function($event){return _vm.moduleCheckbox(item.name, item.id, item.items)}}})],1)])])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }