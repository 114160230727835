<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/Settings/integrations' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType == 'View Integration'"
          @click="formType = 'Edit Integration'"
        >
          <i class="md-icon md-icon-font md-theme-default edit-img">edit</i>
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                v-model="integration.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Integration'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <input
              class="custom-file-input"
              type="file"
              @change="onImageChange"
            />
          </div>

          <div v-if="imgUrl != ''" class="md-layout-item md-size-50">
            <img :src="imgUrl" alt="integration-image" />
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Integration'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/Settings/integrations' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import axios from 'axios'
export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      Name: '',
      touched: {
        Name: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Integration',
      },
      integration: {
        name: '',
        logo: null,
      },
      imgUrl: '',
      loader: false,
      integrationId: null,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createIntegration()
          }
        })
      }
    },

    onImageChange(event) {
      this.integration.logo = event.target.files[0]
      this.imgUrl = URL.createObjectURL(this.integration.logo)
    },

    createIntegration() {
      let integrationData = new FormData()
      integrationData.append('integration[name]', this.integration.name)
      integrationData.append('integration[logo]', this.integration.logo)
      if (this.formType == 'Add Integration') {
        customizeService
          .createIntegration(integrationData)
          .then((res) => {
            this.$router.push({ name: 'IntegrationsList' })
          })
          .catch((err) => alert(err))
      } else {
        axios
          .put(
            this.$route.query.client,
            integrationData,
            customizeService.authFun()
          )
          .then((res) => {
            this.$router.push({ name: 'IntegrationsList' })
          })
          .catch((err) => alert(err))
      }
    },

    reset() {
      this.integration = {
        name: '',
        file: null,
      }
    },
  },
  created() {
    this.formType = this.$route.query.formType
    let self = this
    if (this.formType != 'Add Integration') {
      axios
        .get(this.$route.query.client, customizeService.authFun())
        .then((res) => {
          this.integration = res.data
          if (this.integration.logo != null && this.integration.logo != '') {
            this.imgUrl = this.integration.logo
          }
        })
    }
  },
  watch: {
    Name() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
