var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout module-table"},[_c('div',{staticClass:"md-layout-item"},[_c('md-card',{staticClass:"pagination-card block-bg pad-l-15px pad-r-15px"},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-green mt-15"},[_c('router-link',{attrs:{"to":{ name: 'AddOutlet', query: { formType: 'Add Outlet' } }}},[(_vm.userType != 'user')?_c('md-icon',{staticClass:"add-icon"},[_vm._v("add")]):_vm._e()],1),_c('h3',{staticClass:"title",class:{ 'title-right': _vm.userType == 'user' }},[_vm._v(" "+_vm._s(_vm.$t('Outlet'))+" ")])],1),_c('md-card-content',[(_vm.loader)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{attrs:{"loading":_vm.loader,"color":'#00E0DB'}})],1):_vm._e(),(!_vm.loader)?_c('md-table',{staticClass:"paginated-table table-striped table-hover mt-25",attrs:{"value":_vm.queriedData,"md-sort":_vm.currentSort,"md-sort-order":_vm.currentSortOrder,"md-sort-fn":_vm.customSort},on:{"update:mdSort":function($event){_vm.currentSort=$event},"update:md-sort":function($event){_vm.currentSort=$event},"update:mdSortOrder":function($event){_vm.currentSortOrder=$event},"update:md-sort-order":function($event){_vm.currentSortOrder=$event},"md-selected":_vm.viewOutletDetail},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":_vm.$t('Name')}},[_c('div',{on:{"click":function($event){return _vm.viewOutletDetail(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('md-table-cell',{attrs:{"md-label":_vm.$t('Address')}},[_c('div',{on:{"click":function($event){return _vm.viewOutletDetail(item)}}},[_vm._v(" "+_vm._s(item.address1)+", "+_vm._s(item.address2)+" ")])]),_c('md-table-cell',{attrs:{"md-label":_vm.$t('Logo')}},[_c('div',{on:{"click":function($event){return _vm.viewOutletDetail(item)}}},[(item.img_url)?_c('img',{staticClass:"w-50 outlet-logo",attrs:{"src":item.img_url}}):_vm._e()])]),_c('md-table-cell',{attrs:{"md-label":_vm.$t('Day start')}},[_c('div',{on:{"click":function($event){return _vm.viewOutletDetail(item)}}},[_vm._v(" "+_vm._s(item.wrk_start_time)+" ")])]),(_vm.userType == 'branch_user')?_c('md-table-cell',{attrs:{"md-label":"Login","md-sort-by":"Login"}},[_c('div',{on:{"click":function($event){return _vm.login(item)}}},[_c('a',{staticClass:"outlet-login"},[_vm._v("Login")])])]):_vm._e(),_c('md-table-cell',{staticClass:"center",attrs:{"md-label":_vm.$t('Actions')}},[_c('div',{staticClass:"border-edit"},[_c('md-menu',{attrs:{"md-size":"medium","md-offset-x":-227,"md-offset-y":-16}},[_c('i',{staticClass:"material-icons text_align-center menu-icon",attrs:{"md-menu-trigger":""}},[_vm._v("more_vert")]),_c('md-menu-content',{staticClass:"menuitem-dropdown"},[_c('md-menu-item',{staticClass:"action-header menu-align"},[_vm._v(_vm._s(_vm.$t('Actions')))]),_c('router-link',{attrs:{"to":{
                        name: 'AddOutlet',
                        query: { formType: 'Edit Outlet', client: item.id },
                      }}},[_c('md-menu-item',[_c('span',{staticClass:"edit-align"},[_c('i',{staticClass:"fas fa-pencil-alt"}),_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")])])],1),_c('router-link',{attrs:{"to":{
                        name: 'AssignItems',
                        query: { formType: 'Edit Outlet', client: item.id },
                      }}},[_c('md-menu-item',[_c('span',{staticClass:"edit-align"},[_c('i',{staticClass:"fas fa-pencil-alt"}),_vm._v(" "+_vm._s(_vm.$t('Assign Items'))+" ")])])],1),_c('router-link',{attrs:{"to":{
                        name: 'MenuItem',
                        query: { formType: 'Show Items', client: item.id },
                      }}},[_c('md-menu-item',[_c('span',{staticClass:"edit-align"},[_c('i',{staticClass:"fas fa-pencil-alt"}),_vm._v(" "+_vm._s(_vm.$t('Show Menus'))+" ")])])],1),_c('div',[_c('md-menu-item',[_c('span',{staticClass:"edit-align"},[_c('i',{staticClass:"fa fa-ban",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('Deactivate'))+" ")])])],1),(item.login.password_reset_token)?_c('div',[_c('md-menu-item',[_c('span',{staticClass:"edit-align",on:{"click":function($event){return _vm.activate('Edit', item.login.password_reset_token)}}},[_c('i',{staticClass:"fa fa-pencil-alt",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('Activate Outlet'))+" ")])])],1):_vm._e(),(_vm.userType == 'branch_user')?_c('router-link',{attrs:{"to":{
                        name: 'assignOutlets',
                        query: {
                          screen: item.id,
                        },
                      }}},[_c('md-menu-item',[_c('span',{staticClass:"edit-align"},[_c('i',{staticClass:"fas fa-pencil-alt"}),_vm._v(" "+_vm._s(_vm.$t('Assign Screen Menus'))+" ")])])],1):_vm._e()],1)],1)],1)])],1)}}],null,false,293103104)},[_c('md-table-toolbar',{staticClass:"table-toolbar-align right-0"},[_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t('Search')))]),_c('md-input',{staticClass:"mb-3",attrs:{"type":"search","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('i',{staticClass:"fa fa-search search-align",attrs:{"aria-hidden":"true"}})],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item layout-border"},[_c('div',{staticClass:"pagination-main"},[_c('div',{staticClass:"rowsperpage"},[_vm._v(_vm._s(_vm.$t('Rows Per page')))]),_c('div',{staticClass:"pagenation-number"},[_c('md-field',[_c('md-select',{attrs:{"name":"pages"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('md-option',{key:item,attrs:{"label":item,"value":item}},[_vm._v(_vm._s(item))])}),1)],1)],1),_c('div',{staticClass:"pagination-warp"},[_c('p',{staticClass:"card-category pagination-text-align"},[_vm._v(" "+_vm._s(_vm.$t('From'))+" "+_vm._s(_vm.from + 1)+" "+_vm._s(_vm.$t('to'))+" "+_vm._s(_vm.to)+" ")]),_c('pagination',{staticClass:"pagination-no-border pagination-success",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }