<template>
  <div class="form pt-6">
    <div class="summary text-red" v-if="$v.form.$error">Form has errors</div>
    <form @submit.prevent="submit">
      <div class="flex justify-center my-6">
        <div class="px-4" :class="{ hasError: $v.form.name.$error }">
          <label class="mr-2 font-bold text-grey">Name</label>
          <input type="text" class="input" v-model="form.name" />
        </div>
        <div class="px-4" :class="{ hasError: $v.form.email.$error }">
          <label class="mr-2 font-bold text-grey">Email</label>
          <input type="email" class="input" v-model="form.email" />
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="button">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'FormComponent',

  data() {
    return {
      form: {
        name: '',
        email: '',
      },
    }
  },

  validations: {
    form: {
      name: { required, min: minLength(10) },
      email: { required, email },
    },
  },

  methods: {
    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      // to form submit after this
      alert('Form submitted')
    },
  },
}
</script>
