<template>
  <div class="md-layout dashboard-top-row">
    <!-- Matrix part -->

    <div class="md-layout-item md-size-50 md-medium-size-50">
      <md-card class="block-bg">
        <md-card-content>
          <div class="md-layout datepickers">
            <div class="md-layout-item md-size-50 md-medium-size-50">
              <date-filter-drop @searchType="searchInterval"></date-filter-drop>
            </div>
            <!-- <md-button class="md-primary md-layout-item md-size-10 md-medium-size-10 calendar-btn" @click="adHocDialog=true"><i class="fas fa-calendar-alt"></i></md-button> -->

            <div
              class="md-layout-item md-size-50 md-medium-size-50 date-set"
              v-if="queries.startDate"
            >
              <div class="dates">
                From : <span class="date-space">{{ queries.startDate }}</span>
              </div>
              <div class="dates">
                To : <span class="date-space">{{ queries.endDate }}</span>
              </div>
            </div>
          </div>

          <div class="md-layout">
            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
            >
              <stats-card header-color="dashboard-red">
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-chart-line"></i>
                  </div>
                  <p class="category">Total Orders</p>
                  <h3 class="title">
                    {{ saleData.total }}
                    <!-- {{ formatPrice(saleData.bill_total) }} -->
                  </h3>
                </template>

                <!-- <template slot="footer">
                        <div class="stats">
                          <md-icon>update</md-icon>
                          Just Updated
                        </div>
                      </template> -->
              </stats-card>
            </div>
            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
            >
              <stats-card header-color="dashboard-yellow">
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <p class="category">No. Accepted Orders</p>
                  <h3 class="title">
                    {{ saleData.accepted_count }}
                  </h3>
                </template>

                <!-- <template slot="footer">
                        <div class="stats">
                          <md-icon>local_offer</md-icon>
                          Tracked from Google Analytics
                        </div>
                      </template> -->
              </stats-card>
            </div>
            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
            >
              <stats-card header-color="dashboard-green">
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-coins"></i>
                  </div>
                  <p class="category">Avg Order</p>
                  <h3 class="title">
                    <!-- {{ formatPrice(saleData.avg_spend) }} -->
                  </h3>
                </template>
              </stats-card>
            </div>

            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
            >
              <stats-card header-color="dashboard-violet">
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-tags"></i>
                  </div>
                  <p class="category">Discounts</p>
                  <h3 class="title">
                    <!-- {{ formatPrice(saleData.discount) }} -->
                    {{ saleData.discount_total }}
                  </h3>
                </template>
              </stats-card>
            </div>
            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
            >
              <stats-card header-color="dashboard-trans-green">
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-sync"></i>
                  </div>
                  <p class="category">No. Rejected Orders</p>
                  <h3 class="title">
                    {{ saleData.rejected_count }}
                  </h3>
                </template>
              </stats-card>
            </div>
            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
            >
              <stats-card header-color="dashboard-cyan">
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-money-bill-alt"></i>
                  </div>
                  <p class="category">Total Rejected Orders</p>
                  <h3 class="title">
                    <!-- {{ formatPrice(saleData.avg_txn) }} -->
                    {{ saleData.rejected_total }}
                  </h3>
                </template>
              </stats-card>
            </div>
          </div>

          <div class="md-layout">
            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
            >
              <stats-card header-color="dashboard-orange">
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-arrows-alt-h"></i>
                  </div>
                  <p class="category">Refunds</p>
                  <h3 class="title">
                    <!-- {{ formatPrice(saleData.refunds) }} -->
                  </h3>
                </template>
              </stats-card>
            </div>

            <!-- <div class="md-layout-item md-size-100">
                <div class="md-card-actions md-alignment-left">
                  <h4>Stats</h4>
                </div>
              </div> -->
          </div>

          <!-- <div class="md-card-actions md-alignment-left">
          <div class="stats">
            <h4 class="title">Stats</h4>
            Stats for the user
          </div>
        </div> -->
        </md-card-content>
      </md-card>
    </div>

    <!-- Settings part -->
    <!-- <div class="md-layout-item md-size-50 md-medium-size-50">
      <md-card>
        <md-card-content>
          <label for="Store Status">{{ $t('Store Status') }}</label>

          <md-field>
            <md-select
              v-model="selectedStoreStatus"
              data-vv-name="Store Status"
            >
              <md-option class="first-opt" disabled>Store Status</md-option>
              <md-option v-for="(item, index) in storeStatus" :key="index">{{
                item
              }}</md-option>
            </md-select>
          </md-field>
        </md-card-content>
      </md-card>
    </div> -->

    <md-dialog :md-active.sync="adHocDialog" class="ad-hoc-dialog">
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          <div class="date-title">From Date:</div>
          <datetime
            format="YYYY-MM-DD H:i"
            v-model="queries.startDate"
            width="300px"
            name="dob"
          ></datetime>
        </div>
        <div class="md-layout-item md-size-50">
          <div class="date-title">To Date:</div>
          <datetime
            format="YYYY-MM-DD H:i"
            v-model="queries.endDate"
            width="300px"
            name="dob"
          ></datetime>
        </div>
      </div>

      <div class="md-layout">
        <div class="md-layout-item search-date">
          <md-button class="md-primary" @click="getOnlineSalesData"
            >Search</md-button
          >
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { StatsCard } from '@/components'
import DateFilterDrop from '../../../components/DateFilterDrop.vue'
import datetime from 'vuejs-datetimepicker'
import { dashboardService } from '../../../services/dashboard'

export default {
  components: {
    StatsCard,
    DateFilterDrop,
    datetime,
  },
  data() {
    return {
      adHocDialog: false,
      saleData: {},
      queries: {
        outletId: [],
        search_type: 'Daily',
      },
      storeStatus: ['Open', 'Close', 'invisible'],
      selectedStoreStatus: 'Open',
    }
  },
  created() {
    this.getOnlineSalesData()
  },
  methods: {
    searchInterval(data) {
      if (data != 'Ad Hoc') {
        this.queries.search_type = data
        this.queries.startDate = undefined
        this.queries.endDate = undefined
        this.configOnlineDashboard()
      } else {
        this.queries.search_type = 'Ad Hoc'
        this.adHocDialog = true
      }
    },
    formatPrice(value) {
      if (value >= 0) {
        return value.toFixed(2)
      }
    },
    getOnlineSalesData() {
      dashboardService.getDashboardSalesOnline(this.queries).then((res) => {
        this.saleData = res.data
      })
    },

    configOnlineDashboard() {
      this.getOnlineSalesData()
    },
  },
}
</script>

<style></style>
