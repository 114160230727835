<template>
  <div class="dashboard-block">
    <!-- Modal for showing Subscription -->
    <!-- <md-dialog-prompt
      :md-active.sync="subscriptionStatus"
      :md-backdrop="true"
      v-model="value"
      md-title="What's your name?"
      md-input-maxlength="30"
      md-input-placeholder="Type your name..."
      md-confirm-text="Done"
    /> -->
    <!-- <md-button @click="downloadLogo()"> Download Logos</md-button> -->
    <md-dialog
      :md-active.sync="subscriptionStatus"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
      class="subscriptionModal"
    >
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements bg-grad"
      >
        <md-card-header class="modal-header text-white">
          Welcome to CBE Innova
        </md-card-header>

        <md-card-content>
          <!-- <p class="subhead text-primary">
            It’s time to renew your Subscription!
          </p> -->
          <p class="modal_content">
            Thank you for choosing CBE Innova as your business partner. Right
            now, all you need to do is click on the button below and follow some
            easy steps to complete your subscription.
          </p>

          <p class="subhead text-primary">
            We’re very excited to have you onboard!
          </p>
        </md-card-content>
        <md-button
          class="subscription_btn md-primary"
          @click="handleSubscribe()"
        >
          Subscribe</md-button
        >
      </md-card>
    </md-dialog>

    <div
      class="md-layout dashboard-top-row"
      v-if="userRole == 'branch_user' || userRole == 'user'"
    >
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements block-bg"
      >
        <md-card-content>
          <div
            class="md-layout datepickers dashboard-datepicker bg-transparent"
          >
            <div class="md-layout-item md-size-35 md-medium-size-50">
              <date-filter-drop @searchType="searchInterval"></date-filter-drop>
            </div>
            <!-- <md-button class="md-primary md-layout-item md-size-10 md-medium-size-10 calendar-btn" @click="adHocDialog=true"><i class="fas fa-calendar-alt"></i></md-button> -->
            <div
              class="md-layout-item md-size-50 md-medium-size-50"
              v-if="userRole == 'branch_user'"
            >
              <md-field>
                <label>Outlets</label>
                <md-select
                  v-model="queries.outletId"
                  @md-closed="myCloseMethod"
                  multiple
                >
                  <md-option
                    v-for="item in outlets"
                    :key="item.id"
                    :value="item.id"
                    class="oulet-select-list-item"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div
              class="md-layout-item md-size-65 md-medium-size-50 date-set d-flex align-items-center justify-content-center text-dark left-padding"
              @click="adHocDialog = true"
              v-if="!adHocDialog && queries.startDate"
            >
              <div class="dates mr-2">
                From :
                <span class="date-space text-nowrap">{{
                  queries.startDate
                }}</span>
              </div>
              <div class="dates">
                To :
                <span class="date-space text-nowrap">{{
                  queries.endDate
                }}</span>
              </div>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-xsmall-size-100 md-size-33">
              <stats-card
                header-color="dashboard-red"
                class="d-flex justify-content-center"
              >
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-chart-line"></i>
                  </div>
                  <p class="category"><b>Total Sales</b></p>
                  <h3 class="title">
                    {{ formatPrice(saleData.bill_total) }}
                  </h3>
                </template>

                <!-- <template slot="footer">
                        <div class="stats">
                          <md-icon>update</md-icon>
                          Just Updated
                        </div>
                      </template> -->
              </stats-card>
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-size-33">
              <stats-card
                header-color="dashboard-yellow"
                class="d-flex justify-content-center"
              >
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <p class="category"><b>Customers</b></p>
                  <h3 class="title">
                    {{ saleData.cutomers }}
                  </h3>
                </template>

                <!-- <template slot="footer">
                        <div class="stats">
                          <md-icon>local_offer</md-icon>
                          Tracked from Google Analytics
                        </div>
                      </template> -->
              </stats-card>
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-size-33">
              <stats-card
                header-color="dashboard-green"
                class="d-flex justify-content-center"
              >
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-coins"></i>
                  </div>
                  <p class="category"><b>Avg.Spend</b></p>
                  <h3 class="title">
                    {{ formatPrice(saleData.avg_spend) }}
                  </h3>
                </template>
              </stats-card>
            </div>

            <div class="md-layout-item md-xsmall-size-100 md-size-33">
              <stats-card
                header-color="dashboard-violet"
                class="d-flex justify-content-center"
              >
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-tags"></i>
                  </div>
                  <p class="category"><b>Discounts</b></p>
                  <h3 class="title">
                    {{ formatPrice(saleData.discount) }}
                  </h3>
                </template>
              </stats-card>
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-size-33">
              <stats-card
                header-color="dashboard-trans-green"
                class="d-flex justify-content-center"
              >
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-sync"></i>
                  </div>
                  <p class="category"><b>Transactions</b></p>
                  <h3 class="title">
                    {{ saleData.tot_txn }}
                  </h3>
                </template>
              </stats-card>
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-size-33">
              <stats-card
                header-color="dashboard-cyan"
                class="d-flex justify-content-center"
              >
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-money-bill-alt"></i>
                  </div>
                  <p class="category"><b>Avg. Trans</b></p>
                  <h3 class="title">
                    {{ formatPrice(saleData.avg_txn) }}
                  </h3>
                </template>
              </stats-card>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-xsmall-size-100 md-size-33">
              <stats-card
                header-color="dashboard-pink"
                class="d-flex justify-content-center"
              >
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-briefcase"></i>
                  </div>
                  <p class="category"><b>Tax</b></p>
                  <h3 class="title">
                    {{ formatPrice(saleData.tax) }}
                  </h3>
                </template>
              </stats-card>
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-size-33">
              <stats-card
                header-color="dashboard-brown"
                class="d-flex justify-content-center"
              >
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-wallet"></i>
                  </div>
                  <p class="category"><b>Voids</b></p>
                  <h3 class="title">
                    {{ formatPrice(saleData.void_tot) }}
                  </h3>
                </template>
              </stats-card>
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-size-33">
              <stats-card
                header-color="dashboard-orange"
                class="d-flex justify-content-center"
              >
                <template slot="header">
                  <div class="card-icon">
                    <i class="fas fa-arrows-alt-h"></i>
                  </div>
                  <p class="category"><b>Refunds</b></p>
                  <h3 class="title">
                    {{ formatPrice(saleData.refunds) }}
                  </h3>
                </template>
              </stats-card>
            </div>

            <!-- <div class="md-layout-item md-size-100">
                <div class="md-card-actions md-alignment-left">
                  <h4>Stats</h4>
                </div>
              </div> -->
          </div>

          <div class="md-card-actions md-alignment-left">
            <div class="stats">
              <h4 class="title">Stats</h4>
              Stats for the user
            </div>
          </div>
        </md-card-content>
      </md-card>

      <div
        class="md-layout-item md-size-50 md-medium-size-100 md-xsmall-size-100 right-chart-dashboard position-relative"
        v-if="userRole != 'admin'"
      >
        <div class="loader-container" v-if="loader.hourlySales">
          <clip-loader
            class="loader-dashboard"
            :loading="loader.hourlySales"
            :color="'#00E0DB'"
          ></clip-loader>
        </div>

        <chart-card
          header-animation="true"
          :chart-data="hourlySalesChart.data"
          chart-type="bar"
          background-color="white"
          v-if="chartLoaded.hourlySales"
        >
          <!-- <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button> -->

          <div slot="first-button" class="h-100">
            <md-table class="hourly-table">
              <md-table-row>
                <md-table-head>Hour</md-table-head>
                <md-table-head>Net Sales</md-table-head>
                <md-table-head>Guests</md-table-head>
                <md-table-head>Transaction</md-table-head>
                <md-table-head>Avg. Spend</md-table-head>
                <md-table-head>Avg. Trans</md-table-head>
              </md-table-row>

              <md-table-row v-for="(item, index) in hourlySales" :key="index">
                <md-table-cell>{{ item.tm }}</md-table-cell>
                <md-table-cell>{{ item.net_total }}</md-table-cell>
                <md-table-cell>{{ item.guests }}</md-table-cell>
                <md-table-cell>{{ item.txn_tot }}</md-table-cell>
                <md-table-cell>{{ item.avg_spend }}</md-table-cell>
                <md-table-cell>{{ item.avg_bill }}</md-table-cell>
              </md-table-row>

              <md-table-row>
                <md-table-cell>{{ hourlySalesTotal.tm }}</md-table-cell>
                <md-table-cell>{{
                  hourlySalesTotal.net_total.toFixed(2)
                }}</md-table-cell>
                <md-table-cell>{{ hourlySalesTotal.guests }}</md-table-cell>
                <md-table-cell>{{ hourlySalesTotal.txn_tot }}</md-table-cell>
                <md-table-cell>{{
                  hourlySalesTotal.avg_spend.toFixed(2)
                }}</md-table-cell>
                <md-table-cell>{{
                  hourlySalesTotal.avg_bill.toFixed(2)
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>

          <md-button class="md-simple md-just-icon" slot="second-button">
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="bottom">Change Date</md-tooltip>
          </md-button>

          <template slot="content">
            <!-- <h4 class="title">Completed Tasks</h4> -->
            <!-- <p class="category">
            Last Campaign Performance
          </p> -->
          </template>

          <template slot="footer">
            <div class="stats">
              <h4 class="title"><b>Hourly Sales</b></h4>
              Data for each hour
              <!-- <md-icon>access_time</md-icon>
            campaign sent
            <animated-number :value="26"></animated-number> minutes ago -->
            </div>
          </template>
        </chart-card>
      </div>
    </div>

    <!-- =======================================Admin Stats Card================================================ -->

    <div class="md-layout dashboard-top-row" v-else>
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements"
      >
        <md-card-content>
          <div class="md-layout">
            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
              v-if="userRole == 'admin'"
            >
              <stats-card header-color="blue">
                <template slot="header">
                  <div class="card-icon">
                    <i class="fab fa-twitter"></i>
                  </div>
                  <p class="category">Distributors</p>
                  <h3 class="title">
                    {{ distributorData.length }}
                  </h3>
                </template>
              </stats-card>
            </div>
            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
            >
              <stats-card header-color="rose">
                <template slot="header">
                  <div class="card-icon">
                    <md-icon>equalizer</md-icon>
                  </div>
                  <p class="category">Business Verticals</p>
                  <h3 class="title">
                    {{ totalChains }}
                  </h3>
                </template>
              </stats-card>
            </div>
            <div
              class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
            >
              <stats-card header-color="green">
                <template slot="header">
                  <div class="card-icon">
                    <md-icon>store</md-icon>
                  </div>
                  <p class="category">Outlets</p>
                  <h3 class="title">
                    {{ totalOutlets }}
                  </h3>
                </template>
              </stats-card>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <!---============================================Chart Data==========================================================-->
    <div
      class="md-layout mix"
      v-if="userRole == 'branch_user' || userRole == 'user'"
    >
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33 position-relative pd-l first"
      >
        <div class="loader-container" v-if="loader.paymentMix">
          <clip-loader
            class="loader-dashboard"
            :loading="loader.paymentMix"
            :color="'#00E0DB'"
          ></clip-loader>
        </div>

        <chart-card
          header-animation="true"
          :chart-data="paymentMixChart.data"
          :chart-responsive-options="emailsSubscriptionChart.responsiveOptions"
          chart-type="doughnut"
          chart-inside-header
          background-color="white"
          v-if="chartLoaded.paymentMix"
        >
          <md-icon slot="fixed-button">build</md-icon>
          <!-- <md-button class="md-simple md-info md-just-icon" slot="first-button">
          
        </md-button> -->

          <div class="payment-table" slot="first-button">
            <md-table>
              <md-table-row>
                <md-table-head>Tender</md-table-head>
                <md-table-head>Qty</md-table-head>
                <md-table-head>Total</md-table-head>
                <md-table-head>%</md-table-head>
              </md-table-row>

              <md-table-row v-for="(item, index) in paymentMix" :key="index">
                <md-table-cell>{{ item.type }}</md-table-cell>
                <md-table-cell>{{ item.qty }}</md-table-cell>
                <md-table-cell>{{ item.amount }}</md-table-cell>
                <md-table-cell>{{ item.percentage }}</md-table-cell>
              </md-table-row>

              <md-table-row>
                <md-table-cell>Total</md-table-cell>
                <md-table-cell>{{ paymentMixTotal.qty }} </md-table-cell>
                <md-table-cell>{{
                  paymentMixTotal.amount.toFixed(2)
                }}</md-table-cell>
                <md-table-cell>{{
                  paymentMixTotal.percentage.toFixed(2)
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          <md-button class="md-simple md-just-icon" slot="second-button">
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="bottom">Change Date</md-tooltip>
          </md-button>

          <template slot="content">
            <!-- <h4 class="title">Sales Category Miz</h4> -->
            <!-- <p class="category">
            Last Campaign Performance
          </p> -->
          </template>

          <template slot="footer">
            <div class="stats">
              <h4 class="title"><b>Payment Mix</b></h4>
              Data for each payment type
            </div>
          </template>
        </chart-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33 position-relative second"
      >
        <div class="loader-container" v-if="loader.salesCatMix">
          <clip-loader
            class="loader-dashboard"
            :loading="loader.salesCatMix"
            :color="'#00E0DB'"
          ></clip-loader>
        </div>

        <chart-card
          header-animation="true"
          :chart-data="salesCatMixChart.data"
          :chart-options="salesCatMixChart.options"
          chart-type="doughnut"
          chart-inside-header
          background-color="white"
          v-if="chartLoaded.salesCatMix"
        >
          <!-- <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button> -->

          <div class="sales-table" slot="first-button">
            <md-table>
              <md-table-row>
                <md-table-head class="text-nowrap" width="150"
                  >Sales Category</md-table-head
                >
                <md-table-head>Qty</md-table-head>
                <md-table-head class="text-nowrap">Net Sales</md-table-head>
                <md-table-head>%</md-table-head>
              </md-table-row>

              <md-table-row v-for="(item, index) in salesCatMix" :key="index">
                <md-table-cell>{{ item.order_type }}</md-table-cell>
                <md-table-cell>{{ item.qty }}</md-table-cell>
                <md-table-cell>{{ item.net_total }}</md-table-cell>
                <md-table-cell>{{ item.percentage }}</md-table-cell>
              </md-table-row>

              <md-table-row>
                <md-table-cell>Total</md-table-cell>
                <md-table-cell>{{ salesCatMixTotal.qty }}</md-table-cell>
                <md-table-cell>{{
                  salesCatMixTotal.net_total.toFixed(2)
                }}</md-table-cell>
                <md-table-cell>{{
                  salesCatMixTotal.percentage.toFixed(2)
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          <md-button class="md-simple md-just-icon" slot="second-button">
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="bottom">Change Date</md-tooltip>
          </md-button>

          <!-- <template slot="content">
          <h4 class="title">Daily Sales</h4>
          <p class="category">
            <span class="text-success"
              ><i class="fas fa-long-arrow-alt-up"></i>
              <animated-number :value="55"></animated-number>%
            </span>
            increase in today sales.
          </p>
        </template> -->

          <template slot="footer">
            <div class="stats">
              <!-- <md-icon>access_time</md-icon>
            updated <animated-number :value="4"></animated-number> minutes ago -->
              <h4 class="title"><b>Sales Category Mix</b></h4>
              Data for sales category
            </div>
          </template>
        </chart-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33 position-relative third"
      >
        <div class="loader-container" v-if="loader.menuCatMix">
          <clip-loader
            class="loader-dashboard"
            :loading="loader.menuCatMix"
            :color="'#00E0DB'"
          ></clip-loader>
        </div>

        <chart-card
          header-animation="true"
          :chart-data="menuCatMixChart.data"
          chart-type="Horizontal Bar"
          background-color="white"
          v-if="chartLoaded.menuCatMix"
        >
          <!-- <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button> -->

          <div class="menu-cat-table" slot="first-button">
            <md-table>
              <md-table-row>
                <md-table-head class="text-nowrap" width="150"
                  >Menu Category</md-table-head
                >
                <md-table-head>Qty</md-table-head>
                <md-table-head class="text-nowrap">Net Sales</md-table-head>
                <md-table-head>%</md-table-head>
              </md-table-row>

              <md-table-row v-for="(item, index) in menuCatMix" :key="index">
                <md-table-cell>{{ item.name }}</md-table-cell>
                <md-table-cell>{{ item.qty }}</md-table-cell>
                <md-table-cell>{{ item.net_total }}</md-table-cell>
                <md-table-cell>{{ item.percentage }}</md-table-cell>
              </md-table-row>

              <md-table-row>
                <md-table-cell>Total</md-table-cell>
                <md-table-cell>{{ menuCatTotal.qty }}</md-table-cell>
                <md-table-cell>{{
                  menuCatTotal.net_total.toFixed(2)
                }}</md-table-cell>
                <md-table-cell>{{
                  menuCatTotal.percentage.toFixed(2)
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>

          <md-button class="md-simple md-just-icon" slot="second-button">
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="bottom">Change Date</md-tooltip>
          </md-button>

          <template slot="content">
            <!-- <h4 class="title">Completed Tasks</h4> -->
            <!-- <p class="category">
            Last Campaign Performance
          </p> -->
          </template>

          <template slot="footer">
            <div class="stats">
              <h4 class="title"><b>Menu Category Mix</b></h4>
              Data for each category of items
              <!-- <md-icon>access_time</md-icon>
            campaign sent
            <animated-number :value="26"></animated-number> minutes ago -->
            </div>
          </template>
        </chart-card>
      </div>
    </div>

    <!---------========================Map Data========================---->
    <div class="md-layout" v-if="chartLoaded.mapStats">
      <div class="md-layout-item md-size-100">
        <global-sales-card header-color="green">
          <template slot="header">
            <div class="card-icon">
              <md-icon>language</md-icon>
            </div>
            <h4 class="title">Business Verticals by Top Locations</h4>
          </template>

          <template slot="content">
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <global-sales-table
                  :regionData="countryData"
                ></global-sales-table>
              </div>
              <div class="md-layout-item md-size-50">
                <async-world-map class="map" :data="mapData"></async-world-map>
              </div>
            </div>
          </template>
        </global-sales-card>
      </div>
    </div>

    <md-dialog :md-active.sync="adHocDialog" class="ad-hoc-dialog">
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          <div class="date-title">From Date:</div>
          <datetime
            format="YYYY-MM-DD H:i"
            v-model="queries.startDate"
            width="300px"
            name="dob"
          ></datetime>
        </div>
        <div class="md-layout-item md-size-50">
          <div class="date-title">To Date:</div>
          <datetime
            format="YYYY-MM-DD H:i"
            v-model="queries.endDate"
            width="300px"
            name="dob"
          ></datetime>
        </div>
      </div>

      <div class="md-layout">
        <div class="md-layout-item search-date">
          <md-button class="md-primary" @click="getSalesData">Search</md-button>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import AsyncWorldMap from '@/components/WorldMap/AsyncWorldMap.vue'
import { dashboardService } from '../../services/dashboard.js'
import { accountService } from '../../services/account.js'
import { customizeService } from '../../services/customize.js'
import datetime from 'vuejs-datetimepicker'
import country_codes from './Maps/country_code.js'
import DateFilterDrop from '../../components/DateFilterDrop'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import store from '@/store'
import axios from 'axios'

import {
  StatsCard,
  ChartCard,
  ProductCard,
  AnimatedNumber,
  GlobalSalesCard,
  GlobalSalesTable,
} from '@/components'

export default {
  components: {
    StatsCard,
    ChartCard,
    AnimatedNumber,
    ProductCard,
    GlobalSalesCard,
    GlobalSalesTable,
    AsyncWorldMap,
    datetime,
    DateFilterDrop,
    ClipLoader,
  },
  data() {
    return {
      subscriptionStatus: false,
      active: true,
      value: null,
      loader: {
        salesCatMix: true,
        paymentMix: true,
        menuCatMix: true,
        hourlySales: true,
        dashboardStats: true,
      },
      adHocDialog: false,
      startDate: null,
      endDate: null,
      userRole: accountService.userRole(),
      chartLoaded: {
        salesCatMix: false,
        paymentMix: false,
        menuCatMix: false,
        hourlySales: false,
        mapStats: false,
      },
      seq2: 0,
      saleData: [],
      salesCatMix: [],
      paymentMix: [],
      hourlySales: [],
      menuCatMix: [],
      distributorData: [],
      totalChains: 0,
      totalOutlets: 0,

      mapData: {
        // AU: 760,
        // BR: 550,
        // CA: 120,
        // DE: 1300,
        // FR: 540,
        // GB: 690,
        // GE: 200,
        // IN: 200,
        // RO: 600,
        // RU: 300,
        // US: 2920
      },

      countryData: [],

      salesCatMixChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          height: '230px',
          donut: true,
        },
      },

      paymentMixChart: {
        data: {
          labels: [],
          series: [],
        },
      },

      menuCatMixChart: {
        data: {
          labels: [],
          series: [],
        },
      },

      hourlySalesChart: {
        data: {
          labels: [],
          series: [[], [], []],
        },
      },

      pieChart: {
        data: {
          labels: ['62%', '32%', '6%'],
          series: [62, 32, 6],
        },
        options: {
          height: '230px',
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            'Ja',
            'Fe',
            'Ma',
            'Ap',
            'Mai',
            'Ju',
            'Jul',
            'Au',
            'Se',
            'Oc',
            'No',
            'De',
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            },
          ],
        ],
      },
      queries: {
        outletId: [],
        search_type: 'Daily',
      },
      outlets: [],
    }
  },

  computed: {
    hourlySalesTotal() {
      let total = {
        tm: 'Total',
        net_total: 0,
        guests: 0,
        txn_tot: 0,
        avg_spend: 0,
        avg_bill: 0,
        bill_total: 0,
        sales_total: 0,
      }
      this.hourlySales.forEach((el) => {
        total.net_total += el.net_total
        total.guests += el.guests
        total.txn_tot += el.txn_tot
        total.bill_total += el.bill_total
        total.sales_total += el.sales_total
      })
      if (total.guests > 0) {
        total.avg_spend = total.net_total / total.guests
      }
      if (total.txn_tot > 0) {
        total.avg_bill = total.net_total / total.txn_tot
      }

      return total
    },

    paymentMixTotal() {
      let total = {
        qty: 0,
        amount: 0,
        percentage: 0,
      }
      this.paymentMix.forEach((el) => {
        total.qty += el.qty
        total.amount += el.amount
        total.percentage += el.percentage
      })
      return total
    },

    salesCatMixTotal() {
      let total = {
        qty: 0,
        net_total: 0,
        percentage: 0,
      }
      this.salesCatMix.forEach((el) => {
        total.qty += el.qty
        total.net_total += el.net_total
        total.percentage += el.percentage
      })
      return total
    },

    menuCatTotal() {
      let total = {
        qty: 0,
        net_total: 0,
        percentage: 0,
      }
      this.menuCatMix.forEach((el) => {
        total.qty += el.qty
        total.net_total += el.net_total
        total.percentage += el.percentage
      })
      return total
    },
  },

  methods: {
    handleSubscribe() {
      // window.open('http://localhost:8080/pricing/50', '_blank')
      this.$router.push('/Pricing/' + accountService.getOutletId())
    },
    downloadLogo() {
      // let headers = {
      //   'x-apikey': '59a7ad19f5a9fa0808f11931',
      //   'Access-Control-Allow-Origin': '*',
      //   'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      // }

      for (let i = 0; i < data.length; i++) {
        let url = data[i].logo
        const blob = new Blob([url], { type: 'image/png' })
        const downloadUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = data[i].id
        document.body.appendChild(a)
        a.click()
      }
    },
    searchInterval(data) {
      if (data != 'Ad Hoc') {
        this.queries.search_type = data
        this.queries.startDate = undefined
        this.queries.endDate = undefined
        this.configDashboard()
      } else {
        this.queries.search_type = 'Ad Hoc'
        this.queries.startDate = ''
        this.queries.endDate = ''
        let today = new Date()
        let year = today.getFullYear()
        let month = today.getMonth() + 1
        let day = today.getDate()
        let joinedDate = [year, month, day].join('-')
        if (this.$store.getters.getProfile.outlet_start_time) {
          this.queries.startDate = [
            joinedDate,
            this.$store.getters.getProfile.outlet_start_time,
          ].join(' ')
          this.queries.endDate = [
            joinedDate,
            this.$store.getters.getProfile.outlet_start_time,
          ].join(' ')
        }
        this.adHocDialog = true
      }
    },
    formatPrice(value) {
      if (value >= 0) {
        return value.toFixed(2)
      }
      if (value) {
        return value.toFixed(2)
      }
    },

    getDashboardSale() {
      var self = this
      if (this.userRole != 'admin') {
        dashboardService.getDashboardSales(this.queries).then((res) => {
          self.saleData = res.data
          this.loader.dashboardStats = false
        })
      }
    },

    getSalesCat() {
      var self = this
      dashboardService.getSalesCatMix(this.queries).then((res) => {
        self.salesCatMix = res.data
        this.loader.salesCatMix = false
        self.salesCatMixChart.data.labels = []
        self.salesCatMixChart.data.series = []
        for (var i = 0; i < self.salesCatMix.length; i++) {
          self.salesCatMixChart.data.labels.push(self.salesCatMix[i].order_type)
          self.salesCatMixChart.data.series.push(self.salesCatMix[i].net_total)
        }
        this.chartLoaded.salesCatMix = true
      })
    },

    getPaymentMix() {
      dashboardService.getPaymentMix(this.queries).then((res) => {
        this.paymentMix = res.data
        this.loader.paymentMix = false
        this.paymentMixChart.data.labels = []
        this.paymentMixChart.data.series = []

        res.data.forEach((el) => {
          this.paymentMixChart.data.labels.push(el.type)
          this.paymentMixChart.data.series.push(el.amount)
          this.chartLoaded.paymentMix = true
        })
      })
    },

    getMenuCategoryMix() {
      dashboardService.getMenuCatMix(this.queries).then((res) => {
        this.menuCatMix = res.data
        this.loader.menuCatMix = false
        this.menuCatMixChart.data.labels = []
        this.menuCatMixChart.data.series = []
        res.data.forEach((el) => {
          this.menuCatMixChart.data.labels.push(el.name)
          this.menuCatMixChart.data.series.push(el.net_total)
        })
        this.chartLoaded.menuCatMix = true
      })
    },

    getHourlySales() {
      dashboardService.getHourlySales(this.queries).then((res) => {
        this.hourlySales = res.data
        this.loader.hourlySales = false
        this.hourlySalesChart.data.labels = []
        this.hourlySalesChart.data.series[0] = []
        this.hourlySalesChart.data.series[1] = []
        this.hourlySalesChart.data.series[2] = []
        res.data.forEach((el) => {
          this.hourlySalesChart.data.labels.push(el.tm + ':00')
          this.hourlySalesChart.data.series[0].push(el.net_total)
          this.hourlySalesChart.data.series[1].push(el.guests)
          this.hourlySalesChart.data.series[2].push(el.txn_tot)
        })
        this.chartLoaded.hourlySales = true
      })
    },

    getMapData() {
      if (this.userRole == 'admin') {
        customizeService.getDistributors().then((res) => {
          this.distributorData = res.data.distributors
          this.distributorData.forEach((el) => {
            this.totalChains += el.no_of_chains
            this.totalOutlets += el.outlet_count //Calculating number of chains
            this.countryData.push({
              region: el.country,
              count: el.no_of_chains,
            })
            let distCountry = country_codes.find(
              (element) => element.country == el.country
            )
            if (this.mapData[distCountry.code] == undefined)
              //Checking if the country code is not in mapData
              this.mapData[distCountry.code] = el.no_of_chains
            else this.mapData[distCountry.code] += el.no_of_chains //if country code is present adding the number of chains to the existing va
          })
          this.chartLoaded.mapStats = true
        })
      }
    },

    getSalesData() {
      this.chartLoaded.salesCatMix = false
      this.chartLoaded.paymentMix = false
      this.chartLoaded.menuCatMix = false
      this.chartLoaded.hourlySales = false
      this.chartLoaded.mapStats = false
      ;(this.loader.salesCatMix = true),
        (this.loader.paymentMix = true),
        (this.loader.menuCatMix = true),
        (this.loader.hourlySales = true),
        (this.loader.dashboardStats = true)

      this.getDashboardSale()
      this.getSalesCat()
      this.getPaymentMix()
      this.getMenuCategoryMix()
      this.getHourlySales()
      this.adHocDialog = false
    },

    resetChartData() {
      this.chartLoaded = {}
    },

    configDashboard() {
      this.getMapData()
      if (this.userRole != 'admin' && this.userRole != 'distributor') {
        this.getSalesData()
      }
    },
    myCloseMethod() {
      if (this.queries.outletId.length > 0) {
        this.getSalesData()
      }
    },
  },

  created() {
    this.userRole = accountService.userRole()
    if (this.userRole == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.outlets = res.data.outlets
        this.configDashboard()
      })
    } else if (this.userRole == 'user') {
      customizeService
        .getOutletById(accountService.getOutletId())
        .then((res) => {
          // this.subscriptionStatus = res.data.outlet.subscription_enabled
          this.subscriptionStatus = false
        })
    } else if (this.userRole == 'Distributor') {
      dashboardService.getOutletCount().then((res) => {
        this.totalOutlets = res.data.total_outlets
      })
    }

    if (this.userRole != 'branch_user' && this.userRole != 'Distributor') {
      this.configDashboard()
    }
  },
}
</script>

<style lang="scss">
.title {
  font-size: 17px;
}

h3 {
  font-weight: bold;
}

.datepickers {
  padding: 5px;
  background-color: #eee;
  margin-top: 10px;
}

.ad-hoc-dialog {
  overflow: visible;
  padding: 30px !important;
}

.search-date {
  text-align: center;
  padding-top: 15px;
}

.dates {
  font-size: 14px;
  text-align: right;
  // color: #60616c;
  font-weight: bold;
}

.date-set {
  background-color: #eee;
  border-radius: 3px;
  height: 43px;
  margin-top: 5px;
}
.calendar-btn {
  min-width: 50px;
  max-width: 50px;
  padding: 0px 0px;
  margin-left: 20px;
  margin-top: 4px;
  .md-ripple {
    padding: 0px 0px;
  }
}

.md-size-33 {
  position: relative;
}

//loader
.lds-ring {
  display: inline-block;
  position: relative;
  margin: 35%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(72, 126, 165);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(72, 126, 165) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.md-table-row:last-child td {
  font-weight: bold;
}

.modal-header {
  font-size: 22px;
  font-weight: 900;
  color: #60616c;
}

.subscriptionModal .md-dialog-container {
  position: initial !important;
  margin-top: auto !important;
  margin-left: auto !important;
  overflow-y: hidden !important;
}

.notifModal .md-dialog-container {
  position: initial !important;
  margin-top: auto !important;
  margin-left: auto !important;
  overflow-y: hidden !important;
}

.subhead {
  text-align: center;
  font-size: 16px;
  color: #1f7dbb;
  font-weight: 600;
}

.modal_content {
  text-align: center;
  max-width: 520px;
  width: 100%;
}

.subscription_btn :hover {
  background: #1f7dbb;
}
.days {
  color: #2b82be !important;
}
.ad-hoc-dialog .md-dialog-container {
  max-height: fit-content !important;
}
.ad-hoc-dialog .calender-div {
  top: 5px !important;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .right-chart-dashboard {
    .md-card {
      margin-top: 30px;
    }
  }
}
// @media screen and (max-width: 1366px) and (min-width: 768px) {

// .right-chart-dashboard {
//   .md-card-action-buttons{
//     height: 37% !important;
//   }
// }
// }
</style>
