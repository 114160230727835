<script>
import { Line, mixins } from 'vue-chartjs'
import { watch } from 'fs'
import { setTimeout } from 'timers'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  fontSize: 40,
                },
              },
            ],
          },
        }
      },
    },
  },
  mounted() {},

  methods: {
    loadChart() {
      this.renderChart(this.chartData, this.options)
    },
  },

  watch: {
    chartData() {
      var self = this
      //     setTimeout(function(){
      //             self.loadChart()
      //     },2000)
      self.loadChart()
    },
  },
}
</script>
