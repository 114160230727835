<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  name: 'BarChart',
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default() {
        return { responsive: true, maintainAspectRatio: true }
      },
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
      },
    }
  },
  methods: {
    loadChart() {
      this.renderChart(this.chartData, this.options)
    },
  },
  watch: {
    chartData() {
      var self = this
      //     setTimeout(function(){
      //             self.loadChart()
      //     },2000)
      self.loadChart()
    },
  },
}
</script>
