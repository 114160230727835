var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-table"},[_c('md-card',{staticClass:"hourly-report block-bg"},[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-green md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-size-70"},[_c('span',{staticClass:"arrow_left"},[_c('router-link',{attrs:{"to":{ path: '/dashboard' }}},[_c('img',{attrs:{"src":require("../../../../assets/images/left.svg")}})])],1),_c('h3',[_vm._v(_vm._s(_vm.$t('Hourly Report')))])])]),_c('md-card-content',[_c('div',{staticClass:"md-layout filter-section search-section block-bg"},[_c('date-period-dropdown',{on:{"interval":_vm.setDateType}}),(_vm.userRole == 'branch_user')?_c('div',{staticClass:"md-layout-item md-size-25"},[_c('md-field',[_c('label',[_vm._v("Outlets")]),_c('md-select',{attrs:{"multiple":""},on:{"md-closed":function($event){_vm.queries.outletId.length > 0
                  ? _vm.hourlyReport()
                  : (_vm.queries.outletId = [])}},model:{value:(_vm.queries.outletId),callback:function ($$v) {_vm.$set(_vm.queries, "outletId", $$v)},expression:"queries.outletId"}},_vm._l((_vm.outlets),function(item){return _c('md-option',{key:item.id,staticClass:"oulet-select-list-item",attrs:{"value":item.id}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1)],1):_vm._e(),_c('div',{staticClass:"md-layout-item md-size-25"},[_c('md-button',{staticClass:"md-teritiary",on:{"click":_vm.hourlyReport}},[_vm._v("SEARCH")])],1)],1),(_vm.loader)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{attrs:{"loading":_vm.loader,"color":'#00E0DB'}})],1):_vm._e(),_c('h4',{staticClass:"center"},[_vm._v("Hourly Report- "+_vm._s(_vm.sdate)+" to "+_vm._s(_vm.edate))]),(!_vm.loader)?_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('div',{staticClass:"action-buttons"},[_c('div',{staticClass:"pdf-el"},[_c('div',{staticClass:"download-btn"},[_c('img',{attrs:{"src":require("../../../../assets/images/pdf.svg")},on:{"click":function($event){return _vm.downloadWithoutCSS()}}})])]),_c('div',{staticClass:"excel-el"},[_c('vue-excel-xlsx',{staticClass:"Excel-btn",attrs:{"data":_vm.reports,"columns":_vm.columns,"filename":'Hourly Report',"sheetname":'Hourly Report',"outlet-names":_vm.selectedOutletsNames()}})],1)])]),_c('div',{staticClass:"md-layout-item report-table"},[_c('div',{ref:"content",attrs:{"id":"mytable"}},[_c('md-table',{staticClass:"table-typ1"},[_vm._l((_vm.reports),function(items){return _vm._t("default",[(items.type == 'Time')?_c('md-table-row',[_c('md-table-head',[_vm._v(_vm._s(items.type))]),_c('md-table-head',[_vm._v(_vm._s(items.guest))]),_c('md-table-head',[_vm._v(_vm._s(items.sales_totl))]),_c('md-table-head',[_vm._v(_vm._s(items.txns))]),_c('md-table-head',[_vm._v(_vm._s(items.avg_cus_spend))])],1):_c('md-table-row',{class:[
                    {
                      'bold-total':
                        items.type == 'Total' ||
                        items.type == 'Subtotals' ||
                        items.type == 'Report Total',
                    } ]},[_c('md-table-cell',{class:{
                      'order-type-bold':
                        items.type == 'Quick Service' ||
                        items.type == 'Take Out',
                    }},[_vm._v(_vm._s(items.type))]),_c('md-table-cell',[_vm._v(_vm._s(items.guest))]),_c('md-table-cell',[_vm._v(_vm._s(items.sales_totl))]),_c('md-table-cell',[_vm._v(_vm._s(items.txns))]),_c('md-table-cell',[_vm._v(_vm._s(items.avg_cus_spend))])],1)])})],2)],1)])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }