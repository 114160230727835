import axios from 'axios'
import { accountService } from './../../services/account'
import { customizeService } from '../../services/customize'
import VueJwtDecode from 'vue-jwt-decode'
import routes from './../../routes/routes'
import { Promise, async } from 'q'

const state = {
  userRole: 'distributor',
  logStatus: {},
  cbePayDialog: false,
  validated: true,
  menus: '',
  baseUrl: 'https://api.appetitepos.com',
  profile: {},
  isOnline: false,
  outlet: {},
}

const getters = {
  getUserRole: (state) => state.userRole,
  getLoggedIn: (state) => state.logStatus,
  getValidationStatus: (state) => state.validated,
  getBaseUrl: (state) => state.baseUrl,
  getProfile: (state) => state.profile,
}

const actions = {
  async checkUserRole({ commit }, user) {
    // const p1 = new Promise((resolve, reject) => {
    accountService
      .login(user)
      .then((res) => {
        sessionStorage.setItem('access_token', res.data.access_token)
        sessionStorage.setItem('refresh_token', res.data.refresh_token)
        var decoded = VueJwtDecode.decode(res.data.access_token)
        sessionStorage.setItem('user_type', decoded.user.user_type)
        // resolve(res.data)
        //    customizeService.setTenant().then(response => {
        commit('logInUser', { decoded })
        //    })
      })
      .catch((error) => {
        if (error.response.status == 400) {
          commit('invalidLogin')
        } else {
          commit('loginFailed')
        }
      })
    //  });
    // Promise.all([p1]).then(val => {
    //     console.log("promise resolved");
    //     var token = sessionStorage.getItem('access_token');
    //     var decoded = VueJwtDecode.decode(token)
    //     var subdomain = decoded.user.subdomain;
    //     console.log('subdomain', subdomain)
    //     commit('updateUrl', { subdomain })
    // });
  },
  async checkUrl({ commit }, token) {
    var decoded = VueJwtDecode.decode(token)
    var subdomain = decoded.user.subdomain
    commit('updateUrl', { subdomain })
  },

  async updateProfile({ commit }) {
    accountService.getUserDetails().then((res) => {
      commit('updateProfile', res)
    })
  },
}

const mutations = {
  logInUser: (state, { decoded }) => {
    state.userRole = 'user type changed'
    state.logStatus = { loggedIn: true }
    state.validated = decoded.user.validated
    state.cbePayDialog = true
  },
  invalidLogin: (state) => {
    state.logStatus = { loggedIn: false, status: 'Invalid Login' }
  },
  loginFailed: (state) => {
    state.logStatus = { loggedIn: false, status: 'Server Error' }
  },
  updateUrl: (state, { subdomain }) => {
    accountService.dynamicFlg = true
  },
  updateProfile: (state, { data }) => {
    state.profile = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
