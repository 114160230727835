<template>
  <form>
    <md-card-header
      class="md-card-header-text md-card-header-green md-layout md-gutter"
    >
      <div class="md-layout-item">
        <span class="arrow_left ml-auto mr-auto">
          <router-link :to="{ path: '/reports/clients' }"
            ><md-icon class="material-icons ml-auto"
              >keyboard_arrow_left</md-icon
            ></router-link
          >
        </span>
        <h3
          v-if="formType == 'Edit Client' || formType == 'View Client'"
          class="text-white"
        >
          {{ formType }} - {{ clientDetail.name }}
        </h3>
        <h3 class="text-white" v-else>{{ formType }}</h3>
      </div>
    </md-card-header>
    <md-card class="block-bg">
      <md-card-content v-if="formType == 'Add Client'">
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('required') },
                { 'md-valid': !errors.has('required') && touched.required },
              ]"
            >
              <label>Business Name</label>
              <md-input
                v-model="required"
                data-vv-name="required"
                type="text"
                v-validate="modelValidations.required"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('required')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('required') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Street</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('equalToDest') },
                {
                  'md-valid': !errors.has('equalToDest') && touched.equalToDest,
                },
              ]"
            >
              <label>Street</label>
              <md-input
                v-model="equalToDest"
                data-vv-name="equalToDest"
                data-vv-as="equalToSource"
                type="text"
                v-validate="modelValidations.equalToDest"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToDest')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToDest') && touched.equalToDest"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label for="Country">Country</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="State">State</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label for="City">City</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="Pincode">Pincode</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Cell#</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item"></div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <!-- <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource
                    }
                  ]"
                >
                  <label>E-mail</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                    >done</md-icon>
                  </slide-y-down-transition>
                </md-field>

                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource
                    }
                  ]"
                >
                  <label>Password</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                    >done</md-icon>
                  </slide-y-down-transition>
                </md-field> -->

            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Packages</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>

            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Validity</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item">
            <div class="logo-text-cls">Logo Preview</div>
            <img class="img logo-align" slot="imageHeader" :src="product1" />
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <label class="toggle-align">Status</label>
            <md-switch v-model="switch2"></md-switch>
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              disabled
              >CANCEL</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >SAVE</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>

      <md-card-content v-else-if="formType == 'Edit Client'">
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('required') },
                { 'md-valid': !errors.has('required') && touched.required },
              ]"
            >
              <label>Business Name</label>
              <md-input
                v-model="clientDetail.name"
                data-vv-name="required"
                type="text"
                v-validate="modelValidations.required"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('required')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('required') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Street</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('equalToDest') },
                {
                  'md-valid': !errors.has('equalToDest') && touched.equalToDest,
                },
              ]"
            >
              <label>Street</label>
              <md-input
                v-model="equalToDest"
                data-vv-name="equalToDest"
                data-vv-as="equalToSource"
                type="text"
                v-validate="modelValidations.equalToDest"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToDest')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToDest') && touched.equalToDest"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label for="Country">Country</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="State">State</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label for="City">City</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="Pincode">Pincode</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Cell#</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item"></div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <!-- <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource
                    }
                  ]"
                >
                  <label>E-mail</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                    >done</md-icon>
                  </slide-y-down-transition>
                </md-field>

                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource
                    }
                  ]"
                >
                  <label>Password</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                    >done</md-icon>
                  </slide-y-down-transition>
                </md-field> -->

            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Packages</label>
              <md-input
                v-model="clientDetail.age"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>

            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Validity</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item">
            <div class="logo-text-cls">Logo Preview</div>
            <img class="img logo-align" slot="imageHeader" :src="product1" />
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <label class="toggle-align">Status</label>
            <md-switch v-model="switch2"></md-switch>
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              disabled
              >CANCEL</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >SAVE</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>

      <md-card-content v-else-if="formType == 'View Client'">
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('required') },
                { 'md-valid': !errors.has('required') && touched.required },
              ]"
            >
              <label>Business Name</label>
              <md-input
                v-model="clientDetail.name"
                data-vv-name="required"
                type="text"
                v-validate="modelValidations.required"
                disabled
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('required')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('required') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Street</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('equalToDest') },
                {
                  'md-valid': !errors.has('equalToDest') && touched.equalToDest,
                },
              ]"
            >
              <label>Street</label>
              <md-input
                v-model="equalToDest"
                data-vv-name="equalToDest"
                data-vv-as="equalToSource"
                type="text"
                v-validate="modelValidations.equalToDest"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToDest')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToDest') && touched.equalToDest"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label for="Country">Country</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="State">State</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label for="City">City</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="Pincode">Pincode</label>
              <md-select>
                <md-option value="fight-club">Fight Club</md-option>
                <md-option value="godfather">Godfather</md-option>
                <md-option value="godfather-ii">Godfather II</md-option>
                <md-option value="godfather-iii">Godfather III</md-option>
                <md-option value="godfellas">Godfellas</md-option>
                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                <md-option value="scarface">Scarface</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Cell#</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item"></div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <!-- <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource
                    }
                  ]"
                >
                  <label>E-mail</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                    >done</md-icon>
                  </slide-y-down-transition>
                </md-field>

                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource
                    }
                  ]"
                >
                  <label>Password</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                    >done</md-icon>
                  </slide-y-down-transition>
                </md-field> -->

            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Packages</label>
              <md-input
                v-model="clientDetail.age"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
                disabled
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>

            <md-field
              :class="[
                { 'md-error': errors.has('equalToSource') },
                {
                  'md-valid':
                    !errors.has('equalToSource') && touched.equalToSource,
                },
              ]"
            >
              <label>Validity</label>
              <md-input
                v-model="equalToSource"
                data-vv-name="equalToSource"
                ref="equalToSource"
                type="text"
                v-validate="modelValidations.equalToSource"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('equalToSource')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('equalToSource') && touched.equalToSource"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item">
            <div class="logo-text-cls">Logo Preview</div>
            <img class="img logo-align" slot="imageHeader" :src="product1" />
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <label class="toggle-align">Status</label>
            <md-switch v-model="switch2"></md-switch>
          </div>
        </div>

        <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              disabled
              >CANCEL</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >SAVE</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
export default {
  components: {
    SlideYDownTransition,
  },
  props: {},
  data() {
    return {
      product1: './img/card-2.jpg',
      equalToSource: '',
      equalToDest: '',
      required: '',
      email: '',
      number: '',
      url: '',
      switch2: null,
      touched: {
        required: false,
        email: false,
        number: false,
        url: false,
        equalToSource: false,
        equalToDest: false,
      },
      modelValidations: {
        required: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        number: {
          required: true,
          numeric: true,
        },
        url: {
          required: true,
          url: true,
        },
        equalToSource: {
          required: true,
        },
        equalToDest: {
          required: true,
          confirmed: 'equalToSource',
        },
      },
      formType: {
        type: String,
        default: 'Add Client',
      },
      clientDetail: {
        default: '',
      },
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit('on-submit', this.registerForm, isValid)
      })
    },
  },
  created() {
    this.formType = this.$route.params.formType
    this.clientDetail = this.$route.params.client
  },
  watch: {
    required() {
      this.touched.required = true
    },
    email() {
      this.touched.email = true
    },
    number() {
      this.touched.number = true
    },
    url() {
      this.touched.url = true
    },
    equalToSource() {
      this.touched.equalToSource = true
    },
    equalToDest() {
      this.touched.equalToDest = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
.btn-div {
  border-top: 1px solid gray;
}
</style>
