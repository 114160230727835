import { environment } from '../../config'
import axios from 'axios'
import { accountService } from './account.js'

var baseUrl = `${environment.api.host}`

export const dashboardService = {
  getDashboardSales,
  getSalesCatMix,
  getPaymentMix,
  getMenuCatMix,
  getHourlySales,
  getOutletCount,
  getDashboardSalesOnline,
}

function authFun() {
  const auth = {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
    },
  }
  return auth
}

function getOutletId(data) {
  if (accountService.userRole() == 'branch_user') {
    //console.log('report data:', data.outletId)
    return data.outletId
  } else {
    return accountService.getOutletId()
  }
}

// Dashboard functions

function getSalesCatMix(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.dashboard.sales_category_mix}` +
      '?start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&search_type=' +
      queries.search_type +
      '&outlet_id=' +
      getOutletId(queries),
    authFun()
  )
}

function getDashboardSales(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.dashboard.dashboard_sales}` +
      '?start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&search_type=' +
      queries.search_type +
      '&outlet_id=' +
      getOutletId(queries),
    authFun()
  )
}

function getDashboardSalesOnline(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.dashboard.online_sales}` +
      '?start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&search_type=' +
      queries.search_type +
      '&outlet_id=' +
      getOutletId(queries),
    authFun()
  )
}

function getPaymentMix(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.dashboard.payment_mix}` +
      '?start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&search_type=' +
      queries.search_type +
      '&outlet_id=' +
      getOutletId(queries),
    authFun()
  )
}

function getMenuCatMix(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.dashboard.menu_category_mix}` +
      '?start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&search_type=' +
      queries.search_type +
      '&outlet_id=' +
      getOutletId(queries),
    authFun()
  )
}

function getHourlySales(queries) {
  return axios.get(
    baseUrl +
      `${environment.api.services.dashboard.hourly_sales}` +
      '?start_date=' +
      queries.startDate +
      '&end_date=' +
      queries.endDate +
      '&search_type=' +
      queries.search_type +
      '&outlet_id=' +
      getOutletId(queries),
    authFun()
  )
}

function getOutletCount() {
  if (accountService.userRole() == 'admin')
    return axios.get(
      baseUrl + `${environment.api.services.customize.distributor}`,
      {},
      authFun()
    )
}

function getOnlineSales() {
  if (accountService.userRole() == 'admin')
    return axios.get(
      baseUrl + `${environment.api.services.customize.distributor}`,
      {},
      authFun()
    )
}
