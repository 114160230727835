<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/Settings/Station' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType == 'View Station'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editSupercat"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                v-model="station.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Station'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('copyCount') },
                { 'md-valid': !errors.has('copyCount') && touched.copyCount },
              ]"
            >
              <label>{{ $t('Copy Count') }}</label>
              <md-input
                v-model="station.copy_count"
                data-vv-name="copyCount"
                type="number"
                v-validate="modelValidations.copyCount"
                :disabled="formType == 'View Station'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('copyCount')">
                  {{ errors.first('copyCount') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('copyCount') && touched.copyCount"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Retry Timeout (Seconds)') }}</label>
              <md-input
                v-model="station.retry_time_out"
                type="number"
                :disabled="formType == 'View Station'"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>Backup Printer</label>
              <md-select v-model="station.back_up_station">
                <md-option
                  v-for="item in printers"
                  :key="item.id"
                  :value="item.id"
                  class="oulet-select-list-item"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-checkbox v-model="station.allow_local_printer">{{
              $t(' Local Printer?')
            }}</md-checkbox>
          </div>

          <div class="md-layout-item md-size-50">
            <md-checkbox v-model="station.is_consolidated">{{
              $t('Consolidated?')
            }}</md-checkbox>
          </div>
        </div>

        <div class="md-layout" v-if="station.name == 'Receipt'">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Receipt Header') }}</label>
              <md-input
                v-model="station.bill_header"
                type="text"
                :disabled="formType == 'View Station'"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Order Header') }}</label>
              <md-input
                v-model="station.kot_screen_name"
                type="text"
                :disabled="formType == 'View Station'"
              ></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout" v-if="station.name == 'Receipt'">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Receipt Address ') }}</label>
              <md-textarea
                v-model="station.address"
                type="text"
                :disabled="formType == 'View Station'"
              ></md-textarea>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Receipt Footer ') }}</label>
              <md-textarea
                v-model="station.bill_footer"
                type="text"
                :disabled="formType == 'View Station'"
              ></md-textarea>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Status') },
                { 'md-valid': !errors.has('Status') && touched.Status },
              ]"
            >
              <label>{{ $t('Status') }}</label>
              <md-select
                v-model="station.is_active"
                :disabled="formType == 'View Station'"
                data-vv-name="Status"
                v-validate="modelValidations.Status"
              >
                <md-option class="first-opt" disabled>Status</md-option>
                <md-option value="true">{{ $t('Active') }}</md-option>
                <md-option value="false">{{ $t('Inactive') }}</md-option>
              </md-select>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Status')">
                  {{ errors.first('Status') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Status') && touched.Status"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Station'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/Settings/Station' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
          <button>SAVE</button>-->
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import axios from 'axios'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      copyCount: '',
      Status: '',
      switch2: null,
      Name: '',
      touched: {
        Name: false,
        copyCount: false,
        Status: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
        copyCount: {
          required: true,
        },
        Status: {
          required: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Client',
      },
      clientDetail: {
        default: '',
      },
      station: {
        outlet_id: '',
      },
      loader: false,
      printers: [],
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createStation()
          }
        })
      }
    },

    createStation() {
      let self = this
      if (this.formType == 'Add Station') {
        customizeService
          .createStation(this.station)
          .then((res) => {
            self.$router.push({ name: 'Station' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        let outlet_id = accountService.getOutletId()
        this.station.outlet_id = outlet_id
        let body = {
          stations: this.station,
        }
        axios
          .put(
            this.url + '?outlet_id=' + outlet_id,
            body,
            customizeService.authFun()
          )
          .then((res) => {
            self.$router.push({ name: 'Station' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      }
    },
    fetchPrinters() {
      customizeService.getStation().then((res) => {
        this.printers = res.data.api_v1_station
      })
    },
    reset() {
      this.station = {}
    },

    editSupercat() {
      this.formType = 'Edit Station'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    this.fetchPrinters()

    if (this.formType != 'Add Station') {
      this.loader = true
      axios
        .get(
          this.url + '?chain_id=' + accountService.getChainId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.loader = false
          this.station = res.data.api_v1_station
        })
    }
  },
  watch: {
    Name() {
      this.touched.required = true
    },
    copyCount() {
      this.touched.required = true
    },
    Status() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
