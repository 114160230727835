<template>
  <div>
    <md-card class="annual-sales block-bg">
      <md-card-header class="md-card-header-text md-layout md-gutter">
        <div class="md-layout-item md-size-100">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Yearly Sales') }}</h3>
        </div>
      </md-card-header>

      <div class="md-layout filter-section search-options block-bg">
        <div class="md-layout-item md-size-10">
          <md-field>
            <label>Year</label>
            <md-select v-model="queries.year" @md-closed="yearlySales()">
              <md-option
                v-for="(item, index) in years"
                :key="index"
                :value="item"
                >{{ item }}</md-option
              >
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item" v-if="userRole == 'branch_user'">
          <md-field>
            <label>Outlets</label>
            <md-select
              v-model="queries.outletId"
              multiple
              class="oulet-select-list"
              @md-closed="
                queries.outletId.length > 0
                  ? yearlySales()
                  : (queries.outletId = [])
              "
            >
              <md-option
                v-for="item in outlets"
                :key="item.id"
                :value="item.id"
                class="oulet-select-list-item"
                >{{ item.name }}</md-option
              >
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-button class="md-teritiary" @click="yearlySales"
            >SEARCH</md-button
          >
        </div>
      </div>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <div class="md-layout">
        <div class="chart-container" style="position: relative; width: 50vw">
          <bar-chart
            :chart-data="datacollection"
            :options="chartOptions"
          ></bar-chart>
        </div>

        <div
          class="md-layout-item monthly-sales pad-r-15px pad-b-15px md-size-100"
        >
          <md-table class="table-typ1 report-table">
            <md-table-toolbar>
              <div class="main-heading">
                <div class="md-layout-item md-size-50 text-white">
                  Order Type
                </div>
                <div class="md-layout-item md-size-50 text-white">
                  Payment Type
                </div>
              </div>
              <div class="action-buttons export-btn">
                <div class="pdf-el">
                  <div class="download-btn">
                    <img
                      src="../../../../assets/images/pdf.svg"
                      @click="downloadWithoutCSS()"
                    />
                  </div>
                </div>

                <div class="excel-el">
                  <vue-excel-xlsx
                    class="Excel-btn"
                    :data="report"
                    :columns="columns"
                    :filename="'Annual Sales'"
                    :sheetname="'Annual Sales'"
                    :outlet-names="selectedOutletsNames()"
                  >
                  </vue-excel-xlsx>
                </div>
              </div>
            </md-table-toolbar>

            <md-table-row>
              <md-table-head>Date</md-table-head>
              <md-table-head>Dine In</md-table-head>
              <md-table-head>Take Out</md-table-head>
              <md-table-head>Quick Service</md-table-head>
              <md-table-head>Refund</md-table-head>
              <md-table-head>Web Order</md-table-head>
              <md-table-head>Total Sales</md-table-head>
              <md-table-head>Date</md-table-head>
              <md-table-head>Cash</md-table-head>
              <md-table-head>Card</md-table-head>
              <md-table-head>Other</md-table-head>
              <md-table-head>Voucher</md-table-head>
              <md-table-head>Total Sales</md-table-head>
            </md-table-row>

            <md-table-row v-for="(item, index) in report" :key="index">
              <md-table-cell>{{ item.order_month }}</md-table-cell>
              <md-table-cell>{{ item.din_in.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.take_out.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.quick_service.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.refund.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.web_order.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.bill_total.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.payment_month }}</md-table-cell>
              <md-table-cell>{{ item.cash.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.credit.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.other_amt.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.voucher.toFixed(2) }}</md-table-cell>
              <md-table-cell>{{ item.bill_total.toFixed(2) }}</md-table-cell>
            </md-table-row>

            <md-table-row>
              <slot v-for="(items, index) in totals">
                <md-table-cell v-if="index != 0 && index != 7" :key="index">
                  {{ items }}</md-table-cell
                >
                <md-table-cell v-else :key="index"> Total</md-table-cell>
              </slot>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { accountService } from '../../../../services/account.js'
import { customizeService } from '../../../../services/customize.js'
import { reportService } from '../../../../services/reports.js'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import LineChart from '../../../../components/Graphs/LineChart.vue'
import BarChart from '../../../../components/Graphs/BarChart.vue'
import DateTimePicker from '../../../../components/DateTimePicker.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'

export default {
  components: {
    LineChart,
    ChartCard,
    ClipLoader,
    VueExcelXlsx,
    BarChart,
  },
  data() {
    return {
      sdate: '',
      edate: '',
      search: null,
      searched: [],
      report: [],
      columns: [
        {
          label: 'Date',
          field: 'order_month',
        },
        {
          label: 'Din In',
          field: 'take_out',
        },
        {
          label: 'Quick Service',
          field: 'quick_service',
        },
        {
          label: 'Total Sales',
          field: 'order_total',
        },
        {
          label: 'Date',
          field: 'payment_month',
        },
        {
          label: 'Cash',
          field: 'cash',
        },
        {
          label: 'Credit',
          field: 'credit',
        },
        {
          label: 'Other',
          field: 'other_amt',
        },
        {
          label: 'Voucher',
          field: 'voucher',
        },
        {
          label: 'Total Sales',
          field: 'bill_total',
        },
      ],
      columnStyles: {
        0: { columnWidth: 23 },
        1: { columnWidth: 22 },
        2: { columnWidth: 22 },
        3: { columnWidth: 25 },
        4: { columnWidth: 20 },
        5: { columnWidth: 24 },
        6: { columnWidth: 23 },
        7: { columnWidth: 23 },
        8: { columnWidth: 20 },
        9: { columnWidth: 20 },
        10: { columnWidth: 20 },
        11: { columnWidth: 22 },
        12: { columnWidth: 23 },
      },
      datacollection: null,
      queries: {
        year: new Date().getFullYear(),
        outletId: [],
      },
      totals: ['Total Sales', 0, 0, 0, 0, 0, 0, 'Total Sales', 0, 0, 0, 0, 0],
      years: [],
      userRole: '',
      outlets: [],
      loader: false,

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        layout: {
          padding: {
            left: 10,
            right: 20,
            top: 30,
            bottom: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                fontSize: 14,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 14,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    }
  },

  methods: {
    setDateType() {
      this.yearlySales()
    },
    fillData() {
      this.datacollection = {
        labels: [],
        datasets: [
          {
            label: 'Monthly Sales',
            data: [],
            fill: 'true',
            borderColor: 'red',
            backgroundColor: '#00E0DB',
            barThickness: 30,
          },
        ],
      }
      this.report.forEach((el) => {
        this.datacollection.labels.push(el.order_month)
        this.datacollection.datasets[0].data.push(el.order_total)
      })
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = [
          el.order_month,
          el.din_in,
          el.take_out,
          el.quick_service,
          el.refund,
          el.web_order,
          el.bill_total,
          el.payment_month,
          el.cash,
          el.credit,
          el.other_amt,
          el.voucher,
          el.bill_total,
        ]
        body.push(arrayObj)
      })
      let totObj = [
        'Total',
        this.totals[1].toFixed(2),
        this.totals[2].toFixed(2),
        this.totals[3].toFixed(2),
        this.totals[4].toFixed(2),
        this.totals[5].toFixed(2),
        this.totals[6].toFixed(2),
        'Total',
        this.totals[8].toFixed(2),
        this.totals[9].toFixed(2),
        this.totals[10].toFixed(2),
        this.totals[11].toFixed(2),
        this.totals[12].toFixed(2),
      ]
      body.push(totObj)

      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.queries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },

    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      this.queries.startDate = this.sdate
      this.queries.endDate = this.edate
      let head = [
        [
          'Date',
          'Din In',
          'Take Out',
          'Q Services',
          'Refund',
          'Web Ord',
          'Tot Sales',
          'Date',
          'Cash',
          'Card',
          'Other',
          'Voucher',
          'Tot Sales',
        ],
      ]
      pdfService.headerFooterConfig(
        this.queries,
        doc,
        'Annual Sales',
        selectedOutlets
      )
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        this.columnStyles,
        'Annual-sales.pdf'
      )
    },

    getYear() {
      let currentYear = new Date().getFullYear()

      for (var i = currentYear; i >= 2010; i--) {
        this.years.push(i)
      }
    },

    yearlySales() {
      this.loader = true
      reportService.getYearlySales(this.queries).then((res) => {
        this.totals = [
          'Total Sales',
          0,
          0,
          0,
          0,
          0,
          0,
          'Total Sales',
          0,
          0,
          0,
          0,
          0,
        ]

        this.loader = false
        this.report = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate
        this.report.forEach((el) => {
          this.totals[1] += parseFloat(el.din_in)
          this.totals[2] += parseFloat(el.take_out)
          this.totals[3] += parseFloat(el.quick_service)
          this.totals[4] += parseFloat(el.refund)
          this.totals[5] += parseFloat(el.web_order)
          this.totals[6] += parseFloat(el.bill_total)

          this.totals[8] += el.cash
          this.totals[9] += el.credit
          this.totals[10] += el.other_amt
          this.totals[11] += el.voucher
          this.totals[12] += el.bill_total
        })

        this.totals = this.totals.map((total) => Math.round(total * 100) / 100)
        this.fillData()
      })
    },
  },

  created() {
    this.loader = true
    this.userRole = accountService.userRole()
    this.getYear()
    this.searched = this.users
    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.yearlySales()
      })
    } else {
      this.yearlySales()
    }
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}
.main-heading {
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 18px;
}

.toolbar-monthly {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.search-options {
  padding-top: 12px;
  padding-left: 50px;
}
</style>
