<template>
  <div class="subcat-report menu-item-rep">
    <md-card class="report-card block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 report-name">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Detailed Tax Report') }}</h3>
        </div>
      </md-card-header>

      <div class="md-layout">
        <div class="md-layout-item filter-section block-bg">
          <div class="md-layout search-section">
            <div class="md-layout-item md-size-55 pd-l-0">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout-item md-size-35 pd-l-10">
              <div class="md-layout">
                <div
                  :class="[
                    { 'md-size-30': role() == 'branch_user' },
                    { 'md-size-30': role() != 'branch_user' },
                  ]"
                  class="md-layout-item md-small-size-100 pd-l-0"
                >
                  <!-- <div class="md-layout pd-l-0">
                  <div class="md-layout-item md-size-50 md-small-size-50 pd-l-0"> -->
                  <label>Category</label>
                  <md-field>
                    <md-select v-model="data.superCatId">
                      <md-option
                        v-for="item in superCatList"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
                <div
                  :class="[
                    { 'md-size-25': role() == 'branch_user' },
                    { 'md-size-25': role() != 'branch_user' },
                  ]"
                  class="md-layout-item md-small-size-100 pd-l-10"
                >
                  <label>Tax Rate (%)</label>
                  <md-field>
                    <md-select v-model="data.taxId">
                      <md-option
                        v-for="item in taxList"
                        :key="item"
                        :value="item"
                        >{{ item }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-size-45 md-small-size-45 pd-l-10"
                  v-if="role() == 'branch_user'"
                >
                  <label>Outlet</label>
                  <md-field>
                    <md-select
                      v-model="outletId"
                      multiple
                      @md-closed="
                        outletId.length > 0
                          ? detailedTaxReport()
                          : (outletId = [])
                      "
                    >
                      <md-option
                        v-for="item in outletList"
                        :key="item.id"
                        :value="item.id"
                        class="oulet-select-list-item"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
                <!-- </div>
                </div> -->
              </div>
            </div>
            <div class="md-layout-item md-size-10">
              <div class="float-right">
                <md-button class="md-teritiary" @click="detailedTaxReport()"
                  >Search
                </md-button>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div
          class="md-layout-item md-size-100 card-inside report-table"
          v-if="!loader"
        >
          <div class="xlsx-pdf">
            <img
              class="pdf"
              src="../../../../assets/images/pdf.svg"
              @click="downloadWithoutCSS()"
            />
            <vue-excel-xlsx
              class="excel-btn2 md-primary"
              :data="reportApiRes"
              :columns="columns"
              :filename="'Detailed Tax Report'"
              :sheetname="'Detailed Tax Report'"
              :outlet-names="selectedOutletsNames()"
            ></vue-excel-xlsx>
          </div>

          <md-table class="rep-table table-typ1" v-if="!loader">
            <!-- <md-table-toolbar>
              <div class="md-toolbar-section-start"></div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable" />
              </md-field>
            </md-table-toolbar>-->
            <md-table-row>
              <md-table-head>SKU</md-table-head>
              <md-table-head>Order Type</md-table-head>
              <md-table-head>Category</md-table-head>
              <md-table-head>Menu Item Name</md-table-head>
              <md-table-head>Sales Qty</md-table-head>
              <md-table-head>Tax Rate</md-table-head>
              <md-table-head>Sales Ex Tax</md-table-head>
              <md-table-head>Discount</md-table-head>
              <md-table-head>Tax On Sales</md-table-head>
              <md-table-head>Sales Value</md-table-head>
            </md-table-row>
            <slot v-for="item in reportApiRes">
              <md-table-row slot="md-table-row">
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.sku }}</md-table-cell
                >
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.order_type }}</md-table-cell
                >
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.category }}</md-table-cell
                >
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.menu_item_name }}</md-table-cell
                >
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.sales_qty }}</md-table-cell
                >
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.tax_rate }}</md-table-cell
                >
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.sales_ex_tax }}</md-table-cell
                >
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.discount }}</md-table-cell
                >
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.tax_on_sale }}</md-table-cell
                >
                <md-table-cell
                  :class="{
                    'text-bold': item.is_bold,
                  }"
                  >{{ item.sales_value }}</md-table-cell
                >
              </md-table-row>
            </slot>
          </md-table>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { reportService } from './../../../../services/reports'
import { customizeService } from './../../../../services/customize'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import { DateTimePicker } from '@/components'
import { accountService } from '../../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'

export default {
  components: {
    ChartCard,
    DateTimePicker,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
  },
  data() {
    return {
      columns: [
        {
          label: 'Sku',
          field: 'sku',
        },
        {
          label: 'Order Type',
          field: 'order_type',
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Menu Item Name',
          field: 'menu_item_name',
        },
        {
          label: 'Sales Qty',
          field: 'sales_qty',
        },
        {
          label: 'Tax Rate',
          field: 'tax_rate',
        },
        {
          label: 'Sales Ex Tax',
          field: 'sales_ex_tax',
        },
        {
          label: 'Discount',
          field: 'discount',
        },
        {
          label: 'Tax On Sales',
          field: 'tax_on_sale',
        },
        {
          label: 'Sales Value',
          field: 'sales_value',
        },
      ],
      columnStyles: {
        0: { cellWidth: 26 },
        1: { cellWidth: 26 },
        2: { cellWidth: 26 },
        3: { cellWidth: 26 },
        4: { cellWidth: 26 },
        5: { cellWidth: 26 },
        6: { cellWidth: 26 },
        7: { cellWidth: 26 },
        8: { cellWidth: 26 },
        9: { cellWidth: 26 },
      },
      data: {
        search_type: 'Daily',
      },
      superCatList: [],
      reportApiRes: [],
      taxList: [],
      outletList: [],
      outletId: [],
      loader: false,
      sdate: '',
      edate: '',
    }
  },

  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
    },

    arrangeForPdf() {
      var body = []
      this.reportApiRes.forEach((el) => {
        let arrayObj = [
          el.sku,
          el.order_type,
          el.category,
          el.menu_item_name,
          el.sales_qty,
          el.tax_rate,
          el.sales_ex_tax,
          el.discount,
          el.tax_on_sale,
          el.sales_value,
        ]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },

    downloadWithoutCSS() {
      let selectedOutlets = this.selectedOutletsNames()
      let body = this.arrangeForPdf()
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      this.data.startDate = this.sdate
      this.data.endDate = this.edate
      let head = [
        [
          'SKU',
          'Order Type',
          'Category',
          'Menu Item Name',
          'Sales Qty',
          'Tax Rate',
          'Sales Ex Tax',
          'Discount',
          'Tax On Sales',
          'Sales Value',
        ],
      ]

      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Detailed Tax Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'detailed-tax-report.pdf')
    },
    getSupperCategory() {
      customizeService.getMaincat().then((res) => {
        this.superCatList = res.data.api_v1_main_category
        this.superCatList.unshift({ id: 'undefined', name: 'All' })
      })
    },
    getTax() {
      customizeService.getTaxes().then((res) => {
        this.taxList = res.data.result[0].tax.taxes
        this.taxList.unshift('All')
        this.data.taxId = 'All'
      })
    },
    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
        if (!this.loader) {
          this.detailedTaxReport()
        }
      }
    },
    detailedTaxReport() {
      this.loader = true
      this.data.outletId = this.outletId
      reportService.getDetailedTaxReport(this.data).then((res) => {
        this.loader = false
        this.reportApiRes = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.detailedTaxReport()
      })
    },
    role() {
      return accountService.userRole()
    },
  },

  created() {
    this.searched = this.users
    this.loader = true
    this.getSupperCategory()
    this.getTax()
    this.getOutlets()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}
.md-table.md-theme-default .md-table-content {
  display: none;
}
.pd-l-0 {
  padding-left: 0px;
}

.md-button-content {
  font-size: 12px;
}
.menu-item-rep .md-ripple {
  padding: 6px 15px;
  height: auto !important;
  border-radius: 0px;
}
.content .menu-item-rep .md-ripple {
  padding: 0px !important;
}
.card-inside {
  .md-button {
    .md-ripple {
      padding: 0px 0px;
    }
  }
}
.rep-table {
  margin-top: 0px;
}
</style>
