import { mapGetters, mapActions } from 'vuex'
import store from '@/store'
import Swal from 'sweetalert2'
import { reusableService } from '@/services/reusable.js'

export const screenDesignMixin = {
  data() {
    return {
      // selectedMenus: [],
      activeMenuPage: '',
      selectedMenus: {},
      currentIndex: 0,
    }
  },
  watch: {
    chunkedGrid(newVal, oldVal) {
      this.$nextTick(() => {
        this.addTouchListeners()
        if (newVal.length != oldVal) {
          this.showLast()
        }
      })
    },
  },
  computed: {
    chunkedGrid() {
      if (this.grid) {
        let chunkSize = 64
        switch (this.menuPageSelected.screen_size) {
          case 'grid8by8':
            chunkSize = 64
            break
          case 'grid4by4':
            chunkSize = 16
            break
          default:
            chunkSize = 64
        }
        let chunks = []
        for (let i = 0; i < this.grid.length; i += chunkSize) {
          chunks.push(this.grid.slice(i, i + chunkSize))
        }
        return chunks
      }
      return []
    },
  },
  methods: {
    ...mapActions(['setSelectedMenus']),
    isNotBlank() {
      debugger
      const hasMenuItemId = this.chunkedGrid[this.chunkedGrid.length - 1].some(
        (item) => item.menu_item_id != null && item.menu_item_id != ''
      )

      return hasMenuItemId
    },

    // Slick carosal
    handleAfterChange(currentIndex) {
      this.activeIndex = currentIndex
    },
    addTouchListeners() {
      var vm = this
      let allSlides = document.getElementsByClassName('screen-listing')

      allSlides.forEach((slide) => {
        // slide.addEventListener('mousedown', vm.showPrev)
        // slide.addEventListener('mouseup', vm.showNext)

        slide.addEventListener('touchstart', vm.handleTouchStart)
        slide.addEventListener('touchend', vm.handleTouchEnd)
        slide.addEventListener('mousedown', vm.handleMouseDown)
      })
    },
    handleTouchStart(e) {
      this.startX = e.touches[0].clientX
      this.startY = e.touches[0].clientY
    },
    handleTouchEnd(e) {
      this.endX = e.changedTouches[0].clientX
      this.endY = e.changedTouches[0].clientY
      this.detectSwipe()
    },
    handleMouseDown(e) {
      this.startX = e.clientX
      this.startY = e.clientY

      const handleMouseMove = (e) => {
        this.endX = e.clientX
        this.endY = e.clientY
      }

      const handleMouseUp = (e) => {
        this.endX = e.clientX
        this.endY = e.clientY
        this.detectSwipe()
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    },
    detectSwipe() {
      const deltaX = this.endX - this.startX
      const deltaY = this.endY - this.startY
      if (deltaX != 0 && deltaY != 0) {
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          // Horizontal swipe
          if (deltaX > 0) {
            console.log('Swipe right')
          } else {
            console.log('Swipe left')
          }
        } else {
          // Vertical swipe
          if (deltaY > 0) {
            this.showPrevious()
            console.log('Swipe down')
          } else {
            this.showNext()
            console.log('Swipe up')
          }
        }
      }
    },
    showNext() {
      this.$refs.carousel.next()
    },
    showPrevious() {
      this.$refs.carousel.prev()
    },
    showLast() {
      this.$refs.carousel.goTo(this.chunkedGrid.length - 1)
    },
    removeChunk() {
      reusableService.showSwal('Delete').then((result) => {
        if (result.value) {
          const chunkIndex = this.activeIndex || this.chunkedGrid.length - 1
          const chunkSize = 64
          const startIndex = chunkIndex * chunkSize
          const endIndex = startIndex + chunkSize
          if (startIndex >= 0 && startIndex < this.grid.length) {
            this.grid.splice(startIndex, chunkSize)
          }
        }
      })
    },

    toggleSelection(id) {
      this.selectedMenus = JSON.parse(
        JSON.stringify(store.state.screen.allSelectedMenus)
      )
      if (id >= 0) {
        // const index = this.selectedMenus.indexOf(id)
        // if (index > -1) {
        //   // Item is already selected, remove it
        //   this.selectedMenus.splice(index, 1)
        // } else {
        //   // Item is not selected, add it
        //   this.selectedMenus.push(id)
        // }
        // if (!this.selectedMenus[this.activeTab]) {
        //   this.$set(this.selectedMenus, this.activeTab, [])
        // }
        if (!this.selectedMenus[this.activeTab]) {
          // this.$set(this.selectedMenus, this.activeTab, [])
          this.selectedMenus[this.activeTab] = []
        }
        const index = this.selectedMenus[this.activeTab].indexOf(id)
        if (index > -1) {
          // Item is already selected, remove it
          this.selectedMenus[this.activeTab].splice(index, 1)
        } else {
          // Item is not selected, add it
          this.selectedMenus[this.activeTab].push(id)
        }
        this.setSelectedMenus(JSON.parse(JSON.stringify(this.selectedMenus)))

        // this.$emit('item-seleted', this.selectedMenus)
      }
    },
    conditionFlagGreen(itemId, type) {
      if (this.selectFlagGreen.some((el) => el.id == itemId && el.type == type))
        return true
      else return false
    },

    externalBoxEnter(event, box) {
      event.dataTransfer.setData('box-id', box.id)
      event.dataTransfer.setData('box-name', box.name)
      event.dataTransfer.setData('box-colour', box.color)
      event.dataTransfer.setData('box-price', box.price.toFixed(2))
    },
    externalBoxLeave(event) {
      event.target.classList.remove('dragstart', 'hide')
    },
    boxEnter(event) {
      event.target.classList.add('dragstart')
      event.dataTransfer.setData('text/plain', event.target.dataset.zone)
    },
    boxLeave(event) {
      event.target.classList.remove('dragstart', 'hide')
    },
    dragEnter(event) {
      event.preventDefault()
    },
    dragOver(event) {
      event.preventDefault()
    },
    dropBox(event, zone) {
      //   return (event) => {
      const newUUid = Math.max(...this.grid.map((o) => o.uuid))
      const boxNumber = event.dataTransfer.getData('text/plain')
      const fromZone = this.findZoneByNumber(boxNumber)
      const toZone = this.findZoneByNumber(zone.i)
      let fZone = JSON.parse(JSON.stringify(fromZone))

      if (toZone.item_name !== null && fromZone.i != toZone.i) {
        Swal.fire({
          title: 'Are you sure?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Replace it!',
          cancelButtonText: 'Swap it!',
        }).then((result) => {
          if (result.isConfirmed) {
            // Swal.fire({
            //   title: 'Deleted!',
            //   text: 'Your file has been deleted.',
            //   icon: 'success',
            // })
            toZone.item_name = fZone.item_name
            toZone.colour = fZone.colour
            toZone.menu_item_id = fZone.menu_item_id
            toZone.price = fZone.price

            fromZone.menu_item_id = null
            fromZone.item_name = null
            fromZone.price = null
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            const tempToZone = JSON.parse(JSON.stringify(toZone))
            toZone.item_name = fZone.item_name
            toZone.colour = fZone.colour
            toZone.menu_item_id = fZone.menu_item_id
            toZone.price = fZone.price

            fromZone.menu_item_id = tempToZone.menu_item_id
            fromZone.colour = tempToZone.colour
            fromZone.item_name = tempToZone.item_name
            fromZone.price = tempToZone.price
          }
        })

        return
      }

      // If the drop is from an external source (boxNumber will be empty)
      if (!boxNumber) {
        const externalBoxNumber = event.dataTransfer.getData('box-name')
        const externalBoxColour = event.dataTransfer.getData('box-colour')
        const externalBoxId = event.dataTransfer.getData('box-id')
        const externalBoxPrice = parseFloat(
          event.dataTransfer.getData('box-price')
        )
        if (toZone) {
          toZone.item_name = externalBoxNumber
          toZone.colour = externalBoxColour
          toZone.menu_item_id = externalBoxId
          toZone.price = externalBoxPrice

          toZone.uuid = newUUid + 1
        }
      } else {
        if (fromZone && toZone && fromZone.i != toZone.i) {
          const toUUId = toZone.uuid
          toZone.item_name = fZone.item_name
          toZone.colour = fZone.colour
          toZone.menu_item_id = fZone.menu_item_id
          toZone.uuid = fZone.uuid
          toZone.price = fZone.price

          fromZone.item_name = null
          fromZone.menu_item_id = null
          fromZone.uuid = toUUId
          fromZone.price = null
        }
      }
      event.target.classList.remove('dragstart', 'hide')

      const sound = new Audio(
        'https://assets.mixkit.co/active_storage/sfx/3005/3005-preview.mp3'
      )
      sound.play()
      //   }
    },
    findZoneByNumber(number) {
      // for (let row of this.grid) {
      //   for (let zone of row) {
      //     if (zone.i == number) {
      //       return zone
      //     }
      //   }
      // }
      // return null

      return this.grid.find((zone) => zone.i == number)
    },

    removeFromGrid(item) {
      const zone = this.findZoneByNumber(item.i)
      zone.menu_item_id = null
      zone.item_name = null
      let filtered = JSON.parse(
        JSON.stringify(store.state.screen.allSelectedMenus)
      )
      if (filtered[this.activeTab]) {
        filtered[this.activeTab] = filtered[this.activeTab].filter(function (
          number
        ) {
          return number != item.uuid
        })
        this.setSelectedMenus(JSON.parse(JSON.stringify(filtered)))
      }

      zone.uuid = null
    },
    // addToGrid(item) {
    //   var emptyBox = this.grid.find((el) => el.menu_item_id == null)
    //   emptyBox.menu_item_id = item.id
    //   emptyBox.item_name = item.name
    // },

    editMenuItem(item) {
      this.$emit('menu-item-seleted', item)
    },
  },
}
