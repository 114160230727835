<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header
            class="md-card-header-text md-card-header-green md-layout md-gutter"
          >
            <div class="md-layout-item md-size-70">
              <span class="arrow_left">
                <div class="back-icon" @click="cancelEdit">
                  <img src="../../../assets/images/left.svg" />
                </div>
              </span>
              <h3>{{ formType }}</h3>
            </div>
            <div class="md-layout-item md-size-30">
              <i
                class="md-icon md-icon-font md-theme-default edit-img"
                @click="editDistributor"
                v-if="formType == 'View Distributor'"
                >edit</i
              >
            </div>
          </md-card-header>

          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field
                  :class="[
                    { 'md-error': errors.has('Name') },
                    { 'md-valid': !errors.has('Name') },
                  ]"
                >
                  <label>{{ $t('Name') }}</label>
                  <md-input
                    type="text"
                    v-model="distributorDetails.distributor.name"
                    data-vv-name="Name"
                    v-validate="modelValidations.Name"
                    v-if="formType != 'View Distributor'"
                  ></md-input>
                  <md-input
                    type="text"
                    v-model="distributorDetails.distributor.name"
                    data-vv-name="Name"
                    v-validate="modelValidations.Name"
                    v-else
                    disabled
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('Name')">
                      {{ errors.first('Name') }}
                    </div>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('Name') && touched.Name"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label for="Country">{{ $t('Country') }}</label>
                  <md-select
                    v-model="distributorDetails.distributor.country"
                    :disabled="formType == 'View Distributor'"
                  >
                    <md-option class="first-opt" disabled>Country</md-option>
                    <md-option
                      v-for="(country, index) in countries"
                      :key="index"
                      :value="country"
                      >{{ country }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field
                  :class="[
                    { 'md-error': errors.has('Lang') },
                    { 'md-valid': !errors.has('Lang') },
                  ]"
                >
                  <label>{{ $t('Select Language') }}</label>
                  <md-select
                    type="text"
                    v-model="distributorDetails.distributor.language"
                    data-vv-name="Lang"
                    v-validate="modelValidations.Lang"
                    :disabled="formType == 'View Distributor'"
                  >
                    <md-option class="first-opt" disabled
                      >Select Language</md-option
                    >
                    <md-option
                      v-for="item in selectLang"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.lang }}</md-option
                    >
                  </md-select>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('Lang')">
                      {{ errors.first('Lang') }}
                    </div>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('Lang') && touched.Lang"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field
                  :class="[
                    { 'md-error': errors.has('dbName') },
                    { 'md-valid': !errors.has('dbName') },
                  ]"
                >
                  <label>{{ $t('Db Name') }}</label>
                  <md-input
                    type="text"
                    v-model="distributorDetails.distributor.db_name"
                    data-vv-name="dbName"
                    v-validate="modelValidations.dbName"
                    :disabled="formType == 'View Distributor'"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('dbName')">
                      {{ errors.first('dbName') }}
                    </div>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('dbName') && touched.required"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field
                  :class="[
                    { 'md-error': errors.has('dbHost') },
                    { 'md-valid': !errors.has('dbHost') },
                  ]"
                >
                  <label>{{ $t('Db Host') }}</label>
                  <md-input
                    type="text"
                    v-model="distributorDetails.distributor.db_host"
                    data-vv-name="dbHost"
                    v-validate="modelValidations.dbHost"
                    :disabled="formType == 'View Distributor'"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('dbHost')">
                      {{ errors.first('dbHost') }}
                    </div>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('dbHost') && touched.required"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>

              <div
                class="md-layout-item md-size-50"
                v-if="formType != 'View Distributor'"
              >
                <md-field
                  :class="[
                    { 'md-error': errors.has('dbHost') },
                    { 'md-valid': !errors.has('dbHost') },
                  ]"
                >
                  <label>{{ $t('Db Password') }}</label>
                  <md-input
                    type="password"
                    v-model="distributorDetails.distributor.db_password"
                    data-vv-name="dbPassword"
                    v-validate="modelValidations.dbPassword"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('dbPassword')">
                      {{ errors.first('dbPassword') }}
                    </div>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('dbPassword') && touched.required"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
            </div>
            <!-- <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field
              :class="[
                { 'md-error': errors.has('dbName') },
                { 'md-valid': !errors.has('dbName')}
              ]"
            >
              <label>{{ $t('Db Name') }}</label>
              <md-input
                type="text"
                v-model="distributorDetails.distributor.db_name"
                data-vv-name="dbName"
                v-validate="modelValidations.dbName"
                :disabled="formType == 'View Distributor'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('dbName')">{{errors.first('dbName')}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('dbName') && touched.required">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>-->

            <md-card-header v-if="formType != 'View Distributor'">
              <h3>User Details</h3>
            </md-card-header>

            <div class="md-layout" v-if="formType != 'View Distributor'">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('First Name') }}</label>
                  <md-input
                    v-model="distributorDetails.login.first_name"
                  ></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Last Name') }}</label>
                  <md-input
                    v-model="distributorDetails.login.last_name"
                  ></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field
                  :class="[
                    { 'md-error': errors.has('userName') },
                    { 'md-valid': !errors.has('userName') },
                  ]"
                >
                  <label>{{ $t('Email') }}</label>
                  <md-input
                    v-model="distributorDetails.login.user_name"
                    data-vv-name="userName"
                    type="text"
                    v-validate="modelValidations.userName"
                    v-if="formType != 'View Distributor'"
                  ></md-input>
                  <md-input
                    v-model="distributorDetails.login.user_name"
                    data-vv-name="userName"
                    type="text"
                    v-validate="modelValidations.userName"
                    v-else
                    disabled
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('userName')">
                      {{ errors.first('userName') }}
                    </div>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('userName') && touched.userName"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>

              <div
                class="md-layout-item md-size-50"
                v-if="formType == 'Add Distributor'"
              >
                <md-field
                  :class="[
                    { 'md-error': errors.has('Password') },
                    { 'md-valid': !errors.has('Password') },
                  ]"
                >
                  <label>{{ $t('Password') }}</label>
                  <md-input
                    type="password"
                    v-model="distributorDetails.login.password"
                    data-vv-name="Password"
                    v-validate="modelValidations.Password"
                    autocomplete="new-password"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('Password')">
                      {{ errors.first('Password') }}
                    </div>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('Password') && touched.required"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Address') }}</label>
                  <md-input
                    type="text"
                    v-model="distributorDetails.login.address"
                    :disabled="formType == 'View Distributor'"
                  >
                  </md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Phone Number') }}</label>
                  <md-input
                    type="text"
                    v-model="distributorDetails.login.phone"
                    :disabled="formType == 'View Distributor'"
                  >
                  </md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Postal Code') }}</label>
                  <md-input
                    type="text"
                    v-model="distributorDetails.login.pin_code"
                    :disabled="formType == 'View Distributor'"
                  >
                  </md-input>
                </md-field>
              </div>
            </div>

            <div class="btn-div" v-if="formType !== 'View Distributor'">
              <md-card-actions class="text-center">
                <md-button native-type="submit" @click="cancelEdit">{{
                  $t('CANCEL')
                }}</md-button>
                <md-button
                  native-type="submit"
                  @click.native.prevent="validate"
                  class="md-primary"
                  >{{ $t('SAVE') }}</md-button
                >
              </md-card-actions>
            </div>
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item md-size-100">
        <md-card class="business-vert-card">
          <md-card-header>
            <h3>Business Verticals</h3>
          </md-card-header>

          <md-card-content>
            <md-table class="business-owner-dist-table">
              <md-table-row>
                <md-table-head>Business Owner</md-table-head>
                <md-table-head>Status</md-table-head>
                <md-table-head>Outlets</md-table-head>
              </md-table-row>

              <md-table-row
                v-for="item in chains"
                :key="item.id"
                @click="getOutlets(item.id)"
              >
                <md-table-cell>{{ item.name }}</md-table-cell>
                <md-table-cell>Active</md-table-cell>
                <md-table-cell>10</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <!-- Dialog to display chains -->
    <md-dialog :md-active.sync="outletDialog" class="outlet-dialog">
      <div class="outlet-dialog-content">
        <md-table class="outlet-dialog-table">
          <md-table-row>
            <md-table-head>Outlet</md-table-head>
            <md-table-head>Email</md-table-head>
          </md-table-row>

          <md-table-row v-for="item in outlets" :key="item.id">
            <md-table-cell>{{ item.name }}</md-table-cell>
            <md-table-cell>{{ item.email }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </md-dialog>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import axios from 'axios'
export default {
  components: {
    SlideYDownTransition,
  },
  props: {},
  data() {
    return {
      outletDialog: false,
      userName: '',
      Password: '',
      Name: '',
      dbHost: '',
      dbName: '',
      switch2: null,
      touched: {
        required: false,
        Password: false,
        Name: false,
        Lang: false,
        dbName: false,
        dbHost: false,
        dbPassword: false,
      },
      modelValidations: {
        userName: {
          required: true,
        },
        Password: {
          required: true,
        },
        Name: {
          required: true,
        },
        Lang: {
          required: true,
        },
        dbHost: {
          required: true,
        },
        dbPassword: {},
        dbName: {
          required: true,
        },
      },
      formType: {
        type: String,
        default: 'AddDistributors',
      },
      distributorId: '',
      distributorDetails: {
        distributor: {},
        login: {},
      },
      countries: [],
      selectLang: [
        {
          value: 'en',
          lang: 'English',
        },
        {
          value: 'es',
          lang: 'Spanish',
        },
      ],
      listView: '',
      chains: [],
      outlets: [],
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitDistributor()
          }
        })
      }
    },

    submitDistributor() {
      let self = this
      this.distributorDetails.distributor.db_password = btoa(
        this.distributorDetails.distributor.db_password
      )
      if (this.formType == 'Add Distributor') {
        customizeService
          .createDistributor(this.distributorDetails)
          .then((res) => {
            this.$router.push('/distributors/Distributors-table')
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        customizeService
          .updateDistributor(this.distributorDetails, this.distributorId)
          .then((res) => {
            this.$router.push('/distributors/Distributors-table')
          })
          .catch((err) => console.log(err))
      }
    },

    getCountries() {
      axios.get('https://restcountries.eu/rest/v2/all').then((res) => {
        res.data.forEach((el) => {
          this.countries.push(el.name)
        })
      })
    },

    editDistributor() {
      this.formType = 'Edit Distributor'
    },

    submitEditDistributor() {},

    cancelEdit() {
      if (this.listView == 'table') {
        this.$router.push({ path: '/distributors/Distributors-table' })
      } else {
        this.$router.push({ path: '/distributors/list-dist' })
      }
    },

    getOutlets(chain_id) {
      customizeService.getOutletByChainId(chain_id).then((res) => {
        this.outlets = res.data.outlets
        this.outletDialog = true
      })
    },
  },
  created() {
    this.listView = this.$route.params.viewType
    console.log('List view', this.listView)
    this.formType = this.$route.params.formType
    this.distributorId = this.$route.params.client
    let self = this
    if (this.formType != 'Add Distributor') {
      customizeService.getDistributorById(this.distributorId).then((res) => {
        this.distributorDetails = res.data
      })

      customizeService
        .businessVerticalByDist(this.distributorId)
        .then((res) => {
          this.chains = res.data.chains
        })
    }
    this.getCountries()
  },
  watch: {
    userName() {
      this.touched.required = true
    },
    Password() {
      this.touched.required = true
    },
    Name() {
      this.touched.required = true
    },
    Lang() {
      this.touched.required = true
    },
    dbHost() {
      this.touched.required = true
    },
    dbName() {
      this.touched.required = true
    },
    dbPassword() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}

.back-icon {
  cursor: pointer;
}

.business-vert-card {
  padding: 0px !important;
}

.outlet-dialog {
  width: 500px;
}
</style>
