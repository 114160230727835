// =========================================================
// * Vue Material Dashboard PRO - v1.3.1
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardPlugin from './material-dashboard'
import Vuelidate from 'vuelidate'
import { environment } from './../config'
import Swatches from 'vue-swatches'
import VueListPicker from 'vue-list-picker'
import { accountService } from './services/account'
import VCalendar from 'v-calendar'
// Plugins
import App from './App.vue'
import Chartist from 'chartist'
import moment from 'moment'

Vue.prototype.moment = moment

// router setup
import routes from './routes/routes'

import store from './store'
import VueI18n from 'vue-i18n'
import messages from './lang'

import VueDraggableResizable from 'vue-draggable-resizable'

// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import './assets/css/main.css'
import './assets/css/custom.css'
import './assets/css/appetite.css'

import { longClickDirective } from 'vue-long-click'
//Vue.component('vue-draggable-resizable', VueDraggableResizable)
const longClickInstance = longClickDirective({ delay: 400, interval: 50 })
Vue.directive('longclick', longClickInstance)

// plugin setup
Vue.use(VueRouter)
Vue.use(DashboardPlugin)
Vue.use(Vuelidate)
Vue.use(Swatches)
Vue.use(VueListPicker)
Vue.use(VueI18n)
Vue.use(VueDraggableResizable)
Vue.use(VCalendar)

export const i18n = new VueI18n({
  locale: accountService.getLanguage(),
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages,
})

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  linkExactActiveClass: 'nav-item active',
})

// navigation guard
router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem('access_token') != null || !to.meta.requiredAuth) {
    next()
  } else {
    if (to.name == 'Login') return next()
    next({ path: '/login' })
  }
})

// global library setup
Object.defineProperty(Vue.prototype, '$Chartist', {
  get() {
    return this.$root.Chartist
  },
})

/* eslint-disable no-new */
let vue = new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store,
  i18n,
  data: {
    Chartist: Chartist,
  },
})
