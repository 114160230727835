<template>
  <md-card class="block-bg pd-l pad-r-0">
    <md-card-content class="custom-tab super-table">
      <md-tabs md-alignment="left" :md-active-tab="'tab-modifiers'">
        <md-tab id="tab-modifiers" md-label="Modifiers" exact>
          <modifier-list></modifier-list>
        </md-tab>

        <md-tab id="tab-modifier-groups" md-label="Modifier Groups">
          <modifier-group-list></modifier-group-list>
        </md-tab>
      </md-tabs>
    </md-card-content>
  </md-card>
</template>

<script>
import { Tabs } from '@/components'
import { Pagination } from '@/components'
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import ModifierList from './../Categories/ModifiersList'
import ModifierGroupList from './../Categories/ModifierGroupList'

export default {
  components: {
    Pagination,
    Tabs,
    ModifierList,
    ModifierGroupList,
  },
  computed: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  watch: {},
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}

.tab-pane {
  width: 100%;
}
</style>
