<template>
  <form>
    <md-card>
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <md-icon class="material-icons">keyboard_arrow_left</md-icon>
          </span>
          <h3>View - Asti Wine Bar</h3>
        </div>
        <div class="md-layout-item md-size-30">
          <i class="md-icon md-icon-font md-theme-default edit-img">edit</i>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field
              :class="[
                { 'md-error': errors.has('required') },
                { 'md-valid': !errors.has('required') && touched.required },
              ]"
            >
              <label>Business Name</label>
              <md-input
                v-model="required"
                data-vv-name="required"
                type="text"
                v-validate="modelValidations.required"
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('required')"
                  >close</md-icon
                >
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('required') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource,
                    },
                  ]"
                >
                  <label>Street</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToDest') },
                    {
                      'md-valid':
                        !errors.has('equalToDest') && touched.equalToDest,
                    },
                  ]"
                >
                  <label>Street</label>
                  <md-input
                    v-model="equalToDest"
                    data-vv-name="equalToDest"
                    data-vv-as="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToDest"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToDest')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('equalToDest') && touched.equalToDest"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
            </div>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="md-layout">
          <div class="md-layout-item">
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource,
                    },
                  ]"
                >
                  <label>Country</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToDest') },
                    {
                      'md-valid':
                        !errors.has('equalToDest') && touched.equalToDest,
                    },
                  ]"
                >
                  <label>State</label>
                  <md-input
                    v-model="equalToDest"
                    data-vv-name="equalToDest"
                    data-vv-as="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToDest"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToDest')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('equalToDest') && touched.equalToDest"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
            </div>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>
        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource,
                    },
                  ]"
                >
                  <label>City</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToDest') },
                    {
                      'md-valid':
                        !errors.has('equalToDest') && touched.equalToDest,
                    },
                  ]"
                >
                  <label>Pincode</label>
                  <md-input
                    v-model="equalToDest"
                    data-vv-name="equalToDest"
                    data-vv-as="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToDest"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToDest')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('equalToDest') && touched.equalToDest"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
            </div>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <!-- ----------------------------------------------------------------------------------- -->

        <div class="md-layout">
          <div class="md-layout-item">
            <div class="md-layout">
              <div class="md-layout-item">
                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource,
                    },
                  ]"
                >
                  <label>Cell#</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>

                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource,
                    },
                  ]"
                >
                  <label>E-mail</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>

                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource,
                    },
                  ]"
                >
                  <label>Package</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>

                <md-field
                  :class="[
                    { 'md-error': errors.has('equalToSource') },
                    {
                      'md-valid':
                        !errors.has('equalToSource') && touched.equalToSource,
                    },
                  ]"
                >
                  <label>Validity</label>
                  <md-input
                    v-model="equalToSource"
                    data-vv-name="equalToSource"
                    ref="equalToSource"
                    type="text"
                    v-validate="modelValidations.equalToSource"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has('equalToSource')"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="
                        !errors.has('equalToSource') && touched.equalToSource
                      "
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div class="md-layout-item">
                <div class="logo-text-cls">Logo</div>
                <img
                  class="img logo-align"
                  slot="imageHeader"
                  :src="product1"
                />
              </div>
            </div>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="md-layout">
          <div class="md-layout-item">
            <div class="md-layout">
              <div class="md-layout-item">
                <label class="toggle-align">Status</label>
                <md-switch v-model="switch2"></md-switch>
              </div>
            </div>
          </div>
          <!-- <label class="md-layout-item md-size-20 md-label-on-right">
            <code>required</code>
          </label>-->
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
export default {
  components: {
    SlideYDownTransition,
  },
  data() {
    return {
      product1: './img/card-2.jpg',
      equalToSource: '',
      equalToDest: '',
      required: '',
      email: '',
      number: '',
      url: '',
      switch2: null,
      touched: {
        required: false,
        email: false,
        number: false,
        url: false,
        equalToSource: false,
        equalToDest: false,
      },
      modelValidations: {
        required: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        number: {
          required: true,
          numeric: true,
        },
        url: {
          required: true,
          url: true,
        },
        equalToSource: {
          required: true,
        },
        equalToDest: {
          required: true,
          confirmed: 'equalToSource',
        },
      },
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit('on-submit', this.registerForm, isValid)
      })
    },
  },
  watch: {
    required() {
      this.touched.required = true
    },
    email() {
      this.touched.email = true
    },
    number() {
      this.touched.number = true
    },
    url() {
      this.touched.url = true
    },
    equalToSource() {
      this.touched.equalToSource = true
    },
    equalToDest() {
      this.touched.equalToDest = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
