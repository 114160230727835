<template>
  <div class="sidebar-footer">
    <div class="footer-content-sidebar">
      <img src="../../assets/images/cbe_innova_logo.png" />
      <div class="version">Ver 2.0.0 - {{ $store.getters.appBuildNumber }}</div>
      <div class="copyright">
        <span>&#169;</span> - {{ $t('2020 appetitepos.com') }} <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sidebar-footer',
}
</script>

<style>
.sidebar-footer {
  z-index: 9999;
  bottom: 0px;
  color: white;
  position: fixed;
  text-align: center;
  width: inherit;
  margin: inherit;
}

.sidebar-footer img {
  padding-top: 30px;
  width: 60%;
}

.version {
  font-size: 12px;
  padding-bottom: 10px;
  color: #60616c;
}

.copyright {
  line-height: 12px;
  font-size: 10px;
  padding-bottom: 5px;
  color: #60616c;
}

.footer-content-sidebar {
  border-width: 1px;
  border-top-style: solid;
  border-top-color: #e7e7e7;
  margin-right: 12%;
}
</style>
