<template>
  <div class="container-div" id="eod-canvas">
    <table
      v-for="(data, index) in msg"
      :key="'main-' + index"
      :class="[
        eodReportTableFormatType[data.formatType],
        'first-col-' + data.firstColWidth,
      ]"
    >
      <div class="category-heading" v-if="data.title">{{ data.title }}</div>

      <tr
        id="head-holder"
        :class="{ 'row-border-bottom': data.header.length > 0 }"
      >
        <th
          v-for="(header, index) in data.header"
          :key="'heder-' + index"
          :class="eodReportTableHeaderFormatType[data.headerFormatType]"
          :style="{
            width:
              data.firstColWidth && index != 0
                ? (100 - data.firstColWidth) / (data.header.length - 1) + '%'
                : data.firstColWidth + '%',
          }"
        >
          {{ header }}
        </th>
      </tr>
      <tr
        v-for="(info, index) in data.row"
        :key="'info-' + index"
        :class="{ 'row-bold': info.isBold }"
      >
        <td
          v-for="(col, index) in info.col"
          :key="'col-' + index"
          :style="{
            width:
              data.firstColWidth && index != 0
                ? (100 - data.firstColWidth) / (info.col.length - 1) + '%'
                : data.firstColWidth + '%',
          }"
        >
          {{ col }}
        </td>
      </tr>

      <tr :class="{ 'row-border-top': data.footer.length > 0 }">
        <td
          v-for="(footer, index) in data.footer"
          :key="'footer-' + index"
          :style="{
            width:
              data.firstColWidth && index != 0
                ? (100 - data.firstColWidth) / (data.footer.length - 1) + '%'
                : data.firstColWidth + '%',
          }"
        >
          <b>{{ footer }}</b>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'

const eodReportTableFormatType = {
  0: 'normal',
  1: 'round-rect-bordered',
  2: 'multi-format',
}
const eodReportTableHeaderFormatType = {
  0: 'rows-format-with-bottom-boder',
  1: 'center-with-bottom-boder',
  2: 'center-with-top-bottom-boder',
}
export default {
  name: 'EOD',
  props: {
    msg: Array,
  },
  data() {
    return {
      counter: 0,
      eodReportTableFormatType,
      eodReportTableHeaderFormatType,
    }
  },
  mounted() {
    setInterval(() => this.counter++, 1000)
  },
  methods: {
    isNumeric(num) {
      return !isNaN(num)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: 'Roboto', sans-serif;
  color: #fff;
}
#eod-canvas {
  font-family: 'Roboto', sans-serif;
}
tr {
  font-size: 26px;
  line-height: 40px;
}
table {
  width: 100%;
  padding-bottom: 20px;
}
.container-div {
  width: 600px;
  margin: auto;
  padding: 10px;
}
.round-rect-bordered {
  width: 100%;
  text-align: center;
  padding: 15px 0;
}
.category-heading {
  background: #777777;
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-weight: 900;
  padding: 20px 0;
  margin-bottom: 20px;
}
.round-rect-bordered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.round-rect-bordered tr td {
  display: block;
}
/* .round-rect-bordered tr {
    font-size: 30px;
    line-height: 40px;
}
.date-time{
    width: 100%;
}
.date-time td{
    font-size: 30px;
}
.date-time td:last-child{
    text-align: right;
}

.p-s-category{
    width: 100%;
}
th.center-with-bottom-boder{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
}
.s-category{
    text-align: left;
}
.s-category tbody tr:first-child{
    border-bottom: 1px solid #000;
}
.s-category tr{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.s-category tr th{
    width: 16.666%;
    text-align: center;
}
.s-category tr td{
    width: 16.666%;
    text-align: center;
}
.s-employee tr{
    display: flex;
    justify-content: space-between;
} */

/* .first-col-50 tr {
  display: flex;
  justify-content: space-between;
} */
.container-div div {
  background: #777777;
  color: #fff;
  /* text-align: center; */
  font-size: 26px;
  font-weight: 900;
  padding: 10px 0;
  margin-bottom: 20px;
}
/* .first-col-80 tr {
  display: flex;
  justify-content: space-between;
} */
/* .first-col-80 tr th {
  margin: auto;
  border-bottom: 2px solid #000;
  width: 100%;
  margin-bottom: 15px;
  font-weight: 700;
} */
.multi-format tr td:last-child {
  text-align: right;
}
/* .first-col-25 tr {
  text-align: center;
} */
/* .first-col-45 tr {
  text-align: center;
} */
table {
  display: block;
  background-color: #fff;
}
.multi-format tr {
  display: flex;
  justify-content: space-between;
}
/*--***********************New Styles***********************--*/
.container-div {
  background-color: #fff;
}
.round-rect-bordered {
  border: 2px solid black;
  margin-right: 96px;
  width: 90%;
  margin: auto;
  border-radius: 25px;
  margin-top: 15px;
  margin-bottom: 60px;
}

.round-rect-bordered tr {
  line-height: 30px;
}
.container-div div {
  margin-left: -10px;
  margin-right: -10px;
}
.md-table td,
th {
  text-align: center;
  /* border-bottom: 2px solid; */
}
tr {
  display: flex;
}
/* #head-holder th {
  width: 100% !important;
} */
.row-bold {
  font-weight: 800;
}
.row-border-top {
  border-top: 2px solid;
}
.row-border-bottom {
  border-bottom: 2px solid;
}
tr td:not(:first-child) {
  text-align: right;
}
#head-holder th:not(:first-child) {
  text-align: right;
}
</style>
