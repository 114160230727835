<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header
          class="md-card-header-text md-card-header-green md-layout md-gutter"
        >
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <router-link :to="{ path: '/Distributors/BusinessVertical' }">
                <img src="../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h3>{{ formType }}</h3>
          </div>
          <div
            class="md-layout-item md-size-30"
            v-if="formType == 'View Business Vertical'"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="editbusinessvert"
              >edit</i
            >
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
              >
                <label>{{ $t('Name') }}</label>
                <md-input
                  v-model="chains.chain.name"
                  data-vv-name="Name"
                  type="text"
                  v-validate="modelValidations.Name"
                  disabled
                  v-if="formType == 'View Business Vertical'"
                ></md-input>

                <md-input
                  v-model="chains.chain.name"
                  data-vv-name="Name"
                  type="text"
                  v-validate="modelValidations.Name"
                  v-else
                ></md-input>

                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Name')">
                    {{ errors.first('Name') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Name') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label for="Super Category">{{ $t('Packages') }}</label>
                <md-select
                  v-model="chains.chain.app_package_id"
                  v-validate="modelValidations.Packages"
                  data-vv-name="Packages"
                >
                  <md-option class="first-opt" disabled>Packages</md-option>
                  <md-option
                    :value="item.id"
                    v-for="item in packages"
                    :key="item.id"
                    >{{ item.name }}</md-option
                  >
                </md-select>
                <div class="error" v-show="errors.has('Packages')">
                  {{ errors.first('Packages') }}
                </div>
              </md-field>
            </div>
          </div>
          <div class="md-layout" v-if="formType != 'Add Business Vertical'">
            <div class="md-layout-item md-size-50">
              <label>{{ $t('Share Voucher ?') }}</label>
              <md-checkbox v-model="chains.chain.share_voucher"> </md-checkbox>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>{{ $t('Selected Voucher Outlets ') }}</label>
                <md-select v-model="selectedOutletIds" multiple>
                  <md-option
                    :value="item.id"
                    v-for="item in outlets"
                    :key="item.id"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>

          <!-- <div class="md-layout" v-if="formType == 'Add Business Vertical'">
          <div class="md-layout-item" v-if="formType == 'Add Business Vertical'">
            <md-field
              :class="[
                { 'md-error': errors.has('userName') },
                { 'md-valid': !errors.has('userName') && touched.userName }
              ]"
            >
              <label>{{$t('User Name')}}</label>
              <md-input
                v-model="chains.login.user_name"
                data-vv-name="userName"
                type="text"
                v-validate="modelValidations.userName"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('userName')">{{errors.first("userName")}}</div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('userName') && touched.userName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div> -->

          <!-- <div class="md-layout" v-if="formType == 'Add Business Vertical'">
          <div class="md-layout-item">
            <md-field>
              <label for="Super Category">{{$t('Packages')}}</label>
              <md-select v-model="chains.chain.app_package_id" v-validate="modelValidations.Packages" data-vv-name="Packages">
                <md-option :value="item.id" v-for="item in packages" :key="item.id">{{item.name}}</md-option>
              </md-select>
              <div class="error" v-show="errors.has('Packages')">{{errors.first("Packages")}}</div>
            </md-field>
            
          </div>
        </div> -->
        </md-card-content>

        <md-card-header>
          <h3>User Details</h3>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div
              class="md-layout-item md-size-50"
              v-if="formType == 'Add Business Vertical'"
            >
              <md-field>
                <label>{{ $t('First Name') }}</label>
                <md-input v-model="chains.login.first_name"></md-input>
              </md-field>
            </div>

            <div
              class="md-layout-item md-size-50"
              v-if="formType == 'Add Business Vertical'"
            >
              <md-field>
                <label>{{ $t('Last Name') }}</label>
                <md-input v-model="chains.login.last_name"></md-input>
              </md-field>
            </div>

            <div
              class="md-layout-item"
              v-bind:class="{
                'md-size-100': formType != 'Add Business Vertical',
              }"
              :disabled="formType != 'Add Business Vertical'"
            >
              <md-field
                :class="[
                  { 'md-error': errors.has('userName') },
                  { 'md-valid': !errors.has('userName') && touched.userName },
                ]"
              >
                <label>{{ $t('User Name') }}</label>
                <md-input
                  v-model="chains.login.user_name"
                  data-vv-name="userName"
                  type="text"
                  v-validate="modelValidations.userName"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('userName')">
                    {{ errors.first('userName') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('userName') && touched.userName"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>

            <div class="md-layout" v-if="formType != 'Add Business Vertical'">
              <div class="md-layout-item md-size-50">
                <label>{{ $t('Subscription ?') }}</label>
                <md-checkbox v-model="chains.chain.subscription_enabled">
                </md-checkbox>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Stripe Price') }}</label>
                  <md-select v-model="chains.chain.stripe_price_id">
                    <md-option
                      v-for="item in stripePrices"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.nickname }}
                      {{ item.unit_amount > 0 ? item.unit_amount / 100 : '' }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <div
              class="md-layout-item md-size-50"
              v-if="formType == 'Add Business Vertical'"
            >
              <md-field
                :class="[
                  { 'md-error': errors.has('Password') },
                  { 'md-valid': !errors.has('Password') && touched.Password },
                ]"
              >
                <label>{{ $t('Password') }}</label>
                <md-input
                  v-model="chains.login.password"
                  data-vv-name="Password"
                  type="password"
                  v-validate="modelValidations.Password"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Password')">
                    {{ errors.first('Password') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Password') && touched.Password"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>

            <div
              class="md-layout-item md-size-50"
              v-if="formType == 'Add Business Vertical'"
            >
              <md-field>
                <label>{{ $t('Address') }}</label>
                <md-input
                  type="text"
                  v-model="chains.login.address"
                  :disabled="formType == 'View Distributor'"
                >
                </md-input>
              </md-field>
            </div>

            <div
              class="md-layout-item md-size-50"
              v-if="formType == 'Add Business Vertical'"
            >
              <md-field>
                <label>{{ $t('Phone Number') }}</label>
                <md-input
                  type="text"
                  v-model="chains.login.phone"
                  :disabled="formType == 'View Distributor'"
                >
                </md-input>
              </md-field>
            </div>

            <div
              class="md-layout-item md-size-50"
              v-if="formType == 'Add Business Vertical'"
            >
              <md-field>
                <label>{{ $t('Postal Code') }}</label>
                <md-input
                  type="text"
                  v-model="chains.login.pin_code"
                  :disabled="formType == 'View Distributor'"
                >
                </md-input>
              </md-field>
            </div>
          </div>

          <!-- ----------------------------------------------------------------------------------- -->
          <div class="btn-div" v-if="formType != 'View Business Vertical'">
            <md-card-actions class="text-center">
              <md-button
                native-type="submit"
                :to="{ path: '/Distributors/BusinessVertical' }"
                >{{ $t('CANCEL') }}</md-button
              >
              <md-button
                native-type="submit"
                @click.native.prevent="validate"
                class="md-primary"
                >{{ $t('SAVE') }}</md-button
              >
            </md-card-actions>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div
      class="md-layout-item md-size-100"
      v-if="formType == 'View Business Vertical'"
    >
      <md-card class="business-vert-card">
        <md-card-header>
          <h3>Outlets</h3>
        </md-card-header>

        <md-card-content>
          <md-table class="outlet-businessowner-table">
            <md-table-row>
              <md-table-head>Outlet Name</md-table-head>
              <md-table-head>Email</md-table-head>
              <md-table-head>Phone</md-table-head>
            </md-table-row>

            <md-table-row v-for="item in outlets" :key="item.id">
              <md-table-cell>{{ item.name }}</md-table-cell>
              <md-table-cell>{{ item.email }}</md-table-cell>
              <md-table-cell>{{ item.phone }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize'
import { reusableService } from '../../../services/reusable.js'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import { accountService } from '../../../services/account'
import { stripeService } from '../../../services/stripe.js'

export default {
  components: {
    SlideYDownTransition,
  },
  props: {},
  data() {
    return {
      required: '',
      Name: '',
      userName: '',
      userType: '',
      Password: '',
      Package: '',
      switch2: null,
      touched: {
        required: false,
        Name: false,
        userName: false,
        userType: false,
        Password: false,
      },
      stripePrices: [],
      modelValidations: {
        required: {
          required: true,
        },
        Name: {
          required: true,
        },
        userName: {
          required: true,
          email: false,
        },
        userType: {
          required: true,
        },
        Password: {
          required: true,
        },
        Packages: {
          required: true,
        },
      },

      formType: {
        type: String,
        default: 'Add Business Vertical',
      },
      clientDetail: {
        default: '',
      },
      packages: [],
      chains: {
        chain: {
          name: '',
          app_package_id: '',
        },
        login: {
          user_type: 'branch_user',
        },
      },
      businessId: '',
      outlets: [],
      selectedOutletIds: [],
    }
  },

  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Edit'), isValid)
        }
      })
    },

    submitBv() {
      console.log('Chains:', this.chains)
      this.chains.login.distributor_id = accountService.getDistributorId()
      //this.chains.chain.distributor_id = accountService.getDistributorId()
      this.chains.chain.voucher_outlets = this.selectedOutletIds
      if (this.formType == 'Add Business Vertical') {
        customizeService
          .createBusinessVert(this.chains)
          .then((res) => {
            this.$router.push({
              name: 'BusinessVertical',
            })
          })
          .catch((err) => console.log(err))
      } else {
        customizeService
          .editBusinessVertical(this.businessId, this.chains)
          .then((res) => {
            this.$router.push({
              name: 'BusinessVertical',
            })
          })
      }
    },
    alert(value) {
      var self = this
      if (value === 'Edit') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitBv()
          }
        })
      }
    },
    editbusinessvert() {
      this.formType = 'Edit Business Vertical'
    },
    resetBv() {
      this.chains.chain.name = ''
      this.chains.chain.app_package_id = ''
      this.chains.login.user_name = ''
      this.chains.login.password = ''
    },

    stripePriceList() {
      stripeService.priceList().then((res) => {
        this.stripePrices = res.data.data
      })
    },
  },

  created() {
    this.formType = this.$route.params.formType || this.$route.query.formType
    if (this.formType != 'Add Business Vertical') {
      this.businessId = this.$route.params.client || this.$route.query.client
      customizeService.BusinessVertbyId(this.businessId).then((res) => {
        this.chains = res.data
        this.chains.login = {
          user_name: res.data.login.user_name,
        }
        this.chains.chain.subscription_enabled =
          res.data.chain.subscription_status
        this.selectedOutletIds = res.data.chain.voucher_outlets
      })

      customizeService.getOutletByChainId(this.businessId).then((res) => {
        console.log('Outlets for the business:', res.data)
        this.outlets = res.data.outlets
      })
    }

    customizeService.getAllPackages().then((res) => {
      this.packages = res.data.packages
    })

    this.stripePriceList()
  },
  watch: {
    required() {
      this.touched.required = true
    },
    Name() {
      this.touched.required = true
    },
    userName() {
      this.touched.required = true
    },
    userType() {
      this.touched.required = true
    },
    Password() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
