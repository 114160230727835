import { environment } from './../../config'
import axios from 'axios'
import { accountService } from '../services/account.js'
import store from '../store'
import {
  getScreenMenus,
  getScreenMenu,
  createScreenMenu,
  updateScreenMenu,
  saveScreen,
  getGrid,
  createMenuPage,
  deleteMenuPage,
  updateGrid,
  geteMenuPages,
  getScreenMenuItems,
} from './screenDesign.js' // Adjust the path as necessary

var baseUrl = `${environment.api.host}`
var DynamicUrl = baseUrl
//var accessToken=this.sessionStorage.getItem('access_token');
const auth = {
  headers: {
    Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
  },
}

export const customizeService = {
  addModules,
  getModules,
  deleteModule,
  editmodule,
  getModuleById,
  createNewPackage,
  getAllPackages,
  getPackagebyId,
  getPackageByOutletId,
  deletePackagebyId,
  updatePackage,
  createDistributor,
  getDistributors,
  getDistributorById,
  updateDistributor,
  deleteDistributor,
  createBusinessVert,
  BusinessVert,
  BusinessVertbyId,
  editBusinessVertical,
  deleteBusinessVertical,
  businessVerticalByDist,
  createOutlet,
  getOutlets,
  getAllOutlets,
  getOutletById,
  getOutletByChainId,
  getOutletByDistributorId,
  deleteOutlet,
  editOutlet,
  getOutletOnlineSettings,
  createSupercat,
  getSupercat,
  getSupercatById,
  editSupercat,
  deleteSupercat,
  createMaincat,
  getMaincat,
  getMaincatById,
  editMaincat,
  deleteMaincat,
  createModifier,
  getModifier,
  createModifierGroup,
  editModifierGroup,
  updateModifierGroupStatus,
  deleteModifierGroup,
  getMgById,
  createSubcat,
  getSubcat,
  createCourse,
  getCourse,
  createStation,
  getStation,
  getModifierGroup,
  createMenuItems,
  createMenuList,
  updateMenuList,
  getMenuItemLists,
  getMenuItemList,
  updateMenuItemById,
  updateOnlineDeatils,
  updateMenuItemPriceById,
  createFlipDish,
  getMenuItems,
  getMenuItemById,
  checkBarcodeAvailability,
  getIpadMenuItems,
  createCombo,
  updateComboStatus,
  getCombo,
  createComboGroup,
  getComboGroup,
  createPortions,
  getPortions,
  updatePortion,
  deleteComboGroup,
  getComboGroupById,
  editComboGroup,
  updateComboGroupStatus,
  getTaxes,
  taxes,
  getReprinter,
  createReprinter,
  createDiscountCat,
  getDiscountCat,
  updateDiscountCat,
  getScheduledReport,
  createScheduledReport,
  createOtherPayment,
  getOtherPaymentTypes,
  updateOtherPaymentTypes,
  createTable,
  updateTable,
  getTable,
  getTableAreas,
  createTableArea,
  updateTableArea,
  getEmployees,
  createStaff,
  anonymiseStaff,
  updateEmployee,
  authFun,
  createProfile,
  updateProfile,
  getProfiles,
  createTerminal,
  updateTerminal,
  getterminals,
  getStaffRoles,
  createStaffRole,
  updateStaffRole,
  checkPinAvailability,
  assignItems,
  getAssignItems,
  getRoles,
  createRole,
  getPackageUsingRole,
  deleteRole,
  editRole,
  getVoidReasons,
  createVoidReasons,
  updateVoidReasons,
  updateTablePosition,
  updateMenuItems,
  fetchIpadCategory,
  updateTablePosition,
  saveGrid,
  get_grid,
  get_categories,
  change_item_color,
  change_combo_color,
  change_category_color,
  setTenant,
  getFlipdishMenus,
  getFlipdishMenuSection,
  getFlipDishById,
  updateFlipDishToken,
  getFlipDishStore,
  getFlipdishApps,
  getMapItems,
  delete_category,
  change_category_name,
  main_category_color,
  updateOrderTypes,
  createIntegration,
  getIntegrations,
  getIntegrationByPackage,
  menuItemImport,
  voucherImport,
  update_categories,
  deleteEmployeInfo,
  updateEmployeInfo,
  createEmployeInfo,
  getPriceLevels,
  getOutletMenuItem,
  getOutletMenuItemById,
  updateOutletMenuItemById,
  getVouchers,
  getUsers,
  getUserById,
  updateUser,

  // Menu Section
  createMenuSection,
  getMenuSections,
  getMenuSectionById,
  updateMenuSection,
  getOnlineMenuItems,
  getOnlineOrders,
  getOnlineOrder,
  refundOrder,
  // getPromotions,
  addPromotions,
  // getPromotionById,
  // updatePromotion,
  // deletePromotion,

  //Stripe
  createStripeAccount,
  linkStripeAccount,

  //User
  registerUser,
  sendMessage,

  //Online Promotions

  addOnlinePromotions,
  getOnlinePromotionById,
  updateOnlinePromotion,
  deleteOnlinePromotion,
  getOnlinePromotions,
  addOrRemoveImageUrl,

  //Cbe Pay
  goToOnboard,
  getCbePay,
  getAdyenTransactions,
  getAdyenPayments,

  //Screen
  getScreenMenus,
  getScreenMenu,
  createScreenMenu,
  updateScreenMenu,
  getGrid,
  createMenuPage,
  deleteMenuPage,
  updateGrid,
  geteMenuPages,
  saveScreen,
  getScreenMenuItems,
}

function authFun() {
  const auth = {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
    },
  }
  return auth
}

function setTenant() {
  return axios.get(
    baseUrl + `${environment.api.services.accounts.tenant}`,
    authFun()
  )
}

function getAllUsers() {
  return axios.get(
    baseUrl + `${environment.api.services.customize.get_all_users}`,
    authFun()
  )
}

function addModules(bulkModule) {
  let body = {
    app_module: bulkModule,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.module}`,
    body,
    authFun()
  )
}

function getModules() {
  return axios.get(
    baseUrl + `${environment.api.services.customize.module}`,
    authFun()
  )
}

function getModuleById(id) {
  return axios(
    baseUrl + `${environment.api.services.customize.editmodule}` + id + '.json',
    authFun()
  )
}

function deleteModule(id) {
  return axios.delete(
    baseUrl + `${environment.api.services.customize.editmodule}` + id + '.json',
    authFun()
  )
}

function editmodule(bulkModule, id) {
  let body = {
    app_module: bulkModule,
  }
  return axios.put(
    baseUrl + `${environment.api.services.customize.editmodule}` + id + '.json',
    body,
    authFun()
  )
}

//Packages

function createNewPackage(newpackage) {
  let body = {
    app_packages: newpackage,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.package}`,
    body,
    authFun()
  )
}

function getAllPackages() {
  return axios.get(
    baseUrl + `${environment.api.services.customize.package}`,
    authFun()
  )
}

function getPackagebyId(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.editpackage}` +
      id +
      '.json',
    authFun()
  )
}

function getPackageByOutletId(id, outletId) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.editpackage}` +
      id +
      '.json?outlet_id=' +
      outletId,
    authFun()
  )
}

function deletePackagebyId(id) {
  return axios.delete(
    baseUrl +
      `${environment.api.services.customize.editpackage}` +
      id +
      '.json',
    authFun()
  )
}

function updatePackage(newpackage, id) {
  let body = {
    app_packages: newpackage,
  }
  return axios
    .put(
      baseUrl +
        `${environment.api.services.customize.editpackage}` +
        id +
        '.json',
      body,
      authFun()
    )
    .then((res) => {
      // console.log(res)
    })
    .catch((err) => console.log(err))
}

//Distributors

function createDistributor(details) {
  let body = {
    distributors: details,
  }
  body.distributors.login.user_type = 'Distributor'
  body.distributors.login.status = 'active'

  return axios.post(
    baseUrl + `${environment.api.services.customize.distributor}`,
    body,
    authFun()
  )
}

function getDistributors() {
  return axios.get(
    baseUrl + `${environment.api.services.customize.distributor}`,
    authFun()
  )
}

function getDistributorById(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.editdistributor}` +
      id +
      '.json',
    authFun()
  )
}

function updateDistributor(details, id) {
  let body = {
    distributors: details,
  }
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.editdistributor}` +
      id +
      '.json',
    body,
    authFun()
  )
}

function deleteDistributor(id) {
  return axios.delete(
    baseUrl +
      `${environment.api.services.customize.editdistributor}` +
      id +
      '.json',
    authFun()
  )
}

//Business Vertical

function createBusinessVert(details) {
  let body = {
    chains: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.businessvertical}`,
    body,
    authFun()
  )
}

function BusinessVert() {
  return axios.get(
    baseUrl + `${environment.api.services.customize.businessvertical}`,
    authFun()
  )
}

function BusinessVertbyId(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.editbusinessvertical}` +
      id +
      '.json',
    authFun()
  )
}

function businessVerticalByDist(distributor_id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.businessvertical}` +
      '?distributor_id=' +
      distributor_id,
    authFun()
  )
}

function editBusinessVertical(id, details) {
  let body = {
    chains: details,
  }
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.editbusinessvertical}` +
      id +
      '.json',
    body,
    authFun()
  )
}

function deleteBusinessVertical(id) {
  return axios.delete(
    baseUrl +
      `${environment.api.services.customize.editbusinessvertical}` +
      id +
      '.json',
    authFun()
  )
}

// Outlet

function createOutlet(details) {
  // let body = {
  //     outlets: details
  // }
  // body.outlets.outlet.chain_id = accountService.getChainId();
  // body.outlets.login.distributor_id = accountService.getDistributorId();

  return axios.post(
    baseUrl + `${environment.api.services.customize.outlet}`,
    details,
    authFun()
  )
}

async function getOutlets() {
  return await axios.get(
    baseUrl +
      `${environment.api.services.customize.outlet}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function getAllOutlets() {
  return axios.get(
    baseUrl + `${environment.api.services.customize.outlet}/get_all_outlets`,
    authFun()
  )
}

function getOutletByChainId(chain_id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.outlet}` +
      '?chain_id=' +
      chain_id,
    authFun()
  )
}

function getOutletByDistributorId(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.outlet}` +
      '?distributor_id=' +
      id,
    authFun()
  )
}

function getOutletById(id) {
  return axios.get(
    baseUrl + `${environment.api.services.customize.editoutlet}` + id + '.json',
    authFun()
  )
}

function deleteOutlet(id) {
  return axios.delete(
    baseUrl + `${environment.api.services.customize.editoutlet}` + id + '.json',
    authFun()
  )
}

function editOutlet(id, details) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.editoutlet}` + id + '.json',
    details,
    authFun()
  )
}

function getOutletOnlineSettings(id, details) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.outlet_online_settings}?id=` +
      id,
    authFun()
  )
}

function getPriceLevels(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.editoutlet}get_pricelevels?outlet_id=` +
      accountService.getOutletId(),
    authFun()
  )
}

//Super Category

function createSupercat(details) {
  let body = {
    item_group: details,
  }
  body.item_group.user_id = ''
  body.item_group.status = true
  body.item_group.chain_id = accountService.getChainId()

  return axios.post(
    baseUrl + `${environment.api.services.customize.supercategory}`,
    body,
    authFun()
  )
}

function getSupercat() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.supercategory}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function getSupercatById(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.editsupercategory}` +
      id +
      '.json' +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function editSupercat(id, details) {
  let body = {
    item_group: details,
  }
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.editsupercategory}` +
      id +
      '.json',
    body,
    authFun()
  )
}

function deleteSupercat(id) {
  return axios.delete(
    baseUrl +
      `${environment.api.services.customize.editsupercategory}` +
      id +
      '.json',
    authFun()
  )
}

//Main Category

function createMaincat(details) {
  let body = {
    main_categories: details,
  }
  body.main_categories.codable_type = 'item_group'
  body.main_categories.chain_id = accountService.getChainId()
  return axios.post(
    baseUrl + `${environment.api.services.customize.maincategory}`,
    body,
    authFun()
  )
}

function getMaincat() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.maincategory}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function getMaincatById(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.editmaincategory}` +
      id +
      '.json' +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function editMaincat(id, details) {
  let body = {
    main_categories: details,
  }
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.editmaincategory}` +
      id +
      '.json',
    body,
    authFun()
  )
}

function deleteMaincat(id) {
  return axios.delete(
    baseUrl +
      `${environment.api.services.customize.editmaincategory}` +
      id +
      '.json',
    authFun()
  )
}

//Modifier

function createModifier(details) {
  let body = {
    modifier: details,
  }
  body.modifier.chain_id = accountService.getChainId()
  return axios.post(
    baseUrl + `${environment.api.services.customize.modifier}`,
    body,
    authFun()
  )
}

function getModifier() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.modifier}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

//Modifier Group

function createModifierGroup(details) {
  return axios.post(
    baseUrl + `${environment.api.services.customize.modifiergroup}`,
    details,
    authFun()
  )
}

function getModifierGroup() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.modifiergroup}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function getMgById(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.editmodifiergroup}` +
      id +
      '.json' +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function editModifierGroup(id, details) {
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.editmodifiergroup}` +
      id +
      '.json',
    details,
    authFun()
  )
}

function updateModifierGroupStatus(id, details) {
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.editmodifiergroup}update_status?id=` +
      id,
    details,
    authFun()
  )
}

function deleteModifierGroup(id) {
  return axios.delete(
    baseUrl +
      `${environment.api.services.customize.editmodifiergroup}` +
      id +
      '.json',
    authFun()
  )
}

//Sub Category

function createSubcat(details) {
  let body = {
    sub_categories: details,
  }
  body.sub_categories.codable_type = 'category'
  body.sub_categories.chain_id = accountService.getChainId()

  return axios.post(
    baseUrl + `${environment.api.services.customize.subcategory}`,
    body,
    authFun()
  )
}

function getSubcat() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.subcategory}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

//Menu Section

function createMenuSection(details) {
  let body = {
    menu_section: details,
  }
  body.menu_section.codable_type = 'menu_section'
  body.menu_section.chain_id = accountService.getChainId()

  return axios.post(
    baseUrl + `${environment.api.services.customize.menusection}`,
    body,
    authFun()
  )
}

function getMenuSections() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.menusection}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}
function getMenuSectionById(id) {
  return axios.get(
    baseUrl + `${environment.api.services.customize.menusection}/` + id,
    authFun()
  )
}

function updateMenuSection(id, data) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.menusection}/` + id,
    data,
    authFun()
  )
}
//Course

function createCourse(details) {
  let body = {
    courses: details,
  }
  body.courses.codable_type = 'subcategory'
  body.courses.chain_id = accountService.getChainId()
  return axios.post(
    baseUrl + `${environment.api.services.customize.course}`,
    body,
    authFun()
  )
}

function getCourse() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.course}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

//Station

function createStation(details) {
  let body = {
    stations: details,
  }
  body.stations.chain_id = accountService.getChainId()
  body.stations.outlet_id = accountService.getOutletId()

  return axios.post(
    baseUrl + `${environment.api.services.customize.station}`,
    body,
    authFun()
  )
}

function getStation() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.station}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

//Menu Items

function checkBarcodeAvailability(barCode, menuItemId) {
  return axios.get(
    baseUrl +
      `${
        environment.api.services.customize.menuitems
      }/check_barcode_availability?barcode=${barCode}&menu_item_id=${
        menuItemId || ''
      }&chain_id=${accountService.getChainId()}`,
    authFun()
  )
}

function createMenuItems(details) {
  let body = {
    menu_items: details,
  }
  return axios.post(
    baseUrl +
      `${environment.api.services.customize.menuitems}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    body,
    authFun()
  )
}

function createFlipDish(details, outletId) {
  return axios.post(
    baseUrl +
      `${environment.api.services.customize.flipdish}` +
      '?outlet_id=' +
      outletId,
    details,
    authFun()
  )
}

function getMenuItems(formType = '', outletId = '', items) {
  let key, value
  if (accountService.userRole() == 'branch_user') {
    const chainId = accountService.getChainId()
    key = '?chain_id=' + chainId
    if (formType == 'Show Items') {
      key = '?chain_id=' + chainId + '&outlet_id=' + outletId
    }
  } else {
    const outletId = accountService.getOutletId()
    key = '?outlet_id=' + outletId
  }
  if (items) {
    key = '?outlet_id=' + outletId + '&menu_ids=' + items
  }
  return axios.get(
    baseUrl + `${environment.api.services.customize.menuitems}` + key,
    authFun()
  )
}

function getMenuItemById(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.menuitems}/` +
      id +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function getIpadMenuItems() {
  const outletId = accountService.getOutletId()
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.menuitems}/ipad_menu_items?outlet_id=` +
      outletId,
    authFun()
  )
}

function updateMenuItemById(id, body) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.menuitems}` + '/' + id,
    body,
    authFun()
  )
}

function updateMenuItemPriceById(id, body) {
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.menuitems}/update_price` +
      '?id=' +
      id,
    body,
    authFun()
  )
}
function updateOnlineDeatils(id, body) {
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.menuitems}/update_online_details` +
      '?id=' +
      id,
    body,
    authFun()
  )
}

function addOrRemoveImageUrl(id) {
  let body = { id: id }
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.menuitems}/add_or_remove_image_url` +
      '?id=' +
      id,
    body,
    authFun()
  )
}

//Menu Item List

function createMenuList(body) {
  return axios.post(
    baseUrl + `${environment.api.services.customize.menu_item_lists}`,
    body,
    authFun()
  )
}
function updateMenuList(id, body) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.menu_item_lists}/${id}`,
    body,
    authFun()
  )
}
function getMenuItemList(id) {
  return axios.get(
    baseUrl + `${environment.api.services.customize.menu_item_lists}/${id}`,
    authFun()
  )
}

function getMenuItemLists(body) {
  return axios.get(
    baseUrl +
      `${
        environment.api.services.customize.menu_item_lists
      }?chain_id=${accountService.getChainId()}`,
    authFun()
  )
}

function getFlipdishMenuSection(outId) {
  let outletId = outId && outId != '' ? outId : accountService.getOutletId()
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.flipdish_menu_selections}` +
      '?outlet_id=' +
      outletId,
    authFun()
  )
}

function getFlipDishById(menuId, menuSection, flipId, outletId) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.flipdish_by_id}` +
      '?flipdish_menu_id=' +
      menuId +
      '&flipdish_menu_section_id=' +
      menuSection +
      '&flipdish_item_id=' +
      flipId +
      '&id=' +
      outletId,
    authFun()
  )
}

function updateFlipDishToken(body, outlet = '') {
  let outletId = outlet != '' ? outlet : accountService.getOutletId()

  return axios.put(
    baseUrl +
      `${environment.api.services.customize.update_flipdish_token}` +
      '?outlet_id=' +
      outletId,
    body,
    authFun()
  )
}

function getFlipDishStore(appId, outlet = '') {
  let outletId = outlet != '' ? outlet : accountService.getOutletId()

  return axios.get(
    baseUrl +
      `${environment.api.services.customize.get_flipdish_store}` +
      '?outlet_id=' +
      outletId +
      '&flipdish_app_id=' +
      appId,
    authFun()
  )
}
function getFlipdishMenus(appId, outlet = '') {
  let outletId = outlet != '' ? outlet : accountService.getOutletId()
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.get_flipdish_menus}` +
      '?outlet_id=' +
      outletId +
      '&flipdish_app_id=' +
      appId,
    authFun()
  )
}

function getMapItems() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.get_map_items}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}

function getFlipdishApps(outletId) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.get_flipdish_apps}?outlet_id=${outletId}`,
    authFun()
  )
}

function getOnlineMenuItems() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.online_menu_items}` +
      '?outlet_id=' +
      accountService.getOutletId(),

    authFun()
  )
}

function getOnlineOrders() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.online_orders}` +
      '?outlet_id=' +
      accountService.getOutletId(),

    authFun()
  )
}

function getOnlineOrder(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.online_order}?order_id=` +
      id
  )
}

function getOnlinePromotions() {
  return axios.get(
    baseUrl + `${environment.api.services.customize.online_promotions}`,

    authFun()
  )
}

function refundOrder(id) {
  return axios.post(
    baseUrl +
      `${environment.api.services.customize.order_refund}?order_id=` +
      id
  )
}

function addPromotions(body) {
  return axios.post(
    baseUrl +
      `${environment.api.services.customize.promotions}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    body,
    authFun()
  )
}

function addOnlinePromotions(body) {
  return axios.post(
    baseUrl +
      `${environment.api.services.customize.online_promotions}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    body,
    authFun()
  )
}

function getOnlinePromotionById(id) {
  return axios.get(
    baseUrl + `${environment.api.services.customize.online_promotions}/` + id,

    authFun()
  )
}

function updateOnlinePromotion(id, body) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.online_promotions}/` + id,
    body,
    authFun()
  )
}

function deleteOnlinePromotion(id) {
  return axios.delete(
    baseUrl +
      `${environment.api.services.customize.online_promotions}/` +
      id +
      '.json',
    authFun()
  )
}

function createStripeAccount(body) {
  return axios.post(
    baseUrl +
      `${environment.api.services.stripe.create_account}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    body,

    authFun()
  )
}

function linkStripeAccount(body) {
  return axios.post(
    baseUrl +
      `${environment.api.services.stripe.create_account_link}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    body,

    authFun()
  )
}

function getOutletMenuItem(id, outlet_id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.menuitems}/outlet_menu_item` +
      '?id=' +
      id +
      '&outlet_id=' +
      outlet_id,

    authFun()
  )
}

function getOutletMenuItemById(id, outlet_id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.menuitems}/outlet_menu_item_by_id` +
      '?id=' +
      id +
      '&outlet_id=' +
      outlet_id,

    authFun()
  )
}

function updateOutletMenuItemById(data) {
  let body = { outlet_menus: data }
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.menuitems}/update_outlet_menu_prices` +
      '?outlet_id=' +
      accountService.getOutletId(),
    body,

    authFun()
  )
}

//Menu Category edit

function updateMenuItems(details, outlet_id) {
  let body = {
    params: details,
  }
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.menu_category_edit}?outlet_id=` +
      outlet_id,
    body,
    authFun()
  )
}

//combos

function createCombo(details) {
  let body = {
    combos: details,
  }
  body.combos.chain_id = accountService.getChainId()
  return axios.post(
    baseUrl + `${environment.api.services.customize.combos}`,
    body,
    authFun()
  )
}

function getCombo(outlet_id = '') {
  let outId =
    outlet_id != '' ? (outId = outlet_id) : accountService.getOutletId()
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.combos}` +
      '?outlet_id=' +
      outId,
    authFun()
  )
}
function updateComboStatus(id, body) {
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.combos}` +
      '/update_status?id=' +
      id,
    body,
    authFun()
  )
}

//comboGroup

function createComboGroup(details) {
  let body = {
    combo_groups: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.combogroup}`,
    body,
    authFun()
  )
}

function editComboGroup(id, details) {
  let body = {
    combo_groups: details,
  }
  return axios.put(
    baseUrl + `${environment.api.services.customize.combogroup}` + '/' + id,
    body,
    authFun()
  )
}

function getComboGroup() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.combogroup}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}

function deleteComboGroup(id) {
  return axios.delete(
    baseUrl + `${environment.api.services.customize.combogroup}` + '/' + id,
    authFun()
  )
}

function getComboGroupById(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.combogroup}` +
      '/' +
      id +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

function updateComboGroupStatus(id, body) {
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.combogroup}` +
      '/update_status?id=' +
      id,
    body,
    authFun()
  )
}

//Portions

function createPortions(details) {
  let body = {
    portion: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.portion}`,
    body,
    authFun()
  )
}

function getPortions() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.portion}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}
function updatePortion(id, body) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.portion}/` + id,
    body,
    authFun()
  )
}
//taxes curd

function getTaxes() {
  let type = 'Distributor'
  if (accountService.userRole() == 'branch_user') {
    type = 'Business Owner'
    return axios.get(
      baseUrl +
        `${environment.api.services.customize.taxes}` +
        '?type=' +
        type +
        '&chain_id=' +
        accountService.getChainId(),
      authFun()
    )
  } else {
    return axios.get(
      baseUrl +
        `${environment.api.services.customize.taxes}` +
        '?type=' +
        type +
        '&distributor_id=' +
        accountService.getDistributorId(),
      authFun()
    )
  }
}

function taxes(details) {
  function params() {
    if (details.type == 'Business Owner') {
      return accountService.getChainId()
    } else {
      return accountService.getDistributorId()
    }
  }
  let body = {
    tax: details,
  }
  return axios.put(
    baseUrl + `${environment.api.services.customize.taxes}` + '/' + params(),
    body,
    authFun()
  )
}

// Reprinters

function createReprinter(details) {
  let body = {
    reprinter: details,
  }
  body.reprinter.chain_id = accountService.getChainId()
  return axios.post(
    baseUrl + `${environment.api.services.customize.reprinter}`,
    body,
    authFun()
  )
}

function getReprinter() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.reprinter}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}

// Discount Category

function createDiscountCat(details) {
  let body = {
    discount_categories: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.discountcategory}`,
    body,
    authFun()
  )
}

function getDiscountCat() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.discountcategory}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}

function updateDiscountCat(id, body) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.discountcategory}/` + id,
    body,
    authFun()
  )
}

// Scheduled Report

function getScheduledReport() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.scheduledreport}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}

function createScheduledReport(body) {
  return axios.post(
    baseUrl + `${environment.api.services.customize.scheduledreport}`,
    body,
    authFun()
  )
}

//Other Payment Types

function createOtherPayment(details) {
  let body = {
    other_payment_types: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.otherpaymenttype}`,
    body,
    authFun()
  )
}

function getOtherPaymentTypes(outlet_id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.otherpaymenttype}?outlet_id=${outlet_id}`,
    authFun()
  )
}
function updateOtherPaymentTypes(id, body) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.otherpaymenttype}/` + id,
    body,
    authFun()
  )
}

//Table

function createTable(details) {
  let body = {
    tables: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.table}`,
    body,
    authFun()
  )
}

function getTable() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.table}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}
function updateTable(id, body) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.table}/` + id,
    body,
    authFun()
  )
}
//Table Position

function updateTablePosition(details, zoom_factor = 1) {
  let body = {
    tables: details,
    outlet: {
      floor_plan_zoom_factor: zoom_factor,
    },
  }
  body.outlet_id = accountService.getOutletId()
  body.outlet_id = accountService.getOutletId()

  return axios.put(
    baseUrl +
      `${environment.api.services.customize.table}` +
      '/update_table_position',
    body,
    authFun()
  )
}
//table areas

function getTableAreas() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.tableareas}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}

function createTableArea(details) {
  let body = {
    table_areas: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.tableareas}`,
    body,
    authFun()
  )
}

function updateTableArea(id, body) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.tableareas}/` + id,
    body,
    authFun()
  )
}

//Void Reasons

function getVoidReasons() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.voidreasons}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}

function createVoidReasons(details) {
  let body = {
    quick_notes: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.voidreasons}`,
    body,
    authFun()
  )
}

function updateVoidReasons(id, details) {
  let body = {
    quick_notes: details,
  }
  return axios.put(
    baseUrl + `${environment.api.services.customize.voidreasons}/` + id,
    body,
    authFun()
  )
}

//staffs

function getEmployees() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.employees}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}

function createStaff(details) {
  let body = {
    employes: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.employees}`,
    body,
    authFun()
  )
}

function updateEmployee(id, body) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.employees}/` + id,
    body,
    authFun()
  )
}
function checkPinAvailability(password) {
  let outletId = accountService.getOutletId()
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.employees}/pin_availability?outlet_id=${outletId}&password=${password}`,
    authFun()
  )
}

function anonymiseStaff(query) {
  let body = query
  return axios.post(
    baseUrl + `${environment.api.services.customize.employees}/anonymise_data`,
    body,
    authFun()
  )
}

//profiles

function createProfile(details) {
  let body = {
    terminal_profiles: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.profile}`,
    body,
    authFun()
  )
}

function getProfiles() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.profile}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}
function updateProfile(id, details) {
  let body = {
    terminal_profiles: details,
  }
  return axios.put(
    baseUrl + `${environment.api.services.customize.profile}/` + id,
    body,
    authFun()
  )
}
//profiles

function createTerminal(details) {
  let body = {
    terminals: details,
  }
  return axios.post(
    baseUrl + `${environment.api.services.customize.terminals}`,
    body,
    authFun()
  )
}

function updateTerminal(id, details) {
  let body = {
    terminals: details,
  }
  return axios.put(
    baseUrl + `${environment.api.services.customize.terminals}/` + id,
    body,
    authFun()
  )
}

function getterminals() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.terminals}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}

// Staff Role

function getStaffRoles() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.staffrole}` +
      '?outlet_id=' +
      accountService.getOutletId(),
    authFun()
  )
}

function createStaffRole(details) {
  let body = {
    staff_roles: details,
  }

  return axios.post(
    baseUrl + `${environment.api.services.customize.staffrole}`,
    body,
    authFun()
  )
}

function updateStaffRole(id, details) {
  let body = {
    staff_roles: details,
  }

  return axios.put(
    baseUrl + `${environment.api.services.customize.update_staffrole}` + id,
    body,
    authFun()
  )
}

//Assign Items for outlets

function assignItems(details, outletId) {
  let body = {
    menu_items: details,
  }

  return axios.post(
    baseUrl +
      `${environment.api.services.customize.assignitems}` +
      '?outlet_id=' +
      outletId,
    body,
    authFun()
  )
}

function getAssignItems(outletId) {
  let chain_id = accountService.getChainId()
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.getassignitems}` +
      '?outlet_id=' +
      outletId +
      '&chain_id=' +
      chain_id,
    authFun()
  )
}

// Roles

function getRoles() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.roles}` +
      '?user_id=' +
      accountService.getUserId(),
    authFun()
  )
}

function createRole(details) {
  let body = {
    user_package: details,
  }

  return axios.post(
    baseUrl + `${environment.api.services.customize.roles}`,
    body,
    authFun()
  )
}

function deleteRole(id) {
  return axios.delete(
    baseUrl + `${environment.api.services.customize.getrole}` + id + '.json',
    authFun()
  )
}

function getPackageUsingRole(id) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.editpackage}` +
      accountService.userPackage() +
      '.json' +
      '?role_id=' +
      id,
    authFun()
  )
}

function editRole(id, details) {
  let body = {
    user_package: details,
  }

  return axios.put(
    baseUrl + `${environment.api.services.customize.getrole}` + id + '.json',
    body,
    authFun()
  )
}

// Order Types

function updateOrderTypes(orderTypes) {
  let body = {
    outlets: {
      outlet: orderTypes,
    },
  }
  return axios.put(
    baseUrl +
      `${environment.api.services.customize.editoutlet}` +
      accountService.getOutletId() +
      '.json',
    body,
    authFun()
  )
}

// iPad Grid

function fetchIpadCategory(tabCount) {
  let body = {
    items: {
      name: 'Custom-' + tabCount,
      outlet_id: accountService.getOutletId(),
    },
  }

  return axios.post(
    baseUrl + `${environment.api.services.ipad.ipad_category}`,
    body,
    authFun()
  )
}

function saveGrid(data) {
  return axios.post(
    baseUrl + `${environment.api.services.ipad.ipad_grid}`,
    data,
    authFun()
  )
}

function get_grid(outletId) {
  return axios.get(
    baseUrl +
      `${environment.api.services.ipad.get_grid}` +
      '?outlet_id=' +
      outletId,
    authFun()
  )
}

function get_categories(outletId) {
  return axios.get(
    baseUrl +
      `${environment.api.services.ipad.get_categories}` +
      '?outlet_id=' +
      outletId,
    authFun()
  )
}

function change_item_color(color, id) {
  let body = {
    menu_items: {
      item_color: color,
    },
  }
  return axios.put(
    baseUrl + `${environment.api.services.customize.menuitems}` + '/' + id,
    body,
    authFun()
  )
}

function change_combo_color(color, id) {
  let body = {
    combos: {
      combo: {
        combo_color: color,
      },
    },
  }
  return axios.put(
    baseUrl + `${environment.api.services.customize.combos}` + '/' + id,
    body,
    authFun()
  )
}

function change_category_color(color, id) {
  let body = {
    ipad_category: {
      category_color: color,
    },
  }
  return axios.put(
    baseUrl + `${environment.api.services.ipad.update_category}` + '?id=' + id,
    body,
    authFun()
  )
}

function change_category_name(name, id) {
  let body = {
    ipad_category: {
      name: name,
    },
  }
  return axios.put(
    baseUrl + `${environment.api.services.ipad.update_category}` + '?id=' + id,
    body,
    authFun()
  )
}

function main_category_color(color, id) {
  let body = {
    main_categories: {
      code_color: color,
    },
  }
  return axios.put(
    baseUrl + `${environment.api.services.customize.editmaincategory}` + id,
    body,
    authFun()
  )
}

function delete_category(id) {
  return axios.delete(
    baseUrl + `${environment.api.services.ipad.delete_category}` + '?id=' + id,
    authFun()
  )
}

//Integrations

function createIntegration(details) {
  return axios.post(
    baseUrl + `${environment.api.services.customize.integrations}`,
    details,
    authFun()
  )
}

function getIntegrations() {
  return axios.get(
    baseUrl + `${environment.api.services.customize.integrations}`,
    authFun()
  )
}

function getIntegrationByPackage(packageId) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.integrations_by_package}?package_id=${packageId}`,
    authFun()
  )
}

// Menu Item Import

function menuItemImport(formdata) {
  let chainId = accountService.getChainId()
  return axios.post(
    baseUrl +
      `${environment.api.services.customize.menu_item_import}?chain_id=${chainId}`,
    formdata,
    authFun()
  )
}

function voucherImport(formdata) {
  let outletId = accountService.getOutletId()
  return axios.post(
    baseUrl +
      `${environment.api.services.customize.promotions}/import_from_excel?outlet_id=${outletId}`,
    formdata,
    authFun()
  )
}
//update categories

function update_categories(details, type) {
  const categoryType = type
  let url = ''

  switch (categoryType) {
    case 'super':
      url =
        environment.api.services.customize.supercategory +
        '/update_super_categories'
      break
    case 'main':
      url =
        environment.api.services.customize.maincategory +
        '/update_main_categories'
      break
    case 'sub':
      url =
        environment.api.services.customize.subcategory +
        '/update_sub_categories'
      break
    case 'quick_note':
      url =
        environment.api.services.customize.voidreasons + '/update_void_reasons'
      break
    case 'section':
      url =
        environment.api.services.customize.menusection + '/update_menu_sections'
      break

    case 'table':
      url = environment.api.services.customize.table + '/update_table_position'
      break
    case 'modgroup':
      url =
        environment.api.services.customize.modifiergroup +
        '/update_modifier_groups'
      break
    case 'menu':
      url = environment.api.services.customize.menuitems + '/update_menu_items'
      break
    case 'menulist':
      url =
        environment.api.services.customize.menu_item_lists +
        '/update_menu_lists'
      break
    case 'screen':
      url = environment.api.services.customize.screen + '/update_lists'
      break
    default:
      console.log('Try looking up for a hint.')
  }
  return axios.put(baseUrl + `${url}`, details, authFun())
}

// Employe Information

function deleteEmployeInfo(id) {
  return axios.delete(
    baseUrl + `${environment.api.services.customize.employe_info}` + '/' + id,
    authFun()
  )
}

function updateEmployeInfo(data, id) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.employe_info}` + '/' + id,
    data,
    authFun()
  )
}

function createEmployeInfo(data) {
  return axios.post(
    baseUrl + `${environment.api.services.customize.employe_info}`,
    data,
    authFun()
  )
}

function getVouchers() {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.promotions}/list_vouchers?outlet_id=` +
      accountService.getOutletId(),
    authFun()
  )
}

function getUsers() {
  return axios.get(
    baseUrl + `${environment.api.services.accounts.userdetails}`,
    authFun()
  )
}

function getUserById(id) {
  return axios.get(
    baseUrl + `${environment.api.services.accounts.userdetailsbyid}/?id=` + id,
    authFun()
  )
}

function updateUser(id, data) {
  return axios.put(
    baseUrl +
      `${environment.api.services.accounts.updateuserdetails}/?id=` +
      id,
    data,
    authFun()
  )
}

function registerUser(data) {
  return axios.post(
    baseUrl + `${environment.api.services.customize.outlet}/register_outlet`,
    data
  )
}

function sendMessage(data) {
  return axios.post(
    baseUrl + `${environment.api.services.customize.outlet}/send_message`,
    data
  )
}
function goToOnboard() {
  let outletId = accountService.getOutletId()

  return axios.get(
    baseUrl +
      `${environment.api.services.adyen.end_point}/get_onboarding_link?outlet_id=` +
      outletId,
    authFun()
  )
}

function getCbePay() {
  let outletId = accountService.getOutletId()

  return axios.get(
    baseUrl +
      `${environment.api.services.adyen.end_point}/get_info?outlet_id=` +
      outletId,
    authFun()
  )
}

function getAdyenTransactions() {
  let outletId = accountService.getOutletId()

  return axios.get(
    baseUrl +
      `${environment.api.services.adyen.end_point}/transactions?outlet_id=` +
      outletId,
    authFun()
  )
}

function getAdyenPayments() {
  let outletId = accountService.getOutletId()

  return axios.get(
    baseUrl +
      `${environment.api.services.adyen.end_point}/payments?outlet_id=` +
      outletId,
    authFun()
  )
}
