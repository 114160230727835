import Vuex from 'vuex'
import Vue from 'vue'
import account from './modules/account'
import screen from './modules/screen'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    appBuildNumber: process.env.VUE_APP_BLDNUM || '999',
  },
  getters: {
    appBuildNumber: (state) => {
      return state.appBuildNumber
    },
  },
  modules: {
    account,
    screen,
  },
})
