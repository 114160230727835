<template>
  <div class="subcat-report menu-item-rep">
    <md-card class="report-card block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 report-name">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Wastage Detailed') }}</h3>
        </div>
      </md-card-header>

      <div class="md-layout">
        <div class="md-layout-item filter-section block-bg">
          <div class="md-layout search-options">
            <div class="md-layout-item md-size-55">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout-item md-size-35 pd-l-0">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-70 md-small-size-50 pd-l-10"
                  v-if="role() == 'branch_user'"
                >
                  <label>Outlet</label>
                  <md-field>
                    <md-select
                      v-model="outletId"
                      multiple
                      @md-closed="
                        outletId.length > 0 ? wastageDetails() : (outletId = [])
                      "
                    >
                      <md-option
                        v-for="item in outletList"
                        :key="item.id"
                        class="oulet-select-list-item"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
                <!-- </div>
                </div> -->
              </div>
            </div>
            <div class="md-layout-item md-size-10">
              <div class="float-right">
                <md-button class="md-tertiary" @click="wastageDetails()"
                  >Search
                </md-button>
              </div>
            </div>
          </div>
        </div>

        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div
          class="md-layout-item md-size-100 card-inside report-table"
          v-if="!loader"
        >
          <div class="xlsx-pdf">
            <img
              class="pdf"
              src="../../../../assets/images/pdf.svg"
              @click="downloadWithoutCSS()"
            />
            <vue-excel-xlsx
              class="excel-btn2 md-primary"
              :data="report"
              :columns="columns"
              :filename="'Wastage Detailed Report'"
              :sheetname="'Wastage Details'"
              :outlet-names="selectedOutletsNames()"
            ></vue-excel-xlsx>
          </div>

          <md-table class="rep-table table-typ1" v-if="!loader">
            <md-table-row>
              <md-table-head>SKU</md-table-head>
              <md-table-head>Menu Item</md-table-head>
              <md-table-head>Total Quantity</md-table-head>
              <md-table-head>Cost Value</md-table-head>
              <md-table-head>Sales Value</md-table-head>
            </md-table-row>
            <slot v-for="item in report">
              <md-table-row slot="md-table-row">
                <md-table-cell>{{ item.sku }}</md-table-cell>
                <md-table-cell>{{ item.menu_item }}</md-table-cell>
                <md-table-cell>{{ item.total_quantity }}</md-table-cell>
                <md-table-cell>{{ item.cost_value }}</md-table-cell>
                <md-table-cell>{{ item.sales_value }}</md-table-cell>
              </md-table-row>
            </slot>
          </md-table>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { reportService } from './../../../../services/reports'
import { customizeService } from './../../../../services/customize'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import { DateTimePicker } from '@/components'
import { accountService } from '../../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'

export default {
  components: {
    ChartCard,
    DateTimePicker,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
  },
  data() {
    return {
      columns: [
        {
          label: 'SKU',
          field: 'sku',
        },
        {
          label: 'Menu Item',
          field: 'menu_item',
        },
        {
          label: 'Total Quantity',
          field: 'total_quantity',
        },
        {
          label: 'Cost Value',
          field: 'cost_value',
        },
        {
          label: 'Sales Value',
          field: 'sales_value',
        },
      ],
      columnStyles: {
        0: { cellWidth: 26 },
        1: { cellWidth: 26 },
        2: { cellWidth: 26 },
        3: { cellWidth: 26 },
      },
      data: {
        search_type: 'Daily',
      },
      report: [],
      taxList: [],
      outletList: [],
      outletId: [],
      loader: false,
    }
  },

  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = [
          el.sku,
          el.menu_item,
          el.total_quantity,
          el.cost_value,
          el.sales_value,
        ]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      // this.data.startDate = this.sdate
      // this.data.endDate = this.edate
      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })

      let head = [
        ['SKU', 'Menu Item', 'Total Quantity', 'Cost Value', 'Sales Value'],
      ]

      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Wastage Detailed',
        selectedOutlets
      )
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        {},
        'wastage-detailed-report.pdf'
      )
    },
    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
        if (!this.loader) {
          this.wastageDetails()
        }
      }
    },
    wastageDetails() {
      this.loader = true
      this.data.outletId = this.outletId
      reportService.wastageDetails(this.data).then((res) => {
        this.loader = false
        this.report = res.data.result
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.wastageDetails()
      })
    },
    role() {
      return accountService.userRole()
    },
  },

  created() {
    this.searched = this.users
    this.loader = true
    if (accountService.userRole() == 'branch_user') {
      this.getOutlets()
    } else {
      this.wastageDetails()
    }
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}
.md-table.md-theme-default .md-table-content {
  display: none;
}
.pd-l-0 {
  padding-left: 0px;
}

.md-button-content {
  font-size: 12px;
}
.menu-item-rep .md-ripple {
  padding: 6px 15px;
  height: auto !important;
  border-radius: 0px;
}
.content .menu-item-rep .md-ripple {
  padding: 0px !important;
}
.card-inside {
  .md-button {
    .md-ripple {
      padding: 0px 0px;
    }
  }
}
.rep-table {
  margin-top: 0px;
}
</style>
