<template>
  <div class="report-table">
    <md-card class="report-card block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Hourly Summary Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout filter-section block-bg">
          <div class="md-layout search-options">
            <div class="md-layout-item md-size-60">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout-item md-size-25">
              <div v-if="role() == 'branch_user'">
                <md-field>
                  <label>Outlet</label>
                  <md-select
                    v-model="outletId"
                    multiple
                    @md-closed="
                      outletId.length > 0 ? hourlySummary() : (outletId = [])
                    "
                  >
                    <md-option
                      v-for="item in outletList"
                      :key="item.id"
                      :value="item.id"
                      class="oulet-select-list-item"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout-item md-size-15">
              <md-button class="md-primary" @click="hourlySummary()"
                >Search
              </md-button>
            </div>
          </div>
        </div>
        <div class="md-layout report-drop">
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>
          <div class="md-layout-item md-size-100" v-if="!loader">
            <h4 class="center">Hourly Summary- {{ sdate }} to {{ edate }}</h4>
            <h5 class="center">
              Average no.of transactions/h:{{ avgTxnCount }}
            </h5>
            <h5 class="center">Average transation value/day: {{ avgTxn }}</h5>
          </div>
          <div class="chart-container" style="position: relative; width: 50vw">
            <bar-chart
              :chart-data="datacollection"
              :options="chartOptions"
            ></bar-chart>
          </div>
          <tabs
            :tab-name="['Split', 'All']"
            color-button="success"
            v-if="!loader"
          >
            <!-- here you can add your content for tab-content -->

            <template slot="tab-pane-1">
              <div class="md-layout">
                <div class="md-layout-item">
                  <div ref="content" id="mytable">
                    <md-table class="table-typ1">
                      <md-table-toolbar>
                        <div class="md-toolbar-section-start">
                          <div class="xlsx-pdf">
                            <img
                              class="pdf"
                              src="../../../../assets/images/pdf.svg"
                              @click="downloadWithoutCSS()"
                            />
                            <vue-excel-xlsx
                              class="excel-btn2 md-primary"
                              :data="splitReport"
                              :columns="splitColumns"
                              :filename="'Hourly Report'"
                              :sheetname="'Hourly Report'"
                              :sdate="sdate"
                              :edate="edate"
                              :outlet-names="selectedOutletsNames()"
                            ></vue-excel-xlsx>
                          </div>
                        </div>
                      </md-table-toolbar>

                      <slot v-for="items in splitReport">
                        <md-table-row>
                          <md-table-cell>{{ items.type }}</md-table-cell>
                          <md-table-cell>{{ items.guest }}</md-table-cell>
                          <md-table-cell>{{ items.sales_totl }}</md-table-cell>
                          <md-table-cell>{{ items.txns }}</md-table-cell>
                          <md-table-cell>{{ items.avg_bill }}</md-table-cell>
                          <md-table-cell>{{
                            items.avg_cus_spend
                          }}</md-table-cell>
                        </md-table-row>
                      </slot>
                    </md-table>
                  </div>
                </div>
              </div>
            </template>
            <template slot="tab-pane-2">
              <div class="md-layout">
                <div class="md-layout-item">
                  <div ref="content" id="mytable">
                    <md-table>
                      <md-table-toolbar>
                        <div class="md-toolbar-section-start">
                          <div class="xlsx-pdf">
                            <img
                              class="pdf"
                              src="../../../../assets/images/pdf.svg"
                              @click="downloadWithoutCSS()"
                            />
                            <vue-excel-xlsx
                              class="excel-btn2 md-primary"
                              :data="allReport"
                              :columns="columns"
                              :filename="'Hourly Summary Report'"
                              :sheetname="'Hourly Summary Report'"
                            ></vue-excel-xlsx>
                          </div>
                        </div>
                      </md-table-toolbar>
                      <md-table-row>
                        <md-table-head>Time</md-table-head>
                        <md-table-head>Guest</md-table-head>
                        <md-table-head>Sales Total</md-table-head>
                        <md-table-head>Transaction</md-table-head>
                        <md-table-head>Avg Bill</md-table-head>
                        <md-table-head>Avg Customer Spend</md-table-head>
                      </md-table-row>
                      <slot v-for="items in allReport">
                        <md-table-row>
                          <md-table-cell>{{ items.type }}</md-table-cell>
                          <md-table-cell>{{ items.guest }}</md-table-cell>
                          <md-table-cell>{{ items.sales_totl }}</md-table-cell>
                          <md-table-cell>{{ items.txns }}</md-table-cell>
                          <md-table-cell>{{ items.avg_bill }}</md-table-cell>
                          <md-table-cell>{{
                            items.avg_cus_spend
                          }}</md-table-cell>
                        </md-table-row>
                      </slot>
                    </md-table>
                  </div>
                </div>
              </div>
            </template>
          </tabs>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { Tabs } from '@/components'
import { accountService } from '../../../../services/account.js'
import { reportService } from '../../../../services/reports.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import { DateTimePicker } from '@/components'
import { customizeService } from '../../../../services/customize'
import { pdfService } from '../../../../services/jspdf.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
import LineChart from '../../../../components/Graphs/LineChart.vue'
import BarChart from '../../../../components/Graphs/BarChart.vue'

export default {
  components: {
    Tabs,
    DateTimePicker,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
    LineChart,
    BarChart,
  },
  computed: {},
  data() {
    return {
      splitReport: [],
      allReport: [],
      data: {
        search_type: 'Daily',
      },
      outletList: [],
      outletId: [],
      columns: [
        {
          label: 'Time',
          field: 'type',
        },
        {
          label: 'Guest',
          field: 'guest',
        },
        {
          label: 'Sales Total',
          field: 'sales_totl',
        },
        {
          label: 'Transaction',
          field: 'txns',
        },
        {
          label: 'Avg Bill',
          field: 'avg_bill',
        },
        {
          label: 'Avg Customer Spend',
          field: 'avg_cus_spend',
        },
      ],
      splitColumns: [
        {
          field: 'type',
        },
        {
          field: 'guest',
        },
        {
          field: 'sales_totl',
        },
        {
          field: 'txns',
        },
        {
          field: 'avg_bill',
        },
        {
          field: 'avg_cus_spend',
        },
      ],
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
        3: { cellWidth: 40 },
        4: { cellWidth: 40 },
        5: { cellWidth: 40 },
      },
      datacollection: null,
      loader: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        layout: {
          padding: {
            left: 10,
            right: 20,
            top: 30,
            bottom: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                fontSize: 14,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 14,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
      report: '',
      sdate: '',
      edate: '',
      avgTxnCount: '',
      avgTxn: '',
    }
  },
  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
    },
    arrangeForPdf() {
      var body = []
      this.allReport.forEach((el) => {
        let arrayObj = [
          el.type,
          el.guest,
          el.sales_totl,
          el.txns,
          el.avg_bill,
          el.avg_cus_spend,
        ]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })
      let selectedOutlets = this.selectedOutletsNames()
      this.data.startDate = this.sdate
      this.data.endDate = this.edate
      let head = [
        [
          'Time',
          'Guest',
          'Sales Total',
          'Transaction',
          'Avg Bill',
          'Avg Cstr Spend',
        ],
      ]

      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Hourly Summary Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        {},
        'hourly-summary-report.pdf'
      )
    },

    splitArrangeForPdf() {
      var body = []
      this.splitReport.forEach((el) => {
        let arrayObj = [
          el.type,
          el.guest,
          el.sales_totl,
          el.txns,
          el.avg_bill,
          el.avg_cus_spend,
        ]
        body.push(arrayObj)
      })
      return body
    },
    splitDownloadWithoutCSS() {
      let body = this.splitArrangeForPdf()
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      var header = function (data) {
        doc.setFontSize(16)
        doc.setTextColor(40)
        doc.setFontStyle('normal')
        //doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
        doc.text('Hourly Summary Report', 120, 20)

        doc.setFontSize(10)
        doc.setTextColor(40)
        doc.setFontStyle('normal')
        doc.text(
          'Page ' + doc.internal.getNumberOfPages(),
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        )
      }

      let head = [[]]
      doc.autoTable({
        body: body,
        margin: { top: 30 },
        didDrawPage: header,
      })

      doc.save('Hourly Summary Report.pdf')
    },

    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
        this.hourlySummary()
      }
    },

    hourlySummary() {
      // reportService.getSubCatReport(this.startDate, this.endDate).then(res => {
      //   console.log("sub category rep", res.data);
      //   this.reportApiRes = res.data.result;
      // });
      this.loader = true
      this.data.outletId = this.outletId
      reportService.getHourlySummaryReport('split', this.data).then((res) => {
        this.splitReport = res.data.result
        this.loader = false
        this.sdate = res.data.sdate
        this.edate = res.data.edate
      })
      reportService.getHourlySummaryReport('all', this.data).then((res) => {
        this.allReport = res.data.result
        this.report = res.data.result
        this.loader = false
        this.sdate = res.data.sdate
        this.edate = res.data.edate
        this.fillData()
      })
    },
    fillData() {
      this.datacollection = {
        labels: [
          '1:00',
          '2:00',
          '3:00',
          '4:00',
          '5:00',
          '6:00',
          '7:00',
          '8:00',
          '9:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
          '00:00',
        ],
        datasets: [
          {
            label: 'No.of transactions/h',
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0,
            ],
            fill: 'false',
            borderColor: 'red',
            backgroundColor: '#00E0DB',
          },
          {
            label: 'Average Hourly Transactions',
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0,
            ],
            fill: 'false',
            borderColor: 'blue',
            backgroundColor: '#E59866',
          },
        ],
      }
      let count = this.report.reduce((n, x) => n + (x.txns != 0), 0)

      let average = this.report[this.report.length - 1].txns / (count - 1)
      let averageTxn = 0
      if (this.report[this.report.length - 1].sales_totl > 0) {
        averageTxn =
          this.report[this.report.length - 1].sales_totl /
          this.report[this.report.length - 1].txns
      }

      this.avgTxnCount = average.toFixed(2)
      this.avgTxn = averageTxn.toFixed(2)

      this.report.forEach((el) => {
        if (el.hour != '') {
          let matchedIndex = this.datacollection.labels.findIndex(
            (time) => time == el.hour
          )
          if (matchedIndex != undefined) {
            this.datacollection.datasets[0].data[matchedIndex] = el.txns
            this.datacollection.datasets[1].data[matchedIndex] = average
          } else {
            this.datacollection.datasets[0].data.push(el.sales_totl)
          }
        }
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.hourlySummary()
      })
    },
    role() {
      return accountService.userRole()
    },
  },
  created() {
    // this.searched = this.users
    this.getOutlets()
    this.loader = true
  },
  mounted() {},
  watch: {},
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.menu-icon {
  padding-right: 20px;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}

.tab-pane {
  width: 100%;
}
.md-table {
  overflow: hidden !important;
}
.md-elevation-0 {
  position: relative;
}
.xlsx-pdf {
  position: absolute;
  right: 0;
}

.arrow_left {
  float: left;
}
</style>
