var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-div",attrs:{"id":"eod-canvas"}},_vm._l((_vm.msg),function(data,index){return _c('table',{key:'main-' + index,class:[
      _vm.eodReportTableFormatType[data.formatType],
      'first-col-' + data.firstColWidth ]},[(data.title)?_c('div',{staticClass:"category-heading"},[_vm._v(_vm._s(data.title))]):_vm._e(),_c('tr',{class:{ 'row-border-bottom': data.header.length > 0 },attrs:{"id":"head-holder"}},_vm._l((data.header),function(header,index){return _c('th',{key:'heder-' + index,class:_vm.eodReportTableHeaderFormatType[data.headerFormatType],style:({
          width:
            data.firstColWidth && index != 0
              ? (100 - data.firstColWidth) / (data.header.length - 1) + '%'
              : data.firstColWidth + '%',
        })},[_vm._v(" "+_vm._s(header)+" ")])}),0),_vm._l((data.row),function(info,index){return _c('tr',{key:'info-' + index,class:{ 'row-bold': info.isBold }},_vm._l((info.col),function(col,index){return _c('td',{key:'col-' + index,style:({
          width:
            data.firstColWidth && index != 0
              ? (100 - data.firstColWidth) / (info.col.length - 1) + '%'
              : data.firstColWidth + '%',
        })},[_vm._v(" "+_vm._s(col)+" ")])}),0)}),_c('tr',{class:{ 'row-border-top': data.footer.length > 0 }},_vm._l((data.footer),function(footer,index){return _c('td',{key:'footer-' + index,style:({
          width:
            data.firstColWidth && index != 0
              ? (100 - data.firstColWidth) / (data.footer.length - 1) + '%'
              : data.firstColWidth + '%',
        })},[_c('b',[_vm._v(_vm._s(footer))])])}),0)],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }