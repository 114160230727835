<template>
  <div class="full-div">
    <div>
      <div class="md-layout text-center login-card forgot-password-block">
        <div
          class="md-layout-item md-medium-size-100 md-xlarge-size-40 md-large-size-40"
        >
          <form @submit.prevent="handleSubmit">
            <login-card class="login-card-css">
              <h4 slot="title" class="card-header">
                <a href="#/login">
                  <img
                    class="logo-img"
                    src="../../../assets/images/cbe_innova_logo.png"
                  />
                </a>
              </h4>

              <div slot="inputs">
                <md-field class="md-form-group">
                  <label class="text-label">Username</label>
                  <md-input
                    v-model="user.email"
                    class="input-class form-control"
                  ></md-input>
                  <div
                    v-if="submitted && $v.user.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.email.required"
                      >Email is required</span
                    >
                    <span v-if="!$v.user.email.email">Email is invalid</span>
                  </div>
                </md-field>
                <md-field class="md-form-group">
                  <label class="text-label">Confirm Username</label>
                  <md-input
                    v-model="user.confirmEmail"
                    class="input-class form-control"
                  ></md-input>
                  <div
                    v-if="submitted && $v.user.confirmEmail.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.confirmEmail.required"
                      >Email is required</span
                    >
                    <span v-else-if="!$v.user.confirmEmail.sameAsEmail"
                      >Email doesn't match</span
                    >
                  </div>
                </md-field>
              </div>

              <div slot="footer" class="footer-cls">
                <md-button class="md-primary btn-forget" type="submit"
                  >SEND EMAIL</md-button
                >
              </div>
            </login-card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components'
import Vuelidate from 'vuelidate'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { accountService } from '../../../services/account'
import Swal from 'sweetalert2'

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      user: {
        email: null,
        confirmEmail: null,
      },
      submitted: false,
    }
  },
  validations: {
    user: {
      email: { required },
      confirmEmail: { required, sameAsEmail: sameAs('email') },
    },
  },

  methods: {
    handleSubmit(e) {
      this.submitted = true

      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      accountService.generatePasswordResetToken(this.user.email).then((res) => {
        // window.location.href = res.data.url
        Swal.fire(`Password reset instructions sent to  ${res.data.email}`)
      })
      // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.user))
    },
  },
}
</script>

<style>
.side-div {
  background-color: gray;
}
.full-div {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card.forgot-password-block {
  position: absolute;
  top: 16%;
  left: 0;
  right: 0;
}
.card-header {
  padding-bottom: 0px;
}
.login-card-css {
  max-width: 100%;
  padding-right: 28px;
  padding-left: 29px;
}
.remember-me {
  float: left;
  padding-left: 0px;
}
.forget-pass {
  float: right;
  padding-top: 13px;
  color: #49e0db !important;
}
.forget-pass {
  float: right;
  padding-top: 13px;
  color: #2b82be;
}
.signup-agree {
  line-height: 40px;
  color: gray;
}
.footer-cls {
  width: 100%;
}
.btn-forget {
  min-width: 91%;
  margin-left: 36px !important;
  margin-top: 46px !important;
}
.a-color {
  color: #2b82be;
}
.text-label {
  margin-left: 0px;
}

.input-class {
  margin-left: 0px;
}
.input-class:focus {
  color: #2b82be !important;
}
.logo-img {
  width: 60% !important;
}

.md-focused label {
  color: red;
}
</style>
