<template>
  <div class="md-layout modifierGpData block-bg" v-if="modifierGroup === true">
    <div class="md-layout-item md-size-100">
      <div class="modifier-add md-layout">
        <div
          class="md-layout-item md-size-50 mod-click"
          @click="menuListDialog = true"
        >
          <h5 class="text-dark">
            {{ $t('Menu Item List') }}
            <i
              class="md-icon md-icon-font md-theme-default cursor-pointer"
              :disabled="formType === 'View Menu Item'"
              >add</i
            >
          </h5>
        </div>
        <div class="md-layout-item md-size-50">
          <md-field
            v-if="confirmMenuList.length > 0 && formType != 'View Menu Item'"
          >
            <label>Sort By:</label>
            <md-select
              v-model="menuitems.group_sort_order_type"
              v-on:input="updateSortOrder()"
            >
              <md-option value="0">{{ $t('Priotity') }}</md-option>
              <md-option value="1">{{ $t('Ascending') }}</md-option>
              <md-option value="2">{{ $t('Descending') }}</md-option>
              <md-option value="3">{{ $t('Custom') }}</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
    </div>

    <div class="md-layout-item md-size-100" v-if="confirmMenuList.length > 0">
      <draggable
        class="list-group"
        :list="confirmMenuList"
        group="people"
        :move="enableDrag"
        :disabled="formType === 'View Menu Item'"
      >
        <transition-group>
          <div
            class="list-group-item"
            v-for="(element, index) in confirmMenuList"
            :key="`key-${index}`"
          >
            {{ element.name }}
          </div>
        </transition-group>
      </draggable>
    </div>

    <md-dialog :md-active.sync="menuListDialog" class="modifier-dialog">
      <md-dialog-title class="md-layout">
        <div class="md-layout-item md-size-50 left-padding">
          Menu Item Lists
        </div>
        <div class="close-icon">
          <i
            class="md-icon md-icon-font md-theme-default"
            @click="menuListDialog = false"
            >close</i
          >
        </div>
      </md-dialog-title>
      <div class="modifier-checkbox">
        <md-list>
          <md-list-item
            v-for="(item, index) in menuItemLists"
            :key="`key-${index}`"
          >
            {{ item.name }}
            <md-checkbox
              :model="menuListIds"
              :value="item.id"
              @change="findModifier()"
            ></md-checkbox>
          </md-list-item>
        </md-list>
      </div>
    </md-dialog>
  </div>
</template>

<script>
// import { Tabs } from '@/components'

// import MenuSection from './MenuSection.vue'
import { customizeService } from '@/services/customize.js'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
    // Tabs,
    // SuperCategory,
    // MainCategory,
    // SubCategory,
    // MenuSection,
  },
  props: {
    menuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      activeTab: 'tab-super',
      menuitems: {},
      modifierGroup: true,
      formType: 'New',
      confirmMenuList: [],
      menuItemLists: [],
      menuListDialog: false,
      menuListIds: this.menuList.map((item) => item.menu_item_list_id),
    }
  },

  methods: {
    updateSortOrder() {
      if (this.menuitems.group_sort_order_type == 0) {
        this.priList()
      } else if (this.menuitems.group_sort_order_type == 1) {
        this.orderList()
      } else if (this.menuitems.group_sort_order_type == 2) {
        this.descOrderList()
      } else {
        this.customOrder()
      }
    },
    priList() {
      let self = this
      this.confirmMenuList = []
      for (var i = 0; i < this.menuListIds.length; i++) {
        let body = this.menuItemLists.find(function (el) {
          return el.menu_item_list_id == self.menuListIds[i]
        })
        this.confirmMenuList.push(body)
      }
    },

    orderList() {
      this.confirmModGroups = this.confirmMenuList.sort((one, two) => {
        if (one.name < two.name) {
          return -1
        }
        if (one.name > two.name) {
          return 1
        }
        return 0
      })
      this.drag = false
    },

    descOrderList() {
      this.confirmModGroups = this.confirmMenuList.sort((one, two) => {
        if (one.name > two.name) {
          return -1
        }
        if (one.name < two.name) {
          return 1
        }
        return 0
      })
      this.drag = false
    },

    customOrder() {
      this.drag = true
      this.menuitems.group_sort_order_type = 3
    },
    enableDrag() {
      return this.drag
    },
    updateActiveTab() {
      this.activeTab = this.$route.name
    },
    findModifier() {
      let self = this
      this.confirmMenuList = []
      for (var i = 0; i < this.menuListIds.length; i++) {
        let body = this.menuItemLists.find(function (el) {
          el.menu_item_list_id = el.id
          return el.id == self.menuListIds[i]
        })

        this.confirmMenuList.push(body)
      }
      if (this.confirmMenuList[0]) {
        this.$emit(
          'set-drs-price',
          this.confirmMenuList[0].menu_list_items[0].menu_item_price
        )
      } else {
        this.$emit('set-drs-price', 0)
      }
    },
  },

  created() {
    console.log(this.menuList)
    customizeService.getMenuItemLists().then((res) => {
      this.menuItemLists = res.data.filter((el) => el.status == true)
      this.findModifier()
      // this.$router.push({ path: '/Products/Combos' })
    })
  },

  updated() {
    if (this.$route.path == '/products/categories') {
      this.$router.push({
        name: 'Super Category',
      })
      this.activeTab = 'tab-super'
    }
  },
}
</script>
<style scoped>
.list-group {
  padding: 17px 10px;
  width: 100%;
}

.list-group-item {
  margin: 5px;
  padding: 8px;
  background-color: #2b82be;
  color: white;
  border-radius: 5px;
}
.modifier-checkbox {
  padding: 24px;
}
.close-icon {
  margin-left: auto;
}
</style>
