<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <div>
        <md-card>
          <md-card-header
            class="md-card-header-text md-card-header-green md-layout md-gutter"
          >
            <div class="md-layout-item md-size-70">
              <span class="arrow_left ml-auto">
                <router-link :to="{ path: '/products/MenuItem' }">
                  <img src="../../../assets/images/left.svg" />
                </router-link>
              </span>
              <h3>{{ $t(formType) }}</h3>
            </div>
            <div
              class="md-layout-item md-size-30 pad-xy"
              v-if="formType === 'View Menu Item'"
            >
              <i
                class="md-icon md-icon-font md-theme-default edit-img"
                @click="editMenuItem"
                >edit</i
              >
            </div>
          </md-card-header>

          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>

          <md-card-content v-if="!loader" class="block-bg pad-xy rounded-xl">
            <form data-vv-scope="menu">
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Name') }}*</label>
                    <md-input
                      type="text"
                      v-model="menuitems.name"
                      data-vv-name="Name"
                      :disabled="true"
                    ></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('POS Short Name') }}*</label>
                    <md-input
                      type="text"
                      v-model="menuitems.short_name"
                      data-vv-name="shortName"
                      :disabled="true"
                    ></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout"></div>

              <div class="md-layout">
                <div class="md-layout-item md-size-25">
                  <md-field>
                    <label>{{ $t('Sale Price') }}*</label>
                    <md-input
                      type="text"
                      v-model="menuitems.price"
                      data-vv-name="salePrice"
                      :disabled="
                        !$store.state.account.outlet.allow_price_change ||
                        formType === 'View Menu Item'
                      "
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25">
                  <md-field>
                    <label for="Tax">{{ $t('Tax') }}</label>

                    <md-select
                      v-model="menuitems.tax"
                      :disabled="
                        !$store.state.account.outlet.allow_price_change ||
                        formType === 'View Menu Item'
                      "
                    >
                      <md-option
                        :value="item"
                        v-for="item in taxList"
                        :key="item"
                        >{{ item }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
                <div
                  v-for="element in priceLevels"
                  :key="element.id"
                  class="md-layout-item md-size-50"
                >
                  <md-field>
                    <label> {{ element.name }}</label>
                    <md-input
                      type="text"
                      v-model="element.s_price"
                      :disabled="formType === 'View Menu Item'"
                    ></md-input>
                  </md-field>
                </div>

                <!-- Single Item Combo -->

                <div class="md-layout-item md-size-50">
                  <md-checkbox
                    v-model="isSingleCombo"
                    @click="isSingleCombo = !isSingleCombo"
                    :disabled="formType === 'View Menu Item'"
                    >{{ $t('Single Combo?') }}?</md-checkbox
                  >
                  <div class="md-layout-item" v-if="isSingleCombo">
                    <md-field>
                      <label for="Combos">{{ $t('Combos') }}</label>
                      <!-- <md-select
                                  v-model="menuitems.single_combo_id"
                                  @click="getCombos()"
                                >
                                  <md-option
                                    v-for="item in combos"
                                    :key="item.id"
                                    :value="item.id"
                                    >{{ item.name }}</md-option
                                  >
                                </md-select> -->

                      <md-select
                        v-model="menuitems.single_combo_id"
                        :disabled="formType === 'View Menu Item'"
                      >
                        <md-option
                          :value="item.id"
                          v-for="item in combos"
                          :key="item.id"
                          >{{ item.name }}</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Take Out Price') }}*</label>
                    <md-input
                      type="text"
                      v-model="menuitems.take_away_price"
                      data-vv-name="salePrice"
                      :disabled="
                        !$store.state.account.outlet.allow_price_change ||
                        formType === 'View Menu Item'
                      "
                    ></md-input>
                  </md-field>
                </div>
                <div
                  v-for="element in priceLevels"
                  :key="element.id"
                  class="md-layout-item md-size-50"
                >
                  <md-field>
                    <label> Take Out {{ element.name }}</label>
                    <md-input
                      type="text"
                      v-model="element.t_price"
                      :disabled="formType === 'View Menu Item'"
                    ></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label for="Tax">{{ $t('Take Out Tax') }}</label>

                    <md-select
                      v-model="menuitems.take_away_tax"
                      :disabled="
                        !$store.state.account.outlet.allow_price_change ||
                        formType === 'View Menu Item'
                      "
                    >
                      <md-option
                        :value="item"
                        v-for="item in taxList"
                        :key="item"
                        >{{ item }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t('Printer') }}</label>
                    <md-select
                      v-model="menuitems.printer_ids"
                      multiple
                      @click="printer()"
                    >
                      <md-option
                        :value="item.id"
                        v-for="item in printerList"
                        :key="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-checkbox v-model="flipDish">{{
                    $t('Flip Dish')
                  }}</md-checkbox>
                </div>
                <flipdishForm
                  ref="flipdishform"
                  :flipdishProp="query_param"
                  :flipTitle="menuitems.name"
                  :flipDescription="menuitems.short_name"
                  :flipPrice="menuitems.price"
                  v-show="flipDish"
                ></flipdishForm>
              </div>

              <div class="md-layout" v-if="$store.state.account.isOnline">
                <div class="md-layout-item md-size-50">
                  <md-checkbox v-model="isOnline">{{
                    $t('Online ?')
                  }}</md-checkbox>
                </div>

                <form data-vv-scope="online">
                  <onlineOrder
                    :mod-groups="modGroups"
                    :onlineModGroups="menuitems.online_modifier_groups"
                    :onlineData="menuitems.online_details"
                    :price="menuitems.price"
                    :name="menuitems.name"
                    :menuItem="menuitems"
                    ref="onlineForm"
                    v-if="isOnline"
                  />
                </form>
              </div>

              <md-button
                native-type="submit"
                class="md-primary"
                @click="submitMenuItem()"
                >{{ $t('SAVE') }}</md-button
              >
            </form>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <!-------------------------------------------------- Dialog Box -------------------------------------------------->

    <!-- Price Levels -->
    <md-dialog :md-active.sync="menuPricLevels" class="menu-price-levels">
      <h4>Price Levels</h4>
      <md-dialog-content>
        <div v-for="element in priceLevels" :key="element.id">
          <md-field>
            <label> {{ element.name }}</label>
            <md-input
              type="text"
              v-model="element.s_price"
              :disabled="formType === 'View Menu Item'"
            ></md-input>
          </md-field>
        </div>
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="takeOutPricLevels" class="menu-price-levels">
      <div v-for="element in priceLevels" :key="element.id">
        <md-field>
          {{ element.name }}
          <md-input
            type="text"
            v-model="element.t_price"
            :disabled="formType === 'View Menu Item'"
          ></md-input>
        </md-field>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { Collapse } from '@/components'
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'

import Swatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import { SlideYDownTransition } from 'vue2-transitions'
import { accountService } from '../../../services/account'
import axios from 'axios'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import store from '@/store'
import flipdishForm from './Flipdish.vue'
import onlineOrder from './OnlineOrder.vue'

export default {
  name: 'DialogCustom',
  components: {
    Collapse,
    Swatches,
    SlideYDownTransition,
    ClipLoader,
    onlineOrder,
    flipdishForm,
  },
  provide() {
    return {
      $validator: this.$validator,
    }
  },
  data() {
    return {
      Name: '',
      shortName: '',
      sku: '',
      salePrice: '',
      tax: '',
      mainCat: '',
      removeNullProps: '',
      colorCodeShow: '',
      menuCodeShow: '',
      MenuId: '',

      formType: {
        type: String,
        default: 'Add Outlet',
      },
      outletId: '',

      menuitems: {
        modifier_groups: {},
        portions: [],
        is_price_edit: false,
        is_negative: false,
        is_mapped: false,
        status: 'active',
      },
      loader: false,
      menuPrices: [],
      printerList: [],
      printerCalled: false,
      combos: [],
      isSingleCombo: false,
      priceLevels: [],
      menuPricLevels: false,
      takeOutPricLevels: false,
      taxList: [],
      flipDish: false,
      query_param: {},
      isOnline: false,
      modGroups: [],
    }
  },
  methods: {
    validate(scope) {
      this.$validator.validateAll(scope).then((isValid) => {
        if (!this.is_online && isValid) {
          if (
            store.state.account.profile.user_type == 'branch_user' &&
            this.formType === 'Add Menu Item'
          ) {
            this.showOutlets = true
          } else {
            this.$emit('on-submit', this.alert(scope), isValid)
          }
        } else {
          this.$refs.onlineOder.$validator.validateAll('online').then((res) => {
            if (isValid && res) {
              this.$emit('on-submit', this.alert(scope), isValid)
            }
          })
        }
      })
    },

    alert(value) {
      var self = this
      reusableService.showSwal('Edit').then((result) => {
        if (result.value) {
          if (value === 'menu') {
            self.submitMenuItem()
          } else if (value === 'sub') {
            self.createSupercategory()
          } else if (value === 'main') {
            self.createMaincategory()
          }
        }
      })
    },

    formatPriceLevels(priceLevels) {
      return priceLevels.filter(
        (item) => item['s_price'] !== undefined || item['t_price'] !== undefined
      )
    },
    getModifierGroups() {
      customizeService.getModifierGroup().then((res) => {
        this.modGroups = res.data.filter((item) => item.status == 'active')
      })
    },

    submitMenuItem() {
      let body = {
        menu_items: {
          price: this.menuitems.price,
          outlet_id: this.$route.query.outlet_id,
          single_combo_id: this.isSingleCombo
            ? this.menuitems.single_combo_id
            : '',
          take_away_price: this.menuitems.take_away_price,
          take_away_tax: this.menuitems.take_away_tax,
          price_levels: this.formatPriceLevels(this.priceLevels),
          is_flipdish: this.flipDish,
          is_online: this.isOnline,
          tax: this.menuitems.tax,
          ...(this.menuitems.printer_ids.length > 0 && {
            printer_ids: this.menuitems.printer_ids,
          }),
        },
      }

      customizeService
        .updateMenuItemPriceById(this.menuitems.id, body)
        .then((result) => {
          if (this.flipDish) {
            this.$refs.flipdishform.flipdish.query_param.is_flipdish =
              this.flipDish
            this.$refs.flipdishform.flipdish.query_param.menu_item_id =
              this.query_param.menu_item_id
            this.$refs.flipdishform.createFlipdishMenu(this.outletId)
          }
          if (this.isOnline) {
            this.$refs.onlineForm.isOnline = this.isOnline
            this.$refs.onlineForm.submit(
              this.menuitems.id,
              accountService.getOutletId()
            )
          }

          this.$router.push({
            name: 'MenuItem',
            query: {
              formType: 'Show Items',
              client: this.$route.query.outlet_id,
            },
          })
        })
    },
    printer() {
      return new Promise((resolve, reject) => {
        if (this.printerCalled === false) {
          customizeService
            .getStation()
            .then((res) => {
              this.printerList = res.data.api_v1_station
              this.printerCalled = true
              resolve(true)
            })
            .catch((err) => reject(false))
        }
      })
    },
    resetOutlet() {},

    reset() {
      this.mainCategoryPop = {}
      this.showDialog = false
      this.colorCodeShow = ''
    },

    resetsub() {
      this.subCategoryPop = {}
      this.showSubDialog = false
    },

    getMenuItem() {
      let self = this
      let id = this.$route.query.client
      let outlet_id = this.$route.query.outlet_id

      // axios
      //   .get(
      //     this.url + '?outlet_id=' + accountService.getOutletId(),
      //     customizeService.authFun()
      //   )
      //   .then((res) => {
      //     this.menuitems = res.data.menu_items
      //     this.isSingleCombo = this.menuitems.single_combo_id != null
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
      this.getTax()
      customizeService
        .getOutletMenuItem(id, outlet_id)
        .then((res) => {
          this.menuitems = res.data
          this.isSingleCombo = this.menuitems.single_combo_id != null
          this.query_param.menu_item_id = id
          this.query_param.flipdish_menu_id = this.menuitems.flipdish_menu_id
          this.query_param.flipdish_menu_section_id =
            this.menuitems.flipdish_menu_section_id
          this.query_param.flipdish_item_id = this.menuitems.flipdish_item_id
          this.query_param.take_out_price = this.menuitems.take_out_price
          this.query_param.is_flipdish = this.menuitems.is_flipdish
          this.flipDish = this.menuitems.is_flipdish
          this.isOnline = this.menuitems.is_online
          this.query_param.name = this.menuitems.name
          this.query_param.short_name = this.menuitems.short_name
          this.query_param.price = this.menuitems.price
          this.getPriceLevels()

          this.$refs.flipdishform.setFlipdish()
        })
        .catch((err) => console.log(err))
    },

    getPriceLevels() {
      customizeService
        .getPriceLevels()
        .then((res) => {
          this.priceLevels = res.data
          if (menuitems.price_levels) {
            this.priceLevels = this.menuitems.price_levels.map((x) =>
              Object.assign(
                x,
                this.priceLevels.find((y) => y.id == x.id)
              )
            )
          }
        })
        .catch((err) => console.log(err))
    },

    getCombos() {
      customizeService.getCombo(this.$route.query.outlet_id).then((res) => {
        this.combos = res.data
        this.getMenuItem()
      })
    },

    editMenuItem() {
      this.formType = 'Edit Menu Item'
    },
    getTax() {
      customizeService
        .getTaxes()
        .then((res) => {
          this.taxList = res.data.result[0].tax.taxes
        })
        .catch((err) => {
          console.log('Main Category not found')
        })
    },
  },

  created() {
    var self = this
    this.url = this.$route.query.client
    this.formType = this.$route.query.formType
    this.getCombos()
    this.printer()
    this.getModifierGroups()
  },

  watch: {},

  filters: {
    removeNullProps: function (el) {
      if (el != null && el != '') {
        return el
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.package-name {
  margin-top: 8%;
}

.text-center {
  justify-content: center !important;
}
.btn-add-module {
  text-align: center;
}
.arrow_left {
  float: left;
  padding-top: 31px;
}
.md-card .md-card-actions {
  border: none;
}
.file_Label {
  top: -0.8rem;
  font-size: 0.6875rem;
}
.set_menu {
  width: 90%;
  margin: auto;
}
.select_btn {
  margin-right: 10px;
}
i.md-icon.md-icon-font.md-theme-default {
  margin-top: -10px;
}
.close-button i.md-icon.md-icon-font.md-theme-default {
  margin-top: 17px;
}

.md-dialog {
  max-width: 768px;
}
.list-group {
  padding: 17px 0px;
  width: 50%;
}

.list-group-item {
  margin: 5px;
  padding: 8px;
  background-color: #2b82be;
  color: white;
  border-radius: 5px;
}
.md-dialog {
  width: 50%;
}
.card-content-align {
  padding: 0px 18px;
}
.dialog-top {
  padding-top: 15px;
  min-height: 490px;
  .main-actions {
    display: flex;
    justify-content: center;
    margin-top: 62px;
  }
  .main-lab1 {
    margin-top: 31px;
  }
}
.dialog-top-sub {
  padding-top: 15px;
  .main-actions-sub {
    display: flex;
    justify-content: center;
    margin-top: 29px;
    margin-bottom: 14px;
  }
}
.pd-top {
  padding-top: 15px;
}
.mainCat-field {
  padding-right: 10px;
}
.form__label {
  float: left;
}
.custm-color-picker {
  position: absolute;
  right: 41px;
  margin-top: 16px;
  top: 12px;
  font-size: 14px;
}
.color-picker-layout {
  margin-bottom: 13px;
  margin-top: 13px;
  .form__field {
    .form__input {
      width: 150px;
      position: relative;
      .vue-swatches {
        width: 85px !important;
        cursor: pointer;
        .vue-swatches__trigger {
          width: 68px !important;
        }
      }
      .md-icon {
        position: absolute;
        right: 9px;
        z-index: -1;
        top: 0px;
      }
    }
  }
}

.mod-click {
  cursor: pointer;
  padding-left: 0px !important;
}

.list-group {
  padding: 17px 10px;
  width: 100%;
}

.list-group-item {
  margin: 5px;
  padding: 8px;
  background-color: #2b82be;
  color: white;
  border-radius: 5px;
}

.plus-icon {
  height: 15px;
  width: 15px;
  margin: 0px 4px 4px 0px;
}

.modifier-add {
  display: flex;
}

.modifier-checkbox {
  padding: 0px;
  .md-list-item-content > .md-checkbox:first-child,
  .md-list-item-content > .md-radio:first-child {
    margin-right: 0px;
  }

  .md-list-item .md-list-item-container .md-ripple {
    display: flex;
  }
  overflow-y: scroll;
  height: 300px;
}

.btn-div {
  text-align: center;
}

.mod-click {
  cursor: pointer;
}

.modifier-dialog {
  padding: 15px;
  width: 40%;
  padding-right: none;
}

.close-icon {
  width: 50%;
  text-align: right;
  i {
    cursor: pointer;
  }
}

.portionData {
  width: 94%;
  display: table;
  margin: 10px auto;
  padding-top: 11px;
  padding-bottom: 23px;
  background-color: #eee;
  border-radius: 9px;
}

.modifierGpData {
  width: 94%;
  display: table;
  margin: 10px auto;
  padding-top: 11px;
  padding-bottom: 23px;
  background-color: #eee;
  border-radius: 9px;
}

.add-pop {
  margin-top: 8px !important;
}
.advanced {
  box-shadow: none;
}
.advanced-btn {
  background-color: white !important;
  color: black !important;
  box-shadow: none;
}
.advanced-btn .fa {
  color: black !important;
}

.md-dialog-container {
  left: unset !important;
}
</style>
