<template>
  <form class="md-layout" @submit.prevent="handleSubmit()">
    <md-card class="md-layout-item block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter pad-l-15px"
      >
        <h3 class="md-layout-item md-size-90">{{ $t('Payment Sense') }}</h3>
        <div class="md-layout-item md-size-10 text-right">
          <!-- <i class="md-icon md-icon-font md-theme-default edit-img">edit</i> -->
        </div>
      </md-card-header>
      <md-card-content v-bind:style="'margin:2rem 0;'">
        <div class="md-layout">
          <!-- <div class="md-layout-item layout-border line"></div> -->
          <!-- <form @submit.prevent="handleSubmit"> -->
          <div class="md-layout-item md-size-60 form-group">
            <md-field :class="{ 'md-error': errors.has('HostKey') }">
              <label class="">Payment Host Key</label>
              <md-input
                class="form-control"
                type="text"
                :class="{ 'is-invalid': submitted && errors.has('HostKey') }"
                id="HostKey"
                v-validate="'required'"
                v-model.lazy="pSense.hostKey"
                data-vv-name="HostKey"
                @blur="touched.hostKey = !touched.hostKey"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="submitted && errors.has('HostKey')">
                  {{ errors.first('HostKey') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="
                    !errors.has('HostKey') &&
                    pSense.hostKey != null &&
                    touched.hostKey
                  "
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>

          <div class="form-group md-layout-item md-size-60 mt-7">
            <md-field :class="{ 'md-error': errors.has('ApiKey') }">
              <label class="">Payment Api Key</label>
              <md-input
                class="form-control"
                type="text"
                :class="{ 'is-invalid': submitted && errors.has('ApiKey') }"
                id="ApiKey"
                v-validate="'required'"
                v-model.lazy="pSense.apiKey"
                data-vv-name="ApiKey"
                @blur="touched.apiKey = !touched.apiKey"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="submitted && errors.has('ApiKey')">
                  {{ errors.first('ApiKey') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="
                    !errors.has('ApiKey') &&
                    pSense.apiKey != null &&
                    touched.apiKey
                  "
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>

          <div class="form-group md-layout-item md-size-60 mt-7">
            <md-field>
              <label class="">Installer Id</label>
              <md-input
                class="form-control"
                type="text"
                id="ApiKey"
                v-model.lazy="pSense.installerId"
                data-vv-name="installerId"
              ></md-input>
            </md-field>
          </div>

          <!-- </form> -->
        </div>
        <div
          style="margin-top: 20px"
          class="btn-div md-layout-item md-size-60 md-alignment-center-right"
        >
          <md-button
            type="button"
            @click="handleSubmit()"
            style="float: right"
            class="md-teritiary"
            >Update</md-button
          >
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { Pagination } from '@/components'
import axios from 'axios'
import { SlideYDownTransition } from 'vue2-transitions'
import { required } from 'vuelidate/lib/validators'
import { accountService } from '../../../services/account.js'
import { customizeService } from './../../../services/customize.js'

export default {
  components: {
    Pagination,
    SlideYDownTransition,
  },
  data() {
    return {
      outlet_id: null,
      outletData: null,
      pSense: {
        hostKey: null,
        apiKey: null,
        installerId: null,
      },
      submitted: false,
      touched: {
        hostKey: false,
        apiKey: false,
      },
    }
  },
  beforeMount() {
    this.loadKeys()
  },
  methods: {
    loadKeys() {
      this.outlet_id = accountService.getOutletId()
      customizeService
        .getOutletById(this.outlet_id)
        .then((res) => {
          if (
            !res.data.outlet.pay_host_key == '' ||
            !res.data.outlet.pay_api_key == '' ||
            !res.data.outlet.paysense_installer_id == ''
          ) {
            this.pSense.hostKey = res.data.outlet.pay_host_key
            this.pSense.apiKey = res.data.outlet.pay_api_key
            this.pSense.installerId = res.data.outlet.paysense_installer_id
          }
        })
        .catch((error) => console.log('error>>>', error))
    },
    PostKeys() {
      axios
        .get(`https://${this.pSense.hostKey}/pac/terminals`, {
          headers: {
            Authorization: 'Basic ' + btoa('SR804R13:' + this.pSense.apiKey),
          },
        })
        .then((res) => {
          this.saveData()
        })
        .catch((error) => {
          console.log(error)
          alert('invalid details')
        })
    },

    handleSubmit() {
      this.submitted = true
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.PostKeys()
        }
      })
    },
    saveData() {
      let outlet_id = accountService.getOutletId()
      customizeService.editOutlet(outlet_id, {
        outlets: {
          outlet: {
            pay_host_key: this.pSense.hostKey,
            pay_api_key: this.pSense.apiKey,
            paysense_installer_id: this.pSense.installerId,
          },
        },
      })
    },
  },
}
</script>

<style lang="css" scoped>
.title-main {
  margin-left: 2rem;
}

.sub-group {
  margin-top: 25px;
}

.md-dense {
  float: right;
  margin-top: 2rem;
}
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.error {
  color: red;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}

.text-right {
  text-align: right;
}
</style>
