<template>
  <div class="datetime-comp">
    <div class="md-layout pd-l-0">
      <div class="md-layout-item pd-r-0 pd-l-0 pd-r-10">
        <label class="text-nowrap">From Date</label>
        <datetime
          class="datetime-custm"
          format="YYYY-MM-DD"
          v-model="date"
        ></datetime>
        <i
          class="md-icon md-icon-font md-icon-image md-theme-default custom-icon"
        >
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 10l5 5 5-5z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import datetime from 'vuejs-datetimepicker'

export default {
  name: 'single-date-picker',
  components: { datetime },
  data() {
    return {
      date: '',
      initialDate: 0,
    }
  },
  methods: {},
  watch: {
    date() {
      this.$emit('emitDate', this.date)
    },
  },

  created() {},
}
</script>
<style scoped>
.md-layout-row {
  width: 100%;
  display: flex;
}
.md-size-row {
  width: 50%;
  margin-right: 15px;
}
.pd-l-0 {
  padding-left: 0px;
  position: relative;
}
.custom-icon {
  position: absolute;
  top: 20px;
  right: 0px;
}

.datetime-picker {
  margin: 0px;
  padding: 0px !important;
}

input {
  min-width: 100% !important;
  height: 22px !important;
}

label {
  font-size: 11px !important;
  color: #8d8d8e !important;
}
</style>
