<template>
  <div>
      <md-card class="itemised-trans block-bg">
        <md-card-header class="md-card-header-text md-card-header-green md-layout md-gutter">
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <router-link :to="{ path: '/dashboard' }">
                <img src="../../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h3>{{ $t('CBE Payout Reconciliation Report') }}</h3>
          </div>
        </md-card-header>
        <md-card-content>
            <div class="md-layout filter-section block-bg">
              <div class="md-layout search-options">
                <div class="md-layout-item">
                <date-period-dropdown @interval="setDateType"></date-period-dropdown>
              </div>
              <div class="md-layout-item md-size-30" v-if="userRole == 'branch_user'">
              <md-field style="margin-top:20px;">
                <label for="Outlets">Outlets</label>
                <md-select
                    v-model="queries.outletId"
                    multiple 
                    @md-closed="
                    queries.outletId.length > 0
                      ? getItems()
                      : (queries.outletId = [])"
                    >
                  <md-option
                    v-for="item in outlets"
                    :key="item.id"
                    :value="item.id"
                    class="oulet-select-list-item ">{{ item.name }}</md-option>
                </md-select>
              </md-field>
            </div>

              <div class="md-layout-item md-size-10">
                  <md-button class="md-teritiary" @click="getItems()">Search</md-button>
              </div>
            </div>
            </div>
            <div class="md-layout-item md-size-100 card-inside report-table payout-report">
              <div class="loader-container" v-if="loader">
                <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
              </div>

              <div class="md-layout-item md-size-100 file-icon-holder" v-if="!loader">
                <h4 class="center" v-if="!loader">
                  CBE Payout Reconciliation - {{ sdate }} to {{ edate }}
                </h4>
                <!-- <div
                  class="md-layout justify-space d-flex justify-content-center"
                >
                  <div
                    class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-15"
                  >
                    <stats-card header-color="blue">
                      <template slot="header">
                        <div class="card-icon pb-0">
                          <md-icon>work_history</md-icon>
                        </div>
                        <p class="category">Pending Balance</p>
                        <h3 class="title font-20px">
                          {{ $store.state.account.outlet.currency_type }}
                          {{ parseFloat(pendingBalancesSum / 100).toFixed(2) }}
                        </h3>
                      </template>
                    </stats-card>
                  </div>
                  <div
                    class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-15"
                  >
                    <stats-card header-color="rose">
                      <template slot="header">
                        <div class="card-icon pb-0">
                          <md-icon>wallet</md-icon>
                        </div>
                        <p class="category">Current Balance</p>
                        <h3 class="title font-20px">
                          {{ $store.state.account.outlet.currency_type }}
                          {{ parseFloat(balancesSum / 100).toFixed(2) }}
                        </h3>
                      </template>
                    </stats-card>
                  </div>
                  <div
                    class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-15"
                  >
                    <stats-card header-color="green">
                      <template slot="header">
                        <div class="card-icon pb-0">
                          <md-icon>payments</md-icon>
                        </div>
                        <p class="category">Last Payout</p>
                        <h3 class="title font-20px">
                          {{ $store.state.account.outlet.currency_type }}
                          {{ parseFloat(lastPayout / 100).toFixed(2) }}
                        </h3>
                      </template>

                      <template slot="footer">
                        <div class="footer-update">
                          <div class="stats font-weight-bold">
                            <md-icon>update</md-icon>
                            {{ lastPayoutDate }}
                          </div>
                        </div>
                      </template>
                    </stats-card>
                  </div>
                </div> -->
                <div class="action-buttons">
                  <div class="pdf-el">
                    <div class="download-btn">
                      <img
                        src="../../../../assets/images/pdf.svg"
                        @click="downloadWithoutCSS()"
                      />
                    </div>
                  </div>

                  <div class="excel-el">
                    <vue-excel-xlsx
                      class="Excel-btn"
                      :data="report"
                      :columns="columns"
                      :filename="'CBE Payout Reconciliation'"
                      :sheetname="'CBE Payout Reconciliation'"
                      :outlet-names="selectedOutletsNames()"
                      :sdate="sdate"
                      :edate="edate"
                    >
                    </vue-excel-xlsx>
                  </div>
                </div>
              </div>
              <div class="pad-l-15px pad-r-15px">
                <md-table
                  :value="queriedData"
                  :md-sort.sync="currentSort"
                  :md-sort-order.sync="currentSortOrder"
                  class="paginated-table table-striped table-hover accounts-table"
                  v-if="!loader"
                >
                  <md-table-toolbar class="table-toolbar-align">
                    <md-field>
                      <label>{{ $t('Search') }}</label>
                      <md-input
                        type="search"
                        class="mb-3"
                        clearablef7
                        style=""
                        v-model="searchQuery"
                      ></md-input>
                      <i class="fa fa-search search-align" aria-hidden="true"></i>
                    </md-field>
                  </md-table-toolbar>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell
                      v-bind:md-label="$t('Transaction Date')"
                      md-sort-by="date"
                    >
                      <div style="text-align: center;">
                        {{ new_formatDate(item.date) }}
                      </div>
                    </md-table-cell>

                    <md-table-cell
                      md-numeric
                      v-bind:md-label="$t('CBE PAY Sales')"
                    >
                      <div style="text-align: right;">
                        {{ parseFloat(item.salesAmount).toFixed(2) }}
                      </div>
                    </md-table-cell>
                    <md-table-cell v-bind:md-label="$t('Splits')" md-numeric>
                      <div>
                        {{ parseFloat(item.splitsAmount).toFixed(2) }}
                      </div>
                    </md-table-cell>

                    <md-table-cell v-bind:md-label="$t('EOD Balance')" md-numeric>
                      <div>
                        {{ parseFloat(item.eodBalanceAmount).toFixed(2) }}
                      </div>
                    </md-table-cell>
                    <md-table-cell v-bind:md-label="$t('Payout')" md-numeric>
                      <div>
                        {{ parseFloat(item.payoutAmount).toFixed(2) }}
                      </div>
                    </md-table-cell>

                    <md-table-cell md-numeric v-bind:md-label="$t('Balance Carried Forward')">
                      <div style="text-align: right;">
                        {{ parseFloat(item.balanceCarriedForward).toFixed(2) }}
                      </div>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <!-- <div class="md-layout">
              <div class="md-layout-item layout-border">
                <div class="pagination-main">
                  <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

                  <div class="pagenation-number">
                    <md-field>
                      <md-select v-model="pagination.perPage" name="pages">
                        <md-option
                          v-for="item in pagination.perPageOptions"
                          :key="item"
                          :label="item"
                          :value="item"
                          >{{ item }}</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                  <div class="pagination-warp">
                    <p class="card-category pagination-text-align">
                      {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                    </p>
                    <pagination
                      class="pagination-no-border pagination-success"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="total"
                    ></pagination>
                  </div>
                </div>
              </div>
            </div> -->
        <!-- </md-card-actions> -->
      </md-card>
    </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import { customizeService } from '../../../../services/customize.js'
import { accountService } from '../../../../services/account.js'
import { reusableService } from '../../../../services/reusable.js'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import { cbePayService } from '../../../../services/cbePay.js'
import moment from 'moment'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf';
import {
  StatsCard,
  ChartCard,
  ProductCard,
  AnimatedNumber,
  GlobalSalesCard,
  GlobalSalesTable,
} from '@/components'

export default {
  components: {
    ClipLoader,
    Pagination,
    DatePeriodDropdown,
    VueExcelXlsx,
    StatsCard,
    AnimatedNumber,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length
    },
    totals() {
      let totals = {}
      totals.amount = this.queriedData.reduce((prev, next) => {
        return prev + next.amount
      }, 0)
      totals.sellerSplit = this.queriedData.reduce((prev, next) => {
        return prev + next.sellerSplit
      }, 0)
      return totals
    },
  },
  data() {
    return {
      data: {
        sdate: '',
        search_type: 'Daily',
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      currentSort: 'createdAt',
      currentSortOrder: 'desc',
      searchQuery: '',
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
      activeStatus: {
        index: '',
        status: false,
      },
      sdate: null,
      edate: null,
      outletList: [],
      report: [],
      balancesSum: [],
      pendingBalancesSum: [],
      lastPayout: 0,
      lastPayoutDate: '',
      queries: {
        outletId: [],
        search_type: 'Daily',
        chainid:0,
      },
      outlets: [],
      outletsList: [],
      columns: [
        {
          label: 'Txn Date',
          field: 'date',
        },
        {
          label: 'CBE PAY Sales',
          field: 'salesAmount',
        },
        {
          label: 'Splits',
          field: 'splitsAmount',
        },
        {
          label: 'EOD Balance',
          field: 'eodBalanceAmount',
        },
        {
          label: 'Payout',
          field: 'payoutAmount',
        },
        {
          label: 'Balance Carried Forward',
          field: 'balanceCarriedForward',
        },
      ],
      columnStyles: {
        0: {
          cellWidth: 40,
        },
        1: {
          cellWidth: 45,
        },
        2: {
          cellWidth: 45,
        },
        3: {
          cellWidth: 45,
        },
        4: {
          cellWidth: 40,
        },
        5: {
          cellWidth: 70,
        },
      },
    }
  },

  methods: {

    new_formatDate(date) {
      return moment(date).format('DD-MMM-YYYY')
    },
    fromDateTime(date) {
      this.queries.startDate = date
    },

    toDateTime(date) {
      this.queries.endDate = date
    },
    setDateType(interval) {
      this.queries.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.queries.startDate = interval.startDate
        this.queries.endDate = interval.endDate
      } else {
        this.queries.startDate = interval.date
        this.queries.endDate = undefined
        if (!this.loader) {
          this.getItems()
        }
      }
    },
    toggleRow(index) {
      this.activeStatus.index = index
      this.activeStatus.status = !this.activeStatus.status
    },
    async getItems() {
      this.loader = true
      if (accountService.userRole() == 'branch_user') {
        this.queries.chainid=accountService.getChainId();
            await cbePayService.getPayoutReconciliationHeadoffice(this.queries).then((res) => {
              this.loader = false
              if(res){
                this.sdate = res.data.sdate
                this.edate = res.data.edate
                this.tableData = res.data.result
                this.lastPayout = res.data.lastPayout
                this.lastPayoutDate = res.data.lastPayoutDate
                this.report = JSON.parse(JSON.stringify(res.data.result))
                if (res.data.result) {
                  this.report.forEach((el) => {
                    el.date = moment(el.date).format('DD/MM/YYYY')
                    el.eventDate = moment(el.eventDate).format('DD/MM/YYYY HH:MM')
                    el.amount = parseFloat(el.amount / 100).toFixed(2)
                    el.sellerSplit = parseFloat(el.sellerSplit / 100).toFixed(2)
                    el.valueDate = el.valueDate ? moment(el.valueDate).format('DD/MM/YYYY') : ''})
                }
                if (res.data.balances) {
                  this.balancesSum = res.data.balances.reduce((n, { available }) => n + available,0)
                  this.pendingBalancesSum = res.data.balances.reduce((n, { pending }) => n + pending,0)
                }
                }
            })
          } else {
            await cbePayService.getPayoutsReconciliation(this.queries).then((res) => {
              this.loader = false
              if(res){
                this.sdate = res.data.sdate
                this.edate = res.data.edate
                this.tableData = res.data.result
                this.lastPayout = res.data.lastPayout
                this.lastPayoutDate = res.data.lastPayoutDate
                this.report = JSON.parse(JSON.stringify(res.data.result))
                if (res.data.result) {
                  this.report.forEach((el) => {
                    el.date = moment(el.date).format('DD/MM/YYYY')
                    el.eventDate = moment(el.eventDate).format('DD/MM/YYYY HH:MM')
                    el.amount = parseFloat(el.amount / 100).toFixed(2)
                    el.sellerSplit = parseFloat(el.sellerSplit / 100).toFixed(2)
                    el.valueDate = el.valueDate? moment(el.valueDate).format('DD/MM/YYYY') : ''})
                  }
                if (res.data.balances) {
                  this.balancesSum = res.data.balances.reduce((n, { available }) => n + available,0)
                  this.pendingBalancesSum = res.data.balances.reduce((n, { pending }) => n + pending,0)
                }
              }
            })
          
          }
    },

    viewPromoDetail(item) {
      this.$router.push({
        name: 'AddPromotions',
        query: {
          formType: 'Edit Promotion',
          client: item.id,
        },
      })
    },

    alert(value, id) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.deletePromotion(id)
        }
      })
    },

    deletePromotion(id) {
      customizeService
        .deletePromotion(id)
        .then((res) => {
          console.log('response get', res)
          this.getItems()
        })
        .catch((err) => console.log(err))
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.queries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },

    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = [
          el.date,
          el.salesAmount.toFixed(2),
          el.splitsAmount.toFixed(2),
          el.eodBalanceAmount.toFixed(2),
          el.payoutAmount.toFixed(2),
          el.balanceCarriedForward.toFixed(2),
        ]
        body.push(arrayObj)
      })
      let totObj = ['', '', '', '', '', '']
      body.push(totObj)
      return body
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let dateText = ''
      let head = [
        [
          'Txn Date',
          'Sales Amount',
          'Splits',
          'EOD Balance',
          'Payout',
          'Balance Carried Forward',
        ],
      ]

      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      let selectedOutlets = this.selectedOutletsNames()
      pdfService.headerFooterConfig(
        this.queries,doc,'CBE PAY Payout Reconciliation',selectedOutlets)
      doc.setFontSize(8)
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        this.columnStyles,
        'cbepay-payout-reconciliation.pdf'
      )
    },
  
  },

  async created() {
    this.userRole = accountService.userRole();
  },

  async mounted() {
    if (accountService.userRole() == 'branch_user') {
      await customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => { return outlet.id})
      })
    }
    if (this.$route.query.s && this.$route.query.e) {
        this.queries.search_type = 'Ad Hoc'
        this.queries.startDate = this.$route.query.s
        this.queries.endDate = this.$route.query.e
      }
    await this.getItems();
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['pspRef', 'merchantRef'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>

<style>
.headerDiv {
  display: flex;
}

.add-icon {
  margin-left: 5px !important;
  margin-top: 16px;
  margin-right: 5px;
}

.pagination-text-align {
  float: left;
  padding-top: 3px;
}

.footer-align {
  display: flex;
  float: right;
}

.pagenation-number {
  max-width: 46px;
}

.pagination-warp {
  padding-left: 30px;
}

.pagination-main {
  display: flex;
  float: right;
}

.center {
  text-align: center !important;
}

.head {
  margin-top: 16px !important;
}

.paginated-table .md-table-head-label {
  padding-right: 0px !important;
}

.module-table .md-table-head .md-table-head-container {
  text-align: center !important;
}

.rotate {
  transition: transform 0.3s;
  transform: rotate(180deg);
}

.rotate-btn i {
  color: #585555 !important;
  font-size: 30px !important;
}

.rotate-btn {
  border: none;
  background-color: transparent;
}

.file-icon-holder {
  position: relative;
}

.action-buttons {
  width: 58px !important;
  padding-bottom: 20px;
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: right;
  margin-right: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  top: -25px;
}

.md-table-head:last-child .md-table-head-label {
  padding-right: 25px !important;
}

.balance {
  display: flex;
  padding: 5px;
  max-width: 550px !important;
  margin: auto;
  border: 2px solid #fff;
}

.balance-item-1 {
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-weight: 600;
  padding-left: 0;
  text-align: left;
}

.balance-item-2 {
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-weight: 600;
  text-align: right;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.md-card .md-card-actions {
  margin: 0 !important;
}

.md-card-stats .md-card-header.md-card-header-icon .card-icon i {
  font-size: 28px !important;
}

.md-card-stats {
  height: 110px !important;
}

.font-20px {
  font-size: 20px !important;
}

.payout-report .md-card-actions.md-alignment-left {
  justify-content: flex-end !important;
}

.payout-report .md-card .md-card-actions .stats {
  font-size: 14px !important;
}
</style>
