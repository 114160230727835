<template>
  <div>
    <div class="loader-container" v-if="loader">
      <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
    </div>
    <div class="md-layout" v-if="!loader">
      <div class="md-layout-item md-size-50">
        <md-field
          :class="[
            { 'md-error': errors.has('menu.Title') },
            { 'md-valid': !errors.has('menu.Title') && touched.Title },
          ]"
        >
          <label>{{ $t('FlipDish Item Title') }}*</label>
          <md-input
            type="text"
            v-model="flipdish.data.Name"
            data-vv-name="Title"
            v-validate="modelValidations.Title"
            :disabled="formType === 'View Menu Item'"
          ></md-input>
          <slide-y-down-transition>
            <div class="error" v-show="errors.has('Title')">
              {{ errors.first('Title') }}
            </div>
          </slide-y-down-transition>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50">
        <md-field
          :class="[
            { 'md-error': errors.has('menu.menu_section_id') },
            {
              'md-valid':
                !errors.has('menu.menu_section_id') && touched.menu_section_id,
            },
          ]"
        >
          <label>{{ $t('Flipdish Menu Selection') }}</label>
          <md-select
            v-model="flipdish.query_param.flipdish_menu_section_id"
            data-vv-name="menu_section_id"
            v-validate="modelValidations.flipdish_menu_section_id"
            :disabled="formType === 'View Menu Item'"
            @click="getFlipdishMenuSection()"
          >
            <md-option
              :value="item.MenuSectionId"
              v-for="item in flipdishMenuSection"
              :key="item.MenuSectionId"
              >{{ item.Name }}</md-option
            >
          </md-select>
          <slide-y-down-transition>
            <div class="error" v-show="errors.has('menu_section_id')">
              {{ errors.first('menu_section_id') }}
            </div>
          </slide-y-down-transition>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50">
        <md-field
          :class="[
            { 'md-error': errors.has('menu.Name') },
            { 'md-valid': !errors.has('menu.Name') && touched.Name },
          ]"
        >
          <label>{{ $t('Description') }}*</label>
          <md-input
            type="text"
            v-model="flipdish.data.Description"
            data-vv-name="Description"
            :disabled="formType === 'View Menu Item'"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('menu.Name') },
                { 'md-valid': !errors.has('menu.Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Price') }}*</label>
              <md-input
                type="text"
                v-model="flipdish.data.Price"
                data-vv-name="Price"
                v-validate="modelValidations.Price"
                :disabled="formType === 'View Menu Item'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Price')">
                  {{ errors.first('Price') }}
                </div>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('menu.DepositReturnFee') },
                {
                  'md-valid':
                    !errors.has('menu.DepositReturnFee') && touched.Name,
                },
              ]"
            >
              <label>{{ $t('Deposit Return Fee') }}</label>
              <md-input
                type="text"
                v-model="flipdish.data.DepositReturnFee"
                data-vv-name="Price"
                v-validate="modelValidations.DepositReturnFee"
                :disabled="true"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('DepositReturnFee')">
                  {{ errors.first('DepositReturnFee') }}
                </div>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
      </div>

      <div class="md-layout-item md-size-50">
        <md-field>
          <label for="status">{{ $t('Is Available') }}</label>
          <md-select
            v-model="flipdish.data.IsAvailable"
            name="status"
            :disabled="formType == 'View Combo'"
          >
            <md-option class="first-opt" disabled>IsAvailable</md-option>
            <md-option :value="'true'">{{ $t('Yes') }}</md-option>
            <md-option :value="'false'">{{ $t('No') }}</md-option>
          </md-select>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50">
        <input class="custom-file-input" type="file" @change="onFileChanged" />
        <img
          class="flip-image"
          v-if="flipdish.upload != null"
          :src="flipdish.upload"
        />
      </div>

      <!-- <div class="btn-div md-layout-item md-size-100">
        <md-button native-type="submit" :to="{ path: '/products/MenuItem' }">{{
          $t('CANCEL')
        }}</md-button>
        <md-button
          native-type="submit"
          @click.native.prevent="validate()"
          class="md-primary"
          >{{ $t('SAVE') }}</md-button
        >
      </div> -->
    </div>
  </div>
</template>
<script>
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import { SlideYDownTransition } from 'vue2-transitions'
import axios from 'axios'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  name: 'flipdishForm',
  props: [
    'flipdishProp',
    'flipTitle',
    'flipDescription',
    'flipPrice',
    'flipDrs',
  ],

  components: {
    ClipLoader,
    SlideYDownTransition,
  },

  data() {
    return {
      menu_id: '',
      section_id: '',
      touched: {
        menu_id: false,
        menu_section_id: false,
        Title: false,
        DepositReturnFee: false,
      },
      modelValidations: {
        menu_id: {
          required: true,
        },
        menu_section_id: {
          required: true,
        },
        Title: {
          required: true,
        },
        Price: {
          required: true,
        },
        DepositReturnFee: {
          required: false,
        },
      },
      formType: {
        type: String,
        default: 'Add Outlet',
      },
      outletId: accountService.getOutletId(),

      colors: ['green', 'red', 'blue', 'grey', 'yellow', 'violet'],

      flipdish: {
        query_param: {
          flipdish_menu_id: '',
          flipdish_menu_section_id: '',
          menu_item_id: '',
          is_flipdish: '',
        },
        data: {
          Name: '',
          Description: '',
          Price: '',
          DepositReturnFee: '',
          IsAvailable: 'true',
        },
        upload: '',
      },

      flipdishMenus: [],
      flipdishMenusCalled: false,
      flipdishMenuSection: [],
      flipdishMenuSectionCalled: false,
      flipdishParam: {},
      loader: false,
    }
  },

  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), true)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            if (value == 'Add') {
              self.createIntegration()
            }
          }
        })
      } else if (value === 'Update') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            this.$router.push({
              name: 'MenuItem',
            })
          }
        })
      }
    },

    createFlipdishMenu() {
      let formData = new FormData()
      for (let key in this.flipdish.data) {
        if (this.flipdish.data.hasOwnProperty(key)) {
          let val = this.flipdish.data[key]
          formData.append('flipdish[data[' + key + ']]', val)
        }
      }

      for (let key in this.flipdish.query_param) {
        if (this.flipdish.query_param.hasOwnProperty(key)) {
          let val = this.flipdish.query_param[key]
          formData.append('flipdish[query_param[' + key + ']]', val)
        }
      }

      if (this.flipdish.upload != '' && this.flipdish.upload != null) {
        formData.append('flipdish[upload[File]]', this.flipdish.upload)
      } else {
        delete this.flipdish.upload
      }
      for (var pair of formData.entries()) {
      }

      customizeService
        .createFlipDish(formData, this.outletId)
        .then((res) => {})
        .catch((err) => console.log('Flipdish update error', err))
    },
    onFileChanged(event) {
      this.flipdish.upload = event.target.files[0]
    },

    getFlipdishMenuSection() {
      if (this.flipdishMenuSectionCalled === false) {
        customizeService
          .getFlipdishMenuSection(this.outletId)
          .then((res) => {
            // this.flipdishMenuSection = res.data.Data
            this.flipdishMenuSection = res.data.Data.filter(
              (el) => el.IsAvailable == true
            )
            this.flipdishMenuSectionCalled = true
            this.loader = false
          })
          .catch((err) => {
            console.log('Main Category not found')
          })
      }
    },

    getFlipDishById() {
      customizeService
        .getFlipDishById(
          this.flipdishParam.flipdish_menu_id,
          this.flipdishParam.flipdish_menu_section_id,
          this.flipdishParam.flipdish_item_id,
          this.outletId
        )
        .then((res) => {
          this.flipdish.data.Name = res.data.Data.Name
          this.flipdish.upload = res.data.Data.ImageUrl
          this.flipdish.data.Description = res.data.Data.Description
          this.flipdish.data.IsAvailable = res.data.Data.IsAvailable
          this.flipdish.data.Price = res.data.Data.Price
          this.flipdish.data.DepositReturnFee = res.data.Data.DepositReturnFee

          var MenuItemOptionSets = res.data.Data.MenuItemOptionSets.map(
            function (elem) {
              return elem.MenuItemOptionSetId
            }
          )
          this.flipdish.query_param.menu_modifiers = MenuItemOptionSets
        })
    },
    setFlipdish() {
      if (this.flipdishParam && this.flipdishParam.is_flipdish) {
        this.getFlipdishMenuSection()
        this.getFlipDishById()
      } else {
        this.loader = false
        this.flipdishParam && this.flipdishParam.take_out_price != null
          ? (this.flipdish.data.Price = this.flipdishParam.take_out_price)
          : (this.flipdish.data.Price = this.flipdishParam.price)
        this.flipdish.data.Name = this.flipdishParam.name
        this.flipdish.data.Description = this.flipdishParam.short_name
      }
      this.flipdish.query_param.menu_item_id = this.flipdishParam.menu_item_id
      this.flipdish.query_param.flipdish_menu_id =
        this.flipdishParam.flipdish_menu_id
      this.flipdish.query_param.flipdish_menu_section_id =
        this.flipdishParam.flipdish_menu_section_id
      this.flipdish.query_param.is_flipdish = this.flipdishParam.is_flipdish
      this.flipdish.query_param.menu_modifiers = []
      this.flipdish.query_param.single_combo_id = ''
    },
  },

  created() {
    this.loader = true
    let outletParamId = this.$route.query.outlet_id
    this.outletId = outletParamId ? outletParamId : accountService.getOutletId()
    this.flipdishParam = this.flipdishProp
  },

  computed: {},
  watch: {
    flipTitle: {
      immediate: true,
      handler(val, oldVal) {
        this.flipdish.data.Name = val
      },
    },

    flipDescription: {
      immediate: true,
      handler(val, oldVal) {
        this.flipdish.data.Description = val
      },
    },

    flipPrice: {
      immediate: true,
      handler(val, oldVal) {
        this.flipdish.data.Price = val
      },
    },

    flipDrs: {
      immediate: true,
      handler(val, oldVal) {
        this.flipdish.data.DepositReturnFee = val
      },
    },

    flipdishProp(newVal) {
      console.log('Flip new params', newVal)
      this.flipdishParam = newVal
    },

    MenuId() {
      this.touched.required = true
    },
  },

  filters: {
    removeNullProps: function (el) {
      if (el != null && el != '') {
        return el
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.flip-image {
  width: 120px;
  padding: 5px;
}

.btn-div {
  text-align: center;
}
</style>
