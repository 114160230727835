var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"info-text"},[_vm._v(" Let's start with the basic information (with validation) ")]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-40 md-small-size-100"},[_c('div',{staticClass:"picture-container"},[_c('div',{staticClass:"picture"},[(!_vm.image)?_c('div',[_c('img',{staticClass:"picture-src",attrs:{"src":_vm.avatar,"title":""}})]):_c('div',[_c('img',{attrs:{"src":_vm.image}})]),_c('input',{attrs:{"type":"file"},on:{"change":_vm.onFileChange}})]),_c('h6',{staticClass:"description"},[_vm._v("Choose Picture")])])]),_c('div',{staticClass:"md-layout-item md-size-60 mt-4 md-small-size-100"},[_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('firstName') && _vm.touched.firstName },
          { 'md-form-group': true },
          { 'md-error': _vm.errors.has('firstName') } ]},[_c('md-icon',[_vm._v("face")]),_c('label',[_vm._v("First Name")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.firstName),expression:"modelValidations.firstName"}],attrs:{"data-vv-name":"firstName","type":"text","name":"firstName","required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('firstName')),expression:"errors.has('firstName')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('firstName') && _vm.touched.firstName),expression:"!errors.has('firstName') && touched.firstName"}],staticClass:"success"},[_vm._v("done")])],1)],1),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('lastName') && _vm.touched.lastName },
          { 'md-form-group': true },
          { 'md-error': _vm.errors.has('lastName') } ]},[_c('md-icon',[_vm._v("record_voice_over")]),_c('label',[_vm._v("Last Name")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.lastName),expression:"modelValidations.lastName"}],attrs:{"data-vv-name":"lastName","type":"text","name":"lastName","required":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('lastName')),expression:"errors.has('lastName')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('lastName') && _vm.touched.lastName),expression:"!errors.has('lastName') && touched.lastName"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-size-95 ml-auto mt-4 md-small-size-100"},[_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('email') && _vm.touched.email },
          { 'md-form-group': true },
          { 'md-error': _vm.errors.has('email') } ]},[_c('md-icon',[_vm._v("email")]),_c('label',[_vm._v("Email")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.email),expression:"modelValidations.email"}],attrs:{"data-vv-name":"email","type":"text","name":"email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('email')),expression:"errors.has('email')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('email') && _vm.touched.email),expression:"!errors.has('email') && touched.email"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }