<template>
  <div class="topDiv">
    <md-card>
      <div class="subscription">
        <div>
          <div class="logo-img-block">
            <img
              class="logo-img-login"
              src="../../../assets/images/cbe_innova_logo.png"
            />
          </div>
          <!-- <img class="subscribed_img" src="../../../assets/images/done.svg" /> -->

          <!-- Checkmark img -->
          <div class="success-animation">
            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
        </div>
        <div class="content">You are successfully subscribed</div>
        <router-link :to="{ path: '/dashboard' }">
          <md-button class="md-success md-round md-primary mt-25"
            >Back to Home</md-button
          >
        </router-link>
      </div>
    </md-card>
  </div>
</template>
<script>
export default {
  methods: {},
  created() {},
}
</script>
<style scoped>
.subscription {
  text-align: center;
}

.subscribed_img {
  width: 100px;
  height: 100px;
}

.topDiv {
  display: flex;
  justify-content: center;
  padding-left: 0px !important;
}

.md-card {
  height: 800px;
  display: flex;
  justify-content: center;
}

.content {
  font-size: 22px;
}

/* checkmark Style */

.success-animation {
  margin: 30px auto;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #1f7dbb;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #1f7dbb;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #1f7dbb;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
</style>
