<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header
          class="md-card-header-text md-card-header-green md-layout md-gutter"
        >
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <router-link :to="{ path: '/users' }">
                <img src="../assets/images/left.svg" />
              </router-link>
            </span>
            <h3>{{ 'Edit User' }}</h3>
          </div>
        </md-card-header>
        <div class="card-content-align">
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('User Name') }}</label>
                  <md-input
                    type="text"
                    v-model="user.user_name"
                    disabled
                  ></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Backend End Point') }}</label>
                  <md-input
                    type="text"
                    v-model="user.backend_end_point"
                  ></md-input>
                </md-field>
              </div>

              <div class="btn-div">
                <md-card-actions class="text-center">
                  <md-button
                    native-type="submit"
                    :to="{
                      path: '/users',
                    }"
                    >{{ $t('CANCEL') }}</md-button
                  >
                  <md-button
                    native-type="submit"
                    class="md-primary"
                    @click="submitUser()"
                    >{{ $t('SAVE') }}</md-button
                  >
                </md-card-actions>
              </div>
            </div>
          </md-card-content>
        </div>
      </md-card>
    </div>
  </div>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { Promise } from 'q'
import { customizeService } from '../services/customize.js'
import { reusableService } from '../services/reusable.js'
import { accountService } from '../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },

  data() {
    return {
      user: {},
    }
  },
  methods: {
    submitUser() {
      let data = {
        user: this.user,
      }
      customizeService.updateUser(this.userId, data).then((res) => {
        this.$router.push({
          path: '/users',
        })
      })
    },

    // editUser() {
    //   this.formType = 'Edit User'
    // },
  },
  created() {
    this.userId = this.$route.query.client

    customizeService.getUserById(this.userId).then((res) => {
      this.user = res.data
    })
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.package-name {
  margin-top: 8%;
}

.text-center {
  justify-content: center !important;
}

.btn-add-module {
  text-align: center;
}

.arrow_left {
  float: left;
  padding-top: 21px;
}

.md-card .md-card-actions {
  border: none;
}

.file_Label {
  margin-bottom: 10px;
}

.edit-img {
  padding: 32px;
  float: right;
}

.card-content-align {
  padding: 0px 18px;
}

.md-card img {
  width: 19px;
}

.user-details {
  padding-bottom: 15px;
}

.upload-field {
  .custom-file-input {
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    padding-left: 60px;
    position: relative;
    height: 30px;
    padding-top: 6px;
    color: #3c4858;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Upload Logo';
    color: black;
    display: inline-block;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
    position: absolute;
    left: 0px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 12px;
    padding: 7px 12px;
    top: -2px;
    color: #3c4858;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
}
.md-card {
  .uploaded-img {
    width: 250px;
  }
}
.add-hours {
  display: none !important;
}
</style>
