<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <signup-card>
        <h2 class="title text-center" slot="title">Register</h2>
        <div
          class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 ml-auto"
          slot="content-left"
        >
          <div
            class="info info-horizontal"
            v-for="item in contentLeft"
            :key="item.title"
          >
            <div :class="`icon ${item.colorIcon}`">
              <md-icon>{{ item.icon }}</md-icon>
            </div>
            <div class="description">
              <h4 class="info-title">{{ item.title }}</h4>
              <p class="description">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto"
          slot="content-right"
        >
          <div class="card-content-align">
            <md-card-content>
              <div>
                <div class="md-layout">
                  <div class="left-padding md-layout-item md-size-50">
                    <md-field>
                      <label>{{ $t('Outlet Name') }}</label>
                      <md-input
                        type="text"
                        v-model="outlets.outlet.name"
                        data-vv-name="OutletName"
                        v-validate="modelValidations.OutletName"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div>
                  <!-- <md-field>
                <label>{{$t('Outlet Location')}}</label>
                <md-input type="text" :disabled = "formType == 'View Outlet'" ></md-input>
                </md-field> -->
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>{{ $t('Website') }}</label>
                      <md-input
                        type="text"
                        v-model="outlets.outlet.website"
                        data-vv-name="Website"
                        v-validate="modelValidations.Website"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('Website')">
                          {{ errors.first('Website') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div>
                </div>

                <!-- Address Block  --->
                <div class="md-layout">
                  <div class="left-padding md-layout-item md-size-50">
                    <md-field>
                      <label>{{ $t('Address 1') }}</label>
                      <md-input
                        type="text"
                        v-model="outlets.outlet.address1"
                        data-vv-name="OutletName"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>{{ $t('Address 2') }}</label>
                      <md-input
                        type="text"
                        v-model="outlets.outlet.address2"
                        data-vv-name="OutletName"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout">
                  <div class="left-padding md-layout-item md-size-50">
                    <md-field>
                      <label>{{ $t('Address 3') }}</label>
                      <md-input
                        type="text"
                        v-model="outlets.outlet.address3"
                        data-vv-name="OutletName"
                      ></md-input>
                      <slide-y-down-transition>
                        <div class="error" v-show="errors.has('OutletName')">
                          {{ errors.first('OutletName') }}
                        </div>
                      </slide-y-down-transition>
                    </md-field>
                  </div>
                </div>
              </div>
            </md-card-content>

            <div class="md-layout user-details">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('First Name') }}</label>
                  <md-input v-model="outlets.login.first_name"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Last Name') }}</label>
                  <md-input v-model="outlets.login.last_name"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item">
                <md-field>
                  <label>{{ $t('Email') }}</label>
                  <md-input
                    type="email"
                    autocomplete="off"
                    v-model="outlets.outlet.email"
                    data-vv-name="Email"
                    v-validate="modelValidations.Email"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('Email')">
                      {{ errors.first('Email') }}
                    </div>
                  </slide-y-down-transition>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Password') }}</label>
                  <md-input
                    type="password"
                    autocomplete="new-password"
                    v-model="outlets.login.password"
                    data-vv-name="Password"
                    v-validate="modelValidations.Password"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('Password')">
                      {{ errors.first('Password') }}
                    </div>
                  </slide-y-down-transition>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Phone') }}</label>
                  <md-input
                    type="text"
                    v-model="outlets.login.phone"
                    data-vv-name="Phone"
                    v-validate="modelValidations.Phone"
                  ></md-input>
                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('Phone')">
                      {{ errors.first('Phone') }}
                    </div>
                  </slide-y-down-transition>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Postal Code') }}</label>
                  <md-input type="text" v-model="outlets.login.pin_code">
                  </md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t('Outlet Opening Time (24hr)') }}</label>
                  <md-input
                    type="time"
                    v-model="outlets.outlet.wrk_start_time"
                  ></md-input>
                </md-field>
              </div>
            </div>
          </div>

          <md-checkbox v-model="boolean"
            >I agree to the <a>terms and conditions</a>.</md-checkbox
          >
          <div class="button-container">
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-success md-round mt-4"
              slot="footer"
              >Get Started</md-button
            >
          </div>
        </div>
      </signup-card>
    </div>
  </div>
</template>
<script>
import { SignupCard } from '@/components'
import { customizeService } from '../../../../services/customize'
import { SlideYDownTransition } from 'vue2-transitions'

export default {
  components: {
    SignupCard,
    SlideYDownTransition,
  },
  data() {
    return {
      firstname: null,
      boolean: false,
      email: null,
      password: null,
      outlets: {
        outlet: {
          wrk_start_time: '07:00',
        },
        login: {
          status: 'active',
        },
      },

      modelValidations: {
        OutletName: {
          required: true,
        },
        Email: {
          required: true,
          email: true,
        },
        Password: {
          required: true,
        },
        Website: {
          url: true,
        },
        Phone: {
          numeric: true,
          required: true,
        },
        // VoucherHeader: {
        //   required: true,
        // },
        // VoucherFooter: {
        //   required: true,
        // },
        // Amount: {
        //   required: true,
        //   numeric: true,
        // },
        OutletLogo: {
          image: true,
        },
        Number: {
          numeric: true,
        },
        loading: false,
      },
      contentLeft: [
        {
          colorIcon: 'icon-success',
          icon: 'timeline',
          title: 'Marketing',
          description:
            "We've created the marketing campaign of the website. It was a very interesting collaboration.",
        },

        {
          colorIcon: 'icon-danger',
          icon: 'code',
          title: 'Fully Coded in HTML5',
          description:
            "We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub.",
        },

        {
          colorIcon: 'icon-info',
          icon: 'group',
          title: 'Built Audience',
          description:
            'There is also a Fully Customizable CMS Admin Dashboard for this product.',
        },
      ],
      inputs: [
        {
          icon: 'face',
          name: 'First Name...',
          nameAttr: 'firstname',
          type: 'text',
        },

        {
          icon: 'email',
          name: 'Email...',
          nameAttr: 'email',
          type: 'email',
        },

        {
          icon: 'lock_outline',
          name: 'Password..',
          nameAttr: 'password',
          type: 'password',
        },
      ],
    }
  },
  methods: {
    register() {
      let body = this.outlets
      customizeService.registerUser(body).then((res) => {
        this.$router.push('/login')
      })
    },

    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          // this.$emit('on-submit', this.alert('Add'), isValid)
          this.register()
        }
      })
    },
  },
}
</script>
<style></style>
