<template>
  <md-card class="block-bg">
    <md-card-header>
      <h4 class="title text-white page-title">{{ $t('Assign Items') }}</h4>
    </md-card-header>
    <div class="md-layout">
      <div class="md-layout-item md-size-45 md-medium-size-100">
        <!-- <md-field>
          <label>Select Menu Items</label>
          <md-input></md-input>
        </md-field> -->
        <h4 class="pad-l-15px">Select Menu Items</h4>
        <md-card v-if="formType !== 'View Package'">
          <md-card-content>
            <div class="full-control assign-items">
              <div class="list">
                <md-list>
                  <div v-for="item in MainCat" :key="item.id">
                    <md-list-item md-expand>
                      <div class="module-detail">
                        <!-- <md-icon id="expand-category">expand_more</md-icon> -->
                        <i></i>
                        <div class="md-list-item-text module-name">
                          {{ item.name }}
                        </div>
                        <md-checkbox
                          v-model="item.checked"
                          :disabled="formType == 'View Outlet'"
                          @change="handleCheckboxChange(item.id)"
                          class="checkbox-custom"
                        >
                        </md-checkbox>
                        <!-- {{moduleId[moduleId.findIndex(el => el.module_id == item.id)].module_id}} -->
                        <!-- <div v-if="assignedCat.findIndex(el => el.module_id == item.id)==-1 && formType!=='View Package'" class="md-icon-button md-primary btn-add-module" @click="addModule(item.name,item.id)" :disabled="formType == 'View Package'">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </div> -->
                      </div>
                      <div slot="md-expand">
                        <md-list>
                          <select
                            v-model="moveRight"
                            multiple
                            v-bind:style="{
                              height: 30 * getMenuItemCount(item.id) + 'px',
                            }"
                          >
                            <option
                              v-for="(sub, index) in MenuItems.filter(
                                (it) => it.item_group == item.id
                              )"
                              :value="sub"
                              :key="index"
                            >
                              {{ sub.name }}
                            </option>
                          </select>
                        </md-list>
                      </div>
                    </md-list-item>
                  </div>
                </md-list>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item md-size-10 action-box">
        <div class="actions">
          <div class="action-icons" @click="addMenuItem()">
            <i class="fas fa-chevron-right"></i>
          </div>
          <div class="action-icons" @click="removeMenuItem()">
            <i class="fas fa-chevron-left"></i>
          </div>
          <div class="action-icons" @click="moveAllRight()">
            <i class="fas fa-angle-double-right"></i>
          </div>
          <div class="action-icons" @click="moveAllLeft()">
            <i class="fas fa-angle-double-left"></i>
          </div>
        </div>
      </div>

      <div
        class="md-layout-item md-medium-size-100"
        :class="[formType !== 'View Package' ? 'md-size-45' : 'md-size-100']"
      >
        <!-- <md-field>
          <label>Selected Menu Items</label>
          <md-input></md-input>
        </md-field> -->
        <h4 class="pad-l-15px">Selected Menu Items</h4>
        <md-card>
          <md-card-content>
            <!-- <div class="full-control">
            <div class="list">
              <md-list>
                <h6>{{ $t('Modules')}}</h6>
                <md-list-item md-expand v-for="(item) in assignedCat" :key="item.id">
                  
                  <div class="module-detail">
                    <div
                    class="md-list-item-text module-name"
                  >{{item.name}}</div>
                  </div>
                  
                  <div slot="md-expand">
                    <div class="md-layout">
                      <div class="md-layout-item">
                        <h6>{{$t('Sub Modules')}}</h6>
                      </div>
                      <div class="md-layout-item"></div>
                    </div>

                    <div>
                      <select v-model="moveLeft" multiple>
                                    <option v-for="(sub, index) in assignedItems"
                                                        :value="sub"
                                                        :key="index" 
                                                        v-if="sub.item_group == item.id">
                                                {{ sub.name }}
                                    </option>
                      </select>
                  </div>
                  </div>
                </md-list-item>
              </md-list>
            </div>
          </div> -->
            <div class="full-control assign-items">
              <div class="list">
                <md-list>
                  <div v-for="item in assignedCat" :key="item.id">
                    <md-list-item md-expand>
                      <div class="module-detail">
                        <i></i>
                        <div class="md-list-item-text module-name">
                          {{ item.name }}
                        </div>
                        <md-checkbox
                          v-model="item.checked"
                          :disabled="formType == 'View Outlet'"
                          @change="handleRemoveCheckboxChange(item.id)"
                          class="checkbox-custom"
                        >
                        </md-checkbox>
                      </div>
                      <div slot="md-expand">
                        <md-list>
                          <select
                            v-model="moveLeft"
                            multiple
                            v-bind:style="{
                              height:
                                30 * getAssignedItemsCount(item.id) + 'px',
                            }"
                          >
                            <option
                              v-for="(sub, index) in assignedItems.filter(
                                (it) => it.item_group == item.id
                              )"
                              :value="sub"
                              :key="index"
                            >
                              {{ sub.name }}
                            </option>
                          </select>
                        </md-list>
                      </div>
                    </md-list-item>
                  </div>
                </md-list>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="btn-div" v-if="formType !== 'View Package'">
      <md-card-actions class="text-center">
        <md-button
          native-type="submit"
          :to="{
            path: '/business-vertical/Outlet',
          }"
          >{{ $t('Cancel') }}</md-button
        >
        <md-button
          native-type="submit"
          class="md-primary"
          @click.native.prevent="validate"
          >{{ $t('SAVE') }}</md-button
        >
      </md-card-actions>
    </div>
  </md-card>
</template>

<script>
import { Tabs } from '@/components'
import { Collapse } from '@/components'
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { SlideYDownTransition } from 'vue2-transitions'
import { Promise, reject } from 'q'

export default {
  components: {
    Tabs,
    Collapse,
    SlideYDownTransition,
  },

  props: ['outletid'],

  data() {
    return {
      packageName: '',
      packageCost: '',
      package_cost: '',
      MainCat: [],
      assignedCat: [],
      package: {},
      moduleId: [],
      subModuleId: [],
      subModuleList: [],
      formType: '',
      outletId: '',
      touched: {
        packageName: false,
      },
      modelValidations: {
        packageName: {
          required: true,
          alpha_spaces: true,
        },
      },
      MenuItems: [],
      moveRight: [],
      moveLeft: [],
      assignedItems: [],
      defaultCategories: [],
      finalItems: [],
      selectedMaincat: [],
    }
  },

  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit('on-submit', this.alertModule('Add'), isValid)
        }
      })
    },

    addModule(moduleName, id) {},

    handleRemoveCheckboxChange(id) {
      // Your logic to handle the checkbox change based on the ID goes here
      // For example, you can find the corresponding group object using the ID and perform actions accordingly.
      const group = this.assignedCat.find((group) => group.id == id)
      if (group.checked) {
        let menus = this.assignedItems.filter((el) => el.item_group == id)
        this.moveLeft = [...this.moveLeft, ...menus]
        // Do something else with the checked group
      } else {
        let excludedList = this.moveLeft.filter((el) => el.item_group != id)
        this.moveLeft = excludedList
      }
    },

    handleCheckboxChange(id) {
      // Your logic to handle the checkbox change based on the ID goes here
      // For example, you can find the corresponding group object using the ID and perform actions accordingly.
      const group = this.MainCat.find((group) => group.id == id)
      if (group.checked) {
        let menus = this.MenuItems.filter((el) => el.item_group == id)
        this.moveRight = [...this.moveRight, ...menus]
        // Do something else with the checked group
      } else {
        let excludedList = this.moveRight.filter((el) => el.item_group != id)
        this.moveRight = excludedList
      }
    },

    alertModule(value) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.submitItems()
        }
      })
    },

    addMenuItem() {
      this.moveRight.forEach((el) => {
        for (var i = 0; i < this.MainCat.length; i++) {
          if (el.item_group == this.MainCat[i].id) {
            if (!this.assignedCat.includes(this.MainCat[i])) {
              this.assignedCat.push(this.MainCat[i])
            }
          }
        }
        this.assignedItems.push(el)
        let index
        for (var i = 0; i < this.MenuItems.length; i++) {
          if (el.id == this.MenuItems[i].id) {
            index = i
          }
        }
        this.MenuItems.splice(index, 1)
      })

      // Checkbox reset

      // let catId = this.moveRight.length ? this.moveRight[0].item_group : ''
      // if (catId != '') {
      //   this.assignedCat.find((el) => el.id == catId).checked = false
      // }
      const uniqueIdsSet = new Set(this.moveRight.map((obj) => obj.item_group))
      const distinctIds = [...uniqueIdsSet]
      this.assignedCat.filter((item) => distinctIds.includes(item.id))
      this.assignedCat.map((el) => (el.checked = false))

      this.moveRight = []

      let self = this
      let flag = []
      // Remove Category if there are no menu items
      this.MainCat.forEach((el) => {
        for (var i = 0; i < this.MenuItems.length; i++) {
          if (this.MenuItems[i].item_group == el.id) {
            if (!flag.includes(el.id)) {
              flag.push(el.id)
            }
          }
        }
      })

      this.MainCat = []

      for (var i = 0; i < this.defaultCategories.length; i++) {
        if (flag.includes(this.defaultCategories[i].id)) {
          this.MainCat.push(this.defaultCategories[i])
        }
      }

      this.assignedCat = this.assignedCat.sort(
        (a, b) => a.code_order - b.code_order
      )
      this.assignedItems = this.assignedItems.sort(
        (a, b) => a.item_code - b.item_code
      )
    },

    removeMenuItem() {
      this.moveLeft.forEach((el) => {
        for (var i = 0; i < this.assignedCat.length; i++) {
          if (
            this.assignedCat[i].id == el.item_group &&
            !this.MainCat.includes(this.assignedCat[i])
          ) {
            this.MainCat.push(this.assignedCat[i])
          }
        }
        this.MenuItems.push(el)
        let index
        for (var i = 0; i < this.assignedItems.length; i++) {
          if (el.id == this.assignedItems[i].id) {
            index = i
          }
        }
        this.assignedItems.splice(index, 1)
      })

      // Checkbox reset

      // let catId = this.moveLeft.length ? this.moveLeft[0].item_group : ''
      // if (catId != '') {
      //   this.MainCat.find((el) => el.id == catId).checked = false
      // }
      const uniqueIdsSet = new Set(this.moveLeft.map((obj) => obj.item_group))
      const distinctIds = [...uniqueIdsSet]
      this.assignedCat.filter((item) => distinctIds.includes(item.id))
      this.assignedCat.map((el) => (el.checked = false))

      this.moveLeft = []

      let self = this
      let flag = []
      // Remove Category if there are no menu items
      this.assignedCat.forEach((el) => {
        for (var i = 0; i < this.assignedItems.length; i++) {
          if (this.assignedItems[i].item_group == el.id) {
            if (!flag.includes(el.id)) {
              flag.push(el.id)
            }
          }
        }
      })

      this.assignedCat = []

      for (var i = 0; i < this.defaultCategories.length; i++) {
        if (flag.includes(this.defaultCategories[i].id)) {
          this.assignedCat.push(this.defaultCategories[i])
        }
      }

      this.MainCat = this.MainCat.sort((a, b) => a.code_order - b.code_order)
      this.MenuItems = this.MenuItems.sort((a, b) => a.item_code - b.item_code)
    },

    moveAllRight() {
      this.moveRight = []
      this.MenuItems.forEach((el) => {
        this.moveRight.push(el)
      })
      this.addMenuItem()
    },

    moveAllLeft() {
      this.moveLeft = []
      this.assignedItems.forEach((el) => {
        this.moveLeft.push(el)
      })
      this.removeMenuItem()
    },

    getMenuItemCount(MainCatId) {
      let count = 0
      this.MenuItems.forEach((el) => {
        if (el.item_group == MainCatId) {
          count++
        }
      })
      return count
    },

    getAssignedItemsCount(MainCatId) {
      let count = 0
      this.assignedItems.forEach((el) => {
        if (el.item_group == MainCatId) {
          count++
        }
      })
      return count
    },

    submitItems() {
      this.assignedItems.forEach((el) => {
        this.finalItems.push({
          menu_item_id: el.id,
          status: 'active',
          outlet_id: this.outletId,
        })
      })
      customizeService
        .assignItems(this.finalItems, this.outletId)
        .then((res) => {
          if (!res.data.items.length > 0) {
            this.$router.push({
              path: '/business-vertical/Outlet',
            })
          } else {
            this.$router.push({
              name: 'MenuItem',
              params: { items: res.data.items },
              query: { client: this.outletId },
            })
          }
        })
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.outletId = this.$route.query.client
    let self = this

    const p1 = new Promise((resolve, reject) => {
      customizeService.getMaincat().then((res) => {
        // this.MainCat = res.data.api_v1_main_category
        // this.assignedCat = res.data.api_v1_main_category
        this.defaultCategories = res.data.api_v1_main_category
        resolve()
      })
    })

    Promise.all([p1]).then(() => {
      customizeService.getAssignItems(this.outletId).then((res) => {
        this.MenuItems = res.data.left_items
        this.assignedItems = res.data.right_items
        this.assignedItems.forEach((el) => {
          let category = this.defaultCategories.find(
            (cat) => cat.id == el.item_group
          )
          if (category && !this.assignedCat.includes(category)) {
            this.assignedCat.push(category)
          }
        })

        let flag = []
        // Remove Category if there are no menu items
        this.defaultCategories.forEach((el) => {
          for (var i = 0; i < this.MenuItems.length; i++) {
            if (this.MenuItems[i].item_group == el.id) {
              if (!flag.includes(el.id)) {
                flag.push(el.id)
              }
            }
          }
        })

        this.MainCat = []

        for (var i = 0; i < this.defaultCategories.length; i++) {
          if (flag.includes(this.defaultCategories[i].id)) {
            this.MainCat.push(this.defaultCategories[i])
          }
        }
        this.MainCat = this.MainCat.sort((a, b) => a.code_order - b.code_order)
        this.assignedCat = this.assignedCat.sort(
          (a, b) => a.code_order - b.code_order
        )
      })
    })
  },
  watch: {
    packageName() {
      this.touched.required = true
    },
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.assign-items {
  .md-list-item .md-list-item-container .md-ripple {
    border: none;
  }
  .md-active {
    border: 0;
    .module-detail {
      i {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24"><rect x="0" fill="none" width="24" height="24"/><g><path d="M4 15l8-8 8 8-1.414 1.414L12 9.828l-6.586 6.586"/></g></svg>') !important;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 7px;
        width: 20px;
      }
    }
  }

  .module-detail {
    i {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24"><rect x="0" fill="none" width="24" height="24"/><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"/></g></svg>') !important;
      background-size: 100%;
      margin-top: 7px;
      width: 20px;
    }
  }
}
.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

// .package-name {
//   margin-top: 8%;
// }

.text-center {
  justify-content: center !important;
}
.btn-add-module {
  text-align: center;
}
.arrow_left {
  float: left;
}
.md-card .md-card-actions {
  border: none;
}

.module-detail {
  display: flex;
  width: 90%;
}

.module-name {
  padding-top: 6px;
}

.close-subcat {
  width: 50%;
  text-align: center;
}

.switch-item {
  width: 20%;
}

.sub-name {
  padding-top: 12px;
  width: 30%;
}

.fa-times {
  color: rgb(221, 115, 115);
  padding-top: 12px;
}

.fa-plus {
  padding-top: 12px;
  color: #6090af;
}

.shadow-outline {
  background-color: #6090af;
  color: white;
}

select {
  width: 100%;
  overflow: auto;
}

.action-icons {
  margin: 5px;
  padding: 5px;
}

select {
  font-family: inherit;
  border: none;
  font-size: 15px;
}

.md-list-item-text {
  flex: unset;
  display: unset;
  padding-left: 15px;
}

.action-box {
  position: relative;
}

.actions {
  top: 50%;
  transform: translate(-37%, -50%);
  position: absolute;
  left: 50%;
  font-size: 30px;
}
.page-title {
  margin-left: 25px;
}
</style>
<style>
.checkbox-custom {
  width: auto;
  margin: 16px 16px 16px 0;
  /* display: inline-flex; */
  position: absolute;
  justify-content: center;
  right: 5px;
  bottom: 0px;
}
</style>
