<template>
  <md-card class="pagination-card combo-table">
    <md-card-header
      class="md-card-header-text md-card-header-green md-layout md-gutter"
    >
    </md-card-header>
    <div class="md-layout-item md-size-70">
      <span class="arrow_left ml-auto">
        <router-link :to="{ path: '/products/MenuItem' }">
          <img src="../../../assets/images/left.svg" />
        </router-link>
      </span>
      <h3>{{ $t(formType) }}</h3>
    </div>
    <menu-list-link-items />
  </md-card>
</template>
<script>
import MenuListLinkItems from './Components/MenuListLinkItems.vue'
export default {
  components: {
    MenuListLinkItems,
  },
  data() {
    return {}
  },

  methods: {},

  created() {},
  updated: function () {},
  watch: {},
}
</script>
