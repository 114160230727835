<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/Settings/Modules' }">
              <!-- <md-icon class="material-icons">keyboard_arrow_left</md-icon> -->
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div class="md-layout-item md-layout md-size-30">
          <div class="md-layout-item md-size-20">
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="addSubModule"
              v-if="formType == 'Add Module' || formType == 'Edit Module'"
              >add</i
            >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="editModule"
              v-else-if="formType == 'View Module'"
              >edit</i
            >
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('ModuleName') },
                { 'md-valid': !errors.has('ModuleName') && touched.ModuleName },
              ]"
            >
              <label>{{ $t('Module Name') }}</label>
              <md-input
                v-model="moduleName"
                data-vv-name="ModuleName"
                type="text"
                v-validate="modelValidations.ModuleName"
                :disabled="formType == 'View Module'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('ModuleName')">
                  {{ errors.first('ModuleName') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('ModuleName') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Icons') }}</label>
              <md-select
                type="text"
                v-model="moduleIcon"
                :disabled="formType == 'View Module'"
              >
                <md-option :value="'dashboard'">
                  <!-- class="icon-opt1"<i class="fa fa-trash" aria-hidden="true"></i> -->
                  Dashboard
                </md-option>
                <md-option :value="'settings'">
                  <!-- <i class="md-icon md-icon-font md-theme-default">settings</i> -->
                  Settings
                </md-option>
                <md-option :value="'products'">
                  <!-- <i class="md-icon md-icon-font md-theme-default">widgets</i> -->
                  Products
                </md-option>
                <md-option :value="'reports'">
                  <!-- <i class="md-icon md-icon-font md-theme-default">widgets</i> -->
                  Reports
                </md-option>
                <md-option :value="'outlet_settings'">
                  <!-- <i class="md-icon md-icon-font md-theme-default">widgets</i> -->
                  Outlet Settings
                </md-option>
                <md-option :value="'integrations'">
                  <!-- <i class="md-icon md-icon-font md-theme-default">widgets</i> -->
                  Integrations
                </md-option>
              </md-select>
              <slide-y-down-transition></slide-y-down-transition>
              <slide-y-down-transition></slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field
              :class="[
                { 'md-error': errors.has('modulePath') },
                { 'md-valid': !errors.has('modulePath') && touched.modulePath },
              ]"
            >
              <label>{{ $t('Path') }}</label>
              <md-input
                type="text"
                v-model="modulePath"
                data-vv-name="modulePath"
                v-validate="modelValidations.modulePath"
                :disabled="formType == 'View Module'"
                v-on:keyup="modulePathChange"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('modulePath')">
                  {{ errors.first('modulePath') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('modulePath') && touched.required"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="md-layout" v-for="(item, index) in subModules" :key="index">
          <div class="md-layout-item md-size-30">
            <md-field>
              <label>{{ $t('Sub-Module') }} {{ index + 1 }}</label>
              <md-input
                type="text"
                v-model="item.name"
                :disabled="formType == 'View Module'"
                data-vv-name="subModuleName"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-30">
            <md-field>
              <label>{{ $t('Path') }} {{ index + 1 }}</label>
              <md-input
                type="text"
                v-model="item.module_path"
                :disabled="formType == 'View Module'"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-20">
            <md-field>
              <label>{{ $t('Integrations') }}</label>
              <md-select v-model="item.integration_id">
                <md-option
                  v-for="integration in integrations"
                  :key="integration.id"
                  :value="integration.id"
                  >{{ integration.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-10"
            v-if="formType != 'View Module'"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="removeSubModule(index, item.sub_module_id)"
              >close</i
            >
          </div>

          <div
            class="md-layout-item md-size-10"
            v-if="formType != 'View Module'"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="addNestedModule(index)"
              >add</i
            >
          </div>

          <div
            class="md-layout-item md-size-80"
            v-for="(nested, nestedIndex) in item.nested_modules"
            :key="nestedIndex"
          >
            <div class="md-layout">
              <div class="md-layout-item md-size-40">
                <md-field>
                  <label>Nested module name</label>
                  <md-input
                    type="text"
                    v-model="nested.name"
                    :disabled="formType == 'View Module'"
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-40">
                <md-field>
                  <label>Nested module path</label>
                  <md-input
                    type="text"
                    v-model="nested.path"
                    :disabled="formType == 'View Module'"
                  ></md-input>
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-10"
                v-if="formType != 'View Module'"
              >
                <i
                  class="md-icon md-icon-font md-theme-default edit-img"
                  @click="removeNestedModule(index, nestedIndex)"
                  >close</i
                >
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-checkbox v-model="isdefault"></md-checkbox> Is default
          </div>
        </div>
        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType != 'View Module'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/Settings/Modules' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              @click="validate"
              class="md-primary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>

      <!--======================================= Edit Module Form ========================================-->

      <!--================================ View Module Form ===========================================-->
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
export default {
  components: {
    SlideYDownTransition,
  },
  props: {},
  data() {
    return {
      product1: './img/card-2.jpg',
      equalToSource: '',
      equalToDest: '',
      moduleName: '',
      modulePath: '',
      moduleIcon: '',
      isdefault: false,
      switch2: null,
      touched: {
        required: false,
        modulePath: false,
        ModuleName: false,
      },
      modelValidations: {
        required: {
          required: true,
        },
        modulePath: {
          required: true,
        },
        ModuleName: {
          required: true,
        },
      },

      formType: {
        type: String,
        default: 'AddModules',
      },

      moduleId: '',
      moduleDetail: {
        default: '',
      },
      subModules: [],
      bulkModule: {
        name: '',
        module_path: '',
        items: [],
      },
      integrations: [],
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit('on-submit', this.alertModule('Add'), isValid)
        }
      })
    },

    addSubModule() {
      if (this.modulePath !== '') {
        this.subModules.push({
          name: '',
          module_path: this.modulePath,
          integration_id: null,
          nested_modules: [],
        })
      } else {
        this.subModules.push({ name: '', module_path: '', nested_modules: [] })
      }
      // this.modelValidations.push({"teamId":{"status":"pending"}})
    },

    addNestedModule(index) {
      if (this.subModules[index].nested_modules) {
        this.subModules[index].nested_modules.push({
          path: this.subModules[index].module_path,
          name: '',
        })
      } else {
        this.subModules[index].nested_modules = []
        this.subModules[index].nested_modules.push({
          path: this.subModules[index].module_path,
          name: '',
        })
      }
    },

    removeNestedModule(index, nestedIndex) {
      this.subModules[index].nested_modules.splice(nestedIndex, 1)
    },

    removeSubModule(index, id) {
      if (this.formType == 'Edit Module') {
        reusableService.showSwal('Delete').then((result) => {
          if (result.value) {
            customizeService.deleteModule(id).then((res) => {})
          }
        })
      }
      this.subModules.splice(index, 1)
    },

    alertModule(value) {
      var self = this
      if (this.formType == 'Add Module') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createModule()
          }
        })
      } else if (this.formType == 'Edit Module') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitEditModule()
          }
        })
      }
    },

    createModule() {
      this.bulkModule.name = this.moduleName
      this.bulkModule.module_path = this.modulePath
      this.bulkModule.icon = this.moduleIcon
      this.bulkModule.items = this.subModules
      this.bulkModule.is_default = this.isdefault
      let self = this
      customizeService
        .addModules(this.bulkModule)
        .then((res) => {
          self.$router.push({ name: 'Modules' })
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    editModule() {
      this.formType = 'Edit Module'
    },

    submitEditModule() {
      this.bulkModule.name = this.moduleName
      this.bulkModule.module_path = this.modulePath
      this.bulkModule.items = this.subModules
      this.bulkModule.is_default = this.isdefault
      this.bulkModule.icon = this.moduleIcon
      customizeService
        .editmodule(this.bulkModule, this.moduleId)
        .then((res) => {
          this.$router.push({ name: 'Modules' })
        })
    },

    resetModules() {
      this.subModules = []
      this.moduleName = ''
    },

    modulePathChange() {
      if (this.subModules.length !== 0) {
        for (var i = 0; i < this.subModules.length; i++) {
          this.subModules[i].module_path = this.modulePath
        }
      }
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.moduleId = this.$route.query.client
    let self = this
    if (this.formType != 'Add Module') {
      customizeService.getModuleById(this.moduleId).then((res) => {
        self.moduleName = res.data.result.name
        res.data.result.app_sub_module != null
          ? (self.subModules = res.data.result.app_sub_module)
          : (self.subModules = [])
        self.modulePath = res.data.result.module_path
        self.isdefault = res.data.result.is_default
        self.moduleIcon = res.data.result.icon
      })
    }
    customizeService.getIntegrations().then((res) => {
      this.integrations = res.data
    })
  },
  watch: {
    required() {
      this.touched.required = true
    },
    modulePath() {
      this.touched.required = true
    },
    ModuleName() {
      this.touched.required = true
    },
    //  modulePath: function(val){

    //  },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
// .btn-div {
//   // border-top: 1px solid gray;
// }

.md-list {
  .md-list-item {
    // &:first-child {
    //   .md-list-item-button {
    //   }
    // }
    .md-list-item-text {
      padding-left: 30px;
    }
  }
}
</style>
