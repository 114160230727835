<template>
  <div class="md-layout client-table">
    <div class="md-layout-item">
      <md-card class="pagination-card block-bg">
        <md-card-header class="md-card-header-icon md-card-header-green">
          <router-link
            :to="{ name: 'Edit Forms', params: { formType: 'Add Client' } }"
          >
            <md-icon class="add-icon">add</md-icon>
          </router-link>
          <h3 class="title">Clients</h3>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover itemized-txn"
            @md-selected="viewClientDetail"
            v-bind:class="{}"
          >
            <md-table-toolbar class="table-toolbar-align">
              <md-field>
                <label>Search</label>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style=""
                  v-model="searchQuery"
                ></md-input>
                <i class="fa fa-search search-align" aria-hidden="true"></i>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Serial" md-sort-by="Serial">
                <div @click="viewClientDetail(item)">
                  {{ item.id }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Name" md-sort-by="Name">
                <div @click="viewClientDetail(item)">
                  {{ item.name }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="City" md-sort-by="City">
                <div @click="viewClientDetail(item)">
                  {{ item.email }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Package">
                <div @click="viewClientDetail(item)">{{ item.age }}</div>
              </md-table-cell>
              <md-table-cell class="center" md-label="Status">
                <div @click="viewClientDetail(item)">
                  <i class="fa fa-check check-align" aria-hidden="true"></i>
                </div>
              </md-table-cell>
              <md-table-cell class="center" md-label="Actions">
                <div class="border-edit">
                  <md-menu
                    md-size="medium"
                    :md-offset-x="-227"
                    :md-offset-y="-16"
                  >
                    <i
                      class="material-icons text_align-center menu-icon"
                      md-menu-trigger
                      >more_vert</i
                    >
                    <md-menu-content class="menuitem-dropdown">
                      <md-menu-item class="action-header menu-align"
                        >Actions</md-menu-item
                      >
                      <router-link
                        :to="{
                          name: 'Edit Forms',
                          params: { formType: 'Edit Client', client: item },
                        }"
                      >
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fas fa-pencil-alt"></i>
                            Edit
                          </span>
                        </md-menu-item>
                      </router-link>
                      <div>
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fa fa-clone" aria-hidden="true"></i>
                            Outlets
                          </span>
                        </md-menu-item>
                      </div>
                      <div>
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fa fa-ban" aria-hidden="true"></i>
                            Deactivate
                          </span>
                        </md-menu-item>
                      </div>
                      <div>
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            Delete
                          </span>
                        </md-menu-item>
                      </div>
                    </md-menu-content>
                  </md-menu>
                </div>
                <!-- <md-button
                  class="md-just-icon md-info md-simple"
                  @click.native="handleLike(item)"
                >
                  <md-icon>favorite</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handleEdit(item)"
                >
                  <md-icon>dvr</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item)"
                >
                  <md-icon>close</md-icon>
                </md-button>-->
              </md-table-cell>
            </md-table-row>
          </md-table>
          <!-- <div class="footer-table md-table">
             <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>-->
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">Rows Per page</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  From {{ from + 1 }} to {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- </md-card-actions> -->
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import users from './users'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'

export default {
  components: {
    Pagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      currentSort: 'name',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ['Name', 'Email', 'Age', 'Salary', 'Actions'],
      searchQuery: '',
      propsToSearch: ['name', 'email', 'age'],
      tableData: users,
      searchedData: [],
      fuseSearch: null,
    }
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    handleLike(item) {
      Swal.fire({
        title: `You liked ${item.name}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'md-button md-success',
      })
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit ${item.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'md-button md-info',
      })
    },
    handleDelete(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'md-button md-success btn-fill',
        cancelButtonClass: 'md-button md-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item)
          Swal.fire({
            title: 'Deleted!',
            text: `You deleted ${item.name}`,
            type: 'success',
            confirmButtonClass: 'md-button md-success btn-fill',
            buttonsStyling: false,
          })
        }
      })
    },
    deleteRow(item) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === item.id
      )
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1)
      }
    },
    viewClientDetail(item) {
      this.$router.push({
        name: 'Edit Forms',
        params: { formType: 'View Client', client: item },
      })
    },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3,
    })
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    },
  },
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.menu-icon {
  padding-right: 20px;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}
</style>
