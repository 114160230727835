<template>
  <div>
    <form class="md-layout-item">
      <md-card>
        <md-card-header
          class="md-card-header-text md-card-header-green md-layout md-gutter"
        >
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <img
                @click="backToView"
                src="../../../../assets/images/left.svg"
              />
            </span>
            <h3>{{ formType }}</h3>
          </div>
          <div
            class="md-layout-item md-size-30"
            v-if="formType == 'View Profile'"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="editProfile"
              >edit</i
            >
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
              >
                <label>{{ $t('First Name') }}</label>
                <md-input
                  v-model="profile.first_name"
                  data-vv-name="FirstName"
                  type="text"
                  v-validate="modelValidations.FirstName"
                  :disabled="formType == 'View Profile'"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Name')">
                    {{ errors.first('Name') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Name') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('LastName') },
                  { 'md-valid': !errors.has('LastName') && touched.LastName },
                ]"
              >
                <label>{{ $t('Last Name') }}</label>
                <md-input
                  v-model="profile.last_name"
                  data-vv-name="LastName"
                  type="text"
                  v-validate="modelValidations.LastName"
                  :disabled="formType == 'View Profile'"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('LastName')">
                    {{ errors.first('LastName') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('LastName') && touched.LastName"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('Company') },
                  { 'md-valid': !errors.has('Company') && touched.Company },
                ]"
              >
                <label>{{ $t('Company') }}</label>
                <md-input
                  data-vv-name="Company"
                  type="text"
                  v-validate="modelValidations.Company"
                  :disabled="formType == 'View Profile'"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Company')">
                    {{ errors.first('Company') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Company') && touched.Company"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('Address') },
                  { 'md-valid': !errors.has('Address') && touched.Address },
                ]"
              >
                <label>{{ $t('Address') }}</label>
                <md-input
                  v-model="profile.address"
                  data-vv-name="Address"
                  type="text"
                  v-validate="modelValidations.Address"
                  :disabled="formType == 'View Profile'"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Address')">
                    {{ errors.first('Address') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Address') && touched.Address"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('Phone') },
                  { 'md-valid': !errors.has('Phone') && touched.Phone },
                ]"
              >
                <label>{{ $t('Phone') }}</label>
                <md-input
                  v-model="profile.phone"
                  data-vv-name="Phone"
                  type="text"
                  v-validate="modelValidations.Phone"
                  :disabled="formType == 'View Profile'"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Phone')">
                    {{ errors.first('Phone') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Phone') && touched.Phone"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('created_at') },
                  {
                    'md-valid': !errors.has('created_at') && touched.created_at,
                  },
                ]"
              >
                <label>{{ $t('Created On') }}</label>
                <md-input
                  v-model="profile.created_at"
                  data-vv-name="created_at"
                  type="text"
                  v-validate="modelValidations.created_at"
                  :disabled="formType == 'View Profile'"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('created_at')">
                    {{ errors.first('created_at') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('created_at') && touched.created_at"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Country</label>
                <md-input
                  v-model="profile.country"
                  type="text"
                  :disabled="formType == 'View Profile'"
                ></md-input>
              </md-field>

              <md-field>
                <label>Postal Code</label>
                <md-input
                  v-model="profile.pin_code"
                  type="number"
                  :disabled="formType == 'View Profile'"
                ></md-input>
              </md-field>
            </div>

            <div
              class="md-layout-item md-size-50"
              @click="showProfileDialog = true"
            >
              <img
                class="img logo-align"
                slot="imageHeader"
                :src="profile.logo"
              />
            </div>
          </div>

          <!-- ----------------------------------------------------------------------------------- -->
          <div class="btn-div" v-if="formType !== 'View Profile'">
            <md-card-actions class="text-center">
              <md-button native-type="submit" @click="backToView">{{
                $t('CANCEL')
              }}</md-button>
              <md-button
                class="md-primary"
                native-type="submit"
                @click.native.prevent="validate"
                >{{ $t('SAVE') }}</md-button
              >
            </md-card-actions>
          </div>
        </md-card-content>
      </md-card>
    </form>

    <md-dialog :md-active.sync="showProfileDialog" class="custom-role-package">
      <div class="profile-image">
        <img class="dialog-img" slot="imageHeader" :src="profile.logo" />
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../../services/customize.js'
import { reusableService } from '../../../../services/reusable.js'
import axios from 'axios'
import { accountService } from '../../../../services/account'
export default {
  components: {
    SlideYDownTransition,
    axios,
  },
  props: {},

  data() {
    return {
      Name: '',
      Type: '',
      product1: './img/user-dp.png',

      touched: {
        FirstName: false,
        LastName: false,
        Address: false,
        City: false,
        Country: false,
        PostalCode: false,
      },
      profileBackUp: [],
      formType: 'View Profile',

      modelValidations: {
        FirstName: {
          required: true,
        },
        LastName: {
          required: true,
        },
        Address: {
          required: true,
        },
        created_at: {},
        Country: {},

        Street: {},
      },

      profile: {
        city: '',
        country: '',
      },

      showProfileDialog: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.updateProfile()
          }
        })
      } else if (value == 'Update') {
        reusableService.showSwal(value).then(() => {
          this.formType = 'View Profile'
        })
      }
    },

    updateProfile() {
      accountService.updateUserDetails(this.profile).then((res) => {
        this.alert('Update')
      })
    },

    reset() {
      this.discount = {}
    },

    editProfile() {
      this.formType = 'Edit Profile'
    },
    backToView() {
      this.$router.push({ path: '/dashboard' })
    },
    // getDetails(){
    //    accountService.getUserDetails().then(
    //   res => {
    //     console.log("User details", res.data)
    //     //this.profileBackUp = JSON.parse(JSON.stringify(res.data));
    //     this.profile = res.data
    //     let date = new Date(this.profile.created_at)
    //     this.profile.created_at = date
    //   }
    // )
    // }
  },
  created() {
    // this.getDetails();
    this.profile = this.$store.getters.getProfile
  },

  watch: {
    FirstName() {
      this.touched.required = true
    },
    LastName() {
      this.touched.required = true
    },
    Address() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  margin-top: 10px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}

.dialog-img {
  height: 100%;
  width: 100%;
}

img {
  cursor: pointer;
}
</style>
