<template>
  <md-card class="ipad-grid">
    <div class="md-layout page-top-tools">
      <span
        class="top-edit-span"
        v-if="!editScreenStatus"
        @click="editScreenStatus = true"
        ><md-icon class="top-edit-icn">edit</md-icon>{{ screenMenu.name }}</span
      >
      <div class="md-layout" v-if="editScreenStatus">
        <div class="md-layout-item md-size-15">
          <md-field
            :class="[
              { 'md-error': errors.has('Name') },
              { 'md-valid': !errors.has('Name') && touched.Name },
            ]"
          >
            <label>{{ $t('Name') }}</label>
            <md-input
              v-model="screenMenu.name"
              data-vv-name="Name"
              data-vv-scope="screen"
              type="text"
              v-validate="modelValidations.Name"
            ></md-input>
            <slide-y-down-transition>
              <div class="error" v-show="errors.has('screen.Name')">
                {{ errors.first('screen.Name') }}
              </div>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon
                class="success"
                v-show="!errors.has('Name') && touched.Name"
                >done</md-icon
              >
            </slide-y-down-transition>
          </md-field>
        </div>
        <div class="md-layout-item md-size-15">
          <md-field
            :class="[
              { 'md-error': errors.has('Status') },
              { 'md-valid': !errors.has('Status') && touched.Status },
            ]"
          >
            <label>{{ $t('Status') }}</label>
            <md-select
              v-model="screenMenu.status"
              data-vv-name="Status"
              data-vv-scope="screen"
              type="text"
            >
              <md-option class="first-opt" disabled>Status</md-option>
              <md-option value="true">{{ $t('Active') }}</md-option>
              <md-option value="false">{{ $t('Inactive') }}</md-option>
            </md-select>
            <slide-y-down-transition>
              <div class="error" v-show="errors.has('screen.Status')">
                {{ errors.first('screen.Status') }}
              </div>
            </slide-y-down-transition>
          </md-field>
        </div>
        <div class="md-layout-item md-size-15">
          <md-button
            class="md-just-icon md-round screen-save"
            id="screen-save"
            @click="updateScreen"
            ><md-icon>save</md-icon></md-button
          >
          <md-button
            class="md-just-icon md-round screen-cancel"
            @click="editScreenStatus = false"
            ><md-icon>cancel</md-icon></md-button
          >
        </div>
      </div>
      <div
        id="status-holder"
        :class="{ 'status-holder-inactive': !screenMenuStatus }"
      >
        <p>
          <md-icon>trip_origin</md-icon
          >{{ screenMenuStatus ? 'Active' : 'Inactive' }}
        </p>
      </div>
    </div>
    <div class="md-layout top-btn-holder">
      <vuedrag class="drag-holder" :move="checkMove" :list="menuPages">
        <div
          v-for="page in menuPages"
          :key="page.id"
          class="md-layout-item md-size-15 position-relative"
        >
          <div
            class="category-head"
            @click="activateMenuPage(page)"
            v-bind:style="{ background: page.category_color }"
            :class="{
              'title-active': activeMenuPage == page.id,
            }"
          >
            <div class="category-head-holder">
              <span class="position-relative">{{ page.name }}</span>
            </div>
          </div>
          <md-button
            class="md-round cat-hd-delete"
            id="cat-hd-delete"
            @click="deleteMenuPage(page.id)"
            ><md-icon>delete_forever</md-icon></md-button
          >
          <md-button
            class="cat-hd-edit md-round"
            id="cat-hd-edit"
            @click="editMenuPage(page)"
            ><md-icon>edit</md-icon></md-button
          >
        </div>
        <div
          class="tab-section md-layout-item md-size-15 non-draggable"
          v-if="$store.state.account.profile.has_access"
        >
          <div class="category-add-btn" @click="openMenuPage()">
            <i class="fas fa-plus"></i>Add
          </div>

          <div class="tabs">
            <div class="add-remove"></div>
          </div>
        </div>
      </vuedrag>
    </div>

    <md-card-content>
      <div class="md-layout justify-content-between">
        <div class="md-layout-item md-size-15 pd-l menu-itm-holder">
          <div class="search-field">
            <md-field>
              <label>Search</label>
              <md-input
                v-on:keyup="search"
                v-model="searchKey"
                type="text"
              ></md-input>
            </md-field>
          </div>
          <div class="items-list ipdgrd-sidebar">
            <div>All Menu Items</div>

            <div class="loader-container" v-if="loader.mainCat">
              <clip-loader
                :loading="loader.mainCat"
                :color="'#00E0DB'"
              ></clip-loader>
            </div>
            <!-- <div>
              <md-list
                id="search-result"
                :class="{ 'search-result': searched.length > 0 }"
              >
                <md-list-item
                  v-for="(item, index) in searched.filter(
                    (it) => it.type == 'menu item'
                  )"
                  :key="index"
                >
                  <div
                    class="sub-cat-name"
                    draggable="true"
                    @click="addItemToGrid(item)"
                    @dragend="externalBoxLeave"
                    @dragstart="externalBoxEnter($event, item)"
                  >
                    {{ item.name }}
                  </div>
                  <span
                    v-show="conditionFlagGreen(item.id, item.type)"
                    class="dot"
                  ></span>
                </md-list-item>
              </md-list>
            </div> -->
            <div class="items">
              <slot v-for="cat in mainCat">
                <div
                  class="main-cat"
                  :key="cat.id"
                  @click="cat.checkOpen = !cat.checkOpen"
                  v-bind:class="{ open: cat.checkOpen }"
                >
                  <div class="arrow"></div>

                  <div class="main-cat-name">{{ cat.name }}</div>
                </div>
                <md-list>
                  <div v-show="cat.checkOpen">
                    <md-list-item
                      v-for="(item, index) in searched.filter(
                        (it) =>
                          it.type == 'menu item' &&
                          it.main_category_id == cat.id
                      )"
                      :key="index"
                    >
                      <div
                        class="sub-cat-name"
                        draggable="true"
                        @click="addItemToGrid(item)"
                        @dragend="externalBoxLeave"
                        @dragstart="externalBoxEnter($event, item)"
                      >
                        {{ item.name }}
                      </div>
                      <span
                        v-show="conditionFlagGreen(item.id, item.type)"
                        class="dot"
                      ></span>
                    </md-list-item>
                  </div>
                </md-list>
              </slot>
            </div>

            <div v-show="checkOpen.combos">
              <div class="loader-container" v-if="loader.combos">
                <clip-loader
                  :loading="loader.combos"
                  :color="'#00E0DB'"
                ></clip-loader>
              </div>

              <div class="combos">
                <md-list>
                  <md-list-item
                    v-for="(item, index) in searched.filter(
                      (it) => it.type == 'combo'
                    )"
                    :key="index"
                  >
                    <md-checkbox
                      :model="selectedItems"
                      :value="item.id"
                    ></md-checkbox>
                    {{ item.name }}
                    <span
                      v-show="conditionFlagGreen(item.id, item.type)"
                      class="dot"
                    ></span>

                    <div
                      class="item-color"
                      v-bind:style="{ background: item.color }"
                      @click="currentSwatch(item, $event, 'items')"
                    ></div>
                  </md-list-item>
                </md-list>
              </div>
            </div>

            <div v-show="checkOpen.menuPages">
              <div class="loader-container" v-if="loader.menuCat">
                <clip-loader
                  :loading="loader.menuCat"
                  :color="'#00E0DB'"
                ></clip-loader>
              </div>
              <div class="combos">
                <md-list>
                  <md-list-item
                    v-for="(item, index) in searched.filter(
                      (it) => it.type == 'tab'
                    )"
                    :key="index"
                  >
                    <md-checkbox
                      :model="selectedItems"
                      :value="item.id"
                    ></md-checkbox>
                    {{ item.name }}
                    <span
                      v-show="conditionFlagGreen(item.id, item.type)"
                      class="dot"
                    ></span>

                    <div
                      class="item-color"
                      v-bind:style="{ background: item.color }"
                      @click="currentSwatch(item, $event, 'items')"
                    ></div>
                  </md-list-item>
                </md-list>
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-85">
          <div class="md-layout">
            <div class="md-layout-item md-size-30">
              <h3 class="head-span">{{ menuPage.name }}</h3>
            </div>
            <div class="md-layout-item md-size-70 d-flex justify-content-end">
              <md-button
                class="ipd-edit-btn"
                id="ipd-edit-btn"
                @click="addNewEightByEight"
                :disabled="!menuPage.allow_additional_page"
                ><md-icon>app_registration</md-icon> Add Page</md-button
              >
              <md-button class="ipd-width-btn" id="ipd-width-btn"
                ><md-icon>grid_4x4</md-icon> 8x8</md-button
              >
              <div class="position-relative">
                <clip-loader
                  :loading="loader.saveChanges"
                  :color="'#E84B3D'"
                  class="loader-custom-top"
                ></clip-loader>
                <md-button
                  class="ipd-save-btn"
                  id="ipd-save-btn"
                  @click="saveChanges('new')"
                  :disabled="!$store.state.account.profile.has_access"
                  ><md-icon>save</md-icon>Save</md-button
                >
              </div>
              <md-button
                class="ipd-cancel-btn"
                id="ipd-cancel-btn"
                :to="{ path: '/products/screen-design/menu-list' }"
                ><md-icon>arrow_back</md-icon>Go back</md-button
              >
            </div>
          </div>
          <main>
            <section class="ipad screen-grid position-relative">
              <layout-eight-by-eight
                :grid-data="activeMenuPageGrid"
                :menu-page-active="activeMenuPage"
                :menu-page="menuPage"
                @menu-item-seleted="menuItemEditEvent"
                :ref="activeMenuPage"
                v-if="activeMenuPage != ''"
              />
              <!-- <VueSlickCarousel
                :arrows="true"
                :dots="true"
                :infinite="false"
                :vertical="true"
              >
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
              </VueSlickCarousel> -->
              <!-- </slot> -->
            </section>
          </main>
        </div>
        <div
          class="swatches-holder"
          :class="{
            'sidepanel-animate': showSidePanel,
            'swatches-closed': showBtnEvent,
          }"
        >
          <div class="">
            <h3>Edit</h3>
            <div
              class="swatches-edit"
              v-if="activeMenuItem"
              v-bind:style="{
                background: activeMenuItem ? activeMenuItem.colour : '',
              }"
            >
              <div class="swatches-inner">
                <p class="swatches-name" v-if="!editActiveMenuItem">
                  {{ activeMenuItem.item_name }}
                </p>
                <p class="swatches-price">
                  {{ $store.state.account.outlet.currency_type }}
                  {{ activeMenuItem.price }}
                </p>
              </div>
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
                v-if="editActiveMenuItem"
              >
                <label>{{ $t('Name') }}</label>
                <md-input
                  v-model="activeMenuItem.item_name"
                  data-vv-name="Name"
                  data-vv-scope="edit"
                  type="text"
                  v-validate="modelValidations.Name"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('edit.Name')">
                    {{ errors.first('edit.Name') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Name') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
            <swatches
              v-model="newColour"
              :colors="'text-advanced'"
              shapes="circles"
              popover-to="center"
              inline
            ></swatches>
          </div>
          <div class="swatches-btn-holder">
            <clip-loader
              :loading="loader.saveChanges"
              :color="'#E84B3D'"
              class="loader-custom"
            ></clip-loader>
            <md-button
              class="swatches-save-btn"
              id="swatches-save-btn"
              @click="updateChanges"
              :disabled="!$store.state.account.profile.has_access"
              ><md-icon>save</md-icon>Save</md-button
            >
            <md-button class="swatches-cancel-btn" @click="clearSelecton"
              ><md-icon>cancel</md-icon>Cancel</md-button
            >
          </div>
          <md-button
            @click="showHideSidePanel"
            class="swatches-open md-round"
            id="swatches-open"
            ><md-icon>keyboard_backspace</md-icon></md-button
          >
        </div>
      </div>
    </md-card-content>
    <md-dialog
      :md-active.sync="showTabModal"
      class="add-item-modal"
      id="add-item-modal"
    >
      <md-card class="">
        <md-card-header
          class="md-card-header-text md-card-header-green md-layout md-gutter"
        >
          <h4 class="add-item-title">Create Menu Page</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
              >
                <label>{{ 'Name' }}</label>
                <md-input
                  v-model="menuPageForm.name"
                  data-vv-name="Name"
                  type="text"
                  v-validate="modelValidations.Name"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Name')">
                    {{ errors.first('Name') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Name') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
            <div
              class="md-layout-item md-size-100 flex justify-content-center no-border-"
            >
              <md-field
                :class="[
                  'justify-content-center',
                  { 'md-error': errors.has('Colour') },
                  {
                    'md-valid': !errors.has('Colour') && touched.Colour,
                  },
                ]"
              >
                <swatches
                  v-model="menuPageForm.category_color"
                  :colors="'text-advanced'"
                  data-vv-name="Colour"
                  v-validate="modelValidations.Colour"
                  shapes="circles"
                  popover-to="center"
                  inline
                  id="add-swatches"
                ></swatches>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Colour')">
                    {{ errors.first('Colour') }}
                  </div>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100">
              <md-field
                :class="[
                  { 'md-error': errors.has('screenSize') },
                  {
                    'md-valid': !errors.has('screenSize') && touched.screenSize,
                  },
                ]"
              >
                <label for="screenSize">{{ 'screenSize' }}</label>
                <md-select
                  v-model="menuPageForm.screen_size"
                  data-vv-name="screenSize"
                  v-validate="modelValidations.screenSize"
                >
                  <md-option class="first-opt" id="drop-dwn-btn" disabled
                    >Size</md-option
                  >
                  <md-option
                    v-if="isTillEnabled"
                    class="first-opt"
                    id="drop-dwn-btn"
                    value="grid8by8"
                    :disabled="!isTillEnabled"
                    >8X8</md-option
                  >
                  <md-option
                    class="first-opt"
                    id="drop-dwn-btn"
                    value="grid4by4"
                    v-if="isFourByFourEnabled"
                    :disabled="!isFourByFourEnabled"
                    >4X4</md-option
                  >
                </md-select>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('screenSize')">
                    {{ errors.first('screenSize') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('screenSize') && touched.screenSize"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
            <!-- <div class="md-layout-item md-size-100">
              <md-checkbox v-model="menuPageForm.allow_additional_page"
                >Allow to add additional vertical pages
              </md-checkbox>
            </div> -->

            <div class="md-layout-item md-size-100">
              <div class="add-item-btn-holder">
                <md-button class="add-item-cancel-btn" @click="cancelMenuPage"
                  >Cancel</md-button
                >
                <md-button class="add-item-save-btn" @click="addTab"
                  >Save</md-button
                >
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </md-dialog>
  </md-card>
</template>

<script>
import { customizeService } from '../../../services/customize.js'
import VueSlickCarousel from 'vue-slick-carousel'
import vuedrag from 'vuedraggable'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'jquery-ui-dist/jquery-ui'
import Swatches from 'vue-swatches'
import { Promise, reject } from 'q'
import { accountService } from '../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Vue from 'vue'
import { screenDesignMixin } from './screenDesignMixin'
import layoutEightByEight from './layoutView.vue'
import 'vue-swatches/dist/vue-swatches.css'
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import store from '@/store'
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  components: {
    VueSlickCarousel,
    SlideYDownTransition,
    vuedrag,
    Swatches,
    ClipLoader,
    layoutEightByEight,
  },
  mixins: [screenDesignMixin],

  data() {
    return {
      self: this,

      touched: {
        Name: false,
        screenSize: false,
        Status: true,
        Colour: true,
      },

      modelValidations: {
        Name: {
          required: true,
        },
        screenSize: {
          required: true,
        },
        Status: {
          required: false,
        },
        Colour: {
          required: true,
        },
      },
      items: [],
      combos: [],
      menu_items: [],
      categories: [],
      selectedItems: [],
      tabs: [],
      itemsInGrid: [],
      activeTab: null,
      drag: true,
      btnDisabled: false,
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      searched: [],
      searchKey: null,
      activateSort: false,
      currentSort: 1,
      outlet_id: accountService.getOutletId(),
      chain_id: accountService.getChainId(),
      swatchActive: {},
      activateSwatch: false,
      swatchPosition: '',
      mainCat: [],
      mainCatId: [],
      swatchCat: '',
      selectFlagGreen: [{ id: null, type: '' }],
      loader: {
        menuItems: false,
        mainCat: false,
        combos: false,
        menuCat: false,
        ipad: false,
        saveChanges: false,
      },
      checkOpen: { combos: false, menuPages: false },
      rightClick: false,
      top: '0px',
      left: '0px',
      rightClickCatId: '',

      layout: {},
      showSidePanel: false,
      defaultLayout: {
        grid: [],
      },

      externalBoxes: [13, 14, 15, 16], // External box identifiers
      menuPage: {},
      // activeMenuPage: '',
      menuPageForm: {
        screen_size: this.getDefualtSize(),
      },
      menuPages: [],
      showTabModal: false,
      showBtnEvent: false,
      newColour: '',
      // multiSelected: false,
      multiSelectedMenus: {},
      activeMenuItem: null,
      editActiveMenuItem: false,
      editScreenStatus: false,
      screenMenu: {
        status: false,
      },
      screenMenuStatus: false,
    }
  },
  watch: {
    multiSelected(newVal, oldVal) {
      // if (!newVal) {
      const dataSelected = Object.values(
        store.state.screen.allSelectedMenus
      ).flat()
      if (dataSelected.length == 1) {
        const gridItem = Object.values(this.layout)
          .flat()
          .find((item) => item.uuid == dataSelected[0])
        // this.activeMenuItem = gridItem || {}
        this.activeMenuItem = null
      } else {
        this.activeMenuItem = null
      }
      this.editActiveMenuItem = false
      // }
    },
    newColour(newVal, oldVal) {
      if (this.activeMenuItem) {
        this.activeMenuItem.colour = newVal
      }
    },
  },
  methods: {
    ...mapActions(['setSelectedMenus']),
    getDefualtSize() {
      // let defaultGridSize = 'grid8by8'
      // if (
      //   this.isFourByFourEnabled ||
      //   (this.isTillEnabled && this.isFourByFourEnabled)
      // ) {
      //   defaultGridSize = 'grid4by4' // Set to 4x4 if "Handheld" is selected, or both "Till" and "Handheld" are selected
      // }
      let defaultGridSize = 'grid8by8' // Set to 8x8 for 'Till' by default
      if (this.isEightByEightEnabled) {
        defaultGridSize = 'grid8by8' // Allow up to 8x8 if only 'Till' is selected
      } else if (this.isFourByFourEnabled) {
        defaultGridSize = 'grid4by4' // Force to 4x4 for 'Handheld'
      }
      console.log('DDDD', defaultGridSize)
      return defaultGridSize
    },

    getGridItems(start = 0) {
      let colCount = 8
      const screenSize = this.menuPage.screen_size
      switch (screenSize) {
        case 'grid8by8':
          colCount = 8
          break
        case 'grid4by4':
          colCount = 4
          break
        default:
          colCount = 8
      }

      const rows = colCount // Number of rows
      const cols = colCount // Number of columns
      let i = start // Index counter

      const data = []

      for (let y = 0; y < rows; y++) {
        for (let x = 0; x < cols; x++) {
          data.push({
            x: x,
            y: y,
            i: i,
            item_name: null,
            colour: '',
            menu_item_id: '',
            uuid: i,
            price: '',
          })
          i++
        }
      }
      return data
    },
    addNewEightByEight() {
      const isNotEmpty = this.$refs[this.activeMenuPage].isNotBlank()
      if (isNotEmpty) {
        const lastItem =
          this.layout[this.activeMenuPage]?.[
            this.layout[this.activeMenuPage].length - 1
          ]

        if (lastItem) {
          // Ensure the reactive array updates
          this.layout = {
            ...this.layout,
            [this.activeMenuPage]: [
              ...this.layout[this.activeMenuPage],
              ...this.getGridItems(lastItem.i + 1),
            ],
          }
        }
      } else {
        Swal.fire({
          title: 'Alert!',
          text: `Cannot create additional pages. The page must contain at least one menu item before adding more pages!`,
          type: 'warning',
          buttonsStyling: false,
          confirmButtonClass: 'md-button md-info',
        })
        return false
      }
    },

    async updateChanges(obj) {
      let result = []
      const multiSelected = store.state.screen.allSelectedMenus

      const tempLayout = JSON.parse(JSON.stringify(this.layout))
      var validatedStatus = true
      if (!this.editActiveMenuItem) {
        Object.keys(tempLayout).forEach((key) => {
          if (multiSelected[key]) {
            tempLayout[key] = tempLayout[key].map((item) => ({
              ...item,
              colour: multiSelected[key].includes(item.uuid)
                ? this.newColour
                : item.colour,
            }))
          }
        })
      } else {
        await this.$validator.validateAll('edit').then((result) => {
          let item = tempLayout[this.activeMenuPage].find(
            (el) => el.uuid == this.activeMenuItem.uuid
          )
          item.item_name = this.activeMenuItem.item_name
          item.colour = this.activeMenuItem.colour

          validatedStatus = result
        })
      }
      if (validatedStatus) {
        this.saveChanges('edit', tempLayout)
      }

      // })
    },
    clearSelecton() {
      // this.store.state.screen = {}
      this.setSelectedMenus({})
      this.editActiveMenuItem = false
      this.activeMenuItem = null
      this.showSidePanel = false
      this.newColour = ''
    },
    setSelectedItems(data) {
      this.multiSelected = Object.values(data).flat().length > 1
    },

    menuItemEditEvent(data) {
      const dataSelected = Object.values(
        store.state.screen.allSelectedMenus
      ).flat()
      if (dataSelected.length <= 1) {
        this.activeMenuItem = JSON.parse(JSON.stringify(data))
        this.newColour = this.activeMenuItem.colour
        this.editActiveMenuItem = true
      } else {
        this.editActiveMenuItem = false
        this.activeMenuItem = null
      }

      this.showSidePanel = true
      this.showBtnEvent = false
      // this.showHideSidePanel()
    },
    showHideSidePanel() {
      this.showSidePanel = !this.showSidePanel
      if (!this.showSidePanel) {
        this.showBtnEvent = true
        this.clearSelecton()
      } else {
        this.showBtnEvent = false
      }
    },
    // -------------------------------------------------------------------
    addTab(tabIndex) {
      // this.menuPages.push({ name: 'Test', screen: '', colour: '' })
      this.menuPageForm.screen_id = this.$route.query.screen
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          customizeService.createMenuPage(this.menuPageForm).then((res) => {
            this.menuPage = res.data
            if (this.menuPageForm.id) {
              var foundIndex = this.menuPages.findIndex(
                (x) => x.id == this.menuPageForm.id
              )
              this.menuPages[foundIndex] = res.data
            } else {
              this.menuPages.push({
                name: res.data.name,
                id: res.data.id,
                screen_size: this.getDefualtSize(),
                category_color: res.data.category_color,
              })

              this.layout[res.data.id] = JSON.parse(
                JSON.stringify(this.getGridItems())
              )
            }

            this.activeMenuPage = res.data.id
            this.menuPageForm = { screen_size: this.getDefualtSize() }
            this.showTabModal = false

            // display created tabs in left pane
          })
        }
      })
    },
    // ----------------------------------------------------------------------

    conditionFlagGreen(itemId, type) {
      if (this.selectFlagGreen.some((el) => el.id == itemId && el.type == type))
        return true
      else return false
    },

    dropDown() {
      this.checkOpen = !this.checkOpen
    },
    search() {
      const allItems = JSON.parse(JSON.stringify(this.items))
      // if (this.searchKey != '') {
      this.searched = allItems.filter((el) => {
        return el.name.search(new RegExp(this.searchKey, 'i')) != -1
      })
      // } else {
      //   this.searched = []
      // }
      const values = this.searched.map((item) => item.item_group)
      // Use Set to get unique values
      const openIds = [...new Set(values)]

      if (this.searchKey != '') {
        this.mainCat.forEach(
          (item) => (item.checkOpen = openIds.includes(item.id))
        )
      } else {
        this.mainCat.forEach((item) => (item.checkOpen = false))
      }
    },

    showSort() {
      this.activateSort = !this.activateSort
    },
    setDefault(id) {
      if (this.layout[id] == undefined) {
        // this.layout[id] = JSON.parse(JSON.stringify(this.defaultLayout.grid))

        this.layout[id] = JSON.parse(JSON.stringify(this.getGridItems()))
      }
    },
    activateMenuPage(page) {
      this.activeMenuPage = page.id
      this.menuPage = JSON.parse(JSON.stringify(page))
      this.setDefault(page.id)

      // this.selectedMenuPage = this.selectedMenuPage == page.id ? '' : page.id
    },
    editMenuPage(page) {
      this.showTabModal = true
      this.menuPageForm = JSON.parse(JSON.stringify(page))
    },
    openMenuPage() {
      this.menuPageForm = { screen_size: this.getDefualtSize() }
      this.showTabModal = true
    },
    cancelMenuPage() {
      // this.menuPageForm = {}
      this.showTabModal = false
    },
    deleteMenuPage(id) {
      reusableService.showSwal('Delete').then((result) => {
        if (result.value) {
          customizeService.deleteMenuPage(id).then((res) => {
            let indexToDelete = this.menuPages.findIndex(
              (tableRow) => tableRow.id === id
            )
            if (indexToDelete >= 0) {
              this.menuPages.splice(indexToDelete, 1)
            }

            if (this.menuPage.id == id) {
              const prevMenuPage = this.menuPages[this.menuPages.length - 1]
              this.menuPage = prevMenuPage
              this.activeMenuPage = prevMenuPage.id
            }
          })
        }
      })
    },
    saveChanges(actionType, body = null) {
      let categoryArray = {}
      const screenId = this.$route.query.screen

      this.menuPages.forEach((el, index) => {
        categoryArray[el.id] = {
          sort_order: index + 1,
        }
      })

      const layoutData = actionType == 'new' ? this.layout : body
      const result = Object.entries(layoutData).flatMap(
        ([ipad_category_id, items]) =>
          items.map((item) => ({
            ...item,
            ipad_category_id,
            chain_id: this.chain_id,
            outlet_id: this.outlet_id,
            screen_id: screenId,
          }))
      )

      let data = {
        grid: [
          {
            chain_id: this.chain_id,
            outlet_id: this.outlet_id,
            screen_id: screenId,
            ipad: result,
            category: categoryArray,
          },
        ],
      }
      this.loader.saveChanges = true
      customizeService.saveScreen(data).then((res) => {
        if (actionType == 'edit') {
          this.layout = body
          this.clearSelecton()
        }
        this.loader.saveChanges = false

        var color = Math.floor(Math.random() * 4 + 1)
        this.$notify({
          message: 'Updated Successfully!',
          icon: 'add_alert',
          horizontalAlign: 'bottom',
          verticalAlign: 'right',
          type: 'notification-success',
        })

        this.btnDisabled = false
      })
    },

    checkMove(e) {
      return !e.dragged.classList.contains('non-draggable')

      // Prevent moving elements with the class 'non-draggable'
    },
    toggleMenuPageSelection(id) {
      if (id) {
        const index = this.selectedMenuPages.indexOf(id)
        if (index > -1) {
          // Item is already selected, remove it
          this.selectedMenuPages.splice(index, 1)
        } else {
          // Item is not selected, add it
          this.selectedMenuPages.push(id)
        }
      }
    },
    addItemToGrid(item) {
      emptyBox = this.layout[this.activeMenuPage]
      var emptyBox = emptyBox.find(
        (el) => el.menu_item_id == null || el.menu_item_id == ''
      )
      emptyBox.menu_item_id = item.id
      emptyBox.item_name = item.name
      emptyBox.colour = item.item_color || '#fff'
      emptyBox.price = item.price.toFixed(2)
    },

    updateScreen() {
      const screenId = this.$route.query.screen

      this.$validator.validateAll('screen').then((result) => {
        if (result) {
          customizeService
            .updateScreenMenu(this.screenMenu, screenId)
            .then((res) => {
              this.screen = res.data.screens
              this.screenMenuStatus = res.data.screens.status
              this.$notify({
                message: 'Updated Successfully!',
                icon: 'add_alert',
                horizontalAlign: 'top',
                verticalAlign: 'right',
                type: 'notification-success',
              })
              this.editScreenStatus = false
            })
        }
      })
    },
  },
  computed: {
    activeMenuPageGrid: function () {
      return this.layout[this.activeMenuPage]
    },
    multiSelected() {
      return Object.values(store.state.screen.allSelectedMenus).flat().length
    },
    isTillEnabled() {
      return this.screenMenu.is_till && !this.screenMenu.is_handheld
    },
    // Always enable 4x4, since it's supported by both till and handheld
    isFourByFourEnabled() {
      return this.screenMenu.is_till || this.screenMenu.is_handheld
    },
    // Enable 8x8 only when "Till" is selected
    isEightByEightEnabled() {
      return this.screenMenu.is_till && !this.screenMenu.is_handheld
    },
  },

  created() {
    this.searched = this.items
    this.loader.ipad = true

    const p1 = new Promise((resolve, reject) => {
      this.loader.menuItems = true
      customizeService.getScreenMenuItems().then((res) => {
        let result = res.data.filter((item) => item.status == 'active')
        this.menu_items = result
        result.forEach((el) => {
          el.type = 'menu item'
          el.color = el.item_color || '#fff'
        })
        let items = result
        items.forEach((el) => {
          this.items.push(el)
        })
        this.loader.menuItems = false
        resolve()
      })
    })

    const p2 = new Promise((resolve, reject) => {
      this.loader.combos = true
      customizeService.getCombo().then((res) => {
        this.combos = res.data
        res.data.forEach((el) => {
          el.type = 'combo'
          if (el.combo_color != null) {
            el.color = el.combo_color
          } else {
            el.color = '#ffff'
          }
        })
        let combos = res.data
        combos.forEach((el) => {
          this.items.push(el)
        })
        this.loader.combos = false
        resolve()
      })
    })

    const p3 = new Promise((resolve, reject) => {
      this.loader.menuCat = true
      const screenId = this.$route.query.screen
      customizeService
        .geteMenuPages(this.outlet_id, screenId)
        .then((result) => {
          this.menuPages = result.data
          this.tabs.sort((a, b) => {
            return a.sort_order - b.sort_order
          })
          result.data.forEach((el) => {
            let tabObj = {
              id: el.id,
              name: el.name,
              color: el.category_color,
              type: 'tab',
            }
            this.items.push(tabObj)
          })
          this.loader.menuCat = false
          resolve()
        })
    })

    this.loader.mainCat = true
    customizeService.getMaincat().then((res) => {
      this.loader.mainCat = false
      const result = res.data.api_v1_main_category.filter(
        (el) => el.status == true
      )
      result.forEach((el) => {
        ;(el.type = 'main category'),
          (el.color = el.code_color),
          (el.checkOpen = false)
        this.mainCat.push(el)
      })
    })

    Promise.all([p2, p3, p1]).then(() => {
      customizeService
        .getGrid(this.outlet_id, this.$route.query.screen)
        .then((res) => {
          this.layout = res.data.result || {}
          if (this.menuPages && this.menuPages[0]) {
            this.activeMenuPage = this.menuPages[0].id
            this.menuPage = this.menuPages ? this.menuPages[0] : ''
            this.setDefault(this.menuPages[0].id)
          }

          this.screenMenu = res.data.screen
          this.screenMenuStatus = res.data.screen.status

          // this.assignGrid(res.data)
        })
    })
  },

  mounted() {},
}
</script>

<style lang="scss" scoped>
.md-list-item {
  .md-ripple {
    position: relative;
  }
}
.dot {
  padding-top: 2px;
  /*height: 10px;
    width: 10px;*/
  height: 7px;
  width: 7px;
  top: 47% !important;
  left: -2px !important;
  padding-top: 7px !important;
  border-radius: 50%;
  display: inline-block;
  background-color: #16b402b4;
}
.arrow {
  position: relative;
  height: 15px;
  width: 15px;
  // margin: auto;
  margin-right: 0.25rem;
  margin-top: auto;
  margin-bottom: auto;
}

.arrow::before,
.arrow::after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.5s;
}

.arrow::before {
  left: -5px;
  transform: rotate(-45deg);
  background-color: #fff;
}
.arrow::after {
  left: 5px;
  transform: rotate(45deg);
  background-color: #fff;
}
.open .arrow::before {
  left: -5px;
  transform: rotate(45deg);
}
.open .arrow::after {
  left: 5px;
  transform: rotate(-45deg);
}

.ipad {
  height: 600px;
  // background-image: url('../../../assets/images/ipad-fliped-bt-top.png');
  // background-repeat: no-repeat;
  // background-size: 900px 700px;
  /* border: 1px;
  border-style: groove; */
  padding: 0px !important;
  background: #20586a;
  padding: 15px !important;
  border-radius: 0px;
}

.ipad-workspace {
  // padding-left: 50px;
  // padding-top: 10%;
  // padding-right: 50px;
  // padding-bottom: 10%;
  height: 100%;
  /* margin-right: 186px; */
  width: 100%;

  .tab-section {
    background-color: #49e0db;
    height: 66px;
    width: 100%;
    display: flex;
    justify-content: center;
    .fa-plus-circle {
      font-size: 35px;
      padding: 13px;
      color: white;
      padding-left: 0px;
    }

    .tabs {
      margin-left: 35px;
      display: flex;
    }
  }

  .item-section {
    height: 62%;
    width: 100%;
    /*overflow-y: scroll;*/
    margin-top: 2px;
    overflow: auto;
  }

  .items {
    display: flex;
    /*margin-left: 150px;*/
    margin-left: 120px;
    justify-content: center;
    // @media (min-width: 1200px) and (max-width: 1400px){
    //     margin-left: 57px;
    // }
    .item {
      margin-right: 2px;
      margin-bottom: 2px;
      /*height: 60px;
        width: 98px;*/
      height: 70px;
      width: 126.8px;
      position: relative;
      @media (min-width: 1200px) and (max-width: 1400px) {
        width: 115.8px;
      }
      .item-name {
        font-size: 12px;
      }

      .item-name-grid {
        position: absolute;
        bottom: 0;
        padding: 5px;
        color: #000;
      }

      .close-btn {
        position: absolute;
        right: 0;
        padding-right: 5px;
      }
    }
  }
}

.list-group {
  display: flex;
  flex-wrap: wrap;
  max-width: 535px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.fa-angle-double-right {
  margin: auto;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  color: #fff;
  font-size: 30px;
  // margin-left: 2%;
}

.fa-angle-double-right:hover {
  color: #49e0db;
}
.fa-times-circle:before {
  color: #ff0000;
}
.add-remove {
  width: 520px;
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 430px;
  }
  .draggable {
    background: white;
  }
}

.form__field {
  position: absolute;
  right: 0px;
  top: 5px;
}

.items-list {
  height: 600px;
  overflow-y: scroll;
}

.sort-section {
  margin-top: 40px;
  color: white;
  background-color: transparent;
  display: flex;
}

.sort-btn {
  background: #49e0db;
  margin: 0 0 5px 0;
  width: 15%;
  text-align: center;
  border-radius: 2px;
  color: #000;
}

.sort-order {
  display: flex;
  padding: 5px;
}

.sort-option {
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  background: #434343;
  margin-left: 5px;
  font-size: 10px;
}

.current-sort {
  background: #237dbc;
}

.header-content {
  display: flex;
}

.borderactive {
  border-bottom-style: solid;
  border-bottom-color: #ff1744;
  border-bottom-width: 6px;
}

.item-color {
  float: right;
  width: 32px;
  margin-left: 5px;
  // margin-right: 16px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
}

.colors-popup {
  position: absolute;
  /*left: 400px;*/
  left: 359px;
  z-index: 999;
  height: 100px;

  .fa-window-close {
    z-index: 999;
    color: #ec3232;
    position: absolute;
    left: 196px;
    font-size: 19px;
  }
}

.tab-item {
  border-bottom-style: solid;
  border-bottom-width: 27px;
  background-color: white;
}

.item-header {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
  cursor: pointer;
  padding: 10px 0 5px;
}

.main-cat {
  display: flex;
  padding-left: 14px;
  background: transparent;

  .cat-color {
    margin-left: auto;
    margin-right: 15px;
    margin-top: 5px;
  }
}
.ipdgrd-sidebar .main-cat .arrow::before {
  background-color: #fff;
}
.ipdgrd-sidebar .main-cat .arrow::after {
  background-color: #fff;
}
.main-cat.open {
  background-color: #47dbd5;
}
.main-cat.open .main-cat-name {
  color: #000;
}
.main-cat.open .arrow::after {
  background-color: #000 !important;
}
.main-cat.open .arrow::before {
  background-color: #000 !important;
}
.main-cat-name {
  padding: 14px;
  color: #fff;
  width: 170px;
}

.submit-btn {
  text-align: center;
  padding-top: 20px;
}

.right-click-menu {
  position: absolute;
  color: #3d4858;
  background: #fff;
  border-radius: 7px;
  border: solid 1px #4e92c3;
}

.right-click-menu-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: 12px;
  li {
    padding: 6px;
    border-bottom: solid 1px #4e92c3;
    cursor: pointer;
  }
}
.ipdgrd-sidebar {
  .main-cat {
    .main-cat-name {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    .md-checkbox {
      &.main-catgory-chckbox {
        margin-top: 5px;
        margin-bottom: 0px;
        .md-checkbox {
          border: 1px solid #ff1744 !important;
          &:before {
            height: 20px;
          }
        }
      }
    }
    .arrow {
      width: 10px;
      height: 10px;
      &::before {
        left: -3px;
        background-color: #fff;
      }
      &::after {
        left: 3px;
        background-color: #fff;
      }
    }
  }
  .md-list {
    padding: 1px 0;
  }
}
.ipad-workspace {
  padding-left: 25px;
  padding-right: 25px;
}
@media (min-width: 1750px) {
  .ipad-grid .ipad {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
<style>
.md-checkbox .md-checkbox-container {
  background: #ffffff;
}
.ipad-workspace .slick-next {
  right: -32px;
}
.sub-cat-name {
  width: 100%;
}
.ipad-grid .vue-swatches__container {
  box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
}
.vue-swatches__wrapper {
  padding: 13px;
}
.vue-swatches__swatch {
  margin: 7px !important;
}
.vue-swatches__swatch:nth-child(4n + 4) {
  margin-right: 0 !important;
}
.vue-swatches__swatch:nth-child(4n + 1) {
  margin-left: 0 !important;
}
.tab-input {
  width: 100%;
}

/* main {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: system-ui;
}

section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  justify-items: center;
}

section h1 {
  grid-column: span 4;
  text-align: center;
  font-size: 2em;
  margin: 0;
}

section article {
  display: contents;
}

section article .available_zone {
  position: relative;
  border: 0.25rem dotted #dddddd50;
  padding: 0.2rem;
  height: 5rem;
  width: 5rem;
}

section article .available_zone::before {
  content: attr(number);
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: min-content;
  height: min-content;
  z-index: 1;
  color: #dddddd50;
  font-size: 2em;
}

section article .available_zone .box {
  position: sticky;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #de7300;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.35s ease-in-out;
}

section article .available_zone .box.dragstart {
  border-radius: 5rem;
}

section article .available_zone .box.hide {
  opacity: 0;
} */

main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: system-ui;
}

/* section div.screen-listing {
  flex-direction: row !important;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 5px;
  width: 100%;
  margin-top: 1px;
} */
.screen-listing {
  padding: 5px;
}
.external-list {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.external-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  background-color: #de7300;
  color: white;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.35s ease-in-out;
}

.external-box.dragstart {
  border-radius: 5rem;
}

.external-box.hide {
  opacity: 0;
}

section h1 {
  text-align: center;
  font-size: 2em;
  margin: 0;
}

section article {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* Change 4 to 8 */
  gap: 1rem;
  justify-items: center;
}

section article.available_zone {
  position: relative;
  /* border: 0.25rem dotted #dddddd50; */
  border: none;
  padding: 0.2rem;
  height: 3.5rem;
  /* width: 5rem; */
  background-color: #1f4f60;
  border-radius: 5px;
}
.grid-four-columns article.available_zone {
  height: 7rem;
}
section article.available_zone::before {
  content: attr(number);
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: min-content;
  height: min-content;
  z-index: 1;
  color: #dddddd50;
  font-size: 2em;
  opacity: 0;
}

section article.available_zone .box {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #de7300;
  width: 100%;
  height: 3.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.35s ease-in-out;
  font-size: 12px;
  text-align: center;
  line-height: 14px;
}
.grid-four-columns article.available_zone .box {
  height: 7rem;
}
section article.available_zone .box.dragstart {
  opacity: 0.5;
  -webkit-box-shadow: -1px 0px 6px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: -1px 0px 6px 5px rgba(255, 255, 255, 1);
  box-shadow: -1px 0px 6px 5px rgba(255, 255, 255, 1);
}

section article.available_zone .box.hide {
  opacity: 0;
}
.available_zone {
  /* flex: 1 1 calc(12.5% - 1rem); /* 12.5% width minus the gap to ensure 8 items per row */
  /*max-width: calc(12.5% - 1rem); */
  box-sizing: border-box;
  position: relative;
  border: 0.25rem dotted #dddddd50;
  padding: 0.2rem;
  height: 5rem;
}

.available_zone::before {
  content: attr(number);
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: min-content;
  height: min-content;
  z-index: 1;
  color: #dddddd50;
  font-size: 2em;
}

.box {
  position: sticky;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #de7300;
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.35s ease-in-out;
}
.category-head {
  background-color: #e84b3d85;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 7px;
  text-align: center;
  padding-bottom: 7px;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}
.category-add-btn {
  border: 3px solid #e84b3d;
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  text-align: center;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: 300;
  max-width: 125px;
  cursor: pointer;
}
.category-add-btn i {
  background-color: #163b46;
  border-radius: 50px;
  padding: 7px;
  font-size: 14px;
  margin-right: 8px;
}
.top-btn-holder {
  background-color: #20586a;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.swatches-holder {
  background: linear-gradient(
    to bottom right,
    rgba(201, 201, 201, 0.8),
    rgba(196, 196, 196, 0.25)
  );
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  border-radius: 25px;
  width: 270px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  right: -300px;
  top: 90px;
  z-index: 9999;
  min-height: 525px;
}
.swatches-holder .vue-swatches {
  width: 100% !important;
  margin-left: 0 !important;
}
.swatches-holder .vue-swatches .vue-swatches__container {
  margin: auto;
  background: transparent !important;
  box-shadow: none !important;
}
.swatches-holder h3 {
  text-align: center;
}
.tools-top-div {
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.tools-top-div .head-span {
  font-size: 30px;
  font-weight: 600;
}
.ipad {
  width: 100%;
  margin-right: 15px;
}
.ipdgrd-sidebar .md-list {
  background-color: transparent !important;
}
.items-list[data-v-25820c7a] {
  overflow-y: auto !important;
}
.menu-itm-holder {
  background: linear-gradient(
    to bottom right,
    rgba(201, 201, 201, 0.8),
    rgba(196, 196, 196, 0.25)
  );
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 10px;
}
.items .md-list-item-content {
  min-height: 29px !important;
  padding-top: 5px !important;
  padding: 5px 5px !important;
}
.sub-cat-name {
  color: #fff;
}
.ipd-edit-btn {
  background-color: #a9dfd8 !important;
  width: 130px;
  margin-right: 15px;
  height: 40px;
}
.ipd-edit-btn .md-button-content {
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
}
#ipd-edit-btn .md-button-content i {
  color: #000 !important;
}
.ipd-width-btn {
  background-color: #a9dfd8 !important;
  width: 130px;
  margin-right: 15px;
  height: 40px;
}
.ipd-width-btn .md-button-content {
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
}
#ipd-width-btn .md-button-content i {
  color: #000 !important;
}
.ipd-save-btn {
  background-color: #49dfdb !important;
  width: 130px;
  margin-right: 15px;
  height: 40px;
}
.ipd-save-btn .md-button-content {
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
}
.ipd-cancel-btn {
  background-color: #999999 !important;
  width: 130px;
  margin-right: 15px;
  height: 40px;
}
#ipd-cancel-btn .md-button-content i {
  color: #fff !important;
}
#ipd-save-btn .md-button-content i {
  color: #000 !important;
}
.ipd-cancel-btn .md-button-content {
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
}
.swatches-save-btn {
  background-color: #49dfdb !important;
  width: 115px;
  height: 35px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 25px;
  text-transform: capitalize;
}
#swatches-save-btn i {
  color: #000 !important;
}
.swatches-save-btn .md-button-content {
  color: #000;
  font-weight: 300;
  text-transform: capitalize;
  font-size: 14px;
  max-height: 18px;
}
.swatches-cancel-btn {
  background-color: #999999 !important;
  width: 115px;
  height: 35px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 25px;
}
.swatches-cancel-btn .md-button-content {
  color: #fff;
  font-weight: 300;
  text-transform: capitalize;
  font-size: 14px;
  max-height: 18px;
}
.swatches-btn-holder {
  display: flex;
}
.page-top-tools {
  display: flex;
  padding-top: 15px;
  padding-bottom: 30px;
  justify-content: space-between;
}
.items ul {
  margin-left: 10px;
}
.swatches-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swatches-name {
  font-size: 18px;
  font-weight: 600;
  max-width: 160px;
  z-index: 9;
}
.swatches-price {
  font-size: 18px;
  font-weight: 600;
  z-index: 9;
  text-align: right;
  margin-left: auto;
  margin-bottom: 5px;
  margin-top: 0px;
}
.swatches-edit {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  padding-top: 10px;
}
.swatches-edit-btn {
  position: absolute !important;
  top: -10px;
  right: -10px;
  background-color: transparent !important;
}
.swatches-edit-btn:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}
.swatches-edit-btn:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.swatches-edit-btn:active {
  background-color: transparent !important;
  box-shadow: none !important;
}
.swatches-edit-btn:active:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.swatches-edit-btn .md-ripple .md-button-content i:hover {
  color: aqua !important;
  scale: 1.3;
}
.sidepanel-animate {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(-310px);
    transform: translateX(-310px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-310px);
    transform: translateX(-310px);
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

section article.available_zone.zone-selected {
  box-shadow: 0 0 0px 3px #49dfdb !important;
}
.add-item-modal .md-dialog-container {
  min-height: 677px;
  max-width: 360px;
  border-radius: 15px;
  background: linear-gradient(
    to bottom right,
    rgba(201, 201, 201, 0.8),
    rgba(196, 196, 196, 0.25)
  );
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  top: 50% !important;
  transform: translateY(-20%) !important;
}
.add-item-modal .md-card {
  overflow: hidden;
}
#add-item-modal .md-dialog-container.md-theme-default {
  background-color: unset !important;
}
.add-item-title {
  font-weight: 600 !important;
  padding-left: 15px;
  line-height: 0px;
}
.add-item-modal .md-layout-item {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.add-item-modal .vue-swatches {
  width: 236px !important;
  margin-top: 15px;
}
.add-item-cancel-btn {
  background-color: #999999 !important;
  width: 120px;
  margin-left: 10px;
  margin-top: 5px;
}
.add-item-cancel-btn .md-button-content {
  color: #fff;
  font-weight: 300;
  text-transform: capitalize;
  font-size: 16px;
  max-height: 18px;
}
.add-item-save-btn {
  background-color: #49dfdb !important;
  width: 120px;
  margin-right: 10px;
  margin-top: 5px;
}
.add-item-save-btn .md-button-content {
  color: #000;
  font-weight: 300;
  text-transform: capitalize;
  font-size: 16px;
  max-height: 18px;
}
.add-item-btn-holder {
  display: flex;
  justify-content: space-evenly;
}
.ipdgrd-sidebar::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.ipdgrd-sidebar::-webkit-scrollbar-track {
  background: transparent; /* Track color */
}

.ipdgrd-sidebar::-webkit-scrollbar-thumb {
  background: #ffffffba; /* Thumb color */
  border-radius: 15px; /* Round corners */
}

.ipdgrd-sidebar::-webkit-scrollbar-thumb:hover {
  background: #000; /* Thumb color on hover */
}

/* For Firefox */
.ipdgrd-sidebar {
  scrollbar-width: thin; /* Make scrollbar thin */
  scrollbar-color: #ffffffba #ffffff00; /* Thumb and track colors */
}
.drag-holder {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.swatches-open {
  width: 40px;
  min-width: 40px;
  position: absolute;
  left: -40px;
  top: 50%;
  background-color: #49dfdb !important;
}
.swatches-open:hover {
  background-color: #49dfdb !important;
}
.swatches-open:focus {
  background-color: #49dfdb !important;
}
#swatches-open .md-button-content i {
  font-size: 28px !important;
  color: #000 !important;
}
#swatches-open .md-ripple {
  padding: 12px 20px;
}
html {
  overflow-y: overlay;
}
body {
  overflow-y: overlay;
  overflow-x: hidden;
}
.top-edit-icn {
  background-color: #2983a3;
  padding: 15px;
  font-size: 22px !important;
  border-radius: 5px;
  width: 35px;
  height: 10px;
  margin-right: 10px;
}
.top-edit-span {
  display: flex;
  align-items: center;
}
.sidepanel-animate .swatches-open {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
  animation: rotate-center 0.6s ease-in-out both;
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.cat-hd-delete {
  width: 18px;
  height: 18px;
  min-width: unset !important;
  background-color: transparent !important;
  position: absolute;
  top: -4px;
  left: 15px;
  border-radius: 0px !important;
  z-index: 9;
  box-shadow: none !important;
}
#cat-hd-delete .md-ripple {
  padding: 4px 0px !important;
}
#cat-hd-delete .md-ripple .md-button-content i {
  font-size: 15px !important;
  font-weight: 600;
  color: #fff !important;
  height: 10px !important;
}
#cat-hd-delete:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}
#cat-hd-delete:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
#cat-hd-delete:active {
  background-color: transparent !important;
  box-shadow: none !important;
}
#cat-hd-delete .md-ripple .md-button-content i:hover {
  color: aqua !important;
  scale: 1.3;
}
.cat-hd-edit {
  width: 18px;
  height: 18px;
  min-width: unset !important;
  background-color: transparent !important;
  position: absolute;
  top: -5px;
  right: 2px;
  border-radius: 0px !important;
  z-index: 9;
  box-shadow: none !important;
}
#cat-hd-edit .md-ripple {
  padding: 4px 0px !important;
}
#cat-hd-edit .md-ripple .md-button-content i {
  font-size: 15px !important;
  font-weight: 600;
  color: #fff !important;
  height: 10px !important;
}
#cat-hd-edit:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}
#cat-hd-edit:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
#cat-hd-edit:active {
  background-color: transparent !important;
  box-shadow: none !important;
}
#cat-hd-edit .md-ripple .md-button-content i:hover {
  color: aqua !important;
  scale: 1.3;
}
.title-active {
  box-shadow: 0 0 0px 3px #49dfdb;
}
.swatches-closed {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.available_zone .box::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0000005d;
  z-index: -1;
  border-radius: 4px;
}
.category-head-holder::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0000005d; /* Red with 50% transparency */
  z-index: 0; /* Place the pseudo-element behind the text */
  border-radius: 4px; /* Optional: rounded corners */
}
#add-swatches .vue-swatches__container {
  background: #00000008 !important;
  border-radius: 15px;
  margin-bottom: 15px;
}
.alert-notification-success {
  background-color: #247d68 !important;
  width: 20% !important;
}
.alert-notification-success .material-icons {
  display: none !important;
}
.alert-notification-success span {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}
.loader-custom {
  width: 90%;
  position: absolute;
  z-index: 999;
  margin: auto;
  left: -1px;
  right: 0;
  background-color: #86969675;
  height: 38px;
  border-radius: 5px;
  margin-top: 4px;
}
.loader-custom .v-clip {
  border-width: 5px !important;
  border-style: dashed !important;
}
.loader-custom-top {
  width: 130px;
  position: absolute;
  z-index: 999;
  margin: auto;
  left: -14px;
  right: 0;
  top: 4px;
  background-color: #f0ffffa6;
  height: 40px;
  border-radius: 5px;
}
.loader-custom-top .v-clip {
  border-width: 5px !important;
  border-style: dashed !important;
}
.swatches-edit::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0000002b;
  z-index: 0;
  border-radius: 4px;
}
.search-result {
  position: absolute;
  z-index: 99;
  top: 58px;
  width: 90%;
  max-height: 135px;
  overflow: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
#search-result.search-result {
  background-color: #20586a !important;
}

#search-result .md-list-item {
  height: 25px !important;
}
#search-result .md-list-item-content {
  min-height: 25px !important;
}
#search-result .md-list-item .md-list-item-container .md-ripple {
  padding-top: 5px !important;
}
#status-holder i {
  color: green !important;
  background-color: green;
  border-radius: 50%;
  width: 15px !important;
  height: 15px !important;
  font-size: 20px !important;
  margin-right: 5px;
  min-width: 15px !important;
  margin-bottom: 2px !important;
}
#status-holder.status-holder-inactive i {
  color: rgb(255, 0, 0) !important;
  background-color: rgb(255, 0, 0);
}
#status-holder p {
  font-size: 16px;
  font-weight: 600;
}
.screen-save {
  background-color: #49dfdb !important;
  height: 35px !important;
  width: 35px !important;
  min-width: 35px !important;
  margin-right: 10px;
}
#screen-save i {
  color: #000 !important;
}
.screen-cancel {
  background-color: #c0382a !important;
  height: 35px !important;
  width: 35px !important;
  min-width: 35px !important;
}
.screen-grid .slick-dots {
  bottom: -50px;
}
.screen-grid li button:before {
  display: none;
}
.screen-grid li button {
  font-size: 14px;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
}
.screen-grid li {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  opacity: 0.5;
}
.screen-grid li.slick-active {
  opacity: 1;
}
.screen-delete {
  position: absolute;
  right: 5px;
  left: 0;
  margin-left: auto;
  top: 11px;
  width: 25px;
}
.screen-delete .md-ripple .md-button-content i {
  font-size: 27px !important;
  font-weight: 600;
  color: #ed6565 !important;
  height: 10px !important;
}
.screen-delete .md-ripple .md-button-content i:hover {
  color: aqua !important;
  scale: 1 !important;
}
#drop-dwn-btn .md-list-item-button:hover {
  background-color: #15b0ac !important;
}
</style>
