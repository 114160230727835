import { render, staticRenderFns } from "./ModifiersReport.vue?vue&type=template&id=051f054c&scoped=true&"
import script from "./ModifiersReport.vue?vue&type=script&lang=js&"
export * from "./ModifiersReport.vue?vue&type=script&lang=js&"
import style0 from "./ModifiersReport.vue?vue&type=style&index=0&id=051f054c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051f054c",
  null
  
)

export default component.exports