<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/products/screen-design/menu-list' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ formType }}</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType == 'View Station'"
        >
          <i
            class="md-icon md-icon-font md-theme-default edit-img"
            @click="editSupercat"
            >edit</i
          >
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Name') },
                { 'md-valid': !errors.has('Name') && touched.Name },
              ]"
            >
              <label>{{ $t('Name') }}</label>
              <md-input
                v-model="screen.name"
                data-vv-name="Name"
                type="text"
                v-validate="modelValidations.Name"
                :disabled="formType == 'View Station'"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Name')">
                  {{ errors.first('Name') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Name') && touched.Name"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <div
              v-if="
                userType != 'branch_user' &&
                $store.state.account.profile.has_access
              "
            >
              <label>{{ $t('Is Default ?') }}</label>
              <md-checkbox v-model="screen.is_default"> </md-checkbox>
            </div>
          </div>
          <div class="md-layout-item md-size-20">
            <!-- <md-field> -->
            <md-checkbox
              v-model="screen.is_handheld"
              :disabled="
                formType == 'Edit Menu' && !screen.is_handheld && screen.is_till
              "
              v-validate="{
                required: !screen.is_till,
                at_least_one_selected: { screen },
              }"
              data-vv-name="handheld"
            >
            </md-checkbox>
            <label>{{ $t('Handheld ?') }}</label>

            <!-- </md-field> -->
          </div>
          <div class="md-layout-item md-size-50">
            <!-- <md-field> -->
            <md-checkbox
              v-model="screen.is_till"
              :disabled="
                formType == 'Edit Menu' && !screen.is_handheld && screen.is_till
              "
              v-validate="{
                required: !screen.is_handheld,
                at_least_one_selected: { screen },
              }"
              data-vv-name="till"
            >
            </md-checkbox>
            <label>{{ $t('Till ?') }}</label>

            <!-- </md-field> -->
          </div>
          <div
            class="error md-layout-item md-size-100 pb-10"
            v-if="errors.has('till') || errors.has('handheld')"
          >
            <!-- {{ errors.first('till') || errors.first('tilhandheldl') }} -->
            At least one option must be selected.
          </div>
          <div
            class="alert alert-custom alert-with-icon md-layout-item md-size-50"
            data-notify="container"
            v-if="
              formType == 'Edit Menu' && !screen.is_handheld && screen.is_till
            "
          >
            <i data-notify="icon" class="material-icons">add_alert</i>
            <span data-notify="message"
              >The menu page configuration is not compatible with
              handheld.</span
            >
          </div>
          <!-- <div class="md-layout-item md-size-50">
            <div class="md-layout color-picker-layout">
              <div class="md-layout-item">
                <div class="form__field">
                  <div class="form__input">
                    <label class="form__label">{{ $t('Color') }}</label>
                    <swatches
                      v-model="screen.colour"
                      :colors="'text-advanced'"
                      shapes="circles"
                      popover-to="center"
                    ></swatches>

                    <i
                      class="md-icon md-icon-font md-icon-image md-theme-default"
                    >
                      <svg
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 10l5 5 5-5z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="md-layout"></div>
        <div class="md-layout"></div>
        <div class="md-layout" v-if="screen.name == 'Receipt'"></div>
        <div class="md-layout" v-if="screen.name == 'Receipt'"></div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Status') }}</label>
              <md-select
                v-model="status"
                :disabled="formType == 'View Station'"
              >
                <md-option class="first-opt" disabled>Status</md-option>
                <md-option value="active">{{ $t('Active') }}</md-option>
                <md-option value="inactive">{{ $t('Inactive') }}</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div" v-if="formType !== 'View Station'">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/products/screen-design/menu-list' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              @click="validate"
              class="md-primary"
              :disabled="!$store.state.account.profile.has_access"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
          <!-- <button>CANCEL</button>
            <button>SAVE</button>-->
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import axios from 'axios'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Swatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import '../../../extendedValidations'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
    Swatches,
  },
  props: {},
  data() {
    return {
      status: '',
      copyCount: '',
      switch2: null,
      Name: '',
      touched: {
        Name: false,
        copyCount: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
        copyCount: {
          required: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Client',
      },
      clientDetail: {
        default: '',
      },
      screen: {
        outlet_id: '',
        chain_id: '',
        status: true,
      },
      loader: false,
      printers: [],
      userType: accountService.userRole(),
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Custom'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.createScreen()
          }
        })
      } else if (value == 'Custom') {
        this.screen.status = this.status == 'active'

        let key = ''
        if (
          !this.screen.status &&
          this.screen.screen_outlet_links_attributes &&
          this.screen.screen_outlet_links_attributes.some(
            (item) => item.is_default == true
          )
        ) {
          key = 'screenMesages'
        }
        reusableService.showSwal(value, key).then((result) => {
          if (result.value) {
            self.createScreen()
          }
        })
      }
    },

    createScreen() {
      let self = this
      let outlet_id = accountService.getOutletId()
      this.screen.outlet_id = outlet_id
      this.screen.chain_id = accountService.getChainId()

      if (
        !this.screen.status &&
        this.screen.screen_outlet_links_attributes &&
        this.screen.screen_outlet_links_attributes.some(
          (item) => item.is_default == true
        )
      ) {
        this.screen.screen_outlet_links_attributes = []
      }

      if (this.formType == 'Add Menu') {
        customizeService
          .createScreenMenu(this.screen)
          .then((res) => {
            self.$router.push({ name: 'ScreenMenus' })
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        let body = {
          screens: this.screen,
        }
        customizeService.updateScreenMenu(this.screen, this.url).then((res) => {
          this.screen = res.data.screens
          this.loader = false
          self.$router.push({ name: 'ScreenMenus' })
        })
      }
    },

    reset() {
      this.screen = {}
    },

    editSupercat() {
      this.formType = 'Edit Menu'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this

    if (this.formType != 'Add Menu') {
      this.loader = true
      // axios
      //   .get(
      //     this.url + '?chain_id=' + accountService.getChainId(),
      //     customizeService.authFun()
      //   )
      //   .then((res) => {
      //     this.loader = false
      //     this.screen = res.data.api_v1_screen
      //   })
      customizeService.getScreenMenu(this.url).then((res) => {
        this.screen = res.data.screens
        this.status = this.screen.status ? 'active' : 'inactive'
        this.loader = false
      })
    }
  },
  watch: {
    Name() {
      this.touched.required = true
    },
    copyCount() {
      this.touched.required = true
    },
    Status() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
.alert-custom {
  background-color: #1b333a;
}
.alert i {
  background-color: #49e0db;
}
.pb-10 {
  padding-bottom: 10px;
}
.error {
  color: red;
}
</style>
