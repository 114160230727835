<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-50 md-medium-size-100">
      <md-card class="block-bg">
        <md-card-header class="md-layout employe-detail">
          <div class="md-layout-item md-size-70">
            <span class="arrow_left">
              <router-link :to="{ path: '/outlet/staffs' }">
                <img src="../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h4 class="title">{{ formType }}</h4>
          </div>
          <div
            class="md-layout-item md-size-30"
            v-if="formType == 'View Staff'"
          >
            <i
              class="md-icon md-icon-font md-theme-default edit-img"
              @click="changeFormType()"
              >edit</i
            >
          </div>
        </md-card-header>

        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <md-card-content v-if="!loader">
          <div class="align-items-center d-flex justify-content-between mb-25">
            <h4 class="employ-login w-100 my-0">Employee Details</h4>
            <div
              class="md-layout-item"
              v-if="
                formType == 'Edit Staff' &&
                employee.employe.status == 'inactive'
              "
            >
              <md-button
                class="md-primary"
                native-type="submit"
                :disabled="employee.employe.anonymise"
                @click.native.prevent="anonymiseDate"
                >Anonymise Data</md-button
              >
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('Name') },
                  { 'md-valid': !errors.has('Name') && touched.Name },
                ]"
              >
                <label>Name</label>
                <md-input
                  data-vv-name="Name"
                  type="text"
                  v-validate="modelValidations.Name"
                  :disabled="formType == 'View Staff'"
                  v-model="employee.employe.name"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Name')">
                    {{ errors.first('Name') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Name') && touched.Name"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout md-size-50">
              <div class="md-layout-item md-size-80">
                <md-field
                  :class="[
                    { 'md-error': errors.has('Role') },
                    { 'md-valid': !errors.has('Role') && touched.Role },
                  ]"
                >
                  <label for="Country">Staff Role*</label>
                  <md-select
                    data-vv-name="Role"
                    v-validate="modelValidations.Role"
                    v-model="employee.employe.role_id"
                    v-on:input="updatePos()"
                    :disabled="formType == 'View Staff'"
                  >
                    <md-option class="first-opt" disabled>Staff Role</md-option>
                    <md-option
                      v-for="item in roles"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>

                  <slide-y-down-transition>
                    <div class="error" v-show="errors.has('Role')">
                      {{ errors.first('Role') }}
                    </div>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon
                      class="success"
                      v-show="!errors.has('Role') && touched.Role"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-10 add-role-btn"
                @click="showStaffRole = true"
              >
                <i
                  class="md-icon md-icon-font"
                  :disabled="formType === 'View MenuItems'"
                  >add</i
                >
              </div>
            </div>
          </div>

          <div class="md-layout">
            <!-- <div class="md-layout-item md-size-80">
              <md-field
                :class="[
                { 'md-error': errors.has('Role') },
                { 'md-valid': !errors.has('Role') && touched.Role }
              ]"
              >
                <label for="Country">Staff Role*</label>
                <md-select
                  data-vv-name="Role"
                  v-validate="modelValidations.Role"
                  v-model="employee.employe.employe_type"
                  v-on:input="updatePos()"
                  :disabled="formType=='View Staff'"
                >
                  <md-option v-for="item in roles" :key="item.id" :value="item.id">{{item.name}}</md-option>
                </md-select>
               
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Role')">{{errors.first("Role")}}</div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('Role') && touched.Role">done</md-icon>
                </slide-y-down-transition>
               
              </md-field>
            </div> -->

            <!-- <div class="md-layout-item md-size-20 add-role-btn" @click="showStaffRole=true">
              <i
                class="md-icon md-icon-font"
                :disabled="formType==='View MenuItems'"
              >add</i>
            </div> -->

            <div class="md-layout-item md-size-50">
              <md-field>
                <label for="Country">Rate</label>
                <md-input
                  :disabled="formType == 'View Staff'"
                  data-vv-name="Rate"
                  v-validate="modelValidations.Rate"
                  v-model="employee.employe.rate"
                ></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50">
              <md-field>
                <label for="Country">Contact Number</label>
                <md-input
                  :disabled="formType == 'View Staff'"
                  data-vv-name="Number"
                  v-validate="modelValidations.Number"
                  v-model="employee.employe.phone"
                ></md-input>
              </md-field>
            </div>
          </div>

          <h4 class="employ-login">Login Details</h4>

          <div class="md-layout">
            <!-- <div class="md-layout-item md-size-50">
              <md-field
                :class="[
                  { 'md-error': errors.has('Email') },
                  { 'md-valid': !errors.has('Email') && touched.Email },
                ]"
              >
                <label>Email Id*</label>
                <md-input
                  data-vv-name="Email"
                  type="email"
                  v-validate="modelValidations.Email"
                  :disabled="formType == 'View Staff'"
                  v-model="employee.user.user_name"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Email')">
                    {{ errors.first('Email') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Email') && touched.Email"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div> -->

            <div
              class="md-layout-item md-size-50"
              v-if="
                formType == 'Edit Staff' &&
                this.employee.employe.status != 'inactive'
              "
            >
              <label>Want To Reset Password ?*</label>
              <md-field>
                <md-select
                  v-model="changePassword"
                  :disabled="formType == 'View Staff'"
                >
                  <md-option value="Yes">Yes</md-option>
                  <md-option value="No">No</md-option>
                </md-select>
              </md-field>
            </div>

            <div
              class="md-layout-item md-size-50 mb-25 passwd"
              v-if="changePassword == 'Yes' || formType == 'Add Staff'"
            >
              <md-field
                :class="[
                  { 'md-error': errors.has('Password') },
                  { 'md-valid': !errors.has('Password') && touched.Password },
                ]"
              >
                <label>Password*</label>
                <md-input
                  data-vv-name="Password"
                  type="password"
                  v-validate="modelValidations.Password"
                  :disabled="formType == 'View Staff'"
                  v-model="employee.user.password"
                  autocomplete="new-password"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Password')">
                    {{ errors.first('Password') }}
                  </div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('Password') && touched.Password"
                    >done</md-icon
                  >
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <!-- <div class="md-layout">
            <div class="md-layout-item" v-if="formType == 'Edit Staff'">
              <md-field>
              <md-select
                v-model="changePassword"
                :disabled="formType == 'View Staff'"
              >
                <md-option value="Yes">Yes</md-option>
                <md-option value="No">No</md-option>
              </md-select>
              </md-field>
            </div>

            <div class="md-layout-item" v-if="changePassword=='Yes' || formType == 'Add Staff'">
              <md-field
                :class="[
                { 'md-error': errors.has('Password') },
                { 'md-valid': !errors.has('Password') && touched.Password }
              ]"
              >
                <label>Password*</label>
                <md-input
                  data-vv-name="Password"
                  type="password"
                  v-validate="modelValidations.Password"
                  :disabled="formType == 'View Staff'"
                  v-model="employee.user.password"
                ></md-input>
                <slide-y-down-transition>
                  <div class="error" v-show="errors.has('Password')">{{errors.first("Password")}}</div>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('Password') && touched.Password">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div> -->

          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <label for="Country">Status</label>
                <md-select
                  v-model="employee.user.status"
                  :disabled="formType == 'View Staff'"
                >
                  <md-option class="first-opt" disabled>Status</md-option>
                  <md-option value="inactive">Inactive</md-option>
                  <md-option value="active">Active</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <!-- ----------------------------------------------------------------------------------- -->
          <div class="btn-div cust-btn" v-if="formType !== 'View Staff'">
            <md-card-actions class="text-center">
              <md-button native-type="submit" :to="{ path: '/outlet/staffs' }"
                >CANCEL</md-button
              >
              <md-button
                class="md-primary"
                native-type="submit"
                :disabled="employee.employe.anonymise"
                @click.native.prevent="validate"
                >SAVE</md-button
              >
            </md-card-actions>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-50 md-medium-size-100">
      <md-card class="block-bg">
        <md-card-header class="md-layout">
          <div class="md-layout-item md-size-70">
            <h4 class="title">Roles</h4>
          </div>
        </md-card-header>
        <md-card-content class="md-layout">
          <div class="md-layout-item ul-style">
            <ul class="roles-list">
              <li
                v-for="(item, index) in pos.filter((it) => it.value == true)"
                :key="index"
              >
                <md-checkbox
                  v-model="item.value"
                  :disabled="true"
                  class="pos-res"
                  >{{ item.name }}</md-checkbox
                >
              </li>
            </ul>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <md-dialog :md-active.sync="showStaffRole">
      <manage-staff-role
        :formPop="'Add Staff Role'"
        @closeModal="closeModal"
      ></manage-staff-role>
    </md-dialog>
  </div>
</template>

<script>
import { customizeService } from './../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
import { SlideYDownTransition } from 'vue2-transitions'
import axios from 'axios'
import { Promise } from 'q'
import ManageStaffRole from './ManageStaffRole'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    SlideYDownTransition,
    ManageStaffRole,
    ClipLoader,
  },

  data() {
    return {
      formType: '',
      url: '',
      touched: {
        Name: false,
        Role: false,
        Email: false,
        Password: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
        Role: {
          required: true,
        },

        Email: {
          required: true,
        },
        Password: {
          required: true,
          length: 4,
          numeric: true,
        },
      },
      roles: [],
      pos: [],
      employee: {
        employe: {
          name: '',
          employe_type: '',
          rate: '',
          phone: '',
          outlet_id: accountService.getOutletId(),
        },
        user: {
          user_name: '',
          password: '',
          user_type: 'staff',
          distributor_id: accountService.getDistributorId(),
          status: 'active',
        },
      },
      changePassword: 'No',
      showStaffRole: false,

      staff_roles: {
        name: '',
        outlet_id: accountService.getOutletId(),
        role_json: {
          allow_access_all_saves: false,
          clock_in_out: false,
          clock_in_out_only: false,
          discount: false,
          discount_level: '',
          edit_clock_in_out: false,
          end_of_day_x: false,
          end_of_day_y: false,
          error_correct: false,
          finalise_sale: false,
          open_drawer: false,
          open_price: false,
          petty_cash: false,
          price_edit: false,
          reenter_count: null,
          refund: false,
          reports: false,
          sales_summary: false,
          sell_voucher: false,
          set_float: false,
          settings: false,
          status: 'active',
          sync: false,
          transfer: false,
          void_bill: false,
          void_item: false,
          wastage: false,
        },
      },
      loader: false,
    }
  },
  methods: {
    anonymiseStaff() {
      let body = { outlet_staff_id: this.employee.employe.outlet_staff_id }
      customizeService
        .anonymiseStaff(body)
        .then((res) => {
          console.log('Created staff', res.data)
          this.$router.push({ name: 'Staffs' })
        })
        .catch((err) => console.log('Staff creation failed'))
    },
    anonymiseDate() {
      var self = this
      reusableService.showSwal('Edit').then((result) => {
        if (result.value) {
          self.anonymiseStaff()
        }
      })
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid == true) {
          this.$emit('on-submit', this.alertModule('Add'), isValid)
        }
      })
    },

    alertModule(value) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.createStaff()
        }
      })
    },

    reset() {
      this.employee = {}
    },

    closeModal() {
      this.showStaffRole = false
    },

    createStaff() {
      if (this.formType == 'Add Staff') {
        this.employee.user.user_name =
          this.employee.employe.name.replace(/ /g, '').toLowerCase() +
          '@' +
          accountService.getOutletId() +
          '.com'
        customizeService
          .checkPinAvailability(this.employee.user.password)
          .then((resp) => {
            if (resp.data.success) {
              customizeService
                .createStaff(this.employee)
                .then((res) => {
                  console.log('Created staff', res.data)
                  this.$router.push({ name: 'Staffs' })
                })
                .catch((err) => console.log('Staff creation failed'))
            } else {
              Swal.fire('Pin already exists.')
            }
          })
      } else {
        let body = {
          employes: this.employee,
        }
        customizeService
          .checkPinAvailability(this.employee.user.password)
          .then((resp) => {
            if (resp.data.success) {
              axios
                .put(this.url, body, customizeService.authFun())
                .then((res) => {
                  console.log('Edited successfully')
                  this.$router.push({ name: 'Staffs' })
                })
            } else {
              Swal.fire('Pin already exists.')
            }
          })
      }
    },

    changeFormType() {
      this.formType = 'Edit Staff'
    },

    updatePos() {
      this.pos = []
      if (this.roles.length > 0) {
        let matchedpos = this.roles.find(
          (el) => el.id == this.employee.employe.role_id
        )
        for (var el in matchedpos.role_json) {
          this.pos.push({ name: el, value: matchedpos.role_json[el] })
        }
      }
    },

    createStaffRole() {},

    resetModal() {},
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    const p1 = new Promise((resolve, reject) => {
      customizeService.getStaffRoles().then((res) => {
        this.roles = res.data
        resolve(res.data)
      })
    })
    if (this.formType != 'Add Staff') {
      this.loader = true
      axios
        .get(
          this.url + '?outlet_id=' + accountService.getOutletId(),
          customizeService.authFun()
        )
        .then((res) => {
          this.loader = false
          this.employee.employe = res.data.employes
          this.employee.user = res.data.employes.user
          Promise.all([p1]).then((val) => {
            self.updatePos()
          })
        })
    }
  },
  watch: {
    Name() {
      this.touched.required = true
    },
    Role() {
      this.touched.required = true
    },
    Rate() {
      this.touched.required = true
    },
    Number() {
      this.touched.required = true
    },
    Email() {
      this.touched.required = true
    },
    Password() {
      this.touched.required = true
    },
  },
}
</script>

<style lang="scss" scoped>
$list-width: 100%;

.full-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.md-ripple > span {
  position: relative !important;
}

.list {
  width: $list-width;
}

.full-control > .md-list {
  width: $list-width;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.package-name {
  margin-top: 8%;
}

.text-center {
  justify-content: center !important;
}
.btn-add-module {
  text-align: center;
}
.arrow_left {
  float: left;
  padding-top: 5px !important;
}
.md-card .md-card-actions {
  border: none;
}

.add-role-btn {
  text-align: center;
  padding-top: 18px;
  cursor: pointer;
}

.roles-list {
  /*your fixed height*/
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.roles-list li {
  list-style: none;
}

.ul-style {
  ul {
    padding: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 480px;
    width: 100%;
  }
  li {
    width: 150px;
    height: 42px;
  }
}
.md-dialog {
  margin-left: 10%;
  margin-right: auto;
  width: 70%;
  max-width: 80%;
  max-height: 100%;
}

md-checkbox {
  opacity: 1;
}
.employe-detail {
  padding-left: 7px;
  .md-layout-item {
    padding-left: 0px;
  }
}
.employ-login {
  padding-left: 15px;
}
.md-card {
  .md-card-header {
    h4 {
      margin-top: 8px;
      margin-bottom: 3px;
    }
  }
}
.cust-btn {
  padding-top: 30px;
  padding-bottom: 25px;
}
.passwd .md-field .error {
  font-size: 12px;
}
.align-items-center {
  align-items: center;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
