import { environment } from './../../config'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import { accountService } from '../services/account.js'

var baseUrl = `${environment.api.cbePayHost}`
var dynamicFlg = false
var currentRequest = {}
// axios interceptors
// axios interceptors
const refreshToken = async () => {
  // const response = await axios.post("/refresh-token", {
  //   refreshToken: sessionStorage.getItem("refresh_token"),
  // });

  // const newAccessToken = response.data.access_token;
  // sessionStorage.setItem("access_token", newAccessToken);

  return accountService
    .generateToken()
    .then((res) => {
      const newAccessToken = res.data.token
      localStorage.setItem('token', newAccessToken)
      return newAccessToken
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}
const instance = axios.create({
  baseURL: baseUrl,
})

instance.interceptors.request.use((request) => {
  //console.log(request)
  return request
})

instance.interceptors.response.use(
  (response) => {
    //console.log('respppp', response)
    return response
  },
  async (err) => {
    if (err) {
      //console.log('errr11111', err)

      if (err.response && err.response.status === 401 && !err.config._retry) {
        err.config._retry = true

        // getAccessToken().then((res) => {
        //   console.log('New token', res)
        //   localStorage.setItem('access_token', res.data.access_token)
        //   localStorage.setItem('refresh_token', res.data.refresh_token)
        //   location.reload()
        // })
        try {
          const newAccessToken = await refreshToken()
          //console.log('Newwww', newAccessToken)
          err.config.headers.Authorization = `Bearer ${newAccessToken}`
          //console.log('errr', err.config)
          return instance(err.config)
        } catch (error) {
          return Promise.reject(error)
        }
      } else {
        throw err
      }
    }
  }
)

export const cbePayService = {
  saveCbePayData,
  getCbePayAccounts,
  getCbePayAccount,
  goToOnboard,
  getCapabilities,
  getPayments,
  getPayouts,
  getPayoutsReconciliation,
  getAlterationTime,
  getEnableCbyPayMenu,
  getPayoutReconciliationHeadoffice,
  getPaymentslistHo,
  getPayoutsHo
}

function authFun() {
  const auth = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  return auth
}

function saveCbePayData(userName) {
  let body = {
    user_name: userName,
  }
  return instance.post(
    baseUrl + `${environment.api.cbePay}/geneare_password_token`,
    body,
    authFun()
  )
}

function getCbePayAccounts() {
  return instance.get(baseUrl + `${environment.api.cbePay}`, authFun())
}

function getCbePayAccount(outletId) {
  return instance.get(
    baseUrl +
      `${
        environment.api.services.cbePay.onboarding
      }/outlet/${accountService.getOutletId()}`,
    authFun()
  )
}

function goToOnboard() {
  return instance.get(
    baseUrl +
      `${
        environment.api.services.cbePay.legalEntity
      }/cbypay_hosted_onboarding_link/${accountService.getOutletId()}`,
    authFun()
  )
}

function getCapabilities() {
  return instance.get(
    baseUrl +
      `${
        environment.api.services.cbePay.onboarding
      }/capabilities/${accountService.getOutletId()}`,
    authFun()
  )
}

function getOutletId(data) {
  if (accountService.userRole() == 'branch_user') {
    return data.outletId
  } else {
    return accountService.getOutletId()
  }
}

async function getPayments(query) {
  return await instance.get(
    baseUrl +
      `${environment.api.services.cbePay.payment}/${accountService.getOutletId()}` +
      '/' + query.search_type.replace(/ /g, '') +
      '/' + query.startDate +
      '/' + query.endDate +
      '/' + query.terminalId +
      '/' + query.pageSize +
      '/' + query.pageNumber,
    authFun()
  )
}

async function getPaymentslistHo(query) {
  if(getOutletId(query).length>0){
  return await instance.get(
    baseUrl +
      `${environment.api.services.cbePay.payment}/GetPaymentListHo`+
       '/'+ await getOutletId(query)+
      '/' + query.search_type.replace(/ /g, '') +
      '/' +query.startDate +
      '/' +query.endDate +
      '/' + query.terminalId +
      '/' + query.chainid +
      '/' + query.pageSize +
      '/' + query.pageNumber,
    authFun()
  )
  }
}

function getPayouts(query) {
  return instance.get(
    baseUrl +
      `${environment.api.services.cbePay.payout}/${accountService.getOutletId()}` +
      '/' +
      query.search_type.replace(/ /g, '') +
      '/' +
      query.startDate +
      '/' +
      query.endDate +
      '/' +
      query.terminalId ,
    authFun()
  )
}
async function getPayoutsHo(query) {
  if(getOutletId(query).length>0){
  return await instance.get(
    baseUrl +
      `${environment.api.services.cbePay.payout}/getReportPayoutHO`+
       '/'+ await getOutletId(query)+
      '/' + query.search_type.replace(/ /g, '') +
      '/' +query.startDate +
      '/' +query.endDate +
      '/' + query.terminalId +
      '/' + query.chainid ,
    authFun()
  )
  }
}
async function getPayoutsReconciliation(query) {
  return await instance.get(
    baseUrl +`${ environment.api.services.cbePay.payoutreconciliation
    }/${accountService.getOutletId()}` +
    '/' +query.search_type.replace(/ /g, '') +
    '/' +query.startDate +
    '/' +query.endDate,
    authFun()
  )
}

async function getPayoutReconciliationHeadoffice(queries) {
  if(getOutletId(queries).length>0){
    return await instance.get(
      baseUrl +
        `${environment.api.services.cbePay.payout}/getReportPayHO`+
        '/'+ getOutletId(queries) +
        '/' + queries.search_type.replace(/ /g, '') +
        '/' + queries.startDate +
        '/' +queries.endDate +
        '/' + queries.chainid,
      authFun()
    )
  }
}

async function getAlterationTime() {
  return await  instance.get(
    baseUrl +
      `${
        environment.api.services.cbePay.onboarding
      }/getAlterationTime/${accountService.getOutletId()}`,
    authFun()
  )
}

async function getEnableCbyPayMenu() {
  return await  instance.get(
    baseUrl +
      `${
        environment.api.services.cbePay.payment
      }/GetEnableCbePayList/${accountService.getChainId()}`,
    authFun()
  )
}


