<template>
  <div>
    <md-card class="itemised-trans block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Order Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout filter-section block-bg">
          <div class="md-layout search-options">
            <div class="md-layout-item">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>

            <div
              class="md-layout-item md-size-25"
              v-if="userRole == 'branch_user'"
            >
              <md-field>
                <label>Outlets</label>
                <md-select v-model="queries.outletId" multiple>
                  <md-option
                    v-for="item in outlets"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-size-15">
              <md-button
                class="md-teritiary"
                @click="itemisedTransactions(queries.billType)"
                >SEARCH</md-button
              >
            </div>
          </div>
        </div>
        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div class="md-layout" v-if="!loader">
          <div class="md-layout-item report-table">
            <div class="table-options d-flex justify-content-end"></div>

            <div>
              <!--  <md-table v-model="searched" class="paginated-table">
                <md-table-toolbar>
                  <md-field></md-field>
                  <md-field>
                    <label>{{ $t('Search') }}</label>
                    <md-input
                      type="search"
                      class="mb-3"
                      clearable
                      style="width: 400px;"
                      v-model="searchQuery"
                    ></md-input>
                    <i class="fa fa-search search-align" aria-hidden="true"></i>
                  </md-field>
                </md-table-toolbar>
                <md-table-row>
                  <md-table-head>Terminal</md-table-head>
                  <md-table-head>Order No.</md-table-head>
                  <md-table-head>Date</md-table-head>
                  <md-table-head>Type</md-table-head>
                  <md-table-head>Tables</md-table-head>
                  <md-table-head>Items</md-table-head>
                  <md-table-head>Staff</md-table-head>
                  <md-table-head>No.Of Guest</md-table-head>
                  <md-table-head>Cash</md-table-head>

                  <md-table-head>Credit</md-table-head>
                  <md-table-head>Other</md-table-head>
                  <md-table-head>Voucher</md-table-head>
                  <md-table-head>Total</md-table-head>
                  <md-table-head>Status</md-table-head>
                </md-table-row>

                <slot v-for="item in reports">
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label="Terminal">{{
                      item.terminal_name
                    }}</md-table-cell>
                    <md-table-cell md-label="Bill No.">{{
                      item.kot_no
                    }}</md-table-cell>
                    <md-table-cell md-label="Date">{{
                      item.bill_time
                    }}</md-table-cell>
                    <md-table-cell md-label="Type">{{
                      item.order_type
                    }}</md-table-cell>
                    <md-table-cell md-label="Tables"></md-table-cell>
                    <md-table-cell md-label="Items">
                      <div v-for="(sub, index) in item.items" :key="index">
                        {{ sub.item_name }} ({{ sub.quantity }})
                        <span v-if="index != item.items.length - 1">,</span>
                      </div>
                    </md-table-cell>
                    <md-table-cell md-label="Staff">{{
                      item.settled_by
                    }}</md-table-cell>
                    <md-table-cell md-label="No. of guest">{{
                      item.guest_count
                    }}</md-table-cell>
                    <md-table-cell md-label="Cash">{{
                      item.cash_rcvd
                    }}</md-table-cell>
                    <md-table-cell md-label="Credit">{{
                      item.credit_amt
                    }}</md-table-cell>
                    <md-table-cell md-label="Other">{{
                      item.other_amt
                    }}</md-table-cell>
                    <md-table-cell md-label="Voucher">{{
                      item.voucher_amt
                    }}</md-table-cell>
                    <md-table-cell md-label="Amount">{{
                      item.bill_total
                    }}</md-table-cell>
                    <md-table-cell md-label="Amount">{{
                      item.kot_status
                    }}</md-table-cell>
                  </md-table-row>
                </slot>
                <md-table-row>
                  <md-table-cell>Total</md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell>{{
                    totals.guest_count.toFixed(2)
                  }}</md-table-cell>
                  <md-table-cell>{{
                    totals.cash_rcvd.toFixed(2)
                  }}</md-table-cell>
                  <md-table-cell>{{
                    totals.credit_amt.toFixed(2)
                  }}</md-table-cell>
                  <md-table-cell>{{
                    totals.other_amt.toFixed(2)
                  }}</md-table-cell>
                  <md-table-cell>{{
                    totals.voucher_amt.toFixed(2)
                  }}</md-table-cell>
                  <md-table-cell>{{
                    totals.bill_total.toFixed(2)
                  }}</md-table-cell>
                </md-table-row>
              </md-table> -->
              <md-table
                v-model="reports"
                class="paginated-table itemised-txn table-typ1"
                :md-sort.sync="currentSort"
                :md-sort-order.sync="currentSortOrder"
                :md-sort-fn="customSort"
              >
                <md-table-toolbar class="justify-content-start">
                  <md-field>
                    <label>{{ $t('Search') }}</label>
                    <md-input
                      type="search"
                      class="mb-3"
                      clearable
                      style=""
                      v-model="searchQuery"
                    ></md-input>
                    <i class="fa fa-search search-align" aria-hidden="true"></i>
                  </md-field>
                  <div class="action-buttons export-btn">
                    <div class="pdf-el">
                      <div class="download-btn">
                        <img
                          src="../../../../assets/images/pdf.svg"
                          @click="downloadWithoutCSS()"
                        />
                      </div>
                    </div>

                    <div class="excel-el">
                      <vue-excel-xlsx
                        class="Excel-btn"
                        :data="reports"
                        :columns="columns"
                        :filename="'Itemized transaction Report'"
                        :sheetname="'Itemized Transaction Report'"
                        :outlet-names="selectedOutletsNames()"
                      >
                      </vue-excel-xlsx>
                    </div>
                  </div>
                </md-table-toolbar>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell
                    v-bind:md-label="$t('Terminal')"
                    md-sort-by="terminal_name"
                  >
                    <div>
                      {{ item.terminal_name }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Order No.')"
                    md-sort-by="kot_no"
                  >
                    <div>
                      {{ item.kot_no }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Date')"
                    md-sort-by="bill_time"
                  >
                    <div>
                      {{ item.bill_time }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Type')"
                    md-sort-by="order_type"
                  >
                    <div>
                      {{ item.order_type }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Tables')">
                    <div>
                      <div v-for="(sub, index) in item.tbl" :key="index">
                        {{ sub.name }}
                        <span v-if="index != item.tbl.length - 1">,</span>
                      </div>
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Items')">
                    <div v-for="(sub, index) in item.items" :key="index">
                      {{ sub.item_name }} ({{ sub.quantity }})
                      <span v-if="index != item.items.length - 1">,</span>
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Staff')" md-sort-by="stf">
                    <div>
                      {{ item.stf }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('No. of guest')">
                    <div>
                      {{ item.guest_count }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Cash')"
                    md-sort-by="cash_rcvd"
                  >
                    <div>
                      {{ item.cash_rcvd }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Credit')"
                    md-sort-by="credit_amt"
                  >
                    <div>
                      {{ item.credit_amt }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Other')">
                    <div>
                      {{ item.other_amt }}
                    </div>
                  </md-table-cell>
                  <md-table-cell v-bind:md-label="$t('Voucher')">
                    <div>
                      {{ item.voucher_amt }}
                    </div>
                  </md-table-cell>
                  <md-table-cell md-label="Amount">
                    {{ item.bill_total }}</md-table-cell
                  >
                </md-table-row>
              </md-table>
              <md-table class="footer-table pad-l-15px pad-r-15px">
                <md-table-row>
                  <md-table-cell>Total</md-table-cell>
                  <md-table-cell> </md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell></md-table-cell>
                  <md-table-cell>
                    {{ totals.guest_count.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell
                    >{{ totals.cash_rcvd.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.credit_amt.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.other_amt.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.voucher_amt.toFixed(2) }}
                  </md-table-cell>
                  <md-table-cell>
                    {{ totals.bill_total.toFixed(2) }}
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <md-dialog class="itemised-trans-bill" :md-active.sync="showBill">
      <div id="bill-detail">
        <itemised-bill
          :billDetails="billDetails"
          @downloadBill="downloadBill"
        ></itemised-bill>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { reportService } from './../../../../services/reports'
import { accountService } from '../../../../services/account.js'
import { customizeService } from './../../../../services/customize'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import datetime from 'vuejs-datetimepicker'
import DateTimePicker from '../../../../components/DateTimePicker'
import { Tabs } from '@/components'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
import ItemisedBill from './ItemisedBill'
import Fuse from 'fuse.js'

export default {
  components: {
    datetime,
    Tabs,
    DateTimePicker,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
    ItemisedBill,
  },
  data() {
    return {
      columns: [
        {
          label: 'Terminal',
          field: 'terminal_id',
        },
        {
          label: 'Bill no.',
          field: 'bil_no',
        },
        {
          label: 'Date',
          field: 'bill_date',
        },
        {
          label: 'Order Type',
          field: 'order_type',
        },
        {
          label: 'Number of Guests',
          field: 'guest_count',
        },
        {
          label: 'Cash',
          field: 'cash_rcvd',
        },
        {
          label: 'Credit',
          field: 'credit_amt',
        },
        {
          label: 'Other',
          field: 'other_amt',
        },
        {
          label: 'Voucher',
          field: 'voucher_amt',
        },
        {
          label: 'Bill Total',
          field: 'bill_total',
        },
        {
          label: 'Items',
          field: 'itemsString',
        },
        {
          label: 'Tables',
          field: 'tbl',
        },
        {
          label: 'Staff',
          field: 'stf',
        },
      ],
      columnStyles: {
        0: { columnWidth: 25 },
        1: { columnWidth: 20 },
        2: { columnWidth: 24 },
        3: { columnWidth: 20 },
        4: { columnWidth: 24 },
        5: { columnWidth: 20 },
        6: { columnWidth: 20 },
        7: { columnWidth: 21 },
        8: { columnWidth: 21 },
        9: { columnWidth: 24 },
        10: { columnWidth: 24 },
        11: { columnWidth: 20 },
        12: { columnWidth: 24 },
      },
      activeTab: 1,
      queries: {
        outletId: [],
        billType: 'Completed Bills',
        search_type: 'Daily',
      },
      search: '',
      searched: '',
      reports: [],
      terminals: [],
      selectedTerminal: String,
      outlets: [],
      userRole: '',
      loader: false,
      showBill: false,
      billDetails: {
        data: [],
        tax: [],
      },
      searchQuery: '',
      searchedData: '',
      currentSort: 'bill_time',
      currentSortOrder: 'desc',
    }
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

    searchQuery(value) {
      if (value !== '') this.searchInitiate()
      else this.reports = this.searched
    },
  },

  computed: {
    totals() {
      let totals = {}
      totals.guest_count = this.reports.reduce((prev, next) => {
        return prev + next.guest_count
      }, 0)
      totals.cash_rcvd = this.reports.reduce((prev, next) => {
        return prev + next.cash_rcvd
      }, 0)
      totals.credit_amt = this.reports.reduce((prev, next) => {
        return prev + next.credit_amt
      }, 0)
      totals.other_amt = this.reports.reduce((prev, next) => {
        return prev + next.other_amt
      }, 0)
      totals.voucher_amt = this.reports.reduce((prev, next) => {
        return prev + next.voucher_amt
      }, 0)
      totals.bill_total = this.reports.reduce((prev, next) => {
        return prev + next.bill_total
      }, 0)
      return totals
    },
  },

  methods: {
    customSort(value) {
      return this.searched.sort((a, b) => {
        const sortBy = this.currentSort

        if (this.currentSortOrder === 'desc') {
          return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, { numeric: true })
        }
        return a[sortBy]
          .toString()
          .localeCompare(b[sortBy].toString(), undefined, { numeric: true })
      })
    },
    fromDateTime(date) {
      this.queries.startDate = date
    },

    toDateTime(date) {
      this.queries.endDate = date
    },

    returnItemsAsStrings() {
      let reportString = []
      this.reports.forEach((el) => {
        let itemStringList = ''
        el.items.forEach((item) => {
          itemStringList =
            itemStringList + `${item.item_name}(${item.quantity}),`
        })
        el.itemsString = itemStringList
        reportString.push(el)
      })
    },

    arrangeForPdf() {
      var body = []
      this.reports.forEach((el) => {
        let items = []
        let tables = []
        if (el.items != null) {
          el.items.forEach((item) => {
            items.push(item.item_name + '(' + item.quantity + ')')
          })
        }
        if (el.tbl != null) {
          el.tbl.forEach((table) => {
            tables.push(table.name)
          })
        }

        let arrayObj = [
          el.terminal_id,
          el.kot_no,
          el.bill_time,
          el.order_type,
          tables,
          el.guest_count,
          el.cash_rcvd,
          el.credit_amt,
          el.other_amt,
          el.voucher_amt,
          el.bill_total,
          items,
          el.stf,
        ]
        body.push(arrayObj)
      })
      let totObj = [
        '',
        '',
        '',
        '',
        'Total',
        this.totals.guest_count.toFixed(2),
        this.totals.cash_rcvd.toFixed(2),
        this.totals.credit_amt.toFixed(2),
        this.totals.other_amt.toFixed(2),
        this.totals.voucher_amt.toFixed(2),
        this.totals.bill_total.toFixed(2),
        '',
        '',
        '',
      ]

      body.push(totObj)
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.queries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },

    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let dateText = ''
      let head = [
        [
          'Terminal',
          'Order #',
          'Date',
          'Type',
          'Tables',
          'guests',
          'Cash',
          'Credit',
          'Other',
          'Voucher',
          'Bill Tot',
          'Items',
          'Staff',
        ],
      ]

      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      let selectedOutlets = this.selectedOutletsNames()
      this.queries.startDate = this.sdate
      this.queries.endDate = this.edate
      pdfService.headerFooterConfig(
        this.queries,
        doc,
        'Order Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        this.columnStyles,
        'order-report.pdf'
      )
    },

    searchOnTable() {
      this.searched = this.searchByName(this.reports, this.search)
    },

    searchByName(items, term) {
      if (term) {
        return items.filter((item) =>
          toLower(item.order_type).includes(toLower(term))
        )
      }

      return items
    },

    toLower(text) {
      return text.toString().toLowerCase()
    },

    viewBill(billNo) {
      let outletId
      if (this.userRole != 'branch_user') {
        outletId = accountService.getOutletId()
      }
      reportService.viewItemTransBill(billNo, outletId).then((res) => {
        this.billDetails = res.data.result[0]
        this.showBill = true
      })
    },

    downloadBill(billNo) {
      html2canvas(document.querySelector('#bill-detail'))
        .then((canvas) => {
          document.body.appendChild(canvas)
        })
        .then(() => {
          var canvas = document.querySelector('canvas')
          canvas.style.display = 'none'
          var image = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
          var a = document.createElement('a')
          a.setAttribute('download', billNo + '.png')
          a.setAttribute('href', image)
          a.click()
          a.remove()
        })
    },

    setDateType(interval) {
      this.queries.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.queries.startDate = interval.startDate
        this.queries.endDate = interval.endDate
      } else {
        this.queries.startDate = interval.date
        this.queries.endDate = undefined
        this.itemisedTransactions('Completed Bills')
      }
    },

    itemisedTransactions(bill) {
      this.queries.billType = bill
      this.loader = true
      reportService.getItemTransactions(this.queries).then((res) => {
        this.loader = false
        this.reports = res.data.result
        this.searched = this.reports
        this.returnItemsAsStrings()
      })
    },

    searchInitiate() {
      let result = new Fuse(this.searched, {
        keys: [
          'kot_no',
          'bill_time',
          'order_type',
          'items.item_name',
          'cash_rcvd',
          'credit_amt',
          'stf',
        ],
        threshold: 0.1,
      }).search(this.searchQuery)
      this.reports = result.map((el) => el.item)
    },
  },

  created() {
    this.loader = true
    customizeService.getterminals().then((res) => {
      this.terminals = res.data
      this.terminals.unshift({ id: 'undefined', name: 'All' })
    })

    this.userRole = accountService.userRole()
    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
      })
      this.itemisedTransactions(this.queries.billType)
    } else {
      this.itemisedTransactions(this.queries.billType)
    }
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}

.bill-tabs {
  display: flex;
}

.bill-tab-item {
  padding: 14px;
  margin: 14px;
  border-radius: 3px;
}

.activeTab {
  background: #2b82be;
  color: white;
}

.table-options {
  display: flex;
}

.itemised-txn tr:last-child td {
  font-weight: normal;
}
.footer-table .md-table-content .md-table-row .md-table-cell {
  /*width: 150px;*/
}
</style>
<style>
.md-table table {
  table-layout: fixed;
}

.md-table-cell {
  word-break: break-all;
}
.md-table .md-table-row td {
  padding-left: 5px !important;
}
.md-table-cell:last-child .md-table-cell-container {
  padding-right: 5px !important;
}
.md-table-head:last-child {
  text-align: center !important;
  padding-right: 5px !important;
}
.report-table .md-table-head .md-table-head-container {
}
@media (max-width: 1440px) {
  .itemised-trans .md-table-head-label {
    white-space: normal;
  }
  .md-table-cell {
    font-size: 12px;
  }
}
</style>
