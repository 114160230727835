<template>
  <form class="md-layout-item">
    <md-card>
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/products/MenuItem' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>Integrations</h3>
        </div>
        <div
          class="md-layout-item md-size-30"
          v-if="formType == 'View Integration'"
        >
          <i class="md-icon md-icon-font md-theme-default edit-img">edit</i>
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <div class="md-layout-item md-layout">
              <slot v-for="item in integrations">
                <div
                  class="integration-item md-layout-item md-size-20"
                  :key="item.id"
                >
                  <img
                    class="integration-image"
                    v-if="item.logo != null"
                    :src="item.logo"
                  />
                  <md-radio v-model="integrationType" :value="item.id">{{
                    item.name
                  }}</md-radio>
                </div>
              </slot>
            </div>
          </div>
          <div
            class="md-layout-item"
            v-if="matchedComponent.name == 'Flipdish'"
          >
            <flipdish-form :flipdishProp="menuItem"></flipdish-form>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import flipdishForm from '../Categories/Flipdish'

import axios from 'axios'
export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
    flipdishForm,
  },
  props: {},
  data() {
    return {
      Name: '',
      touched: {
        Name: false,
      },
      modelValidations: {
        Name: {
          required: true,
        },
      },
      formType: {
        type: String,
        default: 'Add Integration',
      },
      integration: {
        name: '',
        file: '',
      },
      integrationType: 'Flipdish',
      matchedComponent: '',
      loader: false,
      integrations: [],
      menuItem: {
        menu_id: null,
        menu_section_id: null,
        flipdish_item_id: null,
      },
    }
  },

  computed: {
    flipDishParam() {
      return this.menuItem
    },
  },

  methods: {
    reset() {
      this.integration = {}
    },

    editSupercat() {
      this.formType = 'Edit Integration'
    },
  },
  created() {
    this.formType = this.$route.query.formType
    this.url = this.$route.query.client
    let self = this
    this.loader = true
    let menuItemFetch = new Promise((resolve, reject) => {
      axios.get(this.url, customizeService.authFun()).then((res) => {
        this.menuItem = res.data.menu_items
        resolve()
      })
    })

    Promise.all([menuItemFetch]).then(() => {
      customizeService
        .getIntegrationByPackage(accountService.userPackage())
        .then((res) => {
          this.loader = false
          this.integrations = res.data
        })
    })
  },
  watch: {
    Name() {
      this.touched.required = true
    },

    integrationType() {
      if (this.integrations.length != 0) {
        this.matchedComponent = this.integrations.find(
          (item) => item.id == this.integrationType
        )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
