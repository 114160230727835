<template>
  <div class="menu-item-rep">
    <md-card class="report-card block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 report-name">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Menu Item Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout md-layout-item filter-section pd-l-0 block-bg">
            <div class="md-layout-item md-size-55">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout-item md-size-45">
              <div class="md-layout search-section">
                <div class="md-layout-item md-size-70 pd-l-0">
                  <div class="md-layout">
                    <div
                      :class="[
                        { 'md-size-35': role() == 'branch_user' },
                        { 'md-size-50': role() != 'branch_user' },
                      ]"
                      class="md-layout-item md-size-30 md-small-size-30 pd-l-0 category-select"
                    >
                      <label>Menu Item</label>
                      <md-field>
                        <md-select v-model="data.menuItemId">
                          <md-option
                            v-for="item in menuItem"
                            :key="item.id"
                            :value="item.id"
                            >{{ item.name }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div
                      :class="[
                        { 'md-size-35': role() == 'branch_user' },
                        { 'md-size-50': role() != 'branch_user' },
                      ]"
                      class="md-layout-item md-size-35 md-small-size-50 pd-l-0 category-select"
                    >
                      <label>Terminals</label>
                      <md-field>
                        <md-select v-model="data.terminalId">
                          <md-option
                            v-for="item in terminalsList"
                            :key="item.id"
                            :value="item.id"
                            >{{ item.name }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div
                      class="md-layout-item md-size-30 md-small-size-50 pd-l-0 category-select"
                      v-if="role() == 'branch_user'"
                    >
                      <label>Outlet</label>
                      <md-field>
                        <md-select
                          v-model="outletId"
                          multiple
                          @md-closed="
                            outletId.length > 0
                              ? menuItemReport()
                              : (outletId = [])
                          "
                        >
                          <md-option
                            v-for="item in outletList"
                            :key="item.id"
                            :value="item.id"
                            class="oulet-select-list-item"
                            >{{ item.name }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-30">
                  <div class="float-right">
                    <md-button class="md-teritiary" @click="menuItemReport()"
                      >Search
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 card-inside report-table">
            <div class="loader-container" v-if="loader">
              <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
            </div>

            <h4 class="center" v-if="!loader">
              Menu Item Report- {{ sdate }} to {{ edate }}
            </h4>

            <md-table class="rep-table table-typ1" v-if="!loader">
              <!-- <md-table-toolbar>
              <div class="md-toolbar-section-start"></div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable" />
              </md-field>
            </md-table-toolbar>-->
              <md-table-toolbar>
                <div class="action-buttons export-btn">
                  <div class="pdf-el">
                    <div class="download-btn">
                      <img
                        src="../../../../assets/images/pdf.svg"
                        @click="downloadWithoutCSS()"
                      />
                    </div>
                  </div>

                  <div class="excel-el">
                    <vue-excel-xlsx
                      class="Excel-btn"
                      :data="reportApiRes"
                      :columns="columns"
                      :filename="'Menu Item Sales Report'"
                      :sheetname="'Menu Item Sales Report'"
                      :outlet-names="selectedOutletsNames()"
                      :sdate="sdate"
                      :edate="edate"
                    >
                    </vue-excel-xlsx>
                  </div>
                </div>
              </md-table-toolbar>

              <md-table-row>
                <md-table-head>SKU</md-table-head>
                <md-table-head>Main Category</md-table-head>
                <md-table-head>Sub Category</md-table-head>
                <md-table-head>Menu Item</md-table-head>
                <md-table-head>Total Quantity</md-table-head>
                <md-table-head>Gross Sales</md-table-head>
                <md-table-head>Discount</md-table-head>
                <md-table-head>Gross Sales After Discount</md-table-head>
                <md-table-head>Tax</md-table-head>
                <md-table-head>Net Sales</md-table-head>
              </md-table-row>
              <slot v-for="item in reportApiRes">
                <md-table-row slot="md-table-row">
                  <md-table-cell md-numeric>{{ item.sku }}</md-table-cell>
                  <md-table-cell
                    :class="{
                      'text-bold':
                        item.sub_category != '' ||
                        item.main_category != '' ||
                        item.menu_item == 'Total',
                    }"
                    >{{ item.main_category }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold':
                        item.sub_category != '' ||
                        item.main_category != '' ||
                        item.menu_item == 'Total',
                    }"
                    >{{ item.sub_category }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold':
                        item.sub_category != '' ||
                        item.main_category != '' ||
                        item.menu_item == 'Total',
                    }"
                    >{{ item.menu_item }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold':
                        item.sub_category != '' ||
                        item.main_category != '' ||
                        item.menu_item == 'Total',
                    }"
                    >{{ item.total_quantity }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold':
                        item.sub_category != '' ||
                        item.main_category != '' ||
                        item.menu_item == 'Total',
                    }"
                    >{{ item.gross_sales }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold':
                        item.sub_category != '' ||
                        item.main_category != '' ||
                        item.menu_item == 'Total',
                    }"
                    >{{ item.discount }}</md-table-cell
                  >
                  <md-table-cell>{{
                    item.gross_sales_sfter_discount
                  }}</md-table-cell>
                  <md-table-cell
                    :class="{
                      'text-bold':
                        item.sub_category != '' ||
                        item.main_category != '' ||
                        item.menu_item == 'Total',
                    }"
                    >{{ item.tax }}</md-table-cell
                  >
                  <md-table-cell
                    :class="{
                      'text-bold':
                        item.sub_category != '' ||
                        item.main_category != '' ||
                        item.menu_item == 'Total',
                    }"
                    >{{ item.net_sale }}</md-table-cell
                  >
                </md-table-row>
              </slot>
            </md-table>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { reportService } from './../../../../services/reports'
import { customizeService } from './../../../../services/customize'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import { accountService } from '../../../../services/account.js'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
export default {
  components: {
    ChartCard,
    DatePeriodDropdown,
    ClipLoader,
    VueExcelXlsx,
  },
  data() {
    return {
      columns: [
        {
          label: 'SKU',
          field: 'sku',
        },
        {
          label: 'Main Category',
          field: 'main_category',
        },
        {
          label: 'Sub Category',
          field: 'sub_category',
        },

        {
          label: 'Menu Item',
          field: 'menu_item',
        },
        {
          label: 'Total Quantity',
          field: 'total_quantity',
        },
        {
          label: 'Gross Sales',
          field: 'gross_sales',
        },
        {
          label: 'Discount',
          field: 'discount',
        },
        {
          label: 'Gross Sales After Discount',
          field: 'gross_sales_sfter_discount',
        },
        {
          label: 'Tax',
          field: 'tax',
        },
        {
          label: 'Net Sale',
          field: 'net_sale',
        },
      ],
      columnStyles: {
        0: { cellWidth: 26 },
        1: { cellWidth: 26 },
        2: { cellWidth: 26 },
        3: { cellWidth: 26 },
        4: { cellWidth: 26 },
        5: { cellWidth: 26 },
        6: { cellWidth: 26 },
        7: { cellWidth: 26 },
        8: { cellWidth: 26 },
        9: { cellWidth: 26 },
      },
      data: {
        search_type: 'Daily',
      },
      outletId: [],
      mainCatList: [],
      outletList: [],
      subCatList: [],
      menuItemRepList: [
        {
          mainCat: '',
          menuItems: '',
          subCat: '',
        },
      ],
      tempItemList: [],
      reportApiRes: [],
      menuItem: [],
      terminalsList: [],
      straightLinesChart: {
        data: {
          labels: [
            "'07",
            "'08",
            "'09",
            "'10",
            "'11",
            "'12",
            "'13",
            "'14",
            "'15",
          ],
          series: [[10, 16, 8, 13, 20, 15, 20, 34, 30]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          classNames: {
            point: 'ct-point ct-white',
            line: 'ct-line ct-white',
          },
        },
      },
      simpleBarChart: {
        data: {
          labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          seriesBarDistance: 20,
          axisX: {
            showGrid: false,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            },
          ],
        ],
      },
      colouredLineChart: {
        data: {
          labels: [
            "'06",
            "'07",
            "'08",
            "'09",
            "'10",
            "'11",
            "'12",
            "'13",
            "'14",
            "'15",
          ],
          series: [[355, 480, 290, 554, 690, 690, 500, 752, 650, 900]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 10,
          }),
          axisY: {
            showGrid: true,
            offset: 40,
          },
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          showPoint: true,
          height: '300px',
        },
      },
      search: null,
      searched: [],
      users: [
        {
          id: 1,
          name: 'Shawna Dubbin',
          email: '',
          gender: '',
          title: '',
        },
        {
          id: 2,
          name: '',
          email: '',
          gender: 'Female',
          title: 'Account Coordinator',
        },
        {
          id: 3,
          name: '',
          email: '',
          gender: 'Male',
          title: 'Community Outreach Specialist',
        },
        {
          id: 4,
          name: '',
          email: 'lizkovitz3@youtu.be',
          gender: '',
          title: '',
        },
        {
          id: 5,
          name: 'Thatcher Stave',
          email: 'tstave4@reference.com',
          gender: 'Male',
          title: 'Software Test Engineer III',
        },
        {
          id: 6,
          name: 'Karim Chipping',
          email: 'kchipping5@scribd.com',
          gender: 'Female',
          title: 'Safety Technician II',
        },
        {
          id: 7,
          name: 'Helge Holyard',
          email: 'hholyard6@howstuffworks.com',
          gender: 'Female',
          title: 'Internal Auditor',
        },
        {
          id: 8,
          name: 'Rod Titterton',
          email: 'rtitterton7@nydailynews.com',
          gender: 'Male',
          title: 'Technical Writer',
        },
      ],
      loader: false,
      sdate: '',
      edate: '',
    }
  },

  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
    },

    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
        if (!this.loader) {
          this.menuItemReport()
        }
      }
    },

    newUser() {
      window.alert('Noop')
    },
    searchOnTable() {
      this.searched = this.searchByName(this.users, this.search)
    },

    searchByName(items, term) {
      if (term) {
        return items.filter((item) =>
          this.toLower(item.name).includes(this.toLower(term))
        )
      }
      return items
    },

    toLower(text) {
      return text.toString().toLowerCase()
    },
    downloadWithCSS() {
      html2canvas(document.getElementById('mytable'), {
        width: 2400,
        height: 1200,
      }).then(function (canvas) {
        var img = canvas.toDataURL('image/png')
        var doc = new jsPDF()
        doc.addImage(img, 'JPEG', 20, 20)
        doc.save('newfile.pdf')
      })
    },
    arrangeForPdf() {
      var body = []
      this.reportApiRes.forEach((el) => {
        let arrayObj = [
          el.sku,
          el.main_category,
          el.sub_category,
          el.menu_item,
          el.total_quantity,
          el.gross_sales,
          el.discount,
          el.gross_sales_sfter_discount,
          el.tax,
          el.net_sale,
        ]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      this.data.startDate = this.sdate
      this.data.endDate = this.edate
      let head = [
        [
          'SKU ',
          'Main Cat',
          'Sub Cat',
          'Menu Item',
          'Total Qty',
          'Gross Sales',
          'Disc',
          'Gross Sales After disc',
          'Tax',
          'Net Sale',
        ],
      ]
      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Menu Item Sales Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'menu-item-report.pdf')
    },
    menuItemReport() {
      this.loader = true
      this.data.outletId = this.outletId
      reportService.getMenuItemReport(this.data).then((res) => {
        this.loader = false
        this.reportApiRes = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate
      })
    },
    getMenu() {
      customizeService.getMenuItems().then((res) => {
        this.menuItem = res.data.menu_items
        this.menuItem.unshift({ id: 'undefined', name: 'All' })
      })
    },
    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminalsList = res.data
        this.terminalsList.unshift({ id: 'undefined', name: 'All' })
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.menuItemReport()
      })
    },
    role() {
      return accountService.userRole()
    },
  },

  created() {
    this.loader = true
    this.searched = this.users
    this.getMenu()
    this.getTerminals()
    this.getOutlets()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.text-bold {
  font-weight: bold;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}
.md-table.md-theme-default .md-table-content {
  display: none;
}
.pd-l-0 {
  padding-left: 0px;
}

.md-button-content {
  font-size: 12px;
}
.menu-item-rep .md-ripple {
  padding: 6px 15px;
  height: auto !important;
  border-radius: 0px;
}
.content .menu-item-rep .md-ripple {
  padding: 0px !important;
}
.card-inside {
  .md-button {
    .md-ripple {
      padding: 0px 0px;
    }
  }
}

// .rep-table{
//   margin-top: 0px;
// }
</style>
