import axios from 'axios'
import { accountService } from './../../services/account'
import { customizeService } from '../../services/customize'
import VueJwtDecode from 'vue-jwt-decode'
import routes from './../../routes/routes'
import { Promise, async } from 'q'

const state = {
  allSelectedMenus: {},
}

const getters = {
  getSelectedMenus: (state) => state.userRole,
}

const actions = {
  setSelectedMenus({ commit }, data) {
    commit('updateSelectedMenus', data)
  },
}

const mutations = {
  updateSelectedMenus(state, payload) {
    state.allSelectedMenus = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
