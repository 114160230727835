<template>
  <div class="menu-item-rep">
    <md-card class="report-card block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70 report-name">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Combos Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout md-layout-item filter-section block-bg">
            <div class="md-layout-item md-size-55">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout-item md-size-45">
              <div class="md-layout search-section">
                <div class="md-layout-item md-size-70 pd-l-0">
                  <div class="md-layout">
                    <div
                      :class="[
                        { 'md-size-35': role() == 'branch_user' },
                        { 'md-size-50': role() != 'branch_user' },
                      ]"
                      class="md-layout-item md-small-size-50 pd-l-mbl-o pd-l0-r15 category-select"
                    >
                      <label>Combos</label>
                      <md-field>
                        <md-select v-model="data.comboId">
                          <md-option
                            v-for="item in combos"
                            :key="item.id"
                            :value="item.id"
                            >{{ item.name }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div
                      :class="[
                        { 'md-size-35': role() == 'branch_user' },
                        { 'md-size-50': role() != 'branch_user' },
                      ]"
                      class="md-layout-item md-small-size-50 pd-l-mbl-o category-select"
                    >
                      <label>Terminals</label>
                      <md-field>
                        <md-select v-model="data.terminalId">
                          <md-option
                            v-for="item in terminalsList"
                            :key="item.id"
                            :value="item.id"
                            >{{ item.name }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div
                      class="md-layout-item md-size-30 md-small-size-50 pd-l-mbl-o pd-l0-r15"
                      v-if="role() == 'branch_user'"
                    >
                      <label>Outlet</label>
                      <md-field>
                        <md-select
                          v-model="outletId"
                          multiple
                          @md-closed="
                            outletId.length > 0
                              ? comboReport()
                              : (outletId = [])
                          "
                        >
                          <md-option
                            v-for="item in outletList"
                            :key="item.id"
                            :value="item.id"
                            class="oulet-select-list-item"
                            >{{ item.name }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-30">
                  <div class="float-right">
                    <md-button class="md-teritiary" @click="comboReport()"
                      >Search</md-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 card-inside report-table">
            <div class="loader-container" v-if="loader">
              <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
            </div>
            <h4 class="center" v-if="!loader">
              Combo Report- {{ sdate }} to {{ edate }}
            </h4>
            <div class="md-layout-item md-size-100" v-if="!loader"></div>

            <md-table class="rep-table table-typ1" v-if="!loader">
              <!-- <md-table-toolbar>
              <div class="md-toolbar-section-start">Combos Sales Report </div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable" />
              </md-field>
              </md-table-toolbar>-->
              <md-table-toolbar>
                <div class="action-buttons export-btn">
                  <div class="pdf-el">
                    <div class="download-btn">
                      <img
                        src="../../../../assets/images/pdf.svg"
                        @click="downloadWithoutCSS()"
                      />
                    </div>
                  </div>

                  <div class="excel-el">
                    <vue-excel-xlsx
                      class="Excel-btn"
                      :data="reportApiRes"
                      :columns="columns"
                      :filename="'Combos Sales Report'"
                      :sheetname="'Combos Sales Report'"
                      :outlet-names="selectedOutletsNames()"
                      :sdate="sdate"
                      :edate="edate"
                    >
                    </vue-excel-xlsx>
                  </div>
                </div>
              </md-table-toolbar>
              <md-table-row>
                <md-table-head>SKU</md-table-head>
                <md-table-head>Combo</md-table-head>
                <md-table-head>Qty</md-table-head>
                <md-table-head>Combo Group</md-table-head>
                <md-table-head>Menu Item</md-table-head>
                <md-table-head>Total Quantity</md-table-head>
                <md-table-head>Standard Price</md-table-head>
                <md-table-head>Gross Sales</md-table-head>
                <md-table-head>Discount</md-table-head>
                <md-table-head>Gross Sales After Discount</md-table-head>
                <md-table-head>Tax</md-table-head>
                <md-table-head>Net Sales</md-table-head>
              </md-table-row>
              <slot v-for="item in reportApiRes">
                <md-table-row slot="md-table-row">
                  <md-table-cell>{{ item.sku }} </md-table-cell>
                  <md-table-cell
                    :class="{
                      'text-bold': item.is_bold,
                    }"
                    >{{ item.combo }}</md-table-cell
                  >
                  <md-table-cell>{{ item.qty }}</md-table-cell>
                  <md-table-cell>{{ item.combo_group }}</md-table-cell>

                  <md-table-cell>{{ item.menu_item }} </md-table-cell>
                  <md-table-cell>{{ item.total_qty }}</md-table-cell>
                  <md-table-cell>{{ item.standard_price }}</md-table-cell>
                  <md-table-cell>{{ item.gross_sales }}</md-table-cell>
                  <md-table-cell>{{ item.discount }}</md-table-cell>
                  <md-table-cell>{{
                    item.gross_sales_after_discount
                  }}</md-table-cell>
                  <md-table-cell>{{ item.tax }}</md-table-cell>
                  <md-table-cell>{{ item.net_sales }}</md-table-cell>
                </md-table-row>
              </slot>
            </md-table>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { reportService } from './../../../../services/reports'
import { customizeService } from './../../../../services/customize'
import { accountService } from '../../../../services/account.js'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
export default {
  components: {
    ChartCard,
    DatePeriodDropdown,
    ClipLoader,
    VueExcelXlsx,
  },
  data() {
    return {
      columns: [
        {
          label: 'SKU',
          field: 'sku',
        },
        {
          label: 'Combo',
          field: 'combo',
        },
        {
          label: 'Qty',
          field: 'qty',
        },
        {
          label: 'Combo Group',
          field: 'combo_group',
        },
        {
          label: 'Menu Item',
          field: 'menu_item',
        },
        {
          label: 'Total Qty',
          field: 'total_quantity',
        },
        {
          label: 'Standard Price',
          field: 'standard_price',
        },
        {
          label: 'Gross Sales',
          field: 'gross_sales',
        },
        {
          label: 'Discount',
          field: 'discount',
        },
        {
          label: 'Gross Sales After Discount',
          field: 'gross_sales_after_discount',
        },
        {
          label: 'Tax',
          field: 'tax',
        },
        {
          label: 'Net Sales',
          field: 'net_sales',
        },
      ],
      columnStyles: {
        0: { cellWidth: 38 },
        1: { cellWidth: 38 },
        2: { cellWidth: 38 },
        3: { cellWidth: 38 },
        4: { cellWidth: 38 },
        5: { cellWidth: 38 },
        6: { cellWidth: 38 },
      },
      data: {
        search_type: 'Daily',
      },
      reportApiRes: [],
      combos: [],
      terminalsList: [],
      outletList: [],
      outletId: [],
      loader: false,
      months: [],
      sdate: '',
      edate: '',
    }
  },

  methods: {
    fromDateTime(fromDateTime) {
      this.data.startDate = fromDateTime
    },
    toDateTime(toDateTime) {
      this.data.endDate = toDateTime
    },

    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
        if (!this.loader) {
          this.comboReport()
        }
      }
    },

    setStartDate(date) {
      this.data.startDate = date
    },

    arrangeForPdf() {
      var body = []
      this.reportApiRes.forEach((el) => {
        let arrayObj = [
          el.sku,
          el.combo,
          el.qty,
          el.combo_group,
          el.menu_item,
          el.total_quantity,
          el.standard_price,
          el.gross_sales,
          el.discount,
          el.gross_sales_after_discount,
          el.tax,
          el.net_sales,
        ]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let selectedOutlets = this.selectedOutletsNames()
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      this.data.startDate = this.sdate
      this.data.endDate = this.edate

      let head = [
        [
          'SKU',
          'Combo',
          'Qty',
          'Combo Group',
          'Menu Item',
          'Tot Qty',
          'Std Price',
          'Gross Sales',
          'Disc',
          'Gross Sales Aft disc',
          'Tax',
          'Net Sale',
        ],
      ]
      pdfService.headerFooterConfig(
        this.data,
        doc,
        'Combo Sales Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, 'combo-sales-report.pdf')
    },

    comboReport() {
      this.loader = true
      this.data.outletId = this.outletId
      reportService.getComboReport(this.data).then((res) => {
        this.loader = false
        this.reportApiRes = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate
      })
    },
    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminalsList = res.data
        this.terminalsList.unshift({ id: 'undefined', name: 'All' })
      })
    },
    getCombos() {
      customizeService.getCombo().then((res) => {
        this.combos = res.data.combos
        // this.combos.unshift({ id: 'undefined', name: 'All' })
        // console.log('combos item', this.combos)
        // this.data.comboId = this.combos[0].combo.id
      })
    },
    getOutlets() {
      customizeService.getOutlets().then((res) => {
        this.outletList = res.data.outlets
        this.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.comboReport()
      })
    },
    role() {
      return accountService.userRole()
    },
  },

  created() {
    this.searched = this.users
    this.loader = true
    this.getTerminals()
    this.getCombos()
    this.getOutlets()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.d-table {
  width: 100%;
}
.Excel-btn {
  height: 40px;
}
.md-table.md-theme-default .md-table-content {
  display: none;
}
.pd-l-0 {
  padding-left: 0px;
}
.md-button-content {
  font-size: 12px;
}
.menu-item-rep .md-ripple {
  padding: 6px 15px;
  height: auto !important;
  border-radius: 0px;
}
.content .menu-item-rep .md-ripple {
  padding: 0px !important;
}
.card-inside {
  .md-button {
    .md-ripple {
      padding: 0px 0px;
    }
  }
}
.rep-table {
  margin-top: 0px;
}
</style>
