<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Change Password') }}</h3>
        </div>
      </md-card-header>

      <div class="loader-container" v-if="loader">
        <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
      </div>

      <md-card-content v-if="!loader">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('old_password') },
                {
                  'md-valid':
                    !errors.has('old_password') && touched.old_password,
                },
              ]"
            >
              <label>{{ $t('Old Password') }}*</label>
              <md-input
                v-model="changePassword.old_password"
                data-vv-name="old_password"
                type="password"
                v-validate="modelValidations.old_password"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('old_password')">
                  {{ errors.first('old_password') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('old_password') && touched.old_password"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('new_password') },
                {
                  'md-valid':
                    !errors.has('new_password') && touched.new_password,
                },
              ]"
            >
              <label>{{ $t('New Password') }}*</label>
              <md-input
                v-model="changePassword.new_password"
                data-vv-name="new_password"
                type="password"
                v-validate="modelValidations.new_password"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('new_password')">
                  {{ errors.first('new_password') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('new_password') && touched.new_password"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('confirmPassword') },
                {
                  'md-valid':
                    !errors.has('confirmPassword') && touched.confirmPassword,
                },
              ]"
            >
              <label>{{ $t('Confirm Password') }}*</label>
              <md-input
                v-model="confirmPassword"
                data-vv-name="confirmPassword"
                type="password"
                v-validate="modelValidations.confirmPassword"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('confirmPassword')">
                  {{ errors.first('confirmPassword') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="
                    !errors.has('confirmPassword') && touched.confirmPassword
                  "
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100">
            <div class="error-msg" v-if="formError != ''">{{ formError }}</div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------------------- -->
        <div class="btn-div">
          <md-card-actions class="text-center">
            <md-button
              native-type="submit"
              :to="{ path: '/Settings/Course' }"
              >{{ $t('CANCEL') }}</md-button
            >
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-teritiary"
              >{{ $t('SAVE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import axios from 'axios'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      old_password: '',
      switch2: null,
      new_password: '',
      confirmPassword: '',
      touched: {
        new_password: false,
        old_password: false,
        confirmPassword: false,
      },
      modelValidations: {
        old_password: {
          required: true,
        },
        new_password: {
          required: true,
        },
        confirmPassword: {
          required: true,
        },
      },
      changePassword: {
        new_password: '',
        old_password: '',
      },
      loader: false,
      formError: '',
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitPassword()
          }
        })
      }
    },

    submitPassword() {
      if (this.confirmPassword != this.changePassword.new_password) {
        this.formError = 'Passwords do not match'
      } else {
        this.formError = ''
        accountService
          .changePassword(this.changePassword)
          .then((res) => {
            alert(res.data.message)
          })
          .catch(() => {
            formError = 'Please try again'
          })
      }
    },
  },
  created() {},
  watch: {
    old_password() {
      this.touched.required = true
    },
    new_password() {
      this.touched.required = true
    },
    confirmPassword() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
