import { environment } from './../../config'
import axios from 'axios'
import { accountService } from '../services/account.js'
import store from '../store'
import account from '../store/modules/account'

var baseUrl = `${environment.api.host}`
var DynamicUrl = store.getters.getBaseUrl
let outletId = ''
//var accessToken=this.sessionStorage.getItem('access_token');
const auth = {
  headers: {
    Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
  },
}

function authFun() {
  const auth = {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
    },
  }
  return auth
}

export function getScreenMenus(parameter) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.screen}` +
      '?chain_id=' +
      accountService.getChainId(),
    authFun()
  )
}
export function getScreenMenu(id) {
  return axios.get(
    baseUrl + `${environment.api.services.customize.screen}/${id}`,
    authFun()
  )
}

export function createScreenMenu(data) {
  let body = {
    screens: data,
  }

  return axios.post(
    baseUrl + `${environment.api.services.customize.screen}`,
    body,
    authFun()
  )
}

export function updateScreenMenu(data, id) {
  let body = {
    screens: data,
  }

  return axios.put(
    baseUrl + `${environment.api.services.customize.screen}/${id}`,
    body,
    authFun()
  )
}

export function getGrid(id, screenId) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.screen}/get_grid?outlet_id=${id}&screen=${screenId}`,
    authFun()
  )
}
export function saveScreen(body) {
  return axios.post(
    baseUrl + `${environment.api.services.customize.screen}/save_screen`,
    body,
    authFun()
  )
}

export function geteMenuPages(outleId, screenId) {
  return axios.get(
    baseUrl +
      `${environment.api.services.customize.screen}/get_menu_pages?outlet_id=${outleId}&screen=${screenId}`,
    authFun()
  )
}

export function createMenuPage(data) {
  if (data.id) {
    let body = {
      ipad_category: {
        ...data,
        outlet_id: accountService.getOutletId(),
        chain_id: accountService.getChainId(),
      },
    }
    return axios.put(
      baseUrl +
        `${environment.api.services.ipad.update_category}` +
        '?id=' +
        data.id,
      body,
      authFun()
    )
  } else {
    let body = {
      items: {
        ...data,
        outlet_id: accountService.getOutletId(),
        chain_id: accountService.getChainId(),
      },
    }
    return axios.post(
      baseUrl + `${environment.api.services.ipad.ipad_category}`,
      body,
      authFun()
    )
  }
}

export function deleteMenuPage(id) {
  return axios.delete(
    baseUrl + `${environment.api.services.ipad.delete_category}` + '?id=' + id,
    authFun()
  )
}

export function updateGrid(data) {
  return axios.put(
    baseUrl + `${environment.api.services.customize.screen}/update_screen`,
    data,
    authFun()
  )
}

export function getScreenMenuItems() {
  return axios.get(
    baseUrl + `${environment.api.services.customize.screen}/screen_menu_items`,
    authFun()
  )
}
