import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue'
import Dashboard from '@/pages/Dashboard/OnlineOrder/Dashboard.vue'
import CbePay from '@/pages/Dashboard/CbePay/Onboard/Account.vue'
import CbePayTransactions from '@/pages/Dashboard/CbePay/Transactions/Transactions.vue'
import CbePayPayments from '@/pages/Dashboard/CbePay/Payments/Payments.vue'
import CbePayPayout from '@/pages/Dashboard/CbePay/Payout/Payout.vue'
import IntegratedPayout from '@/pages/Dashboard/CbePay/Payout/IntegratedPayout.vue'
import CbePayoutReconciliation from '@/pages/Dashboard/CbePay/Payout/PayoutReconciliationReport.vue'

export const cbePayModule = {
  path: '/',
  component: DashboardLayout,
  name: 'Cbe Pay',
  children: [
    {
      path: 'integrations/cbe-pay',
      name: 'cbe-pay',
      components: { default: CbePay },
      meta: {
        title: 'Onboarding',
        breadcrumbs: [
          {
            text: 'Dashboard',
          },
          {
            text: 'Dashboard',
          },
        ],
      },
    },
    {
      path: 'integrations/payments',
      name: 'transactions',
      components: { default: CbePayPayments },
      meta: {
        title: 'Transactions',
        breadcrumbs: [
          {
            text: 'Dashboard',
          },
          {
            text: 'Dashboard',
          },
        ],
      },
    },
    {
      path: 'integrations/payout',
      name: 'transactions',
      components: { default: CbePayPayout },
      meta: {
        title: 'Payouts',
        breadcrumbs: [
          {
            text: 'Dashboard',
          },
          {
            text: 'Dashboard',
          },
        ],
      },
    },
    {
      path: 'integrations/payoutreconciliation',
      name: 'transactions',
      components: { default: CbePayoutReconciliation },
      meta: {
        title: 'Payout-Reconciliation',
        breadcrumbs: [
          {
            text: 'Dashboard',
          },
          {
            text: 'Dashboard',
          },
        ],
      },
    },
    {
      path: 'integrations/integrated',
      name: 'transactions',
      components: { default: IntegratedPayout },
      meta: {
        title: 'Payouts',
        breadcrumbs: [
          {
            text: 'Dashboard',
          },
          {
            text: 'Dashboard',
          },
        ],
      },
    },
    {
      path: 'integrations/transactions',
      name: 'transactions',
      components: { default: CbePayTransactions },
      meta: {
        title: 'Transactions',
        breadcrumbs: [
          {
            text: 'Dashboard',
          },
          {
            text: 'Dashboard',
          },
        ],
      },
    },
  ],
}
