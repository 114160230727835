<template>
  <div class="md-layout client-table">
    <div class="md-layout-item">
      <md-card class="pagination-card pl-2">
        <business
          :days="days"
          :holiday-list="holidayList"
          :hours="collectionHours"
          :settings="storeSettings"
          v-if="!loading"
        ></business>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import users from './users'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'

import Business from '@/components/BusinessHours/BusinessHours.vue'
// import { Chrome } from 'vue-color'

export default {
  components: {
    Pagination,
    Business,
    // 'chrome-picker': Chrome,
  },

  computed: {},
  data() {
    return {
      // displayPicker: { primary: false, secondary: false },
      // primaryColor: '',
      // secondaryColor: '',
      // activePicker: 'primary',

      // colors: { r: 255, g: 0, b: 0 },
      storeSettings: { store: {} },
      holidayList: [],
      days: [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ],
      loading: true,
      defaultHours: {
        collectionStatus: false,
        deliveryStatus: false,
        eatinStatus: false,
        sunday: {
          collection: [
            {
              open: '',
              close: '',
              id: '1',
              isOpen: false,
            },
          ],
          delivery: [
            {
              open: '',
              close: '',
              id: '2',
              isOpen: false,
            },
          ],
          dinein: [
            {
              open: '',
              close: '',
              id: '3',
              isOpen: false,
            },
          ],
        },
        monday: {
          collection: [
            {
              open: '',
              close: '',
              id: '4',
              isOpen: true,
            },
          ],
          delivery: [
            {
              open: '',
              close: '',
              id: '5',
              isOpen: true,
            },
          ],
          dinein: [
            {
              open: '',
              close: '',
              id: '6',
              isOpen: true,
            },
          ],
        },
        tuesday: {
          collection: [
            {
              open: '',
              close: '',
              id: '7',
              isOpen: true,
            },
          ],
          delivery: [
            {
              open: '',
              close: '',
              id: '8',
              isOpen: true,
            },
          ],
          dinein: [
            {
              open: '',
              close: '',
              id: '9',
              isOpen: true,
            },
          ],
        },
        wednesday: {
          collection: [
            {
              open: '',
              close: '',
              id: '10',
              isOpen: true,
            },
          ],
          delivery: [
            {
              open: '',
              close: '',
              id: '11',
              isOpen: true,
            },
          ],
          dinein: [
            {
              open: '',
              close: '',
              id: '12',
              isOpen: true,
            },
          ],
        },
        thursday: {
          collection: [
            {
              open: '',
              close: '',
              id: '13',
              isOpen: true,
            },
          ],
          delivery: [
            {
              open: '',
              close: '',
              id: '14',
              isOpen: true,
            },
          ],
          dinein: [
            {
              open: '',
              close: '',
              id: '15',
              isOpen: true,
            },
          ],
        },
        friday: {
          collection: [
            {
              open: '',
              close: '',
              id: '16',
              isOpen: true,
            },
          ],
          delivery: [
            {
              open: '',
              close: '',
              id: '17',
              isOpen: true,
            },
          ],
          dinein: [
            {
              open: '',
              close: '',
              id: '18',
              isOpen: true,
            },
          ],
        },
        saturday: {
          collection: [
            {
              open: '',
              close: '',
              id: '19',
              isOpen: true,
            },
          ],
          delivery: [
            {
              open: '',
              close: '',
              id: '20',
              isOpen: true,
            },
          ],
          dinein: [
            {
              open: '',
              close: '',
              id: '21',
              isOpen: true,
            },
          ],
        },
      },
    }
  },
  // methods: {
  //   setColor(color) {
  //     this.updateColors(color)
  //     displayPicker.primary
  //       ? (this.primaryColor = color)
  //       : (this.secondaryColor = color)
  //   },
  //   updateColors(color) {
  //     if (color.slice(0, 1) == '#') {
  //       this.colors = {
  //         hex: color,
  //       }
  //     } else if (color.slice(0, 4) == 'rgba') {
  //       var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
  //         hex =
  //           '#' +
  //           (
  //             (1 << 24) +
  //             (parseInt(rgba[0]) << 16) +
  //             (parseInt(rgba[1]) << 8) +
  //             parseInt(rgba[2])
  //           )
  //             .toString(16)
  //             .slice(1)
  //       this.colors = {
  //         hex: hex,
  //         a: rgba[3],
  //       }
  //     }
  //   },
  //   showPicker(colorType) {
  //     document.addEventListener('click', this.documentClick)
  //     // debugger
  //     this.displayPicker[colorType] = true
  //     this.activePicker = colorType
  //   },
  //   hidePicker() {
  //     document.removeEventListener('click', this.documentClick)
  //     // this.displayPicker = false
  //     this.displayPicker[this.activePicker] = false
  //   },
  //   togglePicker(colorType) {
  //     // this.displayPicker ? this.hidePicker() : this.showPicker()
  //     this.activePicker = colorType
  //     this.displayPicker[colorType]
  //       ? this.hidePicker()
  //       : this.showPicker(colorType)
  //   },
  //   updateFromInput(inputType) {
  //     color = inputType == 'primary' ? this.primaryColor : this.secondaryColor
  //     this.updateColors(color, inputType)
  //   },
  //   updateFromPicker(color) {
  //     // debugger
  //     this.colors = color
  //     if (this.displayPicker.primary) {
  //       if (color.rgba.a == 1) {
  //         this.primaryColor = color.hex
  //       } else {
  //         this.primaryColor =
  //           'rgba(' +
  //           color.rgba.r +
  //           ', ' +
  //           color.rgba.g +
  //           ', ' +
  //           color.rgba.b +
  //           ', ' +
  //           color.rgba.a +
  //           ')'
  //       }
  //     } else if (this.displayPicker.secondary) {
  //       if (color.rgba.a == 1) {
  //         this.secondaryColor = color.hex
  //       } else {
  //         this.secondaryColor =
  //           'rgba(' +
  //           color.rgba.r +
  //           ', ' +
  //           color.rgba.g +
  //           ', ' +
  //           color.rgba.b +
  //           ', ' +
  //           color.rgba.a +
  //           ')'
  //       }
  //     }
  //   },
  //   documentClick(e) {
  //     let reference = 'colorpicker' + this.activePicker
  //     var el = this.$refs[reference],
  //       target = e.target
  //     if (el !== target && !el.contains(target)) {
  //       this.hidePicker()
  //     }
  //   },
  // },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3,
    })
  },
  created() {
    let outlet_id = accountService.getOutletId()
    customizeService.getOutletOnlineSettings(outlet_id).then((res) => {
      if (res.data.holidays) {
        this.holidayList = res.data.holidays
      }
      if (res.data.online_store_settings) {
        this.storeSettings = res.data.online_store_settings || {
          theme: {},
          store: { dinein: {}, collection: {}, delivery: {} },
        }
      }
      if (res.data.online_business_hours) {
        this.collectionHours = res.data.online_business_hours || {}

        // if (res.data.holidays) {
        //   this.holidayList = res.data.holidays
        // }
      } else {
        this.collectionHours = this.defaultHours
      }
      this.collectionHours.collectionStatus =
        this.storeSettings.store.collection.allow_collection
      this.collectionHours.deliveryStatus = this.storeSettings.store.delivery
        ? this.storeSettings.store.delivery.allow_delivery
        : false
      this.collectionHours.dineinStatus =
        this.storeSettings.store.dinein.allow_dinein

      this.loading = false
    })
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    },
    // primaryColor(val) {
    //   if (val) {
    //     this.updateColors(val)
    //     this.$emit('input', val)
    //     //document.body.style.background = val;
    //   }
    // },
    // secondaryColor(val) {
    //   if (val) {
    //     this.updateColors(val)
    //     this.$emit('input', val)
    //     //document.body.style.background = val;
    //   }
    // },
  },
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.menu-icon {
  padding-right: 20px;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}

h1 {
  height: 120px;
  line-height: 120px;
  text-align: center;
}
/* .vc-chrome {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 9;
}
.current-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #000;
  cursor: pointer;
} */
.footer {
  margin-top: 20px;
  text-align: center;
}
/* .color-picker {
  position: relative;
  width: 225px;
} */
</style>
