/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="wrapper bg-grad" :class="[{ 'nav-open': $sidebar.showSidebar }]">
    <div>
      <notifications></notifications>
      <side-bar
        :active-color="sidebarBackground"
        :background-image="sidebarBackgroundImage"
        :data-background-color="sidebarBackgroundColor"
        class="sideBar block-bg"
      >
        <user-menu v-if="displayUserMenu <= 900"></user-menu>
        <template slot="links" v-if="userRole == '' || userRole == undefined">
          <sidebar-item
            :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
          ></sidebar-item>
          <!-- <sidebar-item :link="{ name: 'Distributors', icon: 'widgets'}">
          <sidebar-item :link="{ name: 'Distributors List', path: '/distributors/list-dist' }"></sidebar-item>
          <sidebar-item
            :link="{ name: 'Distributors Table', path: '/distributors/Distributors-table' }"
          ></sidebar-item>
        </sidebar-item>  -->

          <sidebar-item
            :link="{ name: 'Products', icon: 'image' }"
            :highlightLink="{ link: '/forms/category-form' }"
          >
            <sidebar-item
              :link="{
                name: 'Super Category',
                path: '/products/super-category',
              }"
              :highlightLink="{ link: '/forms/category-form' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Main Category', path: '/products/main-category' }"
              :highlightLink="{ link: '/products/main-category-form' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Sub Category', path: '/products/sub-category' }"
              :highlightLink="{ link: '/forms/sub-category-form' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Modifiers', path: '/products/Modifiers' }"
              :highlightLink="{ link: '/forms/Modifiers' }"
            ></sidebar-item>

            <sidebar-item
              :link="{ name: 'MenuItem', path: '/products/MenuItem' }"
              :highlightLink="{ link: '/forms/MenuItem' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Combos', path: '/products/Combos' }"
              :highlightLink="{ link: '/forms/Combos' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'portions', path: '/products/Portions' }"
              :highlightLink="{ link: '/forms/Portions' }"
            ></sidebar-item>
            <!-- <sidebar-item :link="{ name: 'Pricing', path: '/pricing' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Timeline', path: '/products/timeline' }"></sidebar-item>
          <sidebar-item :link="{ name: 'ForgetPassword', path: '/ForgetPassword' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Login', path: '/login' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Register', path: '/register' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Lock Screen', path: '/lock' }"></sidebar-item>
          <sidebar-item :link="{ name: 'User Profile', path: '/products/user' }"></sidebar-item> -->
          </sidebar-item>
          <!-- <sidebar-item :link="{ name: 'Outlets', icon: 'apps' }">
          <sidebar-item :link="{ name: 'Buttons', path: '/outlets/buttons' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Grid System', path: '/outlets/grid-system' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Panels', path: '/outlets/panels' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Sweet Alert', path: '/outlets/sweet-alert' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Notifications', path: '/outlets/notifications' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Icons', path: '/outlets/icons' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Typography', path: '/outlets/typography' }"></sidebar-item>
        </sidebar-item> -->
          <!-- <sidebar-item :link="{ name: 'Forms', icon: 'content_paste' }">
          <sidebar-item :link="{ name: 'Regular Forms', path: '/forms/regular' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Extended Forms', path: '/forms/extended' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Validation Forms', path: '/forms/validation' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Edit Forms', path: '/inventory/edit' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Wizard', path: '/forms/wizard' }"></sidebar-item>
        </sidebar-item> -->

          <!-- <sidebar-item  v-for="item in menuItems" :key="item.menuName" :link="{ name: item.menuName, icon: item.icon }">

          <sidebar-item v-for="ind in item.subMenu" :key="ind.name" :link="{ name: ind.name, path: ind.path }"></sidebar-item>
          
          
        </sidebar-item> -->

          <sidebar-item
            :link="{ name: 'Settings', icon: 'settings' }"
            :highlightLink="{ link: '/Settings/AddModules' }"
          >
            <sidebar-item
              :link="{ name: 'Modules', path: '/Settings/Modules' }"
              :highlightLink="{ link: '/Settings/AddModules' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Packages', path: '/Settings/Packages' }"
              :highlightLink="{ link: '/Settings/Packages' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Station', path: '/Settings/Station' }"
              :highlightLink="{ link: '/Settings/Station' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Course', path: '/Settings/Course' }"
              :highlightLink="{ link: '/Settings/Course' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Repeat Printers',
                path: '/Settings/repeat-printer',
              }"
              :highlightLink="{ link: '/Settings/edit-repeat-printer' }"
            ></sidebar-item>
          </sidebar-item>
          <sidebar-item :link="{ name: 'Distributors', icon: 'settings' }">
            <sidebar-item
              :link="{
                name: 'Distributors List',
                path: '/distributors/list-dist',
              }"
              :highlightLink="{ link: '/distributors/AddDistributor' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Distributors Table',
                path: '/distributors/Distributors-table',
              }"
              :highlightLink="{ link: '/distributors/AddDistributor' }"
            ></sidebar-item>
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Business Vertical',
              path: '/distributors/BusinessVertical',
              icon: 'widgets',
            }"
            :highlightLink="{ link: '/distributors/BusinessVertical' }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Outlet',
              path: '/business-vertical/Outlet',
              icon: 'timeline',
            }"
            :highlightLink="{ link: '/business-vertical/Outlet' }"
          >
          </sidebar-item>

          <sidebar-item
            :link="{ name: 'Outlet', icon: 'place' }"
            :highlightLink="{ link: '/Outlet/discount-category' }"
          >
            <sidebar-item
              :link="{
                name: 'Discount Category',
                path: '/outlet/discount-category',
              }"
              :highlightLink="{ link: '/outlet/edit-discount-category' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Other Payment Types',
                path: '/outlet/other-payment-types',
              }"
              :highlightLink="{ link: '/outlet/edit-other-payment' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Table', path: '/outlet/Table' }"
              :highlightLink="{ link: '/outlet/AddTable' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Table Area', path: '/outlet/table-area' }"
              :highlightLink="{ link: '/outlet/table-area-form' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Staffs', path: '/outlet/staffs' }"
              :highlightLink="{ link: '/outlet/ManageStaffForm' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Staff Roles', path: '/outlet/staff-roles' }"
              :highlightLink="{ link: '/outlet/manage-staff-role' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Profile', path: '/outlet/profile' }"
              :highlightLink="{ link: '/outlet/manage-profile' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Terminals', path: '/outlet/Terminals' }"
              :highlightLink="{ link: '/outlet/TerminalsForm' }"
            ></sidebar-item>
          </sidebar-item>

          <!-- Map Templates -->
          <!-- <sidebar-item  :link="{ name: 'Maps', icon: 'place' }">
          <sidebar-item
            :link="{ name: 'Google Maps', path: '/maps/google' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Full Screen Maps', path: '/maps/full-screen' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Vector Maps', path: '/maps/vector-map' }"
          ></sidebar-item>
        </sidebar-item>-->

          <!-- Widget Templates -->
          <!-- <sidebar-item 
          :link="{ name: 'Widgets', icon: 'widgets', path: '/widgets' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Charts', icon: 'timeline', path: '/charts' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Calendar', icon: 'date_range', path: '/calendar' }"
        ></sidebar-item>-->
          <mobile-menu></mobile-menu>
        </template>

        <template slot="links" v-else-if="userRole == 'admin'">
          <sidebar-item
            :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Settings', icon: 'settings' }"
            :highlightLink="{ link: '/Settings/AddModules' }"
          >
            <sidebar-item
              :link="{ name: 'Modules', path: '/Settings/Modules' }"
              :highlightLink="{ link: '/Settings/AddModules' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Integrations', path: '/Settings/integrations' }"
              :highlightLink="{ link: '/Settings/add-integrations' }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item :link="{ name: 'Distributors', icon: 'settings' }">
            <sidebar-item
              :link="{
                name: 'Distributors List',
                path: '/distributors/list-dist',
              }"
              :highlightLink="{ link: '/distributors/AddDistributor' }"
            ></sidebar-item>
            <sidebar-item
              style="display: none"
              :link="{
                name: 'Distributors Table',
                path: '/distributors/Distributors-table',
              }"
              :highlightLink="{ link: '/distributors/AddDistributor' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Outlets',
                path: '/business-vertical/all-outlets',
              }"
              :highlightLink="{ link: '/distributors/AddDistributor' }"
            ></sidebar-item>

            <sidebar-item
              :link="{
                name: 'Chains',
                path: '/distributors/BusinessVertical',
              }"
              :highlightLink="{ link: '/distributors/AddDistributor' }"
            ></sidebar-item>
          </sidebar-item>

          <mobile-menu></mobile-menu>
        </template>

        <template slot="links" v-else-if="userRole == 'Distributor'">
          <sidebar-item
            :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Business Vertical',
              path: '/distributors/BusinessVertical',
              icon: 'widgets',
            }"
            :highlightLink="{ link: '/distributors/BusinessVertical' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Settings', icon: 'settings' }"
            :highlightLink="{ link: '/Settings/AddModules' }"
          >
            <sidebar-item
              :link="{ name: 'Packages', path: '/Settings/Packages' }"
              :highlightLink="{ link: '/Settings/Packages' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Tax', path: '/Settings/add-tax' }"
              :highlightLink="{ link: '/Settings/add-tax' }"
            ></sidebar-item>
          </sidebar-item>

          <mobile-menu></mobile-menu>
        </template>

        <!-- <template slot="links" v-else-if="userRole == 'user'">
        <sidebar-item :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"></sidebar-item>
        <div v-for="item in dynamicmenu" :key="item.module_id">
          <sidebar-item  v-if="item.app_sub_module !== null"  :link="{ name: item.name  }"> -->
        <!-- v-if="ind.outlet_access == true" -->
        <!-- <sidebar-item v-for="ind in item.app_sub_module" :key="ind.name" :link="{ name: ind.name, path: ind.module_path }" ></sidebar-item>
              
          </sidebar-item>

          <sidebar-item v-else :link="{ name: item.name, path: item.module_path, icon: 'widgets' }" :highlightLink = "{ link: 'item.module_path' }">
            </sidebar-item>
        </div>
        <mobile-menu></mobile-menu>
      </template> -->
        <template slot="links" v-else-if="accountType == 'pixel'">
          <!-- <sidebar-item
            :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
          ></sidebar-item> -->
          <sidebar-item
            v-if="cbePayEnabled"
            :link="{
              name: 'Cbe Pay',
              icon: 'dashboard',
              path: '/integrations/cbe-pay',
            }"
          >
            <sidebar-item
              :link="{ name: 'Account', path: '/integrations/cbe-pay' }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Payments',
                path: '/integrations/payments',
              }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item>

            <sidebar-item
              :link="{
                name: 'Payout',
                path: '/integrations/payout',
              }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item>

            <sidebar-item
              :link="{
                name: 'Payout Reconciliation',
                path: '/integrations/payoutreconciliation',
              }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item>
            <!-- <sidebar-item
              :link="{
                name: 'Transactions',
                path: '/integrations/transactions',
              }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item> -->
          </sidebar-item>
        </template>

        <template slot="links" v-else>
          <sidebar-item
            :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
          ></sidebar-item>
          <slot v-for="item in dynamicmenu">
            <div
              :key="item.module_id"
              v-if="
                (item.name != 'Integrations' && userRole == 'branch_user') ||
                (item.app_sub_module !== null && userRole == 'user')
              "
            >
              <sidebar-item
                v-if="item.app_sub_module !== null"
                :link="{ name: item.name, icon: item.icon }"
                :class="getParentStatus(item.id, item.is_default)"
              >
                <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->

                <sidebar-item
                  v-for="ind in item.app_sub_module"
                  :key="ind.name"
                  :link="{ name: ind.name, path: ind.module_path }"
                  class="subitem"
                  :class="getSubStatus(ind.sub_module_id, ind.is_default)"
                  v-show="!excludedMenus.includes(ind.name)"
                >
                  <slot v-if="ind.name == 'Menu Items'">
                    <sidebar-item
                      :key="ind.path"
                      :link="{ name: ind.name, path: ind.module_path }"
                    >
                    </sidebar-item>
                    <sidebar-item
                      :link="{
                        name: 'Menu Item List',
                        path: '/products/MenuItemLists',
                      }"
                    >
                    </sidebar-item>
                  </slot>
                  <sidebar-item
                    v-for="nested in ind.nested_modules"
                    :key="nested.path"
                    :link="{ name: nested.name, path: nested.path }"
                  >
                  </sidebar-item>
                </sidebar-item>
              </sidebar-item>

              <sidebar-item
                v-else
                :link="{
                  name: item.name,
                  path: item.module_path,
                  icon: 'widgets',
                }"
                :highlightLink="{ link: 'item.module_path' }"
              >
              </sidebar-item>
            </div>
          </slot>
          <sidebar-item
            v-if="screenDesignEnabled"
            :link="{
              name: 'Screen Design',
              icon: 'grid',
              path: '/products/screen-design/menu-list',
            }"
          ></sidebar-item>

          <sidebar-item
            v-if="$store.state.account.profile.is_voucher"
            :link="{
              name: 'Vouchers',
              icon: 'dashboard',
              path: '/promotions/vouchers',
            }"
          >
            <sidebar-item
              :link="{ name: 'Vouchers', path: '/promotions/vouchers' }"
              :highlightLink="{ link: '/promotions/vouchers' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Voucher Report',
                path: '/promotions/voucher-report',
              }"
              :highlightLink="{ link: '/promotions/voucher-report' }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item
            :link="{
              name: 'Online Ordering',
              icon: 'settings',
              path: '/online-ordering/dashboard',
            }"
            :highlightLink="{ link: '/online-ordering' }"
            @click="navigateToDashboard()"
            v-if="isOnlineEnabled"
          >
            <sidebar-item
              :link="{ name: 'Dashboard', path: '/online-ordering/dashboard' }"
              :highlightLink="{ link: '/online-ordering/dashboard' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Menu Sections',
                path: '/online-ordering/menu-section',
              }"
              :highlightLink="{ link: '/online-ordering/menu-section' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Menu Item Availability',
                path: '/online-ordering/menu-item',
              }"
              :highlightLink="{ link: '/online-ordering/menu-item' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Orders',
                path: '/online-ordering/orders',
              }"
              :highlightLink="{ link: '/online-ordering/orders' }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Settings',
                path: '/online-ordering/settings',
              }"
              :highlightLink="{ link: '/online-ordering/settings' }"
            >
              <sidebar-item
                :link="{
                  name: 'Theme',
                  path: '/online-ordering/theme',
                }"
                :highlightLink="{ link: '/online-ordering/theme' }"
              >
              </sidebar-item>
              <sidebar-item
                :link="{
                  name: 'Order Types',
                  path: '/online-ordering/order-types',
                }"
                :highlightLink="{ link: '/online-ordering/settings' }"
              >
              </sidebar-item>
              <sidebar-item
                :link="{
                  name: 'Opening Hours',
                  path: '/online-ordering/settings',
                }"
                :highlightLink="{ link: '/online-ordering/settings' }"
              >
              </sidebar-item>
            </sidebar-item>
          </sidebar-item>
          <sidebar-item
            v-if="cbePayEnabled || cbePayEnabledHeadOffice"
            :link="{
              name: 'Cbe Pay',
              icon: 'dashboard',
              path: '/integrations/cbe-pay',
            }"
          >
            <sidebar-item
              v-if="cbePayEnabledoutlet"
              :link="{ name: 'Account', path: '/integrations/cbe-pay' }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item>
            <sidebar-item
              v-if="cbePayEnabledHeadOffice"
              :link="{
                name: 'Payments',
                path: '/integrations/payments',
              }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item>
            <sidebar-item
              v-if="cbePayEnabledHeadOffice"
              :link="{
                name: 'Payout',
                path: '/integrations/payout',
              }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item>
            <sidebar-item
              v-if="cbePayEnabledHeadOffice"
              :link="{
                name: 'Payout Reconciliation',
                path: '/integrations/payoutreconciliation',
              }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item>
            <!-- <sidebar-item
              :link="{
                name: 'Transactions',
                path: '/integrations/transactions',
              }"
              :highlightLink="{ link: '/integrations/cbe-pay' }"
            ></sidebar-item> -->
          </sidebar-item>

          <mobile-menu></mobile-menu>
        </template>
      </side-bar>
      <div class="main-panel dashboard-bg">
        <top-navbar
          @subscribe="handleSubscribe"
          :notifications="outletInfo.notifications"
          ref="topNav"
        ></top-navbar>

        <fixed-plugin
          v-if="
            checkDistributorURL() == '/distributors/list-dist' ||
            checkDistributorURL() == '/distributors/Distributors-table'
          "
          :distIcon="checkDistributorURL()"
        ></fixed-plugin>

        <div
          :class="{ content: !$route.meta.hideContent }"
          @click="toggleSidebar"
        >
          <zoom-center-transition :duration="200" mode="out-in">
            <!-- your content here -->
            <router-view></router-view>
          </zoom-center-transition>
        </div>
        <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
      </div>
    </div>
    <md-dialog
      :md-active.sync="subscriptionStatus"
      :md-click-outside-to-close="outletInfo.allow_skip_subscription"
      :md-close-on-esc="outletInfo.allow_skip_subscription"
      class="subscriptionModal"
    >
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements bg-grad"
      >
        <md-card-header class="modal-header text-white">
          Welcome to CBE Innova
        </md-card-header>

        <md-card-content>
          <!-- <p class="subhead text-primary">
            It’s time to renew your Subscription!
          </p> -->
          <p class="modal_content">
            {{ this.notificationMessage }}
          </p>
        </md-card-content>
        <md-button
          class="subscription_btn md-primary"
          @click="handleSubscribe()"
        >
          Proceed</md-button
        >
      </md-card>
    </md-dialog>
    <md-dialog
      :md-active.sync="subscriptionPaymentStatus"
      :md-click-outside-to-close="outletInfo.allow_skip_subscription"
      :md-close-on-esc="outletInfo.allow_skip_subscription"
      class="subscriptionModal paymentModal"
    >
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements bg-grad"
      >
        <md-card-header class="modal-header text-white">
          Welcome to CBE Innova
        </md-card-header>

        <md-card-content>
          <!-- <p class="subhead text-primary">
            It’s time to renew your Subscription!
          </p> -->
          <p class="modal_content">
            Your 3 month up front subscription payment is coming to an end on
            <strong>{{ outletInfo.stripe_trial_end }}</strong
            >. Please enter in the payment details you wish to use to pay for
            your Innova POS subscription going forward.
          </p>
        </md-card-content>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-button
              class="subscription_btn md-primary w-100"
              @click="handleSubscribe()"
            >
              Subscribe</md-button
            >
          </div>
          <div class="md-layout-item" v-if="outletInfo.allow_skip_subscription">
            <md-button
              class="subscription_btn md-primary w-100"
              @click="subscriptionPaymentStatus = false"
            >
              Skip</md-button
            >
          </div>
        </div>
      </md-card>
    </md-dialog>

    <md-dialog
      :md-active.sync="disableStatus"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
      class="subscriptionModal"
    >
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements bg-grad"
      >
        <md-card-header class="modal-header text-white">
          Welcome to CBE Innova
        </md-card-header>

        <md-card-content>
          <!-- <p class="subhead text-primary">
            It’s time to renew your Subscription!
          </p> -->
          <p class="modal_content">
            Account expired!! Please contact CBE support at 0818 373 000 to
            reactivate your account.
          </p>

          <!-- <p class="subhead text-primary">
            We’re very excited to have you onboard!
          </p> -->
        </md-card-content>
      </md-card>
    </md-dialog>

    <md-dialog
      :md-active.sync="openPricing"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
      class="subscriptionModal"
    >
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements bg-grad"
      >
        <md-dialog-title class="md-layout">
          <div class="md-layout-item md-size-50">Modules</div>
          <div class="md-layout-item md-size-50 close-icon">
            <i
              class="md-icon md-icon-font md-theme-default"
              @click="showSubDialog = false"
              >close</i
            >
          </div>
        </md-dialog-title>

        <div class="list">
          <md-list>
            <slot v-for="item in bulkModule">
              <md-list-item md-expand v-if="item.app_sub_module !== null">
                <div
                  class="module-detail"
                  :class="[
                    {
                      'color-dialog':
                        array.findIndex((el) => el == item.id) !== -1,
                    },
                  ]"
                >
                  <div class="md-list-item-text module-name">
                    {{ item.name }}
                  </div>
                  <md-checkbox
                    :model="array"
                    :value="item.id"
                    :true-value="item.id"
                    @change="
                      moduleCheckbox(item.name, item.id, item.app_sub_module)
                    "
                  ></md-checkbox>
                </div>
                <md-list slot="md-expand">
                  <md-list-item
                    class="md-inset"
                    v-for="(sub, index) in item.app_sub_module"
                    :key="index"
                  >
                    <div
                      class="sub-name-box"
                      :class="[
                        {
                          'color-dialog':
                            subarray.findIndex(
                              (el) => el == sub.sub_module_id
                            ) !== -1,
                        },
                      ]"
                    >
                      {{ sub.name }}
                      <slot v-if="sub.nested_modules != null">
                        <div class="md-layout nested-modules">
                          <div
                            class="nested md-layout-item md-size-100"
                            v-for="nested in sub.nested_modules"
                            :key="nested.path"
                          >
                            {{ nested.name }}
                          </div>
                        </div>
                      </slot>
                    </div>

                    <md-checkbox
                      :model="subarray"
                      :value="sub.sub_module_id"
                      :true-value="sub.sub_module_id"
                      @change="
                        subModuleCheckbox(
                          sub.name,
                          sub.module_path,
                          item.id,
                          sub.sub_module_id
                        )
                      "
                    ></md-checkbox>
                    <br />
                  </md-list-item>
                </md-list>
              </md-list-item>
              <md-list-item v-else>
                <div
                  class="module-detail"
                  :class="[
                    {
                      'color-dialog':
                        array.findIndex((el) => el == item.id) !== -1,
                    },
                  ]"
                >
                  <div class="md-list-item-text module-name">
                    {{ item.name }}
                  </div>
                  <md-checkbox
                    :model="array"
                    :value="item.id"
                    :true-value="item.id"
                    @change="
                      moduleCheckbox(item.name, item.id, item.app_sub_module)
                    "
                  ></md-checkbox>
                </div>
              </md-list-item>
            </slot>
          </md-list>
        </div>
      </md-card>
    </md-dialog>
    <md-dialog
      :md-active.sync="cbePayTerms"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
      class="subscriptionModal"
    >
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements bg-grad"
      >
        <md-card-content class="align-items-center d-flex flex-column">
          <md-field
            :class="[
              { 'md-error': errors.has('cbePaytermsAndCondition') },
              {
                'md-valid ':
                  !errors.has('cbePaytermsAndCondition') &&
                  touched.cbePaytermsAndCondition,
              },
            ]"
            class="subscription-plan"
          >
            <md-checkbox
              v-model="cbePaytermsAndCondition"
              v-validate="modelValidations.cbePaytermsAndCondition"
              data-vv-scope="terms"
              data-vv-name="cbePaytermsAndCondition"
              >I agree to the</md-checkbox
            >
            <span class="text-white">
              <a href="/cbepay_terms_and_condition.pdf" target="_blank"
                >terms and conditions</a
              >
              of CBE Pay.</span
            >
            <slide-y-down-transition>
              <div
                class="error"
                v-show="errors.has('terms.cbePaytermsAndCondition')"
              >
                CBE PAY terms and conditions is required
              </div>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon
                class="success"
                v-show="
                  !errors.has('cbePaytermsAndCondition') &&
                  touched.cbePaytermsAndCondition
                "
                >done</md-icon
              >
            </slide-y-down-transition>
          </md-field>
          <button
            type="button"
            class="md-button arrow-btn md-theme-default pad-10px width-110px rounded-pill"
            @click.prevent="acceptCbePayTerms"
          >
            Accept
          </button>
        </md-card-content>
      </md-card>
    </md-dialog>

    <!-- CBE PAY -->
    <!-- <div class="md-overlay" v-if="showCbePay"></div> -->
    <div
      class="cbe-pay-modal"
      v-if="showCbePay"
      @click="generateOnboardingLink()"
    >
      <strong><md-icon>notification_important</md-icon>Alert</strong>

      <div class="content-block">
        <p class="my-0">Continue Cbe Pay Onboarding</p>
        <!-- <button
          type="button"
          class="md-button arrow-btn md-theme-default pad-10px width-110px rounded-pill"
          @click.prevent="generateOnboardingLink()"
        >
          SUBMIT
        </button> -->
      </div>
    </div>
    <md-dialog
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
      class="cbe-pay-modal"
    >
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements bg-grad"
      >
        <md-card-header class="modal-header text-white">
          Continue Cbe Pay Onboarding
        </md-card-header>

        <md-card-content>
          <!-- <p class="subhead text-primary">
            It’s time to renew your Subscription!
          </p> -->
          <p class="modal_content">
            <button
              type="button"
              class="md-button arrow-btn md-theme-default pad-10px width-110px rounded-pill"
              @click.prevent="generateOnboardingLink()"
            >
              SUBMIT
            </button>
          </p>

          <!-- <p class="subhead text-primary">
            We’re very excited to have you onboard!
          </p> -->
        </md-card-content>
      </md-card>
    </md-dialog>
    <!-- <md-dialog
      :md-active.sync="notAlert"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
      class="notifModal"
    >
      <md-card
        class="stats-card md-layout-item md-medium-size-100 md-xsmall-size-100 stats-elements bg-grad"
      >
        <md-card-header class="modal-header text-white">
          Notice: Reduced VAT rate for hospitality in Republic of Ireland ended
          on 31st August 2023.
        </md-card-header>

        <md-card-content class="align-items-center d-flex flex-column">
          <p class="modal_content">
            From the 1st of September the reduced VAT rate of 9% has returned to
            13.5%. The following link has instructions on how to alter the VAT
            rate on your products in Innova POS. Please take the necessary steps
            to update your menu items in the back office and remember to "Cloud
            Sync" your Innova POS app once finished.
          </p>
          <p>
            <a
              href="https://onlinehelp.cbesoftware.com/webinars/changing-vat-on-cbe-innova-pos/"
              target="_blank"
              >https://onlinehelp.cbesoftware.com/webinars/changing-vat-on-cbe-innova-pos/</a
            >
          </p>
          <p>
            <md-button
              class="subscription_btn md-primary"
              @click="handleNotif()"
            >
              Ok</md-button
            >
          </p>
        </md-card-content>
      </md-card>
    </md-dialog> -->
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'

import 'perfect-scrollbar/css/perfect-scrollbar.css'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import FixedPlugin from '../../FixedPlugin.vue'
import UserMenu from './Extra/UserMenu.vue'
import { SlideYDownTransition } from 'vue2-transitions'

import { accountService } from './../../../services/account.js'
import { mapActions } from 'vuex'

import { ZoomCenterTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize'
import { i18n } from './../../../main.js'
import store from '../../../store'
import { cbePayService } from '@/services/cbePay.js'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    FixedPlugin,
    UserMenu,
    ZoomCenterTransition,
    SlideYDownTransition,
  },
  data() {
    return {
      subscriptionStatus: false,
      subscriptionPaymentStatus: false,
      userStatus: 'active',
      disableStatus: false,
      sidebarBackgroundColor: 'black',
      sidebarBackground: 'green',
      sidebarBackgroundImage: './img/sidebar-2.jpg',
      sidebarMini: true,
      sidebarImg: true,
      packageId: accountService.userPackage(),
      dynamicmenu: [],
      userRole: accountService.userRole(),
      distType: '',
      role_id: accountService.getRoleId(),
      excludedMenus: [],
      payment_info: {},
      isOnlineEnabled: false,
      cbePayEnabled: false,
      cbePayEnabledHeadOffice: false,
      cbePayEnabledoutlet: false,
      showCbePay: false,
      openPricing: false,
      packageModules: [],
      bulkModule: [],
      array: [],
      subarray: [],
      modelValidations: {
        termsAndCondition: {
          required: 'required:true',
        },
        cbePaytermsAndCondition: {
          required: 'required:true',
        },
      },
      touched: {
        termsAndCondition: false,
        cbePaytermsAndCondition: false,
      },
      termsAndCondition: false,
      terms: false,
      stripeSessionUrl: '',
      currentStep: 1,
      outletInfo: {},
      parentModuleIds: [],
      subModuleIds: [],
      notAlert: false,
      notificationMessage: '',
      accountType: 'innova',
      cbePaytermsAndCondition: false,
      cbePayTerms: false,
      screenDesignEnabled: false,
    }
  },

  computed: {
    displayUserMenu() {
      return window.innerWidth
    },
  },
  methods: {
    ...mapActions(['updateProfile']),
    generateToken() {
      accountService.generateToken().then((res) => {
        const token = res.data.token
        localStorage.setItem('token', token)
      })
    },
    getParentStatus(parentId, isDefault) {
      if (this.outletInfo.is_demo) {
        return 'active-sidebar'
      } else {
        return this.parentModuleIds.includes(parentId) || isDefault
          ? 'active-sidebar'
          : 'active-sidebar'
      }
    },
    getSubStatus(subId, isDefault) {
      if (this.outletInfo.is_demo) {
        return 'active-sidebar'
      } else {
        return this.subModuleIds.includes(subId) || isDefault
          ? 'active-sidebar'
          : 'inactive-sidebar'
      }
    },

    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
    handleSubscribe() {
      // window.open('http://localhost:8080/pricing/50', '_blank')

      if (this.payment_info.status == 'past_due' || this.payment_info.status == 'unpaid') {
        window.open(this.payment_info.session.url, '_blank')
      } else {
        if (accountService.userRole() == 'user') {
          this.$router.push('/Pricing/' + accountService.getOutletId())
        } else {
          this.$router.push(
            '/Pricing/' + accountService.getChainId() + '/chain'
          )
        }
      }
    },

    acceptCbePayTerms() {
      this.handleSubmit()
    },

    handleNotif() {
      let body = {
        outlets: {
          outlet: {
            noti_read_count: 1,
          },
        },
      }
      this.loader = true
      customizeService
        .editOutlet(accountService.getOutletId(), body)
        .then((res) => {
          this.notAlert = false
        })
    },
    handleSubmit() {
      this.$validator.validateAll('terms').then((result) => {
        const now = new Date()
        const dateTimeString = now.toLocaleString()
        if (result) {
          let body = {
            outlets: {
              outlet: {
                accept_cbepay_terms: this.cbePaytermsAndCondition,
                cbepay_accept_terms_date: dateTimeString,
                // accept_terms: this.cbePaytermsAndCondition,
                // is_migrated: false,
              },
            },
          }
          this.loader = true
          customizeService
            .editOutlet(accountService.getOutletId(), body)
            .then((res) => {
              // this.outletInfo.is_migrated = false
              this.cbePayTerms = false
              this.showCbePay = true
            })
        }
        // alert("Correct them errors!");
      })
    },
    handlePaymentMethodUpdate() {
      if (this.payment_info && this.payment_info.session) {
        window.open(this.payment_info.session.url, '_blank')
      }
    },
    goToStep(step) {
      // this.currentStep = step
      if (step == 2) {
        this.$validator.validateAll('terms').then((result) => {
          if (result) {
            this.currentStep = step
          }
          // alert("Correct them errors!");
        })
      } else {
        this.currentStep = step
      }
    },
    moduleCheckbox(moduleName, id, subModule) {
      let submod = JSON.parse(JSON.stringify(subModule))
      let index = this.array.findIndex((el) => el == id)
      if (this.packageModules == null) {
        this.packageModules = []
      }
      if (index !== -1) {
        this.packageModules.push({
          name: moduleName,
          id: id,
          app_sub_module: submod,
        })
        if (submod !== null) {
          for (var i = 0; i < submod.length; i++) {
            let subindex = this.subarray.findIndex(
              (el) => el == submod[i].sub_module_id
            )
            if (subindex == -1) {
              this.subarray.push(submod[i].sub_module_id)
            }
          }
        }
      } else {
        let index = this.packageModules.findIndex((el) => el.id == id)
        this.packageModules.splice(index, 1)
        if (submod !== null) {
          for (var i = 0; i < submod.length; i++) {
            let subindex = this.subarray.findIndex(
              (el) => el == submod[i].sub_module_id
            )
            this.subarray.splice(subindex, 1)
          }
        }
      }
    },
    getSidebarItems() {
      if (this.userRole == 'branch_user') {
        this.excludedMenus = [
          'iPad Grid',
          'Staff',
          'Terminals',
          'Tables',
          'Payment Type',
          'Void Reasons',
          'Order Types',
          'Combos',
          'Discount Category',
          'Scheduled Report',
          'Bank Of Ireland',
          'Roles',
        ]
      }

      if (
        this.userRole != '' &&
        this.userRole != 'admin' &&
        this.userRole != 'Distributor'
      ) {
        if (this.role_id == null) {
          customizeService
            .getPackagebyId(this.packageId)
            .then((res) => {
              const defaultModules = this.bulkModule.filter(
                (el) => el.is_default == true
              )
              const result = res.data.package_modules || []
              var ids = new Set(defaultModules.map((d) => d.id))

              // this.dynamicmenu = [
              //   ...defaultModules,
              //   ...result.filter((d) => !ids.has(d.id)),
              // ]
              this.parentModuleIds = result.map((a) => a.id)

              for (let i = 0; i < result.length; i++) {
                const innerArray = result[i].app_sub_module
                for (let j = 0; j < innerArray.length; j++) {
                  const item = innerArray[j]
                  this.subModuleIds.push(item.sub_module_id)
                }
              }

              this.dynamicmenu = this.packageModules
            })
            .catch((err) => {
              this.$router.push({
                name: 'Login',
                // params: { formType: "View Category", category: item }
              })
            })
        } else {
          customizeService.getPackageUsingRole(this.role_id).then((res) => {
            this.dynamicmenu = res.data.package_modules
          })
        }
      }
    },

    checkDistributorURL() {
      this.distType = this.$route.path
      return this.$route.path
    },

    getPackageModules() {
      let self = this
      customizeService.getPackagebyId(1).then((res) => {
        self.packageModules = res.data.package_modules || []

        self.packageName = res.data.name
        for (var i = 0; i < self.packageModules.length; i++) {
          this.array.push(self.packageModules[i].id)
          if (self.packageModules[i].app_sub_module !== null) {
            for (
              var j = 0;
              j < self.packageModules[i].app_sub_module.length;
              j++
            ) {
              this.subarray.push(
                self.packageModules[i].app_sub_module[j].sub_module_id
              )
            }
          }
        }

        customizeService.getModules().then((resp) => {
          for (var i = 0; i < resp.data.result.length; i++) {
            this.bulkModule.push(resp.data.result[i])
          }

          this.getSidebarItems()
        })
      })
    },

    navigateToDashboard() {
      this.$router.push({
        name: 'online-ordering',
      })
    },

    generateOnboardingLink() {
      cbePayService.goToOnboard().then((res) => {
        this.loader = false
        this.showCbePay = false
        // this.stripe_account_no = res.data.id
        window.open(res.data, '_blank')
      })
    },
    getCbePayCapabilities() {
      cbePayService.getCapabilities().then((res) => {
        const capabilities = res.data
        if (capabilities) {
          const completedCount = capabilities.filter(
            (entry) => entry.verificationStatus == 'Valid'
          ).length

          // console.log('Cap C', completedCount)
          if (completedCount != capabilities.length) {
            this.showCbePay = true
          }
        }
      })
    },
  },

  created() {
    if (accountService.userRole() == 'user') {
      customizeService
        .getOutletById(accountService.getOutletId())
        .then((res) => {
          let flag = sessionStorage.getItem('flag') || 0
          this.outletInfo = res.data.outlet
          store.state.account.outlet = res.data.outlet
          if (store.state.account.cbePayDialog) {
            this.getCbePayCapabilities()
          }

          this.accountType = this.outletInfo.account_type || 'innova'
          this.notAlert = this.outletInfo.noti_read_count <= 0 ? true : false
          // this.outletInfo.is_migrated = false
          this.$refs.topNav.notificationList = this.outletInfo.notifications
          this.payment_info = res.data.outlet.payment || {}
          this.isOnlineEnabled = res.data.outlet.is_online
          this.cbePayEnabled = res.data.outlet.enable_cbe_pay
          this.screenDesignEnabled = res.data.outlet.enable_screen_design
          if (this.cbePayEnabled == true) {
            this.cbePayEnabledHeadOffice = true
            this.cbePayEnabledoutlet = true
          }
          // this.cbePayTerms =
          //   this.cbePayEnabled && !res.data.outlet.accept_cbepay_terms
          store.state.account.isOnline = res.data.outlet.is_online

          this.disableStatus =
            res.data.login.status == 'disabled' ||
            res.data.login.status == 'inactive'
              ? true
              : false

          if (!this.disableStatus) {
            this.subscriptionStatus =
              res.data.outlet.subscription_enabled &&
              !this.disableStatus &&
              this.outletInfo.subscription_required_type == 1

            if (!this.outletInfo.allow_bo) {
              this.subscriptionStatus = true
              this.payment_info = res.data.outlet.payment
              this.notificationMessage =
                'A recent notification advise of an issue your payment for Innova POS services. Our Accounts team has attempted to contact you in the interim in order to discuss the matter. Unfortunately the matter has not been resolved and at this time access to your Innova POS account has been reduced to POS only. Please make contact with Accounts in order to reinstate your backoffice access and to avoid any further disruptions.'
            } else if (
              this.payment_info.status == 'past_due' || this.payment_info.status == 'unpaid' &&
              this.outletInfo.allow_bo
            ) {
              this.subscriptionStatus = true
              this.payment_info = res.data.outlet.payment
              this.notificationMessage =
                'Your Innova POS Subscription status has changed from Active to Incomplete or Past Due. This may be as a result of an issue with the payment type you defined in the Stripe dashboard. Please check this at your earliest convenience to avoid any disruption to your Innova POS service.  If you have any further queries please contact our accounts team on 0818 373 000'
            } else {
              this.payment_info.status = 'active'
            }

            this.subscriptionPaymentStatus =
              this.outletInfo.subscription_required_type == 0 && flag == 0

            this.cbePayTerms =
              res.data.outlet.enable_cbe_pay &&
              !res.data.outlet.accept_cbepay_terms &&
              flag == 0 &&
              !this.subscriptionPaymentStatus
          }

          // this.subscriptionStatus = true
        })
      // this.getPackageModules()
    } else {
      customizeService
        .BusinessVertbyId(accountService.getChainId())
        .then((res) => {
          this.subscriptionStatus = res.data.chain.subscription_enabled
          this.payment_info = res.data.chain.payment || {}
          this.screenDesignEnabled = true
        })
    }
    if (this.cbePayEnabled == false) {
      cbePayService
        .getEnableCbyPayMenu(accountService.getChainId())
        .then((res) => {
          this.cbePayEnabledHeadOffice = res.data
        })
    }
    // if (accountService.userRole() == 'user') {
    this.updateProfile()
    // }
    this.checkDistributorURL()
    // this.getSidebarItems()
    this.getPackageModules()

    this.generateToken()
    i18n.locale = accountService.getLanguage()
  },
  mounted() {
    let docClasses = document.body.classList
    let isWindows = navigator.platform.startsWith('Win')
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar('sidebar')
      initScrollbar('sidebar-wrapper')
      initScrollbar('main-panel')

      docClasses.add('perfect-scrollbar-on')
    } else {
      docClasses.add('perfect-scrollbar-off')
    }
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar()
    },
  },
}
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.sideBar {
  padding-left: 15px;
}
</style>
<style>
.subscription-plan .md-checkbox .md-checkbox-label {
  color: #ffffff !important;
}

.inactive-sidebar {
  pointer-events: none;
  opacity: 0.5;
}

.notifModal .md-dialog-container.md-theme-default {
  background-color: transparent !important;
}

.notifModal .modal_content {
  max-width: 90% !important;
}

.notifModal .modal-header {
  text-align: center;
  max-width: 70%;
  margin: auto;
  padding-top: 20px;
}

.notifModal .md-theme-default a:not(.md-button) {
  color: #54b9ff !important;
}

.paymentModal .md-dialog-container {
  max-width: 700px;
  width: 100%;
}

.paymentModal .modal_content {
  max-width: 100%;
  font-size: 19px;
  padding: 0px 25px 10px;
}

.subscriptionModal .modal_content {
  text-align: center;
  max-width: 640px;
  width: 100%;
}

.cbe-pay-modal {
  z-index: 999999 !important;
  background-color: #fff5eb;
  border-left: 4px solid #fa7f05;
  width: 450px;
  display: flex;
  flex-flow: column;
  position: fixed;
  right: 10px;
  top: 10px;
  padding: 10px;
  cursor: pointer;
}

.cbe-pay-modal strong {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.cbe-pay-modal .md-icon.md-theme-default.md-icon-font {
  color: #fa7f05 !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  margin: 0 6px 0 0px;
}
</style>
