<template>
  <div class="full-div password-reset">
    <div
      class="md-layout text-center login-card d-flex align-items-center h-90vh"
    >
      <div
        class="md-layout-item md-medium-size-100 md-xlarge-size-40 md-large-size-40"
      >
        <form class="md-layout-item">
          <md-card>
            <h4 class="card-header">
              <a href="#/login">
                <img
                  class="logo-img"
                  src="@/assets/images/cbe_innova_logo.png"
                />
              </a>
            </h4>
            <p class="h3">Welcome To CBEInnova</p>
            <md-card-content v-if="!loader">
              <div class="md-layout d-flex align-items-center flex-column">
                <div class="md-layout-item md-size-75 p-0 position-relative">
                  <ul>
                    <li v-bind:class="{ is_valid: contains_eight_characters }">
                      8 Characters
                    </li>
                    <li v-bind:class="{ is_valid: contains_number }">
                      Contains Number
                    </li>

                    <li v-bind:class="{ is_valid: contains_uppercase }">
                      Contains Uppercase
                    </li>
                    <li v-bind:class="{ is_valid: contains_special_character }">
                      Contains Special Character
                    </li>
                  </ul>

                  <div
                    class="checkmark_container"
                    v-bind:class="{ show_checkmark: valid_password }"
                  >
                    <svg width="50%" height="50%" viewBox="0 0 140 100">
                      <path
                        class="checkmark"
                        v-bind:class="{ checked: valid_password }"
                        d="M10,50 l25,40 l95,-70"
                      />
                    </svg>
                  </div>
                </div>
                <div class="md-layout-item md-size-75">
                  <md-field
                    :class="[
                      {
                        'md-error':
                          errors.has('new_password') || !valid_password,
                      },
                      {
                        'md-valid':
                          !errors.has('new_password') && touched.new_password,
                      },
                    ]"
                  >
                    <label>{{ $t('New Password') }}*</label>
                    <md-input
                      v-model="password.new_password"
                      data-vv-name="new_password"
                      type="password"
                      v-validate="modelValidations.new_password"
                    ></md-input>
                    <slide-y-down-transition>
                      <div
                        class="error text-danger"
                        v-show="errors.has('new_password')"
                      >
                        {{ errors.first('new_password') }}
                      </div>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="
                          !errors.has('new_password') && touched.new_password
                        "
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-75">
                  <md-field
                    :class="[
                      { 'md-error': errors.has('confirmPassword') },
                      {
                        'md-valid':
                          !errors.has('confirmPassword') &&
                          touched.confirmPassword,
                      },
                    ]"
                  >
                    <label>{{ $t('Confirm Password') }}*</label>
                    <md-input
                      v-model="confirmPassword"
                      data-vv-name="confirmPassword"
                      type="password"
                      v-validate="modelValidations.confirmPassword"
                    ></md-input>
                    <slide-y-down-transition>
                      <div
                        class="error text-danger"
                        v-show="errors.has('confirmPassword')"
                      >
                        {{ errors.first('confirmPassword') }}
                      </div>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon
                        class="success"
                        v-show="
                          !errors.has('confirmPassword') &&
                          touched.confirmPassword
                        "
                        >done</md-icon
                      >
                    </slide-y-down-transition>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-100">
                  <div class="error-msg text-danger" v-if="formError != ''">
                    {{ formError }}
                  </div>
                </div>
              </div>

              <!-- ----------------------------------------------------------------------------------- -->
              <div class="btn-div">
                <md-card-actions class="text-center mt-4">
                  <md-button
                    native-type="submit"
                    @click.native.prevent="validate"
                    class="md-teritiary"
                    >{{ $t('SAVE') }}</md-button
                  >
                </md-card-actions>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import axios from 'axios'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,

      old_password: '',
      switch2: null,
      new_password: '',
      confirmPassword: '',
      touched: {
        new_password: false,
        old_password: false,
        confirmPassword: false,
      },
      modelValidations: {
        old_password: {
          required: true,
        },
        new_password: {
          required: true,
        },
        confirmPassword: {
          required: true,
        },
      },
      password: {
        new_password: null,
        old_password: '',
      },
      loader: false,
      formError: '',
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        console.log('valid', isValid)
        if (isValid && this.valid_password) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.submitPassword()
          }
        })
      }
    },

    submitPassword() {
      const token = this.$route.params.token
      if (this.confirmPassword != this.password.new_password) {
        this.formError = 'Passwords do not match'
      } else {
        this.formError = ''
        accountService
          .newPassword(this.password, token)
          .then((res) => {
            // alert(res.data.message)
            if (res.data.success) {
              Swal.fire({
                title: 'Sucess',
                text: `Your password changed sucessfully!`,
                type: 'sucess',
                showCancelButton: false,
                confirmButtonClass: 'md-button md-success btn-fill',
                cancelButtonClass: 'md-button md-danger btn-fill',
                confirmButtonText: 'Login Now!',
                buttonsStyling: false,
              }).then((result) => {
                this.$router.push({
                  name: 'Login',
                })
              })
            } else {
              Swal.fire({
                title: res.data.message,
                text: `Please contact support`,
                type: 'warning',
                confirmButtonClass: 'md-button md-success btn-fill',
                buttonsStyling: false,
              })
            }
          })
          .catch(() => {
            formError = 'Please try again'
          })
      }
    },
    checkPassword() {
      this.password_length = this.password.new_password.length
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

      if (this.password_length > 8) {
        this.contains_eight_characters = true
      } else {
        this.contains_eight_characters = false
      }

      this.contains_number = /\d/.test(this.password.new_password)
      this.contains_uppercase = /[A-Z]/.test(this.password.new_password)
      this.contains_special_character = format.test(this.password.new_password)

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true
      } else {
        this.valid_password = false
      }
    },
  },
  created() {},
  computed: {
    newPassword() {
      return this.password.new_password
    },
  },
  watch: {
    old_password() {
      this.touched.required = true
    },
    'password.new_password': function (newVal, oldVal) {
      // this.touched.required = true
      this.checkPassword()
    },
    confirmPassword() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}

.logo-img {
  width: 35% !important;
}
.h-90vh {
  height: 90vh;
}

h2 {
  text-align: center;
  color: #fff;
  font-weight: 400;
}

ul {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

li {
  margin-bottom: 8px;
  color: #525f7f;
  position: relative;
}

li:before {
  content: '';
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
}

#app {
  width: 400px;
}

/* Password Input --------- */

.input_container {
  position: relative;
  padding: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 6px;
  background: #fff;
}

input[type='password'] {
  line-height: 1.5;
  display: block;
  color: rgba(136, 152, 170, 1);
  font-weight: 300;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #fff;
  transition: border-color 0.4s ease;
  border: 1px solid #cad1d7;
  outline: 0;
}

input[type='password']:focus {
  border-color: rgba(50, 151, 211, 0.45);
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;
  background: #2ecc71;
  width: 50px;
  height: 50px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
p-0 {
  padding: 0 !important;
}
.password-reset ul {
  list-style: none;
  margin: 0 0 15px;
  padding: 0;
  background: rgba(225, 225, 255, 0.4);
  padding: 12px;
  border-radius: 11px;
}
.password-reset li {
  color: #eddddd !important;
  margin-bottom: 2px !important;
  position: relative;
}
.password-reset input {
  background: transparent !important;
  border: none !important;
}
.password-reset li.is_valid:before {
  content: '✓' !important;
  color: #2ecc71;
  background: none;
  position: absolute;
  top: 0;
  left: inherit;
  right: -20px;
  text-align: right;
}
.password-reset .show_checkmark {
  right: 9px;
  top: 9px;
  width: 35px;
  height: 35px;
}
.password-reset .card-header {
  margin: 5px 0;
}
.password-reset input {
  padding: 0 !important;
}
.password-reset .md-field.md-theme-default.md-has-value .md-input {
  -webkit-box-shadow: 0 0 0 30px #1a3137 inset;
}
</style>
