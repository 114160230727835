<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <h3>Table Position</h3>
        </div>
      </md-card-header>

      <md-card-content class="ipad">
        <!-- <div class="md-layout-item md-size-50">
          <md-field>
            <label>{{ $t('Floor Plan Zoom Factor') }}</label>
            <md-input type="text" v-model="zoom_factor"></md-input>
          </md-field>
        </div> -->
        <div class="re-arrange">
          <md-field class="flr-field">
            <!-- <label for="comboGroup">{{$t('Combo Group')}}</label> -->
            <md-select v-model="flrId">
              <md-option
                :value="item.id"
                v-for="item in tableAreaList"
                :key="item.id"
                @select="areaChanged(item.id)"
                >{{ item.floor_name }}</md-option
              >
            </md-select>
          </md-field>
          <h2>Rearrange Table</h2>
        </div>

        <div class="ipad-moveable">
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>
          <vue-draggable-resizable
            v-for="item in tablePosition.filter(
              (it) => it.outlet_floor_id == flrId
            )"
            class="draggable-table"
            :key="item.id"
            :x="item.table_left"
            :y="item.table_top"
            :w="zoom_factor != '' && item.w != '' ? item.w * zoom_factor : 0"
            :h="zoom_factor != '' && item.h != '' ? item.h * zoom_factor : 0"
            @dragging="(left, top) => dragging(item.id, left, top)"
            @dragstop="(left, top) => dragstop(item.id, left, top)"
            @click="dragClick(item.id)"
            :resizable="false"
            :parent="true"
            :class="[
              { circle: item.shape == '1' },
              { square: item.shape == '0' },
              { 'rect-top': item.shape == '2' },
              { 'rect-bottom': item.shape == '3' },
              { 'rect-left': item.shape == '4' },
              { 'rect-right': item.shape == '5' },
              { 'pos-drag': item.id == currentDragId && item.draggedCss == 1 },
              {
                'not-pos-drag':
                  item.id != currentDragId && item.draggedCss == 0,
              },
            ]"
          >
            <p v-longclick="() => changeValue()">
              {{ item.name }}
              <!-- X: {{x }} / Y: {{ y }} -->
            </p>
          </vue-draggable-resizable>
        </div>
        <div class="md-layout justify-content-center mt-25">
          <input
            type="range"
            min="0.5"
            max="3"
            step="0.1"
            v-model="zoom_factor"
          />
        </div>
        <div class="text-center">Adjust Table Size</div>
      </md-card-content>
      <md-card-actions class="btn-div text-center">
        <md-button
          native-type="submit"
          class="md-primary"
          @click="updateTablePos"
          >{{ $t('SAVE') }}</md-button
        >
      </md-card-actions>
    </md-card>
    <md-dialog :md-active.sync="modDialog" class="table-popup">
      <md-dialog-title class="md-layout">
        <div class="close-icon">
          <i
            class="md-icon md-icon-font md-theme-default text-white"
            @click="modDialog = false"
            >close</i
          >
        </div>
      </md-dialog-title>
      <div class="rearrange-table">
        <ul class="first-ul">
          <li class="first-li" @click="modalOpen(lastId, '1')"></li>
          <li class="second-li" @click="modalOpen(lastId, '0')"></li>
          <li class="third-li" @click="modalOpen(lastId, '2')"></li>
        </ul>
        <ul class="second-ul">
          <li class="four-li" @click="modalOpen(lastId, '3')"></li>
          <li class="five-li" @click="modalOpen(lastId, '4')"></li>
          <li class="six-li" @click="modalOpen(lastId, '5')"></li>
        </ul>
      </div>
    </md-dialog>
    <!-- <div v-if="modDialog=true">
       <div class="close-icon">
          <i class="md-icon md-icon-font md-theme-default" @click="modDialog=false">close</i>
        </div>
       <div class="rearrange-table">
        <ul class="first-ul">
          <li class="first-li" @click="modalOpen(lastId,'0')"></li>
          <li class="second-li" @click="modalOpen(lastId,'1')"></li>
          <li class="third-li" @click="modalOpen(lastId,'2')"></li>
        </ul>
        <ul class="second-ul">
          <li class="four-li" @click="modalOpen(lastId,'3')"></li>
          <li class="five-li" @click="modalOpen(lastId,'4')"></li>
          <li class="six-li" @click="modalOpen(lastId,'5')"></li>
        </ul>
      </div>
    </div>-->
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import axios from 'axios'
import { required, minLength, between, email } from 'vuelidate/lib/validators'
import { accountService } from '../../../services/account.js'
import VueDraggableResizable from 'vue-draggable-resizable'
import { Promise, async } from 'q'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  components: {
    SlideYDownTransition,
    VueDraggableResizable,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      position: {},
      lastId: '',
      shape: [],
      modDialog: false,
      flrId: '',
      tabeList: [],
      tableAreaList: [],
      tablePosition: [],
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      table: {},
      currentDragId: '',
      loader: false,
      zoom_factor: 1,
    }
  },
  methods: {
    onResize: function (x, y, width, height) {
      this.x = x
      this.y = y
      this.width = width
      this.height = height
    },
    onDrag: function (x, y) {
      this.x = x
      this.y = y
    },
    changeValue() {
      this.modDialog = true
    },
    getDetails() {
      this.loader = true
      const bodyStyles = document.body.style

      const p1 = new Promise((resolve, reject) => {
        customizeService
          .getTable()
          .then((res) => {
            const zoomFactor = res.data.floor_plan_zoom_factor
              ? res.data.floor_plan_zoom_factor
              : this.zoom_factor
            bodyStyles.setProperty('--primary-zoom-factor', zoomFactor + 'px')
            this.tabeList = res.data.tables.filter(
              (el) => el.status == 'active'
            )
            this.zoom_factor = res.data.floor_plan_zoom_factor
            customizeService
              .getTableAreas()
              .then((res) => {
                resolve(res.data.table_areas)
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => console.log(err))
      })
      Promise.all([p1]).then((val) => {
        this.loader = false
        this.tableAreaList = val[0].filter((el) => el.status == 'active')
        this.flrId = this.tableAreaList[0].id
      })
    },
    dragging(id, left, top) {
      this.currentDragId = id
      this.tablePosition.forEach((position) => {
        if (position.id == id) {
          let index = this.tablePosition.findIndex((el) => el.id == id)
          this.tablePosition[index]['draggedCss'] = 1
        }
      })
    },
    dragstop(id, left, top) {
      this.lastId = id
      this.tablePosition.forEach((position) => {
        if (position.id == id) {
          let index = this.tablePosition.findIndex((el) => el.id == id)
          this.tablePosition[index]['table_left'] = left
          this.tablePosition[index]['table_top'] = top
        }
      })
    },
    areaChanged(id) {
      var x = 0
      var y = 0
      this.tabeList.forEach((el) => {
        delete el.outlet_id
        delete el.sequence_no
        delete el.chair_no
        delete el.tot_value
        delete el.status
        delete el.description
        delete el.url
      })

      const screenWidth = document.querySelector('.ipad-moveable').offsetWidth
      let tableX = 5
      let tableY = 5

      this.tabeList.forEach((table) => {
        if (id == table.outlet_floor_id) {
          if (this.tablePosition.findIndex((el) => el.id == table.id) == -1) {
            this.tablePosition.push(table)
            // this.tablePosition[this.tablePosition.length - 1]["table_left"] = x;
            // this.tablePosition[this.tablePosition.length - 1]["table_top"] = y;
            this.tablePosition[this.tablePosition.length - 1]['w'] = 75
            this.tablePosition[this.tablePosition.length - 1]['h'] = 75
            if (table.table_left == null && table.table_top == null) {
              if (tableY + 80 > screenWidth) {
                tableX += 80
                tableY = 5
              }
              table.table_left = tableY
              table.table_top = tableX
              tableY += 80

              this.tablePosition[this.tablePosition.length - 1][
                'draggedCss'
              ] = 0
            } else {
              this.tablePosition[this.tablePosition.length - 1][
                'draggedCss'
              ] = 1
            }
            // this.tablePosition[this.tablePosition.length - 1]["shape"] = "0";

            x = x + 75
            // if(this.tablePosition.length%7==0){
            //   y=100*(this.tablePosition.length/7);
            //   x=0;
            // }
          }
        }
      })
    },
    modalOpen(id, val) {
      var shapeIndex = this.tablePosition.findIndex((el) => el.id == id)
      this.tablePosition[shapeIndex].shape = val
      switch (val) {
        case '0':
          this.tablePosition[shapeIndex]['w'] = 75
          this.tablePosition[shapeIndex]['h'] = 75
          break
        case '1':
          this.tablePosition[shapeIndex]['w'] = 75
          this.tablePosition[shapeIndex]['h'] = 75
          break
        case '2':
          this.tablePosition[shapeIndex]['w'] = 56.25
          this.tablePosition[shapeIndex]['h'] = 75
          break
        case '3':
          this.tablePosition[shapeIndex]['w'] = 75
          this.tablePosition[shapeIndex]['h'] = 56.25
          break
        case '4':
          this.tablePosition[shapeIndex]['w'] = 75
          this.tablePosition[shapeIndex]['h'] = 75
          break
        case '5':
          this.tablePosition[shapeIndex]['w'] = 75
          this.tablePosition[shapeIndex]['h'] = 75
          break
      }
      this.modDialog = false
    },
    updateTablePos() {
      this.tablePosition.forEach((el) => {
        this.table[el.id] = {
          table_left: el.table_left,
          table_top: el.table_top,
          shape: el.shape,
        }
      })
      customizeService
        .updateTablePosition(this.table, this.zoom_factor)
        .then((res) => {
          reusableService.showSwal('Update').then((result) => {})
        })
    },
  },
  created() {
    this.getDetails()
  },
  beforeCreate() {},
  watch: {
    flrId: function (event) {
      console.log('ididid', event)
      this.areaChanged(event)
    },
    zoom_factor: function (event) {
      if (event != '') {
        const bodyStyles = document.body.style
        bodyStyles.setProperty('--primary-zoom-factor', event + 'px')
      } else {
        this.zoom_factor = 1
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ipad {
  //   width: 80%;
  //  min-height: calc(100vh - 123px);
  width: 900px;
  height: 750px;
  // background-image: url('../../../assets/images/ipad-fliped-bt-top.png');
  background: linear-gradient(
    109.46deg,
    rgba(201, 201, 201, 0.8) 1.57%,
    rgba(196, 196, 196, 0.1) 100%
  );
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  margin: auto;
  position: relative;
}
.ipad-moveable {
  // min-height: calc(100vh - 305px);
  overflow: hidden;
  border: 1px solid #fff;
  position: relative;
  height: 522px;
  width: 845px;
  margin: 0% 3%;
}
.draggable-table {
  background-color: white;
  float: left;
  // display: inline-block;
  text-align: center;
  // line-height: calc(75 * 0.65px) !important;
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  margin: 1px;
}
.re-arrange {
  background-color: #49e0db;
  border-bottom: 1px solid #ddd;
  padding: 0 28px;
  height: 45px;
  width: 86%;
  margin: 60px 7% 10px;
  text-align: center;
  display: flex;
  h2 {
    font-size: 24px;
    color: white;
    font-size: 24px;
    color: #000;
    width: 60%;
    text-align: center;
    margin-top: 5px;
  }
}

.flr-field {
  width: 150px;

  margin-top: 0px;
}
.rearrange-table {
  padding: 0px 20px 20px;
  li {
    &:hover {
      background-color: #00e0db !important;
    }
  }
  .first-ul {
    padding: 0px;
    display: flex;
    margin: 10px 10px;
    padding-bottom: 25px;
    li {
      list-style: none;
      background-color: #dadada;
      cursor: pointer;
    }
  }
  .second-ul {
    display: flex;
    padding: 0px;
    margin: 10px 10px;
    li {
      list-style: none;
      cursor: pointer;
      background-color: #dadada;
      &:first-child {
        margin-top: 15px;
      }
    }
  }
}
.md-dialog-fullscreen {
  min-width: 330px;
}
.first-li {
  width: 60px;
  height: 60px;
  border-radius: 590%;
}
.second-li {
  width: 60px;
  height: 60px;
  margin: 0px 75px;
}
.third-li {
  width: 40px;
  height: 60px;
}
.four-li {
  width: 60px;
  height: 40px;
}
.five-li {
  width: 40px;
  height: 60px;
  margin: 0px 75px;
  transform: rotate(-45deg);
}
.six-li {
  width: 40px;
  height: 60px;
  transform: rotate(45deg);
}
.md-dialog-title {
  position: relative;
  height: 40px;
  margin-bottom: 0px;
  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
}

.square {
  margin: 0px;
  text-align: center;
  // line-height: calc(75 * 0.65px) !important;
  align-items: center;
  justify-content: center;
  display: flex;

  border: none;
  // width: 75px !important;
  // height: 100px !important;
}
.rect-top {
  height: calc(75 * var(--primary-zoom-factor)) !important;
  width: calc(56.25 * var(--primary-zoom-factor)) !important;
  text-align: center;
  // line-height: calc(75 * 0.68px);
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
}
.circle {
  border-radius: 50%;
  width: calc(100 * var(--primary-zoom-factor)) !important;
  height: calc(100 * var(--primary-zoom-factor)) !important;
  text-align: center;
  // line-height: calc(75 * 0.65px) !important;
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
}
.rect-right {
  width: calc(75 * var(--primary-zoom-factor)) !important;
  height: calc(100 * var(--primary-zoom-factor)) !important;
  // transform: rotate(50deg) !important;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);

  // line-height: calc(50.25px) !important;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
}
.rect-left {
  width: calc(75 * var(--primary-zoom-factor)) !important;
  height: calc(100 * var(--primary-zoom-factor)) !important;
  // transform: rotate(-50deg) !important;
  clip-path: polygon(0 1%, 75% 0, 100% 100%, 23% 100%);

  // line-height: calc(50.25px) !important;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  border: 0;
}
.rect-bottom {
  width: calc(75 * var(--primary-zoom-factor)) !important;
  height: calc(56 * var(--primary-zoom-factor)) !important;
  text-align: center;
  // line-height: calc(75 * 0.4px) !important;
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
}
.pos-drag {
  position: absolute;
}
.not-pos-drag {
  position: absolute !important;
}
.btn-div {
  text-align: center;
  margin: auto;
}
</style>
