<template>
  <div class="mainDiv md-card block-bg">
    <div class="head text-white">Theme Selector</div>
    <div class="md-layout topDiv">
      <div
        class="input-group color-picker left-padding md-layout-item md-size-50"
        ref="colorpickerprimary"
      >
        <h6 class="mt-0 text-white">{{ $t('Primary Color') }}</h6>
        <md-field>
          <!-- <label>{{ $t('Primary Color') }}</label> -->
          <br />
          <input
            type="text"
            class="form-control colorInput text-white"
            v-model="primaryColor"
            @focus="showPicker('primary')"
            @input="updateFromInput('primary')"
          />
          <span class="input-group-addon color-picker-container">
            <span
              class="current-color"
              :style="'background-color: ' + primaryColor"
              @click="togglePicker('primary')"
            ></span>
            <chrome-picker
              :value="colors"
              @input="updateFromPicker"
              v-if="displayPicker.primary"
            />
          </span>
        </md-field>
      </div>
      <div
        class="input-group color-picker md-layout-item md-size-50"
        ref="colorpickersecondary"
      >
        <h6 class="text-white">{{ $t('Secondary Color') }}</h6>
        <md-field>
          <!-- <label>{{ $t('Secondary Color') }}</label> -->
          <input
            type="text"
            class="form-control colorInput text-white"
            v-model="secondaryColor"
            @focus="showPicker('secondary')"
            @input="updateFromInput('secondary')"
          />
          <span class="input-group-addon color-picker-container">
            <span
              class="current-color"
              :style="'background-color: ' + secondaryColor"
              @click="togglePicker('secondary')"
            ></span>
            <chrome-picker
              :value="colors"
              @input="updateFromPicker"
              v-if="displayPicker.secondary"
            />
          </span>
        </md-field>
      </div>
      <!-- LOGO -->
      <div class="md-layout-item md-size-50 md-xsmall-size-100 min-300">
        <h5 class="text-white">Logo</h5>
        <div class="file-input">
          <div v-if="!logoImageRegular">
            <div class="image-container box-shadow-none">
              <img :src="logoRegularImg" title="" />
            </div>
          </div>
          <div class="image-container" v-else>
            <img :src="logoImageRegular" />
          </div>
          <div class="button-container">
            <md-button
              class="md-danger md-round"
              @click="deleteImage('logo')"
              v-if="logoRegularImg"
              ><i class="fa fa-times"></i>Delete</md-button
            >
            <md-button
              class="md-danger md-round"
              @click="removeImage('logo')"
              v-if="logoImageRegular"
              ><i class="fa fa-times"></i>Remove</md-button
            >
            <md-button class="md-success md-round md-fileinput">
              <template v-if="!logoImageRegular">Upload Logo</template>
              <template v-else>Change</template>
              <input type="file" @change="onFileChange" />
            </md-button>
          </div>
        </div>
      </div>
      <!-- BANNER -->
      <div class="md-layout-item md-size-50 md-xsmall-size-100 min-300">
        <h5 class="text-white">Banner(Prfearble size is 1185px X 182px)</h5>
        <div class="file-input">
          <div v-if="!bannerImageRegular">
            <div class="image-container box-shadow-none">
              <img :src="bannerRegularImg" title="" />
            </div>
          </div>
          <div class="image-container" v-else>
            <img :src="bannerImageRegular" />
          </div>
          <div class="button-container">
            <md-button
              class="md-danger md-round"
              @click="deleteImage('banner')"
              v-if="bannerRegularImg"
              ><i class="fa fa-times"></i>Delete</md-button
            >
            <md-button
              class="md-danger md-round"
              @click="removeImage('banner')"
              v-if="bannerImageRegular"
              ><i class="fa fa-times"></i>Remove</md-button
            >
            <md-button class="md-success md-round md-fileinput">
              <template v-if="!bannerImageRegular">Upload Banner</template>
              <template v-else>Change</template>
              <input type="file" @change="onFileChange($event, 'banner')" />
            </md-button>
          </div>
        </div>
      </div>
    </div>

    <div class="btnDiv">
      <md-card-actions class="text-center">
        <md-button native-type="submit" @click="submit()" class="md-primary">{{
          $t('SAVE')
        }}</md-button>
      </md-card-actions>
    </div>
  </div>
</template>
<script>
import { Chrome } from 'vue-color'
import { accountService } from '../../../services/account.js'
import { customizeService } from '../../../services/customize.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import Swal from 'sweetalert2'
import { reusableService } from '../../../services/reusable.js'

export default {
  components: {
    'chrome-picker': Chrome,
  },
  data() {
    return {
      displayPicker: { primary: false, secondary: false },
      primaryColor: '',
      secondaryColor: '',
      activePicker: 'primary',
      storeSettings: {},
      colors: { r: 255, g: 0, b: 0 },
      theme: {},
      logoRegularImg: '',
      logoImageRegular: '',
      logoFile: '',
      bannerFile: '',
      bannerRegularImg: '',
      bannerImageRegular: '',
    }
  },
  // props: {
  //   theme: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  methods: {
    setColor(color) {
      this.updateColors(color)
      displayPicker.primary
        ? (this.primaryColor = color)
        : (this.secondaryColor = color)
    },
    updateColors(color) {
      if (color.slice(0, 1) == '#') {
        this.colors = {
          hex: color,
        }
      } else if (color.slice(0, 4) == 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex =
            '#' +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1)
        this.colors = {
          hex: hex,
          a: rgba[3],
        }
      }
    },
    showPicker(colorType) {
      document.addEventListener('click', this.documentClick)

      if ((this.displayPicker[this.activePicker] = false)) {
        this.hidePicker()
        this.displayPicker[colorType] = true
        this.activePicker = colorType
      } else {
        this.displayPicker[colorType] = true
        this.activePicker = colorType
      }

      // this.displayPicker[colorType] = true
      // this.activePicker = colorType
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick)
      // this.displayPicker = false
      this.displayPicker[this.activePicker] = false
    },
    togglePicker(colorType) {
      // this.displayPicker ? this.hidePicker() : this.showPicker()
      this.activePicker = colorType
      this.displayPicker[colorType]
        ? this.hidePicker()
        : this.showPicker(colorType)
    },
    updateFromInput(inputType) {
      color = inputType == 'primary' ? this.primaryColor : this.secondaryColor
      this.updateColors(color, inputType)
    },
    updateFromPicker(color) {
      this.colors = color
      if (this.displayPicker.primary) {
        if (color.rgba.a == 1) {
          this.primaryColor = color.hex
        } else {
          this.primaryColor =
            'rgba(' +
            color.rgba.r +
            ', ' +
            color.rgba.g +
            ', ' +
            color.rgba.b +
            ', ' +
            color.rgba.a +
            ')'
        }
      } else if (this.displayPicker.secondary) {
        if (color.rgba.a == 1) {
          this.secondaryColor = color.hex
        } else {
          this.secondaryColor =
            'rgba(' +
            color.rgba.r +
            ', ' +
            color.rgba.g +
            ', ' +
            color.rgba.b +
            ', ' +
            color.rgba.a +
            ')'
        }
      }
    },
    documentClick(e) {
      let reference = 'colorpicker' + this.activePicker
      var el = this.$refs[reference],
        target = e.target
      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    },

    submit() {
      let outlet_id = accountService.getOutletId()
      this.storeSettings['theme'] = {}
      this.storeSettings['theme']['primaryColor'] = this.primaryColor
      this.storeSettings['theme']['secondaryColor'] = this.secondaryColor

      let formData = new FormData()
      // formData.append("outlets[name]", this.outlet.name);

      if (this.logoFile != '') {
        formData.append('outlets[outlet[online_logo]]', this.logoFile)
      }

      if (this.bannerFile != '') {
        formData.append('outlets[outlet[online_banner]]', this.bannerFile)
      }

      customizeService
        .editOutlet(outlet_id, formData)
        .then((response) => {
          // Swal.fire('Successfully Updated ')
        })
        .catch((error) => {
          // Swal.fire('Error, try again later')
        })

      customizeService
        .editOutlet(outlet_id, {
          outlets: {
            outlet: {
              online_store_settings: this.storeSettings,
            },
          },
        })
        .then((response) => {
          if (this.logoFile || this.bannerFile) {
            customizeService
              .editOutlet(outlet_id, formData)
              .then((response) => {
                Swal.fire('Successfully Updated ')
              })
              .catch((error) => {
                Swal.fire('Error, try again later')
              })
          } else {
            Swal.fire('Successfully Updated ')
          }
        })

      // .then((res) => {
      // if (res.status === 200) {
      //   Swal.fire({
      //     position: 'top-end',
      //     icon: 'success',
      //     title: 'Your work has been saved',
      //     showConfirmButton: false,
      //     timer: 1000,
      //   })
      // } else {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Something went wrong!',
      //   })
      // }
      // })
    },
    onFileChange(e, uploadType = 'logo') {
      let files = e.target.files || e.dataTransfer.files

      uploadType == 'logo'
        ? (this.logoFile = event.target.files[0])
        : (this.bannerFile = event.target.files[0])
      if (!files.length) return
      // if (e.target.name) {
      //   this.createImage(files[0], 'circle')
      // } else {
      this.createImage(files[0], uploadType)
      // }
    },
    createImage(file, type) {
      let reader = new FileReader()
      let vm = this

      reader.onload = (e) => {
        // if (type === 'circle') {
        //   vm.imageCircle = e.target.result
        // } else {
        if (type == 'logo') {
          vm.logoImageRegular = e.target.result
        } else {
          vm.bannerImageRegular = e.target.result
        }

        // }
      }
      reader.readAsDataURL(file)
    },
    removeImage: function (type) {
      if (type === 'logo') {
        this.logoImageRegular = ''
      } else {
        this.bannerImageRegular = ''
      }
    },
    alertModule(value, imgType) {
      var self = this
      let outlet_id = accountService.getOutletId()

      // if (this.formType == "Add Module") {
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          let formData = new FormData()
          if (imgType != '') {
            formData.append('outlets[outlet[purge_img_type]]', imgType)
          }

          customizeService.editOutlet(outlet_id, formData).then((res) => {
            if (imgType != '') {
              if (imgType == 'logo') {
                this.logoRegularImg = ''
              } else {
                this.bannerRegularImg = ''
              }
              Swal.fire('Successfully Updated ')
            }
          })
        }
      })
    },
    deleteImage(imgType) {
      this.purgeImg = true
      this.alertModule('Edit', imgType)
    },
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    primaryColor(val) {
      if (val) {
        this.updateColors(val)
        this.$emit('input', val)
        //document.body.style.background = val;
      }
    },
    secondaryColor(val) {
      if (val) {
        this.updateColors(val)
        this.$emit('input', val)
        //document.body.style.background = val;
      }
    },
  },
  created() {
    let outlet_id = accountService.getOutletId()

    customizeService.getOutletOnlineSettings(outlet_id).then((res) => {
      this.storeSettings = res.data.online_store_settings || {
        theme: {},
        store: { dinein: {}, collection: {}, delivery: {} },
      }
      this.primaryColor = this.storeSettings.theme.primaryColor
      this.secondaryColor = this.storeSettings.theme.secondaryColor
      this.logoRegularImg = res.data.logo_online
      this.bannerRegularImg = res.data.banner_online
    })
  },
}
</script>
<style>
.color-picker {
  position: relative;
  width: 225px;
}
.current-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #000;
  cursor: pointer;
}

.mainDiv {
  height: 700px;
}

.vc-chrome {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 9;
}

.head {
  font-size: 20px;
  /* margin-left: 13px;
  margin-top: 20px; */
  margin: 20px 0 15px 13px;
}

.text-center {
  justify-content: center !important;
}

.topDiv {
  margin-bottom: 120px;
  margin-top: 0px;
}

.btnDiv .md-card-actions {
  border: none !important;
}

.colorInput {
  width: 100%;
}
.left-padding {
  padding-left: 0px;
}

.topDiv h6 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.text-white {
  color: #fff;
}
.min-300 {
  min-height: 300px;
}
</style>
