<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>

          <h3>Order Types</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-checkbox v-model="orderTypes.quick_service">{{
              $t('Quick Service')
            }}</md-checkbox>
          </div>
          <div class="md-layout-item md-size-100">
            <md-checkbox v-model="orderTypes.take_out">{{
              $t('Take Out')
            }}</md-checkbox>
          </div>
          <div class="md-layout-item md-size-100">
            <md-checkbox v-model="orderTypes.dine_in">{{
              $t('Dine in')
            }}</md-checkbox>
          </div>

          <div class="md-layout-item md-size-100">
            <md-checkbox v-model="orderTypes.web_order">{{
              $t('Web Order')
            }}</md-checkbox>
          </div>

          <div class="md-layout-item md-size-100">
            <md-checkbox v-model="orderTypes.delivery">{{
              $t('Delivery')
            }}</md-checkbox>
          </div>
        </div>
      </md-card-content>

      <div class="btn-div">
        <md-card-actions class="text-center">
          <md-button native-type="submit">{{ $t('CANCEL') }}</md-button>
          <md-button
            class="md-teritiary"
            native-type="submit"
            @click="updateOrderTypes()"
            >{{ $t('UPDATE') }}</md-button
          >
        </md-card-actions>
      </div>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
export default {
  components: {},
  props: {},

  data() {
    return {
      orderTypes: {
        quick_service: false,
        take_out: false,
        dine_in: false,
        open_table: false,
        web_order: false,
        deliver: false,
      },
    }
  },
  methods: {
    updateOrderTypes() {
      customizeService.updateOrderTypes(this.orderTypes).then((res) => {
        console.log('Response:', res)
      })
    },
  },
  created() {
    customizeService.getOutletById(accountService.getOutletId()).then((res) => {
      this.orderTypes = {
        quick_service: res.data.outlet.quick_service,
        take_out: res.data.outlet.take_out,
        dine_in: res.data.outlet.dine_in,
        // open_table: res.data.outlet.open_table,
        web_order: res.data.outlet.web_order,
        delivery: res.data.outlet.delivery,
      }
    })
  },

  watch: {},
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
</style>
