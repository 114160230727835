<template>
  <div class="md-layout table-table">
    <div class="md-layout-item pd-l">
      <md-card class="pagination-card block-bg pad-l-15px pad-r-15px">
        <md-card-header
          class="md-card-header-icon md-card-header-green d-flex align-items-center justify-content-between mt-15"
        >
          <h3 class="title d-flex align-items-center">
            <router-link
              :to="{
                name: 'AddTable',
                query: { formType: 'Add Table', seq: maxSeq.sequence_no },
              }"
            >
              <md-icon class="add-icon">add</md-icon>
            </router-link>
            {{ $t('Table') }}
          </h3>
          <div class="active-inactive-tab" v-if="!loader">
            <div class="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox"
                id="myonoffswitch"
                tabindex="0"
                :value="activetab"
                @change="updateInput($event)"
                :checked="activetab == 'active' ? true : false"
              />
              <label class="onoffswitch-label" for="myonoffswitch">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
        </md-card-header>
        <md-card-content class="">
          <div class="loader-container" v-if="loader">
            <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
          </div>
          <div class="select-holder">
            <md-checkbox v-model="selAll"></md-checkbox>
            <label>Select All/Deselect Tables</label>
          </div>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover table-typ1 mt-25 outlet-table"
            @md-selected="viewDetails"
            v-if="!loader"
          >
            <md-table-toolbar class="table-toolbar-align">
              <div class="download-btn">
                <img src="@/assets/images/pdf.svg" @click="showTables = true" />
              </div>

              <md-field>
                <label>{{ $t('Search') }}</label>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style=""
                  v-model="searchQuery"
                ></md-input>
                <i class="fa fa-search search-align" aria-hidden="true"></i>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                v-bind:md-label="$t('Seq Position')"
                md-sort-by="sequence_no"
              >
                <div @click="viewDetails(item)">
                  {{ item.sequence_no }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Name')" md-sort-by="name">
                <div @click="viewDetails(item)">
                  {{ item.name }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Seating Capacity')">
                <div @click="viewDetails(item)">
                  {{ item.chair_no }}
                </div>
              </md-table-cell>
              <md-table-cell
                v-bind:md-label="$t('Floor Area')"
                md-sort-by="tableArea"
              >
                <div @click="viewDetails(item)">
                  {{ item.tableArea }}
                </div>
              </md-table-cell>
              <md-table-cell class="center" v-bind:md-label="$t('Status')">
                <div @click="viewDetails(item)">
                  <i
                    v-bind:class="[
                      'check-align',
                      item.status === 'active' ? 'fa fa-check ' : 'fa fa-ban',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Description')">
                <div @click="viewDetails(item)">
                  {{ item.description }}
                </div>
              </md-table-cell>
              <md-table-cell v-bind:md-label="$t('Print')">
                <div>
                  <div class="md-layout-item text-center">
                    <md-checkbox
                      v-model="selectedTables"
                      :value="item.name"
                    ></md-checkbox>
                  </div>
                </div>
              </md-table-cell>
              <md-table-cell class="center" v-bind:md-label="$t('Actions')">
                <div class="border-edit">
                  <md-menu
                    md-size="medium"
                    :md-offset-x="-227"
                    :md-offset-y="-16"
                  >
                    <i
                      class="material-icons text_align-center menu-icon"
                      md-menu-trigger
                      >more_vert</i
                    >
                    <md-menu-content class="menuitem-dropdown">
                      <md-menu-item class="action-header menu-align">{{
                        $t('Actions')
                      }}</md-menu-item>
                      <router-link
                        :to="{
                          name: 'AddTable',
                          query: { formType: 'Edit Table', client: item.url },
                        }"
                      >
                        <md-menu-item>
                          <span class="edit-align">
                            <i class="fas fa-pencil-alt"></i>
                            {{ $t('Edit') }}
                          </span>
                        </md-menu-item>
                      </router-link>
                      <div>
                        <md-menu-item>
                          <span class="edit-align" @click="updateItem(item.id)">
                            <i class="fa fa-ban" aria-hidden="true"></i>
                            {{
                              item.status == 'active'
                                ? $t('Deactivate')
                                : $t('Activate')
                            }}
                          </span>
                        </md-menu-item>
                      </div>
                      <div>
                        <md-menu-item>
                          <span
                            class="edit-align"
                            @click="alert('Delete', item.url)"
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            {{ $t('Delete') }}
                          </span>
                        </md-menu-item>
                      </div>
                      <!-- 
                      <div>
                        <md-menu-item>
                          <span
                            class="edit-align"
                            @click="downloadQrCode(item)"
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            {{ $t('Download QR Code') }}
                          </span>
                        </md-menu-item>
                      </div> -->
                    </md-menu-content>
                  </md-menu>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <!-- <div class="footer-table md-table">
             <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
        </div>-->
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <div class="md-layout">
          <div class="md-layout-item layout-border">
            <div class="pagination-main">
              <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

              <div class="pagenation-number">
                <md-field>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
              <div class="pagination-warp">
                <p class="card-category pagination-text-align">
                  {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                </p>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- </md-card-actions> -->

        <!-- <md-button @click.native.prevent="downloadQrCode">{{
          $t('SAVE')
        }}</md-button> -->
      </md-card>
    </div>

    <md-dialog :md-active.sync="showTables" class="qr-download">
      <md-card class="">
        <div class="md-layout-item">
          <div class=""></div>
        </div>

        <md-button class="download-btn-popup" @click="downloadQrCode()">
          Download PDF
          <md-icon class="arrow-down-bold-circle-outline">download</md-icon>
        </md-button>
        <!-- <a href="#" @click="downloadQrCode">Download</a> -->
        <div id="table-qrcode" class="table-qr">
          <div v-for="item in selectedTables" :key="item" class="pad-20px">
            <qrcode-vue :value="onlineBaseUrl + item" :size="size" level="H" />
            <p>Table: {{ item }}</p>
          </div>
        </div>
      </md-card>
    </md-dialog>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { accountService } from '../../../services/account'

import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import QrcodeVue from 'qrcode.vue'
import axios from 'axios'
import html2canvas from 'html2canvas'
import Sortable from 'sortablejs'
import jsPDF from 'jspdf'

export default {
  name: 'modifier-list',
  components: {
    Pagination,
    axios,
    ClipLoader,
    QrcodeVue,
    Sortable,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData.filter(
          (item) => item.status == this.activetab
        )
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      onlineBaseUrl: `${
        process.env.VUE_APP_ONLINE_APP_URL
      }/cbeinnova-demo/store/${accountService.getOutletId()}/home/`,
      size: 100,
      currentSort: 'sequence_no',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ['Name', 'Email', 'Age', 'Salary', 'Actions'],
      searchQuery: '',
      propsToSearch: ['name'],
      tableData: [],
      tempdata: [],
      searchedData: [],
      fuseSearch: null,
      tableArea: [],
      loader: false,
      activetab: 'active',
      maxSeq: 0,
      selectedTables: [],
      selAll: false,
      showTables: false,
    }
  },
  methods: {
    customSort(value) {
      return this.tableData.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, { numeric: true })
        }
        return a[sortBy]
          .toString()
          .localeCompare(b[sortBy].toString(), undefined, { numeric: true })
      })
    },
    handleLike(item) {
      Swal.fire({
        title: `You liked ${item.name}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'md-button md-success',
      })
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit ${item.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'md-button md-info',
      })
    },

    viewDetails(item) {
      this.$router.push({
        name: 'AddTable',
        query: { formType: 'View Table', client: item.url },
      })
    },
    alert(value, url) {
      var self = this
      if (value === 'Delete') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.delete(url)
          }
        })
      }
    },
    delete(url) {
      axios.delete(url + '.json', customizeService.authFun()).then((res) => {
        this.getItems()
      })
    },
    getTableArea() {
      var self = this
      this.loader = true
      customizeService.getTableAreas().then((res) => {
        self.tableArea = res.data.table_areas
        this.getItems()
      })
    },

    getItems() {
      var self = this
      customizeService.getTable().then((res) => {
        this.loader = false
        // self.tableData = res.data.tables
        self.tempData = JSON.parse(JSON.stringify(res.data.tables))
        self.tableData = self.tempData.filter((item) => item.status == 'active')
        self.tableData.forEach((element) => {
          var index = this.tableData.indexOf(element)
          self.tableArea.forEach((el) => {
            if (element.outlet_floor_id === el.id) {
              self.tableData[index]['tableArea'] = el.floor_name
            }
          })
        })
        this.maxSeq = self.tempData.reduce(
          (prev, current) =>
            prev.sequence_no > current.sequence_no ? prev : current,
          0
        )
      })
    },

    updateItem(id) {
      var self = this
      reusableService.showSwal('Edit').then((result) => {
        if (result.value) {
          self.updateItemStatus(id)
        }
      })
    },
    updateItemOrder: function () {
      // get your info then...
      let sort_order_json = { tables: {} }
      this.tableData.map(function (item, index) {
        item.sequence_no = index + 1
        sort_order_json['tables'][item.id] = {
          sequence_no: index + 1,
        }
      })
      customizeService.update_categories(sort_order_json, 'table')
    },

    updateItemStatus(id) {
      let item = this.tableData.find((element) => element.id == id)
      let status = item.status == 'active' ? 'inactive' : 'active'
      let body = {
        tables: { status: status },
      }

      customizeService.updateTable(id, body).then((result) => {
        item.status = status
      })
    },
    filterBystatus(status) {
      this.activetab = status
      this.searchedData = []
      this.tableData = this.tempData.filter((item) => item.status == status)
      this.customSort()
    },
    updateInput(event) {
      let status = event.target.checked ? 'active' : 'inactive'
      this.filterBystatus(status)
    },

    downloadQrCode() {
      var _self = this
      html2canvas(document.querySelector('#table-qrcode'), {
        useCORS: true,
        allowTaint: true,
        scrollY: -window.scrollY,
      }).then((canvas) => {
        // document.body.appendChild(canvas)
        // const content = canvas

        // var imgData = canvas.toDataURL('image/png')
        // var doc = new jsPDF('p', 'mm')
        // doc.addImage(imgData, 'PNG', 10, 10)
        // doc.save('sample-file.pdf')
        var imgData = canvas.toDataURL('image/png')
        var imgWidth = 210
        var pageHeight = 295
        var imgHeight = (canvas.height * imgWidth) / canvas.width
        var heightLeft = imgHeight
        // window.jsPDF = window.jspdf.jsPDF
        var doc = new jsPDF('p', 'mm')
        var position = 0

        doc.addImage(imgData, 'jpeg', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'jpeg', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }
        doc.save('tables.pdf')
        _self.showTables = false
        _self.selAll = false
      })

      // ##############################################################################
      // let url = 'reggie/store/' + table.outlet_id + '/home/' + table.id
      // this.value = this.value + url
      // html2canvas(document.querySelector('#table-qrcode'), {
      //   useCORS: true,
      //   allowTaint: true,
      //   scrollY: -window.scrollY,
      // })
      //   .then((canvas) => {
      //     // document.body.appendChild(canvas)
      //     document.getElementById('append-div').appendChild(canvas)
      //     console.log(canvas)
      //   })
      //   .then(() => {
      //     // var canvas = document.querySelector('canvas')
      //     // canvas.style.display = 'none'
      //     // console.log(canvas)
      //     var image = canvas
      //       .toDataURL('image/png')
      //       .replace('image/png', 'image/octet-stream')
      //     var a = document.createElement('a')
      //     a.setAttribute('download', table.id + '.png')
      //     a.setAttribute('href', image)
      //     a.click()
      //     a.remove()
      //   })
    },
  },
  mounted() {
    // Fuse search initialization.
    // this.fuseSearch = new Fuse(this.tableData, {
    //   keys: ["name"],
    //   threshold: 0.6
    // });
  },

  created() {
    this.getTableArea()
  },
  updated: function () {
    let table = document.querySelector('.outlet-table tbody')
    const _self = this
    Sortable.create(table, {
      onEnd({ newIndex, oldIndex }) {
        const rowSelected = _self.tableData.splice(oldIndex, 1)[0] // Get the selected row and remove it
        _self.tableData.splice(newIndex, 0, rowSelected) // Move it to the new index
        _self.updateItemOrder()
      },
    })
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['name'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },

    selAll(val) {
      if (val) {
        this.selectedTables = this.tableData.map((el) => el.name)
      } else {
        this.selectedTables = []
      }
    },
  },
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}
#table-qrcode {
  width: 700px;
  height: auto;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  /* visibility: hidden; */
}
#table-qrcode p {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.outlet-table .md-table-row {
  cursor: move;
}
.download-btn {
  width: 40px;
  margin-right: 15px;
  margin-left: auto;
}

.download-btn:hover {
  transform: scale(1.1);
  filter: invert(44%) sepia(11%) saturate(5207%) hue-rotate(119deg)
    brightness(102%) contrast(101%);
}
.select-holder {
  padding-right: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 30px;
  right: 320px;
}
.select-holder label {
  color: #fff;
  font-weight: 600;
}
.download-btn-popup {
  display: flex;
  width: 155px;
  padding: 6px;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1055;
  background-color: #008000bd !important;
}
.download-btn-popup i {
  margin: initial !important;
}
.qr-download {
  height: 100%;
}
</style>
