<template>
  <div class="md-layout module-table">
    <div class="md-layout-item">
      <md-card class="report-card block-bg">
        <md-card-header
          class="md-card-header-text md-card-header-green md-layout md-gutter"
        >
          <div class="md-layout-item md-size-70 report-name">
            <span class="arrow_left">
              <router-link :to="{ path: '/dashboard' }">
                <img src="../../../../assets/images/left.svg" />
              </router-link>
            </span>
            <h3>{{ $t('CBE PAY Report') }}</h3>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout md-layout-item filter-section block-bg">
              <div class="md-layout-item md-size-55">
                <date-period-dropdown
                  @interval="setDateType"
                ></date-period-dropdown>
              </div>
              <div class="md-layout-item md-size-45">
                <div class="md-layout search-section">
                  <div class="md-layout-item md-size-30">
                    <div class="float-right">
                      <md-button class="md-teritiary" @click="getItems()"
                        >Search
                      </md-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 card-inside report-table">
              <div class="loader-container" v-if="loader">
                <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
              </div>

              <div
                class="md-layout-item md-size-100 file-icon-holder"
                v-if="!loader"
              >
                <h4 class="center" v-if="!loader">
                  CBE PAY Payout- {{ sdate }} to {{ edate }}
                </h4>

                <div class="action-buttons">
                  <div class="pdf-el">
                    <div class="download-btn">
                      <img
                        src="../../../../assets/images/pdf.svg"
                        @click="downloadWithoutCSS()"
                      />
                    </div>
                  </div>

                  <div class="excel-el">
                    <vue-excel-xlsx
                      class="Excel-btn"
                      :data="report"
                      :columns="columns"
                      :filename="'CBE PAY Transactions'"
                      :sheetname="'CBE PAY Transactions'"
                      :outlet-names="selectedOutletsNames()"
                    >
                    </vue-excel-xlsx>
                  </div>
                </div>
              </div>

              <md-table
                :value="queriedData"
                :md-sort.sync="currentSort"
                :md-sort-order.sync="currentSortOrder"
                class="paginated-table table-striped table-hover accounts-table"
                v-if="!loader"
              >
                <md-table-toolbar class="table-toolbar-align">
                  <md-field>
                    <label>{{ $t('Search') }}</label>
                    <md-input
                      type="search"
                      class="mb-3"
                      clearablef7
                      style=""
                      v-model="searchQuery"
                    ></md-input>
                    <i class="fa fa-search search-align" aria-hidden="true"></i>
                  </md-field>
                </md-table-toolbar>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell
                    v-bind:md-label="$t('Date')"
                    md-sort-by="createdAt"
                  >
                    <div>
                      {{ moment(item.createdAt).format('DD/MM/YYYY HH:mm') }}
                    </div>
                  </md-table-cell>

                  <md-table-cell v-bind:md-label="$t('CBE PAY Sales')">
                    <div>{{ item.sale }}</div>
                  </md-table-cell>

                  <md-table-cell
                    v-bind:md-label="$t('Splits')"
                    md-sort-by="split"
                  >
                    <div>
                      {{ (item.split / 100).toFixed(2) }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('EOD Balance')"
                    md-sort-by="eodBalance"
                  >
                    <div class="center">{{ item.eodBalance }}</div>
                  </md-table-cell>

                  <md-table-cell v-bind:md-label="$t('Paid Out')">
                    <div class="center">
                      {{ item.payout }}
                    </div>
                  </md-table-cell>
                  <md-table-cell
                    v-bind:md-label="$t('Balance Carried Forward')"
                  >
                    <div class="center">
                      {{ item.balanceCarriedForward }}
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>
        </md-card-content>
        <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
        <!-- <div class="md-layout">
              <div class="md-layout-item layout-border">
                <div class="pagination-main">
                  <div class="rowsperpage">{{ $t('Rows Per page') }}</div>
    
                  <div class="pagenation-number">
                    <md-field>
                      <md-select v-model="pagination.perPage" name="pages">
                        <md-option
                          v-for="item in pagination.perPageOptions"
                          :key="item"
                          :label="item"
                          :value="item"
                          >{{ item }}</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                  <div class="pagination-warp">
                    <p class="card-category pagination-text-align">
                      {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
                    </p>
                    <pagination
                      class="pagination-no-border pagination-success"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="total"
                    ></pagination>
                  </div>
                </div>
              </div>
            </div> -->
        <!-- </md-card-actions> -->
      </md-card>
    </div>
  </div>
</template>
<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import { customizeService } from '../../../../services/customize.js'
import { accountService } from '../../../../services/account.js'
import { reusableService } from '../../../../services/reusable.js'
import DatePeriodDropdown from '../../../../components/DatePeriodDropdown'
import { cbePayService } from '../../../../services/cbePay.js'
import moment from 'moment'
import VueExcelXlsx from '../../../../components/VueExcelXlsx'
import { pdfService } from '../../../../services/jspdf.js'
import jsPDF from 'jspdf'

export default {
  components: {
    ClipLoader,
    Pagination,
    DatePeriodDropdown,
    VueExcelXlsx,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData

      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      data: {
        sdate: '',
        search_type: 'Daily',
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      currentSort: 'createdAt',
      currentSortOrder: 'desc',
      searchQuery: '',
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
      activeStatus: { index: '', status: false },
      sdate: '',
      edate: '',
      outletList: [],
      outletId: [],
      report: [],
      balancesSum: [],
      lastPayout: 0,
      lastPayoutDate: '',
      columns: [
        {
          label: 'Txn Date',
          field: 'createdAt',
        },

        {
          label: 'Tnx Id',
          field: 'transactionId',
        },

        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Status',
          field: 'status',
        },

        {
          label: 'Value Date',
          field: 'valuedate',
        },
        {
          label: 'Estimated Arrival',
          field: 'estimateArrivalTime',
        },
      ],
      columnStyles: {
        0: { columnWidth: 50 },
        1: { columnWidth: 30 },
        2: { columnWidth: 25 },
        3: { columnWidth: 35 },
        4: { columnWidth: 30 },
        5: { columnWidth: 50 },
        6: { columnWidth: 40 },
      },
    }
  },

  methods: {
    setDateType(interval) {
      this.data.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.data.startDate = interval.startDate
        this.data.endDate = interval.endDate
      } else {
        this.data.startDate = interval.date
        this.data.endDate = undefined
        if (!this.loader) {
          this.getItems()
        }
      }
    },
    toggleRow(index) {
      this.activeStatus.index = index
      this.activeStatus.status = !this.activeStatus.status
    },
    getItems() {
      this.loader = true
      cbePayService.getPayouts(this.data).then((res) => {
        this.loader = false
        this.tableData = res.data.result
        this.lastPayout = res.data.lastPayout
        this.lastPayoutDate = res.data.lastPayoutDate
        this.balancesSum = res.data.balances.reduce(
          (n, { available }) => n + available,
          0
        )
        this.report = JSON.parse(JSON.stringify(res.data.result))

        this.report.forEach((el) => {
          el.eventDate = moment(el.eventDate).format('DD/MM/YYYY HH:MM')
          el.amount = (el.amount / 100).toFixed(2)
          el.sellerSplit = (el.sellerSplit / 100).toFixed(2)
          el.valueDate = el.valueDate
            ? moment(el.valueDate).format('DD/MM/YYYY')
            : ''
          // el.card_ref_id = el.card_ref_id
          //   ? el.card_ref_id.split(',')[2].replace(/[^a-z0-9]/gi, '')
        })

        // this.stripe_account_no = res.data.id
        this.sdate = res.data.sdate
        this.edate = res.data.edate
      })
    },

    viewPromoDetail(item) {
      this.$router.push({
        name: 'AddPromotions',
        query: { formType: 'Edit Promotion', client: item.id },
      })
    },

    alert(value, id) {
      var self = this
      reusableService.showSwal(value).then((result) => {
        if (result.value) {
          self.deletePromotion(id)
        }
      })
    },

    deletePromotion(id) {
      customizeService
        .deletePromotion(id)
        .then((res) => {
          console.log('response get', res)
          this.getItems()
        })
        .catch((err) => console.log(err))
    },

    selectedOutletsNames() {
      let selectedOutlets = this.outletList
        .filter((item) => this.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    arrangeForPdf() {
      var body = []
      this.report.forEach((el) => {
        let arrayObj = [
          el.createdAt,
          el.transactionId,
          el.amount,
          el.status,
          el.valueDate,
          el.estimateArrivalTime,
          el.trackingStatus,
        ]
        body.push(arrayObj)
      })
      let totObj = [
        '',
        'Total',
        (this.totals.amount / 100).toFixed(2),
        '',
        '',
        '',
        '',
      ]

      body.push(totObj)

      return body
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      let dateText = ''
      let head = [
        [
          // 'Termi',
          'Txn Date',
          'Tnx Id',
          'Amount',
          'Status',
          'Value Date',
          'Estimated Arrival',
          'Tracking Status',
        ],
      ]

      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      let selectedOutlets = this.selectedOutletsNames()
      pdfService.headerFooterConfig(
        this.data,
        doc,
        'CBE PAY Payout',
        selectedOutlets
      )
      doc.setFontSize(8)
      pdfService.autoTableConfig(
        doc,
        head,
        body,
        this.columnStyles,
        'cbepay-payout.pdf'
      )
    },
  },

  created() {
    // this.getItems()

    customizeService.getOutlets().then((res) => {
      this.outletList = res.data.outlets
      this.outletId = res.data.outlets.map((outlet) => {
        return outlet.id
      })
    })
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['pspRef', 'merchantRef'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>
<style>
.headerDiv {
  display: flex;
}

.add-icon {
  margin-left: 5px !important;
  margin-top: 16px;
  margin-right: 5px;
}

.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center !important;
}

.head {
  margin-top: 16px !important;
}

.paginated-table .md-table-head-label {
  padding-right: 0px !important;
}

.module-table .md-table-head .md-table-head-container {
  text-align: center !important;
}

.rotate {
  transition: transform 0.3s;
  transform: rotate(180deg);
}

.rotate-btn i {
  color: #585555 !important;
  font-size: 30px !important;
}

.rotate-btn {
  border: none;
  background-color: transparent;
}
.file-icon-holder {
  position: relative;
}
.action-buttons {
  width: 58px !important;
  padding-bottom: 20px;
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: right;
  margin-right: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  top: -25px;
}
.md-table-head:last-child .md-table-head-label {
  padding-right: 25px !important;
}
.balance {
  display: flex;
  padding: 5px;
  max-width: 420px;
  margin: auto;
  border: 2px solid #fff;
}
.balance-item-1 {
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-weight: 600;
  padding-left: 0;
  text-align: left;
}
.balance-item-2 {
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-weight: 600;
  text-align: right;
}
</style>
