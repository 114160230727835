<template>
  <div class="modifier-gp-table">
    <md-card class="pagination-card">
      <md-card-header
        class="md-card-header-icon md-card-header-green"
        v-if="$store.state.account.profile.has_access"
      >
        <div class="d-flex justify-content-between">
          <h3 class="title d-flex align-items-center mt-0">
            <router-link
              :to="{
                name: 'AddModifierGroup',
                query: {
                  formType: 'Add Modifier Group',
                  seq: maxSeq.sort_order,
                },
              }"
            >
              <md-icon class="add-icon">add</md-icon>
            </router-link>
            {{ $t('Modifier Group') }}
          </h3>
          <div class="active-inactive-tab" v-if="!loader">
            <div class="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox-main"
                id="myonoffswitch-main"
                tabindex="0"
                :value="activetab"
                @change="updateInput($event)"
                :checked="activetab == 'active' ? true : false"
              />
              <label class="onoffswitch-label" for="myonoffswitch-main">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <md-table
          :value="queriedData"
          :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder"
          :md-sort-fn="customSort"
          class="paginated-table table-striped table-hover modgroup-table"
          @md-selected="viewModifierGroup"
          v-if="!loader"
        >
          <md-table-toolbar class="table-toolbar-align">
            <md-field>
              <label>{{ $t('Search') }}</label>
              <md-input
                type="search"
                class="mb-3"
                clearable
                style=""
                v-model="searchQuery"
              ></md-input>
              <i class="fa fa-search search-align" aria-hidden="true"></i>
            </md-field>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell
              v-bind:md-label="$t('Sort Order')"
              md-sort-by="sort_order"
            >
              <div @click="viewModifierGroup(item)">
                {{ item.sort_order }}
              </div>
            </md-table-cell>
            <md-table-cell v-bind:md-label="$t('Name')" md-sort-by="name">
              <div @click="viewModifierGroup(item)">
                {{ item.name }}
              </div>
            </md-table-cell>
            <!-- <md-table-cell md-label="Description" md-sort-by="City">
                <div @click="viewModifierGroup(item)">
                  {{
                  item.items.length
                  }}
                </div>
              </md-table-cell>-->
            <md-table-cell class="center" v-bind:md-label="$t('Status')">
              <div @click="viewModifierGroup(item)">
                <i
                  v-bind:class="[
                    'check-align',
                    item.status === 'active' ? 'fa fa-check ' : 'fa fa-ban',
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
            </md-table-cell>
            <md-table-cell class="center" v-bind:md-label="$t('Actions')">
              <div class="border-edit">
                <md-menu
                  md-size="medium"
                  :md-offset-x="-227"
                  :md-offset-y="-16"
                >
                  <i
                    class="material-icons text_align-center menu-icon"
                    md-menu-trigger
                    >more_vert</i
                  >
                  <md-menu-content class="menuitem-dropdown">
                    <md-menu-item class="action-header menu-align">{{
                      $t('Actions')
                    }}</md-menu-item>
                    <router-link
                      :to="{
                        name: 'AddModifierGroup',
                        query: {
                          formType: 'Edit Modifier Group',
                          client: item.id,
                        },
                      }"
                    >
                      <md-menu-item>
                        <span class="edit-align">
                          <i class="fas fa-pencil-alt"></i>
                          {{ $t('Edit') }}
                        </span>
                      </md-menu-item>
                    </router-link>
                    <div>
                      <md-menu-item>
                        <span class="edit-align" @click="updateItem(item.id)">
                          <i class="fa fa-ban" aria-hidden="true"></i>
                          {{
                            item.status == 'active'
                              ? $t('Deactivate')
                              : $t('Activate')
                          }}
                        </span>
                      </md-menu-item>
                    </div>
                    <!-- <div>
                      <md-menu-item>
                        <span
                          class="edit-align"
                          @click="alertModule('Delete', item.id)"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          {{ $t('Delete') }}
                        </span>
                      </md-menu-item>
                    </div> -->
                  </md-menu-content>
                </md-menu>
              </div>
              <!-- <md-button
                  class="md-just-icon md-info md-simple"
                  @click.native="handleLike(item)"
                >
                  <md-icon>favorite</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handleEdit(item)"
                >
                  <md-icon>dvr</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item)"
                >
                  <md-icon>close</md-icon>
                </md-button>-->
            </md-table-cell>
          </md-table-row>
        </md-table>
        <!-- <div class="footer-table md-table">
             <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>-->
      </md-card-content>
      <!-- <md-card-actions md-alignment="space-between " style="display:unset"> -->
      <div class="md-layout absolute-layout">
        <div class="md-layout-item layout-border">
          <div class="pagination-main">
            <div class="rowsperpage">{{ $t('Rows Per page') }}</div>

            <div class="pagenation-number">
              <md-field>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="pagination-warp">
              <p class="card-category pagination-text-align">
                {{ $t('From') }} {{ from + 1 }} {{ $t('to') }} {{ to }}
              </p>
              <pagination
                class="pagination-no-border pagination-success"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- </md-card-actions> -->
    </md-card>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import users from './../Tables/users.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customizeService } from '../../../services/customize.js'
import { reusableService } from '../../../services/reusable.js'
import { accountService } from '../../../services/account.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Sortable from 'sortablejs'

export default {
  name: 'modifier-group-list',
  components: {
    Pagination,
    ClipLoader,
    Sortable,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData.filter(
          (item) => item.status == this.activetab
        )
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
  },
  data() {
    return {
      currentSort: 'sort_order',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ['Name', 'Email', 'Age', 'Salary', 'Actions'],
      searchQuery: '',
      propsToSearch: ['name'],
      tableData: [],
      tempData: [],
      searchedData: [],
      fuseSearch: null,
      loader: false,
      activetab: 'active',
      maxSeq: 0,
    }
  },
  methods: {
    customSort(value) {
      return this.tableData.sort((a, b) => {
        const sortBy = this.currentSort
        if (a[sortBy] == null) {
          return 1
        }
        if (b[sortBy] == null) {
          return -1
        }

        if (this.currentSortOrder === 'desc') {
          return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, { numeric: true })
        }
        return a[sortBy]
          .toString()
          .localeCompare(b[sortBy].toString(), undefined, { numeric: true })
      })
    },
    updateItemOrder: function () {
      // get your info then...
      let sort_order_json = { modifier_group: {} }
      this.tableData.map(function (item, index) {
        item.sort_order = index + 1
        sort_order_json['modifier_group'][item.id] = {
          sort_order: index + 1,
        }
      })
      customizeService.update_categories(sort_order_json, 'modgroup')
    },
    handleLike(item) {
      Swal.fire({
        title: `You liked ${item.name}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'md-button md-success',
      })
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit ${item.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'md-button md-info',
      })
    },
    // handleDelete(item) {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: `You won't be able to revert this!`,
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonClass: "md-button md-success btn-fill",
    //     cancelButtonClass: "md-button md-danger btn-fill",
    //     confirmButtonText: "Yes, delete it!",
    //     buttonsStyling: false
    //   }).then(result => {
    //     if (result.value) {
    //       this.deleteRow(item);
    //       Swal.fire({
    //         title: "Deleted!",
    //         text: `You deleted ${item.name}`,
    //         type: "success",
    //         confirmButtonClass: "md-button md-success btn-fill",
    //         buttonsStyling: false
    //       });
    //     }
    //   });
    // },

    viewModifierGroup(item) {
      this.$router.push({
        name: 'AddModifierGroup',
        query: {
          formType: 'Edit Modifier Group',
          client: item.id,
        },
      })
    },
    alertModule(value, id) {
      var self = this
      if (value === 'Delete') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            self.deleteModifierGroup(id)
          }
        })
      }
    },
    updateItem(id) {
      var self = this
      reusableService.showSwal('Edit').then((result) => {
        self.updateItemStatus(id)
      })
    },

    updateItemStatus(id) {
      let item = this.tempData.find((element) => element.id == id)
      let status = item.status == 'active' ? 'inactive' : 'active'
      let body = {
        modifier_group: { status: status },
      }

      customizeService.updateModifierGroupStatus(id, body).then((result) => {
        item.status = status
        this.filterBystatus(this.activetab)
        this.customSort()
      })
    },
    deleteModifierGroup(id) {
      customizeService.deleteModifierGroup(id).then((res) => {
        this.getItems()
      })
    },

    getItems() {
      var self = this
      this.loader = true
      customizeService.getModifierGroup().then((res) => {
        this.loader = false
        self.tempData = JSON.parse(JSON.stringify(res.data))
        self.tableData = self.tempData.filter((item) => item.status == 'active')
        this.maxSeq = self.tempData.reduce(
          (prev, current) =>
            prev.sort_order > current.sort_order ? prev : current,
          0
        )
      })
    },

    filterBystatus(status) {
      this.activetab = status
      this.searchedData = []
      this.tableData = this.tempData.filter((item) => item.status == status)
    },
    updateInput(event) {
      let status = event.target.checked ? 'active' : 'inactive'
      this.filterBystatus(status)
    },
  },
  mounted() {
    // Fuse search initialization.
    // this.fuseSearch = new Fuse(this.tableData, {
    //   keys: ["name"],
    //   threshold: 0.6
    // });
  },

  created() {
    this.getItems()
  },
  updated: function () {
    let table = document.querySelector('.modgroup-table tbody')
    const _self = this
    Sortable.create(table, {
      onEnd({ newIndex, oldIndex }) {
        const rowSelected = _self.tableData.splice(oldIndex, 1)[0] // Get the selected row and remove it
        _self.tableData.splice(newIndex, 0, rowSelected) // Move it to the new index
        _self.updateItemOrder()
      },
    })
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      this.searchedData = this.tableData
      console.log('result', result)
      if (value !== '') {
        result = new Fuse(this.tableData, {
          keys: ['name'],
          threshold: 0.1,
        }).search(this.searchQuery)
        this.searchedData = result.map((el) => el.item)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.add-icon {
  float: left;
  padding-top: 32px;
}
.action-header {
  text-align: center;
}
.menu-align {
  padding: 0;
  background-color: #cccccc;
}
.pagination-text-align {
  float: left;
  padding-top: 3px;
}
.footer-align {
  display: flex;
  float: right;
}
.pagenation-number {
  max-width: 46px;
}
.pagination-warp {
  padding-left: 30px;
}
.pagination-main {
  display: flex;
  float: right;
}
.center {
  text-align: center;
}
.modgroup-table .md-table-row {
  cursor: move;
}
</style>
