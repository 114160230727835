<template>
  <form class="md-layout-item">
    <md-card class="block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/Dashboard' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>FlipDish</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div v-if="!loader" class="md-layout">
          <!-- <div class="md-layout-item md-size-50">
            <md-field
              :class="[
                { 'md-error': errors.has('Token') },
                { 'md-valid': !errors.has('Token') && touched.Token },
              ]"
            >
              <label>{{ $t('Token') }}*</label>
              <md-input
                v-model="token"
                data-vv-name="Token"
                type="text"
                v-validate="modelValidations.Token"
                v-on:blur="updateStores()"
              ></md-input>
              <slide-y-down-transition>
                <div class="error" v-show="errors.has('Token')">
                  {{ errors.first('Token') }}
                </div>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon
                  class="success"
                  v-show="!errors.has('Token') && touched.Token"
                  >done</md-icon
                >
              </slide-y-down-transition>
            </md-field>
          </div> -->

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Store') }}</label>
              <md-select v-on:input="updateMenuId()" v-model="storeId">
                <md-option
                  :value="item.StoreId"
                  v-for="item in stores"
                  :key="item.StoreId"
                  >{{ item.Name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Menu') }}</label>
              <md-select v-model="menuId">
                <md-option
                  :value="item.MenuId"
                  v-for="item in menus"
                  :key="item.MenuId"
                  >{{ item.Name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-25">
            <md-field>
              <label>{{ $t('Processing Fee') }}</label>
              <md-select v-model="flipDish.processing_fee">
                <md-option
                  :value="item.id"
                  v-for="item in mapped_items"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-25">
            <md-field>
              <label>{{ $t('Delivery Fee') }}</label>
              <md-select v-model="flipDish.delivery_fee">
                <md-option
                  :value="item.id"
                  v-for="item in mapped_items"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Voucher') }}</label>
              <md-select v-model="flipDish.voucher">
                <md-option
                  :value="item.id"
                  v-for="item in vouchers"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Processing Fee Payment Type') }}</label>
              <md-select v-model="flipDish.processing_fee_paytype">
                <md-option
                  :value="item.id"
                  v-for="item in otherFeeTypes"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Delivery Fee Payment Type') }}</label>
              <md-select v-model="flipDish.delivery_fee_paytype">
                <md-option
                  :value="item.id"
                  v-for="item in otherFeeTypes"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Open Item') }}</label>
              <md-select v-model="flipDish.open_item">
                <md-option
                  :value="item.id"
                  v-for="item in mapped_items"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Online Payment Type') }}</label>
              <md-select v-model="flipDish.online_payment_type">
                <md-option
                  :value="item.id"
                  v-for="item in online_payments"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Selected Stores') }}</label>
              <md-select
                v-on:input="updateMenuId()"
                v-model="selectedStoreIds"
                multiple
              >
                <md-option
                  :value="item.StoreId"
                  v-for="item in stores"
                  :key="item.StoreId"
                  >{{ item.Name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Selected Terminals') }}</label>
              <md-select v-model="selectedTerminalIds" multiple>
                <md-option
                  :value="item.id"
                  v-for="item in terminals"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t('Selected Terminals') }}</label>
              <md-select v-model="selectedTerminalIds" multiple>
                <md-option
                  :value="item.id"
                  v-for="item in terminals"
                  :key="item.id"
                  >{{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50">
            <md-checkbox v-model="flipDish.auto_accept">{{
              $t('Auto Accept')
            }}</md-checkbox>
          </div>
        </div>

        <div class="md-layout-item md-size-50">
          <md-field>
            <!-- <label>{{ $t('Auto Accept') }}</label> -->
            <!-- <md-select v-model="selectedTerminalIds" multiple>
              <md-option
                :value="item.id"
                v-for="item in terminals"
                :key="item.id"
                >{{ item.name }}</md-option
              >
            </md-select> -->
            <md-switch v-model="flipDish.auto_accept">Auto Accept ?</md-switch>
          </md-field>
        </div>

        <div class="btn-div">
          <md-card-actions class="text-center">
            <md-button native-type="submit" :to="{ path: '/Dashboard' }">{{
              $t('CANCEL')
            }}</md-button>
            <md-button
              native-type="submit"
              @click.native.prevent="validate"
              class="md-teritiary"
              >{{ $t('UPDATE') }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { reusableService } from '../../../services/reusable.js'
import { customizeService } from '../../../services/customize.js'
import { accountService } from '../../../services/account.js'
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    SlideYDownTransition,
    ClipLoader,
  },
  props: {},
  data() {
    return {
      Token: '',
      touched: {
        Token: false,
      },
      modelValidations: {
        Token: {
          required: true,
        },
      },
      flipDish: {
        processing_fee: '',
        delivery_fee: '',
        open_item: '',
        online_payment_type: '',
        auto_accept: false,
      },
      token: '',
      appId: '',
      storeId: '',
      menuId: '',
      stores: [],
      menus: [],
      terminals: [],
      mapped_items: [],
      otherFeeTypes: [],
      vouchers: [],
      online_payments: [],
      selectedStoreIds: [],
      selectedTerminalIds: [],

      loader: false,
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$emit('on-submit', this.alert('Add'), isValid)
        }
      })
    },

    alert(value) {
      var self = this
      if (value === 'Add') {
        reusableService.showSwal(value).then((result) => {
          if (result.value) {
            this.updateOutletFlipDish()
          }
        })
      }
    },

    updateMenuId() {
      // let matchedStore = this.stores.find(
      //   (store) => store.StoreId == this.storeId
      // )
      // if (matchedStore) {
      //   this.menuId = matchedStore.MenuId
      // }
      this.getMenus()
    },

    updateStores() {
      let body = {
        flipdish_token: this.token,
      }
      customizeService.updateFlipDishToken(body).then((res) => {
        this.appId = res.data[0].AppId
        this.getStores()
      })
    },

    updateOutletFlipDish() {
      var store_details = []
      // this.stores.forEach(function (obj) {
      //   // alert( key + ": " + value );
      //   var obj = { name: obj.Name, id: obj.StoreId }
      //   store_details.push(obj)
      // })
      let matched_stores = this.stores.filter((e) =>
        this.selectedStoreIds.includes(e.StoreId)
      )
      this.flipDish.flipDishStores = store_details
      this.flipDish.allowed_terminal_ids = this.selectedTerminalIds

      matched_stores.forEach(function (obj) {
        // alert( key + ": " + value );
        var obj = { name: obj.Name, id: obj.StoreId }
        store_details.push(obj)
      })

      // this.menuId = matchedStore.MenuId

      let body = {
        outlets: {
          outlet: {
            store_id: this.storeId,
            // flipdish_app_id: this.appId,
            flipdish_menu_id: this.menuId,
            flipdish_settings: this.flipDish,
          },
        },
      }
      this.loader = true
      customizeService
        .editOutlet(accountService.getOutletId(), body)
        .then((res) => {
          this.loader = false
        })
    },

    getStores() {
      customizeService.getFlipDishStore().then((res) => {
        this.stores = res.data.Data
        // this.storeId = res.data.Data[0].StoreId
      })
    },
    getTerminals() {
      customizeService.getterminals().then((res) => {
        this.terminals = res.data
        // this.storeId = res.data.Data[0].StoreId
      })
    },
    getMenus() {
      customizeService.getFlipdishMenus().then((res) => {
        this.menus = res.data.Data
        // this.storeId = res.data.Data[0].StoreId
      })
    },
  },
  created() {
    customizeService.getDiscountCat().then((res) => {
      this.vouchers = res.data.discount_categories
    })
    customizeService
      .getOtherPaymentTypes(accountService.getOutletId())
      .then((res) => {
        this.online_payments = res.data.other_payment_types
        this.otherFeeTypes = res.data.other_payment_types.filter(
          (item) => item.status == true && item.is_fee == true
        )
      })

    customizeService.getMapItems().then((res) => {
      this.mapped_items = res.data
    })

    customizeService.getOutletById(accountService.getOutletId()).then((res) => {
      this.flipDish = res.data.outlet.flipdish_settings
        ? res.data.outlet.flipdish_settings
        : this.flipDish

      if (res.data.outlet.flipdish_settings.flipDishStores) {
        this.selectedStoreIds =
          res.data.outlet.flipdish_settings.flipDishStores.map((el) => el.id)
      }

      this.appId = res.data.outlet.flipdish_app_id
      this.getStores()
      this.getTerminals()
      this.storeId = res.data.outlet.store_id
      this.menuId = res.data.outlet.flipdish_menu_id
      this.selectedTerminalIds =
        res.data.outlet.flipdish_settings.allowed_terminal_ids
    })

    // this.updateStores()
  },
  watch: {
    Token() {
      this.touched.required = true
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.text-center {
  justify-content: center !important;
}
.arrow_left {
  float: left;
  padding-top: 21px;
}
.logo-align {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 22px;
}
.toggle-align {
  float: left;
  padding-top: 12px;
  padding-right: 9px;
}
.edit-img {
  padding: 32px;
  float: right;
}
.logo-text-cls {
  text-align: center;
}
</style>
